import React, { useState } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';

// # MATERIAL.UI IMPORT
import { TextField, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';

// # CSS
import '../../../assets/css/contact.css';

export default function ContactEntreprise() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    name: '',
    address: '',
    employees: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    choice: 'Code entreprise',
    message: '',
  });

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_API}/contact/entreprise`, state)
      .then(() => {
        enqueueSnackbar('Votre message a bien été envoyé', {
          variant: 'success',
        });

        setState(() => ({
          name: '',
          address: '',
          employees: '',
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          choice: 'Code entreprise',
          message: '',
        }));
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  return (
    <div id="contact">
      <div className="appel">
        <div>
          <p>
            Nous serions également ravis <br />
            si vous préférez nous appeler !
          </p>
          <p className="num">
            Florence et Adrien : <br />
            01 41 14 18 10
          </p>
        </div>
      </div>
      <form onSubmit={(e) => handleSubmit(e)} className="form">
        <h1>Contactez-nous !</h1>
        <p className="p">
          Vous souhaitez mettre en place un service de livraison de paniers de fruits et légumes Bio
          dans votre entreprise ? Remplissez ce formulaire pour recevoir une offre personnalisée.
        </p>
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Nom de l'entreprise"
          variant="outlined"
          value={state.name}
          name="name"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Adresse de livraison"
          helperText="Où seront livrer les paniers"
          variant="outlined"
          value={state.address}
          name="address"
          onChange={handleChange}
        />
        <TextField
          className="input"
          fullWidth
          color="primary"
          label="Nombre de salariés"
          helperText="Qui pourront bénéficier des paniers Bio"
          variant="outlined"
          value={state.employees}
          name="employees"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Votre Nom"
          variant="outlined"
          value={state.lastname}
          name="lastname"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Votre Prénom"
          variant="outlined"
          value={state.firstname}
          name="firstname"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Téléphone"
          variant="outlined"
          value={state.phone}
          name="phone"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="E-mail"
          variant="outlined"
          value={state.email}
          name="email"
          onChange={handleChange}
        />
        <FormControl variant="outlined">
          <InputLabel>Solution qui retient votre attention</InputLabel>
          <Select
            label="Solution qui retient votre attention"
            value={state.choice}
            name="choice"
            onChange={handleChange}
          >
            <MenuItem value="Code entreprise">Code Entreprise</MenuItem>
            <MenuItem value="Plateforme Collaborative">Plateforme Collaborative</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="input"
          required
          multiline
          rows={4}
          fullWidth
          color="primary"
          label="Message"
          helperText="Facultatif"
          variant="outlined"
          value={state.message}
          name="message"
          onChange={handleChange}
        />
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}
