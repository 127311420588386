import React, { Fragment } from 'react';

// MATERIAL.UI
import Button from '@material-ui/core/Button';

export default function paymentSuccess() {
  return (
    <div>
      <h1>Commande annulée</h1>

      <h3>Votre commande n'a pas été finalisée</h3>
      <p>
        Vous pouvez contacter notre équipe en cas de problème ou retourner au catalogue de produits
      </p>

      <Button className="btn-orange" href="/catalogue/les-paniers-bio">
        Voir le catalogue de produits
      </Button>

      <div id="contact">
        <div style={{ margin: '0' }} className="appel">
          <div>
            <p>
              Nous serions également ravis <br />
              si vous préférez nous appeler !
            </p>
            <p className="num">
              Florence et Adrien : <br />
              01 41 14 18 10
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
