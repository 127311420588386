import React, { Fragment, useContext, useEffect } from 'react';

import axios from 'axios';

import moment from 'moment';
import 'moment/locale/fr';

import { InvitationContext } from '../../contexts/InvitationContext/InvitationContext';

export default function InvitationSession() {
  moment.locale('fr');
  const { invitation } = useContext(InvitationContext);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/categories/?isActive=true`)
        .then((res) => {
          const { categories } = res.data.data;
          const sortedCat = categories.sort((a, b) => a.position - b.position);

          window.location = `/eco-catalogue/${sortedCat[0].slug}`;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (invitation.uuid !== '') {
      fetchData();
    }
  }, [invitation.uuid]);

  return <></>;
}
