import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import {
  Button,
  Menu,
  Collapse,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import moment from 'moment';
import calendarImg from '../assets/img/picto/calendar.png';

import 'moment/locale/fr';
import { CartContext } from '../contexts/CartContext';
import {getSchedules} from "../utils/getSchedules";
import {SystemContext} from "../contexts/SystemContext/SystemContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 40px)',
    gridTemplateRows: 'repeat(6, 40px)',
    columnGap: '5px',
    rowGap: '5px',
    width: '310px',
    margin: '0 auto',
    padding: '1rem',
  },
  month: {
    gridColumnStart: 2,
    gridColumnEnd: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  weekdays: {
    color: '#bbbbbbde',
  },
  day: {
    color: '#f05c35',
    backgroundColor: '#ffffff',
    border: 'none',
    fontFamily: 'Bitter',
    fontWeight: 600,
    letterSpacing: '2px',
    '&:hover': {
      backgroundColor: '#f05c35',
      color: 'white',
      cursor: 'pointer',
    },
    '&:disabled': {
      color: '#bbbbbbde',
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'unset',
      },
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },
  button: {
    marginTop: '1rem',
  },
  arrow: {
    backgroundColor: 'white',
    border: 'none',
    color: 'black',
    '&:hover': {
      color: '#f05c35',
      cursor: 'pointer',
    },
  },
  calendar: {
    borderRadius: '10px',
    padding: '2px 5px',
    width: '100%',
    backgroundColor: 'white',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Calendar(props) {
  moment.locale('fr');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [schedule, setSchedule] = useState(false);
  const [value, setValue] = React.useState(null);
  const { setDeliveryInfos } = useContext(CartContext);
  const { closureTimes } = useContext(SystemContext);
  const [state, setState] = useState({
    closureStart: closureTimes.start,
    closureEnd: closureTimes.end,
    displayMonth: moment().format('YYYY-MM'),
    monthLength: 0,
    startDay: 1,
    day: '',
    weekdays: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
    monthDays: [],
    schedule: [],
    deliveryInfos: {
      deliveryType: props.deliveryType,
      deliveryDate: '',
      deliverySchedule: '',
      deliveryId: '',
    },
  });

  useEffect(() => {
    const monthLength = moment(state.displayMonth, 'YYYY-MM').daysInMonth();
    const startDay = moment(state.displayMonth).isoWeekday();
    const {closureStart} = state;
    const {closureEnd} = state;
    const start = moment(`${state.displayMonth}-01`);
    const end = moment(`${state.displayMonth}-${monthLength}`);
    const now = start.clone();
    const today = moment();
    const lastDay = moment().add(4, 'month');

    const month = [];

    while (now.isSameOrBefore(end)) {
      let disabled = true;
      const day = now.format('dddd');
      if (now.isBetween(today, lastDay)) {
        disabled = !props.dates.some((date) => date.day === day);
      }
      if (now.isBetween(closureStart, closureEnd) || moment(now).format('YYYY-MM-DD') === closureStart || moment(now).format('YYYY-MM-DD') === closureEnd) {
        disabled = true;
      }
      props.relayInfos?.closure.forEach(close => {
        if(now.isBetween(close.start, close.end)){
          disabled = true;
        }
      });
      const tempObj = {
        formatedDate: now.format('YYYY-MM-DD'),
        day,
        disabled,
      };
      month.push(tempObj);
      now.add(1, 'days');
    }

    setState((prev) => ({
      ...prev,
      monthLength,
      startDay,
      monthDays: month,
      schedule: props.dates,
    }));
  }, [state.displayMonth, props.dates]);

  const handleChange = (event) => {
    event.persist();
    const deliverySchedule = event.target.value;
    setValue(deliverySchedule);

    const deliveryInfos = {
      ...state.deliveryInfos,
    };

    if (props.relayInfos) {
      deliveryInfos.deliverySchedule = deliverySchedule;
      deliveryInfos.deliveryId = props.relayInfos.id;
      deliveryInfos.deliverySubType = props.relayInfos.type;
    } else {
      deliveryInfos.deliverySchedule = deliverySchedule;
    }

    setDeliveryInfos(deliveryInfos);

    setAnchorEl(false);
  };

  const handleNextMonth = () => {
    setState((prev) => ({
      ...prev,
      displayMonth: moment(state.displayMonth).add(1, 'month').format('YYYY-MM'),
    }));
  };

  const handleLastMonth = () => {
    setState((prev) => ({
      ...prev,
      displayMonth: moment(state.displayMonth).subtract(1, 'month').format('YYYY-MM'),
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDate = (date, day) => {
    setState((prev) => ({
      ...prev,
      day,
      deliveryInfos: {
        ...prev.deliveryInfos,
        deliveryDate: date,
      },
    }));

    setSchedule(true);
    setValue(null);
  };

  const getCalendarDayStyle = (idx, date) => {
    const style = {};

    if (idx === 0) style.gridColumnStart = state.startDay;

    if (date === state.deliveryInfos.deliveryDate) {
      style.backgroundColor = '#f05c33';
      style.color = 'white';
    }

    return style;
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.calendar}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={calendarImg} alt="calendar" width="60px" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <section className={classes.container}>
          <button onClick={handleLastMonth} className={classes.arrow}>
            <ArrowBackIosIcon fontSize="small" />
          </button>
          <section className={classes.month}>{moment(state.displayMonth).format('MMMM')}</section>
          <button onClick={handleNextMonth} className={classes.arrow}>
            <ArrowForwardIosIcon fontSize="small" />
          </button>
          {state.weekdays.map((weekday, idx) => (
            <section key={idx} className={classes.weekdays}>
              <p>{weekday}</p>
            </section>
          ))}
          {state.monthDays.map((day, idx) => (
            <button
              style={getCalendarDayStyle(idx, day.formatedDate)}
              key={idx}
              disabled={day.disabled}
              onClick={() => handleClickDate(day.formatedDate, day.day)}
              className={classes.day}
            >
              <p>{idx + 1}</p>
            </button>
          ))}
        </section>
        <Collapse in={schedule}>
          <div className={classes.flex}>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Horaires : {moment(state.deliveryInfos.deliveryDate).format('dddd Do MMMM')}
                </FormLabel>
                <RadioGroup
                  aria-label="timeDelivery"
                  name="timeDelivery"
                  value={value}
                  onChange={handleChange}
                >
                  {props.dates
                    .filter((date) => date.day === state.day)
                    .map((el, idx) => (
                      <FormControlLabel
                          key={idx}
                          value={`${el.startTime} - ${el.endTime}`}
                          control={<Radio />}
                          label={`${el.startTime} - ${el.endTime}`}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </Collapse>
      </Menu>
    </div>
  );
}
