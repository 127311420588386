import React, { useState, useContext } from 'react';

import axios from 'axios';

// # MATERIAL.UI IMPORT
import { TextField, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

// # CONTEXT
import { UserContext } from '../../../contexts/UserContext/UserContext';

// # CSS
import '../../../assets/css/contact.css';

export default function CreateContact() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    contact: {
      firstname: '',
      lastname: '',
      phoneNumber: '',
      email: '',
    },
  });

  const { addContact, user } = useContext(UserContext);

  const handleChange = (event) => {
    event.persist();

    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        [name]: value,
      },
    }));
  };

  const handleAddContact = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/users/contacts`,
        { ...state.contact, phoneNumber: state.contact.phoneNumber || '0000000000' },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar('Votre contact a bien été ajouté', {
          variant: 'success',
        });

        addContact(res.data.data.contacts);

        setState((prev) => ({
          ...prev,
          contact: {
            firstname: '',
            lastname: '',
            phoneNumber: '',
            email: '',
          },
        }));
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  return (
    <div id="contact">
      <div className="form">
        <h1>Ajoutez un contact</h1>
        <TextField
          className="input"
          required
          fullWidth
          color="secondary"
          id="outlined-basic"
          label="Prénom"
          variant="outlined"
          value={state.contact.firstname}
          name="firstname"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="secondary"
          id="outlined-basic"
          label="Nom"
          variant="outlined"
          value={state.contact.lastname}
          name="lastname"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="secondary"
          id="outlined-basic"
          label="E-mail"
          variant="outlined"
          value={state.contact.email}
          name="email"
          onChange={handleChange}
        />
        <TextField
          className="input"
          fullWidth
          color="secondary"
          id="outlined-basic"
          type="number"
          label="Téléphone"
          variant="outlined"
          value={state.contact.phoneNumber}
          name="phoneNumber"
          onChange={handleChange}
        />
        <Button className="btn-orange" onClick={handleAddContact}>
          Ajouter
        </Button>
      </div>
    </div>
  );
}
