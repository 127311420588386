import React from 'react';
import ReactDOM from 'react-dom';

// # SENTRY
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// # MATERIAL UI
import { frFR } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

// # COMPONENTS
import App from './App';

// # CONTEXTS
import UserContextProvider from './contexts/UserContext/UserContext';
import InvitationContextProvider from './contexts/InvitationContext/InvitationContext';
import CartContextProvider from './contexts/CartContext';
import SystemContextProvider from './contexts/SystemContext/SystemContext';

if (process.env.REACT_APP_API !== 'http://localhost:3000/api/v1') {
  Sentry.init({
    dsn: 'https://fb7219362a8f4373978c08e52e086afe@o571262.ingest.sentry.io/5737647',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const theme = createTheme(
  {
    palette: {
      type: 'light',
      primary: {
        light: '#f9ffc9',
        main: '#bfcd46',
        dark: '#bfcd46',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FFF2E7',
        main: '#f05c35',
        dark: '#f05c35',
        contrastText: '#fff',
      },
      info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: '#fff',
      },
      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
      },
      default: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: '#fff',
      },
      error: {
        light: '#f42929',
        main: '#f42929',
        dark: '#f42929',
        contrastText: '#fff',
      },
    },
  },
  frFR
);

ReactDOM.render(
  <UserContextProvider>
    <InvitationContextProvider>
      <CartContextProvider>
        <SystemContextProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={5} preventDuplicate>
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </SystemContextProvider>
      </CartContextProvider>
    </InvitationContextProvider>
  </UserContextProvider>,
  document.getElementById('root')
);
