import React, { useState, useEffect, Fragment } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router';

import {
  Grid,
  Paper,
  Chip,
  Button,
  Switch,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBot: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editing: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      maxWidth: '70%',
      marginBottom: '1rem',
    },
  },
  marginB: {
    margin: '0 0 1rem 0',
  },
  marginR: {
    margin: '0 1rem 0 0',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

export default function SegmentDetails() {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    loading: true,
    editing: false,
    segment: {},
    segmentUpdate: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      const segments = await axios
        .get(`${process.env.REACT_APP_API}/segments/${id}`)
        .catch((err) => {
          console.log(err);
        });

      setState((prev) => ({
        ...prev,
        loading: false,
        segment: segments.data.data.segment,
        segmentUpdate: {
          isActive: segments.data.data.segment.isActive,
          name: segments.data.data.segment.name,
          category: segments.data.data.segment.category,
          amount: segments.data.data.segment.amount,
          description: segments.data.data.segment.description,
        },
      }));
    };
    fetchData();
  }, [id]);

  const handleEditing = (bool) => {
    setState((prev) => ({
      ...prev,
      editing: bool,
    }));
  };

  const handleSwitch = (event) => {
    setState((prev) => ({
      ...prev,
      segmentUpdate: {
        ...prev.segmentUpdate,
        isActive: event.target.checked,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      segmentUpdate: {
        ...prev.segmentUpdate,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeAmount = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      segmentUpdate: {
        ...prev.segmentUpdate,
        [event.target.name]: event.target.value * 1,
      },
    }));
  };

  const handleUpdate = async () => {
    const token = Cookies.get('jwt');

    await axios
      .patch(`${process.env.REACT_APP_API}/segments/${id}`, state.segmentUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        enqueueSnackbar(`Une problème est survenu`, {
          variant: 'error',
        });
      });
  };

  const handleDelete = async () => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/segments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = '/admin/segments';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <div className={classes.flexTop}>
            <h1>Segment :</h1>
            {state.editing ? (
              <div>
                <Button
                  className={classes.marginR}
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleDelete()}
                >
                  Supprimer
                </Button>
                <Button variant="contained" color="primary" onClick={(e) => handleEditing(false)}>
                  Annuler
                </Button>
              </div>
            ) : (
              <div className={classes.margin}>
                <div>
                  <Button variant="contained" color="primary" onClick={(e) => handleEditing(true)}>
                    Modifier
                  </Button>
                </div>
              </div>
            )}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <h3>Informations :</h3>
                {state.editing ? (
                  <section className={classes.editing}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.segmentUpdate.isActive}
                          name="isActive"
                          onClick={handleSwitch}
                        />
                      }
                      label={state.segmentUpdate.isActive ? 'Actif' : 'Inactif'}
                    />
                    <TextField
                      className={classes.marginB}
                      label="Nom"
                      variant="outlined"
                      type="text"
                      size="small"
                      name="name"
                      defaultValue={state.segmentUpdate.name}
                      onChange={handleChange}
                    />
                    <FormControl variant="outlined" className={classes.marginB}>
                      <InputLabel>Catégorie</InputLabel>
                      <Select
                        native
                        label="Catégorie"
                        defaultValue={state.segmentUpdate.category}
                        name="category"
                        onChange={handleChange}
                      >
                        <option value="entreprise">Entreprise</option>
                        <option value="eco">Eco-acteur</option>
                        <option value="relay">Point relais</option>
                      </Select>
                    </FormControl>
                    <TextField
                      className={classes.marginB}
                      label="Taux commercial"
                      variant="outlined"
                      type="number"
                      size="small"
                      name="amount"
                      defaultValue={state.segmentUpdate.amount}
                      onChange={handleChangeAmount}
                    />
                    <TextField
                      className={classes.marginB}
                      label="Description"
                      variant="outlined"
                      type="text"
                      size="small"
                      name="description"
                      defaultValue={state.segmentUpdate.description}
                      onChange={handleChange}
                    />
                    <Button variant="contained" color="primary" onClick={handleUpdate}>
                      Valider les modifications
                    </Button>
                  </section>
                ) : (
                  <section>
                    <Chip
                      size="small"
                      label={state.segment.isActive ? 'Actif' : 'Inactif'}
                      color={state.segment.isActive ? 'primary' : 'secondary'}
                    />
                    <p>
                      <b>ID :</b> {state.segment._id}
                    </p>
                    <p>
                      <b>Nom :</b> {state.segment.name}
                    </p>
                    <p>
                      <b>Catégorie :</b>{' '}
                      {state.segment.category === 'entreprise'
                        ? 'Entreprise'
                        : state.segment.category === 'eco'
                        ? 'Eco-acteur'
                        : 'Point relais'}
                    </p>
                    <p>
                      <b>Taux commercial :</b> {state.segment.amount} %
                    </p>
                    <p>
                      <b>Description :</b>
                      <br />
                      {state.segment.description}
                    </p>
                  </section>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
