const currencyFormater = (number) => {
  const formatedNumber = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);

  return formatedNumber.replace(/\s+/g, '');
};

export default currencyFormater;
