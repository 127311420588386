import React from 'react';
import Meta from '../utils/metaDescription';

// STYLE
import '../assets/css/style.css';
import '../assets/css/ecoActeur.css';

// MATERIAL <>
import { Hidden } from '@material-ui/core';

// COMPOSANT
import NavbarBlog from '../components/NavbarBlog';
import Navbar from '../components/Navbar';
import MenuBlog from '../components/MenuBlog';
import Footer from '../components/Footer';

export default function BlogLayout(props) {
  return (
    <div className="front">
      <Meta page="recettes" />
      <Navbar navbar={<MenuBlog />} />
      <Hidden smDown>
        <NavbarBlog />
      </Hidden>

      <div>{props.children}</div>
      <Footer />
    </div>
  );
}
