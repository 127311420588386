import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputBase,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { fade, makeStyles } from '@material-ui/core/styles';
import CreateSegmentModal from '../../../components/Segment/SegmentCreateModal';
import SegmentTable from '../../../components/Segment/SegmentTable';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade('#9e9e9e'),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

export default function SegmentList() {
  const classes = useStyles();
  const [state, setState] = useState({
    loading: true,
    segment: [],
    segmentShowcased: [],
    status: 'all',
    category: 'all',
  });

  const handleSearch = (e) => {
    const search = e.target.value.toString().toLowerCase();

    if (state.status !== 'all' || state.category !== 'all') {
      setState((prev) => ({
        ...prev,
        status: 'all',
        category: 'all',
      }));
    }
    const filtered = state.segment.filter((el) => el.name.toLowerCase().indexOf(search) > -1);

    if (search.length === 0) {
      return setState((prev) => ({
        ...prev,
        segmentShowcased: prev.segment,
      }));
    }
    setState((prev) => ({
      ...prev,
      segmentShowcased: filtered,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/segments/`)
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          const sortedSegments = res.data.data.segments.sort(
            (a, b) => -b.name.localeCompare(a.name)
          );

          setState((prev) => ({
            ...prev,
            segment: sortedSegments,
            segmentShowcased: sortedSegments,
            loading: false,
          }));
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    switch (state.category) {
      case 'all':
        if (state.status === 'all') {
          setState((prev) => ({
            ...prev,
            segmentShowcased: prev.segment,
          }));
          break;
        }
        const filtrer = state.segment.filter((el) => el.isActive === state.status);
        setState((prev) => ({
          ...prev,
          segmentShowcased: filtrer,
        }));
        break;

      default:
        const filterByCat = state.segment.filter((el) => el.category === state.category);
        if (state.status === 'all') {
          setState((prev) => ({
            ...prev,
            segmentShowcased: filterByCat,
          }));
          break;
        }
        const filterByStatus = filterByCat.filter((el) => el.isActive === state.status);
        setState((prev) => ({
          ...prev,
          segmentShowcased: filterByStatus,
        }));
        break;
    }
  }, [state.status, state.category, state.segment]);

  return (
    <>
      <div className={classes.flex}>
        <h2>Toutes les segments</h2>
        <CreateSegmentModal />
      </div>
      <Paper className={classes.paper}>
        <h3>Filtre</h3>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={3}>
            <FormControl style={{ minWidth: '150px' }} variant="outlined">
              <InputLabel id="select-payment-label">Statut</InputLabel>
              <Select
                labelId="select-payment-label"
                label="Rubrique"
                defaultValue="all"
                value={state.status}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value={false}>Inactif</MenuItem>
                <MenuItem value>Actif</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ minWidth: '200px' }} variant="outlined">
              <InputLabel id="select-payment-label">Catégorie</InputLabel>
              <Select
                labelId="select-payment-label"
                label="Rubrique"
                defaultValue="all"
                value={state.category}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    category: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value="entreprise">Entreprise</MenuItem>
                <MenuItem value="relay">Point relais</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Chercher un segment..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={handleSearch}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <SegmentTable data={state.segmentShowcased} />
      )}
    </>
  );
}
