import React from 'react';

export default function ExpiredInvitation() {
  return (
    <>
      <h1>La livraison de cette invitation a déjà eu lieue</h1>
      <h3>Merci de vérifier vos mail pour choisir une invitation à venir.</h3>
    </>
  );
}
