import React, {useContext, useEffect, useState} from 'react';

import {fade, makeStyles} from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography
} from "@material-ui/core";

import { CartContext } from "../../../contexts/CartContext";

import img2 from '../../../assets/img/pointrelais.png';
import img1 from '../../../assets/img/home.png';
import { axiosGetRequest } from "../../../utils/axiosRequests";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  relay: {
    cursor: "pointer",
    marginTop: '1em',
    padding: '1em',
    textAlign: 'left',
    border: '2px solid transparent',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  selectedRelay: {
    cursor: "pointer",
    marginTop: '1em',
    padding: '1em',
    textAlign: 'left',
    border: `2px solid ${theme.palette.primary.main}`
  },
  picto: {
    marginRight: '1em',
    maxHeight: '60px',
  },
  name: {
    color: theme.palette.secondary.main,
    fontSize: '1em',
    marginBottom: '5px'
  },
  address: {
    fontSize: '14px'
  },
  city: {
    fontSize: '14px'
  },
  accordion: {
    boxShadow: 'none',
    '&:before': {
      content: 'none',
      backgroundColor: 'none !important',
    },
    margin: '0 !important',
    fontSize: '14px',
  },
  accordionSummary: {
    padding: 0,
    minHeight: 'initial !important',
    '& .Mui-expanded': {
      margin: '0 !important'
    },
    '& .MuiAccordionSummary-content': {
      margin: '0 !important'
    }
  },
  accordionTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  accordionContent: {
    display: 'block',
    padding: 0,
    '& p:first-child': {
      marginTop: 0,
    },
    '& p:last-child': {
      marginBottom: 0,
    }
  },
}));

export default function DeliveryBox(props) {
  const classes = useStyles();

  const {
    deliveryInfos,
    setDeliveryInfos,
    setDeliveryAddress,
    setDeliveryBillingAddress,
    setDiscount,
    setCompanyDiscount
  } = useContext(CartContext);

  const [deliveryZone, setDeliveryZone] = useState(null);

  useEffect(() => {
    axiosGetRequest(`/deliveryCities/?zipCode=${props.item.zipCode ?? (props.item.city ? props.item.city.zipCode : props.item.zipCode)}`)
      .then((res) => {
        if (deliveryInfos.deliveryType === 'relayPoint' || props.item.type === 'relay') {
          return;
        }

        if (res.data.data.deliveryCity[0]) {
          setDeliveryZone(res.data.data.deliveryCity[0].deliveryZone);
        }
      });
  }, [])

  const selectAddress = (address, index) => {
    if (address._id && (!address.type || address.type && address.type !== 'relay' && address.type !== 'entreprise')) {
      setDiscount(null);

      setDeliveryAddress({
        deliveryAddress: {
          title: address.title || '',
          address: address.street,
          zipCode: address.zipCode,
          city: address.city,
          country: 'France',
          complement: address.comment
        }
      });

      setDeliveryBillingAddress({
        deliveryBillingAddress: {
          title: address.title || '',
          address: address.street,
          zipCode: address.zipCode,
          city: address.city,
          country: 'France',
          complement: address.comment
        }
      });

      setDeliveryInfos({
        companyDiscount: 0,
        deliveryDate: null,
        deliverySchedule: null,
        depositSchedule: null,
        deliveryType: 'home',
        deliverySubType: '',
        deliveryId: address._id,
      });

      props.handleRelayChange(null);
    } else {
      setDeliveryAddress({
        deliveryAddress: {
          title: address.name ?? address.title,
          address: address._id ? address.address.address : address.street,
          zipCode: address._id ? address.city.zipCode : address.zipCode,
          city: address._id ? address.city.name : address.city,
          country: address._id ? address.address.country : address.country,
          complement: address._id ? address.address.complement : address.complement,
          comment: address._id ? address.address.comment : address.information,
        }
      });

      setDeliveryBillingAddress({
        deliveryBillingAddress: {
          title: address.name ?? address.title,
          address: address._id ? address.address.address : address.street,
          zipCode: address._id ? address.city.zipCode : address.zipCode,
          city: address._id ? address.city.name : address.city,
          country: address._id ? address.address.country : address.country,
          complement: address._id ? address.address.complement : address.complement,
          comment: address._id ? address.address.comment : address.information,
        },
      });

      setDeliveryInfos({
        companyDiscount: 0,
        deliveryDate: null,
        deliverySchedule: null,
        depositSchedule: null,
        deliveryType: address._id ? 'relayPoint' : 'home',
        deliverySubType: address._id ? address.type : '',
        deliveryId: address._id ?? index,
      });

      if (address.discount && address.discount.value > 0) {
        setDiscount(address.discount);
      } else {
        setDiscount(null);
      }

      props.handleRelayChange(address);
    }
  }

  return (
    <React.Fragment>
      <Paper className={props.item.id !== '' && deliveryInfos.deliveryId === props.item.id || props.item._id !== '' && deliveryInfos.deliveryId === props.item._id || props.index !== '' && deliveryInfos.deliveryId === props.index ? classes.selectedRelay : classes.relay} onClick={() => selectAddress(props.item, props.index)}>
        <Box sx={{ display: 'flex' }}>
          <img
            className={classes.picto}
            src={props.item.street ? img1 : img2}
            alt={props.item.name}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
            <span className={classes.name}>{props.item.street ? (props.item.title ?? 'Adresse principale') : (!props.item._id ? (props.item.title ?? 'Livraison à domicile') : props.item.name)}</span>
            <span className={classes.address}>{props.item.street ?? (typeof props.item.address !== 'object' ? props.item.address : props.item.address.address)}</span>
            <span className={classes.city}>{props.item.zipCode ?? (props.item.city ? props.item.city.zipCode : props.item.zipCode)} {props.item.city && !props.item.city.name ? props.item.city : (props.item.city ? props.item.city.name : props.item.city)}</span>
            {deliveryZone && props.condition === true &&
              <Accordion className={classes.accordion}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.accordionTitle}>Condition de livraison</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                  <p>Livraison gratuite dès {deliveryZone.charges[2].threshold} €</p>
                  <p>
                    Entre {deliveryZone.charges[1].threshold} € et{' '}
                    {deliveryZone.charges[2].threshold} € :{' '}
                    {deliveryZone.charges[1].costsTTC} € de participation
                  </p>
                  <p>
                    Entre {deliveryZone.charges[0].threshold} € et{' '}
                    {deliveryZone.charges[1].threshold} € :{' '}
                    {deliveryZone.charges[0].costsTTC} € de participation
                  </p>
                </AccordionDetails>
              </Accordion>
            }
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  )
}