import React, { useContext } from 'react';
import { Stepper, Step } from 'react-form-stepper';

import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { CartContext } from '../../contexts/CartContext';

import CartSummary from './Cart/CartSummary';
import OrderSummary from './Cart/OrderSummary';
import Suggestions from './Cart/Suggestions';
import DeliverySummary from './Cart/DeliverySummary';
import UserSummary from './Cart/UserSummary';
import Payment from './Cart/Payment';
import { UserContext } from '../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1120px',
        paddingBottom: '2em',
        width: '100%',
        [theme.breakpoints.between('xs', 'md')]: {
            maxWidth: '95%',
        },
    },
    button: {
        marginTop: '3em',
        width: '100%',
    },
}));

export default function Panier() {
    const classes = useStyles();

    const { cartItems, deliveryInfos, step, setStep } = useContext(CartContext);
    const { user } = useContext(UserContext);

    const goTo = (step) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setStep(step);
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Stepper
                    activeStep={step}
                    styleConfig={{
                        activeBgColor: '#BFCD46',
                        completedBgColor: '#BFCD46',
                        inactiveBgColor: '#CBCBCB',
                        borderRadius: '5px',
                        size: '50px',
                        labelFontSize: '1em',
                        circleFontSize: '22px',
                        fontWeight: 400,
                    }}
                >
                    <Step label="Panier" onClick={() => goTo(0)} disabled={false} />
                    <Step label="Livraison" onClick={() => goTo(1)} disabled={step < 1} />
                    <Step label="Paiement" onClick={() => goTo(2)} disabled={step < 2} />
                </Stepper>
                {step === 0 && (
                    <React.Fragment>
                        <CartSummary title="Récapitulatif de commande" />
                        {cartItems && cartItems.length === 0 && (
                            <Alert severity="warning" style={{ maxWidth: '50%', margin: '25px auto' }}>
                                Vous n’avez pas ajouté d’article
                            </Alert>
                        )}
                        {cartItems && cartItems.length > 0 && (
                            <React.Fragment>
                                <OrderSummary />
                                <Suggestions />
                                <Button
                                    onClick={() => goTo(1)}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    className={classes.button}
                                >
                                    Valider mon panier
                                </Button>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                {step === 1 && (
                    <React.Fragment>
                        <DeliverySummary />
                        <Button
                            disabled={
                                !deliveryInfos.deliveryBillingAddress ||
                                !deliveryInfos.deliveryBillingAddress.address ||
                                !deliveryInfos.deliveryAddress ||
                                !deliveryInfos.deliveryAddress.address ||
                                !deliveryInfos.deliveryDate ||
                                !deliveryInfos.deliverySchedule
                            }
                            onClick={() => goTo(2)}
                            variant="contained"
                            color="secondary"
                            size="large"
                            className={classes.button}
                        >
                            Valider
                        </Button>
                    </React.Fragment>
                )}
                {step === 2 && (
                    <React.Fragment>
                        <CartSummary title="Récapitulatif" light />
                        <OrderSummary delivery />
                        {!user.token && <UserSummary />}
                        {user.token && <Payment />}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}
