import React, {useContext, useState} from 'react';
import Script from "react-load-script";
import { useSnackbar } from 'notistack';

import {
  Button,
  Collapse,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import {UserContext} from "../../../contexts/UserContext/UserContext";
import {axiosPostRequest, axiosPatchRequest} from "../../../utils/axiosRequests";
import {CartContext} from "../../../contexts/CartContext";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  person: {
    marginBottom: '-1em',
  },
  grid: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  span: {
    color: '#f05c35',
  },
  newsletterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function UserSummary() {
  const classes = useStyles();

  const { login, user, setAddress } = useContext(UserContext);
  const { deliveryInfos, setDeliveryInfos, setDeliveryAddress, setDeliveryBillingAddress } = useContext(CartContext);
  const { enqueueSnackbar } = useSnackbar();

  const [mode, setMode] = useState('registration');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [state, setState] = useState({
    entreprise: false,
    emailError: false,
    newUser: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordConfirm: '',
      information: '',
      phoneNumber: '',
      address: [
        {
          street: deliveryInfos.deliveryBillingAddress.address,
          zipCode: deliveryInfos.deliveryBillingAddress.zipCode,
          city: deliveryInfos.deliveryBillingAddress.city,
          comment: deliveryInfos.deliveryBillingAddress.comment,
          isMain: true,
          isBill: true,
        },
      ],
      newsLetter: true,
    },
  });

  const handleScriptLoad = () => {
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components'],
    };

    ['autocomplete', 'autocomplete-entreprise'].forEach((id) => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(id),
        options
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const components = place.address_components;
        let street;
        let zipCode;
        let city;

        components.forEach((component) => {
          const componentType = component.types[0];

          // eslint-disable-next-line default-case
          switch (componentType) {
            case 'locality':
              city = component.long_name;
              break;
            case 'street_number':
              street = component.long_name;
              break;
            case 'route':
              if (street) {
                street += ` ${component.long_name}`;
              } else {
                street = component.long_name;
              }
              break;
            case 'postal_code':
              zipCode = component.long_name;
              break;
          }
        });

        if (id === "autocomplete-entreprise") {
          setState((prev) => ({
            ...prev,
            newUser: {
              ...prev.newUser,
              address: [
                {
                  ...prev.newUser.address[1],
                  street,
                  zipCode,
                  city,
                },
              ],
            },
          }));
        } else {
          setState((prev) => ({
            ...prev,
            newUser: {
              ...prev.newUser,
              address: [
                {
                  ...prev.newUser.address[0],
                  street,
                  zipCode,
                  city,
                },
              ],
            },
          }));
        }
      });
    });
  };

  const handleRegistration = (e) => {
    e.preventDefault();

    axiosPostRequest('/users/signup', null, state.newUser).then((res) => {
      const response = res.data.data.user;
      const addresses = user.addresses;

      login({
        id: response.id,
        token: res.data.token,
        role: response.role,
        personalData: {
          firstname: response.firstname,
          lastname: response.lastname,
          fullname: response.fullname,
          phoneNumber: response.phoneNumber,
          email: response.email,
          complement: response.information,
          newsLetter: response.newsLetter,
        },
        addresses: [...response.address],
        ecoData: {
          ecoPoints: 0,
          customMessage: '',
          pickupSchedule: {
            from: '17:00',
            to: '18:00',
          },
          contacts: [],
        },
      });


      axiosPatchRequest('/users/updateMe', res.data.token, {
        address: response.address.concat(addresses)
      })
      .then((res) => {
        if (addresses[deliveryInfos.deliveryId]) {
          const deliveryAddress = res.data.data.user.address.find((address) => address.street === addresses[deliveryInfos.deliveryId].street);

          if (deliveryAddress) {
            setDeliveryInfos({
              deliveryDate: deliveryInfos.deliveryDate,
              deliverySchedule: deliveryInfos.deliverySchedule,
              depositSchedule: deliveryInfos.depositSchedule,
              deliveryType: 'home',
              deliverySubType: '',
              deliveryId: deliveryAddress._id,
            });

            setDeliveryAddress({
              deliveryAddress: {
                title: deliveryAddress.title || '',
                address: deliveryAddress.street,
                zipCode: deliveryAddress.zipCode,
                city: deliveryAddress.city,
                country: 'France',
                complement: deliveryAddress.comment
              }
            });
          }
        }

        if (state.newUser.address[1]) {
          setDeliveryBillingAddress({
            deliveryBillingAddress: {
              address: state.newUser.address[1].street,
              comment: state.newUser.address[1].comment,
              zipCode: state.newUser.address[1].zipCode,
              city: state.newUser.address[1].city,
              country: state.newUser.address[1].country
            },
          });
        }

        setAddress(res.data.data.user.address);
      })
    })
    .catch((err) => {
      const { errors } = err.response.data.error;
      Object.keys(errors).forEach((key) => {
        enqueueSnackbar(`${errors[key].message}`, { variant: 'error' });
      });
    });
  }

  const handleLogin = (e) => {
    e.preventDefault();

    axiosPostRequest('/users/login', null, { email, password }).then((res) => {
      const response = res.data.data.user;
      const addresses = user.addresses;

      const currUser = {
        id: response.id,
        token: res.data.token,
        role: response.role,
        personalData: {
          firstname: response.firstname,
          lastname: response.lastname,
          fullname: response.fullname,
          phoneNumber: response.phoneNumber,
          email: response.email,
          complement: response.complement || '',
          newsLetter: response.newsLetter,
        },
        addresses: response.address,
        edenred: response.edenred
      };

      login({
        ...currUser,
        ecoData: {
          ecoPoints: response.ecoPoints || 0,
          customMessage: response.ecoInvitationSettings?.customMessage || '',
          pickupSchedule: {
            from: response.ecoInvitationSettings?.pickupSchedule.from || '',
            to: response.ecoInvitationSettings?.pickupSchedule.to || '',
          },
          contacts: response.contacts || [],
        },
      });

      axiosPatchRequest('/users/updateMe', res.data.token, {
        address: response.address.concat(addresses)
      })
      .then((res) => {
        if (addresses[deliveryInfos.deliveryId]) {
          const deliveryAddress = res.data.data.user.address.find((address) => address.street === addresses[deliveryInfos.deliveryId].street);

          if (deliveryAddress) {
            setDeliveryInfos({
              deliveryDate: deliveryInfos.deliveryDate,
              deliverySchedule: deliveryInfos.deliverySchedule,
              depositSchedule: deliveryInfos.depositSchedule,
              deliveryType: 'home',
              deliverySubType: '',
              deliveryId: deliveryAddress._id,
            });

            setDeliveryAddress({
              deliveryAddress: {
                title: deliveryAddress.title || '',
                address: deliveryAddress.street,
                zipCode: deliveryAddress.zipCode,
                city: deliveryAddress.city,
                country: 'France',
                complement: deliveryAddress.comment
              }
            });
          }

          setAddress(res.data.data.user.address);
        }
      });
    })
    .catch(() => {
      enqueueSnackbar('Email ou mot de passe incorrect', {
        variant: 'error',
      });
    });
  }

  return (
    <React.Fragment>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <h3 className={classes.title}>Informations personnelles</h3>
      <Collapse in={mode === 'registration'}>
        <form id="formRegister" className={classes.form} onSubmit={handleRegistration}>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.newUser.entreprise}
                    color="primary"
                    onChange={(e) => {
                      const entreprise = e.target.checked;
                      setState((prev) => ({
                        entreprise,
                        newUser: {
                          ...prev.newUser,
                        },
                      }));
                    }}
                  />
                }
                label="Entreprise"
              />
            </Grid>
          </Grid>
          <Collapse in={state.entreprise}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  name="socialanomination"
                  variant="outlined"
                  type="text"
                  required={state.entreprise === true}
                  fullWidth
                  label="Dénomination Sociale"
                  onChange={(e) => {
                    const title = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      newUser: {
                        ...prev.newUser,
                        address: [
                          {
                            ...prev.newUser.address[0],
                            title,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={!state.entreprise}>
            <Grid container spacing={2} className={classes.person}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="Nom"
                  variant="outlined"
                  type="text"
                  margin="normal"
                  required
                  fullWidth
                  label="Nom"
                  onChange={(e) => {
                    const lastname = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      newUser: {
                        ...prev.newUser,
                        lastname,
                      },
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="Prénom"
                  variant="outlined"
                  type="text"
                  margin="normal"
                  required
                  fullWidth
                  label="Prénom"
                  onChange={(e) => {
                    const firstname = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      newUser: {
                        ...prev.newUser,
                        firstname,
                      },
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <TextField
                name="address"
                variant="outlined"
                type="text"
                required={state.entreprise === false}
                fullWidth
                label="Adresse"
                id="autocomplete"
                defaultValue={user.addresses[deliveryInfos.deliveryId] ? user.addresses[deliveryInfos.deliveryId].street : ''}
                onChange={(e) => {
                  const street = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      address: [
                        {
                          ...prev.newUser.address[0],
                          street,
                        },
                      ],
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="complet address"
                variant="outlined"
                type="text"
                fullWidth
                label="Complément d'adresse"
                onChange={(e) => {
                  const comment = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      address: [
                        {
                          ...prev.newUser.address[0],
                          comment,
                        },
                      ],
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="information"
                variant="outlined"
                type="text"
                fullWidth
                label="Informations pour le livreur (étage, digicode, etc...)"
                defaultValue={user.addresses[deliveryInfos.deliveryId] ? user.addresses[deliveryInfos.deliveryId].information : ''}
                onChange={(e) => {
                  const information = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      information,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                Name="Email"
                variant="outlined"
                type="text"
                required
                error={!!state.emailError}
                fullWidth
                label="Adresse mail"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    emailError: false,
                  }))
                }
                onChange={(e) => {
                  const email = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      email,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="phonenumber"
                variant="outlined"
                type="text"
                required
                fullWidth
                error={
                  !(
                    state.newUser.phoneNumber.length === 10 ||
                    state.newUser.phoneNumber.length === 0
                  )
                }
                label="Téléphone"
                onChange={(e) => {
                  const phoneNumber = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      phoneNumber,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="password"
                variant="outlined"
                required
                fullWidth
                error={!(state.newUser.password.length > 7 || state.newUser.password.length === 0)}
                helperText="Entrez un mot de passe avec plus de 8 caractères"
                label="Mot de passe"
                type="password"
                onChange={(e) => {
                  const password = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      password,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="password confirm"
                variant="outlined"
                required
                error={state.newUser.passwordConfirm !== state.newUser.password}
                helperText="Entrez à nouveau le même mot de passe"
                fullWidth
                label="Confirmation de mot de passe"
                type="password"
                onChange={(e) => {
                  const passwordConfirm = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      passwordConfirm,
                    },
                  }));
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.newsletterContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.newUser.newsLetter}
                  value="true"
                  color="primary"
                  onChange={(e) => {
                    const newsletter = e.target.checked;
                    setState((prev) => ({
                      ...prev,
                      newUser: {
                        ...prev.newUser,
                        newsLetter: newsletter,
                      },
                    }));
                  }}
                />
              }
              label="Newsletters"
            />
            <div>
                <span className={classes.span}>
                  (Je souhaite recevoir la composition des paniers Bio et les recettes tous les
                  dimanches)
                </span>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!state.newUser.address[0].isBill}
                    color="primary"
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          address: [
                            {
                              ...prev.newUser.address[0],
                              isBill: !state.newUser.address[0].isBill
                            },
                          ],
                        },
                      }));
                    }}
                  />
                }
                label="Adresse de facturation différente de l'adresse de livraison"
              />
            </Grid>
          </Grid>
          <Collapse in={!state.newUser.address[0].isBill}>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address"
                  variant="outlined"
                  type="text"
                  fullWidth
                  label="Adresse"
                  id="autocomplete-entreprise"
                  onChange={(e) => {
                    const street = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      newUser: {
                        ...prev.newUser,
                        address: [
                          {
                            ...prev.newUser.address[1],
                            street,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="complet address"
                  variant="outlined"
                  type="text"
                  fullWidth
                  label="Complément d'adresse"
                  onChange={(e) => {
                    const comment = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      newUser: {
                        ...prev.newUser,
                        address: [
                          {
                            ...prev.newUser.address[1],
                            comment,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            M&apos;inscrire
          </Button>
          <Grid container>
            <Grid item xs={12}>
              <Link onClick={() => setMode('login')} variant="body2">
                J'ai déjà un compte et je souhaite me connecter
              </Link>
            </Grid>
          </Grid>
        </form>
      </Collapse>
      <Collapse in={mode === 'login'}>
        <form className={classes.form} onSubmit={handleLogin}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse Email"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Connexion
          </Button>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Link onClick={() => setMode('registration')} variant="body2">
                Je ne possède pas de compte et je souhaite m'inscrire
              </Link>
              <br />
              <br />
            </Grid>
          </Grid>
        </form>
      </Collapse>
    </React.Fragment>
  )
}