import React, { Fragment, useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

// # UTILS
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Skeleton, Autocomplete } from '@material-ui/lab';
import currencyFormater from '../utils/currencyFormater';
import { autocompleteGroupedFormater } from '../utils/stringFormater';
import { axiosGetRequest, axiosPostRequest } from '../utils/axiosRequests';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  container: {
    maxHeight: 'fit-content',
  },
  stats: {
    margin: '50px 0',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
    maxWidth: '500px',
  },
  totalRow: {
    backgroundColor: '#fafafa',
  },
  marginB: {
    marginBottom: '1rem',
  },
}));

const columns = [
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  { id: 'reference', label: 'Référence', minWidth: 100, align: 'left' },
  { id: 'quantity', label: 'Quantité', minWidth: 50, align: 'center' },
  { id: 'ht', label: 'Total HT', minWidth: 50, align: 'center' },
  { id: 'tva', label: 'Total TVA', minWidth: 50, align: 'center' },
  { id: 'average', label: 'Moyenne TTC', minWidth: 50, align: 'center' },
  { id: 'ttc', label: 'Total TTC', minWidth: 50, align: 'right' },
];

function Row(props) {
  const { product } = props;

  return (
    <TableRow>
      <TableCell>{product.name}</TableCell>
      <TableCell>{product.reference}</TableCell>
      <TableCell align="center">{product.quantity}</TableCell>
      <TableCell align="center">{currencyFormater(product.totalPriceHt)}</TableCell>
      <TableCell align="center">{currencyFormater(product.totalTvaAmount)}</TableCell>
      <TableCell align="center">
        {currencyFormater(product.totalPriceTtc / product.quantity)}
      </TableCell>
      <TableCell align="right">{currencyFormater(product.totalPriceTtc)}</TableCell>
    </TableRow>
  );
}

export default function Dashboard() {
  moment.locale('fr');
  const classes = useStyles();

  const [state, setState] = useState({
    relayPoints: [],
    idfCities: [],
    segments: [],
    last12months: [],
    relays: [],
    conciergeries: [],
    entreprises: [],
    ecoUsers: [],
    products: {},
    productsDisplay: {},
    categories: [],
  });

  const [filter, setFilter] = useState({
    period: 'dateToDate',
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(1, 'days').format('YYYY-MM-DD'),
    week: moment().format('YYYY-MM-DD'),
    day: moment().format('YYYY-MM-DD'),
    month: '',
    advStatus: 'archived',
    place: 'all',
    zipCode: '',
    date: 'delivery',
    detailsCategory: 'all',
    arrRelayIds: [],
    ecoUserIds: [],
  });

  // INFOS : FETCH UNMUTABLE DATAS
  useEffect(() => {
    const token = Cookies.get('jwt');

    // INFOS : FETCH IDF CITIES
    axiosGetRequest('/idfCities').then((res) => {
      const idfCitiesFormat = autocompleteGroupedFormater(res.data.data.cities, 'name');
      setState((prev) => ({
        ...prev,
        idfCities: [...idfCitiesFormat],
      }));
    });

    // INFOS : FETCH RELAY POINTS
    axiosGetRequest('/relayPoints', token)
      .then((res) => {
        const dataFormat = autocompleteGroupedFormater(res.data.data.relayPoints, 'name');

        // # FORMAT RELAYS
        const relaysFormat = dataFormat.filter(
          (el) => el.type === 'relay' && el.name !== 'Bio Culture'
        );

        // # FORMAT ENTREPRISES
        const entreprisesFormat = dataFormat.filter(
          (el) => el.type === 'entreprise' && el.name !== 'Bio Culture'
        );

        // # FORMAT CONCIERGERIES
        const conciergeriesFormat = dataFormat.filter(
          (el) => el.type === 'conciergerie' && el.name !== 'Bio Culture'
        );

        setState((prev) => ({
          ...prev,
          relayPoints: [...dataFormat],
          relays: [...relaysFormat],
          conciergeries: [...conciergeriesFormat],
          entreprises: [...entreprisesFormat],
        }));
      })
      .catch((err) => console.log(err));

    // INFOS : FETCH ECO USERS
    axiosGetRequest('/users/?role=eco', token)
      .then((res) => {
        const { users } = res.data.data;
        const usersFormat = autocompleteGroupedFormater(users, 'lastname');
        setState((prev) => ({
          ...prev,
          ecoUsers: [...usersFormat],
        }));
      })
      .catch((err) => console.log(err));

    // INFOS : FETCH SEGMENTS
    axiosGetRequest('/segments').then((res) => {
      setState((prev) => ({
        ...prev,
        segments: [...res.data.data.segments],
      }));
    });

    const months = [];
    const dateStart = moment();
    const dateEnd = moment().subtract(12, 'month');
    while (dateStart.diff(dateEnd, 'months') >= 0) {
      months.push(dateStart.format('YYYY-MM'));
      dateStart.subtract(1, 'month');
    }

    setState((prev) => ({
      ...prev,
      last12months: [...months],
    }));

    setFilter((prev) => ({
      ...prev,
      month: moment().format('YYYY-MM'),
    }));
  }, []);

  const handleFetch = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    console.log(filter);

    // INFOS : PREPARE FILTER OBJECT FOR API
    const fields = {};
    fields.advStatus = filter.advStatus;
    fields.period = filter.period;

    if (filter.period === 'dateToDate') {
      fields.from = filter.from;
      fields.to = filter.to;
    }

    if (filter.period === 'month') {
      fields.month = filter.month;
    }

    if (filter.period === 'week') {
      fields.week = filter.week;
    }

    if (filter.period === 'day') {
      fields.day = filter.day;
    }

    if (filter.place !== 'all') {
      fields.place = filter.place;
    }

    if (filter.place === 'home' && filter.zipCode !== '') {
      fields.zipCode = filter.zipCode;
    }

    if (filter.place === 'eco' && filter.ecoUserIds.length > 0) {
      fields.ecoUserIds = filter.ecoUserIds;
    }

    if (
      (filter.place === 'relay' ||
        filter.place === 'conciergerie' ||
        filter.place === 'entreprise') &&
      filter.arrRelayIds.length > 0
    ) {
      fields.arrRelayIds = filter.arrRelayIds;
    } else if (filter.place === 'all' && filter.segments && filter.segments.length > 0) {
      fields.arrRelayIds = filter.segments;
    }

    fields.date = filter.date;

    const token = Cookies.get('jwt');
    await axiosPostRequest('/orders/dashboard', token, { fields })
      .then((res) => {
        const { statistics } = res.data;
        setState((prev) => ({
          ...prev,
          loading: false,
          stats: statistics.stats,
          products: [...statistics.products],
          productsDisplay: [...statistics.products],
          categories: [...statistics.categories],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCity = (value) => {
    if (!value) {
      return setFilter((prev) => ({
        ...prev,
        zipCode: '',
      }));
    }

    setFilter((prev) => ({
      ...prev,
      zipCode: value.zipCode || '',
    }));
  };

  const handleFilter = (event) => {
    event.persist();

    if (event.target.name === 'place') {
      return setFilter((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
        zipCode: '',
        arrRelayIds: [],
        ecoUserIds: [],
      }));
    }

    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAutocompleteMultiple = (value, key) => {
    const valueIds = value.map((val) => val.id);

    setFilter((prev) => ({
      ...prev,
      [key]: valueIds,
    }));
  };

  const handleDetailsCategory = (event) => {
    event.persist();
    const { value } = event.target;
    const { products } = state;

    if (value !== 'all') {
      const filtered = products.filter((product) => product.category === value);
      setState((prev) => ({
        ...prev,
        productsDisplay: [...filtered],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        productsDisplay: [...prev.products],
      }));
    }

    setFilter((prev) => ({
      ...prev,
      detailsCategory: value,
    }));
  };

  return (
    <>
      <div className={classes.root}>
        <h2>Dashboard</h2>
      </div>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Statut</InputLabel>
              <Select
                label="advStatus"
                value={filter.advStatus}
                name="advStatus"
                onChange={handleFilter}
              >
                <MenuItem value="input">En cours de saisie</MenuItem>
                <MenuItem value="validate">Validées</MenuItem>
                <MenuItem value="processed">Traitées</MenuItem>
                <MenuItem value="archived">Archivées</MenuItem>
                <MenuItem value="canceled">Annulées</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Période</InputLabel>
              <Select label="periode" name="period" value={filter.period} onChange={handleFilter}>
                <MenuItem value="dateToDate">Date à date</MenuItem>
                <MenuItem value="month">Mois</MenuItem>
                <MenuItem value="week">Semaine</MenuItem>
                <MenuItem value="day">Jour</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {filter.period === 'dateToDate' && (
            <Grid item>
              <FormControl variant="outlined">
                <TextField
                  label="Période"
                  type="date"
                  value={filter.from}
                  name="from"
                  onChange={handleFilter}
                />
                <TextField type="date" value={filter.to} name="to" onChange={handleFilter} />
              </FormControl>
            </Grid>
          )}
          {filter.period === 'month' && (
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel>Mois</InputLabel>
                <Select label="Mois" name="month" value={filter.month} onChange={handleFilter}>
                  {state.last12months.map((month, idx) => (
                    <MenuItem key={idx} value={month}>
                      {moment(month).format('MMMM YYYY')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {filter.period === 'week' && (
            <Grid item>
              <FormControl variant="outlined">
                <TextField
                  label="Semaine"
                  type="date"
                  value={filter.week}
                  name="week"
                  onChange={handleFilter}
                />
              </FormControl>
            </Grid>
          )}
          {filter.period === 'day' && (
            <Grid item>
              <FormControl variant="outlined">
                <TextField
                  label="Jour"
                  type="date"
                  value={filter.day}
                  name="day"
                  onChange={handleFilter}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Lieu</InputLabel>
              <Select label="place" value={filter.place} name="place" onChange={handleFilter}>
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value="home">Domicile</MenuItem>
                <MenuItem value="relay">Point relais</MenuItem>
                <MenuItem value="conciergerie">Conciergerie</MenuItem>
                <MenuItem value="entreprise">Entreprise</MenuItem>
                <MenuItem value="eco">Eco-acteur</MenuItem>
                <MenuItem value="bioculture">Bio Culture</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {filter.place === 'all' && (
            <Grid item>
              <FormControl variant="outlined">
                <Autocomplete
                  multiple
                  onChange={(event, value) => handleAutocompleteMultiple(value, 'segments')}
                  options={state.segments.sort(
                    (a, b) => -b.name.localeCompare(a.name)
                  )}
                  groupBy={(option) => option.firstLetter}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => `${option.name}`}
                  renderOption={(option) => <>{option.name}</>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Segments"
                      variant="outlined"
                      style={{
                        width: '200px',
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {filter.place === 'relay' && (
            <Grid item>
              <Autocomplete
                multiple
                onChange={(event, value) => handleAutocompleteMultiple(value, 'arrRelayIds')}
                options={state.relays.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => <>{option.name}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Point relais"
                    variant="outlined"
                    style={{
                      width: '200px',
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {filter.place === 'conciergerie' && (
            <Grid item>
              <Autocomplete
                multiple
                onChange={(event, value) => handleAutocompleteMultiple(value, 'arrRelayIds')}
                options={state.conciergeries.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => <>{option.name}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Conciergeries"
                    variant="outlined"
                    style={{
                      width: '200px',
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {filter.place === 'entreprise' && (
            <Grid item>
              <Autocomplete
                multiple
                onChange={(event, value) => handleAutocompleteMultiple(value, 'arrRelayIds')}
                options={state.entreprises.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => <>{option.name}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Entreprises"
                    variant="outlined"
                    style={{
                      width: '200px',
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {filter.place === 'eco' && (
            <Grid item>
              <Autocomplete
                multiple
                onChange={(event, value) => handleAutocompleteMultiple(value, 'ecoUserIds')}
                options={state.ecoUsers.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.firstname} ${option.lastname.toUpperCase()}`}
                renderOption={(option) => (
                  <>{`${option.firstname} ${option.lastname.toUpperCase()}`}</>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nom ou prénom"
                    variant="outlined"
                    style={{
                      width: '200px',
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {filter.place === 'home' && (
            <Grid item>
              <Autocomplete
                options={state.idfCities.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                onChange={(event, value) => handleCity(value)}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.name} - ${option.zipCode}`}
                renderOption={(option) => (
                  <>
                    {option.name} - {option.zipCode}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ville ou Code Postal"
                    variant="outlined"
                    style={{
                      width: '170px',
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Date</InputLabel>
              <Select label="date" value={filter.date} name="date" onChange={handleFilter}>
                <MenuItem value="payment">Date de commande</MenuItem>
                <MenuItem value="delivery">Date de livraison</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleFetch}>
              Charger
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {state.loading !== undefined &&
        (state.loading ? (
          <Skeleton variant="rect" width="100%" height={150} />
        ) : (
          <div>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Total HT sur Payé</TableCell>
                      <TableCell>Total TVA sur Payé</TableCell>
                      <TableCell>Total TTC Payé</TableCell>
                      <TableCell>Total réduction article</TableCell>
                      <TableCell>TVA réduction article</TableCell>
                      <TableCell>Total TTC avant réduc</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>Catalogue (TVA 5,5%)</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva55.totalPaidHt)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva55.totalPaidTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva55.totalPaidTtc)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva55.productDiscount)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva55.productDiscountTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva55.totalTtc)}</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Catalogue (TVA 10%)</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.totalPaidHt)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.totalPaidTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.totalPaidTtc)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.productDiscount)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.productDiscountTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.totalTtc)}</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Catalogue (TVA 20%)</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva20.totalPaidHt)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva20.totalPaidTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva20.totalPaidTtc)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.productDiscount)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva10.productDiscountTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.tva20.totalTtc)}</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}>
                      <TableCell>
                        <strong>Sous total</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.subTotal.totalPaidHt)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.subTotal.totalPaidTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.subTotal.totalPaidTtc)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.subTotal.productDiscount)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.subTotal.productDiscountTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.subTotal.totalTtc)}</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>
                          Bon d'achat €<br />
                          Réduc % (TVA 5,5%)
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>-</strong>
                      </TableCell>
                      <TableCell>
                        <strong>-</strong>
                      </TableCell>
                      <TableCell>
                        <strong>-</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.discount.productDiscount)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.discount.productDiscountTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>-</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Frais de livraison (TVA 20%)</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.deliveryCost.totalPaidHt)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.deliveryCost.totalPaidTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.deliveryCost.totalPaidTtc)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>-</strong>
                      </TableCell>
                      <TableCell>
                        <strong>-</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.deliveryCost.totalTtc)}</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}>
                      <TableCell>
                        <strong>CA Total</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.total.totalPaidHt)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.total.totalPaidTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.total.totalPaidTtc)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.total.productDiscount)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.total.productDiscountTva)}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{currencyFormater(state.stats.total.totalTtc)}</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper className={classes.stats}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableBody>
                        <TableRow>
                          <TableCell>Bon achat utilisé</TableCell>
                          <TableCell>
                            <strong>{state.stats.infos.discounts}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nombre de commandes</TableCell>
                          <TableCell>
                            <strong>{state.stats.infos.orders}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nombre de livraisons</TableCell>
                          <TableCell>
                            <strong>{state.stats.infos.delivery}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nombre d'articles</TableCell>
                          <TableCell>
                            <strong>{state.stats.infos.products}</strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.stats}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableBody>
                        <TableRow className={classes.totalRow}>
                          <TableCell>
                            <strong>Récap. Comptable</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Marchandises</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Livraisons</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total HT</TableCell>
                          <TableCell>
                            <strong>
                              {currencyFormater(
                                state.stats.subTotal.totalPaidHt -
                                  (state.stats.discount.productDiscount -
                                    state.stats.discount.productDiscountTva)
                              )}
                            </strong>
                          </TableCell>
                          <TableCell>
                            <strong>
                              {currencyFormater(state.stats.deliveryCost.totalPaidHt)}
                            </strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Tva 5,5%</TableCell>
                          <TableCell>
                            <strong>
                              {currencyFormater(
                                state.stats.tva55.totalPaidTva -
                                  state.stats.discount.productDiscountTva
                              )}
                            </strong>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow>
                          <TableCell>Total TVA 10%</TableCell>
                          <TableCell>
                            <strong>{currencyFormater(state.stats.tva10.totalPaidTva)}</strong>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow>
                          <TableCell>Total TVA 20%</TableCell>
                          <TableCell>
                            <strong>{currencyFormater(state.stats.tva20.totalPaidTva)}</strong>
                          </TableCell>
                          <TableCell>
                            {currencyFormater(state.stats.deliveryCost.totalPaidTva)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total TTC</TableCell>
                          <TableCell>
                            <strong>
                              {currencyFormater(
                                state.stats.total.totalPaidHt -
                                  (state.stats.discount.productDiscount -
                                    state.stats.discount.productDiscountTva) +
                                  (state.stats.tva55.totalPaidTva -
                                    state.stats.discount.productDiscountTva) +
                                  state.stats.tva10.totalPaidTva +
                                  state.stats.tva20.totalPaidTva
                              )}
                            </strong>
                          </TableCell>
                          <TableCell>
                            {currencyFormater(
                              state.stats.deliveryCost.totalPaidHt +
                                state.stats.deliveryCost.totalPaidTva
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
            <Paper className={classes.paper}>
              <h3>Détails des ventes</h3>
              <div className={classes.marginB}>
                <FormControl variant="outlined">
                  <InputLabel>Catégories</InputLabel>
                  <Select
                    label="Catégories"
                    autoWidth
                    value={filter.detailsCategory}
                    onChange={handleDetailsCategory}
                  >
                    <MenuItem value="all">Toutes</MenuItem>
                    {state.categories.map((category, idx) => (
                      <MenuItem key={idx} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {state.productsDisplay.length !== 0 && (
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.productsDisplay
                        .sort((a, b) => b.quantity - a.quantity)
                        .map((product, idx) => (
                          <Row key={idx} product={product} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </div>
        ))}
    </>
  );
}
