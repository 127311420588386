import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';
import Cookies from 'js-cookie';

// # MOMENT JS
import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import {
  FormControlLabel,
  Switch,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Box,
} from '@material-ui/core';
import { Skeleton, Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  paper2: {
    padding: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  box: {
    margin: '0 1rem 1rem 0',
    maxWidth: '20%',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControl: {
    minWidth: '170px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiOutlinedInput-root': {
      marginBottom: '1rem',
    },
  },
  marginB: {
    marginBottom: '1rem',
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexSelect: {
    display: 'flex',
    marginBottom: '1rem',
    '& > *': {
      marginRight: '1rem',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',

    '& > div:first-child': {
      marginBottom: '1rem',
    },
  },
  small: {
    width: '60px',
  },
  customProduct: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function TableProducts(props) {
  return (
    <TableContainer component={Paper} className={props.classes.marginB}>
      <Table className={props.classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nom</TableCell>
            <TableCell align="left">Catégorie</TableCell>
            <TableCell align="left">Sous-catégorie</TableCell>
            <TableCell align="left">Prix/u</TableCell>
            <TableCell align="center">Quantité</TableCell>
            <TableCell align="right">Prix/tot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.order.items.map((item, index) => {
            const product = props.products.find((el) => el._id === item.item) || item;
            return (
              <TableRow key={index}>
                <TableCell align="left">{product.name}</TableCell>
                <TableCell align="left">{product.category.name}</TableCell>
                <TableCell align="left">{product.subCategory.name}</TableCell>
                <TableCell align="left">{product.price} €</TableCell>
                <TableCell align="center">
                  <TextField
                    className={props.classes.small}
                    variant="outlined"
                    type="Number"
                    size="small"
                    value={props.order.items[index].quantity}
                    onChange={props.handleUpdateQuantity(index)}
                  />
                </TableCell>
                <TableCell align="right">
                  {Math.round((product.price * item.quantity + Number.EPSILON) * 100) / 100}€
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell align="center">
              <b>{props.order.items.reduce((acc, curr) => acc + curr.quantity, 0)}</b>
            </TableCell>
            <TableCell align="right">
              <b>
                {Math.round(
                  props.order.items.reduce((acc, curr) => {
                    const prod = props.products.find((el) => el._id === curr.item) || curr;
                    return acc + curr.quantity * prod.price;
                  }, 0) * 100
                ) / 100}{' '}
                €
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AddressInfos(props) {
  return (
    <>
      <h2>Récapitulatif :</h2>
      <div>
        <p>
          <b>Type de livraison :</b>{' '}
          {props.deliveryInfos.deliveryType === 'home' ? 'À domicile' : 'En point de livraison'}{' '}
          {props.deliveryInfos.deliveryAddress.title &&
            props.deliveryInfos.deliveryAddress.title !== '' &&
            `(${props.deliveryInfos.deliveryAddress.title})`}{' '}
        </p>
        <p>
          <b>Date de livraison :</b>{' '}
          {moment(props.deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')}
        </p>
        <p>
          <b>Horaire de livraison : </b>
          {props.deliveryInfos.deliverySchedule}
        </p>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <form className={props.classes.form}>
            <h3>Adresse de livraison :</h3>
            <TextField
              id="da-address"
              label="Adresse de livraison"
              type="text"
              variant="outlined"
              name="address"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'delivery')}
              value={props.deliveryInfos.deliveryAddress.address}
            />
            <TextField
              id="da-zipCode"
              label="Code postal"
              type="number"
              variant="outlined"
              name="zipCode"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'delivery')}
              value={props.deliveryInfos.deliveryAddress.zipCode}
            />
            <TextField
              id="da-city"
              label="Ville"
              type="text"
              variant="outlined"
              name="city"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'delivery')}
              value={props.deliveryInfos.deliveryAddress.city}
            />
            <TextField
              id="da-country"
              label="Pays"
              type="text"
              variant="outlined"
              name="country"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'delivery')}
              value={props.deliveryInfos.deliveryAddress.country}
            />
            <TextField
              id="da-complement"
              label="Complément d'adresse"
              type="text"
              variant="outlined"
              multiline
              name="complement"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'delivery')}
              value={props.deliveryInfos.deliveryAddress.complement}
            />
          </form>
        </Grid>
        <Grid item xs={2} className={props.classes.divider}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5}>
          <form className={props.classes.form}>
            <h3>Adresse de facturation :</h3>
            <TextField
              id="df-address"
              label="Adresse de facturation"
              type="text"
              variant="outlined"
              name="address"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'billing')}
              value={props.deliveryInfos.deliveryBillingAddress.address}
            />
            <TextField
              id="df-zipCode"
              label="Code postal"
              type="number"
              variant="outlined"
              name="zipCode"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'billing')}
              value={props.deliveryInfos.deliveryBillingAddress.zipCode}
            />
            <TextField
              id="df-city"
              label="Ville"
              type="text"
              variant="outlined"
              name="city"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'billing')}
              value={props.deliveryInfos.deliveryBillingAddress.city}
            />
            <TextField
              id="df-country"
              label="Pays"
              type="text"
              variant="outlined"
              name="country"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'billing')}
              value={props.deliveryInfos.deliveryBillingAddress.country}
            />
            <TextField
              id="df-complement"
              label="Complément d'adresse"
              type="text"
              variant="outlined"
              multiline
              name="complement"
              onChange={(event) => props.handleChangeDeliveryAddress(event, 'billing')}
              value={props.deliveryInfos.deliveryBillingAddress.complement}
            />
          </form>
        </Grid>
      </Grid>
    </>
  );
}

function ChoiceHome(props) {
  return (
    <div className={props.classes.flexSelect}>
      <FormControl variant="outlined" className={props.classes.formControl}>
        <InputLabel id="select-type">Adresses</InputLabel>
        <Select
          autoWidth
          label="Adresses"
          defaultValue=""
          onChange={(e) => props.handleChangeAddress(e.target.value)}
        >
          {props.length > 0 ? (
            props.address.map((el, index) =>
              el.isMain ? (
                <MenuItem
                  key={index}
                  value={el._id}
                >{`(Principale) ${el.street} ${el.city} ${el.zipCode}`}</MenuItem>
              ) : (
                <MenuItem
                  key={index}
                  value={el._id}
                >{`${el.street} ${el.city} ${el.zipCode}`}</MenuItem>
              )
            )
          ) : (
            <MenuItem>Aucune adresse</MenuItem>
          )}
        </Select>
      </FormControl>
      {props.zipCode ? (
        props.force ? (
          <div className={props.classes.column}>
            <TextField
              id="date"
              label="Date de livraison"
              type="date"
              variant="outlined"
              className={props.classes.textField}
              onChange={(e) => props.handleChangeDeliveryDay(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Horaire de livraison"
              type="Text"
              variant="outlined"
              defaultValue="9H00 - 17H30"
              className={props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => props.handleChangeDeliverySchedule(e.target.value)}
            />
          </div>
        ) : (
          <FormControl variant="outlined" className={props.classes.formControl}>
            <InputLabel id="select-type">Livraison le :</InputLabel>
            <Select
              autoWidth
              label="Livraison le"
              defaultValue=""
              onChange={(e) => props.handleChangeDeliveryDate(e.target.value)}
            >
              {props._length > 0 ? (
                props.matchingDates.map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Aucune date</MenuItem>
              )}
            </Select>
          </FormControl>
        )
      ) : null}
      <FormControlLabel
        control={<Switch checked={props.force} />}
        onChange={props.handleChangeForce}
        label="Forcer la date"
      />
    </div>
  );
}

function ChoiceBioculture(props) {
  return (
    <div className={props.classes.flexSelect}>
      <form className={props.classes.container} noValidate>
        <TextField
          id="date"
          label="Date de livraison"
          type="date"
          variant="outlined"
          className={props.classes.textField}
          defaultValue={props.deliveryDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            const date = e.target.value;
            props.setState((prev) => ({
              ...prev,
              order: {
                ...prev.order,
                deliveryInfos: {
                  ...prev.order.deliveryInfos,
                  deliveryDate: date,
                },
              },
            }));
          }}
        />
      </form>
    </div>
  );
}

function ChoiceRelay(props) {
  return (
    <div className={props.classes.flexSelect}>
      {props.state.relayPoints.length > 0 && (
        <Autocomplete
          onChange={(e, newElem) => newElem && props.handleChangeRelay(newElem._id)}
          options={props.state.searchRelay.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          autoHighlight
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) =>
            `${option.name} (${option.city.name} - ${option.city.zipCode})`
          }
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Point de livraison" variant="outlined" />
          )}
        />
      )}
      {!props.state.force ? (
        <FormControl variant="outlined" className={props.classes.formControl}>
          <InputLabel id="select-type">Livraison le :</InputLabel>
          <Select
            autoWidth
            label="Livraison le :"
            defaultValue=""
            onChange={(e) => props.handleChangeDeliveryDate(e.target.value)}
          >
            {props.state.matchingDates.length > 0 ? (
              props.state.matchingDates.map((el, index) => (
                <MenuItem key={index} value={el}>
                  {el}
                </MenuItem>
              ))
            ) : (
              <MenuItem>Choisissez un point de livraison</MenuItem>
            )}
          </Select>
        </FormControl>
      ) : props.state.relay === '' ? (
        <p>Sélectionnez un point de livraison</p>
      ) : (
        <div className={props.classes.column}>
          <TextField
            id="date"
            label="Date de livraison"
            type="date"
            variant="outlined"
            className={props.classes.textField}
            defaultValue={props.state.order.deliveryInfos.deliveryDate}
            onChange={(e) => props.handleChangeDeliveryDay(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Horaire de livraison"
            type="Text"
            variant="outlined"
            defaultValue="9H00 - 17H30"
            className={props.classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => props.handleChangeDeliverySchedule(e.target.value)}
          />
        </div>
      )}
      <FormControlLabel
        control={<Switch checked={props.state.force} />}
        onChange={props.handleChangeForce}
        label="Forcer la date"
        labelPlacement="bottom"
      />
    </div>
  );
}

function SelectDeliveryType(props) {
  return (
    <FormControl variant="outlined" className={props.formControl}>
      <InputLabel id="select-type">Type de livraison</InputLabel>
      <Select
        labelId="select-type"
        id="select-type"
        value={props.deliveryType}
        onChange={(e) => props.handleChangeDeliveryType(e.target.value)}
        autoWidth
        label="Type de livraison"
      >
        <MenuItem value="home">À domicile</MenuItem>
        <MenuItem value="relayPoint">Point de livraison</MenuItem>
        <MenuItem value="bioculture">Chez Bioculture</MenuItem>
      </Select>
    </FormControl>
  );
}

function AddProduct(props) {
  return (
    <Grid className={props.classes.box} item xs={3}>
      <Paper className={props.classes.paper2}>
        <h4>{props.datas.category.name}</h4>
        <FormControl variant="outlined" className={props.classes.marginB}>
          <InputLabel>Produits</InputLabel>
          <Select
            className={props.classes.marginB}
            native
            onChange={(e) => {
              const element = e.target.value;
              props.setState((prev) => ({
                ...prev,
                selector: { ...prev.selector, [props.category.name]: element },
              }));
            }}
            label="Produits"
          >
            <option value="" />
            {props.datas.subcategories
              .filter((sub) => sub.category.id === props.datas.category.id)
              .map((sub, index) => (
                <optgroup key={index} label={sub.name}>
                  {props.datas.elements
                    .filter((el) => el.subCategory._id === sub._id)
                    .map((el, index) => (
                      <option key={index} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                </optgroup>
              ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => props.handleAddProduct(props.category.name)}
          >
            Ajouter
          </Button>
        </FormControl>
      </Paper>
    </Grid>
  );
}

export default function UserCreateOrder() {
  // # SETUP
  moment.locale('fr');

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const classes = useStyles();

  const [state, setState] = useState({
    fetchLastOrder: {
      bool: false,
      lastOrder: {},
    },
    loading: true,
    force: false,
    user: {
      address: [],
    },
    categories: [],
    subcategories: [],
    products: [],
    baskets: [],
    deliveryCities: [],
    relayPoints: [],
    relay: '',
    searchRelay: [],
    order: {
      owner: id,
      items: [],
      advStatus: 'validate',
      status: 'waiting',
      deliveryInfos: {
        deliveryAddress: {
          title: '',
          address: '',
          zipCode: '',
          city: '',
          country: '',
          complement: '',
        },
        deliveryBillingAddress: {
          address: '',
          zipCode: '',
          city: '',
          country: '',
          complement: '',
        },
        deliveryType: '',
        deliverySubType: '',
        deliveryId: '',
        deliveryDate: '',
        deliverySchedule: '',
      },
    },
    customProduct: {
      name: 'Custom',
      reference: 'Custom',
      category: {
        name: 'Custom',
      },
      subCategory: {
        name: 'Custom',
      },
      quantity: 1,
      price: 10,
      tvaRate: '5,5',
      size: 'M',
    },
    selector: {},
    schedules: [],
    matchingDates: [],
  });

  const getMatchingDates = (address, type) => {
    const start = moment();
    const end = moment().add(2, 'weeks');

    const now = start.clone();
    const dates = [];

    while (now.isSameOrBefore(end)) {
      dates.push(now.format('dddd Do MMMM YYYY'));
      now.add(1, 'days');
    }

    const matchingDates = [];
    const iterator = type === 'home' ? address.infos : address.schedules;

    for (const el of dates) {
      const day = el.split(' ')[0];

      for (const schedule of iterator) {
        if (schedule.day.toLowerCase() === day) {
          const fullSchedule = el.concat(' ', `(${schedule.startTime} - ${schedule.endTime})`);
          matchingDates.push(fullSchedule);
        }
      }
    }

    return matchingDates;
  };

  const handleChangeAddress = useCallback(
    (id) => {
      const address = state.user.address.find((adr) => adr._id === id);
      const delivery = state.deliveryCities.find((el) => el.zipCode === address.zipCode);
      const matchingDates = getMatchingDates(delivery, 'home');

      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryAddress: {
              address: address.street,
              zipCode: address.zipCode,
              city: address.city,
              country: 'France',
              complement: address.comment,
            },
            deliveryBillingAddress: {
              address: address.street,
              zipCode: address.zipCode,
              city: address.city,
              country: 'France',
              complement: address.comment,
            },
          },
        },
        matchingDates,
      }));

      if (matchingDates.length > 0) setSchedules(matchingDates[0]);
    },
    [state.deliveryCities, state.user.address]
  );

  const handleChangeRelay = useCallback(
    (id) => {
      const relaySelected = state.relayPoints.find((el) => el._id === id);
      const matchingDates = getMatchingDates(relaySelected, 'relay');

      setState((prev) => ({
        ...prev,
        relay: relaySelected._id,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliverySubType: relaySelected.type,
            deliveryId: relaySelected.id,
            deliverySchedule: '',
            deliveryAddress: {
              title: relaySelected.name,
              address: relaySelected.address.address,
              zipCode: relaySelected.city.zipCode,
              city: relaySelected.city.name,
              country: 'France',
              complement: relaySelected.address.complement,
            },
          },
        },
        matchingDates,
      }));

      if (matchingDates.length > 0) setSchedules(matchingDates[0]);
    },
    [state.relayPoints]
  );

  useEffect(() => {
    const { lastOrder, bool } = state.fetchLastOrder;

    if (bool) {
      if (lastOrder.deliveryInfos.deliveryType === 'home') {
        const matchingAddress = state.user.address.find(
          (adr) =>
            adr.street === lastOrder.deliveryInfos.deliveryAddress.address &&
            adr.zipCode === lastOrder.deliveryInfos.deliveryAddress.zipCode
        );
        if (matchingAddress) handleChangeAddress(matchingAddress._id);
      }

      if (lastOrder.deliveryInfos.deliveryType === 'relayPoint') {
        handleChangeRelay(lastOrder.deliveryInfos.deliveryId);
      }
    }
  }, [handleChangeAddress, handleChangeRelay, state.fetchLastOrder, state.user.address]);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('jwt');

      // # FETCH USER INFOS
      const user = await axios.get(`${process.env.REACT_APP_API}/users/${id}`);

      // # FETCH ALL CATEGORIES
      const categories = await axios.get(`${process.env.REACT_APP_API}/categories`);

      // # FETCH ALL SUB CATEGORIES
      const subcategories = await axios.get(`${process.env.REACT_APP_API}/subcategories`);

      // # FETCH ALL PRODUCTS
      const products = await axios.get(`${process.env.REACT_APP_API}/products/?isActive=true`);

      // # FETCH ALL BASKETS
      const baskets = await axios.get(`${process.env.REACT_APP_API}/baskets/?isActive=true`);

      // # FETCH ALL DELIVERY CITIES
      const deliveryCities = await axios.get(`${process.env.REACT_APP_API}/deliveryCities`);

      // # FETCH ALL RELAY POINTS
      const relayPoints = await axios
        .get(`${process.env.REACT_APP_API}/relayPoints/?isActive=true`)
        .then((res) =>
          res.data.data.relayPoints.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
              firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              ...option,
            };
          })
        );

      // # FETCH LAST ORDER
      const lastOrder = await axios
        .get(`${process.env.REACT_APP_API}/orders/lastOrder/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.lastOrder);

      setState((prev) => ({
        ...prev,
        user: { ...user.data.data.user },
        categories: [...categories.data.data.categories],
        subcategories: [...subcategories.data.data.subCategories],
        products: [...products.data.data.products],
        baskets: [...baskets.data.data.baskets],
        deliveryCities: [...deliveryCities.data.data.deliveryCity],
        relayPoints: [...relayPoints],
        searchRelay: [...relayPoints],
        loading: false,
      }));

      for (const el of categories.data.data.categories) {
        setState((prev) => ({
          ...prev,
          selector: {
            ...prev.selector,
            [el.name]: '',
          },
        }));
      }

      if (lastOrder.length > 0) {
        const lstOrder = lastOrder[0];
        const {
          deliveryAddress,
          deliveryBillingAddress,
          deliveryType,
          deliverySubType,
          deliveryId,
        } = lstOrder.deliveryInfos;

        setState((prev) => ({
          ...prev,
          order: {
            ...prev.order,
            deliveryInfos: {
              ...prev.order.deliveryInfos,
              deliveryAddress,
              deliveryBillingAddress,
              deliveryType,
              deliverySubType,
              deliveryId,
            },
          },
          fetchLastOrder: {
            bool: true,
            lastOrder: lstOrder,
          },
        }));
      }
    };

    fetchData();
  }, [id]);

  // # PREPARE PROPS ELEMENTS FOR ADDPRODUCT COMPONENT
  const setupAddproduct = (category) => {
    const { id, slug } = category;

    const formatedDatas = {};

    if (slug === 'les-paniers-bio') {
      formatedDatas.type = 'basket';
      formatedDatas.category = category;
      formatedDatas.subcategories = state.subcategories.filter(
        (subcat) => subcat.category.id === id
      );
      formatedDatas.elements = state.baskets;
    } else {
      formatedDatas.type = 'product';
      formatedDatas.category = category;
      formatedDatas.subcategories = state.subcategories.filter(
        (subcat) => subcat.category.id === id
      );
      formatedDatas.elements = state.products.filter((product) => product.category.id === id);
    }

    return formatedDatas;
  };

  const handleChangeForce = () => {
    setState((prev) => ({
      ...prev,
      force: !prev.force,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          deliverySchedule: '9H00 - 17H30',
        },
      },
    }));
  };

  const handleAddProduct = (name) => {
    const elem = state.selector[name];
    const matchingProduct = state.order.items.find((el) => el.item === elem);

    if (elem) {
      if (matchingProduct) {
        const addItem = {
          item: matchingProduct.item,
          quantity: matchingProduct.quantity + 1,
        };
        const without = state.order.items.filter((el) => el.item !== matchingProduct.item);
        const newItems = [...without, addItem];
        setState((prev) => ({
          ...prev,
          order: {
            ...prev.order,
            items: newItems,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          order: {
            ...prev.order,
            items: [
              ...prev.order.items,
              {
                item: elem,
                quantity: 1,
              },
            ],
          },
        }));
      }
    }
  };

  const handleUpdateQuantity = (index) => (e) => {
    const quantity = e.target.value * 1;
    let items = [...state.order.items];
    items[index].quantity = quantity;

    if (quantity <= 0) items = items.filter((el, idx) => idx !== index);

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        items,
      },
    }));
  };

  const handleChangeDeliveryType = (value) => {
    if (value === 'bioculture') {
      setState((prev) => ({
        ...prev,
        relay: '',
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryType: value,
            deliveryAddress: {
              address: '28 rue de Wolfenbüttel',
              zipCode: '92310',
              city: 'Sèvres',
              country: 'France',
              complement: 'Bioculture',
            },
            deliverySchedule: '14H00-18H00',
          },
        },
        matchingDates: [],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        relay: '',
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryType: value,
            deliverySubType: '',
            deliveryId: '',
            deliveryDate: '',
            deliverySchedule: '',
            deliveryAddress: {
              address: '',
              zipCode: '',
              city: '',
              country: '',
              complement: '',
            },
            deliveryBillingAddress: {
              address: '',
              zipCode: '',
              city: '',
              country: '',
              complement: '',
            },
          },
        },
        matchingDates: [],
      }));
    }
  };

  const handleChangeSearchRelay = (value) => {
    if (value) {
      const search = value.zipCode;

      const relayDisplay = state.relayPoints.filter((el) => el.city.zipCode === search);

      return setState((prev) => ({
        ...prev,
        searchRelay: relayDisplay,
      }));
    }
    return setState((prev) => ({
      ...prev,
      searchRelay: [],
      matchingDates: [],
    }));
  };

  const setSchedules = (value) => {
    const date = moment(value.split('(')[0].trim(), 'dddd Do MMMM YYYY', 'fr').format('YYYY-MM-DD');

    const schedule = value.substring(0, value.length - 1).split('(')[1];

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          deliveryDate: date,
          deliverySchedule: schedule,
        },
      },
    }));
  };

  const handleSelectRelay = () => {
    const relay = state.relayPoints.find((el) => el._id === state.relay);

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          deliveryAddress: {
            title: relay.name,
            address: relay.address.address,
            zipCode: relay.city.zipCode,
            city: relay.city.name,
            country: relay.address.country,
            complement: relay.address.complement,
          },
        },
      },
    }));
  };

  const handleChangeDeliveryDate = (value) => {
    setSchedules(value);
  };

  const handleChangeDeliveryDay = (value) => {
    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          deliveryDate: value,
        },
      },
    }));
  };

  const handleChangeDeliverySchedule = (value) => {
    const schedule = value.trim().toUpperCase();
    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          deliverySchedule: schedule,
        },
      },
    }));
  };

  const handleChangeDeliveryAddress = (event, type) => {
    event.persist();
    const { value, name } = event.target;

    if (type === 'delivery') {
      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryAddress: {
              ...prev.order.deliveryInfos.deliveryAddress,
              [name]: value,
            },
          },
        },
      }));
    }

    if (type === 'billing') {
      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryBillingAddress: {
              ...prev.order.deliveryInfos.deliveryBillingAddress,
              [name]: value,
            },
          },
        },
      }));
    }
  };

  const handleCreateOrder = async () => {
    const token = Cookies.get('jwt');

    // TODO : NEED UPDATE -> ADD TYPE TO ITEM FOR API
    const products = [...state.baskets, ...state.products];
    const order = {
      ...state.order,
      items: state.order.items.map((item) => ({
        ...item,
        type: item.item ? products.find((product) => product._id === item.item).type : 'custom',
      })),
    };

    await axios
      .post(`${process.env.REACT_APP_API}/orders`, order, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        window.location = `/admin/orders/${res.data.data.order._id}`;
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.message}`, {
          variant: 'error',
        });
      });
  };

  const handleCustomProduct = () => {
    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        items: [state.customProduct, ...prev.order.items],
      },
    }));
  };

  const handleChangeCustom = (name, value) => {
    setState((prev) => ({
      ...prev,
      customProduct: {
        ...prev.customProduct,
        [name]: value,
      },
    }));
  };

  const handleChangeStatus = (event) => {
    const { value, name } = event.target;

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state.loading ? (
            <Skeleton variant="text" width={500} height={50} animation="wave" />
          ) : (
            <div className={classes.top}>
              <h1>Création de commande pour {state.user.fullname}</h1>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Ajouter des produits</h3>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {state.loading ? (
                <Skeleton variant="rect" height={300} animation="wave" />
              ) : (
                state.categories
                  .sort((a, b) => a.position - b.position)
                  .map((cat, index) => (
                    <AddProduct
                      classes={classes}
                      key={index}
                      datas={setupAddproduct(cat)}
                      category={cat}
                      subcategories={state.subcategories}
                      state={state}
                      setState={setState}
                      handleAddProduct={handleAddProduct}
                    />
                  ))
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Ajouter un produit hors catalogue</h3>
            <div className={classes.customProduct}>
              <TextField
                variant="outlined"
                type="Text"
                label="Nom"
                defaultValue={state.customProduct.name}
                name="name"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <TextField
                variant="outlined"
                type="Number"
                InputProps={{ inputProps: { min: 1 } }}
                label="Quantité"
                defaultValue={state.customProduct.quantity}
                name="quantity"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value * 1)}
              />
              <TextField
                variant="outlined"
                type="Number"
                label="Prix"
                defaultValue={state.customProduct.price}
                name="price"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <TextField
                variant="outlined"
                type="Text"
                label="Taux TVA"
                defaultValue={state.customProduct.tvaRate}
                name="tvaRate"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <FormControl variant="outlined">
                <InputLabel id="select-type">Taille</InputLabel>
                <Select
                  autoWidth
                  label="Taille"
                  value={state.customProduct.size}
                  name="size"
                  onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
                >
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="SOLO">Solo</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleCustomProduct}>
                Ajouter
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {state.loading ? (
            <Skeleton variant="rect" height={300} animation="wave" />
          ) : (
            <Paper className={classes.paper}>
              <h3>Produits de la commande</h3>
              {state.order.items.length > 0 ? (
                <TableProducts
                  classes={classes}
                  order={state.order}
                  products={[...state.products, ...state.baskets]}
                  handleUpdateQuantity={handleUpdateQuantity}
                />
              ) : (
                <p>Aucun produit</p>
              )}
            </Paper>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.marginB}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Informations de livraison</h3>
            <div className={classes.flexSelect}>
              <SelectDeliveryType
                formControl={classes.formControl}
                deliveryType={state.order.deliveryInfos.deliveryType}
                handleChangeDeliveryType={handleChangeDeliveryType}
              />
              {state.order.deliveryInfos.deliveryType ? (
                state.order.deliveryInfos.deliveryType === 'home' ? (
                  <ChoiceHome
                    classes={classes}
                    force={state.force}
                    length={state.user.address.length}
                    address={state.user.address}
                    zipCode={state.order.deliveryInfos.deliveryAddress.zipCode}
                    _length={state.matchingDates.length}
                    matchingDates={state.matchingDates}
                    handleChangeForce={handleChangeForce}
                    handleChangeAddress={handleChangeAddress}
                    handleChangeDeliveryDay={handleChangeDeliveryDay}
                    handleChangeDeliveryDate={handleChangeDeliveryDate}
                    handleChangeDeliverySchedule={handleChangeDeliverySchedule}
                  />
                ) : state.order.deliveryInfos.deliveryType === 'relayPoint' ? (
                  <ChoiceRelay
                    classes={classes}
                    state={state}
                    handleChangeForce={handleChangeForce}
                    handleChangeSearchRelay={handleChangeSearchRelay}
                    handleChangeRelay={handleChangeRelay}
                    handleSelectRelay={handleSelectRelay}
                    handleChangeDeliveryDay={handleChangeDeliveryDay}
                    handleChangeDeliveryDate={handleChangeDeliveryDate}
                    handleChangeDeliverySchedule={handleChangeDeliverySchedule}
                  />
                ) : (
                  <ChoiceBioculture
                    classes={classes}
                    deliveryDate={state.order.deliveryInfos.deliveryDate}
                    setState={setState}
                  />
                )
              ) : null}
            </div>
          </Paper>
        </Grid>
      </Grid>

      {state.order.deliveryInfos.deliveryType &&
        state.order.deliveryInfos.deliveryDate &&
        state.order.deliveryInfos.deliverySchedule && (
          <Grid container spacing={3} className={classes.marginB}>
            <Grid item xs={9}>
              <Paper className={classes.paper}>
                <AddressInfos
                  classes={classes}
                  deliveryInfos={state.order.deliveryInfos}
                  handleChangeDeliveryAddress={handleChangeDeliveryAddress}
                />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <h3>Statut de commande :</h3>
                <div className={classes.form}>
                  <FormControl variant="outlined" className={classes.marginB}>
                    <InputLabel>Statut de paiement</InputLabel>
                    <Select
                      native
                      label="Statut de paiement"
                      value={state.order.status}
                      name="status"
                      onChange={(event) => handleChangeStatus(event)}
                    >
                      <option value="waiting">En attente</option>
                      <option value="canceled">Annulé</option>
                      <option value="paid">Payé</option>
                      <option value="refuse">Refusé</option>
                      <option value="refund">Remboursé</option>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.marginB}>
                    <InputLabel>Statut ADV</InputLabel>
                    <Select
                      native
                      label="Statut ADV"
                      value={state.order.advStatus}
                      name="advStatus"
                      onChange={(event) => handleChangeStatus(event)}
                    >
                      <option value="input">En attente</option>
                      <option value="canceled">Annulé</option>
                      <option value="validate">Validé</option>
                      <option value="processed">Traité</option>
                      <option value="archived">Archivé</option>
                    </Select>
                  </FormControl>
                </div>
              </Paper>
            </Grid>
          </Grid>
        )}

      {state.order.items.length > 0 && state.order.deliveryInfos.deliverySchedule !== '' && (
        <Button variant="contained" color="primary" onClick={handleCreateOrder}>
          Créer la commande
        </Button>
      )}
    </>
  );
}
