import React, { useContext } from 'react';

// # CONTEXT

// # MATERIAL UI
import { Button, makeStyles } from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import { CartContext } from '../../../contexts/CartContext';

const useStyles = makeStyles((theme) => ({
  qtyBtn: {
    width: '40px',
    minWidth: '40px',
    height: '40px',
    borderRadius: '40px',

    '& span': {
      margin: 0,
    },
  },
}));

export default function AddButton(props) {
  const classes = useStyles();
  const { decrease, removeProduct } = useContext(CartContext);

  const { product, matchingItem } = props;

  return (
    <Button
      className={classes.qtyBtn}
      variant="outlined"
      startIcon={<Remove />}
      disabled={matchingItem?.quantity === 1}
      onClick={() => (matchingItem?.quantity > 1 ? decrease(matchingItem) : removeProduct(product))}
    />
  );
}
