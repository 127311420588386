import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// # MATERIAL UI
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';

// # COMPONENTS
import CreateTag from '../../../components/Tag/CreateTag';
import TagTable from '../../../components/Tag/TagTable';

// # UTILS
import {
  axiosDeleteRequest,
  axiosGetRequest,
  axiosPatchRequest,
  axiosPostRequest,
} from '../../../utils/axiosRequests';
import string_to_slug from '../../../utils/string_to_slug';
import { createTag, deleteTag, updateTag } from '../../../utils/contenfulRequests';

export default function TagList() {
  const token = Cookies.get('jwt');
  const [state, setState] = useState({
    loading: true,
    tags: [],
  });

  useEffect(() => {
    // # FETCH ALL TAGS
    axiosGetRequest('/tags').then((res) => {
      const { tags } = res.data.data;
      setState((prev) => ({
        ...prev,
        loading: false,
        tags,
      }));
    });
  }, [token]);

  // # CREATE TAG
  const handleCreateTag = async (tag) => {
    tag.slug = `tags-${string_to_slug(tag.name)}`;
    tag.name = `Tags.${tag.name}`;
    return axiosPostRequest('/tags', token, tag)
      .then((res) => {
        const newTag = res.data.data.tag;
        setState((prev) => ({
          ...prev,
          tags: [newTag, ...prev.tags],
        }));
        createTag(newTag);
      })
      .catch((err) => {
        console.log('erreur', err);
      });
  };

  const handleUpdateTag = async (id, slug, idx, newTag) => {
    console.log('elementId', id, 'slug', slug, 'idx', idx, 'tag', newTag);
    return axiosPatchRequest(`/tags/${id}`, token, { name: newTag })
      .then(() => {
        const { tags } = state;
        tags[idx].name = newTag;
        console.log(tags);
        setState((prev) => ({
          ...prev,
          tags,
        }));
        updateTag(slug, newTag);
      })
      .catch((err) => {
        console.log('erreur', err);
      });
  };

  // # DELETE TAG
  const handleDeleteTag = async (tag) => {
    axiosDeleteRequest(`/tags/${tag.id}`, token).then(() => {
      setState((prev) => ({
        ...prev,
        tags: prev.tags.filter((el) => el.id !== tag.id),
      }));
      deleteTag(tag.slug);
    });
  };

  return (
    <>
      <h2>Tous les tags</h2>

      {state.loading && <Skeleton variant="rect" width="100%" height="250px" />}

      {!state.loading && (
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <TagTable
              tags={state.tags}
              handleDeleteTag={handleDeleteTag}
              handleUpdateTag={handleUpdateTag}
            />
          </Grid>
          <Grid item xs={3}>
            <CreateTag handleCreateTag={handleCreateTag} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
