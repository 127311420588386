import React, { useState, useEffect, useContext } from 'react';
import Script from 'react-load-script';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  List,
  FormControl,
  Select,
  InputLabel,
  ListItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  form: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',
  },
  title: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  addAddress: {
    width: '50%',
    margin: '0 auto',
  },
}));

export default function Adresses(props) {
  const [editing, setEditing] = useState('');
  const [manageAddress, setManageAddress] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const classes = useStyles();
  const { setAddress, user } = useContext(UserContext);
  const [state, setState] = useState({
    idfCities: [],
    selectMain: '0',
    selectBill: '0',
    loading: false,
    entreprise: false,
    addressTemp: {},
    addAddress: {},
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      const idfCities = await axios.get(`${process.env.REACT_APP_API}/idfCities`).catch((err) => {
        console.log(err.response);
      });

      const idfCitiesFormat = idfCities.data.data.cities.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });

      setState((prev) => ({
        ...prev,
        idfCities: [...idfCitiesFormat],
        loading: false,
      }));
    };

    fetchData();
  }, []);

  const updateDbAndContext = async (data, message, action) => {
    const token = Cookies.get('jwt');
    await axios
      .patch(
        `${process.env.REACT_APP_API}/users/updateMe`,
        { address: data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAddress(res.data.data.user.address);
        if (action === 0) setEditing('');
        if (action === 1) setAddAddress((prev) => !prev);
        enqueueSnackbar(message, {
          variant: 'success',
        });
        if (action === 3)
          enqueueSnackbar(message, {
            variant: 'success',
          });
      })
      .catch((err) => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  const handleUpdate = (e, key) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      addressTemp: {
        ...prev.addressTemp,
        [key]: e.target.value,
      },
    }));
  };

  const handleScriptLoad = (method) => {
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const components = place.address_components;
      let street;
      let zipCode;
      let city;

      components.forEach((component) => {
        const componentType = component.types[0];

        // eslint-disable-next-line default-case
        switch (componentType) {
          case 'locality':
            city = component.long_name;
            break;
          case 'street_number':
            street = component.long_name;
            break;
          case 'route':
            if (street) {
              street += ` ${component.long_name}`;
            } else {
              street = component.long_name;
            }
            break;
          case 'postal_code':
            zipCode = component.long_name;
            break;
        }

        // eslint-disable-next-line default-case
      });
      switch (method) {
        case 'add':
          setState((prev) => ({
            ...prev,
            addAddress: {
              ...prev.addAddress,
              street,
              city,
              zipCode,
            },
          }));
          break;
        case 'edit':
          setState((prev) => ({
            ...prev,
            addressTemp: {
              ...prev.addressTemp,
              street,
              city,
              zipCode,
            },
          }));
          break;
        default:
      }
    });
  };

  const chooseAddress = (action) => {
    const addressCopy = user.addresses;
    if (action === 'Main') {
      addressCopy.forEach((el, index) => {
        if (index === state.selectMain * 1) {
          return (el.isMain = true);
        }
        return (el.isMain = false);
      });
    } else {
      addressCopy.forEach((el, index) => {
        if (index === state.selectBill * 1) {
          return (el.isBill = true);
        }
        return (el.isBill = false);
      });
    }

    updateDbAndContext(addressCopy, `Votre choix d'adresse principale a été pris en compte`, 2);
  };

  const handleAdd = (e, key) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      addAddress: {
        ...prev.addAddress,
        [key]: e.target.value,
      },
    }));
  };

  const handleSubmit = async (event, action) => {
    event.preventDefault();
    let newAddress;
    if (action === 'Main') {
      newAddress = [...user.addresses.filter((el, index) => el.isMain !== true), state.addressTemp];
    } else {
      newAddress = [...user.addresses.filter((el, index) => el.isBill !== true), state.addressTemp];
    }
    updateDbAndContext(newAddress, `L'adresse a bien été modifié`, 0);
  };

  const deleteAddress = async (indexAddress) => {
    const selectedAddress = user.addresses.filter((el, index) => index === indexAddress);
    if (selectedAddress[0].isMain === true || selectedAddress[0].isBill === true) {
      enqueueSnackbar(
        'Vous ne pouvez pas supprimer cette adresse car elle est soit une adresse de livraison soit une adresse de facturation',
        {
          variant: 'error',
        }
      );
    } else {
      const removeAddress = user.addresses.filter((el, index) => index !== indexAddress);
      updateDbAndContext([...removeAddress], 'Votre adresse a bien été supprimé', 3);
    }
  };

  const handleSubmitAdd = async (event) => {
    event.preventDefault();

    updateDbAndContext(
      [...user.addresses, state.addAddress],
      `Votre adresse a bien été ajoutée`,
      1
    );
  };

  const handleChangeMain = (e) => {
    e.persist();
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      selectMain: value,
    }));
  };

  const handleChangeBill = (e) => {
    e.persist();
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      selectBill: value,
    }));
  };

  const handleEditMain = () => {
    setState((prev) => ({
      ...prev,
      addressTemp: user.addresses.find((el) => el.isMain === true),
    }));
    setEditing('Main');
  };

  const handleEditBill = () => {
    setState((prev) => ({
      ...prev,
      addressTemp: user.addresses.find((el) => el.isBill === true),
    }));
    setEditing('Bill');
  };

  return (
    <section>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
      />
      {user ? (
        manageAddress ? (
          <div>
            <div className={classes.title}>
              <h3>gérer mes adresses</h3>
              <div>
                <Button
                  className="btn-orange"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    setAddAddress(true);
                  }}
                >
                  Ajouter une adresse
                </Button>
                <Button
                  className="btn-orange"
                  onClick={() => {
                    setManageAddress(false);
                  }}
                >
                  Revenir à mes adresses
                </Button>
              </div>
            </div>
            <List>
              {user.addresses.length === 0 && "Vous n'avez aucune adresse"}
              {user.addresses.map((el, index) => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HomeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={el.street} secondary={el.zipCode} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => deleteAddress(index)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {addAddress && (
              <Paper className={classes.paper}>
                <Script
                  url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
                  onLoad={handleScriptLoad('add')}
                />
                <form onSubmit={handleSubmitAdd}>
                  <Grid className={classes.form} container spacing={1}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.entreprise}
                              color="primary"
                              onChange={(e) => {
                                setState((prev) => ({
                                  ...prev,
                                  entreprise: !state.entreprise,
                                }));
                              }}
                            />
                          }
                          label="Entreprise"
                        />
                      </Grid>
                      {state.entreprise ? (
                        <Grid item xs={12}>
                          <TextField
                            className={classes.input}
                            label="Dénomination Sociale"
                            variant="outlined"
                            onChange={(e) => handleAdd(e, 'title')}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <TextField
                            className={classes.input}
                            label="Titre"
                            variant="outlined"
                            onChange={(e) => handleAdd(e, 'title')}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        placeholder="Adresse"
                        label="Adresse"
                        variant="outlined"
                        id="autocomplete"
                        type="text"
                        onChange={(e) => handleAdd(e, 'street')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        placeholder="Commentaire livreur"
                        label="Commentaire livreur"
                        variant="outlined"
                        onChange={(e) => handleAdd(e, 'comment')}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit">Ajouter cette adresse</Button>
                </form>
              </Paper>
            )}
          </div>
        ) : (
          <div className={classes.address}>
            <div className={classes.title}>
              <h3>Mes Adresses</h3>
              <Button
                className="btn-orange"
                onClick={() => {
                  setManageAddress(true);
                }}
              >
                Gérer mes adresses
              </Button>
            </div>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <h2>Adresse de Livraison</h2>
                {editing === 'Main' ? (
                  <div>
                    <Paper className={classes.paper}>
                      <Script
                        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
                        onLoad={handleScriptLoad('edit')}
                      />
                      <form onSubmit={(e) => handleSubmit(e, 'Main')}>
                        <Grid className={classes.form} container spacing={1}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={state.entreprise}
                                  color="primary"
                                  onChange={(e) => {
                                    setState((prev) => ({
                                      ...prev,
                                      entreprise: !state.entreprise,
                                    }));
                                  }}
                                />
                              }
                              label="Entreprise"
                            />
                          </Grid>
                          {state.entreprise ? (
                            <Grid item xs={12}>
                              <TextField
                                className={classes.input}
                                defaultValue={user.addresses.find((el) => el.isMain === true).title}
                                label="Dénomination Sociale"
                                variant="outlined"
                                onChange={(e) => handleUpdate(e, 'title')}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <TextField
                                className={classes.input}
                                defaultValue={user.addresses.find((el) => el.isMain === true).title}
                                label="Titre"
                                variant="outlined"
                                onChange={(e) => handleUpdate(e, 'title')}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              className={classes.input}
                              defaultValue={user.addresses.find((el) => el.isMain === true).street}
                              label="Adresse"
                              variant="outlined"
                              id="autocomplete"
                              onChange={(e) => handleUpdate(e, 'street')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              className={classes.input}
                              defaultValue={user.addresses.find((el) => el.isMain === true).comment}
                              label="Commentaire livreur"
                              variant="outlined"
                              onChange={(e) => handleUpdate(e, 'comment')}
                            />
                          </Grid>
                        </Grid>
                        <Button className="btn-orange" type="submit">
                          Enregistrer
                        </Button>
                      </form>
                    </Paper>
                  </div>
                ) : (
                  <div>
                    <Grid spacing={6}>
                      <Grid item xs={12}>
                        {user.addresses.length > 0 && (
                          <div>
                            <p>
                              {user.addresses.find((el) => el.isMain === true).title
                                ? user.addresses.find((el) => el.isMain === true).title
                                : null}
                            </p>
                            <p>{user.addresses.find((el) => el.isMain === true).street}</p>
                            <p>
                              {user.addresses.find((el) => el.isMain === true).zipCode}{' '}
                              {user.addresses.find((el) => el.isMain === true).city}
                            </p>
                            <p>{user.addresses.find((el) => el.isMain === true).comment}</p>
                            <Button className="btn-orange" onClick={handleEditMain}>
                              Modifier cette adresse
                            </Button>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-native-simple">Adresse</InputLabel>
                          <Select
                            native
                            label="Adresse"
                            inputProps={{
                              name: 'adresse',
                              id: 'outlined-age-native-simple',
                            }}
                            onChange={(e) => handleChangeMain(e)}
                            defaultValue={state.selectBill * 1}
                          >
                            {user.addresses.length > 0 ? (
                              user.addresses.map((el, index) => (
                                <option value={index}>{el.street}</option>
                              ))
                            ) : (
                              <option value="">Vous n'avez pas d'adresse</option>
                            )}
                          </Select>
                          {user.addresses.length > 0 && (
                            <Button
                              className={`btn-orange ${classes.button}`}
                              onClick={() => chooseAddress('Main')}
                            >
                              Choisir cette adresse
                            </Button>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <h2>Adresse de Facturation</h2>
                {editing === 'Bill' ? (
                  <Paper className={classes.paper}>
                    <Script
                      url={`https://maps.googleapis.com/maps/api/js?key=${
                        process.env.REACT_APP_G_PLACES_KEY ??
                        'AIzaSyCtrVLcldYH4lrHR9-ixQxXY7DdE8HB12E'
                      }&libraries=places`}
                      onLoad={handleScriptLoad('edit')}
                    />
                    <form onSubmit={(e) => handleSubmit(e, 'Bill')}>
                      <Grid className={classes.form} container spacing={1}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.entreprise}
                                color="primary"
                                onChange={(e) => {
                                  setState((prev) => ({
                                    ...prev,
                                    entreprise: !state.entreprise,
                                  }));
                                }}
                              />
                            }
                            label="Entreprise"
                          />
                        </Grid>
                        {state.entreprise ? (
                          <Grid item xs={12}>
                            <TextField
                              className={classes.input}
                              defaultValue={user.addresses.find((el) => el.isBill === true).title}
                              label="Dénomination Sociale"
                              variant="outlined"
                              onChange={(e) => handleUpdate(e, 'title')}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              className={classes.input}
                              defaultValue={user.addresses.find((el) => el.isBill === true).title}
                              label="Titre"
                              variant="outlined"
                              onBlur={(e) => handleUpdate(e, 'title')}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <TextField
                            className={classes.input}
                            defaultValue={user.addresses.find((el) => el.isBill === true).street}
                            label="Adresse"
                            variant="outlined"
                            id="autocomplete"
                            onChange={(e) => handleUpdate(e, 'street')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.input}
                            defaultValue={user.addresses.find((el) => el.isBill === true).comment}
                            label="Commentaire livreur"
                            variant="outlined"
                            onChange={(e) => handleUpdate(e, 'comment')}
                          />
                        </Grid>
                      </Grid>
                      <Button className="btn-orange" type="submit">
                        Enregistrer
                      </Button>
                    </form>
                  </Paper>
                ) : (
                  <div>
                    <Grid spacing={3}>
                      <Grid item xs={12}>
                        {user.addresses.length > 0 && (
                          <div>
                            <p>
                              {user.addresses.find((el) => el.isBill === true).title
                                ? user.addresses.find((el) => el.isBill === true).title
                                : null}
                            </p>
                            <p>{user.addresses.find((el) => el.isBill === true).street}</p>
                            <p>
                              {user.addresses.find((el) => el.isBill === true).zipCode}{' '}
                              {user.addresses.find((el) => el.isBill === true).city}
                            </p>
                            <p>{user.addresses.find((el) => el.isBill === true).comment}</p>
                            <Button className="btn-orange" onClick={handleEditBill}>
                              Modifier cette adresse
                            </Button>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-native-simple">Adresse</InputLabel>
                          <Select
                            native
                            label="Adresse"
                            inputProps={{
                              name: 'adresse',
                              id: 'outlined-age-native-simple',
                            }}
                            onChange={(e) => handleChangeBill(e)}
                            defaultValue={state.selectBill * 1}
                          >
                            {user.addresses.length > 0 ? (
                              user.addresses.map((el, index) => (
                                <option value={index}>{el.street}</option>
                              ))
                            ) : (
                              <option value="">Vous n'avez pas d'adresse</option>
                            )}
                          </Select>
                          {user.addresses.length > 0 && (
                            <Button
                              onClick={() => chooseAddress('Bill')}
                              className={`btn-orange ${classes.button}`}
                            >
                              Choisir cette adresse
                            </Button>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )
      ) : null}
    </section>
  );
}
