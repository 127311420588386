import React, { useState, Fragment } from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  IconButton,
  Collapse,
  Box,
  Typography,
  Chip,
  Switch,
} from '@material-ui/core';

// # UTILS
import moment from 'moment';
import currencyFormater from '../../utils/currencyFormater';

// # MOMENT JS
moment.locale();

const columns = [
  { id: 'details', label: '', minWidth: 50, align: 'left' },
  { id: 'identity', label: 'ID', minWidth: 50, align: 'left' },
  { id: 'image', label: 'Image', minWidth: 70, align: 'center' },
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  { id: 'subCategory', label: 'Sous-rubrique', minWidth: 100, align: 'left' },
  {
    id: 'price',
    label: 'Prix',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'stock',
    label: 'Stock',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'created',
    label: 'Date de création',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  delivery: {
    color: '#f05c33',
  },
  chips: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',

    '& > div': {
      margin: '5px 0',
    },
  },
});

function Row(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { basket, idx, handleSwitchActive } = props;

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Link href={`/admin/baskets/${basket.id}`}>{basket.id.slice(-5)}</Link>
        </TableCell>
        <TableCell align="center">
          <img
            src={`${process.env.REACT_APP_AWS}/${basket.photo}`}
            alt={`${basket.reference}`}
            width="70px"
          />
        </TableCell>
        <TableCell align="left">{basket.name}</TableCell>
        <TableCell align="left">
          <Link href="#">{basket.subCategory.name}</Link>
        </TableCell>
        <TableCell align="center">{currencyFormater(basket.price)}</TableCell>
        <TableCell align="center">{basket.stock}</TableCell>
        <TableCell align="right">{moment(basket.createdAt).format('LLL')}</TableCell>
        <TableCell>
          <Switch
            checked={basket.isActive}
            onChange={() => handleSwitchActive(basket.id, idx)}
            color={basket.isActive ? 'primary' : 'secondary'}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" component="div">
                <b>Produits :</b>
              </Typography>
              <Table size="small" aria-label="products">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Référence</TableCell>
                    <TableCell>Origine</TableCell>
                    <TableCell>Poids</TableCell>
                    <TableCell align="right">Labels</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {basket.products.map((product, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <img
                          src={`${process.env.REACT_APP_AWS}/${product.photo}`}
                          alt={`${product.reference}`}
                          width="70px"
                        />
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.reference}</TableCell>
                      <TableCell>{product.origin}</TableCell>
                      <TableCell>
                        {product.weight.quantity} {product.weight.abbreviation}
                      </TableCell>
                      <TableCell className={classes.chips}>
                        {product.labels.organic && (
                          <Chip size="small" color="primary" label="Bio" />
                        )}
                        {product.labels.wild && (
                          <Chip size="small" color="primary" label="Sauvage" />
                        )}
                        {product.labels.french && (
                          <Chip size="small" color="primary" label="Français" />
                        )}
                        {product.labels.native && (
                          <Chip size="small" color="primary" label="Local" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function BasketTable(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, key) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.baskets.length > 0 ? (
              props.baskets.map((basket, idx) => (
                <Row
                  key={idx}
                  basket={basket}
                  idx={idx}
                  handleSwitchActive={props.handleSwitchActive}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  Aucune commande à cette date
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
