import React from 'react';

// # MATERIAL UI
import { Link, ListItem, ListItemIcon, ListSubheader, List } from '@material-ui/core';
import { Speed, GroupAdd, LocalFlorist, Label, Image} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: '60px',
  },
}));

export default function OthersMenu() {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Divers
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem>
        <ListItemIcon>
          <Speed />
        </ListItemIcon>
        <Link href="/admin/weight">Unités de poids</Link>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <Label />
        </ListItemIcon>
        <Link href="/admin/tags">Tags</Link>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <GroupAdd />
        </ListItemIcon>
        <Link href="/admin/teams">Équipes</Link>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <LocalFlorist />
        </ListItemIcon>
        <Link href="/admin/producers">Producteurs</Link>
      </ListItem>
    </List>
  );
}
