import React from 'react';

// STYLE//
import '../../assets/css/stylementionslegales.css';

export default function MentionLegales() {
  return (
    <div id="mentionslegales">
      <div className="container">
        <h1>mentions légales</h1>

        <div className="text">
          <h4>Directeur de la publication :</h4>
          <p>
            <b>Le site Bio Culture - Les Paniers Bio est édité par Bio Culture SARL.</b>
            <br />
            Siège social :
            <br />
            28 rue de Wolfenbüttel
            <br />
            92310 Sèvres
            <br />
            Siret n° 511 155 822 00022
            <br />
            RCS Nanterre B 511 155 822
          </p>

          <h4>Conception, réalisation et hébergement :</h4>
          <p>
            <b>Logiciel de vente en ligne :</b>
            <br />
            Og'Up Boutique
            <br />
            Og'Up Boutique ® est une marque d'OGAM Sas
            <br /> <span>www.ogam.fr</span>
            <br />
            Siège social :
            <br />
            112, avenue de l'Agent Sarre
            <br />
            92270 BOIS-COLOMBES
            <br />
            Siret n° 798 853 750 00023
            <br />
            RCS Nanterre B 798 853 750
            <br />
            <br />
            <b>Direction artistique :</b>Romain DEPLA
          </p>

          <h4>Propriété intellectuelle :</h4>
          <p>
            L'ensemble de ce site relève des législations Françaises et Internationales sur les
            droits d'auteur et la propriété intellectuelle.
            <br />
            Tous les droits de reproduction sont réservés pour les textes et les photographies de ce
            site.
            <br />
            La reproduction de tout ou partie de ce site sur un support électronique ou autre quel
            qu'il soit, est formellement interdite sauf autorisation écrite de l'auteur,
            conformément à l'article L 122-4 du Code de la Propriété intellectuelle.
            <br />
            Crédit photos et images décoratives : © Shutterstock.
            <br />
            Crédit photos propres à l'entreprise : © Bio Culture.
            <br />
            Crédit textes : © Bio Culture.
          </p>

          <h4>Liens hypertextes :</h4>
          <p>
            Les liens hypertextes mis en place dans le cadre du présent site internet en direction
            d'autres ressources présentes sur le réseau Internet, et notamment vers ses partenaires
            ont fait l'objet d'une autorisation préalable, auprès de l'éditeur.
          </p>

          <h4>Droit de réponse :</h4>
          <p>
            Toute personne citée sur le site peut faire valoir un droit de réponse. Une simple
            demande sera adressée par courrier. Voir la page Contact pour tout autre mode de
            correspondance.
          </p>

          <h4>Politique de confidentialité :</h4>
          <p>
            En aucun cas, les données recueillies sur le site Bio Culture - Les Paniers Bio ne
            seront cédées ou vendues à des tiers. Aucune adresse e-mail ne sera transmise à des
            tiers y compris à nos partenaires sauf à la demande expresse et avec l'accord écrit de
            leurs propriétaires. Les informations recueillies via la page Contact ne servent qu'à la
            mise en relation client immédiate et les informations personnelles recueillies pour
            enrichir le compte utilisateur client ne servent qu'au traitement des commandes et à
            leurs livraisons. Toutes les données personnelles recueillies sont accessibles et
            modifiables via le compte utilisateur client. En cas de difficulté à vous connecter à ce
            compte, à consulter ou à modifier ces informations, n'hésitez par à nous contacter.
          </p>
        </div>
      </div>
    </div>
  );
}
