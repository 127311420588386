import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';

// # MOMENT JS
import moment from 'moment';
import 'moment/locale/fr';

// # CONTEXT
import { AccountCircle, LocalShipping, LocationOn } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { InvitationContext } from '../../contexts/InvitationContext/InvitationContext';

// # UTILS
import { axiosGetRequest } from '../../utils/axiosRequests';

// # CUSTOM COMPONENTS
import ProductCard from '../../components/Product/ProductCard';

// # MATERIAL UI

const useStyles = makeStyles({
  txtInfos: {
    '& > span': {
      display: 'block',
      fontSize: '15px',
    },
  },
  invitationTitle: {
    margin: '3rem 0  1rem 0!important',
  },
  padding: {
    padding: '1rem',
  },
  div: {
    backgroundColor: '#f6f4eb',
    width: '250px',
    margin: '0 auto',
    borderRadius: '4px',
    padding: '1rem',
    '@media (min-width: 600px) and (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: '500px',
    },
  },
});

export default function Products() {
  moment.locale('fr');
  const classes = useStyles();

  const { slug } = useParams();

  const { invitation } = useContext(InvitationContext);

  const [state, setState] = useState({
    loading: true,
    category: {},
    subcategories: [],
    products: [],
  });

  useEffect(() => {
    const type = slug === 'les-paniers-bio' ? 'baskets' : 'products';

    // # GET CATEGORY MATCHING PATH SLUG
    axiosGetRequest(`/categories/?slug=${slug}`)
      .then((res) => {
        const category = res.data.data.categories[0];
        const categoryId = res.data.data.categories[0].id;

        setState((prev) => ({
          ...prev,
          category,
        }));

        // # GET SUB CATEGORIES
        axiosGetRequest(`/subcategories/?category=${categoryId}`)
          .then((res) => {
            const subcategories = res.data.data.subCategories;
            setState((prev) => ({
              ...prev,
              subcategories,
            }));
          })
          .catch((err) => console.log(err));

        // # GET PRODUCTS
        axiosGetRequest(`/${type}/?category=${categoryId}&isEco=true&isActive=true`)
          .then((res) => {
            const products = res.data.data[type];
            setState((prev) => ({
              ...prev,
              products,
            }));
          })
          .catch((err) => console.log(err));
      })
      .finally(() => setState((prev) => ({ ...prev, loading: false })))
      .catch((err) => console.log(err));
  }, [slug]);

  return (
    <section>
      <Grid container>
        <Grid className={classes.padding} item xs={12} md={4}>
          <h4 className={classes.invitationTitle}>
            Invitation à la commande groupée de Fruits et Légumes Bioculture
          </h4>
          <br />
          <div className={classes.div}>
            <p className={classes.txtInfos}>
              <AccountCircle color="secondary" /> <br />
              <b>Organisé par :</b>
              <span>
                {invitation.sender.firstname} {invitation.sender.lastname}
              </span>
            </p>

            <p className={classes.txtInfos}>
              <LocalShipping color="secondary" /> <br />
              <b>Livré le : </b>
              <span>
                {moment(invitation.deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')}
              </span>
              <span>(Entre {invitation.deliveryInfos.deliverySchedule.replace('-', 'et')})</span>
            </p>
            <p className={classes.txtInfos}>
              <LocationOn color="secondary" /> <br />
              <b>À l'adresse :</b>
              <span>{invitation.deliveryInfos.deliveryAddress.address}</span>
              <span>
                {invitation.deliveryInfos.deliveryAddress.zipCode} -{' '}
                {invitation.deliveryInfos.deliveryAddress.city}
              </span>
            </p>
          </div>
        </Grid>
        <Grid className={classes.padding} item xs={12} md={8}>
          {!state.loading &&
            state.subcategories
              .sort((a, b) => a.position - b.position)
              .map((el, index) => {
                const result = state.products.filter((product) => product.subCategory.id === el.id);

                return (
                  <div key={index}>
                    {result.length > 0 && (
                      <div>
                        <h3>{el.name}</h3>
                        <p>{el.description}</p>
                        <Grid container justifyContent="center">
                          {result
                            .sort((a, b) => a.position - b.position)
                            .map((prod, idx) => (
                              <ProductCard key={idx} infos={prod} />
                            ))}
                        </Grid>
                      </div>
                    )}
                  </div>
                );
              })}
        </Grid>
      </Grid>
    </section>
  );
}
