import React, { useState, useContext } from 'react';
import axios from 'axios';

import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';

// # Context

// # MATERIAL UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { UserContext } from '../../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // # CONTEXTS
  const { login } = useContext(UserContext);

  // # STATE HOOKS
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = {
      email,
      password,
    };

    await axios.post(`${process.env.REACT_APP_API}/users/login`, user, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    await axios
      .post(`${process.env.REACT_APP_API}/users/login`, user, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        const response = res.data.data.user;

        const currUser = {
          id: response.id,
          token: res.data.token,
          role: response.role,
          personalData: {
            firstname: response.firstname,
            lastname: response.lastname,
            fullname: response.fullname,
            phoneNumber: response.phoneNumber,
            email: response.email,
            complement: response.complement || '',
            newsLetter: response.newsLetter,
          },
          addresses: response.address,
          edenred: response.edenred
        };

        login({
          ...currUser,
          ecoData: {
            ecoPoints: response.ecoPoints || 0,
            customMessage: response.ecoInvitationSettings?.customMessage || '',
            pickupSchedule: {
              from: response.ecoInvitationSettings?.pickupSchedule.from || '',
              to: response.ecoInvitationSettings?.pickupSchedule.to || '',
            },
            contacts: response.contacts || [],
          },
        });

        localStorage.removeItem('delivery');
        window.location = '/';
      })
      .catch(() => {
        enqueueSnackbar('Email ou mot de passe incorrect', {
          variant: 'error',
        });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Valider
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgotpassword" variant="body2">
                Mot de passe oublié?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                Vous n&apos;avez pas encore de compte
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8} />
    </Container>
  );
}
