import React, { Fragment } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import moment from 'moment';

export default function DateFilter(props) {
  const { state, onChange, filter } = props;

  return (
    <>
      <Grid item>
        <FormControl variant="outlined">
          <InputLabel>Période</InputLabel>
          <Select label="periode" name="period" value={filter.period} onChange={onChange}>
            <MenuItem value="dateToDate">Date à date</MenuItem>
            <MenuItem value="month">Mois</MenuItem>
            <MenuItem value="week">Semaine</MenuItem>
            <MenuItem value="day">Jour</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {filter.period === 'dateToDate' && (
        <Grid item>
          <FormControl variant="outlined">
            <TextField
              label="Période"
              type="date"
              name="from"
              value={state.from}
              onChange={onChange}
            />
            <TextField type="date" name="to" value={state.to} onChange={onChange} />
          </FormControl>
        </Grid>
      )}
      {filter.period === 'month' && (
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel>Mois</InputLabel>
            <Select label="Mois" name="month" value={state.month} onChange={onChange}>
              {state.last12Months.map((month, idx) => (
                <MenuItem key={idx} value={month}>
                  {moment(month).format('MMMM YYYY')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {filter.period === 'week' && (
        <Grid item>
          <FormControl variant="outlined">
            <TextField
              label="Semaine"
              type="date"
              name="week"
              value={state.week}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
      )}
      {filter.period === 'day' && (
        <Grid item>
          <FormControl variant="outlined">
            <TextField
              value={state.today}
              label="Jour"
              type="date"
              name="day"
              onChange={onChange}
            />
          </FormControl>
        </Grid>
      )}
    </>
  );
}
