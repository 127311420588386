import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import moment from 'moment';

// # .ENV VARIABLES
const lyraKey = process.env.REACT_APP_LYRA_KEY;
const lyraShopId = process.env.REACT_APP_LYRA_SHOP_ID;
const appMode = process.env.REACT_APP_MODE;

// INFO : CREATE VADS OBJ FOR LYRA FORM API
export const createVadsObject = async (order, totalToPay, user) => {
  const { address, city, zipCode } = order.deliveryInfos.deliveryAddress;
  const ctx = appMode === 'development' ? 'TEST' : 'PRODUCTION';
  const urlSuccess = `https://${
    appMode === 'development' ? 'demo' : 'www'
  }.panier-bio-paris.fr/success/${order.id}`;
  const totalAmount = Math.round(totalToPay);
  const eligibleAmount = totalAmount >= 2500 ? '2500' : `${totalAmount}`;

  const vads = {
    vads_acquirer_transient_data: JSON.stringify({ CONECS: { eligibleAmount } }),
    vads_action_mode: 'INTERACTIVE',
    vads_amount: `${totalAmount}`,
    vads_ctx_mode: ctx,
    vads_currency: '978',
    vads_cust_address: address,
    vads_cust_city: city,
    vads_cust_country: 'FR',
    vads_cust_email: user.personalData ? user.personalData.email : user.email,
    vads_cust_first_name: user.personalData ? user.personalData.firstname : user.firstname,
    vads_cust_id: user.id,
    vads_cust_last_name: user.personalData ? user.personalData.lastName : user.lastname,
    vads_cust_phone: user.personalData
      ? user.personalData.phoneNumber || ''
      : user.phoneNumber || '',
    vads_cust_zip: zipCode,
    vads_order_id: order.id,
    vads_page_action: 'PAYMENT',
    vads_payment_config: 'SINGLE',
    vads_site_id: lyraShopId,
    vads_trans_date: moment.utc().format('YYYYMMDDHHmmss'),
    vads_trans_id: `${order.id.slice(-6)}`,
    vads_url_success: urlSuccess,
    vads_version: 'V2',
  };

  // * LOOP THROUGH ALL SORTED & VADS FIELDS
  const vadsFields = [];
  Object.keys(vads)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => (key.startsWith('vads_') ? vadsFields.push(vads[key]) : null));

  // * JOIN VADS ARR AND ADD LYRA KEY TO THE END
  const concat = `${vadsFields.join('+')}+${lyraKey}`;

  // * SIGNATURE HASH WITH HMAC SHA256
  const signature = Base64.stringify(hmacSHA256(concat, lyraKey));

  return {
    ...vads,
    signature,
  };
};
