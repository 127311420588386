import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Script from 'react-load-script';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Collapse, Grid, InputAdornment, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Search } from '@material-ui/icons';

import { CartContext } from '../../../contexts/CartContext';
import { UserContext } from '../../../contexts/UserContext/UserContext';

import { axiosGetRequest, axiosPatchRequest } from '../../../utils/axiosRequests';
import Calendar from './Calendar';
import DeliveryBox from './DeliveryBox';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Bitter !important',
    fontSize: '2em !important',
    fontWeight: 400,
    textAlign: 'center',
  },
  autocomplete: {
    marginTop: '1em',
  },
  form: {
    margin: '1em 0',
  },
  input: {
    width: '100%',
  },
  alert: {
    marginTop: '1em',
  },
  divider: {
    border: 'none',
    borderTop: '1px dotted #000',
    color: '#fff',
    backgroundColor: '#fff',
    marginTop: '10px',
    height: '1px',
    width: '100%',
  },
  deliveryTime: {
    borderBottom: '1px solid #000',
    paddingBottom: '35px',
  },
  buttonAdd: {
    marginTop: '1em',
    width: '100%',
  },
  enterpriseDescription: {
    color: theme.palette.secondary.main,
  },
  inputDiscount: {
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    background: '#fff',
    padding: '14px 16px !important',
    minWidth: '235px',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 'calc(100% - 31px)',
    },
  },
  companyDiscountContainer: {
    marginTop: '25px',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column',
    },
  },
  companyDiscount: {
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column',
    },
  },
  apply: {
    paddingLeft: '25px',
    paddingRight: '25px',
    textTransform: 'capitalize',
    fontWeight: 400,
    marginLeft: '-75px',
  },
}));

export default function DeliverySummary() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    deliveryInfos,
    setZipCode,
    setDeliveryAddress,
    setDeliveryBillingAddress,
    setDeliveryInfos,
    setDiscount,
    setEnterpriseDiscountCode,
    companyDiscountCode,
  } = useContext(CartContext);

  const { setAddress, user } = useContext(UserContext);

  const [userAddress, setUserAddress] = useState({
    title: '',
    street: '',
    city: '',
    zipCode: '',
    information: '',
    country: 'France',
  });

  const [displayForm, setDisplayForm] = useState(false);
  const [companyDiscount, setCompanyDiscount] = useState(null);
  const [enterpriseRelay, setEnterpriseRelay] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedRelay, setSelectedRelay] = useState([]);
  const [nearbyRelays, setNearbyRelays] = useState([]);

  const handleAdresseCreation = (e) => {
    e.preventDefault();

    if (!userAddress.zipCode || !userAddress.city) {
      enqueueSnackbar('Adresse invalide', {
        variant: 'error',
      });

      return;
    }

    const form = document.getElementById('formAddress').reset();

    if (user.token) {
      return axiosPatchRequest('/users/updateMe', user.token, {
        address: [...user.addresses, userAddress],
      })
        .then((res) => {
          setAddress(res.data.data.user.address);
          setDisplayForm(false);
          setUserAddress({
            title: '',
            street: '',
            city: '',
            zipCode: '',
            information: '',
            country: 'France',
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
          });
        });
    } else {
      setAddress([...user.addresses, userAddress]);
      setDisplayForm(false);
      setUserAddress({
        title: '',
        street: '',
        city: '',
        zipCode: '',
        information: '',
        country: 'France',
      });
    }
  };

  const applyCompanyDiscount = async (event) => {
    event.preventDefault();

    await axios
      .get(`${process.env.REACT_APP_API}/relayPoints/?code=${companyDiscount}`)
      .then((res) => {
        const relay = res.data.data.relayPoints;

        if (relay.length < 1) {
          enqueueSnackbar('Veuillez renseigner un code entreprise valide', {
            variant: 'error',
          });
        } else {
          setDeliveryAddress({
            deliveryAddress: {
              title: relay[0].name,
              address: relay[0].address.address,
              zipCode: relay[0].city.zipCode,
              city: relay[0].city.name,
              country: relay[0].address.country,
              complement: relay[0].address.complement,
              comment: relay[0].address.comment,
            },
          });

          setDeliveryBillingAddress({
            deliveryBillingAddress: {
              title: relay[0].name,
              address: relay[0].address.address,
              zipCode: relay[0].city.zipCode,
              city: relay[0].city.name,
              country: relay[0].address.country,
              complement: relay[0].address.complement,
              comment: relay[0].address.comment,
            },
          });

          setDeliveryInfos({
            deliveryDate: null,
            deliverySchedule: null,
            depositSchedule: null,
            deliveryType: 'relayPoint',
            deliverySubType: relay[0].type,
            deliveryId: relay[0].id,
          });

          setSelectedRelay(relay[0]);
          setEnterpriseRelay(relay[0]);
          setEnterpriseDiscountCode(companyDiscount);

          if (relay[0].discount && relay[0].discount.value > 0) {
            setDiscount(relay[0].discount);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleDatesChange = (dates) => {
    setSelectedDates(dates);
  };

  const handleRelayChange = (relay) => {
    setSelectedRelay(relay);
  };

  const handleScriptLoad = () => {
    ['delivery-autocomplete', 'autocomplete-add', 'autocomplete-time'].forEach((id) => {
      const options = {
        componentRestrictions: {
          country: 'fr',
        },
        fields: ['address_components'],
      };

      if (id !== 'autocomplete-add') {
        options.types = ['(regions)'];
      }

      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(id),
        options
      );

      autocomplete.addListener('place_changed', async () => {
        const place = autocomplete.getPlace();
        const components = place.address_components;
        let streetName = '';
        let streetNumber = '';
        let city;
        let country;
        let zipCode;

        if (place && components) {
          components.forEach((component) => {
            const componentType = component.types[0];

            switch (componentType) {
              case 'sublocality_level_1':
                if (component.long_name === '16e Arrondissement de Paris') {
                  zipCode = '75116';
                  break;
                }
                break;
              case 'street_number':
                streetNumber = component.long_name;
              case 'route':
                streetName = component.long_name;
              case 'locality':
                city = component.long_name;
                break;
              case 'postal_code':
                zipCode = component.long_name;
                break;
              case 'country':
                country = component.long_name;
              default:
                break;
            }
          });

          if (id === 'delivery-autocomplete') {
            await axiosGetRequest(`/idfcities/?name=${city}`).then((res) => {
              if (!zipCode) {
                zipCode = res.data.data.cities[0].zipCode;
                setZipCode(zipCode);
              } else {
                setZipCode(zipCode);
              }

              axiosGetRequest(`/relayPoints?type=relay&isActive=true`).then((res) => {
                setNearbyRelays(
                  res.data.data.relayPoints.filter((relay) => relay.city.zipCode === zipCode)
                );
              });

              if (!enterpriseRelay || enterpriseRelay !== selectedRelay) {
                setSelectedRelay(null);
              }
            });
          } else if (id === 'autocomplete-add') {
            const street = streetNumber + ' ' + streetName;

            setUserAddress((prev) => ({
              ...prev,
              street: street,
              city: city,
              zipCode: zipCode,
            }));
          } else if (id === 'autocomplete-time') {
            await axiosGetRequest(`/idfcities/?name=${city}`).then((res) => {
              if (!zipCode) {
                zipCode = res.data.data.cities[0].zipCode;
                setZipCode(zipCode);
              } else {
                setZipCode(zipCode);
              }
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    if (deliveryInfos.deliveryAddress.zipCode && !enterpriseRelay) {
      axiosGetRequest(`/deliveryCities/?zipCode=${deliveryInfos.deliveryAddress.zipCode}`).then(
        (res) => {
          if (res.data.data.deliveryCity[0]) {
            setSelectedDates(res.data.data.deliveryCity[0].infos);
          } else {
            setSelectedDates([]);
          }
        }
      );
    }
  }, [deliveryInfos.deliveryAddress.zipCode]);

  useEffect(() => {
    if (companyDiscountCode) {
      axiosGetRequest(`/relayPoints/?code=${companyDiscountCode}`).then((res) => {
        const relay = res.data.data.relayPoints;
        setEnterpriseRelay(relay[0]);
        setSelectedRelay(relay[0]);
      });
    }

    axiosGetRequest(`/relayPoints?type=relay&isActive=true`).then((res) => {
      if (deliveryInfos.deliveryAddress.zipCode) {
        setNearbyRelays(
          res.data.data.relayPoints.filter(
            (relay) => relay.city.zipCode === deliveryInfos.deliveryAddress.zipCode
          )
        );
      }

      if (deliveryInfos.deliveryId) {
        res.data.data.relayPoints.forEach((relay) => {
          if (deliveryInfos.deliveryId === relay.id) {
            setSelectedRelay(relay);
          }
        });
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <h3 className={classes.title}>Livraison</h3>
      <Grid container>
        <Grid item xs={12} md={6}>
          <React.Fragment>
            <Box sx={{ display: 'flex', marginTop: '2em' }}>
              <Box sx={{ textAlign: 'left' }}>Livraison à domicile</Box>
              <Box sx={{ flexGrow: 1, margin: '0 15px' }}>
                <div className={classes.divider}>&nbsp;</div>
              </Box>
            </Box>
            {user.addresses && user.addresses.length < 1 && (
              <React.Fragment>
                {user.addresses.map((address) => (
                  <DeliveryBox
                    item={address}
                    handleRelayChange={handleRelayChange}
                    handleDatesChange={handleDatesChange}
                  />
                ))}
              </React.Fragment>
            )}
            {user.addresses && user.addresses.length > 0 && (
              <React.Fragment>
                {user.addresses.map((address, index) => (
                  <DeliveryBox
                    item={address}
                    index={index}
                    handleRelayChange={handleRelayChange}
                    handleDatesChange={handleDatesChange}
                    condition={true}
                  />
                ))}
              </React.Fragment>
            )}
            {!displayForm && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonAdd}
                onClick={() => setDisplayForm(true)}
              >
                Ajouter une adresse
              </Button>
            )}
            <Collapse in={displayForm === true}>
              <form id="formAddress" className={classes.form} onSubmit={handleAdresseCreation}>
                <Grid className={classes.form} container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.input}
                      placeholder="Titre"
                      label="Titre"
                      variant="outlined"
                      onChange={(e) => {
                        e.persist();
                        setUserAddress((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.input}
                      required
                      placeholder="Adresse"
                      label="Adresse"
                      variant="outlined"
                      id="autocomplete-add"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.input}
                      placeholder="Commentaire livreur"
                      label="Commentaire livreur"
                      variant="outlined"
                      onChange={(e) => {
                        e.persist();
                        setUserAddress((prev) => ({
                          ...prev,
                          information: e.target.value,
                        }));
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="default"
                      size="large"
                      className={classes.input}
                      onClick={() => setDisplayForm(false)}
                    >
                      Annuler
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                      className={classes.input}
                    >
                      Ajouter cette adresse
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Collapse>
          </React.Fragment>
          <React.Fragment>
            <Box>
              <Box sx={{ display: 'flex', marginTop: '2em' }}>
                <Box sx={{ textAlign: 'left' }}>Livraison en entreprise</Box>
                <Box sx={{ flexGrow: 1, margin: '0 15px' }}>
                  <div className={classes.divider}>&nbsp;</div>
                </Box>
              </Box>
              <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                Pour les entreprises partenaires !{' '}
              </span>
            </Box>
            <Box
              sx={{ display: 'flex', textAlign: 'left', minHeight: '45px', lineHeight: '45px' }}
              className={classes.companyDiscountContainer}
            >
              <Box sx={{ display: 'flex' }} className={classes.companyDiscount}>
                <form onSubmit={(e) => applyCompanyDiscount(e)}>
                  <input
                    type="text"
                    className={classes.inputDiscount}
                    placeholder="Saisissez le code"
                    defaultValue={companyDiscountCode}
                    onChange={(e) => {
                      e.persist();
                      setCompanyDiscount(e.target.value);
                    }}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.apply}
                    color="secondary"
                  >
                    OK
                  </Button>
                </form>
              </Box>
            </Box>
            {enterpriseRelay && (
              <React.Fragment>
                <DeliveryBox
                  item={enterpriseRelay}
                  key={enterpriseRelay.id}
                  handleRelayChange={handleRelayChange}
                  handleDatesChange={handleDatesChange}
                  condition={false}
                />
                {enterpriseRelay.description && (
                  <p className={classes.enterpriseDescription}>{enterpriseRelay.description}</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
          <React.Fragment>
            <Box sx={{ display: 'flex', marginTop: '2em' }}>
              <Box sx={{ textAlign: 'left' }}>Livraison en point relais</Box>
              <Box sx={{ flexGrow: 1, margin: '0 15px' }}>
                <div className={classes.divider}>&nbsp;</div>
              </Box>
              {nearbyRelays && nearbyRelays.length > 0 && <Box>({nearbyRelays.length})</Box>}
            </Box>
            <TextField
              className={classes.autocomplete}
              label="Ville, code postal"
              type="text"
              variant="outlined"
              id="delivery-autocomplete"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            {nearbyRelays.map((nearbyRelay) => (
              <DeliveryBox
                item={nearbyRelay}
                key={nearbyRelay.id}
                handleRelayChange={handleRelayChange}
                handleDatesChange={handleDatesChange}
              />
            ))}
            {nearbyRelays.length < 1 &&
              selectedDates.length < 1 &&
              deliveryInfos.deliveryAddress &&
              !deliveryInfos.deliveryAddress.address &&
              deliveryInfos.deliveryAddress.zipCode && (
                <Alert severity="info" className={classes.alert}>
                  Aucun point relais n’est disponible dans votre secteur. N’hésitez pas à nous
                  recommander votre commerçant favoris, nous le contacterons.
                </Alert>
              )}
          </React.Fragment>
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedDates.length >= 1 && <Calendar relay={selectedRelay} dates={selectedDates} />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
