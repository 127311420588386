import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { DeleteOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Divider, Select, MenuItem } from '@material-ui/core';

import { CartContext } from '../../../contexts/CartContext';

import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';
import currencyFormater from '../../../utils/currencyFormater';
import { UserContext } from '../../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#C59F6E',
        borderRadius: '5px',
        color: '#FFFFFF',
        marginTop: '4em',
        width: '100%',
    },
    container: {
        padding: '1em 1.5em',
    },
    divider: {
        background: '#FFFFFF',
        margin: '1em 0',
    },
    select: {
        borderRadius: '5px',
        border: 'none',
        background: '#fff',
        padding: '14px 16px !important',
        marginTop: '4px',
        minWidth: '235px',
        fontSize: '0.825rem',
        height: '40px',
        marginLeft: '25px',
        [theme.breakpoints.between('xs', 'sm')]: {
            marginLeft: 0,
            marginTop: '15px',
        },
    },
    input: {
        borderRadius: '5px',
        border: 'none',
        background: '#fff',
        padding: '14px 16px !important',
        minWidth: '235px',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: 'calc(100% - 31px)',
        },
    },
    discountContainer: {
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
        },
    },
    discount: {
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
        },
    },
    apply: {
        paddingLeft: '25px',
        paddingRight: '25px',
        textTransform: 'capitalize',
        fontWeight: 400,
        marginLeft: '-75px',
    },
    delete: {
        minWidth: '20px',
        color: '#fff',
        padding: 0,
        margin: 0,
        marginTop: '-3px',
        marginLeft: '5px',
    },
    svg: {
        height: '18px',
        width: '18px',
    },
}));

export default function OrderSummary(props) {
    const { user } = useContext(UserContext);

    const { cartItems, deliveryInfos, discount, setDiscount, total, companyDiscountCode } = useContext(CartContext);

    const [userDiscounts, setUserDiscounts] = useState([]);

    const [deliveryCost, setDeliveryCost] = useState(0);
    const [discountCode, setDiscountCode] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        if (user.token) {
            axiosGetRequest('/discounts/user', user.token).then((res) => {
                setUserDiscounts(res.data.data.discounts);
            });
        }
    }, [user.token]);

    const applyDiscount = async (discountName) => {
        // anonymously check discount
        return axiosPostRequest('/discounts/check', user.token, {
            name: discountName,
            amount: total,
            requete: 'discount',
        })
            .then((res) => {
                setDiscount(res.data.data.discount);
            })
            .catch((err) => {
                enqueueSnackbar(err.response.data.message, {
                    variant: 'error',
                });

                const index = userDiscounts.findIndex((discount) => discount.name === discountName);
                setUserDiscounts((prevState) => prevState.filter((_, i) => i !== index));
            });
    };

    const removeDiscount = () => {
        setDiscount(null);
    };

    useEffect(() => {
        if (props.delivery) {
            axiosGetRequest(`/deliveryCities/?zipCode=${deliveryInfos.deliveryAddress.zipCode}`).then((res) => {
                if (deliveryInfos.deliveryType === 'relayPoint') {
                    setDeliveryCost(0);
                    return;
                }

                if (res.data.data.deliveryCity[0]) {
                    setDeliveryCost(
                        res.data.data.deliveryCity[0].deliveryZone.charges
                            .filter((charge) => total >= charge.threshold)
                            .pop().costsTTC
                    );
                }
            });
        }
    }, [cartItems]);

    const getDiscount = () => {
        if (!discount) {
            return 0;
        }

        let formattedDiscount = '-' + currencyFormater(Math.abs(Number(total) + deliveryCost - getTotal()));

        if (discount.value && discount.reduction) {
            if (discount.reduction === 'percent') {
                formattedDiscount += ' (-' + discount.value + '%)';
            }
        }

        return formattedDiscount;
    };

    const getTotal = () => {
        if (!discount) {
            if (deliveryCost) {
                return Math.round((Number(total) + deliveryCost) * 100) / 100;
            } else {
                return Math.round(Number(total) * 100) / 100;
            }
        }

        if (discount.value && discount.reduction) {
            if (discount.reduction === 'percent') {
                if (deliveryCost) {
                    return Math.round((Number(total) + deliveryCost - total * 1 * (discount.value / 100)) * 100) / 100;
                } else {
                    return Math.round((Number(total) - total * 1 * (discount.value / 100)) * 100) / 100;
                }
            }

            if (deliveryCost) {
                return Math.round((Number(total) + deliveryCost - discount.value) * 100) / 100;
            } else {
                return Math.round((Number(total) - discount.value) * 100) / 100;
            }
        }
        return total;
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.container}>
                    {props.delivery && (
                        <Box sx={{ display: 'flex', textAlign: 'left', minHeight: '45px', lineHeight: '45px' }}>
                            <Box sx={{ flexGrow: 1, fontWeight: 400 }}>Prix produit :</Box>
                            <Box>{currencyFormater(total)}</Box>
                        </Box>
                    )}
                    {props.delivery && (discount === null || !Object.keys(discount).length) && (
                        <React.Fragment>
                            <Divider className={classes.divider} />
                            <Box
                                sx={{ display: 'flex', textAlign: 'left', minHeight: '45px', lineHeight: '45px' }}
                                className={classes.discountContainer}
                            >
                                <Box sx={{ flexGrow: 1, fontWeight: 400 }}>Code promotionnel :</Box>
                                <Box sx={{ display: 'flex' }} className={classes.discount}>
                                    <form
                                        onSubmit={(event) => {
                                            event.preventDefault();
                                            applyDiscount(discountCode);
                                        }}
                                    >
                                        <input
                                            type="text"
                                            className={classes.input}
                                            placeholder="Saisissez le code"
                                            onChange={(e) => {
                                                e.persist();
                                                setDiscountCode(e.target.value);
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className={classes.apply}
                                            color="secondary"
                                        >
                                            OK
                                        </Button>
                                    </form>
                                    {userDiscounts.length > 0 && (
                                        <Select
                                            defaultValue={'none'}
                                            className={classes.select}
                                            onChange={(e) => applyDiscount(userDiscounts[e.target.value].name)}
                                        >
                                            <MenuItem disabled value={'none'}>
                                                Vos bons de réduction
                                            </MenuItem>
                                            {userDiscounts.map(
                                                (el, index) =>
                                                    el.isActive && (
                                                        <MenuItem key={el.id} value={index}>
                                                            {el.name} - {el.value}{' '}
                                                            {el.reduction === 'percent' ? '%' : '€'}
                                                        </MenuItem>
                                                    )
                                            )}
                                        </Select>
                                    )}
                                </Box>
                            </Box>
                        </React.Fragment>
                    )}
                    {props.delivery && discount !== null && discount.value && (
                        <React.Fragment>
                            <Divider className={classes.divider} />
                            <Box sx={{ display: 'flex', textAlign: 'left', minHeight: '45px', lineHeight: '45px' }}>
                                <Box sx={{ flexGrow: 1, fontWeight: 400 }}>
                                    {companyDiscountCode ? 'Code entreprise' : 'Code promotionnel'} :
                                </Box>
                                <Box>{getDiscount()}</Box>
                                {discount._id && (
                                    <Box>
                                        <Button
                                            type="button"
                                            onClick={() => removeDiscount()}
                                            className={classes.delete}
                                        >
                                            <DeleteOutlined className={classes.svg} />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </React.Fragment>
                    )}
                    {props.delivery && (
                        <React.Fragment>
                            <Divider className={classes.divider} />
                            <Box sx={{ display: 'flex', textAlign: 'left', minHeight: '45px', lineHeight: '45px' }}>
                                <Box sx={{ flexGrow: 1, fontWeight: 400 }}>Frais de livraison :</Box>
                                <Box>{deliveryCost ? currencyFormater(deliveryCost) : 'Gratuit'}</Box>
                            </Box>
                            <Divider className={classes.divider} />
                        </React.Fragment>
                    )}
                    <Box sx={{ display: 'flex', textAlign: 'left', minHeight: '45px', lineHeight: '45px' }}>
                        <Box sx={{ flexGrow: 1, fontWeight: 700 }}>Total TTC :</Box>
                        <Box>{currencyFormater(getTotal())}</Box>
                    </Box>
                </div>
            </div>
        </React.Fragment>
    );
}
