import React, { Fragment, useState } from 'react';
import { Button, Modal, Fade, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// # ICON
import CloseIcon from '@material-ui/icons/Close';

// # IMG
//import img1 from '../assets/img/pop-up-vacances-noel.png';
import banniere from '../assets/img/popup_11-12_novembre.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    backgroundColor: 'white',
    borderRadius: '30px',
    width: '30px',
    height: '64px',
    marginBottom: '5px',
  },
  fade: {
    display: 'flex',
    maxWidth: '450px',
    maxHeight: '90vh',
    flexDirection: 'column',
    alignItems: 'center',
    outline: 'none',

    '& > figure': {
      borderRadius: '5px',
      overflow: 'hidden',
      background: 'white',

      '& > figcaption': {
        padding: '0.5rem',
        fontSize: '14px',
        lineHeight: '22px',
      },
    },
  },
}));

export default function ModalAdd(props) {
  const classes = useStyles();
  const {banner} = props;
  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem('popUp', JSON.stringify(false));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <Button onClick={handleClose} className={classes.button}>
            <CloseIcon fontSize="large" color="secondary" />
          </Button>
          <img width={'80%'} src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${banner.photo}`} alt="banniere"/>
          {/*<figure className={classes.popup}>
            <img width="100%" src={img1} alt="pop-up-noel" />
            <figcaption>
              Chers clients,
              <br />
              Toute l’équipe de Bio Culture vous souhaite de très belles fêtes de fin d’année !
              <br />
              Nous aurons le plaisir de vous retrouver dès le 3 janvier pour une rentrée gourmande,
              Bio et Locale.
              <br />
              Retrouvez dès aujourd’hui le pain Bio des boulangeries Thierry Marx : Farine
              Biologique d’Ile de France, levain naturel, pâton levé pendant 48 heures.
              <br />
              Et nous vous réservons pleins d’autres surprises pour la nouvelle année !<br />
              Bien sincèrement,
              <br />
              <br />
              Adrien
              <br />
              Fondateur
            </figcaption>
          </figure>*/}
        </div>
      </Fade>
    </Modal>
  );
}
