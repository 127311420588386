import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import moment from 'moment';
import 'moment/locale/fr';

import Script from 'react-load-script';

import { useSnackbar } from 'notistack';
import {
  IconButton,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Grid,
  TextField,
  Divider,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

// img

import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import img2 from '../assets/img/pointrelais.png';
import img1 from '../assets/img/home.png';
import Calendar from './Calendar';

// # STYLE
import '../assets/css/style.css';
import '../assets/css/styleshipping.css';

// # CONTEXT
import { UserContext } from '../contexts/UserContext/UserContext';
import { CartContext } from '../contexts/CartContext';

const useStyles = makeStyles((theme) => ({
  h3: {
    fontFamily: 'Bitter',
  },
  list: {
    height: '100%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    '& h2': {
      margin: '2rem 2rem 0 2rem',
      textAlign: 'center',
      color: '#f05c34',
      outline: 'none',
    },
  },
  '.MuiStepLabel-label': {
    color: '9e9e9e',
  },
  fullList: {
    width: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  '& .MuiDrawer-paper': {
    width: 'auto',
  },
  centerItem: {
    alignSelf: 'center',
  },
  date: {
    textTransform: 'capitalize',
    backgroundColor: '#BFCD45',
    padding: '1rem',
    margin: '0',
    borderRadius: '10px',
    color: 'white',
  },
  minuscule: {
    textTransform: 'lowercase',
  },
  deliveryCard: {
    padding: '1.5em',
    boxShadow: '0px 0px 5px rgb(0 0 0 / 32%)',
    borderRadius: '10px',
    margin: '30px auto 30px auto',
    '& > .MuiFormControl-root': {
      width: '100%',
    },
    '& > .MuiAccordion-root': {
      marginTop: '0.5rem',
      '&::before': {
        backgroundColor: 'transparent',
      },
    },
    '& h4': {
      margin: 0,
    },
    '& > div:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
      paddingTop: '0.5rem',
      '& > div:first-child': {
        '& > p': {
          marginTop: 0,
          marginBottom: 0,
          fontSize: '12px',
        },
      },
    },
  },
  deliveryCardInfos: {
    textAlign: 'left',
  },
  zipInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '220px',
    margin: '15px auto 30px auto',
    border: 'solid 6px #f05c34',
    borderRadius: '50px',
    paddingLeft: '25px',
    '& > input': {
      border: 'none',
      outline: 'none',
    },
    '& > button': {
      padding: '10px 12px',
    },
  },
  drawer: {
    maxWidth: '360px',
    width: '90vw',
    padding: '1rem',
    textAlign: 'left',
  },
  row: {
    textAlign: 'center',
  },
  inputZipCode: {
    fontSize: '16px',
  },
  btn: {
    backgroundColor: '#F05C33',
    color: 'white',
    textTransform: 'capitalize',
    borderRadius: '30px',
    padding: '0.5rem',
    '&:hover': {
      color: '#F05C33',
      border: 'solid 1px #F05C33',
      backgroundColor: 'white',
    },
  },
  buttonShipping: {
    position: 'fixed',
    zIndex: '10',
    bottom: '2%',
    right: '2%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
    fontFamily: 'Bitter',
    textTransform: 'inherit',
    color: 'white',
    padding: '15px',
    fontSize: '1rem',
    backgroundColor: '#f05c34',
    border: 'none',
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: '#bfcf38',
      transition: '0.3s',
    },
  },
  midDivider: {
    margin: '1rem 0',
  },
}));

export default function Shipping(props) {
  const token = Cookies.get('jwt');
  // # Setup
  moment.locale('fr');
  const classes = useStyles();
  const [state, setState] = useState({
    loading: true,
    idfCities: [],
    code: '',
    relay: [],
    city: [],
    infosRelay: {},
    infosCity: {},
    deliveryInfos: {},
  });

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);

  const {
    deliveryInfos,
    setZipCode,
    setDeliveryInfos,
    setDrawer,
    setDeliveryAddress,
    setDeliveryBillingAddress,
  } = useContext(CartContext);

  useEffect(() => {
    const fetchCities = async () => {
      const idfCities = await axios.get(`${process.env.REACT_APP_API}/idfCities`);

      const idfCitiesFormat = idfCities.data.data.cities.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });

      setState((prev) => ({
        ...prev,
        idfCities: [...idfCitiesFormat],
      }));
    };

    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/relayPoints?type=relay&isActive=true`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const dataRelay = res.data.data.relayPoints;
          const filter = dataRelay.filter(
            (el) => el.city.zipCode === deliveryInfos.deliveryAddress.zipCode
          );

          setState((prev) => ({
            ...prev,
            relay: [...filter],
          }));

          filter.forEach((el, idx) =>
            setState((prev) => ({
              ...prev,
              infosRelay: {
                ...prev.infosRelay,
                [idx]: {
                  date: '',
                },
              },
            }))
          );
        })
        .catch((err) => {
          console.log(err.response);
        });

      await axios
        .get(
          `${process.env.REACT_APP_API}/deliveryCities/?zipCode=${deliveryInfos.deliveryAddress.zipCode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const resData = res.data.data.deliveryCity;
          console.log(resData);

          setState((prev) => ({
            ...prev,
            code: deliveryInfos.deliveryAddress.zipCode,
            city: [...resData],
          }));

          resData.forEach((el, idx) =>
            setState((prev) => ({
              ...prev,
              infosCity: {
                ...prev.infosCity,
                [idx]: {
                  date: '',
                },
              },
            }))
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    fetchCities();

    if (deliveryInfos.deliveryAddress.zipCode) {
      fetchData().then(() =>
        setState((prev) => ({
          ...prev,
          loading: false,
        }))
      );
    }
  }, [deliveryInfos.deliveryAddress.zipCode, token]);

  const handleDeliverySelect = (type, idx, relayType, relayId) => {
    const cityInfos = state.city[idx];
    if (type === 'city') {
      console.log('type');
      if (!deliveryInfos.deliverySchedule) {
        enqueueSnackbar('Veuillez renseigner une date de livraison', {
          variant: 'error',
        });
      } else {
        if (user.address) {
          const mainAddress = user.address.find((el) => el.isMain === true);
          const isBill = user.address.find((el) => el.isBill === true);
          if (mainAddress && mainAddress.zipCode === cityInfos.zipCode) {
            setDeliveryAddress({
              deliveryAddress: {
                title: mainAddress.title || '',
                address: mainAddress.street,
                zipCode: cityInfos.zipCode,
                city: mainAddress.city,
                country: 'France',
                complement: mainAddress.comment,
              },
            });

            if (isBill) {
              setDeliveryBillingAddress({
                deliveryBillingAddress: {
                  title: isBill.title || '',
                  address: isBill.street,
                  zipCode: isBill.zipCode,
                  city: isBill.city,
                  country: 'France',
                  complement: isBill.comment,
                },
              });
            } else {
              setDeliveryBillingAddress({
                deliveryBillingAddress: {
                  title: mainAddress.title || '',
                  address: mainAddress.street,
                  zipCode: cityInfos.zipCode,
                  city: mainAddress.city,
                  country: 'France',
                  complement: mainAddress.comment,
                },
              });
            }
          } else {
            setDeliveryAddress({
              deliveryAddress: {
                title: '',
                address: '',
                zipCode: cityInfos.zipCode,
                city: cityInfos.name,
                country: '',
                complement: '',
              },
            });
          }
        } else {
          setDeliveryAddress({
            deliveryAddress: {
              title: '',
              address: '',
              zipCode: cityInfos.zipCode,
              city: cityInfos.name,
              country: '',
              complement: '',
            },
          });
        }

        let deliveryObj;

        if(cityInfos.deliveryService) {
          deliveryObj = {
            deliveryDate: deliveryInfos.deliveryDate,
            deliverySchedule: deliveryInfos.deliverySchedule,
            depositSchedule: deliveryInfos.depositSchedule,
            deliveryType: deliveryInfos.deliveryType,
            deliverySubType: deliveryInfos.deliverySubType,
            deliveryId: deliveryInfos.deliveryId,
            deliveryService: cityInfos.deliveryService.id,
          };
        }else {
          deliveryObj = {
            deliveryDate: deliveryInfos.deliveryDate,
            deliverySchedule: deliveryInfos.deliverySchedule,
            depositSchedule: deliveryInfos.depositSchedule,
            deliveryType: deliveryInfos.deliveryType,
            deliverySubType: deliveryInfos.deliverySubType,
            deliveryId: deliveryInfos.deliveryId,
          };
        }
        
        console.log('deliveryInfos', cityInfos, 'deliveryObj', deliveryObj);

        setDeliveryInfos({
          ...deliveryObj
        })

        setDrawer(false);

        enqueueSnackbar('Votre choix a bien été enregistré', {
          variant: 'success',
        });
      }
    }

    if (type === 'relay') {
      if (!deliveryInfos.deliverySchedule) {
        if (state.infosCity[idx].date === '') {
          console.log('il y a une date');
        } else {
          enqueueSnackbar('Veuillez renseigner une date de livraison', {
            variant: 'error',
          });
        }
      } else {
        const relayInfos = state.relay[idx];
        setDeliveryAddress({
          deliveryAddress: {
            title: relayInfos.name,
            address: relayInfos.address.address,
            zipCode: relayInfos.city.zipCode,
            city: relayInfos.city.name,
            country: relayInfos.address.country,
            complement: relayInfos.address.complement,
            comment: relayInfos.address.comment,
          },
        });

        if (user.address) {
          const mainAddress = user.address.find((el) => el.isMain === true);
          setDeliveryBillingAddress({
            deliveryBillingAddress: {
              address: mainAddress.street,
              zipCode: mainAddress.zipCode,
              city: mainAddress.city,
              country: 'France',
              complement: mainAddress.comment,
            },
          });
        } else {
          setDeliveryBillingAddress({
            deliveryBillingAddress: {
              title: relayInfos.name,
              address: relayInfos.address.address,
              zipCode: relayInfos.city.zipCode,
              city: relayInfos.city.name,
              country: relayInfos.address.country,
              complement: relayInfos.address.complement,
              comment: relayInfos.address.comment,
            },
          });
        }

        let deliveryObj;

        console.log(cityInfos.deliveryService)

        if(cityInfos.deliveryService) {
          deliveryObj = {
            deliveryDate: deliveryInfos.deliveryDate,
            deliverySchedule: deliveryInfos.deliverySchedule,
            depositSchedule: deliveryInfos.depositSchedule,
            deliveryType: deliveryInfos.deliveryType,
            deliverySubType: relayType,
            deliveryId: relayId,
            deliveryService: cityInfos.deliveryService.id,
          };
        } else {
          deliveryObj= {
            deliveryDate: deliveryInfos.deliveryDate,
            deliverySchedule: deliveryInfos.deliverySchedule,
            depositSchedule: deliveryInfos.depositSchedule,
            deliveryType: deliveryInfos.deliveryType,
            deliverySubType: relayType,
            deliveryId: relayId,
          };
        }

        console.log(deliveryObj);

        setDeliveryInfos({
          ...deliveryObj,
        });

        setDrawer(false);
        enqueueSnackbar('Votre choix a bien été enregistré', {
          variant: 'success',
        });
      }
    }

    // CHECK IF CURRENT WEEK FOR WARNING IF NOT
    const todayWeekNum = moment().isoWeek();
    const deliveryDateWeekNum = moment(deliveryInfos.deliveryDate).isoWeek();

    if (deliveryDateWeekNum > todayWeekNum) {
      enqueueSnackbar(
        `ÊTES-VOUS SUR DE VOULOIR COMMANDER POUR LA SEMAINE PROCHAINE ?
        La composition des paniers change chaque samedi à midi pour la semaine suivante.
        Êtes-vous prêt à recevoir un panier surprise ?
        Si ce n’est pas le cas, patientez jusqu’à samedi midi pour passer votre commande.
        Le contenu des paniers de la semaine n’est pas modifiable.`,
        {
          variant: 'warning',
          autoHideDuration: 10000,
          style: { whiteSpace: 'pre-line' },
        }
      );
    }
  };

  const handleScriptLoad = () => {
    const options = {
      types: ['(regions)'],
      componentRestrictions: {
        country: 'fr',
      },
      fields: ['address_components'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    );

    autocomplete.addListener('place_changed', async () => {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));

      const place = autocomplete.getPlace();
      const components = place.address_components;
      let name;
      let zipCode;

      if (place && components) {
        await components.forEach((component) => {
          const componentType = component.types[0];

          switch (componentType) {
            case 'sublocality_level_1':
              if (component.long_name === '16e Arrondissement de Paris') {
                zipCode = '75116';
                break;
              }
              break;
            case 'locality':
              name = component.long_name;
              break;
            case 'postal_code':
              zipCode = component.long_name;
              break;
            default:
              break;
          }
        });

        await axios
          .get(`${process.env.REACT_APP_API}/idfcities/?name=${name}`)
          .then((res) => {
            if (!zipCode) {
              setZipCode(res.data.data.cities[0].zipCode);
            } else {
              setZipCode(zipCode);
            }

            setState((prev) => ({
              ...prev,
              loading: false,
            }));
          })
          .catch(() =>
            enqueueSnackbar('Malheuresement nous ne livrons pas votre ville', {
              variant: 'error',
            })
          );
      }
    });
  };

  const list = (anchor) => (
    <div className={classes.drawer} role="presentation">
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <IconButton
        className="buttonprofil"
        aria-label="menu"
        align="left"
        onClick={() => setDrawer(false)}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <div className={classes.row}>
        <h2 style={{ fontFamily: 'Bitter', margin: '1rem 0px 1rem 0px', fontSize: '1.3rem' }}>
          Choisissez votre mode de livraison
        </h2>
        <TextField
          className={classes.marginB}
          label="Code postal ou ville"
          type="text"
          variant="outlined"
          id="autocomplete"
          fullWidth
        />

        <Divider className={classes.midDivider} variant="middle" />

        <div className={classes.deliveryList}>
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={9}>
              <h3 className={classes.h3}> Livraison à domicile :</h3>
            </Grid>
            <Grid item xs={3}>
              <img src={img1} alt="home" />
            </Grid>
          </Grid>
          {state.city.length > 0 ? (
            state.city.map((el, idx) => (
              <div key={idx} className={classes.deliveryCard}>
                <div className={classes.cardHeader}>
                  <h4>{el.name}</h4>
                </div>
                <Grid container spacing={3} justify="flex-start">
                  {deliveryInfos.deliveryType === 'home' && (
                    <Grid item xs={12}>
                      <p className={classes.date}>
                        {`${moment(deliveryInfos.deliveryDate).format('dddd Do MMMM')}`}{' '}
                        <span className={classes.minuscule}>
                          entre {deliveryInfos.deliverySchedule}
                        </span>
                      </p>
                    </Grid>
                  )}
                  <Grid item xs={8} className={classes.centerItem}>
                    Choisissez un créneaux
                  </Grid>
                  <Grid item xs={4}>
                    <Calendar dates={el.infos} deliveryType="home" />
                  </Grid>
                  <Grid item xs={12}>
                    {el.deliveryZone.charges && el.deliveryZone.charges.length > 0 ? (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Condition de livraison</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block' }}>
                          <p>Livraison gratuite dès {el.deliveryZone.charges[2].threshold} €</p>
                          <p>
                            Entre {el.deliveryZone.charges[1].threshold} € et{' '}
                            {el.deliveryZone.charges[2].threshold} € :{' '}
                            {el.deliveryZone.charges[1].costsTTC} € de participation
                          </p>
                          <p>
                            Entre {el.deliveryZone.charges[0].threshold} € et{' '}
                            {el.deliveryZone.charges[1].threshold} € :{' '}
                            {el.deliveryZone.charges[0].costsTTC} € de participation
                          </p>
                        </AccordionDetails>
                      </Accordion>
                    ) : null}
                  </Grid>
                </Grid>

                <Button
                  className={classes.btn}
                  style={{ marginTop: '10px' }}
                  onClick={() => handleDeliverySelect('city', idx)}
                  disabled={deliveryInfos.deliveryType !== 'home'}
                >
                  Valider
                </Button>
              </div>
            ))
          ) : (
            <p>
              Vous habitez en ile de France ?<br />
              Contactez-nous, nous pourrons peut-être vous livrer prochainement .
            </p>
          )}

          <Divider className={classes.midDivider} variant="middle" />

          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={9}>
              <h3 className={classes.h3}>Livraison en point relais :</h3>
            </Grid>
            <Grid item xs={3}>
              <img src={img2} alt="home" />
            </Grid>
          </Grid>
          {state.relay.length > 0 ? (
            state.relay.map((el, idx) => (
              <div key={idx} className={classes.deliveryCard}>
                <div>
                  <div className={classes.deliveryCardInfos}>
                    <h4>{el.name}</h4>
                    <p>{el.address.address}</p>
                    <p>
                      {el.city.zipCode} - {el.city.name}
                    </p>
                  </div>
                </div>
                <Grid container spacing={3}>
                  {deliveryInfos.deliveryId === el.id && (
                    <Grid item xs={12}>
                      <p className={classes.date}>
                        {`${moment(deliveryInfos.deliveryDate).format('dddd Do MMMM')}`}{' '}
                        <span className={classes.minuscule}>
                          entre {deliveryInfos.deliverySchedule}
                        </span>
                      </p>
                    </Grid>
                  )}
                  <Grid item xs={8} className={classes.centerItem}>
                    Choisissez un créneaux
                  </Grid>
                  <Grid item xs={4}>
                    <Calendar relayInfos={el} dates={el.schedules} deliveryType="relayPoint" />
                  </Grid>
                </Grid>
                <Button
                  className={classes.btn}
                  style={{ marginTop: '10px' }}
                  onClick={() => handleDeliverySelect('relay', idx, el.type, el.id)}
                  disabled={deliveryInfos.deliveryId !== el.id}
                >
                  Valider
                </Button>
              </div>
            ))
          ) : (
            <p>Aucun point relais disponible</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div id="shipping">
      <Drawer
        className={classes.list}
        anchor="right"
        open={props.drawer}
        onClose={() => setDrawer(false)}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}
