import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
  ButtonGroup,
  Button,
  IconButton,
} from '@material-ui/core/';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { useSnackbar } from 'notistack';

import _ from 'lodash';

import moment from 'moment';

const columns = [
  {
    id: 'order',
    label: 'Ordre',
    minWidth: 100,
    align: 'center',
  },
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  {
    id: 'mainCategory',
    label: 'Rubrique principale',
    minWidth: 100,
    align: 'left',
  },
  { id: 'active', label: 'Actif', minWidth: 100, align: 'center' },
  {
    id: 'created',
    label: 'Date de création',
    minWidth: 100,
    align: 'center',
  },
];

const columnsBis = [
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  {
    id: 'mainCategory',
    label: 'Rubrique principale',
    minWidth: 100,
    align: 'left',
  },
  { id: 'active', label: 'Actif', minWidth: 100, align: 'center' },
  {
    id: 'created',
    label: 'Date de création',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  a: {
    color: '#adc00d',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  div: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
});

export default function SubCategoryTable(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const token = Cookies.get('jwt');
  const [state, setState] = useState({
    rubrique: props.rubrique,
    baseSubcategories: _.cloneDeep(props.data),
    subcategories: props.data.sort((a, b) => a.position - b.position),
    isReorder: false,
  });

  useEffect(() => {
    setState((prev) => ({
      baseSubcategories: _.cloneDeep(props.data),
      rubrique: props.rubrique,
      subcategories: props.data.sort((a, b) => a.position - b.position),
      isReorder: false,
    }));
  }, [props]);

  const handleSort = (cat, action, idx) => {
    if (!state.isReorder) {
      setState((prev) => ({
        ...prev,
        isReorder: !prev.isReorder,
      }));
    }
    if (action === 'increase') {
      const tempSubcategories = [...state.subcategories];

      tempSubcategories[idx].position -= 1;

      tempSubcategories[idx - 1].position += 1;

      setState((prev) => ({
        ...prev,
        subcategories: tempSubcategories.sort((a, b) => a.position - b.position),
      }));
    } else {
      const tempSubcategories = [...state.subcategories];
      tempSubcategories[idx].position += 1;

      tempSubcategories[idx + 1].position -= 1;

      setState((prev) => ({
        ...prev,
        subcategories: tempSubcategories.sort((a, b) => a.position - b.position),
      }));
    }
  };

  const handleSubmit = async () => {
    const tempTable = [];
    state.subcategories.forEach((el, idx) => {
      if (el._id !== state.baseSubcategories[idx]._id) {
        tempTable.push(el);
      }
    });

    const temp = [];
    tempTable.forEach((el) => {
      const tempObj = {
        id: el._id,
        position: el.position,
      };
      temp.push(tempObj);
    });

    await axios
      .patch(`${process.env.REACT_APP_API}/subcategories`, temp, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        enqueueSnackbar(`Une erreur s'est produite`, {
          variant: 'error',
        });
      })
      .then(
        enqueueSnackbar('Les modifications ont été prises en compte', {
          variant: 'success',
        }),
        setState((prev) => ({
          ...prev,
          isReorder: !prev.isReorder,
        }))
      );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className={classes.div}>
        {state.isReorder && (
          <Button variant="contained" color="secondary" type="button" align="flex-end" onClick={handleSubmit}>
            Enregistrer les modifications
          </Button>
        )}
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {state.rubrique !== 'all'
                  ? columns.map((column, idx) => (
                      <TableCell key={idx} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))
                  : columnsBis.map((column, idx) => (
                      <TableCell key={idx} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.subcategories
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort((a, b) => a.position - b.position)
                .map((subcategory, idx) => (
                  <TableRow key={idx}>
                    {state.rubrique !== 'all' && (
                      <TableCell align="left">
                        <ButtonGroup
                          variant="contained"
                          color="primary"
                          aria-label="contained primary button group"
                          style={{
                            marginLeft: idx + 1 === state.subcategories.length ? '50px' : '0',
                          }}
                        >
                          {idx + 1 !== state.subcategories.length && (
                            <IconButton onClick={() => handleSort(subcategory, 'decrease', idx)}>
                              <ExpandMore />
                            </IconButton>
                          )}
                          <Button>{subcategory.position}</Button>
                          {idx + 1 !== 1 && (
                            <IconButton onClick={() => handleSort(subcategory, 'increase', idx)}>
                              <ExpandLess />
                            </IconButton>
                          )}
                        </ButtonGroup>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <a className={classes.a} href={`/admin/subcategories/${subcategory._id}`}>
                        {subcategory.name}
                      </a>
                    </TableCell>
                    <TableCell align="left">
                      {subcategory.category ? (
                        <a className={classes.a} href={`/admin/categories/${subcategory.category._id}`}>
                          {subcategory.category.name}
                        </a>
                      ) : (
                        'Non associée'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Badge
                        badgeContent={subcategory.isActive ? 'Actif' : 'Inactif'}
                        color={subcategory.isActive ? 'primary' : 'error'}
                      />
                    </TableCell>
                    <TableCell align="center">{moment(subcategory.createdAt).format('DD-MM-YYYY')}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
