import React, { useState } from 'react';

import { useParams } from 'react-router';

import axios from 'axios';

// # MATERIAL UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  // # STATE HOOKS
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const { token } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password.length < 8) {
      enqueueSnackbar('Votre mot de passe doit contenir au moins 8 caractères', {
        variant: 'error',
      });
    } else if (password !== passwordConfirm) {
      enqueueSnackbar('Les deux champs sont différents', {
        variant: 'error',
      });
    } else {
      await axios
        .patch(
          `${process.env.REACT_APP_API}/users/resetPassword/${token}`,
          { password },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        // eslint-disable-next-line no-return-assign
        .then(() => (window.location = '/login'))
        .catch((err) => {
          const { errors } = err.response.data.error;
          Object.keys(errors).forEach((key) => {
            enqueueSnackbar(`${errors[key].message}`, { variant: 'error' });
          });
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Réinitialiser mon mot de passe
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nouveau mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="Confirmation du mot de passe"
            type="password"
            id="passwordConfirm"
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Réinitialiser
          </Button>
        </form>
      </div>
    </Container>
  );
}
