import React, { useState } from 'react';
import axios from 'axios';

import { useSnackbar } from 'notistack';

// # MATERIAL UI
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  // # STATE HOOKS
  const [email, setEmail] = useState();

  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API}/users/forgotPassword`,
        { email },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .then(() => {
        setEmailSent(true);
        enqueueSnackbar('Un mail de réinitialisation vous a été envoyé', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur s'est produite", {
          variant: 'error',
        });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {emailSent ? (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Mot de passe oublié
          </Typography>
          <h3>
            Un mail vous a été envoyé !
            <br />
            Si vous ne le voyez pas, vérifiez dans vos courriers indésirables
          </h3>
        </div>
      ) : (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Mot de passe oublié
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Valider
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/signup" variant="body2">
                  Vous n&apos;avez pas encore de compte ?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      <Box mt={8} />
    </Container>
  );
}
