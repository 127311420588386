import React, { Fragment, useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Collapse,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import {
  PersonRounded,
  ShoppingBasketRounded,
  EuroRounded,
  ShoppingCartRounded,
  EqualizerRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';

// # COMPONENTS
import StatsRecap from '../../../components/Stats/StatsRecap';

// # UTILS
import { axiosPostRequest } from '../../../utils/axiosRequests';
import currencyFormater from '../../../utils/currencyFormater';
import formatNum from '../../../utils/numberFormater';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      width: '200px',
      marginBottom: '1rem',
    },
  },
  paperFull: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
}));

function Row(props) {
  const { redundancy, data } = props;
  const { count, users } = data;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {count} Personnes ont commandé {redundancy} fois
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Clients
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Rôle</TableCell>
                    <TableCell>Adresse mail</TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell>Nom</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <Link href={`/admin/users/${user.id}`}>{user.id.slice(-8)}</Link>
                      </TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        <Link href={`mailto:${user.email}`}>{user.email}</Link>
                      </TableCell>
                      <TableCell>{user.firstname}</TableCell>
                      <TableCell>{user.lastname}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function AnalyticsDashboard() {
  moment.locale('fr');
  const classes = useStyles();

  const [state, setState] = useState({
    fetched: false,
    filters: {
      users: {
        selectedUsers: 'new',
        roleUsers: 'user',
        createdAt: 'dateToDate',
        from: '',
        to: '',
        date: '',
      },
      orders: {
        period: 'dateToDate',
        from: '',
        to: '',
        date: '',
      },
    },
    stats: {
      loading: true,
      usersLen: 0,
      ordersLen: 0,
      ordersTotalValue: 0,
      repurchaseRate: 0,
      lifeTimeValue: 0,
      redundancy: {},
    },
  });

  // # INITIALIZE FILTERS
  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const threeMonthAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');

    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        users: {
          ...prev.filters.users,
          from: threeMonthAgo,
          to: today,
          date: today,
        },
        orders: {
          ...prev.filters.orders,
          from: threeMonthAgo,
          to: today,
          date: today,
        },
      },
    }));
  }, []);

  // # HANDLE CHANGE FILTERS
  const handleChangeFilters = (event, type) => {
    const { name, value } = event.target;
    setState({
      ...state,
      filters: {
        ...state.filters,
        [type]: {
          ...state.filters[type],
          [name]: value,
        },
      },
    });
  };

  // # FETCH DATA
  const handleFetchData = () => {
    const token = Cookies.get('jwt');

    setState((prev) => ({
      ...prev,
      fetched: true,
      stats: {
        ...prev.stats,
        loading: true,
      },
    }));

    axiosPostRequest('/orders/userStatistics', token, { filters: state.filters })
      .then((res) => {
        const { stats } = res.data;
        console.log(stats);
        setState((prev) => ({
          ...prev,
          stats: {
            loading: false,
            ...stats,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
        setState((prev) => ({
          ...prev,
          fetched: false,
          stats: {
            ...prev.stats,
            loading: true,
          },
        }));
      });
  };

  return (
    <>
      <div className={classes.root}>
        <h2>Statistiques des utilisateurs</h2>
      </div>

      <Grid container spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <h3>Filtres clients :</h3>
            <FormControl variant="outlined">
              <InputLabel>Clients à sélectionner</InputLabel>
              <Select
                label="Clients à sélectionner"
                name="selectedUsers"
                value={state.filters.users.selectedUsers}
                onChange={(event) => handleChangeFilters(event, 'users')}
              >
                <MenuItem value="all">Tous les clients</MenuItem>
                <MenuItem value="new">Nouveaux inscrits</MenuItem>
              </Select>
            </FormControl>

            {state.filters.users.selectedUsers !== 'all' && (
              <>
                <FormControl variant="outlined">
                  <InputLabel>Rôle</InputLabel>
                  <Select
                    label="Rôle"
                    name="roleUsers"
                    value={state.filters.users.roleUsers}
                    onChange={(event) => handleChangeFilters(event, 'users')}
                  >
                    <MenuItem value="user">Client</MenuItem>
                    <MenuItem value="eco">Eco-Acteur</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel>Date de création</InputLabel>
                  <Select
                    label="Date de création"
                    name="createdAt"
                    value={state.filters.users.createdAt}
                    onChange={(event) => handleChangeFilters(event, 'users')}
                  >
                    <MenuItem value="dateToDate">Date à date</MenuItem>
                    <MenuItem value="month">Mois</MenuItem>
                    <MenuItem value="year">Année</MenuItem>
                  </Select>
                </FormControl>

                {state.filters.users.createdAt === 'dateToDate' ? (
                  <FormControl variant="outlined">
                    <TextField
                      style={{ marginBottom: '1rem' }}
                      label="Du"
                      type="date"
                      name="from"
                      value={state.filters.users.from}
                      onChange={(event) => handleChangeFilters(event, 'users')}
                    />
                    <TextField
                      label="Au"
                      type="date"
                      name="to"
                      value={state.filters.users.to}
                      onChange={(event) => handleChangeFilters(event, 'users')}
                    />
                  </FormControl>
                ) : (
                  <FormControl variant="outlined">
                    <TextField
                      label="Date"
                      type="date"
                      name="date"
                      value={state.filters.users.date}
                      onChange={(event) => handleChangeFilters(event, 'users')}
                    />
                  </FormControl>
                )}
              </>
            )}
          </Paper>
        </Grid>

        <Grid item>
          <Paper className={classes.paper}>
            <h3>Filtres commandes :</h3>

            <FormControl variant="outlined">
              <InputLabel>Période</InputLabel>
              <Select
                label="Période"
                name="period"
                value={state.filters.orders.period}
                onChange={(event) => handleChangeFilters(event, 'orders')}
              >
                <MenuItem value="dateToDate">Date à date</MenuItem>
                <MenuItem value="month">Mois</MenuItem>
                <MenuItem value="year">Année</MenuItem>
              </Select>
            </FormControl>

            {state.filters.orders.period === 'dateToDate' ? (
              <FormControl variant="outlined">
                <TextField
                  style={{ marginBottom: '1rem' }}
                  label="Du"
                  type="date"
                  name="from"
                  value={state.filters.orders.from}
                  onChange={(event) => handleChangeFilters(event, 'orders')}
                />
                <TextField
                  label="Au"
                  type="date"
                  name="to"
                  value={state.filters.orders.to}
                  onChange={(event) => handleChangeFilters(event, 'orders')}
                />
              </FormControl>
            ) : (
              <FormControl variant="outlined">
                <TextField
                  label="Date"
                  type="date"
                  name="date"
                  value={state.filters.orders.date}
                  onChange={(event) => handleChangeFilters(event, 'orders')}
                />
              </FormControl>
            )}
          </Paper>
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={handleFetchData}>
            Rechercher
          </Button>
        </Grid>
      </Grid>

      {state.fetched && (
        <>
          <StatsRecap
            loading={state.stats.loading}
            datas={[
              {
                picto: <PersonRounded />,
                name: 'Nombre de clients',
                value: state.stats.usersLen,
              },
              {
                picto: <ShoppingBasketRounded />,
                name: "Nombre d'achat total",
                value: state.stats.ordersLen,
              },
              {
                picto: <EuroRounded />,
                name: 'Montant total',
                value: currencyFormater(state.stats.ordersTotalValue),
              },
              {
                picto: <ShoppingCartRounded />,
                name: 'Taux de réachat',
                value: `${formatNum(state.stats.repurchaseRate)}x`,
              },
              {
                picto: <EqualizerRounded />,
                name: 'Life time value',
                value: `${currencyFormater(state.stats.lifeTimeValue)}`,
              },
            ]}
          />
          {!state.stats.loading && (
            <Grid container>
              <Grid item xs={12}>
                <Paper className={classes.paperFull}>
                  <h3>Redondance de commandes :</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {Object.keys(state.stats.redundancy).map((el, idx) => (
                          <Row key={idx} redundancy={el} data={state.stats.redundancy[el]} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
