import React, {Fragment, useState, useEffect} from 'react';

import {Button, Collapse, Divider, Grid, Hidden, TextField} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ProductCard from "./Product/ProductCard";
import {Search} from "@material-ui/icons";
import {axiosGetRequest} from "../utils/axiosRequests";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: 'white',
  },
  textFieldMobile: {
    '& > div': {
      borderRadius: '30px !important',
    },
    '& > div:hover': {
      '& > fieldset': {
        borderColor: '#adc00d !important'
      }
    },
    '& > div > input': {
      color: 'white'
    },
    '& > div > fieldset': {
      border: 'solid 2px white',
    },
  },
  textField: {
    maxWidth: '940px',
    '& > div': {
      borderRadius: '30px !important',
    },
    '& > div:hover': {
      '& > fieldset': {
        borderColor: '#adc00d !important'
      }
    },
    '& > div > input': {
      color: 'white'
    },
    '& > div > fieldset': {
      border: 'solid 2px white',
    },
  },
  logo2: {
    color: 'white',
    width: '1.5em',
    height: '1.5em'
  },
  productCard: {
    marginRight: '1rem',
    maxWidth: '200px'
  },
  collapse: {
    display: 'flex',
    overflow  : 'scroll',
    paddingTop: '1rem',
    '@media screen and (max-width: 1100px)': {
      display: 'none'
    }
  }
}));

export default function NavCategories(props) {
  const classes = useStyles();
  const {openSearch, setOpenSearch} = props;
  const [search, setSearch] = useState({
    search: '',
    results: []
  });
  const data = props.categories;

  useEffect(() => {
    if(search.search === ''){
      setSearch((prev) => ({
        ...prev,
        results: []
      }));
    }
  }, [search.search]);


  const handleSearch = async(e) => {
    e.preventDefault();
    axiosGetRequest(`/products/search/${search.search}`).then((res) => {
      setSearch((prev) => ({
        ...prev,
        results: res.data.data.products,
      }))
    }).catch((err) => console.log(err))
    console.log(search.search)
  }

  return (
    <div className="nav-navbar2">
      <ul style={{ padding: '0' }}>
        <Hidden smDown>
          <Divider className={classes.divider} orientation="vertical" flexItem color="white" />
        </Hidden>
        {data
          .sort((a, b) => a.position - b.position)
          .map((el, index) => (
            <Fragment key={index}>
              <li>
                <a href={`/catalogue/${el.slug}`}>{el.name}</a>
              </li>
              <Divider orientation="vertical" flexItem className={classes.divider} />
            </Fragment>
          ))}
        <Hidden smDown>
          <li style={{margin: 'auto 0'}}>
            <Button onClick={()=>setOpenSearch((prev)=>(!prev))}><Search className={classes.logo2}/></Button>
          </li>
        </Hidden>
        <Hidden smDown>
          <Divider orientation="vertical" flexItem className={classes.divider} />
        </Hidden>
      </ul>
      <Collapse in={openSearch}>
        <div style={{padding: '1rem 1rem 0 1rem'}}>
          <form onSubmit={handleSearch}>
            <TextField className={classes.textField} size={'small'} fullWidth variant={'outlined'} placeholder={'Recherche'}
                       onChange={(e)=> {
                         e.persist();
                         setSearch((prev) => ({
                           ...prev,
                           search: e.target.value
                         }))
                       }}>
            </TextField>
            <Button type={'submit'} style={{marginLeft: '1rem', display: 'none'}} variant={'contained'} color={'primary'}><Search/></Button>
          </form>
        </div>
        <div style={{display: 'flex', overflow  : 'scroll'}}>
          {search.results && search.results.map((el, idx) => (
              <ProductCard key={idx} infos={el} productCard={classes.productCard}/>
          ))}
        </div>
      </Collapse>
    </div>
  );
}
