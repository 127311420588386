import { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import SubscriberTable from '../../../components/Subscriber/SubscriberTable';
import BasicModal from '../../../components/shared/Modal/BasicModal';
import { useFetchSubscribersWithOrder } from '../../../shared/subscriber/hooks/subscriber';
import plurialFormatter from '../../../utils/plurialFormatter';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
    },
  },
}));

export default function SubscriberList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, subscribers, duplicateOrdersFn } = useFetchSubscribersWithOrder();

  const [selectedSubscribers, setSelectedSubscribers] = useState([]);

  const handleCheckAll = (event) => {
    const { checked } = event.target;
    setSelectedSubscribers(checked ? subscribers.map((subscriber) => subscriber._id) : []);
  };

  const handleCheckOne = (event) => {
    const { checked, value } = event.target;
    setSelectedSubscribers((prev) => (checked ? [...prev, value] : prev.filter((id) => id !== value)));
  };

  const handleSubmit = async () => {
    const response = await duplicateOrdersFn(selectedSubscribers);

    if (response.status !== 200) {
      return enqueueSnackbar('Une erreur est survenue lors de la duplication des commandes', {
        variant: 'error',
      });
    }

    const { result } = response.data.data;

    const isFullSuccess = Object.values(result).every((value) => value === true);

    if (isFullSuccess) {
      return enqueueSnackbar('Toutes les commandes ont été dupliquées', {
        variant: 'success',
      });
    }

    const duplicateOrderLen = Object.values(result).filter((value) => value === true).length;
    if (duplicateOrderLen) {
      enqueueSnackbar(`${duplicateOrderLen} commandes ont été dupliquées`, {
        variant: 'success',
      });
    }
    Object.entries(result).forEach(([subscriberId, response]) => {
      const matchingSubscriber = subscribers.find((subscriber) => subscriber._id === subscriberId);
      const matchingSubscriberFullname = `${matchingSubscriber.lastname} ${matchingSubscriber.firstname}`;

      if (response === 'No order found') {
        return enqueueSnackbar(`Aucune commande trouvée pour l'utilisateur ${matchingSubscriberFullname}`, {
          variant: 'error',
        });
      }

      if (response === 'Order duplication failed') {
        return enqueueSnackbar(
          `La duplication de la commande de l'utilisateur ${matchingSubscriberFullname} a échoué`,
          {
            variant: 'error',
          }
        );
      }
    });
  };

  return (
    <Container className={classes.root}>
      <div className={classes.header}>
        <h1>Gestion des utilisateurs abonnés</h1>
        <div>
          {selectedSubscribers.length > 0 && (
            <p>
              {selectedSubscribers.length}{' '}
              {plurialFormatter(selectedSubscribers.length, 'abonné sélectionné', 'abonnés sélectionnés')}
            </p>
          )}
          <BasicModal
            isDisabled={loading || !selectedSubscribers.length}
            btnLabel="Dupliquer les dernières commandes"
            modalContent="Vous allez dupliquer les dernières commandes des utilisateurs sélectionnés. Elles seront générées automatiquement au même jour de la semaine précédente."
            onSubmit={handleSubmit}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton height={250} />
      ) : (
        <SubscriberTable
          selectedSubscribers={selectedSubscribers}
          subscribers={subscribers}
          onCheckAll={handleCheckAll}
          onCheckOne={handleCheckOne}
        />
      )}
    </Container>
  );
}
