import React, { useState, useEffect, Fragment } from 'react';

import Cookies from 'js-cookie';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// # MATERIAL UI
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Paper,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

// # UTILS
import { Autocomplete } from '@material-ui/lab';
import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';
import { autocompleteGroupedFormater } from '../../../utils/stringFormater';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    marginBottom: '1rem',
    padding: '15px 30px 24px',
    outline: 'none',

    '& h4': {
      margin: '5px 0',
    },
  },
  divider: {
    margin: '1rem',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infos: {
    '& > div': {
      display: 'flex',
      flexGrow: 1,

      '& > .MuiFormControl-root': {
        margin: '0 1rem 1rem 0',
        flexGrow: 1,
      },
    },
  },
}));

export default function CreateProduct() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    categories: [],
    subCategories: [],
    taxes: [],
    tags: [],
    weightUnities: [],
    newProduct: {
      photo: undefined,
      name: undefined,
      reference: undefined,
      price: undefined,
      tvaRate: undefined,
      description: EditorState.createEmpty(),
      category: undefined,
      subCategory: undefined,
      isActive: true,
      isEco: false,
      size: 'M',
      stock: 100,
      tags: [],
      weight: {
        quantity: undefined,
        unity: undefined,
      },
      origin: undefined,
      labels: {
        organic: true,
        wild: false,
        french: false,
        native: false,
      },
    },
  });

  useEffect(() => {
    const token = Cookies.get('jwt');

    // # FETCH TAXES
    axiosGetRequest('/taxes/', token).then((res) => {
      const { taxes } = res.data.data;
      setState((prev) => ({
        ...prev,
        taxes,
      }));
    });

    // # FETCH CATEGORIES
    axiosGetRequest('/categories/', token).then((res) => {
      const { categories } = res.data.data;
      setState((prev) => ({
        ...prev,
        categories,
      }));
    });

    // # FETCH SUB CATEGORIES
    axiosGetRequest('/subcategories/', token).then((res) => {
      const { subCategories } = res.data.data;
      setState((prev) => ({
        ...prev,
        subCategories,
      }));
    });

    // # FETCH TAGS
    axiosGetRequest('/tags/', token).then((res) => {
      const { tags } = res.data.data;
      const formattedTags = tags.map((tag) => ({
        ...tag,
        cleanName: tag.name.toLowerCase().split('.')[1],
      }));
      const tagsFormat = autocompleteGroupedFormater(formattedTags, 'cleanName');
      setState((prev) => ({
        ...prev,
        tags: [...tagsFormat],
      }));
    });

    // # FETCH WEIGHT UNITIES
    axiosGetRequest('/weight/', token).then((res) => {
      const { unities } = res.data.data;
      setState((prev) => ({
        ...prev,
        weightUnities: unities,
      }));
    });
  }, []);

  // # HANDLE IMG UPLOAD
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        photo: file,
      },
    }));
  };

  // # HANDLE EDITOR CHANGES
  const onEditorStateChange = (editorState) => {
    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        description: editorState,
      },
    }));
  };

  // # HANDLE INPUTS CHANGES
  const handleInputsChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        [name]: value,
      },
    }));
  };

  // # HANDLE WEIGHT INPUTS CHANGES
  // TODO : NEED REFACTORING
  const handleInputsWeightChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        weight: {
          ...prev.newProduct.weight,
          [name]: value,
        },
      },
    }));
  };

  // # HANDLE SWITCH CHANGES
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        [name]: checked,
      },
    }));
  };

  // # HANDLE SWITCH LABELS CHANGES
  const handleSwitchLabelsChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        labels: {
          ...prev.newProduct.labels,
          [name]: checked,
        },
      },
    }));
  };

  // # HANDLE AUTOCOMPLETE CHANGES
  const handleAutocompleteMultiple = (value) => {
    setState((prev) => ({
      ...prev,
      newProduct: {
        ...prev.newProduct,
        tags: value,
      },
    }));
  };

  // # HANDLE SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault();
    const token = Cookies.get('jwt');

    const formData = new FormData();

    if (state.newProduct.photo) {
      formData.append('photo', state.newProduct.photo);
    }

    formData.append('name', state.newProduct.name);
    formData.append('reference', state.newProduct.reference);
    formData.append('price', state.newProduct.price);
    formData.append('tvaRate', state.newProduct.tvaRate);
    formData.append(
      'description',
      draftToHtml(convertToRaw(state.newProduct.description.getCurrentContent()))
    );
    formData.append('category', state.newProduct.category);
    formData.append('subCategory', state.newProduct.subCategory);
    formData.append('isActive', state.newProduct.isActive);
    formData.append('isEco', state.newProduct.isEco);
    formData.append('size', state.newProduct.size);
    formData.append('stock', state.newProduct.stock);
    formData.append('quantity', state.newProduct.weight.quantity);
    formData.append('unity', state.newProduct.weight.unity);
    formData.append('origin', state.newProduct.origin);
    formData.append('organic', state.newProduct.labels.organic);
    formData.append('wild', state.newProduct.labels.wild);
    formData.append('french', state.newProduct.labels.french);
    formData.append('native', state.newProduct.labels.native);
    formData.append('tags', JSON.stringify(state.newProduct.tags));

    axiosPostRequest('/products/', token, formData)
      .then((res) => {
        window.location = `/admin/products/${res.data.data.product._id}`;
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  return (
    <>
      <h2>Nouveau produit</h2>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <section className={classes.spaceBetween}>
              <div className={classes.flexCenter}>
                {state.newProduct.photo ? (
                  <img src={URL.createObjectURL(state.newProduct.photo)} alt="upload" width={250} />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`}
                    alt="missing"
                    width={250}
                  />
                )}
                <Input type="file" onChange={handleFileChange} />
              </div>
              <Divider className={classes.divider} orientation="vertical" flexItem />
              <div>
                <h4>Visibilité :</h4>
                <div className={classes.flex}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isActive"
                        color="primary"
                        checked={state.newProduct.isActive}
                        onChange={handleSwitchChange}
                      />
                    }
                    label="Actif"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name="isEco"
                        color="primary"
                        checked={state.newProduct.isEco}
                        onChange={handleSwitchChange}
                      />
                    }
                    label="Eco-acteurs"
                  />
                </div>
                <Divider className={classes.divider} />
                <div>
                  <h4>Labels :</h4>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          name="organic"
                          color="primary"
                          checked={state.newProduct.labels.organic}
                          onChange={handleSwitchLabelsChange}
                        />
                      }
                      label="Bio"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="wild"
                          color="primary"
                          checked={state.newProduct.labels.wild}
                          onChange={handleSwitchLabelsChange}
                        />
                      }
                      label="Sauvage"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          name="french"
                          color="primary"
                          checked={state.newProduct.labels.french}
                          onChange={handleSwitchLabelsChange}
                        />
                      }
                      label="Français"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="native"
                          color="primary"
                          checked={state.newProduct.labels.native}
                          onChange={handleSwitchLabelsChange}
                        />
                      }
                      label="Local"
                    />
                  </div>
                </div>
                <Divider className={classes.divider} />
                <TextField
                  name="origin"
                  label="Origine"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={state.newProduct.origin}
                  onChange={handleInputsChange}
                />
              </div>
            </section>
            <Divider className={classes.divider} />
            <section className={classes.infos}>
              <h3>Informations :</h3>
              <div>
                <TextField
                  name="name"
                  label="Nom"
                  type="text"
                  variant="outlined"
                  value={state.newProduct.name}
                  onChange={handleInputsChange}
                />
                <TextField
                  name="reference"
                  label="Référence"
                  type="text"
                  variant="outlined"
                  value={state.newProduct.reference}
                  onChange={handleInputsChange}
                />
              </div>
              <div>
                <FormControl variant="outlined">
                  <InputLabel>Rubrique</InputLabel>
                  <Select
                    native
                    label="Rubrique"
                    name="category"
                    value={state.newProduct.category}
                    onChange={handleInputsChange}
                  >
                    <option value="" />
                    {state.categories.map((category, idx) => (
                      <option key={idx} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined">
                  <InputLabel>Sous-rubrique</InputLabel>
                  <Select
                    native
                    label="Sous-rubrique"
                    name="subCategory"
                    value={state.newProduct.subCategory}
                    onChange={handleInputsChange}
                  >
                    <option value="" />
                    {state.subCategories.map((subCategory, idx) => (
                      <option key={idx} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <TextField
                  name="price"
                  label="Prix"
                  type="number"
                  variant="outlined"
                  value={state.newProduct.price}
                  onChange={handleInputsChange}
                />
                <FormControl variant="outlined">
                  <InputLabel>TVA</InputLabel>
                  <Select
                    native
                    name="tvaRate"
                    label="TVA"
                    onChange={handleInputsChange}
                    value={state.newProduct.tvaRate}
                  >
                    <option value="" />
                    {state.taxes.map((tax, idx) => (
                      <option key={idx} value={tax.id}>
                        {tax.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  name="stock"
                  label="Stock"
                  type="number"
                  variant="outlined"
                  value={state.newProduct.stock}
                  onChange={handleInputsChange}
                />
              </div>
              <div>
                <TextField
                  name="quantity"
                  label="Poids"
                  type="number"
                  variant="outlined"
                  value={state.newProduct.weight.quantity}
                  onChange={handleInputsWeightChange}
                />
                <FormControl variant="outlined">
                  <InputLabel>Unité</InputLabel>
                  <Select
                    native
                    name="unity"
                    label="Unité"
                    value={state.newProduct.weight.unity}
                    onChange={handleInputsWeightChange}
                  >
                    <option value="" />
                    {state.weightUnities.map((unity, idx) => (
                      <option key={idx} value={unity.id}>
                        {unity.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel>Taille</InputLabel>
                  <Select
                    native
                    name="size"
                    label="Taille"
                    onChange={handleInputsChange}
                    value={state.newProduct.size}
                  >
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="SOLO">Solo</option>
                  </Select>
                </FormControl>
              </div>
            </section>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <h3>Description :</h3>
            <Editor
              editorState={state.newProduct.description}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                options: ['inline', 'colorPicker', 'link'],
                inline: {
                  options: ['bold', 'italic'],
                },
              }}
              editorStyle={{
                border: '1px solid #F1F1F1',
                minHeight: '150px',
                padding: '0 1rem',
                marginBottom: '1rem',
              }}
              onEditorStateChange={onEditorStateChange}
            />
          </Paper>
          <Paper className={classes.paper}>
            <h3>Ajouter des tags :</h3>
            <Autocomplete
              multiple
              value={state.newProduct.tags}
              onChange={(event, value) => handleAutocompleteMultiple(value)}
              options={state.tags.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
              groupBy={(option) => option.firstLetter}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => `${option.name}`}
              renderOption={(option) => <>{option.name}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  variant="outlined"
                  style={{
                    width: '100%',
                    marginBottom: '1rem',
                  }}
                />
              )}
            />
          </Paper>
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Créer le produit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
