const closureTimes = (closureTimes) => {
  localStorage.setItem('closureTimes', JSON.stringify(closureTimes));
};

const setUpClosureTimes = (payload) => {
  const closureTimesTemp = {
    start: payload.start,
    end: payload.end,
    updatedAt: payload.updatedAt
  }

  console.log('setUp', payload);

  closureTimes(closureTimesTemp);
  return closureTimesTemp;
}

export const SystemReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        closureTimes: {
          ...setUpClosureTimes(action.payload),
        },
      };
  }
}