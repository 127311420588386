import React from 'react';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListSubheader,
  List,
  ListItemText,
  Collapse,
} from '@material-ui/core';

import {LocalShipping, ExpandLess, ExpandMore, LocationOn, Equalizer, LocationCity} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function SalesMenu() {
  const classes = useStyles();
  const [openCities, setOpenCities] = React.useState(false);
  const [openZones, setOpenZones] = React.useState(false);

  const handleOpenCities = () => {
    setOpenCities((prev) => !prev);
  };

  const handleOpenZones = () => {
    setOpenZones((prev) => !prev);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Livraison
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button onClick={handleOpenCities}>
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText primary="Villes de livraison" />
        {openCities ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCities} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/deliveryCities">Toutes les villes</Link>
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleOpenZones}>
        <ListItemIcon>
          <LocationOn />
        </ListItemIcon>
        <ListItemText primary="Zones de livraison" />
        {openZones ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openZones} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/deliveryZones">Toutes les zones</Link>
          </ListItem>
        </List>
      </Collapse>
      <ListItem button>
        <ListItemIcon>
          <LocationCity />
        </ListItemIcon>
        <Link href="/admin/deliveryServices">Services de livraison</Link>
      </ListItem>
    </List>
  );
}
