import React from 'react';

// # MATERIAL
import { Container } from '@material-ui/core';

// # IMAGES
import img1 from '../../../assets/img/picto/illu.png';
import img2 from '../../../assets/img/zero_fraise.png';
import img3 from '../../../assets/img/picto/ordi.png';
import img4 from '../../../assets/img/corbeille-4.png';
import img5 from '../../../assets/img/zero_tomate.png';
import img6 from '../../../assets/img/picto/producteur.png';
import img7 from '../../../assets/img/montagne.png';

// # STYLE
import '../../../assets/css/styleccm.css';

export default function EcoPoints() {
  return (
    <div id="ccm">
      <div className="container">
        <h1>comment ça marche ?</h1>
      </div>
      <Container maxWidth="md" className="illu">
        <div className="line">
          <img src={img1} alt="herobio" />
          <div className="section">
            <h3>je suis éco-acteur</h3>
            <ul>
              <li>J’organise des commandes groupées de paniers Bio</li>
              <li>Je crée du lien et du partage</li>
              <li>Je gagne des Eco-points</li>
            </ul>
          </div>
        </div>
        <div className="line">
          <div className="section">
            <h3>je rassemble</h3>
            <ul>
              <li>
                Je communique auprès de mon entourage sur les commandes groupées que j’organise.
              </li>
              <li>
                Bio Culture m’accompagne dans ma démarche avec les moyens de communication adaptés
              </li>
            </ul>
          </div>
          <img src={img2} alt="herobio" />
        </div>
        <div className="line">
          <img src={img3} alt="herobio" />
          <div className="section">
            <h3>je lance mon invitation</h3>
            <ul>
              <li>Je choisis le jour et l’heure auxquels je souhaite être livré</li>
              <li>Je personnalise le texte proposé</li>
              <li>J’envoie ma proposition de commande groupée à mes contacts</li>
            </ul>
          </div>
        </div>
        <div className="line">
          <div className="section">
            <h3>On se fait plaisir !</h3>
            <ul>
              <li>Mes contacts passent commande en quelques clics, sans créer de compte</li>
              <li>Ils paient par carte bancaire, sur un site sécurisé</li>
              <li>Ils reçoivent un ticket de paiement sur leur boîte email.</li>
            </ul>
          </div>
          <img src={img4} alt="herobio" />
        </div>
        <div className="line">
          <img src={img5} alt="herobio" />
          <div className="section">
            <h3>je suis la progression</h3>
            <ul>
              <li>
                Je suis en permanence : le nombre de réponses, de commandes passées, grâce à mon
                tableau de bord
              </li>
              <li>Je visualise en direct les Eco-points que je vais recevoir</li>
              <li>Je peux relancer les plus distraits de mes invités en un clic</li>
            </ul>
          </div>
        </div>
        <div className="line">
          <div className="section">
            <h3>Le jour j</h3>
            <ul>
              <li>Bio Culture me livre mes paniers Bio</li>
              <li>Mes participants viennent les récupérer dans le créneau que j’ai indiqué</li>
              <li>
                Je reçois mes Eco-points que je pourrai utiliser dès ma prochaine commande en bon
                d’achat ou en don alimentaire.
              </li>
            </ul>
          </div>
          <img src={img6} alt="herobio" />
        </div>
      </Container>
      <img id="montagne" src={img7} alt="montagne" />
      <div className="bande">
        <h3>bioculture et moi</h3>
        <p>
          L'équipe de bioculture est à mon écoute pour m'aider et m'accompagner
          <br /> Bio Culture m’invite à des rencontres “ Eco-Acteurs ” pour échanger, partager,
          déguster des fruits et légumes...
        </p>
      </div>
    </div>
  );
}
