import React from 'react';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import MailIcon from '@material-ui/icons/Mail';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import BuildIcon from '@material-ui/icons/Build';

// STYLE//
import '../assets/css/navbar.css';

export default function NavbarEA() {
  return (
    <div id="navbareco">
      <div className="nav-navbareco">
        <ul>
          <li>
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <MailIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Mes invitations
            </a>
            <ul className="dropdown">
              <li>
                <a href="/create_invitation">Créer une invitation</a>
              </li>
              <li>
                <a href="/invitations">Mes invitations</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <PermContactCalendarIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Mes contacts
            </a>
            <ul className="dropdown">
              <li>
                <a href="/create_contact">Ajouter des contacts</a>
              </li>
              <li>
                <a href="/contact_list">Mes contacts</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/vos-eco-points">
              <LocalOfferIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Mes éco-points
            </a>
          </li>
          <li>
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <BuildIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Mes options
            </a>
            <ul className="dropdown">
              {/* <li>
                <a href="/#">FAQ</a>
              </li> */}
              <li>
                <a href="/contact">Poser une question</a>
              </li>
              {/* <li>
                <a href="/#">Je me lance</a>
              </li> */}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
