import React, { useState, useEffect } from 'react';

import axios from 'axios';

// COMPOSANTS
import ArticleCard from '../../../components/Front/Blog/ArticleCard';

import { useLocation, useParams } from 'react-router-dom';

// # CSS
import '../../../assets/css/pages/productPages.css';

// MATERIAL UI

import { Grid, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '14px',
    border: '1px solid rgb(0 0 0 0 70%)',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
      '& > div > span': {
        transition: '0.2s',
        backgroundColor: '#ADC00D',
        color: 'white',
        '& > a': {
          color: 'white',
        },
      },
    },
  },
  h1: {
    fontSize: '3rem !important',
    margin: '2rem 0 !important',
  },
  h3: {
    fontSize: '2rem !important',
    width: '100%',
    textAlign: 'center',
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      margin: '5rem 10rem 2rem 10rem',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60%',
  },
  content: {
    textAlign: 'left',
    fontFamily: 'Bitter',
    fontSize: '1rem',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cover: {
    width: '40%',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  divider: {
    backgroundColor: '#ADC00D',
  },
  next: {
    padding: '0.5rem 0',
    color: '#ADC00D',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: '#ADC00D',
      color: 'white',
    },
  },
  overflow: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    marginTop: '0',
    marginBottom: '0',
    '& > p': {
      margin: '0',
      color: 'black',
    },
  },
  link: {
    padding: '0.5rem !important',
    textAlign: 'center !important',
    borderRadius: '0 0 14px 0',
  },
  a: {
    color: '#ADC00D',
    textAlign: 'center',
  },
  article: {
    display: 'flex',
    borderRadius: '14px',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    minHeight: '312px',
    '&:hover': {
      boxShadow: 'none',
      '& > div > p': {
        transition: '0.5s',
        backgroundColor: '#ADC00D',
        '&>a': {
          color: 'white',
        },
      },
    },
    '& > figure': {
      width: '40%',
      margin: '0',
      '& > img': {
        width: '100%',
        borderRadius: '14px 0 0 14px',
      },
    },
  },
  text: {
    padding: '1rem',
  },
  actions: {
    borderRadius: '14px',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  legend: {
    position: 'absolute',
    top: '350px',
    padding: '2rem',
    backgroundColor: 'rgba(0,0,0, 0.6)',
  },
  title: {
    margin: 0,
    color: 'white',
    fontSize: '1.5rem',
  },
  date: {
    margin: '1rem 0 0 0',
    textAlign: 'left',
    color: 'lightgrey',
  },
  chip: {
    marginRight: '0.5rem',
    '& > svg': {
      width: '20px !important',
    },
  },
  margin: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 3rem',
    },
  },
}));

export default function FilterArticles(props) {
  const classes = useStyles();
  const location = useLocation();
  const { contentType } = location.state;
  const { tag } = useParams();
  console.log(contentType);
  const [state, setState] = useState({
    results: [],
    tagName: '',
    skip: 0,
  });

  const handleMoreArticles = async () => {
    const skiped = state.skip + 6;
    const results = contentType === 'lesActusDuBio' ? await axios
        .get(
            `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&content_type=${contentType}&limit=6&skip=${skiped}&order=-fields.data`
        )
        .then((res) => {
          console.log(res.data.items);
          return res.data.items;
        })
        .catch((err) => {
          console.log(err);
        }) : await axios
        .get(
            `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&content_type=${contentType}&metadata.tags.sys.id[in]=${tag}&limit=6&skip=${skiped}&order=-fields.data`
        )
        .then((res) => {
          console.log(res.data.items);
          return res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });

    results.forEach((article) => {
      if (article.fields.text.content[0].content[0].value) {
        article.fields.infos = article.fields.text.content[0].content[0].value.split('\n');
      }
    });

    results.forEach(async (article) => {
      await axios
        .get(
          `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/assets/${article.fields.image.sys.id}?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8`
        )
        .then((res) => {
          setState((prev) => ({
            ...prev,
            loading: false,
          }));
          return (article.fields.imageURL = res.data.fields.file.url);
        })
        .catch((err) => {
          console.log(err);
        });
    });

    setState((prev) => ({
      ...prev,
      results: [...prev.results, ...results],
      skip: prev.skip + 6,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const resTag = await axios
        .get(
          `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/tags/${tag}?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8`
        )
        .then((res) => {
          console.log(res.data);
          return res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(resTag);
      let results;
      if (contentType === 'lesActusDuBio') {
        results = await axios
          .get(
            `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&content_type=${contentType}&limit=6&order=-fields.data`
          )
          .then((res) => {
            console.log('res :', res.data);
            return res.data.items;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        results = await axios
          .get(
            `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&content_type=${contentType}&metadata.tags.sys.id[in]=${tag}&limit=6`
          )
          .then((res) => {
            console.log('res:', res.data);
            return res.data.items;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      /* results.forEach((article) => {
                if(article.fields.text.content[0].content[0].value){
                    article.fields.infos = article.fields.text.content[0].content[0].value.split("\n");
                }
            }) */

      results.forEach(async (article) => {
        await axios
          .get(
            `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/assets/${article.fields.image.sys.id}?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8`
          )
          .then((res) => {
            setState((prev) => ({
              ...prev,
              loading: false,
            }));
            return (article.fields.imageURL = res.data.fields.file.url);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      setState((prev) => ({
        ...prev,
        results,
        tagName: resTag,
      }));
    };
    fetchData();
  }, [tag]);

  return (
    <div className="margin">
      <Grid container>
        <Grid item xs={12}>
          <h1>{state.tagName.split('.')[1]}</h1>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <Grid container spacing={3}>
            {state.results.map((article, idx) => (
              <Grid key={idx} className={classes.grid} item xs={12} md={6}>
                <ArticleCard contentType={contentType} article={article} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {contentType === 'test' ? (
            <Button
              style={{ margin: '1rem 0' }}
              className="btn-orange"
              onClick={handleMoreArticles}
            >
              Charger plus de recettes
            </Button>
          ) : (
            <Button
              style={{ margin: '1rem 0' }}
              className="btn-orange"
              onClick={handleMoreArticles}
            >
              Charger plus d'articles
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
