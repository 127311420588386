import React, { useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { makeStyles, TextField, Button, Modal, Backdrop, Fade } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    margin: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateDeliveryZoneModal() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [state, setState] = useState({
    deliveryZone: {
      name: '',
      charges: [
        {
          threshold: 0,
          costsHT: 0,
          costsTTC: 0,
        },
        {
          threshold: 0,
          costsHT: 0,
          costsTTC: 0,
        },
        {
          threshold: 0,
          costsHT: 0,
          costsTTC: 0,
        },
      ],
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCharges = (idx, value) => {
    const arr = state.deliveryZone.charges;
    arr[idx].costsHT = value;
    arr[idx].costsTTC = Math.round((value + value * (20 / 100)) * 100) / 100;

    setState((prev) => ({
      ...prev,
      deliveryZone: {
        ...prev.deliveryZone,
        charges: [...arr],
      },
    }));
  };

  const handleThreshold = (idx, value) => {
    const arr = state.deliveryZone.charges;
    arr[idx].threshold = value;

    setState((prev) => ({
      ...prev,
      deliveryZone: {
        ...prev.deliveryZone,
        charges: [...arr],
      },
    }));
  };

  const handleAddZone = async () => {
    const token = Cookies.get('jwt');
    await axios
      .post(`${process.env.REACT_APP_API}/deliveryZones`, state.deliveryZone, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  return (
    <div>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        Nouvelle Zone
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Créer une nouvelle zone</h2>
            <TextField
              fullWidth
              className={classes.marginB}
              id="zonename"
              label="Nom de la Zone"
              type="text"
              variant="outlined"
              onChange={(e) => {
                const name = e.target.value;
                setState((prev) => ({
                  ...prev,
                  deliveryZone: {
                    ...prev.deliveryZone,
                    name,
                  },
                }));
              }}
            />
            {[1, 2, 3].map((el, idx) => (
              <div key={idx} className={classes.flex}>
                <TextField
                  className={classes.marginR}
                  label="Seuil"
                  type="number"
                  variant="outlined"
                  onChange={(e) => handleThreshold(idx, e.target.value * 1)}
                />
                <TextField
                  className={classes.marginR}
                  label="Frais HT"
                  type="number"
                  variant="outlined"
                  onChange={(e) => handleCharges(idx, e.target.value * 1)}
                />
                <TextField
                  label="Frais TTC"
                  type="number"
                  variant="outlined"
                  value={state.deliveryZone.charges[idx].costsTTC}
                />
              </div>
            ))}
            <Button variant="contained" color="primary" fullWidth onClick={handleAddZone}>
              Valider
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
