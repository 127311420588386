import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

// # CONTEXT
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Modal,
  Collapse,
  Hidden,
  Button, TextField, CardActionArea, Card, CardMedia, CardContent, Typography, Chip, Divider, Badge, IconButton
} from '@material-ui/core';
import {AccountCircle, LocalShipping} from "@material-ui/icons";

// # CONTEXT
import { UserContext } from '../contexts/UserContext/UserContext';
import { CartContext } from '../contexts/CartContext';

// # MOMENT
import 'moment/locale/fr';

// # CUSTOM COMPONENTS
import Cart from './Cart';
import ProfileNav from './ProfileNav';
import NavShipping from './NavShipping';
import Shipping from './Shipping';
import NavbarEA from './NavbarEcoacteur';
import NavCategories from './NavCategories';

// # ASSETS
import logo from '../assets/img/logo-bioculture.jpg';

// # CSS
import '../assets/css/navbar.css';

// # UTILS
import { getSchedules } from '../utils/getSchedules';
import {makeStyles} from "@material-ui/core/styles";
import {Alarm, People, Search, ShoppingCart, Whatshot} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Link} from "react-router-dom";
import {axiosGetRequest} from "../utils/axiosRequests";
import ProductCard from "./Product/ProductCard";
import GroupedInfos from "./Product/Card/GroupedInfos";
import GroupedLabels from "./Product/Card/GroupedLabels";
import currencyFormater from "../utils/currencyFormater";
import RemoveButton from "./Product/Card/RemoveButton";
import AddButton from "./Product/Card/AddButton";
import ProductDrawer from "./Product/SwipeableDrawer/ProductDrawer";
import CustomDrawer from "./Navigation/CustomDrawer";
import {SystemContext} from "../contexts/SystemContext/SystemContext";

const useStyles = makeStyles((theme)=> ({
  red: {
    color: 'red',
  },
  logo: {
    color: '#888888'
  },
  textField: {
    '& > div': {
      borderRadius: '30px !important',
    },
    '& > div:hover': {
      '& > fieldset': {
        borderColor: '#adc00d !important'
      }
    },
    '& > div > input': {
      color: 'black'
    },
    '& > div > fieldset': {
      border: 'solid 2px #e94c17',
    },
  },
  productCard: {
    marginRight: '1rem',
    maxHeight: '88vh',
    minWidth: '200px',
    '& > figure': {
      maxWidth: '200px',
      margin: '0 auto'
    },
  },
  buttonActive: {
    backgroundColor: '#e5e5e5 !important',
  },
  searchDiv: {
    height: 'calc(100vh - 195px)'
  },
  name: {
    fontFamily: 'Amatic SC, sans-serif',
    margin: '0',

    [theme.breakpoints.down(555)]: {
      fontSize: '1.4rem !important',
    },
  },
  price: {
    textAlign: 'right',
    fontWeight: 500,
    color: theme.palette.secondary.main,
    margin: '0 0 0.5rem 0',

    [theme.breakpoints.down(555)]: {
      margin: 0,
      textAlign: 'center',
    },
  },
}))

export default function Navbar(props) {
  const classes = useStyles();
  const closureTimes = useContext(SystemContext);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [code, setCode] = useState();
  const [delivery, setDelivery] = useState({});
  const [info, setInfo] = useState(false);
  const [schedule, setSchedule] = useState();
  const [search, setSearch] = useState({
    search: '',
    results: [],
  });
  const [drawerProduct, setDrawerProduct] = useState(false);
  const [details, setDetails] = useState();
  const [matchingItem, setMatchingItem] = useState(null);
  const {
    setDeliveryInfos,
    setDeliveryAddress,
    setDeliveryBillingAddress,
    deliveryInfos,
    drawerCart,
    drawer,
    setCompanyDiscount,
    setDiscount,
    cartItems,
    addProduct,
  } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const updateParent = (value) => setDrawerProduct(value);

  const handleChange = (event) => {
    setSchedule(event.target.value);
  };

  const handleSchedule = (temp) => {
    const date = moment(temp.split('(')[0].trim(), 'dddd Do MMMM YYYY', 'fr').format('YYYY-MM-DD');
    const schedule = temp.substring(0, temp.length - 1).split('(')[1];

    setDeliveryInfos({
      ...deliveryInfos,
      deliveryDate: date,
      deliverySchedule: schedule,
    });

    setOpen(false);

    enqueueSnackbar('Votre choix a bien été enregistré', {
      variant: 'success',
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className="modal">
      <h2>Entrez votre code entreprise</h2>
      <div className="code">
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12}>
            <p>Le code entreprise est désormais à renseigner sur la page livraison</p>
          </Grid>
            <Grid item xs={12}>
            <button className="btn-orange" type="button" onClick={() => handleClose()}>
              OK
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      const categories = await axios
        .get(`${process.env.REACT_APP_API}/categories/?isActive=true`)
        .catch((err) => {
          console.log(err);
        });
      setData(categories.data.data.categories);
      setLoading((prev) => !prev);
    };
    fetchData();
  }, []);

  const handleSearch = async(e) => {
    e.preventDefault();
    axiosGetRequest(`/products/search/${search.search}`).then((res) => {
      setSearch((prev) => ({
        ...prev,
        results: res.data.data.products,
      }))
    }).catch((err) => console.log(err))
    console.log(search.search)
  }

  return (
    <section id="navbar">
      <nav className={props.navbarPosition}>
        {/* <div style={{ backgroundColor: '#bfcf38', padding: '0.5rem', color: 'white' }}>
          <p style={{ margin: '0', fontWeight: '100' }}>
            Nous restons ouverts le <span style={{ color: '#f05c33' }}>Mercredi 14 Juillet</span> pour effectuer la
            livraison de vos paniers bio
          </p>
        </div> */}
        <div className="nav-navbar">
          <figure>
            <a href="/">
              <img src={logo} alt="Logo Bioculture" />
            </a>
          </figure>
          <Hidden smDown>
            <ul>
              <li>
                <a href="/catalogue/les-paniers-bio">Je commande</a>
              </li>
              <li>
                <a href="/valeurs">Nos valeurs</a>
                <ul className="dropdown">
                  <li>
                    <a href="/nos-valeurs">Nos valeurs</a>
                  </li>
                  <li>
                    <a href="/nos-producteurs">Nos producteurs</a>
                  </li>
                  <li>
                    <a href="/nos-produits">Nos produits</a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://la-cooperative-bio-culture.fr/"
                    >
                      Projet agricole
                    </a>
                  </li>
                  <li>
                    <a href="/zero-dechets">Zéro déchet</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/offre-entreprise">Offre entreprises</a>
                <ul className="dropdown">
                  <li>
                    <a href="/offre-entreprise">Une offre adaptée</a>
                  </li>
                  <li>
                    <a href="/corbeille-fruit">Corbeille de fruits</a>
                  </li>
                  <li>
                    <button
                      className="btn-orange"
                      style={{ margin: '1rem 0' }}
                      onClick={handleOpen}
                    >
                      Code Entreprise
                    </button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      {body}
                    </Modal>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/c-c-m">Commande groupée</a>
                <ul className="dropdown">
                  <li>
                    <a href="/c-c-m">Comment ça marche</a>
                  </li>
                  <li>
                    <a href="/eco-points">Ecopoints</a>
                  </li>
                  <li>
                    <a href="/eco-acteur">Devenir eco-acteur</a>
                  </li>
                </ul>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="/blog">
                  Recettes
                </a>
              </li>
            </ul>
          </Hidden>
          <div className="nav-pictos">
          <Hidden mdUp>
            <IconButton
                aria-label="voir le painier"
                onClick={()=>setOpenSearch((prev) => !prev)}>
              <Search/>
            </IconButton>
          </Hidden>
              <ProfileNav/>
              <NavShipping/>
            <Cart drawer={drawerCart} />
            <Hidden mdUp>{props.navbar}</Hidden>
          </div>
        </div>
        <CustomDrawer updateParent={updateParent} open={drawerProduct}>
          {details && <ProductDrawer infos={details} setDrawer={setDrawerProduct}/>}
        </CustomDrawer>
        {loading && !window.location.href.includes('/blog') ? (
          <NavCategories setOpenSearch={setOpenSearch} openSearch={openSearch} categories={data} />
        ) : null}
        <Hidden smDown>
          {user.role === 'eco' && !window.location.href.includes('/blog') ? <NavbarEA /> : null}
        </Hidden>
      </nav>
    </section>
  );
}
