import React, { createContext, useReducer } from 'react';
import { InvitationReducer } from './InvitationReducer';

export const InvitationContext = createContext();

const invitationStorage = localStorage.getItem('invitation')
  ? JSON.parse(localStorage.getItem('invitation'))
  : {
      id: '',
      uuid: '',
      userToken: '',
      deliveryInfos: {
        deliveryAddress: {
          address: '',
          city: '',
          complement: '',
          country: '',
          zipCode: '',
        },
        deliveryDate: '',
        deliverySchedule: '',
      },
      sender: {
        email: '',
        firstname: '',
        lastname: '',
      },
    };

const initialState = {
  invitation: invitationStorage,
};

function InvitationContextProvider({ children }) {
  const [state, dispatch] = useReducer(InvitationReducer, initialState);

  const setInvitation = (payload) => {
    dispatch({ type: 'SET_INVIT', payload });
  };

  const contextValues = {
    setInvitation,
    ...state,
  };

  return <InvitationContext.Provider value={contextValues}>{children}</InvitationContext.Provider>;
}

export default InvitationContextProvider;
