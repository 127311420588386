import React, { useState, useEffect, useContext, Fragment } from 'react';

import moment from 'moment';
import 'moment/locale/fr';

// # CONTEXT
import {SystemContext} from "../../../contexts/SystemContext/SystemContext";

// # MATERIAL.UI
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Avatar,
  TextField,
  Button,
  Collapse,
  Divider,
  Grid,
  Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../contexts/UserContext/UserContext';

// # COMPONENTS
import InvitationMailPreview from '../../../components/Invitations/InvitationMailPreview';

// # UTILS
import Meta from '../../../utils/metaDescription';
import { getSchedules } from '../../../utils/getSchedules';
import { axiosPostRequest, axiosGetRequest } from '../../../utils/axiosRequests';

// # IMG
import img from '../../../assets/img/fond_invitation.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '80%',
  },
  container: {
    marginTop: '6rem',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  img: {
    marginBottom: '-5px',
    width: '100%',
  },
  root: {
    width: '60%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
  },
  flex: {
    marginTop: '30px',
    display: 'flex',
    width: '60%',
    margin: '0 auto',
    justifyContent: 'space-between',
  },
  flex2: {
    display: 'flex',
    width: '60%',
    margin: '0 auto',
  },
  Button: {
    backgroundColor: 'white',
    color: '#f05c34',
    fontSize: '1rem',
    border: '1px solid #f05c34',
    textTransform: 'capitalize',
    padding: '14px',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#f05c34',
      color: 'white',
    },
  },
  Button2: {
    backgroundColor: 'white !important',
    color: '#f05c34 !important',
    border: '1px solid #f05c34 !important',
    marginRight: '2vw',
    height: 'fit-content',
  },
  ButtonAnul: {
    backgroundColor: '#bfbdbd !important',
    color: 'white !important',
    marginRight: '2vw',
    height: 'fit-content',
  },
  span: {
    color: 'red',
  },
  input: {
    width: '80%',
    marginTop: '30px',
  },
  inputDate: {
    width: '80%',
  },
}));

export default function Invitation() {
  moment.locale('fr');
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { user, setEcoInfos } = useContext(UserContext);
  const { closureTimes } = useContext(SystemContext);

  const [state, setState] = useState({
    address: [],
    deliveryCity: {},
    inputsHandler: {
      step1: '',
      step2: {
        value: '',
        display: false,
        schedules: [],
      },
      step3: {
        display: false,
        contacts: [],
      },
    },
    dataSend: {
      invitation: {
        contacts: [],
        ecoInvitationSettings: {
          customMessage: '',
          pickupSchedule: {
            from: '17:00',
            to: '18:00',
          },
        },
        deliveryInfos: {
          deliveryDate: '',
          deliverySchedule: '',
          deliveryAddress: {
            address: '',
            zipCode: '',
            city: '',
            country: '',
            complement: '',
          },
        },
      },
    },
  });

  useEffect(() => {
    axiosGetRequest('/users/contacts', user.token)
      .then(() => {
        setState((prev) => ({
          ...prev,
          address: [...user.addresses],
          inputsHandler: {
            ...prev.inputsHandler,
            step3: {
              ...prev.inputsHandler.step3,
              contacts: [...user.ecoData.contacts],
            },
          },
          dataSend: {
            ...prev.dataSend,
            invitation: {
              ...prev.dataSend.invitation,
              ecoInvitationSettings: {
                ...prev.dataSend.invitation.ecoData,
                customMessage: user.ecoData.customMessage || '',
                pickupSchedule: {
                  from: user.ecoData.pickupSchedule.from || '17:00',
                  to: user.ecoData.pickupSchedule.to || '18:00',
                },
              },
            },
          },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [user, user.token]);

  const setSchedules = (zipCode) => {
    axiosGetRequest(`/deliveryCities/?zipCode=${zipCode}`, user.token)
      .then((res) => {
        const resData = res.data.data.deliveryCity[0];
        setState((prev) => ({
          ...prev,
          deliveryCity: { ...resData },
          inputsHandler: {
            ...prev.inputsHandler,
            step2: {
              ...prev.inputsHandler.step2,
              schedules: [...resData.infos],
              display: true,
            },
          },
        }));
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleStep1 = (e) => {
    const { value } = e.target;
    const adrArr = value.split('//');
    const address = adrArr[0];
    const city = adrArr[1];
    const zipCode = adrArr[2];
    const complement = adrArr[3];

    setState((prev) => ({
      ...prev,
      inputsHandler: {
        ...prev.inputsHandler,
        step1: value,
      },
      dataSend: {
        ...prev.dataSend,
        invitation: {
          ...prev.dataSend.invitation,
          deliveryInfos: {
            ...prev.dataSend.invitation.deliveryInfos,
            deliveryAddress: {
              address,
              zipCode,
              city,
              country: 'France',
              complement,
            },
          },
        },
      },
    }));

    setSchedules(zipCode);
  };

  const handleDelivery = (e) => {
    const { value } = e.target;
    const idx = value * 1;
    const deliveryInfos = getSchedules(state.inputsHandler.step2.schedules, closureTimes, 8, 'days')[idx];

    const date = moment(deliveryInfos.split('(')[0].trim(), 'dddd Do MMMM YYYY', 'fr').format(
      'YYYY-MM-DD'
    );
    const schedule = deliveryInfos.substring(0, deliveryInfos.length - 1).split('(')[1];

    setState((prev) => ({
      ...prev,
      inputsHandler: {
        ...prev.inputsHandler,
        step2: {
          ...prev.inputsHandler.step2,
          value,
        },
        step3: {
          ...prev.inputsHandler.step3,
          display: true,
          checkedIdx: [],
        },
      },
      dataSend: {
        ...prev.dataSend,
        invitation: {
          ...prev.dataSend.invitation,
          deliveryInfos: {
            ...prev.dataSend.invitation.deliveryInfos,
            deliveryDate: date,
            deliverySchedule: schedule,
          },
        },
      },
    }));
  };

  const handleMassive = (action) => {
    if (action === 'all') {
      const ids = state.inputsHandler.step3.contacts.map((contact) => contact.id);
      return setState((prev) => ({
        ...prev,
        dataSend: {
          ...prev.dataSend,
          invitation: {
            ...prev.dataSend.invitation,
            contacts: [user.id, ...ids],
          },
        },
      }));
    }

    return setState((prev) => ({
      ...prev,
      dataSend: {
        ...prev.dataSend,
        invitation: {
          ...prev.dataSend.invitation,
          contacts: [],
        },
      },
    }));
  };

  const handleToggle = (id) => {
    const contact = state.dataSend.invitation.contacts.find((el) => el === id);

    if (contact) {
      const newContacts = state.dataSend.invitation.contacts.filter((el) => el !== id);
      return setState((prev) => ({
        ...prev,
        dataSend: {
          ...prev.dataSend,
          invitation: {
            ...prev.dataSend.invitation,
            contacts: [...newContacts],
          },
        },
      }));
    }

    return setState((prev) => ({
      ...prev,
      dataSend: {
        ...prev.dataSend,
        invitation: {
          ...prev.dataSend.invitation,
          contacts: [...prev.dataSend.invitation.contacts, id],
        },
      },
    }));
  };

  const handleChangeMail = (event) => {
    event.persist();
    const { value } = event.target;

    setState((prev) => ({
      ...prev,
      dataSend: {
        ...prev.dataSend,
        invitation: {
          ...prev.dataSend.invitation,
          ecoInvitationSettings: {
            ...prev.dataSend.invitation.ecoInvitationSettings,
            customMessage: value,
          },
        },
      },
    }));
  };

  const handleCreateInvit = () => {
    axiosPostRequest('/invitations/', user.token, state.dataSend)
      .then(() => {
        enqueueSnackbar("L'invitation a été créée", {
          variant: 'success',
        });
        setEcoInfos(state.dataSend.invitation.ecoInvitationSettings);
        window.location = '/invitations';
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
      });
  };

  const handleTime = (e, type) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      dataSend: {
        ...prev.dataSend,
        invitation: {
          ...prev.dataSend.invitation,
          ecoInvitationSettings: {
            ...prev.dataSend.invitation.ecoInvitationSettings,
            pickupSchedule: {
              ...prev.dataSend.invitation.ecoInvitationSettings.pickupSchedule,
              [type]: e.target.value,
            },
          },
        },
      },
    }));
  };

  return (
    <>
      <Container>
        <Meta page="commandgroup" />

        <div className={classes.container}>
          <h1>Créer une invitation</h1>

          <h3>Choisissez une adresse de livraison :</h3>

          {state.address.length > 0 ? (
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="demo-controlled-open-select-label">Adresse de livraison</InputLabel>
              <Select
                label="Adresse de livraison"
                value={state.inputsHandler.step1}
                onChange={(e) => handleStep1(e)}
              >
                {state.address.map((adr) => (
                  <MenuItem
                    key={adr.street}
                    value={`${adr.street}//${adr.city}//${adr.zipCode}//${adr.comment}//`}
                  >
                    {adr.street} - {adr.city} ({adr.zipCode})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Button className="btn-orange" href="/profil/?page=ad">
              Ajouter une adresse
            </Button>
          )}

          <Collapse in={state.inputsHandler.step2.display}>
            <h3>Choisissez un créneau :</h3>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="demo-controlled-open-select-label">Créneau de livraison</InputLabel>
              <Select
                label="Créneau de livraison"
                value={state.inputsHandler.step2.value}
                onChange={handleDelivery}
              >
                {getSchedules(state.inputsHandler.step2.schedules, closureTimes, 8, 'days').map((date, idx) => (
                  <MenuItem key={date} value={idx}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Collapse>

          <Collapse in={state.inputsHandler.step3.display}>
            <h3>Choisissez vos invités :</h3>

            <p>
              Vous serez livrez le{' '}
              {moment(state.dataSend.invitation.deliveryInfos.deliveryDate).format('dddd Do MMMM')}{' '}
              ({state.dataSend.invitation.deliveryInfos.deliverySchedule})
            </p>

            <div className={classes.flex}>
              <h2>Liste de contacts</h2>
            </div>

            <List dense className={classes.root}>
              {state.inputsHandler.step3.contacts.map((contact, idx) => (
                <Fragment key={contact._id}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt={`Avatar n°${idx + 1}`}
                        src={`/static/images/avatar/${idx + 1}.jpg`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={idx}
                      primary={`${contact.firstname} ${contact.lastname}`}
                      secondary={`${contact.email}`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        onChange={() => handleToggle(contact.id)}
                        checked={state.dataSend.invitation.contacts.indexOf(contact.id) !== -1}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
            <div className={classes.flex2}>
              <Button
                style={{ marginRight: '20px' }}
                onClick={() => handleMassive('all')}
                className="btn-orange"
              >
                Tout cocher
              </Button>
              <Button onClick={() => handleMassive('none')} className={classes.Button}>
                Tout décocher
              </Button>
            </div>

            <h3>Personnalisez votre e-mail d'invitation</h3>

            <p>
              Précisez l'heure de retrait des paniers chez vous{' '}
              <span className={classes.span}>(saisie obligatoire)</span>
            </p>

            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={1}>
                <p>Entre</p>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.inputDate}
                  type="time"
                  variant="outlined"
                  value={state.dataSend.invitation.ecoInvitationSettings.pickupSchedule.from}
                  onChange={(e) => handleTime(e, 'from')}
                />
              </Grid>
              <Grid item xs={1}>
                <p>et</p>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.inputDate}
                  type="time"
                  variant="outlined"
                  value={state.dataSend.invitation.ecoInvitationSettings.pickupSchedule.to}
                  onChange={(e) => handleTime(e, 'to')}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p>Vous souhaitez ajouter un message personnalisé, écrivez-le ci-dessous :</p>
                <TextField
                  multiline
                  fullWidth
                  color="primary"
                  label="Texte personnalisé"
                  variant="outlined"
                  value={state.dataSend.invitation.ecoInvitationSettings.customMessage}
                  onChange={handleChangeMail}
                />
              </Grid>

              <Grid item xs={12}>
                <InvitationMailPreview
                  message={state.dataSend.invitation.ecoInvitationSettings.customMessage}
                  userFirstname={user.firstname}
                />
              </Grid>
            </Grid>

            <div className={classes.flex}>
              <Button onClick={() => handleCreateInvit()} className="btn-orange">
                Envoyer l'invitation
              </Button>
            </div>
          </Collapse>
        </div>
      </Container>
      <img className={classes.img} src={img} alt="fond_invitation" />
    </>
  );
}
