import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { CardActionArea, Chip, Divider, Typography } from '@material-ui/core';
import moment from 'moment';
import { Alarm, People, Whatshot } from '@material-ui/icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: '3rem !important',
    marginBottom: '4rem !important',
    fontFamily: 'Bitter !important',
    textAlign: 'center',
  },
  h3: {
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem !important',
      marginBottom: '6rem',
    },
    fontSize: '1.5rem !important',
    margin: '0 1rem 4rem 1rem !important',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Bitter !important',
  },
  container: {
    [theme.breakpoints.up('md')]: {
      margin: '5rem 5rem',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '5rem 10rem',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60%',
  },
  content: {
    textAlign: 'left',
    fontFamily: 'Bitter',
    fontSize: '1rem',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cover: {
    width: '40%',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  divider: {
    backgroundColor: '#ADC00D',
  },
  next: {
    padding: '0.5rem 0',
    color: '#ADC00D',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: '#ADC00D',
    },
  },
  sideBar: {
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    marginTop: '7rem',
    padding: '1rem',
    borderRadius: '0 14px 14px 0',
    height: 'fit-content',
    minHeight: '80%',
  },
  textField: {
    borderRadius: '14px !important',
  },
  overflow: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    marginTop: '0',
    marginBottom: '0',
    '& > p': {
      margin: '0',
      color: 'black',
    },
  },
  link: {
    padding: '0.5rem !important',
    textAlign: 'center !important',
    borderRadius: '0 0 14px 0',
  },
  article: {
    display: 'flex',
    borderRadius: '14px',
    minHeight: '312px',
    width: '100%',
    '& > div > p > span': {
      color: 'white !important',
    },
    '& > figure': {
      width: '40%',
      margin: '0',
      '& > img': {
        width: '100%',
        borderRadius: '14px 0 0 14px',
      },
    },
  },
  text: {
    padding: '1rem',
  },
  actions: {
    display: 'flex',
    border: '1px solid rgb(0 0 0 0 70%)',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
      '& > div > div > div:nth-child(1) > h5': {
        color: '#f05c35',
      },
      '& > div > div > div:nth-child(2) > p': {
        transition: '0.2s',
        backgroundColor: '#ADC00D',
        color: 'white',
        '& > span': {
          color: 'white',
        },
      },
    },
    borderRadius: '14px',
  },
  legend: {
    position: 'absolute',
    top: '350px',
    padding: '2rem',
    backgroundColor: 'rgba(0,0,0, 0.6)',
  },
  title: {
    margin: 0,
    color: 'white',
    fontSize: '1.5rem',
  },
  date: {
    marginBottom: '1rem',
    textAlign: 'left',
  },
  chip: {
    marginRight: '0.5rem',
    '& > svg': {
      width: '20px !important',
    },
  },
  a: {
    color: '#ADC00D',
  },
}));

export default function ArticleCard(props) {
  const classes = useStyles();
  const { article, contentType } = props;
  console.log('contentType', contentType);

  // # article.sys.id, article.fields.imageURL, article.fields.title, article.fields.persons, article.fields.preparation, article.fields.cuisson, article.fields.description,

  return (
    <Fragment>
        <Link to={{
          pathname: `/blog/${article.fields.slug}`,
          search: `?contentType=${contentType}`,
          state: {
            article: article,
          }
        }}>
          <CardActionArea className={classes.actions}>
            <div className={classes.article}>
              <figure
                style={{
                  backgroundImage: `url(https:${article.fields.imageURL})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '14px 0 0 14px',
                }}
              ></figure>
              <div className={classes.content}>
                <div className={classes.text}>
                  <Typography component="h5" variant="h5">
                    {article.fields.title}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Publié le {moment(article.fields.data).format('LL')}
                  </Typography>
                  <div style={{margin: '1rem 0'}}>
                  {contentType !== 'lesActusDuBio' && (
                      <Fragment>
                        <Chip className={classes.chip} avatar={<People></People>} color={'secondary'} label={article.fields.persons}/>
                        <Chip className={classes.chip} avatar={<Alarm></Alarm>}  color={'secondary'} label={`${article.fields.preparation} min`}/>
                        <Chip className={classes.chip} avatar={<Whatshot></Whatshot>} color={'secondary'} label={`${article.fields.cuisson} min`}/>
                      </Fragment>
                  )}
                  </div>
                  <p className={classes.overflow}>
                    {documentToReactComponents(article.fields.description)}
                  </p>
                </div>
                <div>
                  <Divider className={classes.divider}></Divider>
                  <Typography className={classes.link}>
                    <span className={classes.a}>
                      Lire la suite
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          </CardActionArea>
        </Link>
    </Fragment>
  );
}
