import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import '../assets/css/style.css';

// # COMPONENTS
import Navbar from '../components/Navbar';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

// INFOS : EVENT MODAL
import ModalAdd from '../components/ModalAdd';

// # CONTEXT
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext/UserContext';
import { SystemContext } from '../contexts/SystemContext/SystemContext';

// # STYLE
import '../assets/css/style.css';
import '../assets/css/ecoActeur.css';
import {axiosGetRequest} from "../utils/axiosRequests";
import moment from "moment";

export default function Front(props) {
  const { drawer } = useContext(CartContext);
  const { user, logout } = useContext(UserContext);
  const { closureTimes, initialize } = useContext(SystemContext);
  const [popUp, setPopUp] = useState();
  const [className, setClassName] = useState('show');
  const [lastScroll, setLastScroll] = useState(10);

   // INFOS : EVENT MODAL

  const open = sessionStorage.getItem('popUp') ? JSON.parse(sessionStorage.getItem('popUp')) : true;

  useEffect(() => {
    const fetchPopUp = async() => {
      const bannersRequest = await axiosGetRequest('/banners/').then(res => res.data.data.banners).catch(err => console.log(err));
      const banner = bannersRequest.find(banner => banner.isActive === true);
      if(banner){
        setPopUp(banner)
      }
    }
    fetchPopUp();
  }, [])

  useEffect(() => {
    const fetchClosureTimes = async() => {
      //SET CLOSURE TIMES
      const expirationDate = closureTimes?.updatedAt;
      const now = moment();
      if (now.isAfter(expirationDate, 'day') || closureTimes === null) {
        console.log('After');
        const closureTimesData = await axiosGetRequest('/configs/closureTimes').then(res => res.data.data.infos).catch(err => console.log(err));
        console.log('closureTimes', closureTimesData);
        initialize({...closureTimesData.setting.closureTimes, updatedAt: moment()});
      }
    }

    if (user.role === '' && Cookies.get('jwt')) {
      Cookies.remove('jwt');
    }

    // INFO : TEMP -> OLD CONTEXT
    if (!user.ecoData || !user.personalData) {
      Cookies.remove('jwt');
      window.location = '/';
      logout();
    }

    fetchClosureTimes();

    // INFOS : SHOW / HIDE MENU
    window.addEventListener('scroll', () => {
      const top = window.scrollY;
      if (top <= 100) return setClassName('show');
      const scroll = top - lastScroll;
      if (scroll > 0 && scroll >= 20) {
        setClassName('hidden');
        return setLastScroll(() => top);
      }
      if (scroll <= -20) {
        setClassName('show');
        return setLastScroll(() => top);
      }
    });
  }, [user, lastScroll, logout]);

  useEffect(() => {
    if (localStorage.getItem('invitation')) {
      localStorage.removeItem('invitation');
    }
  });

  return (
    <div className="front">
      <Navbar navbarPosition={className} navbar={<Menu />} />
      <div className="marginTop">{props.children}</div>
      <Footer />

      {/* INFO : EVENT MODAL */}
      {popUp ? (
          <ModalAdd banner={popUp} open={open} />
      ): null}
    </div>
  );
}
