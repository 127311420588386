import React from 'react';

// # IMAGES
import img8 from '../../assets/img/val_conv_3.png';
import img9 from '../../assets/img/IledeFrance.png';
import img10 from '../../assets/img/logo_producteur-special.png';

export default function ProductPageInfos() {
  return (
    <div className="column">
      <div className="label">
        <p>Tous nos producteurs sont contrôlés et certifiés en Agriculture Biologique</p>
        <img src={img8} alt="ablabel" />
      </div>
      <div className="label2">
        <p className="greenbox">Un réseau de plus de 30 agriculteurs Bio en Ile de France.</p>
        <img src={img9} alt="ablabel" />
      </div>
      <div className="label3">
        <img src={img10} alt="ablabel" />

        <p>Les producteurs fixent librement leurs prix, pour une juste rémunération.</p>
      </div>
    </div>
  );
}
