import React, { Fragment } from 'react';

import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import moment from 'moment';
import currencyFormater from '../../utils/currencyFormater';
import { calcTotalOrder } from '../../utils/totalOrders';

const HEADER_CELLS = [
  { id: 'user', label: 'Utilisateur', align: 'left' },
  { id: 'amount', label: 'Montant', align: 'center' },
  { id: 'lastDeliveryDate', label: 'Dernière livraison', align: 'left' },
  { id: 'nextDeliveryDate', label: 'Duplication au', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'type', label: 'Abonnement', align: 'right' },
];

function SubscriberTableHeader({ isChecked, onCheckAll }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell padding="checkbox">
          <Checkbox checked={isChecked} onChange={onCheckAll} />
        </TableCell>
        {HEADER_CELLS.map((cell) => (
          <TableCell key={cell.id} align={cell.align}>
            {cell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function SubscriberTableRow({ subscriber, isChecked, isOpen, onClickOpen, onCheckOne }) {
  const lastSubscriberOrder = subscriber.order;
  const lastDeliveryDate = moment(lastSubscriberOrder.deliveryInfos.deliveryDate);

  const today = moment().day();
  const lastDeliveryDay = lastDeliveryDate.day();
  const daysUntilNextDelivery = (lastDeliveryDay + 7 - today) % 7 || 7;
  const nextDeliveryDate = moment().add(daysUntilNextDelivery, 'days').format('DD-MM-YYYY');

  const { formatTotalTtc } = calcTotalOrder(lastSubscriberOrder);

  const handleClick = (event) => onClickOpen(event.currentTarget.value);

  return (
    <TableRow>
      <TableCell>
        <IconButton onClick={handleClick} value={subscriber._id} size="small">
          {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox value={subscriber._id} checked={isChecked} onChange={onCheckOne} />
      </TableCell>
      <TableCell align="left">
        <Link href={`/admin/users/${subscriber._id}`}>
          {subscriber.lastname} {subscriber.firstname}
        </Link>
      </TableCell>
      <TableCell align="center">{currencyFormater(formatTotalTtc)}</TableCell>
      <TableCell align="left">{lastDeliveryDate.format('DD-MM-YYYY')}</TableCell>
      <TableCell align="left">{nextDeliveryDate}</TableCell>
      <TableCell align="left">{subscriber.email}</TableCell>
      <TableCell align="right">{subscriber.role === 'subscriberBtoC' ? 'B2C' : 'B2B'}</TableCell>
    </TableRow>
  );
}

function LastOrderTableRow({ isOpen, lastOrder }) {
  const { deliveryInfos, discount, deliveryCost } = lastOrder;

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" component="div">
              Dernière commande
            </Typography>
            <Typography variant="subtitle2" gutterBottom component="p">
              Livraison le {moment(deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')} à{' '}
              {deliveryInfos.deliverySchedule}{' '}
              {deliveryInfos.deliveryType === 'relayPoint' ? 'en point de livraison' : 'à domicile'}
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Rubrique</TableCell>
                  <TableCell>Sous-rubrique</TableCell>
                  <TableCell align="right">Quantité</TableCell>
                  <TableCell align="right">Prix</TableCell>
                  <TableCell align="right">Prix total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lastOrder.items.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.subCategory}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">{currencyFormater(item.price)}</TableCell>
                    <TableCell align="right">{currencyFormater(item.price * item.quantity)}</TableCell>
                  </TableRow>
                ))}
                {discount && (
                  <TableRow>
                    <TableCell colSpan={2}>Bon de réduction</TableCell>
                    <TableCell colSpan={3}>{discount.name}</TableCell>
                    <TableCell align="right" colSpan={2}>
                      {discount.value} {discount.reduction === 'percent' ? '%' : '€'}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={2}>Type de livraison</TableCell>
                  <TableCell colSpan={3}>{deliveryInfos.deliveryType}</TableCell>
                  <TableCell align="right" colSpan={2}>
                    {deliveryCost} €
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

function SubscriberTableBody({ selectedSubscribers, subscribers, onCheckOne }) {
  const [openRowId, setOpenRowId] = React.useState(null);

  const handleOpen = (id) => setOpenRowId((prev) => (prev === id ? null : id));

  return (
    <TableBody>
      {subscribers.map((subscriber) => (
        <Fragment key={subscriber._id}>
          <SubscriberTableRow
            subscriber={subscriber}
            isChecked={!!selectedSubscribers.find((id) => id === subscriber._id)}
            isOpen={subscriber._id === openRowId}
            onClickOpen={handleOpen}
            onCheckOne={onCheckOne}
          />
          <LastOrderTableRow isOpen={subscriber._id === openRowId} lastOrder={subscriber.order} />
        </Fragment>
      ))}
    </TableBody>
  );
}

export default function SubscriberTable({ selectedSubscribers, subscribers, onCheckAll, onCheckOne }) {
  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <SubscriberTableHeader
            selectedSubscribers={selectedSubscribers}
            isChecked={selectedSubscribers.length === subscribers.length}
            onCheckAll={onCheckAll}
          />
          <SubscriberTableBody
            selectedSubscribers={selectedSubscribers}
            subscribers={subscribers}
            onCheckOne={onCheckOne}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
}
