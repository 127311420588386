import moment from 'moment';

const lastMonths = (substractMonths) => {
  const months = [];
  const dateStart = moment();
  const dateEnd = moment().subtract(substractMonths, 'months');
  while (dateStart.diff(dateEnd, 'months') >= 0) {
    months.push(dateStart.format('YYYY-MM'));
    dateStart.subtract(1, 'months');
  }
  return months;
};

export default lastMonths;
