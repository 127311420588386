import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import {
  Grid,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Link,
  TextField,
  IconButton,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginB: {
    marginBottom: '1rem',
  },
  small: {
    width: '90px',
  },
}));

function TableCharges(props) {
  return (
    <TableContainer component={Paper} className={props.marginB}>
      <Table className={props.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Seuil</TableCell>
            <TableCell align="right">Frais HT</TableCell>
            <TableCell align="right">Frais TTC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.charges.map((el, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <TextField
                  className={props.small}
                  variant="outlined"
                  type="Number"
                  size="small"
                  value={props.charges[index].threshold}
                  onChange={(e) => props.handleThreshold(index, e.target.value * 1)}
                />
                €
              </TableCell>
              <TableCell align="right">
                <TextField
                  className={props.small}
                  variant="outlined"
                  type="Number"
                  size="small"
                  defaultValue={props.charges[index].costsHT}
                  onChange={(e) => props.handleCosts(index, e.target.value * 1)}
                />
                €
              </TableCell>
              <TableCell align="right">
                <TextField
                  className={props.small}
                  variant="outlined"
                  type="Number"
                  size="small"
                  value={props.charges[index].costsTTC}
                />
                €
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function DeliveryZoneInfos(props) {
  const classes = useStyles();
  const { zone, cities } = props;
  const [state, setState] = useState({
    charges: [],
  });

  useEffect(() => {
    setState({ charges: [...zone.deliveryZone.charges] });
  }, [zone]);

  const handleThreshold = (index, value) => {
    const arr = state.charges;
    arr[index].threshold = value;

    setState({ charges: arr });
  };

  const handleCosts = (index, value) => {
    const arr = state.charges;
    arr[index].costsHT = value;
    arr[index].costsTTC = Math.round((value + value * (20 / 100)) * 100) / 100;

    setState({ charges: arr });
  };

  const handleUpdate = async (e) => {
    const token = Cookies.get('jwt');

    await axios
      .patch(
        `${process.env.REACT_APP_API}/deliveryZones/${zone.deliveryZone._id}`,
        { charges: state.charges },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        window.location = '/admin/deliveryZones';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleDeleteCity = async (idx) => {
    const city = cities[idx];
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/deliveryCities/${city._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleDeleteZone = async (idx) => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/deliveryZones/${props.zone.deliveryZone._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = '/admin/deliveryZones';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Paper className={classes.paper}>
          <h3>Villes de la zone :</h3>
          <div className={classes.marginB}>
            <ul>
              {cities.map((el, index) => (
                <li key={index}>
                  <Link href={`/admin/deliveryCities/${el._id}`}>
                    {el.name} ({el.zipCode})
                  </Link>
                  <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={(e) => handleDeleteCity(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </li>
              ))}
            </ul>
          </div>
          <Divider />
          <h3>Frais de livraison pour la zone :</h3>
          {state.charges.length > 0 ? (
            <TableCharges
              table={classes.table}
              marginB={classes.marginB}
              charges={state.charges}
              small={classes.small}
              handleThreshold={handleThreshold}
              handleCosts={handleCosts}
            />
          ) : null}
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Sauvegarder les modifications
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <h3>Actions rapides :</h3>
          <Button variant="contained" fullWidth color="secondary" onClick={handleDeleteZone}>
            Supprimer la zone
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
