import React, { useContext, useState, useEffect } from 'react';

// # CONTEXT
import { makeStyles, Grid } from '@material-ui/core';
import { CartContext } from '../../../contexts/CartContext';

// # CUSTOM COMPONENTS
import GroupedInfos from './GroupedInfos';
import AddButton from './AddButton';
import GroupedLabels from './GroupedLabels';
import RemoveButton from './RemoveButton';

// # UTILS
import currencyFormater from '../../../utils/currencyFormater';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    maxWidth: '275px',
    minHeight: '110px',
    marginBottom: '1rem',
    padding: '5px',
    alignItems: 'center',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 5px 5px 0px #49494929',

    '& figure': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70px',
      height: '70px',
      margin: 0,

      '& > img': {
        width: '100%',
        verticalAlign: 'text-bottom',
      },
    },

    [theme.breakpoints.down('lg')]: {
      minWidth: '45%',

      '& > div:first-child': {
        width: '40%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      '& > div:last-child': {
        width: '60%',
      },
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '& > h4': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: 'Bitter',
      fontWeight: '500',
      margin: '0 0 2px 0',
    },

    '& > p': {
      margin: '0 0 5px 0',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      color: '#767676',
    },
  },
  labels: {
    display: 'flex',
    alignItems: 'self-start',

    '& > div:first-child': {
      marginBottom: '5px',
    },
  },
  rate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > p': {
      textAlign: 'right',
      fontFamily: 'Bitter',
      fontWeight: 500,
      fontSize: '1rem',
      color: '#F05C35',
      margin: 0,
    },

    '& > button': {
      marginLeft: '10px',
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      maxHeight: '40px',
      marginLeft: '10px',

      '& > p': {
        width: '20px',
        textAlign: 'center',
      },
    },
  },
}));

export default function SuggestionCard(props) {
  const classes = useStyles();

  const { cartItems } = useContext(CartContext);

  const { product, setDrawer } = props;
  const { _id, type, photo, name, origin, weight, persons, labels, price } = product;

  const [currentItem, setCurrentItem] = useState({});

  useEffect(
    () => setCurrentItem(() => cartItems.find((item) => item._id === _id)),
    [_id, cartItems, product]
  );

  return (
    <Grid container className={classes.root}>
      <Grid item lg={4}>
        <figure>
          <img src={`${process.env.REACT_APP_AWS}/${photo}`} alt={name} />
        </figure>
      </Grid>
      <Grid item lg={8} className={classes.infos}>
        <h4>{name}</h4>
        <p>{origin}</p>
        <div className={classes.labels}>
          <GroupedInfos
            type={type}
            infos={{
              weight: { ...weight },
              persons: { ...persons },
            }}
          />
          <GroupedLabels labels={labels} limit={2} />
        </div>
        <Grid container className={classes.rate}>
          <p>{currencyFormater(price)}</p>
          {currentItem ? (
            <Grid item lg={8}>
              <RemoveButton product={product} matchingItem={currentItem} />
              <p>{currentItem.quantity}</p>
              <AddButton matchingItem={currentItem} setDrawer={setDrawer} product={product} />
            </Grid>
          ) : (
            <AddButton setDrawer={setDrawer} product={product} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
