import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';

import moment from 'moment';
import 'moment/locale/fr';
import { useSnackbar } from 'notistack';
import debounce from '../utils/debounce';

import '../assets/css/ecoActeur.css';

// # CONTEXT
import { InvitationContext } from '../contexts/InvitationContext/InvitationContext';

// # COMPONENTS
import EcoNav from '../components/EcoNav';
import EcoFooter from '../components/EcoFooter';

export default function EcoActeurs(props) {
  const { uuid } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [className, setClassName] = useState('show');

  const { invitation, setInvitation } = useContext(InvitationContext);

  let lastScroll = 0;
  moment.locale('fr');

  useEffect(() => {
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/invitations/session/${uuid}`)
        .then((res) => {
          const { deliveryInfos, sender, id } = res.data.data;

          if (moment().isBefore(deliveryInfos.deliveryDate)) {
            const currInvitation = {
              id,
              uuid,
              deliveryInfos: {
                deliveryAddress: {
                  address: deliveryInfos.deliveryAddress.address,
                  city: deliveryInfos.deliveryAddress.city,
                  complement: deliveryInfos.deliveryAddress.complement,
                  country: deliveryInfos.deliveryAddress.country,
                  zipCode: deliveryInfos.deliveryAddress.zipCode,
                },
                deliveryDate: deliveryInfos.deliveryDate,
                deliverySchedule: deliveryInfos.deliverySchedule,
              },
              sender: {
                email: sender.email,
                firstname: sender.firstname,
                lastname: sender.lastname,
              },
              userToken: res.data.token,
            };

            setInvitation(currInvitation);
          } else {
            enqueueSnackbar('La date de cette invitation est passée, vous allez être redirigé', {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              onExited: () => (window.location = '/'),
            });
          }
        })
        .catch(() => {
          enqueueSnackbar(
            "Aucune invitation correspondante n'a été trouvé, vous allez être redirigé",
            {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              onExited: () => (window.location = '/'),
            }
          );
        });
    };

    const { pathname } = window.location;
    if (pathname.startsWith('/eco-invitation/') && uuid) {
      fetchData();
    }
  }, [uuid, invitation, setInvitation, enqueueSnackbar]);

  const handleScroll = debounce(() => {
    const top = document.documentElement.scrollTop;
    if (lastScroll < top) {
      setClassName('hidden');
    } else {
      setClassName('show');
    }
    lastScroll = top;
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [lastScroll, handleScroll]);

  return (
    <div className="front">
      <EcoNav navbarPosition={className} />
      <div>
        <div className="marginTop">{React.cloneElement(props.children)}</div>
      </div>
      <EcoFooter />
    </div>
  );
}
