import React, { useState, useContext } from 'react';
import Script from 'react-load-script';
import axios from 'axios';
import Cookies from 'js-cookie';

// # MATERIAL UI
import {
  Button,
  Collapse,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

// # CONTEXT
import { UserContext } from '../../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  span: {
    color: '#f05c35',
  },
}));

export default function SignUp() {
  const classes = useStyles();

  const { login } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  // # STATE HOOKS
  const [state, setState] = useState({
    entreprise: false,
    emailError: false,
    newUser: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordConfirm: '',
      information: '',
      phoneNumber: '',
      address: [
        {
          street: '',
          zipCode: '',
          city: '',
          comment: '',
          isMain: true,
          isBill: true,
        },
      ],
      newsLetter: true,
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API}/users/signup`, state.newUser, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        const response = res.data.data.user;
        login({
          id: response.id,
          token: res.data.token,
          role: response.role,
          personalData: {
            firstname: response.firstname,
            lastname: response.lastname,
            fullname: response.fullname,
            phoneNumber: response.phoneNumber,
            email: response.email,
            complement: response.information,
            newsLetter: response.newsLetter,
          },
          addresses: [...response.address],
          ecoData: {
            ecoPoints: 0,
            customMessage: '',
            pickupSchedule: {
              from: '17:00',
              to: '18:00',
            },
            contacts: [],
          },
        });
        window.history.back();
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        Object.keys(errors).forEach((key) => {
          enqueueSnackbar(`${errors[key].message}`, { variant: 'error' });
        });
      });
  };

  const handleScriptLoad = () => {
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components'],
    };

    ['autocomplete', 'autocomplete-entreprise'].forEach((id) => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(id),
        options
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const components = place.address_components;
        let street;
        let zipCode;
        let city;

        components.forEach((component) => {
          const componentType = component.types[0];

          // eslint-disable-next-line default-case
          switch (componentType) {
            case 'locality':
              city = component.long_name;
              break;
            case 'street_number':
              street = component.long_name;
              break;
            case 'route':
              if (street) {
                street += ` ${component.long_name}`;
              } else {
                street = component.long_name;
              }
              break;
            case 'postal_code':
              zipCode = component.long_name;
              break;
          }
        });

        setState((prev) => ({
          ...prev,
          newUser: {
            ...prev.newUser,
            address: [
              {
                ...prev.newUser.address[0],
                street,
                zipCode,
                city,
              },
            ],
          },
        }));
      });
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Inscrivez-vous
        </Typography>
        <form id="formRegister" className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Nom"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                label="Nom"
                onChange={(e) => {
                  const lastname = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      lastname,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Prénom"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                label="Prénom"
                onChange={(e) => {
                  const firstname = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      firstname,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                Name="Email"
                variant="outlined"
                type="text"
                required
                error={!!state.emailError}
                fullWidth
                label="Adresse mail"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    emailError: false,
                  }))
                }
                onChange={(e) => {
                  const email = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      email,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                variant="outlined"
                required
                fullWidth
                error={!(state.newUser.password.length > 7 || state.newUser.password.length === 0)}
                helperText="Entrez un mot de passe avec plus de 8 caractères"
                label="Mot de passe"
                type="password"
                onChange={(e) => {
                  const password = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      password,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password confirm"
                variant="outlined"
                required
                error={state.newUser.passwordConfirm !== state.newUser.password}
                helperText="Entrez à nouveau le même mot de passe"
                fullWidth
                label="Confirmation de mot de passe"
                type="password"
                onChange={(e) => {
                  const passwordConfirm = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      passwordConfirm,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="phonenumber"
                variant="outlined"
                type="text"
                required
                fullWidth
                error={
                  !(
                    state.newUser.phoneNumber.length === 10 ||
                    state.newUser.phoneNumber.length === 0
                  )
                }
                label="Téléphone"
                onChange={(e) => {
                  const phoneNumber = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newUser: {
                      ...prev.newUser,
                      phoneNumber,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.newUser.entreprise}
                    color="primary"
                    onChange={(e) => {
                      const entreprise = e.target.checked;
                      setState((prev) => ({
                        entreprise,
                        newUser: {
                          ...prev.newUser,
                        },
                      }));
                    }}
                  />
                }
                label="Entreprise"
              />
            </Grid>

            <Collapse in={state.entreprise}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>entreprise</h3>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="address"
                    variant="outlined"
                    type="text"
                    required={state.entreprise === true}
                    fullWidth
                    label="Adresse"
                    id="autocomplete-entreprise"
                    onChange={(e) => {
                      const street = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          address: [
                            {
                              ...prev.newUser.address[0],
                              street,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="complete address"
                    variant="outlined"
                    type="text"
                    fullWidth
                    label="Complément d'adresse"
                    onChange={(e) => {
                      const comment = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          address: [
                            {
                              ...prev.newUser.address[0],
                              comment,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p id="label">Informations pour le livreur (étage, digicode, etc...)</p>
                  <TextField
                    name="information"
                    variant="outlined"
                    type="text"
                    fullWidth
                    label="Informations"
                    onChange={(e) => {
                      const information = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          information,
                        },
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>

            <Collapse in={!state.entreprise}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h3>adresse</h3>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="address"
                    variant="outlined"
                    type="text"
                    required={state.entreprise === false}
                    fullWidth
                    label="Adresse"
                    id="autocomplete"
                    onChange={(e) => {
                      const street = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          address: [
                            {
                              ...prev.newUser.address[0],
                              street,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="complet address"
                    variant="outlined"
                    type="text"
                    fullWidth
                    label="Complément d'adresse"
                    onChange={(e) => {
                      const comment = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          address: [
                            {
                              ...prev.newUser.address[0],
                              comment,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p id="label">Informations pour le livreur (étage, digicode, etc...)</p>
                  <TextField
                    name="information"
                    variant="outlined"
                    type="text"
                    fullWidth
                    label="Informations"
                    onChange={(e) => {
                      const information = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          information,
                        },
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>

            <Grid item xs={12}>
              <div>
                <span className={classes.span}>
                  (Je souhaite recevoir la composition des paniers Bio et les recettes tous les
                  dimanches)
                </span>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.newUser.newsLetter}
                    value="true"
                    color="primary"
                    onChange={(e) => {
                      const newsletter = e.target.checked;
                      setState((prev) => ({
                        ...prev,
                        newUser: {
                          ...prev.newUser,
                          newsLetter: newsletter,
                        },
                      }));
                    }}
                  />
                }
                label="Newsletters"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            M&apos;inscrire
          </Button>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Link href="/login" variant="body2">
                Je possède déja un compte
              </Link>
              <br />
              <br />
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
