import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
  Button,
  IconButton,
  ButtonGroup,
} from '@material-ui/core/';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import axios from 'axios';
import Cookies from 'js-cookie';

import { useSnackbar } from 'notistack';

import moment from 'moment';

const columns = [
  { id: 'order', label: 'Ordre', minWidth: 100, align: 'left' },
  { id: 'name', label: 'Nom', minWidth: 100, align: 'center' },
  { id: 'active', label: 'Actif', minWidth: 100, align: 'center' },
  {
    id: 'created',
    label: 'Date de création',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  div: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  a: {
    color: '#ADC00D',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default function CategoryTable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const token = Cookies.get('jwt');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [state, setState] = useState({
    baseCategories: [],
    categories: [],
    isReorder: false,
  });

  useEffect(() => {
    const { data } = props;

    setState((prev) => ({
      ...prev,
      baseCategories: data,
      categories: data,
    }));
  }, [props]);

  const handleSort = (action, idx) => {
    const tempCategories = [...state.categories];

    if (!state.isReorder) {
      setState((prev) => ({
        ...prev,
        isReorder: !prev.isReorder,
      }));
    }

    if (action === 'increase') {
      tempCategories[idx].position -= 1;
      tempCategories[idx - 1].position += 1;
    } else {
      tempCategories[idx].position += 1;
      tempCategories[idx + 1].position -= 1;
    }

    setState((prev) => ({
      ...prev,
      categories: tempCategories,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = async () => {
    const tempTable = [];
    state.categories.forEach((el, idx) => {
      if (el.position !== state.baseCategories[idx].position) {
        tempTable.push(el);
      }
    });

    const temp = [];
    tempTable.forEach((el) => {
      const tempObj = {
        id: el._id,
        position: el.position,
      };
      temp.push(tempObj);
    });

    await axios
      .patch(`${process.env.REACT_APP_API}/categories`, temp, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        enqueueSnackbar('Les modifications ont été prises en compte', {
          variant: 'success',
        }),
        setState((prev) => ({
          ...prev,
          isReorder: !prev.isReorder,
        }))
      )
      .catch((err) => {
        enqueueSnackbar(`Une erreur s'est produite`, {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <div className={classes.div}>
        {state.isReorder && (
          <Button
            variant="contained"
            color="secondary"
            type="button"
            align="flex-end"
            onClick={handleSubmit}
          >
            Enregistrer les modifications
          </Button>
        )}
      </div>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.categories
                .sort((a, b) => a.position - b.position)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((category, idx) => (
                  <TableRow key={idx}>
                    <TableCell align="left">
                      <ButtonGroup
                        variant="contained"
                        color="primary"
                        aria-label="contained primary button group"
                        style={{
                          marginLeft: category.position === state.categories.length ? '50px' : '0',
                        }}
                      >
                        {category.position !== state.categories.length && (
                          <IconButton onClick={() => handleSort('decrease', idx)}>
                            <ExpandMore />
                          </IconButton>
                        )}
                        <Button>{category.position}</Button>
                        {category.position !== 1 && (
                          <IconButton onClick={() => handleSort('increase', idx)}>
                            <ExpandLess />
                          </IconButton>
                        )}
                      </ButtonGroup>
                    </TableCell>
                    <TableCell align="center">
                      <a className={classes.a} href={`/admin/categories/${category._id}`}>
                        {category.name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <Badge
                        badgeContent={category.isActive ? 'Actif' : 'Inactif'}
                        color={category.isActive ? 'primary' : 'error'}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {moment(category.createdAt).format('DD-MM-YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100]}
          component="div"
          count={state.categories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
