import axios from 'axios';

const space = process.env.REACT_APP_CONTENTFUL_SPACEID;
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONEMENTID;
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN_DELIVERY;

const BASE_URL = `https://cdn.contentful.com/spaces/${space}/environments/${environment}`;

// * FETCH RECEIPTS WITH TAGS
export const retrieveEntriesByTags = (tagsArr) => {
  const tags = tagsArr.reduce((acc, curr, idx) => `${acc}${idx !== 0 ? ',' : ''}${curr.slug}`, '');
  const URL = `${BASE_URL}/entries?access_token=${accessToken}&metadata.tags.sys.id[in]=${tags}&limit=1`;

  return axios.get(URL).then((res) => res.data.items);
};

// * RETRIEVE IMG BY ARTICLE ID
export const retrieveImgByArticleId = async (articleImgId) => {
  const URL = `${BASE_URL}/assets/${articleImgId}?access_token=${accessToken}`;
  return await axios.get(URL).then((res) => res.data.fields.file.url);
};
