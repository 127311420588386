import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import Cookies from 'js-cookie';

import SearchIcon from '@material-ui/icons/Search';

// MATERIAL.UI
import {
  Paper,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Grid,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import lastMonths from '../../../utils/lastMonths';
import CreateDiscountModal from '../../../components/Discount/DiscountCreateModal';
import DiscountTable from '../../../components/Discount/DiscountTable';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(3),
    overflow: 'auto',
    marginBottom: '1rem',
  },
}));

export default function DiscountList() {
  const classes = useStyles();
  const { query } = useParams();

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    loading: true,
    discount: [],
    discountShowcased: [],
    status: 'all',
    segment: 'all',
    segments: [],
    search: '',
    months: lastMonths(12),
    month: 'all',
    all: false,
  });

  useEffect(() => {
    let reduction = '';
    const jwt = Cookies.get('jwt');
    query === 'all' ? (reduction = '?') : (reduction = `?reduction=${query}`);

    const fetchData = async () => {
      const discount = await axios
        .get(`${process.env.REACT_APP_API}/discounts/${reduction}`)
        .catch((err) => {
          console.log(err);
        });
      const segments = await axios
        .get(`${process.env.REACT_APP_API}/segments/?isActive=true`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .catch((err) => {
          console.log(err.response);
        });
      setState((prev) => ({
        ...prev,
        discount: discount.data.data.discounts,
        discountShowcased: discount.data.data.discounts,
        segments: segments.data.data.segments,
      }));
      setLoading(false);
    };
    fetchData();
  }, [query]);

  useEffect(() => {
    let filter = [...state.discount];
    console.log('startFilter', filter)

    if (state.status !== 'all') {
      console.log('status', state.status)
      console.log('filter', filter)
      filter = filter.filter((el) => el.isActive === state.status);
    }

    if (state.month !== 'all') {
      console.log('month', state.month)
      console.log(filter.map(el => moment(el.createdAt).format('YYYY-MM')));
      filter = filter.filter((el) => moment(el.createdAt).format('YYYY-MM') === state.month);
      console.log('filter', filter)
    }

    if (state.segment !== 'all') {
      console.log('segment', state.segment)
      filter = filter.filter((el) => el.segment?.id === state.segment);
      console.log('filter', filter)
    }

    if (state.search !== '') {
      console.log('search', state.search)
      filter = filter.filter((el) => el.name.toLowerCase().indexOf(state.search) > -1);
      console.log('filter', filter)
    }

    console.log('finalFilter', filter);

    setState((prev) => ({
      ...prev,
      discountShowcased: filter,
    }));
  }, [state.status, state.discount, state.segment, state.month, state.search]);

  const handleAll = (isall) => {
    setState((prev) => ({
      ...prev,
      all: isall,
    }));
  };

  return (
    <>
      <div className={classes.flex}>
        <h2>Tous les bons d'achat</h2>
        {loading ? null : <CreateDiscountModal dataLength={state.discount.length} />}
      </div>
      <Paper className={classes.paper}>
        <h2 style={{ marginTop: '0' }}>Filter</h2>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormControl style={{ minWidth: '200px' }} variant="outlined">
            <InputLabel id="select-payment-label">Statut</InputLabel>
            <Select
              labelId="select-payment-label"
              label="Statut"
              defaultValue="all"
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
            >
              <MenuItem value="all">Tout</MenuItem>
              <MenuItem value={false}>Inactif</MenuItem>
              <MenuItem value>Actif</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: '200px' }} variant="outlined">
            <InputLabel id="select-payment-label">Mois</InputLabel>
            <Select
              labelId="select-month-label"
              label="Mois"
              defaultValue="all"
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  month: e.target.value,
                }))
              }
            >
              <MenuItem value="all">Tout</MenuItem>
              {state.months.map((el) => (
                <MenuItem value={el}>{moment(el).format('MMMM YYYY')}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            placeholder="Chercher un code..."
            inputProps={{ 'aria-label': 'Chercher un produit' }}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                search: e.target.value.toString().toLowerCase(),
              }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl style={{ minWidth: '200px' }} variant="outlined">
            <InputLabel id="select-payment-label">Segment</InputLabel>
            <Select
              labelId="select-payment-label"
              label="Segment"
              defaultValue="all"
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  segment: e.target.value,
                }))
              }
            >
              <MenuItem value="all">Tout</MenuItem>
              {state.segments.map((segment, idx) => (
                <MenuItem key={idx} value={segment.id}>
                  {segment.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!state.all ? (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => handleAll(true)}
            >
              Tout cocher
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => handleAll(false)}
            >
              Tout décocher
            </Button>
          )}
        </div>
      </Paper>
      {loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <DiscountTable all={state.all} month={state.month} data={state.discountShowcased} />
      )}
    </>
  );
}
