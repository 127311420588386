import React, {createRef, Fragment, useState, useEffect} from 'react';

import {Button, Grid, Modal, Paper, TextField} from "@material-ui/core";
import {DesktopWindows, TabletMac, PhoneIphone } from '@material-ui/icons';
import {makeStyles} from "@material-ui/core/styles";
import {axiosGetRequest, axiosPostRequest, axiosPatchRequest} from "../../utils/axiosRequests";
import Cookies from "js-cookie";

const useStyles = makeStyles({
    paper: {
        padding: '1rem',
        marginBottom: '1rem'
    },
    instructions: {
        padding: '1rem',
        backgroundColor: '#F7F5DC',
        borderRadius: '8px',
        marginBottom: '1rem',
        '& > h3': {
            margin: 0,
            color: '#F05C35',
        },
        '& > p': {
            marginBottom: 0,
            '& > b': {
                color: 'red',
            }
        },
    },
    dimensions: {
        padding: '1rem',
        backgroundColor: '#F7F5DC',
        borderRadius: '8px',
        marginTop: '1rem',
        '& > p': {
            margin: 0,
            '& > b': {
                color: 'red',
            }
        },
    },
    image: {
        borderRadius: '8px',
        margin: 'auto',
        '& > img': {
            borderRadius: '8px',
            width: '100%',
            margin: '0 auto'
        },
    },
    inputFile: {
        display: 'none',
    },
    imageTable:{
        width: '100%',
        '&:hover':{
            cursor: 'pointer',
        }
    },
    modal: {
        width: 'fit-content',
        height: 'fit-content',
        margin: 'auto',
    },
    imageModal: {
        maxHeight: '90vh',
    },
})

export default function PageHome() {
    const classes = useStyles();
    const myRefD = createRef();
    const myRefT = createRef();
    const myRefM = createRef();
    const initialState = {
        photoD: undefined,
        photoM: undefined,
        photoT: undefined,
    };
    const [state, setState] = useState({
        open: false,
        addHome: {
            ...initialState
        },
        pageHome: {
            ...initialState
        },
    });
    const token = Cookies.get('jwt');

    useEffect(() => {
            axiosGetRequest('/home/').then((res) => setState((prev) => ({
                ...prev,
                pageHome: {
                    ...res.data.data.home
                }
            })));
    }, []);

    // input image Desktop
    const selectFileD = () => {
        myRefD.current.click();
    }

    // input image Tablette
    const selectFileT = () => {
        myRefT.current.click();
    }

    // input image Mobile
    const selectFileM = () => {
        myRefM.current.click();
    }

    const widden = (file) => {
        setState(prev => ({
            ...prev,
            open :true,
            file,
        }));
    };

    const addNewHomeSetUp = (pageHome) => {
        setState(prev => ({
            ...prev,
            pageHome
        }))
    }

    const handleCreateBanner = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('photoD', state.addHome.photoD);
        formData.append('photoT', state.addHome.photoT);
        formData.append('photoM', state.addHome.photoM);
        if(state.pageHome.photoD && state.pageHome.photoT && state.pageHome.photoM) {
            formData.append('lastphotoD', state.pageHome.photoD);
            formData.append('lastphotoT', state.pageHome.photoT);
            formData.append('lastphotoM', state.pageHome.photoM);
            return axiosPatchRequest(`/home/${state.pageHome._id}`, token, formData)
                .then((res) => {
                    const { home } = res.data.data;
                    setState((prev) => ({
                        ...prev,
                        addHome: { ...initialState },
                    }));

                    // # UPDATE PRODUCERS ARR IN PARENT COMPONENT
                    addNewHomeSetUp(home);
                })
                .catch((err) => {
                    console.log(err.response);
                })
        }

        axiosPostRequest(`/home/`, token, formData)
            .then((res) => {
                const { home } = res.data.data;
                setState((prev) => ({
                    ...prev,
                    addHome: { ...initialState },
                }));

                // # UPDATE PRODUCERS ARR IN PARENT COMPONENT
                addNewHomeSetUp(home);
            })
            .catch((err) => {
                console.log(err.response);
            })

    }
    const handleFileChange = (e, name) => {
        const file = e.target.files[0];

        setState((prev) => ({
            ...prev,
            addHome: {
                ...prev.addHome,
                [name]: file,
            },
        }));
    };

    const handleChange = (e) => {
        e.persist();
        const {name, value} = e.target;
        setState(prev =>({
            ...prev,
            addHome: {
                ...prev.addHome,
                [name] : value
            }
        }) );
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            open: false
        }))
    }

    return (
        <Fragment>
            <h1>Page Home</h1>
            <Modal className={classes.modal} open={state.open} onClose={handleClose}>
                <Paper>
                    <img className={classes.imageModal} src={state.file} alt="image"/>
                </Paper>
            </Modal>
            <div className={classes.instructions}>
                <h3>Instructions</h3>
                <p>Afin de modifier la bannière, vous devez importer les différentes images pour les différentes tailles d'appareils dans les emplacements qui conviennent en vous référent au icons. <br/> <b>Attention: la taille maximales des images est 160GB, si l'upload ne fonctionne pas, les images sont peut-être trop grosses.</b></p>
            </div>
            <Paper className={classes.paper}>
                <h2 style={{marginTop: 0}}>Ajouter une bannière</h2>
                <Grid container spacing={3} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={10}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <figure className={classes.image}>
                                            {state.addHome.photoD ? (
                                                <img className={classes.imageTable} onClick={()=>widden(URL.createObjectURL(state.addHome.photoD))} src={URL.createObjectURL(state.addHome.photoD)} alt="upload" />
                                            ) : (
                                                <DesktopWindows fontSize={'large'} className={classes.imageTable}/>
                                            )}
                                        </figure>
                                        <div className={classes.dimensions}>
                                    <p>Dimensions : 1280 x 400</p>
                                </div>
                                        <input ref={myRefD} className={classes.inputFile} type="file" onChange={(e)=>handleFileChange(e, 'photoD')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth onClick={selectFileD} variant={"contained"} color={"primary"}>Desktop</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <figure className={classes.image}>
                                            {state.addHome.photoT ? (
                                                <img className={classes.imageTable} onClick={()=>widden(URL.createObjectURL(state.addHome.photoT))} src={URL.createObjectURL(state.addHome.photoT)} alt="upload" />
                                            ) : (
                                                <TabletMac fontSize={'large'} className={classes.imageTable}/>
                                            )}
                                        </figure>
                                        <div className={classes.dimensions}>
                                    <p>Dimensions : 835 x 405</p>
                                </div>
                                        <input ref={myRefT} className={classes.inputFile} type="file" onChange={(e)=>handleFileChange(e, 'photoT')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth onClick={selectFileT} variant={"contained"} color={"primary"}>Tablette</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <figure className={classes.image}>
                                            {state.addHome.photoM ? (
                                                <img className={classes.imageTable} onClick={()=>widden(URL.createObjectURL(state.addHome.photoM))} src={URL.createObjectURL(state.addHome.photoM)} alt="upload" />
                                            ) : (
                                                <PhoneIphone fontSize={'large'} className={classes.imageTable}/>
                                            )}
                                        </figure>
                                        <div className={classes.dimensions}>
                                    <p>Dimensions : 375 x 182</p>
                                </div>
                                        <input ref={myRefM} className={classes.inputFile} type="file" onChange={(e)=>handleFileChange(e, 'photoM')} />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth onClick={selectFileM} variant={"contained"} color={"primary"}>Mobile</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant={"contained"} color={'primary'} onClick={handleCreateBanner}>Valider</Button>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.paper}>
                <h2 style={{marginTop: 0}}>Images Home Actuelle</h2>
                <h3 style={{marginTop: 0}}>Desktop</h3>
                <Grid container spacing={3} justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item xs={12}>
                                <figure className={classes.image}>
                                    {state.pageHome.photoD ? (
                                        <img className={classes.imageTable} onClick={()=>widden(`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.pageHome.photoD}`)} src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.pageHome.photoD}`} alt="upload" />
                                    ) : (
                                        <img src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`} alt="missing" />
                                    )}
                                </figure>
                            </Grid>
                        </Grid>
                <h3 style={{marginTop: 0}}>Tablette</h3>
                <Grid container spacing={3} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <figure className={classes.image}>
                            {state.pageHome.photoT ? (
                                <img className={classes.imageTable} onClick={()=>widden(`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.pageHome.photoT}`)} src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.pageHome.photoT}`} alt="upload" />
                            ) : (
                                <img src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`} alt="missing" />
                            )}
                        </figure>
                    </Grid>
                </Grid>
                <h3 style={{marginTop: 0}}>Mobile</h3>
                <Grid container spacing={3} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <figure className={classes.image}>
                            {state.pageHome.photoM ? (
                                <img className={classes.imageTable} onClick={()=>widden(`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.pageHome.photoM}`)} src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.pageHome.photoM}`} alt="upload" />
                            ) : (
                                <img src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`} alt="missing" />
                            )}
                        </figure>
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    );
}