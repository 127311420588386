import React from 'react';

// # UTILS
import Meta from '../../../utils/metaDescription';

// # STYLE
import '../../../assets/css/stylenosproduits.css';

// # IMAGES
import img1 from '../../../assets/img/produits_sains-1.png';
import img2 from '../../../assets/img/val_conv_3.png';
import img3 from '../../../assets/img/produits_sains-3.png';
import img4 from '../../../assets/img/adrien.png';
import img5 from '../../../assets/img/produits_sains-producteur.png';
import img6 from '../../../assets/img/produits_sains-logo_AB.png';

export default function Nosproduits() {
  return (
    <>
      <Meta page="produits" />

      <div id="nos_produits">
        <div className="container">
          <h1>Des produits sains</h1>
          <h2>pour une terre préservée</h2>
          <h3>
            Depuis plus de 10 ans, Bio Culture s&apos;engage au quotidien pour une agriculture
            biologique, contrôlée et certifiée
          </h3>
          <p>
            {' '}
            <b>
              Pour vous garantir une traçabilité sans faille du champ jusqu&apos;à votre assiette.
            </b>{' '}
          </p>
        </div>

        <div className="schema">
          <figure>
            <figcaption>Arrivages chaque matin</figcaption>
          </figure>

          <div>
            <p>+</p>
          </div>

          <figure>
            <figcaption>Paniers préparés avec amour</figcaption>
          </figure>

          <div>
            <p>=</p>
          </div>

          <figure>
            <figcaption>Le sourire de nos clients</figcaption>
          </figure>
        </div>

        <div className="section">
          <section>
            <img src={img1} alt="" />
            <p>Redonner de la valeur au végétal, en proposant des produits de haute qualité</p>
          </section>

          <section>
            <img src={img2} alt="" />
            <p>Donner une information claire à nos clients (Origine, label Bio)</p>
          </section>

          <section>
            <img src={img3} alt="" />
            <p>Valoriser les produits à travers des recettes et des conseils.</p>
          </section>
        </div>

        <div className="container">
          <div className="row">
            <div>
              <img src={img4} alt="adrien" />
            </div>
            <div className="text">
              <h2 className="citation">
                « L&apos;histoire d&apos;un produit, son mode de production biologique, son origine,
                font que l&apos;on se nourrit en prenant conscience des bienfaits qu&apos;il nous
                apporte. Notre consommation prend alors une toute autre dimension ! Le défi de Bio
                Culture : vous faire re-découvrir le goût des fruits et des légumes et vous apporter
                la créativité pour les cuisiner ! »
              </h2>
              <p>
                <b>Adrien Gazeau</b>
              </p>
              <p>Fondateur</p>
            </div>
          </div>
        </div>

        <div className="container">
          <h3>Bio Culture est partenaire de plus de 30 agriculteurs Bio en Ile de France.</h3>
          <img id="producteur" src={img5} alt="producteur" />

          <div className="row">
            <div>
              <img src={img6} alt="ablabel" />
            </div>
            <ul>
              <li className="li">
                Nous sommes contrôlés (et certifiés) par un organisme indépendant 2 fois par an
              </li>
              <br />
              <li className="li">
                Nous faisons le choix de la transparence, de la qualité et du développement du
                réseau local, afin de proposer ce qu&apos;il y a de meilleur. (Certification Ecocert
                FR-BIO- O1)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
