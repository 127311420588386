import React, { useState, useEffect } from 'react';

// # CUSTOM COMPONENTS
import { makeStyles } from '@material-ui/core';
import CustomChip from '../../Basics/CustomChip';

// # MATERIAL UI

const useStyles = makeStyles(() => ({
  labels: {
    display: 'flex',
    flexWrap: 'wrap',

    '& > div': {
      marginBottom: '0.5rem',
    },
  },
}));

export default function GroupedLabels(props) {
  const classes = useStyles();
  const { labels, limit } = props;

  const [state, setState] = useState({
    labels,
  });

  useEffect(() => {
    const truthyLabels = Object.fromEntries(
      Object.entries(labels)
        .filter(([key, value]) => value)
        .slice(0, limit)
    );

    setState({
      labels: truthyLabels,
    });
  }, [labels, limit]);

  return (
    <div className={classes.labels}>
      {state.labels.organic && <CustomChip color="primary" label="Bio" />}
      {state.labels.wild && <CustomChip color="primary" label="Sauvage" />}
      {state.labels.french && <CustomChip className="france" />}
      {state.labels.native && <CustomChip color="primary" label="Local" />}
    </div>
  );
}
