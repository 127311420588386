const contentful = require('contentful-management');

const client = contentful.createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const createTag = (newTag) => {
  client
    .getSpace(process.env.REACT_APP_CONTENTFUL_SPACEID)
    .then((space) => space.getEnvironment(process.env.REACT_APP_CONTENTFUL_ENVIRONEMENTID))
    .then((env) => env.createTag(newTag.slug, newTag.name, 'public'))
    .then((tag) => console.log(tag))
    .catch(console.error);
};

const updateTag = (tagId, newTag) => {
  client
    .getSpace(process.env.REACT_APP_CONTENTFUL_SPACEID)
    .then((space) => space.getEnvironment(process.env.REACT_APP_CONTENTFUL_ENVIRONEMENTID))
    .then((env) => env.getTag(tagId))
    .then((tag) => {
      tag.name = newTag;
      tag.update();
    })
    .then((updatedTag) => console.log(updatedTag))
    .catch(console.error);
};

const deleteTag = (tagId) => {
  client
    .getSpace(process.env.REACT_APP_CONTENTFUL_SPACEID)
    .then((space) => space.getEnvironment(process.env.REACT_APP_CONTENTFUL_ENVIRONEMENTID))
    .then((env) => env.getTag(tagId))
    .then((tag) => tag.delete())
    .catch(console.error);
};

export { createTag, updateTag, deleteTag };
