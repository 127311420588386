import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {CartContext} from '../../../contexts/CartContext';

import ProductBox from "./ProductBox";
import { axiosGetRequest } from '../../../utils/axiosRequests';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '4em'
  },
  title: {
    fontFamily: 'Bitter !important',
    fontSize: '2em !important',
    fontWeight: 400,
    textAlign: 'center'
  }
}));

const MAX_SUGGESTIONS = 4;

export default function Suggestions() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  const {
    cartItems
  } = useContext(CartContext);

  useEffect(() => {
    const fetchSuggestions = async () => {
      const promises = [];
      const products = [];

      cartItems.forEach((cartItem) => {
        if (cartItem.type !== "basket") {
          if (products.length < MAX_SUGGESTIONS) {
            promises.push(axiosGetRequest(`/products/suggestions/${cartItem.id ?? cartItem._id}`)
            .then((res) => {
              res.data.data.products.forEach((product) => {
                if (products.length < MAX_SUGGESTIONS && !products.includes(product)) {
                  products.push(product);
                }
              })
            }))
          }
        }
      })

      Promise.all(promises).then(() => {
        setSuggestions(products);
        setLoading(false);
      });
    }

    fetchSuggestions();
  }, []);

  return (
    <React.Fragment>
      {loading && suggestions.length
        ? <Skeleton variant="rect" height={200} /> : (
          <div className={classes.root}>
            <h3 className={classes.title}>
              Suggestions de produits
            </h3>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={2}>
                {suggestions.map((suggestion) =>
                  <Grid item xs={12} md={6} key={suggestion.name}>
                    <ProductBox item={suggestion} displayLabels={true} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </div>
        )
      }
    </React.Fragment>
  )
}