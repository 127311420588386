import React, { Fragment, useContext, useEffect, useState } from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// # COMPONENTS
import ModalProducteurs from '../../../components/Front/ModalNosProducteurs';

// # UTILS
import Meta from '../../../utils/metaDescription';
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # STYLE
import '../../../assets/css/mainstyle.css';
import '../../../assets/css/stylenosproducteurs.css';

// # IMAGES
import img8 from '../../../assets/img/val_conv_3.png';
import img9 from '../../../assets/img/IledeFrance.png';
import img10 from '../../../assets/img/logo_producteur-special.png';
import {UserContext} from "../../../contexts/UserContext/UserContext";

const useStyles = makeStyles({
  container: {
    maxWidth: '1024px',
    margin: '0 auto',
  },
  label: {
    height: 'fit-content',
    textAlign: 'center',
    margin: '0 0 150px 0',
    backgroundColor: '#F05C35',
    padding: '2rem',
    '& > p': {
      fontFamily: 'Amatic SC',
      fontSize: '2.5rem',
      color: 'white',
    },
    '& > img': {
      marginBottom: '-120px',
    },
  },
  label2: {
    height: 'fit-content',
    margin: 'auto',
    backgroundColor: '#f8d644',
    padding: '2rem',
    '& > p': {
      fontFamily: 'Amatic SC',
      fontSize: '2.5rem',
      color: 'white',
    },
    'p > img': {
      marginBottom: '-120px',
    },
  },
  label3: {
    height: 'fit-content',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#bfcf38',
    padding: '2rem',
    '& > p': {
      fontFamily: 'Amatic SC',
      fontSize: '2.5rem',
      color: 'white',
    },
    '& > img': {
      marginTop: '-80px',
    },
  },
  padding: {
    padding: '2rem',
  },
  grid: {
    padding: '1rem',
  },
  prod: {
    padding: '2rem',
    '@media screen and (max-width: 600px)': {
      padding: '1rem',
    },
  },
});

export default function NosProducteurs() {
  const classes = useStyles();

  const { user } = useContext(UserContext);

  const [state, setState] = useState({
    producers: [],
  });

  useEffect(() => {
    axiosGetRequest(`/producers`, user.token).then((res) => {
      const { producers } = res.data.data;
      setState((prev) => ({
        ...prev,
        producers,
      }));
    });
  }, [user.token]);

  return (
    <>
      <Meta page="producteurs" />

      <div className={classes.prod} id="nos_producteurs">
        <h1>Nos producteurs</h1>
        <p className="p">
          Bio Culture sélectionne pour vous des producteurs passionnés du Bio, <br /> du terroir et
          du travail bien fait.
        </p>

        <Grid className={classes.container} container>
          <Grid className={classes.grid} item xs={12} sm={6} md={4}>
            <div className={classes.label2}>
              <p className="greenbox">
                Un réseau de plus de
                <br />
                30 agriculteurs Bio
                <br />
                en Ile de France.
              </p>
              <img src={img9} alt="ablabel" />
            </div>
          </Grid>
          {state.producers
            .sort((a, b) => a.position - b.position)
            .map((producer, idx) =>
              idx === Math.round(state.producers.length / 2) ? (
                <Fragment key={producer._id}>
                  <Grid className={classes.grid} item xs={12} sm={6} md={4}>
                    <div className={classes.label}>
                      <p>
                        Tous nos producteurs sont contrôlés et certifiés en Agriculture Biologique
                      </p>
                      <img src={img8} alt="ablabel" />
                    </div>
                  </Grid>
                  <Grid className={classes.grid} item xs={12} sm={6} md={4}>
                    <ModalProducteurs id="producteur1" infos={producer} />
                  </Grid>
                </Fragment>
              ) : (
                <Grid className={classes.grid} item key={producer._id} xs={12} sm={6} md={4}>
                  <ModalProducteurs id="producteur1" infos={producer} />
                </Grid>
              )
            )}

          <Grid className={classes.grid} item xs={12} sm={6} md={4}>
            <div className={classes.label3}>
              <img src={img10} alt="ablabel" />

              <p>Les producteurs fixent librement leurs prix, pour une juste rémunération.</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
