import React, { useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';

import {
  TextField,
  Button,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    minWidth: '400px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateSegmentModal() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    newSegment: {
      name: '',
      category: 'entreprise',
      amount: 0,
      description: '',
      isActive: true,
    },
  });

  const handleOpen = (bool) => {
    setState((prev) => ({
      ...prev,
      open: bool,
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      newSegment: {
        ...prev.newSegment,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeNum = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      newSegment: {
        ...prev.newSegment,
        [event.target.name]: event.target.value * 1,
      },
    }));
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const token = Cookies.get('jwt');

    await axios
      .post(`${process.env.REACT_APP_API}/segments`, state.newSegment, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const id = res.data.data.newSegment._id;

        window.location = `/admin/segments/${id}`;
        enqueueSnackbar('Le segment a été ajouté avec succès', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={(e) => handleOpen(true)}
        startIcon={<AddIcon />}
      >
        Nouveau
      </Button>
      <Modal
        className={classes.modal}
        open={state.open}
        onClose={(e) => handleOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={state.open}>
          <div className={classes.paper}>
            <h2>Nouveau segment</h2>
            <form className={classes.form} onSubmit={handleCreate}>
              <TextField
                className={classes.marginB}
                label="Nom"
                type="text"
                variant="outlined"
                name="name"
                onChange={handleChange}
              />
              <div className={classes.flex}>
                <FormControl variant="outlined" className={classes.marginB}>
                  <InputLabel>Catégorie</InputLabel>
                  <Select
                    native
                    label="Catégorie"
                    defaultValue={state.newSegment.category}
                    name="category"
                    onChange={handleChange}
                  >
                    <option value="relay">Point relais</option>
                    <option value="entreprise">Entreprise</option>
                    <option value="eco">Eco-acteur</option>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.marginB}>
                  <InputLabel>Statut</InputLabel>
                  <Select
                    native
                    label="Statut"
                    defaultValue={state.newSegment.category}
                    name="isActive"
                    onChange={handleChange}
                  >
                    <option value>Actif</option>
                    <option value={false}>Inactif</option>
                  </Select>
                </FormControl>
              </div>
              <TextField
                className={classes.marginB}
                label="Taux commercial"
                type="number"
                variant="outlined"
                name="amount"
                onChange={handleChangeNum}
              />
              <TextField
                className={classes.marginB}
                label="Description"
                type="text"
                variant="outlined"
                name="description"
                multiline
                onChange={handleChange}
              />
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Créer
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
