import moment from 'moment';

// INFO : GET SCHEDULES FROM DELIVERY PLACE & RETURN AN ARRAY OF FORMATTED DELIVERY SCHEDULES
export const getSchedules = (schedules, closureTimes, amount, unit) => {
  const closureStart = closureTimes.start
  const closureEnd = closureTimes.end;

  const start = moment().add(1, 'day');
  const end = moment().add(amount, unit);

  const now = start.clone();
  const dates = [];

  while (now.isSameOrBefore(end)) {
    if (!now.isBetween(closureStart, closureEnd)) {
      dates.push(now.format('dddd Do MMMM'));
    }
    now.add(1, 'days');
  }

  const matchingDates = [];

  for (const el of dates) {
    const day = el.split(' ')[0];

    for (const schedule of schedules) {
      if (schedule.day.toLowerCase() === day) {
        const fullSchedule = el.concat(' ', `(${schedule.startTime} - ${schedule.endTime})`);
        matchingDates.push(fullSchedule);
      }
    }
  }

  return matchingDates;
};
