import React, { Fragment } from 'react';

// # CUSTOM COMPONENTS
import InvitationTable from '../../../components/Invitations/InvitationsTable';

export default function InvitationsDashboard() {
  return (
    <>
      <h1>Invitations</h1>
      <InvitationTable />
    </>
  );
}
