import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  Link,
  TextField,
  Badge,
} from '@material-ui/core';

import EuroIcon from '@material-ui/icons/Euro';
import currencyFormater from '../../../utils/currencyFormater';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import {calcTotalOrder} from "../../../utils/totalOrders";

const useStyles = makeStyles({
  root: {
    margin: '0 0 1rem 0',
    borderRadius: '0',
    boxShadow: '2px 2px 10px grey',
  },
  margin: {
    margin: '10% auto',
    maxWidth: '600px',
    textAlign: 'left',
  },
  container: {
    maxHeight: 'fit-content',
  },
  delivery: {
    color: '#f05c33',
  },
  section: {
    margin: '2rem 0 4rem 0',
    padding: '2rem',
    textAlign: 'left',
    borderRadius: '0',
    boxShadow: '2px 2px 10px grey',
  },
  h3Bottom: {
    fontSize: '2rem',
    margin: '0 0 1rem 0',
  },
  h3Top: {
    margin: '2rem 0 1rem 0',
  },
  pub: {
    padding: '2rem',
    backgroundColor: '#bfcf38',
    color: 'white',
    marginBottom: '2rem',
    boxShadow: '2px 2px 10px grey',
    textAlign: 'center',
  },
  '.MuiTableCell-root': {
    fontFamily: 'Bitter',
  },
  tableTitle: {
    fontFamily: 'Amatic SC',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#8bc34a',
    margin: '1rem',
  },
});

export default function VosEcoPoints() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { user, setEcoInfos } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    invitations: [],
    discounts: [],
    checkbox: 'gagne',
    user: {
      ecoData: {
        ecoPoints: 0,
      },
    },
    orders: {},
    discountAmount: 1,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      user: { ...user },
    }));
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      const invitations = await axios
        .get(`${process.env.REACT_APP_API}/invitations/user`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => res.data.data.invitations)
        .catch((err) => {
          console.error(err);
        });

      await Promise.all(
        invitations.map(async (invitation) => {
          await axios
            .get(`${process.env.REACT_APP_API}/orders/?invitation=${invitation._id}`, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((res) => {
              const { orders } = res.data.data;
              setState((prev) => ({
                ...prev,
                orders: {
                  ...prev.orders,
                  [invitation._id]: [...orders],
                },
              }));
            })
            .catch((err) => {
              console.error(err);
            });
        })
      );

      const discounts = await axios
        .get(`${process.env.REACT_APP_API}/discounts/user`, {
          headers: {
            authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          const filter = res.data.data.discounts.filter((el) => el.name.startsWith('ECO-'));
          return filter;
        });
      setState((prev) => ({
        ...prev,
        invitations: [...invitations],
        discounts: [...discounts],
      }));
    };
    fetchData();
  }, [user.token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAmount = (event) => {
    event.persist();
    let { value } = event.target;
    if (value > state.user.ecoData.ecoPoints) {
      value = state.user.ecoData.ecoPoints;
    }
    setState((prev) => ({
      ...prev,
      discountAmount: value,
    }));
  };

  const handleSubmit = async () => {
    if (state.checkbox !== 'gagne') {
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/discounts/eco-discount`,
        {
          discountAmount: state.discountAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        const { newPointsAmount, newDiscount } = res.data.data;
        setEcoInfos({
          ecoPoints: newPointsAmount,
        });
        setState((prev) => ({
          ...prev,
          discounts: [...prev.discounts, newDiscount],
        }));
        enqueueSnackbar("Le bon d'achat a été généré", {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  const calcInvitInfos = (orders) => {
    const total = orders.filter((order) => order.status === 'paid')
      .reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0);

    let points = 0;
    if (total > 150) {
      points = Math.round(total * (10 / 100));
    }
    return { total, points };
  };

  const columns = [
    { id: 'id', label: 'ID', minWidth: 100, align: 'center' },
    {
      id: 'createdAt',
      label: "Date de l'invitation",
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'deliveryDate',
      label: 'Date de livraison',
      minWidth: 100,
      align: 'center',
    },
    { id: 'amount', label: 'Montant Payé', minWidth: 100, align: 'center' },
    {
      id: 'ecoPoint',
      label: 'Eco-Points',
      minWidth: 100,
      align: 'center',
    },
  ];

  return (
    <div className={classes.margin}>
      <h3 className={classes.h3Top}>Récapitulatif de vos invitations</h3>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.invitations.map((invitation) => (
                <TableRow key={invitation._id}>
                  <TableCell align="center">{invitation.id.slice(-5)}</TableCell>
                  <TableCell align="center">{moment(invitation.createdAt).format('LL')}</TableCell>
                  <TableCell align="center">
                    {moment(invitation.deliveryInfos.deliveryDate).format('LL')}
                  </TableCell>
                  <TableCell align="center">
                    {currencyFormater(calcInvitInfos(state.orders[invitation.id]).total)}
                  </TableCell>
                  <TableCell align="center">
                    {calcInvitInfos(state.orders[invitation.id]).points}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50]}
          component="div"
          count={state.invitations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Paper className={classes.section}>
        <Grid container justify="space-between" alignContent="flex-end">
          <Grid item xs={6}>
            <h3 className={classes.h3Bottom}>Il vous reste {user.ecoData.ecoPoints} éco-points</h3>
          </Grid>
          <Grid item xs={3}>
            <TextField
              style={{ fontFamily: 'Bitter' }}
              value={state.discountAmount}
              color="secondary"
              variant="outlined"
              type="number"
              onChange={handleAmount}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: state.user.ecoData.ecoPoints,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EuroIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <p style={{ marginBottom: '0' }}>
          Choisissez le montant que vous souhaitez retire selon les deux options ci-dessous
        </p>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkbox === 'gagne'}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  checkbox: 'gagne',
                }))
              }
              name="jegagne"
            />
          }
          label={
            <p>
              <b style={{ color: '#f05c33' }}>
                Générer un bon d’achat pour bénéficier de fruits et légumes Bio
              </b>
              <br />
              <em>J'utilise ce bon d’achat comme bon me semble</em>
            </p>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkbox === 'assoc'}
              disabled
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  checkbox: 'assoc',
                }))
              }
              name="jegagne"
            />
          }
          label={
            <p>
              <b style={{ color: '#f05c33' }}>
                Faire un don à une association (Option bientôt disponible)
              </b>
              <br />
              <em>
                Bio Culture se charge de tout et prend en charge la livraison ! Les dons sont
                effectués en fin de mois. La nature du don sera déterminée en fonction des besoins
                de la structure. Vous serez informé par e-mail dès que le don sera effectué.
              </em>{' '}
              <Link color="primary" href="/associations">
                {' '}
                La liste des associations{' '}
              </Link>{' '}
            </p>
          }
        />
        <Button
          className="btn-orange"
          disabled={state.checkbox !== 'gagne'}
          onClick={() => handleSubmit()}
        >
          Valider
        </Button>
      </Paper>

      <h3 className={classes.h3Top}>Vos bons de réduction et dons</h3>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.tableTitle}>Vos bons générés</p>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Bon</TableCell>
                    <TableCell align="center">Montant Payé</TableCell>
                    <TableCell align="center">Utilisations restantes</TableCell>
                    <TableCell align="center">Expiration</TableCell>
                    <TableCell align="center">Actif</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.discounts.length > 0 ? (
                    state.discounts.map((discount) => (
                      <TableRow key={discount._id}>
                        <TableCell align="left">{discount.name}</TableCell>
                        <TableCell align="center">
                          {discount.value} {discount.reduction === 'flat' ? '€' : '%'}
                        </TableCell>
                        <TableCell align="center">{discount.count}</TableCell>
                        <TableCell align="center">
                          {moment(discount.expireAt).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="center">
                          <Badge
                            badgeContent={discount.isActive ? 'Actif' : 'Inactif'}
                            color={discount.isActive ? 'primary' : 'secondary'}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Pas de bon d'achat
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 50]}
              component="div"
              count={state.discounts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.pub}>
        <p style={{ margin: '0 0 1rem 0' }}>
          Vous êtes une association ? Vous pouvez financer votre projet grâce aux Eco-Points !
          N'hésitez pas à nous contacter pour plus d'information.
        </p>
        <Button className="btn-orange">En savoir plus</Button>
      </div>
    </div>
  );
}
