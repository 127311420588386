import React, { useState, useEffect, useRef, Fragment } from 'react';

import { useLocation } from 'react-router';
import queryString from 'query-string';

import Cookies from 'js-cookie';
import axios from 'axios';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment';
import 'moment/locale/fr';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Paper,
    TextField,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    Badge,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Switch,
} from '@material-ui/core';
import { MonetizationOnRounded, ShoppingCartRounded, Loop } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

// # CUSTOM COMPONENTS
import StatsRecap from '../../../components/Stats/StatsRecap';
import OrderTable from '../../../components/Order/OrderTable';

// # UTILS
import currencyFormater from '../../../utils/currencyFormater';
import { calcTotalOrder } from '../../../utils/totalOrders';
import { axiosPostRequest } from '../../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        overflow: 'auto',
        marginBottom: '1rem',
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        '& > button:first-child': {
            marginBottom: '1rem',
        },
    },
    firstLine: {
        '& > div': {
            minWidth: '150px',
            marginRight: '1rem',
        },
    },
    badge: {
        margin: '1rem 0 1rem 3.6rem',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 1rem 0',
    },
    flexAlign: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
    },
}));

class LabelPrint extends React.Component {
    render() {
        return (
            <div style={{ width: '234px' }}>
                {Object.entries(this.props.data).map((type, idx1) => (
                    // # DISPLAY TYPE
                    <Fragment key={idx1}>
                        <div
                            style={{
                                color: 'black',
                                display: 'flex',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pageBreakAfter: 'always',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '40px',
                                    lineHeight: '40px',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {type[0] === 'paniers' ? 'Les Paniers Bio' : 'Autres'}
                            </p>
                        </div>

                        {Object.entries(type[1]).map((category, idx2) => (
                            <Fragment key={idx2}>
                                {Object.entries(category[1]).map((zipcode, idx3) => (
                                    <Fragment key={idx3}>
                                        {type[0] !== 'paniers' && (
                                            <div
                                                style={{
                                                    color: 'black',
                                                    display: 'flex',
                                                    height: '100%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    pageBreakAfter: 'always',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: '50px',
                                                        lineHeight: '50px',
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    {zipcode[0]}
                                                </p>
                                            </div>
                                        )}
                                        {Object.entries(zipcode[1]).map((schedule, idx4) => (
                                            <Fragment key={idx4}>
                                                {Object.entries(schedule[1]).map((owner, idx5) => (
                                                    <Fragment key={idx5}>
                                                        {owner[1].map((label, labIdx) => (
                                                            <Fragment key={labIdx}>
                                                                <div
                                                                    style={{
                                                                        color: 'black',
                                                                        textAlign: 'center',
                                                                        // padding: '20px 2px 10px 5px',
                                                                        pageBreakAfter: 'always',
                                                                    }}
                                                                >
                                                                    {label[0].orderInfos.title &&
                                                                        label[0].orderInfos.title !== '' && (
                                                                            <>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: '15px',
                                                                                        lineHeight: '15px',
                                                                                        margin: '0',
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {label[0].orderInfos.title}
                                                                                    </span>
                                                                                </p>
                                                                                <hr
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        backgroundColor: 'black',
                                                                                        height: '2px',
                                                                                        width: '50%',
                                                                                        margin: '10px auto 15px auto',
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}

                                                                    {label[0].orderInfos.ecoSender &&
                                                                        label[0].orderInfos.ecoSender !== '' && (
                                                                            <>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: '15px',
                                                                                        lineHeight: '15px',
                                                                                        margin: '0',
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {label[0].orderInfos.ecoSender}
                                                                                    </span>
                                                                                </p>
                                                                                <hr
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        backgroundColor: 'black',
                                                                                        height: '2px',
                                                                                        width: '50%',
                                                                                        margin: '10px auto 15px auto',
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}

                                                                    <p
                                                                        style={{
                                                                            fontSize: '15px',
                                                                            lineHeight: '15px',
                                                                            margin: '0 0 10px 0',
                                                                        }}
                                                                    >
                                                                        {`${moment(
                                                                            label[0].orderInfos.deliveryDate
                                                                        ).format('DD/MM/YYYY')} - (${
                                                                            label[0].orderInfos.deliverySchedule
                                                                        })`}
                                                                    </p>

                                                                    <p
                                                                        style={{
                                                                            fontSize: '15px',
                                                                            lineHeight: '15px',
                                                                            margin: '0',
                                                                        }}
                                                                    >
                                                                        {label[0].orderInfos.zipCode}{' '}
                                                                        {label[0].orderInfos.city.toUpperCase()}
                                                                    </p>
                                                                    <hr
                                                                        style={{
                                                                            border: 'none',
                                                                            backgroundColor: 'black',
                                                                            height: '2px',
                                                                            width: '50%',
                                                                            margin: '10px auto 15px auto',
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            lineHeight: '16px',
                                                                            margin: '0',
                                                                        }}
                                                                    >
                                                                        <b>{label[0].owner}</b>
                                                                    </p>
                                                                    <hr
                                                                        style={{
                                                                            border: 'none',
                                                                            backgroundColor: 'black',
                                                                            height: '2px',
                                                                            width: '50%',
                                                                            margin: '10px auto 25px auto',
                                                                        }}
                                                                    />

                                                                    <div>
                                                                        {label.map((prod, index) => (
                                                                            <Fragment key={index}>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '17px',
                                                                                        margin: '10px 0 10px 0',
                                                                                    }}
                                                                                >
                                                                                    {prod.ref === 'Produit custom'
                                                                                        ? prod.name
                                                                                        : prod.ref}{' '}
                                                                                    {prod.quantity > 1 && (
                                                                                        <b
                                                                                            style={{
                                                                                                fontSize: '30px',
                                                                                                lineHeight: '33px',
                                                                                            }}
                                                                                        >
                                                                                            x{prod.quantity}
                                                                                        </b>
                                                                                    )}
                                                                                </p>
                                                                                {index + 1 !== label.length && (
                                                                                    <hr
                                                                                        style={{
                                                                                            border: 'none',
                                                                                            backgroundColor: 'black',
                                                                                            height: '1px',
                                                                                            width: '20%',
                                                                                            margin: '0 auto 10px auto',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Fragment>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        ))}
                                                    </Fragment>
                                                ))}
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                ))}
                            </Fragment>
                        ))}
                    </Fragment>
                ))}
            </div>
        );
    }
}

export default function OrderList() {
    const token = Cookies.get('jwt');
    moment.locale('fr');

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState({
        orders: [],
    });

    // # FORMATED DATA FOR MASSIVE LABELS PRINT
    const [formatedData, setFormatedData] = useState({});

    // # STATE
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [toggle, setToggle] = useState(false);

    const [filter, setFilter] = useState({
        deliveryAt: moment().add(1, 'days').format('YYYY-MM-DD'),
        advStatus: queryString.parse(useLocation().search).adv || 'all',
        payment: queryString.parse(useLocation().search).payment || 'all',
    });

    const classes = useStyles();

    // # DISPLAY ALL VALIDATES ORDERS
    useEffect(() => {
        const fetchValidateOrders = async () => {
            const token = Cookies.get('jwt');
            await axios
                .get(`${process.env.REACT_APP_API}/orders/?advStatus=validate`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    const { orders } = res.data.data;
                    const totalPaid = orders.reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0);
                    const sortedOrders = orders.sort(
                        (a, b) =>
                            moment(a.deliveryInfos.deliveryDate).format('YYYYMMDD') -
                            moment(b.deliveryInfos.deliveryDate).format('YYYYMMDD')
                    );

                    setData((prev) => ({
                        ...prev,
                        totalPaid,
                        ordersLen: orders.length,
                        orders: sortedOrders,
                    }));
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        if (toggle) {
            setLoading(true);
            fetchValidateOrders();
        }
    }, [toggle]);

    useEffect(() => {
        if (!toggle) {
            setLoading(true);

            if (filter.advStatus === 'validate') {
                const fetchData = async () => {
                    const orders = await axios
                        .get(
                            `${process.env.REACT_APP_API}/orders/delivery/${filter.deliveryAt}?adv=${filter.advStatus}&payment=${filter.payment}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .catch((err) => {
                            console.log(err);
                        });

                    console.log('orders', orders);

                    const sortedArray = orders.data.data.orders.sort(
                        (a, b) =>
                            moment(a.deliveryInfos.deliveryDate).format('YYYYMMDD') -
                            moment(b.deliveryInfos.deliveryDate).format('YYYYMMDD')
                    );
                    setData({ orders: sortedArray });
                    setLoading(false);
                };
                fetchData();
            } else {
                const fetchData = async () => {
                    const token = Cookies.get('jwt');
                    const orders = await axios
                        .get(
                            `${process.env.REACT_APP_API}/orders/delivery/${filter.deliveryAt}?adv=${filter.advStatus}&payment=${filter.payment}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .catch((err) => {
                            console.log(err);
                        });
                    setData(orders.data.data);
                    setLoading(false);
                };
                fetchData();
            }
        }
    }, [filter, toggle]);

    const handleModalOpen = () => setModal(true);

    const handleModalClose = () => setModal(false);

    const handleRollbackOrders = () => {
        axiosPostRequest('/orders/rollback', token, { date: filter.deliveryAt }).then(() => {
            setModal(false);
            setFilter((prev) => ({
                ...prev,
                advStatus: 'validate',
            }));
        });
    };

    const handleAdv = (data) => {
        setFilter((prev) => ({
            ...prev,
            advStatus: data,
        }));
    };

    const handlePayment = (data) => {
        setFilter((prev) => ({
            ...prev,
            payment: data,
        }));
    };

    const handleDate = (data) => {
        setFilter((prev) => ({
            ...prev,
            deliveryAt: data,
        }));
    };

    const archivedMany = async () => {
        const token = Cookies.get('jwt');
        await axios
            .post(
                `${process.env.REACT_APP_API}/orders/archivedMany`,
                { date: filter.deliveryAt },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setOpen(false);

                new Promise((resolve, reject) => {
                    resolve(setFormatedData(res.data.formatedData));
                })
                    .then(() => {
                        const newStateOrders = data.orders.filter(
                            (order) => order.status !== 'paid' || order.advStatus !== 'validate'
                        );
                        setData({ orders: newStateOrders });
                    })
                    .then(() => handlePrint());
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        if (data.orders && data.orders.length > 0) {
            const validOrders = data.orders.filter(
                (order) => order.status === 'paid' && order.advStatus === 'validate'
            );
            if (validOrders.length > 0) {
                setOpen(true);
            } else {
                return enqueueSnackbar('Aucune commande validée ET payée à cette date', {
                    variant: 'error',
                });
            }
        } else {
            return enqueueSnackbar('Aucune commande à cette date', {
                variant: 'error',
            });
        }
    };

    return (
        <>
            <Dialog open={modal} onClose={handleModalClose}>
                <DialogTitle>{`Voulez-vous annuler l'achivage des commandes du ${moment(filter.deliveryAt).format(
                    'DD MMMM YYYY'
                )}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous allez repasser toutes les commandes payées et archivées à cette date à l'état "Validée"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary" variant="outlined">
                        Annuler
                    </Button>
                    <Button onClick={handleRollbackOrders} color="primary" variant="contained">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>

            {queryString.parse(useLocation().search).adv === 'validate' && !toggle && (
                <div style={{ overflow: 'hidden', height: 0, width: 0 }}>
                    <LabelPrint ref={componentRef} data={formatedData} />
                </div>
            )}
            <div>
                {queryString.parse(useLocation().search).adv === 'validate' ? (
                    <h2>Toutes les commandes validées</h2>
                ) : (
                    <h2>Toutes les commandes</h2>
                )}
                <StatsRecap
                    datas={[
                        {
                            name: 'Total Payé',
                            picto: <ShoppingCartRounded />,
                            value: currencyFormater(
                                data.orders
                                    .filter((order) => order.status === 'paid')
                                    .reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0)
                            ),
                        },
                        {
                            name: 'Nombre de commandes',
                            picto: <MonetizationOnRounded />,
                            value: data.orders.length,
                        },
                    ]}
                    loading={loading}
                />
                {queryString.parse(useLocation().search).adv === 'validate' && (
                    <Paper className={classes.paper}>
                        <h3>Outils de recherche</h3>
                        <div className={classes.flexCenter}>
                            <div className={classes.flexAlign}>
                                {!toggle && (
                                    <TextField
                                        style={{ marginRight: '1rem' }}
                                        label="Date de livraison"
                                        type="date"
                                        value={filter.deliveryAt}
                                        onChange={(e) => handleDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                <FormControlLabel
                                    control={<Switch checked={toggle} onChange={() => setToggle((prev) => !prev)} />}
                                    label="Toutes les commandes"
                                />
                            </div>
                            {!toggle && (
                                <>
                                    <div className={classes.actions}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<Loop />}
                                            onClick={() => handleModalOpen()}
                                        >
                                            Annuler l'archivage
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                                            Archiver et imprimer
                                        </Button>
                                    </div>
                                    <Dialog open={open} onClose={handleClose}>
                                        <DialogTitle>Confirmez l'archivage</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Vous allez archiver puis imprimer toutes les commandes du{' '}
                                                {moment(filter.deliveryAt).format('DD/MM/YYYY')}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button autoFocus onClick={handleClose} color="primary">
                                                Annuler
                                            </Button>
                                            <Button onClick={archivedMany} color="primary">
                                                Confirmer
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            )}
                        </div>
                    </Paper>
                )}
            </div>
            {queryString.parse(useLocation().search).adv !== 'validate' && (
                <Paper className={classes.paper}>
                    <h3>Outils de recherche</h3>
                    <div className={classes.firstLine}>
                        {filter.advStatus !== 'validate' && (
                            <>
                                <TextField
                                    label="Date de livraison"
                                    type="date"
                                    value={filter.deliveryAt}
                                    onChange={(e) => handleDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <FormControl variant="outlined">
                                    <InputLabel id="select-payment-label">Statut de paiement</InputLabel>
                                    <Select
                                        labelId="select-payment-label"
                                        label="Statut de paiement"
                                        autoWidth
                                        value={filter.payment}
                                        onChange={(e) => handlePayment(e.target.value)}
                                    >
                                        <MenuItem value="all">Tous</MenuItem>
                                        <MenuItem value="canceled">Annulée</MenuItem>
                                        <MenuItem value="paid">Payée</MenuItem>
                                        <MenuItem value="refuse">Refusée</MenuItem>
                                        <MenuItem value="waiting">En attente de paiement</MenuItem>
                                        <MenuItem value="refund">Remboursé</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        <FormControl variant="outlined">
                            <InputLabel id="select-adv-label">Statut ADV</InputLabel>
                            <Select
                                labelId="select-adv-label"
                                label="Statut ADV"
                                value={filter.advStatus}
                                onChange={(e) => handleAdv(e.target.value)}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                <MenuItem value="input">
                                    <Badge
                                        className={classes.badge}
                                        size="medium"
                                        badgeContent="En cours de saisie"
                                        color="secondary"
                                    />
                                </MenuItem>
                                <MenuItem value="validate">
                                    <Badge
                                        className={classes.badge}
                                        size="medium"
                                        badgeContent="Validée"
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem value="archived">
                                    <Badge
                                        className={classes.badge}
                                        size="medium"
                                        badgeContent="Archivée"
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem value="canceled">
                                    <Badge
                                        className={classes.badge}
                                        size="medium"
                                        badgeContent="Annulée"
                                        color="error"
                                    />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Paper>
            )}
            {loading ? (
                <Skeleton variant="rect" width={500} height={150} />
            ) : (
                <div>
                    <OrderTable data={data} />
                </div>
            )}
        </>
    );
}
