import React from 'react';
import { Link, ListItem, ListItemIcon, ListSubheader, List, ListItemText, Collapse } from '@material-ui/core';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import {
  CardMembership,
  ShoppingCart,
  ExpandLess,
  ExpandMore,
  LocalOffer,
  Receipt,
  PermContactCalendar,
  Info,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: '60px',
  },
}));

export default function SalesMenu() {
  const classes = useStyles();
  const [openOrders, setOpenOrders] = React.useState(false);
  const [openDiscount, setOpenDiscount] = React.useState(false);
  const [openDeliveryNote, setOpenDeliveryNote] = React.useState(false);
  const [openEco, setOpenEco] = React.useState(false);
  const [openSubscriber, setOpenSubscriber] = React.useState(false);

  const handleOpenOrders = () => {
    setOpenOrders((prev) => !prev);
  };

  const handleOpenDiscount = () => {
    setOpenDiscount((prev) => !prev);
  };

  const handleOpenDeliveryNote = () => {
    setOpenDeliveryNote((prev) => !prev);
  };

  const handleOpenEco = () => {
    setOpenEco((prev) => !prev);
  };

  const handleOpenSubscriber = () => setOpenSubscriber((prev) => !prev);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Ventes
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem>
        <ListItemIcon>
          <Info />
        </ListItemIcon>
        <Link href="/admin/orders/infos">Informations du jour</Link>
      </ListItem>
      <ListItem button onClick={handleOpenOrders}>
        <ListItemIcon>
          <ShoppingCart />
        </ListItemIcon>
        <ListItemText primary="Commandes" />
        {openOrders ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <SubdirectoryArrowRightIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={<Link href="/admin/orders?adv=validate&payment=all">Commandes validées</Link>} />
      </ListItem>
      <Collapse in={openOrders} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/orders?adv=all&payment=all">Toutes les commandes</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/orders?adv=input&payment=all">En cours de saisie</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/orders?adv=processed&payment=all">Traitées</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/orders?adv=archived&payment=all">Archivées</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/orders?adv=canceled&payment=all">Annulées</Link>
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleOpenDeliveryNote}>
        <ListItemIcon>
          <Receipt />
        </ListItemIcon>
        <ListItemText primary="Bons de livraison" />
        {openDeliveryNote ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDeliveryNote} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/deliveryNotes">Tous les bons de livraisons</Link>
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleOpenDiscount}>
        <ListItemIcon>
          <LocalOffer />
        </ListItemIcon>
        <ListItemText primary="Codes promo" />
        {openDiscount ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDiscount} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/discounts/list/all">Tous les codes</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/discounts/list/percent">Tous les bons de réduc°</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/discounts/list/flat">Tous les bons d'achat</Link>
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={handleOpenEco}>
        <ListItemIcon>
          <PermContactCalendar />
        </ListItemIcon>
        <ListItemText primary="Eco Acteurs" />
        {openEco ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openEco} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/invitations">Invitations</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/statistics">Statistiques</Link>
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={handleOpenSubscriber}>
        <ListItemIcon>
          <CardMembership />
        </ListItemIcon>
        <ListItemText primary="Abonnés" />
        {openSubscriber ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openSubscriber} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/subscribers/BtoC/management">Gestion B2C</Link>
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
