import React from 'react';

// # CUSTOM COMPONENTS
import CustomChip from '../../Basics/CustomChip';

export default function GroupedInfos(props) {
  const { type, infos } = props;

  return (
    <>
      {type === 'composition' && (
        <CustomChip
          color="secondary"
          label={`${infos.weight.quantity} ${infos.weight.abbreviation}`}
        />
      )}

      {type === 'product' && (
        <CustomChip
          color="secondary"
          label={`${infos.weight.quantity} ${infos.weight.unity.abbreviation}`}
        />
      )}

      {type === 'basket' && (
        <>
          <CustomChip
            color="secondary"
            label={`${infos.persons.from} à ${infos.persons.to} pers`}
          />
          <CustomChip
            color="secondary"
            label={`${infos.weight.from} à ${infos.weight.to} ${infos.weight.unity.abbreviation}`}
          />
        </>
      )}
    </>
  );
}
