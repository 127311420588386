import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router';

import { useReactToPrint } from 'react-to-print';
import Cookies from 'js-cookie';
import moment from 'moment';

// # CUSTOM COMPONENTS
import { makeStyles } from '@material-ui/core/styles';

// # UTILS

// # MATERIAL UI
import { Grid, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { axiosGetRequest, axiosPatchRequest } from '../../../utils/axiosRequests';
import OrderDetails from '../../../components/Order/OrderDetails';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flex: {
    display: 'flex',
    width: '99%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '596px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

class LabelPrint extends React.Component {
  render() {
    return (
      <div style={{ width: '234px' }}>
        {this.props.labels.length > 0
          ? this.props.labels.map((lab, idx) => (
              <Fragment key={idx}>
                <div
                  style={{
                    color: 'black',
                    textAlign: 'center',
                    padding: '20px 2px 10px 5px',
                    pageBreakAfter: 'always',
                  }}
                >
                  {this.props.order.deliveryInfos.deliveryType === 'relayPoint' && (
                    <>
                      <p
                        style={{
                          fontSize: '15px',
                          lineHeight: '15px',
                          margin: '0',
                        }}
                      >
                        {this.props.order.deliveryInfos.deliveryAddress.title !== '' && (
                          <span>{this.props.order.deliveryInfos.deliveryAddress.title}</span>
                        )}
                      </p>
                      <hr
                        style={{
                          border: 'none',
                          backgroundColor: 'black',
                          height: '2px',
                          width: '50%',
                          margin: '10px auto 15px auto',
                        }}
                      />
                    </>
                  )}
                  {this.props.order.invitation && (
                    <>
                      <p
                        style={{
                          fontSize: '15px',
                          lineHeight: '15px',
                          margin: '0',
                        }}
                      >
                        Eco-acteur :<br />
                        {this.props.order.invitation.sender.fullname}
                      </p>
                      <hr
                        style={{
                          border: 'none',
                          backgroundColor: 'black',
                          height: '2px',
                          width: '50%',
                          margin: '10px auto 15px auto',
                        }}
                      />
                    </>
                  )}
                  <p
                    style={{
                      fontSize: '15px',
                      lineHeight: '15px',
                      margin: '0 0 10px 0',
                    }}
                  >
                    {`${moment(this.props.order.deliveryInfos.deliveryDate).format(
                      'DD/MM/YYYY'
                    )} - (${this.props.order.deliveryInfos.deliverySchedule})`}
                  </p>
                  <p
                    style={{
                      fontSize: '15px',
                      lineHeight: '15px',
                      margin: '0',
                    }}
                  >
                    {this.props.order.deliveryInfos.deliveryAddress.zipCode}{' '}
                    {this.props.order.deliveryInfos.deliveryAddress.city.toUpperCase()}
                  </p>
                  <hr
                    style={{
                      border: 'none',
                      backgroundColor: 'black',
                      height: '2px',
                      width: '50%',
                      margin: '10px auto 15px auto',
                    }}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: '16px',
                      margin: '0',
                    }}
                  >
                    <b>
                      {this.props.order.owner.lastname} {this.props.order.owner.firstname}
                    </b>
                  </p>
                  <hr
                    style={{
                      border: 'none',
                      backgroundColor: 'black',
                      height: '2px',
                      width: '50%',
                      margin: '10px auto 25px auto',
                    }}
                  />
                  <div>
                    {this.props.order.owner.groupLabels ? (
                      lab.map((prod, index) => (
                        <p
                          key={index}
                          style={{
                            fontSize: '16px',
                            lineHeight: '17px',
                            margin: '10px 0 10px 0',
                          }}
                        >
                          {prod.ref === 'Produit custom' ? prod.name : prod.ref}{' '}
                          {prod.quantity > 1 && (
                            <b style={{ fontSize: '30px', lineHeight: '33px' }}>x{prod.quantity}</b>
                          )}
                          {index + 1 !== lab.length && (
                            <hr
                              style={{
                                border: 'none',
                                backgroundColor: 'black',
                                height: '1px',
                                width: '20%',
                                margin: '10px auto 10px auto',
                              }}
                            />
                          )}
                        </p>
                      ))
                    ) : (
                      <p
                        key={idx}
                        style={{
                          fontSize: '16px',
                          lineHeight: '16px',
                          margin: '0 0 15px 0',
                        }}
                      >
                        {lab.name}{' '}
                        {lab.quantity > 1 && (
                          <b style={{ fontSize: '30px', lineHeight: '33px' }}>x{lab.quantity}</b>
                        )}
                        <b style={{ fontSize: '10px', display: 'block' }}>{lab.ref}</b>
                      </p>
                    )}
                  </div>
                </div>
              </Fragment>
            ))
          : null}
      </div>
    );
  }
}

export default function OrderPage() {
  const classes = useStyles();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const token = Cookies.get('jwt');
  const { id } = useParams();

  const [state, setState] = useState({
    order: {},
    labels: [],
    loading: true,
  });

  useEffect(() => {
    axiosGetRequest(`/orders/${id}`, token).then((res) => {
      console.log(res.data.data.order)
      setState((prev) => ({
        ...prev,
        order: res.data.data.order,
        loading: false,
      }));
    });
  }, [id, token]);

  const handleChangeAdv = (value) => {
    axiosPatchRequest(`/orders/adv/${id}`, token, { advStatus: value })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          order: res.data.data.order,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdvShortcut = () => {
    axiosPatchRequest(`/orders/adv/${id}`, token, { advStatus: 'archived' })
      .then(() => {
        setState((prev) => ({
          ...prev,
          order: {
            ...prev.order,
            advStatus: 'archived',
          },
        }));
        getLabels();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getLabels = () => {
    axiosGetRequest(`/orders/labels/${id}`, token)
      .then((res) => {
        new Promise((resolve) => {
          resolve(
            setState((prev) => ({
              ...prev,
              labels: res.data.labels,
            }))
          );
        }).then(() => handlePrint());
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <Grid container spacing={3}>
      <div className={classes.flex}>
        <h2>Détails de la commande ID : {id}</h2>
        <div className={classes.margin}>
          <div>
            <Button variant="contained" color="primary" href={`/admin/orders/updateOrder/${id}`}>
              Modifier la commande
            </Button>
          </div>
        </div>
      </div>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <div style={{ overflow: 'hidden', height: 0, width: 0 }}>
            <LabelPrint ref={componentRef} order={state.order} labels={state.labels} />
          </div>
          <OrderDetails
            order={state.order}
            methods={{
              handleChangeAdv,
              handleAdvShortcut,
              getLabels,
            }}
            token={token}
          />
        </>
      )}
    </Grid>
  );
}
