import React, {Fragment, useState, useEffect} from 'react';
import Cookies from "js-cookie";
import moment from "moment";

import {Paper, TextField, Grid, Button} from '@material-ui/core';
import {makeStyles} from "@material-ui/core";
import {axiosGetRequest, axiosPatchRequest} from "../../../utils/axiosRequests";

const useStyle = makeStyles({
  paper: {
    padding: '1rem',
    '& > div > div > h3': {
      marginTop: 0
    }
  }
})

export default function CloseWebsite() {
  const classes = useStyle();
  const token = Cookies.get('jwt');
  const [state, setState] = useState({
    closureStart: null,
    closureEnd: null
  });

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
    console.log(e.target.name);
  };

  const handleSubmit = async() => {
    const update = {
        closureTimes: {
          start: state.closureStart,
          end: state.closureEnd,
        }
      };
    const response = axiosPatchRequest('/configs/closureTimes', token, update).then(res => res.data.data.infos).catch(err => console.log(err));
    console.log(response);
  }

  useEffect(() => {
    const fetch = async() => {
      const closureTimesData = await axiosGetRequest('/configs/closureTimes').then(res => res.data.data.infos).catch(err => console.log(err));
      console.log('closureTimes', closureTimesData);

      closureTimesData && setState((prev) => ({
        ...prev,
        closureStart: closureTimesData.setting.closureTimes.start,
        closureEnd: closureTimesData.setting.closureTimes.end,
      }))
    };
    fetch();
  }, []);


  return (
    <Fragment>
      <h1>Fermeture du site</h1>

      <Paper className={classes.paper}>
        <p>Début de fermeture : {moment(state.closureStart).format('LL')}</p>
        <p>Fin de fermeture : {moment(state.closureEnd).format('LL')}</p>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <h3>Date de début</h3>
            <TextField onChange={handleChange} name={'closureStart'} type={"date"} size={"small"} variant={"outlined"}/>
          </Grid>
          <Grid item xs={3}>
            <h3>Date de fin</h3>
            <TextField onChange={handleChange} name={'closureEnd'} type={"date"} size={"small"} variant={"outlined"}/>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleSubmit} color={'primary'} variant={'contained'}>Valider</Button>
          </Grid>
        </Grid>
      </Paper>

    </Fragment>
  );
}