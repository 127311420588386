import React, { useState, useEffect, Fragment } from 'react';

// # LIBS
import axios from 'axios';
import Cookies from 'js-cookie';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
  Link,
  TextField,
  Button,
  Switch,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';

// # UTILS
import currencyFormater from '../../utils/currencyFormater';
import { axiosPatchRequest } from '../../utils/axiosRequests';

const columns = [
  { id: 'image', label: 'Image', minWidth: 100, align: 'left' },
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  { id: 'ref', label: 'Référence', minWidth: 100, align: 'left' },
  {
    id: 'category',
    label: 'Rubrique',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'subcategory',
    label: 'Sous-rubrique',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'price',
    label: 'Prix',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'tax',
    label: 'TVA',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'inventory',
    label: 'Stock',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'right',
  },
  {
    id: '',
    label: '',
    minWidth: 100,
    align: 'center',
  },
];

const columnsBis = [
  { id: 'ordre', label: 'Ordre', maxWidth: 50, align: 'center' },
  { id: 'image', label: 'Image', minWidth: 100, align: 'left' },
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  { id: 'ref', label: 'Référence', minWidth: 100, align: 'left' },
  {
    id: 'category',
    label: 'Rubrique',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'subcategory',
    label: 'Sous-rubrique',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'price',
    label: 'Prix',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'tax',
    label: 'TVA',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'inventory',
    label: 'Stock',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  div: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  switch: {
    marginLeft: '30px',
  },
});

export default function ProductTable(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [state, setState] = useState({
    isReorder: false,
    products: props.data.sort((a, b) => a.position - b.position),
    saved: false,
    changingProducts: [],
    updateProduct: [],
  });

  const handleClick = (id, idx) => {
    const temp = state.products[idx];
    temp.isRestock = true;

    setState((prev) => ({
      ...prev,
      products: prev.products.map((el, index) => (index === idx ? temp : el)),
    }));
  };

  const handleStockChange = (idx, id, e) => {
    const temp = state.products[idx];
    temp.stock = e.target.value;

    setState((prev) => ({
      ...prev,
      products: prev.products.map((el, index) => (index === idx ? temp : el)),
    }));
  };

  const handleStockValidation = async (idx, id, e) => {
    if (e.keyCode !== 13) {
      return;
    }

    const temp = state.products[idx];
    temp.isRestock = false;

    setState((prev) => ({
      ...prev,
      products: prev.products.map((el, index) => (index === idx ? temp : el)),
    }));

    await axios.patch(`${process.env.REACT_APP_API}/products/${id}`, temp, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleChange = (id, idx) => {
    const temp = state.products[idx];
    temp.isActive = !temp.isActive;

    axiosPatchRequest(`/products/status/${id}`, token, { isActive: temp.isActive })
      .then(() => {
        setState((prev) => ({
          ...prev,
          products: prev.products.map((el, index) => (index === idx ? temp : el)),
        }));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      products: props.data.sort((a, b) => a.position - b.position),
      changingProducts: [],
    }));
    if (props.subcategory !== 'all') {
      setState((prev) => ({
        ...prev,
        isReorder: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        isReorder: false,
      }));
    }
  }, [props]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (idx, id, e) => {
    if (!state.saved) {
      setState((prev) => ({
        ...prev,
        saved: !prev.saved,
      }));
    }
    const tempProduct = [...state.products];
    const tempChange = {
      id,
      position: e.target.value,
    };

    const filter = state.changingProducts.filter((el) => el.id === id);

    if (filter.length === 0) {
      const temp = state.changingProducts;
      temp.push(tempChange);
      setState((prev) => ({
        ...prev,
        changingProducts: temp,
      }));
    } else {
      const change = state.changingProducts.find((el) => el.id === tempChange.id);
      change.position = tempChange.position;
    }

    tempProduct[idx].position = e.target.value * 1;

    setState((prev) => ({
      ...prev,
      products: tempProduct.sort((a, b) => a.position - b.position),
    }));
  };

  const handleSubmit = async () => {
    await axios
      .patch(`${process.env.REACT_APP_API}/products`, state.changingProducts, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        console.log(err.response);
        enqueueSnackbar(`Une erreur s'est produite`, {
          variant: 'error',
        });
      })
      .then(
        enqueueSnackbar('Les modifications ont été prises en compte', {
          variant: 'success',
        }),
        setState((prev) => ({
          ...prev,
          saved: !prev.saved,
        }))
      );
  };

  return (
    <>
      {state.saved && (
        <div className={classes.div}>
          {state.isReorder && (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              align="flex-end"
              onClick={handleSubmit}
              style={{ marginTop: '1rem' }}
            >
              Enregistrer les modifications
            </Button>
          )}
        </div>
      )}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {state.isReorder ? (
                <TableRow>
                  {columnsBis.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {state.products
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((product, idx) => (
                  <TableRow key={idx}>
                    {state.isReorder && (
                      <TableCell align="left">
                        <TextField
                          id="order"
                          key={idx}
                          variant="outlined"
                          type="number"
                          value={product.position}
                          onChange={(e) => handleSort(idx, product._id, e)}
                        />
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <img
                        src={`${process.env.REACT_APP_AWS}/${product.photo}`}
                        alt={product.name}
                        width="50"
                        height="50"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Link href={`/admin/products/${product._id}`}>{product.name}</Link>
                    </TableCell>
                    <TableCell align="left">{product.reference}</TableCell>
                    <TableCell align="left">{product.category.name}</TableCell>
                    <TableCell align="left">{product.subCategory.name}</TableCell>
                    <TableCell align="center">{currencyFormater(product.price)}</TableCell>
                    <TableCell align="center">{product.tvaRate.amount} %</TableCell>
                    <TableCell align="center" onClick={() => handleClick(product.id, idx)}>
                      {product.isRestock ? (
                        <TextField
                          key={idx}
                          variant="outlined"
                          value={product.stock}
                          onChange={(e) => handleStockChange(idx, product._id, e)}
                          onKeyDown={(e) => handleStockValidation(idx, product._id, e)}
                        />
                      ) : (
                        product.stock
                      )}
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      <Badge
                        badgeContent={product.isActive ? 'Actif' : 'Inactif'}
                        color={product.isActive ? 'primary' : 'error'}
                        onChange={() => handleChange(product.id, idx)}
                      />
                      <Switch
                        className={classes.switch}
                        checked={product.isActive}
                        onChange={() => handleChange(product.id, idx)}
                        name="checkedB"
                        color={product.isActive ? 'primary' : 'secondary'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100]}
          component="div"
          count={state.products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
