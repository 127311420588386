import { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

export default function BasicModal({ isDisabled, btnLabel, modalContent, onSubmit }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    onSubmit();
    handleClose();
  };

  return (
    <div>
      <Button disabled={isDisabled} variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
        {btnLabel}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{modalContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
