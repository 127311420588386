import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { Paper, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SubCategoryTable from '../../../components/SubCategory/SubCategoryTable';
import CreateSubCategoryModal from '../../../components/SubCategory/SubCategoryCreateModal';

// MATERIAL.UI

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
}));

export default function SubCategoryList() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    categories: [],
    subCategories: [],
    subCategoryShowcased: [],
    filter: {
      rubrique: 'all',
      status: 'all',
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/subcategories/`)
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          setState((prev) => ({
            ...prev,
            subCategories: res.data.data.subCategories,
            subCategoryShowcased: res.data.data.subCategories,
          }));
        });

      await axios
        .get(`${process.env.REACT_APP_API}/categories/`)
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          setState((prev) => ({
            ...prev,
            categories: res.data.data.categories,
          }));
        });

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    switch (state.filter.rubrique) {
      case 'all':
        if (state.filter.status === 'all') {
          setState((prev) => ({
            ...prev,
            subCategoryShowcased: prev.subCategories,
          }));
          break;
        }
        const filtrer = state.subCategories.filter((el) => el.isActive === state.filter.status);
        setState((prev) => ({
          ...prev,
          subCategoryShowcased: filtrer,
        }));
        break;

      default:
        const filterByCat = state.subCategories.filter(
          (el) => el.category.id === state.filter.rubrique
        );
        if (state.filter.status === 'all') {
          setState((prev) => ({
            ...prev,
            subCategoryShowcased: filterByCat,
          }));
          break;
        }
        const filterByStatus = filterByCat.filter((el) => el.isActive === state.filter.status);
        setState((prev) => ({
          ...prev,
          subCategoryShowcased: filterByStatus,
        }));
        break;
    }

    // if (state.filter.rubrique === 'all') {
    // }

    // const filtrer = state.subCategories.filter(
    //   (el) => el.category.id === state.filter
    // );

    // setState((prev) => ({
    //   ...prev,
    //   subCategoryShowcased: filtrer,
    // }));
  }, [state.subCategories, state.filter]);

  return (
    <>
      <div className={classes.flex}>
        <h2>Toutes les sous-rubriques</h2>
        {loading ? null : <CreateSubCategoryModal data={state.categories} />}
      </div>
      <Paper className={classes.paper}>
        <h3>Filtre</h3>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl style={{ minWidth: '200px', marginRight: '0.5rem' }} variant="outlined">
              <InputLabel id="select-payment-label">Rubrique</InputLabel>
              <Select
                labelId="select-payment-label"
                fullWidth
                label="Rubrique"
                defaultValue="all"
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    filter: {
                      ...prev.filter,
                      rubrique: e.target.value,
                    },
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                {state.categories.length > 0 &&
                  state.categories.map((el, idx) => (
                    <MenuItem key={idx} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: '200px' }} variant="outlined">
              <InputLabel id="select-payment-label">Statut</InputLabel>
              <Select
                labelId="select-payment-label"
                label="Statut`"
                fullWidth
                defaultValue="all"
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    filter: {
                      ...prev.filter,
                      status: e.target.value,
                    },
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value={false}>Inactif</MenuItem>
                <MenuItem value>Actif</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      {loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <SubCategoryTable rubrique={state.filter.rubrique} data={state.subCategoryShowcased} />
      )}
    </>
  );
}
