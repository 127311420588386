import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

// # MATERIAL.UI
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
// ## ICONS
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HomeIcon from '@material-ui/icons/Home';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// # CONTEXT
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { CartContext } from '../../../contexts/CartContext';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';
import { Purchase } from '../../../utils/gtmEcommerce';

// # CSS
import '../../../assets/css/contact.css';

const useStyles = makeStyles((theme) => ({
  icons: {
    color: 'white',
    backgroundColor: '#bfcf38',
    width: 'fit-content',
    height: '35px',
    margin: '0 auto',
    padding: '1rem',
    borderRadius: '50px',
  },
}));

export default function PaymentSuccess() {
  const { id } = useParams();

  const [state, setState] = useState({
    order: {},
  });

  const { user } = useContext(UserContext);
  const { clearCart } = useContext(CartContext);

  const classes = useStyles();

  useEffect(() => {
    clearCart();

    const order = axiosGetRequest(`/orders/${id}`, user.token).then((res) => {
      const { order } = res.data.data;

      // # ADD TRANSACTION INFOS TO DATALAYER FOR GTM
      if (!order.analytics) Purchase(order);

      return order;
    });

    let invitation;
    if (order.invitation) {
      invitation = axiosGetRequest(`/invitations/${order.invitation}`, user.token).then(
        (res) => res.data.data.invitation
      );
    }

    setState((prev) => ({
      ...prev,
      order: { ...order },
      invitation: { ...invitation },
    }));
  }, [id]);

  return (
    <div>
      <h1>Paiement réussi</h1>

      <h3>Merci de votre commande chez Bio Culture.</h3>
      <p>
        Nos équipes se mettent au travail pour vous apporter les meilleurs fruits et légumes Bio et
        Locaux.
      </p>

      {state.order.deliveryInfos ? (
        <Grid style={{ margin: '1rem 0' }} container spacing={3} justify="center">
          <Grid item xs={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.icons}>
                  <LocalShippingIcon fontSize="large" />
                </div>
              </Grid>
              <Grid item xs={12}>
                {moment(state.order.deliveryInfos.deliveryDate).format('LL')}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {state.order.deliveryInfos.deliveryType === 'home' ? (
                  <div className={classes.icons}>
                    <HomeIcon fontSize="large" />
                  </div>
                ) : state.order.deliveryInfos.deliveryType === 'relay' ? (
                  <LocationOnIcon fontSize="large" />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <p style={{ margin: 0 }}>
                  {state.order.deliveryInfos.deliveryAddress.address} <br />{' '}
                  {state.order.deliveryInfos.deliveryAddress.zipCode}{' '}
                  {state.order.deliveryInfos.deliveryAddress.city}
                </p>
              </Grid>
            </Grid>
          </Grid>
          {state.invitation.sender && (
            <Grid item xs={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className={classes.icons}>
                    <AccountCircleIcon fontSize="large" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {state.invitation.sender.fullname}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : null}
      <Button className="btn-orange" href="/profil/?page=com">
        Voir mes commandes
      </Button>

      {user.role === 'eco' ? null : (
        <div id="contact">
          <div style={{ margin: '0' }} className="appel">
            <div>
              {user.role === 'eco' ? (
                <p>À très vite. L'équipe de Bio Cutlure</p>
              ) : (
                <p>
                  Nous serions également ravis <br />
                  si vous préférez nous appeler !
                </p>
              )}

              <p className="num">
                Florence et Adrien : <br />
                01 41 14 18 10
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
