import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router';

import moment from 'moment';
import 'moment/locale/fr';

// # COMPONENTS
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Avatar,
  TextField,
  Button,
  Divider,
  Container,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import InvitationMailPreview from '../../../components/Invitations/InvitationMailPreview';

// # CONTEXT
import { UserContext } from '../../../contexts/UserContext/UserContext';

// # UTILS
import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';

// # MATERIAL.UI

// # IMG
import img from '../../../assets/img/fond_invitation.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  img: {
    marginBottom: '-5px',
    width: '100%',
  },
  root: {
    width: '60%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
  },
  flex: {
    marginTop: '30px',
    display: 'flex',
    width: '60%',
    margin: '0 auto',
    justifyContent: 'center',
  },
  flex2: {
    display: 'flex',
    width: '60%',
    margin: '0 auto',
  },
  Button: {
    backgroundColor: 'white',
    color: '#f05c34',
    border: '1px solid #f05c34',
    padding: '14px',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#f05c34',
      color: 'white',
    },
  },
  Button2: {
    backgroundColor: 'white !important',
    color: '#f05c34 !important',
    border: '1px solid #f05c34 !important',
    marginRight: '2vw',
    height: 'fit-content',
  },
  ButtonAnul: {
    backgroundColor: '#bfbdbd !important',
    color: 'white !important',
    marginRight: '2vw',
    height: 'fit-content',
  },
  span: {
    color: 'red',
  },
  input: {
    width: '80%',
    marginTop: '30px',
  },
}));

export default function UpdateInvitation() {
  moment.locale('fr');
  const { invitationId } = useParams();

  // INFOS : MATERIAL UI
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);

  const [state, setState] = useState({
    invitation: {
      id: '',
      ecoInvitationSettings: {
        customMessage: '',
        pickupSchedule: {
          from: '',
          to: '',
        },
      },
      deliveryInfos: {
        deliveryAddress: {
          address: '',
          zipCode: '',
          city: '',
          country: '',
          complement: '',
        },
        deliveryDate: '',
        deliverySchedule: '',
      },
      contacts: [],
    },
    contactsToRelaunch: [],
  });

  useEffect(() => {
    axiosGetRequest(`/invitations/${invitationId}`, user.token)
      .then((res) => {
        const { invitation } = res.data.data;
        const { ecoInvitationSettings, deliveryInfos, contacts, id } = invitation;
        setState((prev) => ({
          ...prev,
          invitation: {
            ...prev.invitation,
            id,
            ecoInvitationSettings: ecoInvitationSettings || {
              customMessage: '',
              pickupSchedule: {
                from: '17:00',
                to: '18:00',
              },
            },
            deliveryInfos,
            contacts,
          },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [invitationId, user, user.token]);

  const handleToggle = (id) => {
    const contact = state.contactsToRelaunch.find((el) => el === id);

    if (contact) {
      const newContacts = state.contactsToRelaunch.filter((el) => el !== id);
      return setState((prev) => ({
        ...prev,
        contactsToRelaunch: [...newContacts],
      }));
    }

    return setState((prev) => ({
      ...prev,
      contactsToRelaunch: [...prev.contactsToRelaunch, id],
    }));
  };

  const handleShortcut = (action) => {
    if (action === 'all') {
      const ids = state.invitation.contacts.map((contact) => contact.id);
      return setState((prev) => ({
        ...prev,
        contactsToRelaunch: [...ids],
      }));
    }

    return setState((prev) => ({
      ...prev,
      contactsToRelaunch: [],
    }));
  };

  const handleTime = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      invitation: {
        ...prev.invitation,
        ecoInvitationSettings: {
          ...prev.invitation.ecoInvitationSettings,
          pickupSchedule: {
            ...prev.invitation.ecoInvitationSettings.pickupSchedule,
            [name]: value,
          },
        },
      },
    }));
  };

  const handleChangeMail = (event) => {
    event.persist();
    const { value } = event.target;

    setState((prev) => ({
      ...prev,
      invitation: {
        ...prev.invitation,
        ecoInvitationSettings: {
          ...prev.invitation.ecoInvitationSettings,
          customMessage: value,
        },
      },
    }));
  };

  const handleRelaunch = () => {
    if (state.contactsToRelaunch.length === 0) {
      return enqueueSnackbar('Veuillez sélectionner des contacts à relancer', {
        variant: 'error',
      });
    }

    axiosPostRequest(`/invitations/relaunch`, user.token, {
      invitation: state.invitation,
      contactsToMail: state.contactsToRelaunch,
    })
      .then(() => {
        enqueueSnackbar('Les relances ont bien été envoyées', {
          variant: 'success',
        });
        window.location = '/invitations';
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
      });
  };

  return (
    <>
      <Container>
        <h1>Relancer mon invitation</h1>
        <h3>Choisissez vos invités :</h3>
        <p>
          Vous serez livrez le{' '}
          {moment(state.invitation.deliveryInfos.deliveryDate).format('dddd Do MMMM')} (
          {state.invitation.deliveryInfos.deliverySchedule})
        </p>

        <div className={classes.flex}>
          <h2>Liste de contacts à relancer</h2>
        </div>

        <List dense className={classes.root}>
          {state.invitation.contacts.map((contact, idx) => (
            <Fragment key={contact._id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${idx + 1}`}
                    src={`/static/images/avatar/${idx + 1}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={idx}
                  primary={`${contact.firstname} ${contact.lastname}`}
                  secondary={`${contact.email}`}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={state.contactsToRelaunch.indexOf(contact.id) !== -1}
                    onChange={() => handleToggle(contact.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <div className={classes.flex2}>
          <Button
            style={{ marginRight: '20px' }}
            className="btn-orange"
            onClick={() => handleShortcut('all')}
          >
            TOUT COCHER
          </Button>
          <Button className={classes.Button} onClick={() => handleShortcut('none')}>
            Tout décocher
          </Button>
        </div>

        <h3>Personnalisez votre e-mail d'invitation</h3>
        <p>
          Précisez l'heure de retrait des paniers chez vous{' '}
          <span className={classes.span}>(saisie obligatoire)</span>
        </p>

        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={1}>
            <p>Entre</p>
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="time"
              variant="outlined"
              value={state.invitation.ecoInvitationSettings.pickupSchedule.from}
              name="from"
              onChange={handleTime}
            />
          </Grid>
          <Grid item xs={1}>
            <p>et</p>
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="time"
              variant="outlined"
              value={state.invitation.ecoInvitationSettings.pickupSchedule.to}
              name="to"
              onChange={handleTime}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>Vous souhaitez ajouter un message personnalisé, écrivez-le ci-dessous :</p>
            <TextField
              multiline
              fullWidth
              color="primary"
              label="Texte personnalisé"
              variant="outlined"
              value={state.invitation.ecoInvitationSettings.customMessage}
              onChange={handleChangeMail}
            />
          </Grid>

          <Grid item xs={12}>
            <InvitationMailPreview
              message={state.invitation.ecoInvitationSettings.customMessage}
              userFirstname={user.firstname}
            />
          </Grid>
        </Grid>

        <div className={classes.flex}>
          <Button className="btn-orange" onClick={() => handleRelaunch()}>
            Relancer des invitations
          </Button>
        </div>
      </Container>
      <img className={classes.img} src={img} alt="fond_invitation" />
    </>
  );
}
