import React from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';

// # UTILS
import Meta from '../../../utils/metaDescription';

// # STYLE
import '../../../assets/css/stylezerodechets.css';

// # IMAGES
import img1 from '../../../assets/img/zero_sac_1.png';
import img2 from '../../../assets/img/zero_sac_2.png';
import img3 from '../../../assets/img/zero_sac_3.png';
import img4 from '../../../assets/img/fond-zero_pan_2.png';
import img5 from '../../../assets/img/zero_tomate.png';
import img6 from '../../../assets/img/zero_fraise.png';
import img7 from '../../../assets/img/zero_sac_cabas_1.png';
import img8 from '../../../assets/img/zero_sac_cabas_2.png';
import img9 from '../../../assets/img/zero_sac_recycle.png';
import img10 from '../../../assets/img/fleche-a.png';
import img11 from '../../../assets/img/fleche-b.png';
import img12 from '../../../assets/img/zero_sac_kraft.png';
import img13 from '../../../assets/img/zero_sac_sachet.png';

const useStyles = makeStyles(() => ({
  h3: {
    color: 'white !important',
    marginTop: '5rem',
  },
}));

export default function ZeroDechets() {
  const classes = useStyles();
  return (
    <>
      <Meta page="dechet" />

      <div id="zero_dechets">
        <div className="container">
          <h1>Zéro Dechet</h1>
          <p>
            L&apos;écologie et le respect de la terre sont au cœur même de la démarche de Bio
            Culture. Depuis sa création, nous veillons à limiter l&apos;impact écologique de
            l&apos;entreprise en proposant des solutions d&apos;emballage respectueuses de
            l&apos;environnement.
          </p>
          <h3>Un panier Bio = 0 % de déchet non renouvelable ou réutilisable</h3>

          <div className="section">
            <div>
              <img src={img1} alt="sac1" />
              <p>
                Nos sacs-cabas qui servent à transporter vos fruits et légumes sont fabriqués à
                partir de 20 à 80% de matière recyclée (issue des déchets de production) et sont
                100% recyclables (et réutilisables bien entendu).
              </p>
            </div>
            <div>
              <img src={img2} alt="sac2" />
              <p>
                Nos sacs Kraft sont réutilisables, biodégradables et recyclables. Ils sont de
                fabrication Européennes.
              </p>
            </div>
            <div>
              <img src={img3} alt="sac3" />
              <p>
                Nos sachets kraft sont biodégradables et recyclables (pensez au tri sélectif),
                issues de forets à gestion durable et de fabrication Française.
              </p>
            </div>
          </div>
        </div>

        <img width="100%" src={img4} alt="fond1" />
        <div className="cadre">
          <img src={img5} alt="tomate" />
          <div>
            <p>
              Nous vous invitions à participer avec nous à notre démarche en redonnant vos sacs Bio
              Culture à votre livreur, votre point relais ou dans votre entreprise afin qu&apos;ils
              soient réutilisés lors d&apos;une prochaine commande.
            </p>
            <h3 className={classes.h3}>
              C&apos;est grâce aux petites contributions qu&apos;il y a de grandes avancées.
            </h3>
          </div>
          <img src={img6} alt="fraise" />
        </div>

        <h3>Mon sac cabas</h3>

        <div className="container">
          <div className="picto">
            <div>
              <img src={img7} alt="sac1" />
              <p>Je le redonne en point relais ou au livreur pour une nouvelle utilisation</p>
            </div>
            <div className="fleche">
              <img src={img10} alt="flèche" />
            </div>
            <div>
              <img src={img8} alt="sac2" />
              <p>J&apos;ai le droit d&apos;en conserver un pour mes courses ;-)</p>
            </div>
            <div className="fleche">
              <img src={img11} alt="flèche" />
            </div>
            <div>
              <img src={img9} alt="sac3" />
              <p>
                S&apos;il est abimé, je le mets dans la poubelle « Recyclé » car Il est entièrement
                recyclable.
              </p>
            </div>
          </div>

          <h3>Mon sac kraft</h3>

          <div className="picto">
            <div>
              <img src={img12} alt="sac1" />
              <p>
                S&apos;il est en bon état, pliez-le proprement et redonnez-le à votre livreur, nous
                lui donnerons une seconde vie.
              </p>
            </div>
            <div className="fleche">
              <img src={img10} alt="flèche" />
            </div>
            <div>
              <img src={img8} alt="sac2" />
              <p>
                S&apos;il est abimé, je le mets dans la poubelle « Recyclé » car Il est entièrement
                recyclable.
              </p>
            </div>
          </div>

          <h3>Mes sachets</h3>

          <div className="picto">
            <div>
              <img src={img13} alt="sac1" />
              <p>Nous ne les réutilisons pas à cause de leur fragilité en cas de 2e usage.</p>
            </div>
            <div className="fleche">
              <img src={img10} alt="flèche" />
            </div>
            <div>
              <img src={img8} alt="sac2" />
              <p>
                Vous pouvez les mettre dans votre compost ou dans votre poubelle « spéciale papier »
                pour quils soient recyclés.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
