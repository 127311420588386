import React, { useState } from 'react';

import Cookies from 'js-cookie';

// # MATERIAL UI
import { Paper, Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { axiosDeleteRequest, axiosPatchRequest } from '../../utils/axiosRequests';

const useStyles = makeStyles({
  paper: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > figure': {
      width: '35%',
      margin: 0,

      '& img': {
        width: '100%',
        verticalAlign: 'text-bottom',
      },
    },

    '& > div': {
      width: '55%',
    },
  },
  textField: {
    margin: '0.5rem 0',
  },
});

export default function TeamCard(props) {
  const token = Cookies.get('jwt');
  const classes = useStyles();
  const { team, removeNewTeam } = props;
  const [state, setState] = useState({
    name: team.name,
    description: team.description,
    update: false,
  });
  const [value, setValue] = useState({
    name: team.name,
    description: team.description,
  });

  const onChange = (e) => {
    e.persist();
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdateOn = () => {
    setState((prevState) => ({
      ...prevState,
      update: true,
    }));
  };

  const handleUpdate = (id) => {
    axiosPatchRequest(`/teams/${id}`, token, {
      name: value.name,
      description: value.description,
    }).then((res) => {
      console.log(res);
      const { name, description } = res.data.data.newTeam;
      setState((prev) => ({
        name,
        description,
        update: false,
      }));
    });
  };

  const handleDelete = (id) => {
    axiosDeleteRequest(`/teams/${id}`, token).then(() => {
      removeNewTeam(id);
    });
  };

  return (
    <Grid item xs={4}>
      <Paper className={classes.paper}>
        <figure>
          <img
            src={`${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${team.photo}`}
            alt={team.name}
          />
        </figure>
        <div>
          {state.update ? (
            <TextField
              className={classes.textField}
              name="name"
              variant="outlined"
              size="small"
              defaultValue={value.name}
              fullWidth
              onChange={onChange}
            />
          ) : (
            <h4>{state.name}</h4>
          )}
          {state.update ? (
            <TextField
              className={classes.textField}
              name="description"
              variant="outlined"
              size="small"
              defaultValue={value.description}
              fullWidth
              onChange={onChange}
            />
          ) : (
            <p>{state.description}</p>
          )}
          <Button
            fullWidth
            className={classes.textField}
            variant="contained"
            color="primary"
            onClick={state.update ? () => handleUpdate(team.id) : handleUpdateOn}
          >
            {state.update ? 'Valider' : 'Modifier'}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(team.id)}
          >
            Supprimer
          </Button>
        </div>
      </Paper>
    </Grid>
  );
}
