import React from 'react';
import moment from 'moment';

// # MATERIAL UI
import { Grid, makeStyles } from '@material-ui/core';

// # CUSTOM COMPONENTS
import ProductSuggestion from './ProductSuggestion';
import BasketComposition from './BasketComposition';
import ReceiptSuggestion from './ReceiptSuggestion';
import ProducerList from './ProducerList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',

    '& > h4': {
      margin: '0 0 1rem 0',
      width: 'fit-content',
      fontFamily: 'Bitter',
      fontSize: '1rem',
      fontWeight: 500,
      borderBottom: '2px solid #F05C35',
    },

    [theme.breakpoints.up('lg')]: {
      maxHeight: '615px',
      overflow: 'hidden',
    },
  },
}));

export default function ColumnRight(props) {
  const classes = useStyles();
  const { product, setDrawer, producers } = props;

  const { type, id, products, availability, tags } = product;

  // # FORMATED DATE FOR BASKET COMPOSITION
  const formatedCompositionDate = (from, to) => {
    const dateFrom = from.split('-')[2];
    const dateTo = moment(to).format('DD MMMM');

    return `Composition du ${dateFrom} au ${dateTo}`;
  };

  return (
    <Grid className={classes.root} item xs={12} lg={5}>
      {type === 'product' && (
        <>
          {producers.length > 0 ? (
            <ProducerList title="Cultivé(e) par :" producers={producers} />
          ) : (
            <>
              <h4>Une idée recette :</h4>
              <ReceiptSuggestion tags={tags} />
            </>
          )}
          <h4 style={{ marginTop: '1rem' }}>Nos suggestions du jour...</h4>
          <ProductSuggestion productId={id} setDrawer={setDrawer} />
        </>
      )}

      {type === 'basket' && (
        <>
          <h4>{formatedCompositionDate(availability.from, availability.to)}</h4>
          <BasketComposition products={products} />
        </>
      )}
    </Grid>
  );
}
