import React, { useEffect, useState } from 'react';

// # CUSTOM COMPONENTS

// # MATERIAL UI
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CompositionCard from '../Card/CompositionCard';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90%',
    overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',

    [theme.breakpoints.down('md')]: {
      height: 'unset',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
  },
}));

export default function BasketComposition(props) {
  const classes = useStyles();

  const { products } = props;
  const [state, setState] = useState({
    loading: true,
    composition: [],
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      loading: false,
      composition: [...products],
    }));
  }, [products]);

  return (
    <Grid className={classes.root}>
      {state.loading ? (
        <Skeleton variant="rect" animation="wave" width="100%" height="100px" />
      ) : (
        state.composition.map((product, idx) => <CompositionCard key={idx} product={product} />)
      )}
    </Grid>
  );
}
