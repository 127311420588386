import React, { Fragment, useState, useContext } from 'react';
import moment from 'moment';

// # Context
import { CartContext } from '../contexts/CartContext';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Popover, Button, Grid, Badge } from '@material-ui/core';

// ICONS
import LocalShippingIcon from '@material-ui/icons/LocalShipping';


// STYLE
import '../assets/css/style.css';
import {LocalShipping} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      maxWidth: '250px',
      padding: '1rem',
    },
  },
  root: {
    margin: '1rem',
  },
  marginB: {
    marginBottom: '0.5rem',
  },
  iconButton: {
    marginRight: '1rem',
  },
  button: {
    backgroundColor: '#f05c33',
    color: 'white',
    fontFamily: 'Bitter',
    borderRadius: '30px',
    textTransform: 'Capitalize',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: '#bfcf38',
    },
  },
}));

export default function NavShipping() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  // # CONTEXTS
  const { deliveryInfos, setStep, setDrawerCart } = useContext(CartContext);

  const handleClick = (event) => {
    setStep(1);
    setDrawerCart(true);

    if (!deliveryInfos.deliveryAddress || deliveryInfos.deliveryAddress.zipCode === "") {
      return;
    }

    // setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
          aria-label="voir le panier"
          onClick={(e) => handleClick(e)}
      >
        <Badge variant={deliveryInfos.deliveryAddress.zipCode === "" ? "standard" : "dot"} color="secondary">
          <LocalShipping/>
        </Badge>
      </IconButton>
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 style={{ margin: '0 0 1rem 0' }}>Votre mode de livraison :</h2>
            </Grid>
            <Grid item xs={12}>
              <p style={{ marginTop: '0' }}>
                {deliveryInfos.deliveryType === 'home'
                  ? 'Livraison à domicile'
                  : deliveryInfos.deliveryType === 'relay'
                  ? 'Livraison en point relaie'
                  : deliveryInfos.deliveryType === 'entreprise'
                  ? 'Livraison en entreprise'
                  : null}
              </p>

              <p
                style={{
                  textTransform: 'Capitalize',
                  margin: '0',
                  color: 'gray',
                }}
              >
                {deliveryInfos.deliveryAddress.address}
              </p>

              <p
                style={{
                  textTransform: 'Capitalize',
                  margin: '0',
                  color: 'gray',
                }}
              >
                {deliveryInfos.deliveryAddress.zipCode}
                {deliveryInfos.deliveryAddress.city}
              </p>

              <p
                style={{
                  textTransform: 'Capitalize',
                  marginBottom: '0',
                }}
              >
                {moment(deliveryInfos.deliveryDate).format('dddd Do MMMM')}
              </p>

              <p
                style={{
                  textTransform: 'Capitalize',
                  margin: '0',
                }}
              >
                {deliveryInfos.deliverySchedule}
              </p>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => setDrawer(true)} className={classes.button}>
                Modifier
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
}
