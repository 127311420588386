import React from 'react';
import { Link, ListItem, ListItemIcon, ListSubheader, List, ListItemText, Collapse } from '@material-ui/core';

import { People, ExpandLess, ExpandMore, Store, LinearScale } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function ContactMenu() {
    const classes = useStyles();
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openRelay, setOpenRelay] = React.useState(false);
    const [openSegment, setOpenSegment] = React.useState(false);

    const handleOpenUsers = () => {
        setOpenUsers((prev) => !prev);
    };

    const handleOpenRelay = () => {
        setOpenRelay((prev) => !prev);
    };

    const handleOpenSegment = () => {
        setOpenSegment((prev) => !prev);
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Contacts
                </ListSubheader>
            }
            className={classes.root}
        >
            <ListItem button onClick={handleOpenUsers}>
                <ListItemIcon>
                    <People />
                </ListItemIcon>
                <ListItemText primary="Utilisateurs" />
                {openUsers ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/all">Tous les utilisateurs</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/user">Utilisateur simple</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/subscriberBtoC">Abonnés BtoC</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/subscriberBtoB">Abonnés BtoB</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/eco">Eco-acteur</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/conciergerie">Conciergerie</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/admin">Administrateur</Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/users/list/superAdmin">Super administrateur</Link>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleOpenRelay}>
                <ListItemIcon>
                    <Store />
                </ListItemIcon>
                <ListItemText primary="Points de livraison" />
                {openRelay ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openRelay} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/relayPoints">Tous les points de livraison</Link>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleOpenSegment}>
                <ListItemIcon>
                    <LinearScale />
                </ListItemIcon>
                <ListItemText primary="Segments" />
                {openSegment ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openSegment} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <Link href="/admin/segments">Tous les segments</Link>
                    </ListItem>
                </List>
            </Collapse>
        </List>
    );
}
