import React, { createContext, useReducer } from 'react';
import {SystemReducer} from "./SystemReducer";

export const SystemContext = createContext();

const closureTimes = localStorage.getItem('closureTimes') ? JSON.parse(localStorage.getItem('closureTimes')) : null;

const initalState = {
  closureTimes
}

function SystemContextProvider({children}) {
  const [state, dispatch] = useReducer(SystemReducer, initalState);

  const initialize = (payload) => {
    dispatch({ type: 'INITIALIZE', payload})
  }

  const contextValues = {
    initialize,
    ...state
  }
  return <SystemContext.Provider value={contextValues}>{children}</SystemContext.Provider>
}

export default SystemContextProvider;