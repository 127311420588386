import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
  Button,
  Checkbox,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import axios from 'axios';
import Cookies from 'js-cookie';
import currencyFormater from '../../utils/currencyFormater';
import { calcTotalOrder } from '../../utils/totalOrders';

moment.locale();

const columns = [
  {
    id: 'showCA',
    label: `Afficher CA`,
    minWidth: 100,
    align: 'center',
  },
  { id: 'promoCode', label: 'Code promo', minWidth: 100, align: 'left' },
  { id: 'nbr', label: "Nombre d'utilisation", minWidth: 100, align: 'center' },
  { id: 'CA', label: 'CA', minWidth: 100, align: 'center' },
  { id: 'value', label: 'Valeur', minWidth: 100, align: 'center' },
  {
    id: 'dispo',
    label: 'Utilisations disponibles',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'expires',
    label: 'Expiration',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'limited',
    label: 'Limité',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'active',
    label: 'Actif',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'supprimer',
    label: 'Supprimer',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  a: {
    color: '#adc00d',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default function DiscountTable(props) {
  const classes = useStyles();
  const jwt = Cookies.get('jwt');
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [state, setState] = useState({
    orders: {},
    cAselected: false,
    all: false,
    month: 'all',
  });

  useEffect(() => {
    const { all, month } = props;

    setState((prev) => ({
      ...prev,
      all,
      month,
    }));
  }, [props]);

  useEffect(() => {
    const body = {
      names: [...selected],
    };
    const tempObj = {};

    if (state.month !== 'all') {
      body.month = state.month;
    }

    const fetchData = async () => {
      const matchingOrders = await axios
        .post(`${process.env.REACT_APP_API}/discounts/orders`, body, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => res.data.data.orders);

      await matchingOrders.forEach((order) => {
        if (tempObj.hasOwnProperty(order.discount.name)) {
          return (tempObj[order.discount.name] = [...tempObj[order.discount.name], order]);
        }
        tempObj[order.discount.name] = [order];
      });

      setState((prev) => ({
        ...prev,
        orders: tempObj,
      }));
    };

    if (selected.length > 0) fetchData();
  }, [selected, jwt, props.data, state.month]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      all: props.all,
    }));

    //* if all checked so props.all === true then push $discount.name in $state.id and $selected

    if (props.all) {
      const discountsTemp = props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const discountArray = discountsTemp.map((discount) => discount.name);

      setSelected(discountArray);
    } else {
      setState((prev) => ({
        ...prev,
        orders: {},
      }));

      setSelected([]);
    }
  }, [props.all, page, rowsPerPage, props.data]);

  const handleTotal = (discountName) => {
    const orders = state.orders[discountName];
    if (orders) {
      return orders.reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0);
    }
    return 0;
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];

      const orderTemp = Object.keys(state.orders)
        .filter((key) => newSelected.includes(key))
        .reduce((el, idx) => {
          el[idx] = state.orders[idx];
          return el;
        }, {});

      setState((prev) => ({
        ...prev,
        orders: orderTemp,
      }));
    } else {
      newSelected = selected.filter((select) => select !== name);

      const orderTemp = Object.keys(state.orders)
        .filter((key) => newSelected.includes(key))
        .reduce((el, idx) => {
          el[idx] = state.orders[idx];
          return el;
        }, {});

      setState((prev) => ({
        ...prev,
        orders: orderTemp,
      }));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async (id) => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/discounts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = '/admin/discounts/list/all';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((discount) => {
                const isItemSelected = isSelected(discount.name);

                return (
                  <TableRow
                    key={discount._id}
                    hover
                    onClick={(event) => handleClick(event, discount.name, discount.id)}
                  >
                    <TableCell align="center">
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell align="left">
                      <a className={classes.a} href={`/admin/discounts/${discount._id}`}>
                        {discount.name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      {selected.indexOf(discount.name) !== -1 ? (
                        state.orders[discount.name] ? (
                          state.orders[discount.name].length
                        ) : (
                          0
                        )
                      ) : (
                        <Badge badgeContent="Masqué" color="secondary" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {selected.indexOf(discount.name) !== -1 ? (
                        currencyFormater(handleTotal(discount.name))
                      ) : (
                        <Badge badgeContent="Masqué" color="secondary" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {discount.value}
                      {discount.reduction === 'percent' ? '%' : '€'}
                    </TableCell>
                    <TableCell align="center">{discount.count}</TableCell>
                    <TableCell align="center">
                      {moment(discount.expireAt).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      <Badge
                        badgeContent={discount.limited ? 'Oui' : 'Non'}
                        color={discount.limited ? 'primary' : 'secondary'}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Badge
                        badgeContent={discount.isActive ? 'Actif' : 'Inactif'}
                        color={discount.isActive ? 'primary' : 'secondary'}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => handleDelete(discount._id)}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
