import React from 'react';

// # CUSTOM COMPONENTS
import { makeStyles, Grid } from '@material-ui/core';
import GroupedLabels from './GroupedLabels';
import GroupedInfos from './GroupedInfos';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    maxWidth: '275px',
    height: '100px',
    marginBottom: '1rem',
    padding: '5px',
    alignItems: 'center',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 5px 5px 0px #49494929',

    '& figure': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70px',
      height: '70px',
      margin: 0,

      '& > img': {
        width: '100%',
        verticalAlign: 'text-bottom',
      },
    },

    [theme.breakpoints.down('md')]: {
      minWidth: '250px',
      justifyContent: 'space-evenly',
      marginRight: '1rem',
    },

    [theme.breakpoints.only('xs')]: {
      minWidth: '230px',
      maxWidth: '255px',
      marginRight: 0,
    },

    [theme.breakpoints.down(545)]: {
      minWidth: '100%',
    },
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '& > h4': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: 'Bitter',
      fontWeight: '500',
      margin: '0 0 2px 0',
    },

    '& > p': {
      margin: '0 0 5px 0',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      color: '#767676',
    },
  },
  labels: {
    display: 'flex',
    alignItems: 'self-start',

    '& > div': {
      marginBottom: '5px',
    },
  },
}));

export default function CompositionCard(props) {
  const classes = useStyles();
  const { product } = props;
  const { photo, name, origin, weight, persons, labels } = product;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={4}>
        <figure>
          <img src={`${process.env.REACT_APP_AWS}/${photo}`} alt={name} />
        </figure>
      </Grid>
      <Grid item xs={8} className={classes.infos}>
        <h4>{name}</h4>
        <p>{origin}</p>
        <div className={classes.labels}>
          <GroupedInfos
            type="composition"
            infos={{
              weight: { ...weight },
              persons: { ...persons },
            }}
          />
          <GroupedLabels labels={labels} limit={2} />
        </div>
      </Grid>
    </Grid>
  );
}
