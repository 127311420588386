import React from 'react';

// # MATERIAL UI
import { Link, ListItem, ListItemIcon, ListSubheader, List } from '@material-ui/core';
import { Image, Home} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: '60px',
    },
}));

export default function OthersMenu() {
    const classes = useStyles();

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Site internet
                </ListSubheader>
            }
            className={classes.root}
        >
            <ListItem>
                <ListItemIcon>
                    <Image />
                </ListItemIcon>
                <Link href="/admin/banners">Pop Up</Link>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Home />
                </ListItemIcon>
                <Link href="/admin/pageHome">Bannière Home</Link>
            </ListItem>
        </List>
    );
}