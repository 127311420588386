import React, { useState, Fragment, useContext } from 'react';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  TextField,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
  contact: {
    marginTop: '2rem',
    marginBottom: '50px',
    padding: '1rem',
  },
  button2: {
    backgroundColor: 'white !important',
    color: '#f05c34 !important',
    border: '1px solid #f05c34 !important',
    marginRight: '2vw',
    height: 'fit-content',
  },
  flex: {
    display: 'flex',
    width: '100%',
    margin: '50px auto 0 auto',
    maxWidth: '600px',
  },
  root: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px',
  },
  delete: {
    backgroundColor: 'white !important',
    border: '1px solid #f05c34 !important',
  },
  modify: {
    backgroundColor: 'white !important',
    border: '1px solid #f05c34 !important',
    marginRight: '1rem',
  },
  marginRight: {
    marginRight: '10px',
  },
  padding: {
    padding: '15px',
  },
  modifyForm: {
    margin: '0 auto',
    width: '500px',
  },
  input: {
    margin: '0.5rem 0',
  },
  btnVert: {
    outline: 'none',
    cursor: 'pointer',
    fontFamily: 'Bitter',
    textTransform: 'inherit',
    color: 'white',
    padding: ' 10px 15px',
    fontSize: '1rem',
    backgroundColor: '#ADC00D',
    border: 'none',
    borderRadius: '40px',
    marginRight: '1rem',
    '&:hover ': {
      backgroundColor: '#f05c34',
      transition: '0.3s',
    },
  },
  button: {
    padding: '0.5rem 1rem',
    margin: '0 0 10px 0',
    backgroundColor: '#f05c34',
    color: 'white',
    borderRadius: '50px',

    '&:hover': {
      backgroundColor: '#bfcf38',
    },
  },
}));

export default function ContactList() {
  const classes = useStyles();
  const { user, removeContact } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    edit: false,
    deleteDialog: {
      open: false,
      id: '',
    },
    index: 0,
    contactId: null,
    editedContact: {
      firstname: '',
      lastname: '',
      phoneNumber: '',
      email: '',
    },
  });

  const handleDelete = async () => {
    const deleteContactId = state.deleteDialog.id;

    await axios
      .delete(`${process.env.REACT_APP_API}/users/contacts/${deleteContactId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        removeContact(deleteContactId);
        setState((prev) => ({
          ...prev,
          deleteDialog: {
            ...prev.deleteDialog,
            open: false,
            id: '',
          },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEditOpen = (contact, i) => {
    setState((prev) => ({
      ...prev,
      edit: true,
      index: i,
      contactId: contact._id,
      editedContact: {
        firstname: contact.firstname,
        lastname: contact.lastname,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();

    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      editedContact: {
        ...prev.editedContact,
        [name]: value,
      },
    }));
  };

  const handleChangeContact = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_API}/users/contacts/${state.contactId}`,
        { ...state.editedContact, phoneNumber: state.editedContact.phoneNumber || '0000000000' },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar('Votre contact a bien été ajouté', {
          variant: 'success',
        });
        const { contacts } = state;

        contacts[state.index] = res.data.data.contact;

        setState((prev) => ({
          ...prev,
          contacts,
        }));
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });

    setState((prev) => ({
      ...prev,
      edit: false,
    }));
  };

  const handleDialogClose = () => {
    setState((prev) => ({
      ...prev,
      deleteDialog: {
        open: false,
        id: '',
      },
    }));
  };

  const handleDialogOpen = (id) => {
    setState((prev) => ({
      ...prev,
      deleteDialog: {
        open: true,
        id,
      },
    }));
  };

  return (
    <div className={classes.contact}>
      <Collapse in={state.edit}>
        <div className={classes.modifyForm}>
          <h1>Modifier un contact</h1>
          <TextField
            className={classes.input}
            required
            fullWidth
            color="secondary"
            id="outlined-basic"
            label="Prénom"
            variant="outlined"
            value={state.editedContact.firstname}
            name="firstname"
            onChange={handleChange}
          />
          <TextField
            className={classes.input}
            required
            fullWidth
            color="secondary"
            id="outlined-basic"
            label="Nom"
            variant="outlined"
            value={state.editedContact.lastname}
            name="lastname"
            onChange={handleChange}
          />
          <TextField
            className={classes.input}
            required
            fullWidth
            color="secondary"
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            value={state.editedContact.email}
            name="email"
            onChange={handleChange}
          />
          <TextField
            className={classes.input}
            fullWidth
            color="secondary"
            id="outlined-basic"
            type="number"
            label="Téléphone"
            variant="outlined"
            value={
              state.editedContact.phoneNumber === '0000000000'
                ? ''
                : state.editedContact.phoneNumber
            }
            name="phoneNumber"
            onChange={handleChange}
          />
          <Button className={classes.btnVert} onClick={() => handleChangeContact()}>
            Modifier
          </Button>
          <Button className="btn-orange" onClick={() => handleChangeContact()}>
            Annuler
          </Button>
        </div>
      </Collapse>
      <Collapse in={!state.edit}>
        <h1>Liste de contacts</h1>
        <div>
          <div className={classes.flex}>
            <Button href="/create_contact" className={classes.button}>
              Ajouter un contact
            </Button>
          </div>
          <List dense className={classes.root}>
            {user.ecoData.contacts.map((contact, idx) => (
              <Fragment key={contact._id}>
                <ListItem className={classes.padding} button>
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n°${idx + 1}`}
                      src={`/static/images/avatar/${idx + 1}.jpg`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={idx}
                    primary={`${contact.firstname} ${contact.lastname}`}
                    secondary={`${contact.email} ${
                      contact.phoneNumber === '0000000000' ? '' : `/ ${contact.phoneNumber}`
                    }`}
                  />
                  <ListItemSecondaryAction>
                    {contact.role === 'contact' ? (
                      <IconButton
                        onClick={() => handleEditOpen(contact, idx)}
                        className={classes.modify}
                      >
                        <CreateIcon color="secondary" />
                      </IconButton>
                    ) : null}
                    <IconButton
                      onClick={() => handleDialogOpen(contact._id)}
                      className={classes.delete}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
          <Dialog
            open={state.deleteDialog.open}
            onClose={handleDialogClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }}>Confirmez la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>Êtes-vous sûre de vouloir supprimer ce contact</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleDialogClose()} color="primary">
                Annuler
              </Button>
              <Button color="primary" onClick={() => handleDelete()}>
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Collapse>
    </div>
  );
}
