import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import Meta from '../../../utils/metaDescription';

// STYLE//
import '../../../assets/css/styleOffreEntreprise.css';

// IMAGES//
import img1 from '../../../assets/img/entreprise-1.png';
import img2 from '../../../assets/img/picto/ordi.png';
import img3 from '../../../assets/img/entreprise-3.png';
import img4 from '../../../assets/img/fond-val_pan_2.png';

export default function OffreEntreprise() {
  const checkout = () => {
    window.location = '/contact-entreprise';
  };

  return (
    <>
      <Meta page="entreprises" />

      <div id="offreentreprise">
        <div className="container">
          <h1>l'offre entreprise : àdaptée à chaque besoin</h1>
          <p>
            Offrez un service de qualité à vos salariés en proposant la livraison de paniers
            <br />
            de fruits et légumes Bio au bureau
          </p>
          <div className="row">
            <img src={img1} alt="entreprise" />
            <div>
              <p>Taille de mon entreprise : de 100 à plusieurs milliers</p>
              <p>Un code entreprise pour chacun des salariés</p>
              <p>
                Temps nécéssaire à la mise en place : 15 mn
                <br />
                Suivi nécéssaire : Communication ponctuelle
                <br />
                Gestion du paiement : Chacun paie sa commande directement sur notre site internet
                <br />
                Avantage : Gestion simple / Autonomie de chacun
              </p>
            </div>
          </div>

          <div className="list">
            <img src={img2} alt="entreprise" />
            <div>
              <p>
                {' '}
                <span>1/</span> Lors d'un appel de 10 mn avec Bio Culture, je définis mes besoins :
                Jour de livraison, choix du code entreprise, etc ...
              </p>
              <p>
                {' '}
                <span>2/</span> Je communique à tous les salariés un code entreprise, leur
                permettant de commander en toute autonomie sur le site de Bio Culture
              </p>
              <p>
                {' '}
                <span>3/</span> Le jour qui a été défini, je suis livré de l'ensemble des paniers
                qui ont été commandés (les paniers sont nominatifs)
              </p>
            </div>
          </div>

          <Button className="btn-orange" onClick={() => checkout()}>
            Contactez-nous
          </Button>

          <div className="row">
            <img src={img3} alt="entreprise" />
            <div>
              <p>Taille de mon entreprise : de 5 à 100 salariés</p>
              <p>Une plateforme de commandes groupées</p>
              <p>
                Temps nécéssaire à la mise en place : 20 mn
                <br />
                Suivi nécéssaire : 5 à 7 mn à chaque lancement d'invitation
                <br />
                Gestion du paiement : Chacun paie sa commande directement sur notre site internet
                <br />
                Avantage : Je bénéficie d'Eco-points sur mes commandes
              </p>
            </div>
          </div>

          <div className="list">
            <img src={img4} alt="entreprise" />
            <div>
              <p>
                {' '}
                <span>1/</span> Je crée mon compte sur la plateforme collaborative : Bio Culture et
                Vous
              </p>
              <p>
                {' '}
                <span>2/</span> J'enregistre sur mon tableau de bord mes collègues intéressés
              </p>
              <p>
                {' '}
                <span>3/</span> Je lance, quand je le souhaite, une invitation à une commande
                groupée.
              </p>
              <p>
                {' '}
                <span>4/</span> Je suis livré de l'ensemble des paniers qui ont été commandés le
                jour que j'ai choisi (Les paniers sont nominatifs)
              </p>
            </div>
          </div>

          <Button className="btn-orange" onClick={() => checkout()}>
            Contactez-nous
          </Button>
        </div>
      </div>
    </>
  );
}
