import React, {createRef, Fragment, useState} from 'react';
import {Button, Grid, Paper, TextField} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import {makeStyles} from "@material-ui/core/styles";
import {axiosPostRequest} from "../../utils/axiosRequests";
import Cookies from "js-cookie";

const useStyles = makeStyles({
    paper: {
        padding: '1rem',
        marginBottom: '1rem'
    },
    instructions: {
        padding: '1rem',
      backgroundColor: '#F7F5DC',
        borderRadius: '8px',
        marginBottom: '1rem',
        '& > h3': {
            margin: 0,
            color: '#F05C35',
        },
        '& > p': {
            marginBottom: 0,
            '& > b': {
                color: 'red',
            }
        }
    },
    image: {
        borderRadius: '8px',
        margin: 'auto',
        '& > img': {
            borderRadius: '8px',
            width: '100%'
        },
    },
    inputFile: {
        display: 'none',
    },
    imageTable:{
        '&:hover':{
            cursor: 'pointer',
        }
    }
})

export default function AddBanner(props) {
    const classes = useStyles();
    const token = Cookies.get('jwt');
    const {widden, addNewBannerToList} = props;
    const myRef = createRef();
    const initialState = {
        title: undefined,
        photo: undefined,
        isActive: undefined,
    };
    const [state, setState] = useState({
        addBanner: {...initialState},
    })

    const selectFile = () => {
        myRef.current.click();
    }

    const handleChange = (e) => {
        e.persist();
        const {name, value} = e.target;
        setState(prev => ({
            ...prev,
            addBanner: {
                ...prev.addBanner,
                [name]: value
            }
        }))
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        setState((prev) => ({
            ...prev,
            addBanner: {
                ...prev.addBanner,
                photo: file,
            },
        }));
    };

    const handleCreateBanner = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('title', state.addBanner.title);
        formData.append('photo', state.addBanner.photo);

        axiosPostRequest(`/banners/`, token, formData)
            .then((res) => {
                const { banner } = res.data.data;
                setState((prev) => ({
                    ...prev,
                    addBanner: { ...initialState },
                }));

                // # UPDATE PRODUCERS ARR IN PARENT COMPONENT
                addNewBannerToList(banner);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }

    return (
        <Fragment>
            <div className={classes.instructions}>
                <h3>Instructions</h3>
                <p>Afin d'ajouter une Pop Up, vous devez importer une image, ajouter un titre puis valider. Afin d'actionner la Pop Up, il suffit d'activer le switch lorsque la Pop Up apparaît dans la tableau. <br/> Si une autre Pop Up était déjà activée, elle se désactivera automatiquement. <br/> <b>Attention: la taille maximales des images est 160GB, si l'upload ne fonctionne pas, les images sont peut-être trop grosses.</b></p>
            </div>
            <Paper className={classes.paper}>
                <h2 style={{marginTop: 0}}>Ajouter une Pop Up</h2>
                <Grid container spacing={3} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={7}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item xs={2}>
                                <figure className={classes.image}>
                                    {state.addBanner.photo ? (
                                        <img className={classes.imageTable} onClick={()=>widden(URL.createObjectURL(state.addBanner.photo))} src={URL.createObjectURL(state.addBanner.photo)} alt="upload" />
                                    ) : (
                                        <img src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`} alt="missing" />
                                    )}
                                </figure>
                                <input ref={myRef} className={classes.inputFile} type="file" onChange={handleFileChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth onClick={selectFile} variant={"contained"} color={"primary"}><ImageIcon/></Button>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField fullWidth id="outlined-basic" size={"small"} name={"title"} label="Titre" variant="outlined" onChange={handleChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant={"contained"} color={'primary'} onClick={handleCreateBanner}>Valider</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    );
}