import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/fr';

import { CartContext } from '../../../contexts/CartContext';
import { SystemContext } from "../../../contexts/SystemContext/SystemContext";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 50px)',
    gridTemplateRows: 'repeat(6, 50px)',
    columnGap: '10px',
    rowGap: '10px',
    margin: '0 auto',
    padding: '1rem',
    [theme.breakpoints.between("xs", "xl")]: {
      marginLeft: '4em'
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: '2em',
      marginLeft: '1em !important',
    },
  },
  month: {
    gridColumnStart: 2,
    gridColumnEnd: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  day: {
    color: '#7c7c7c',
    backgroundColor: '#ffffff',
    border: 'none',
    fontFamily: 'Bitter',
    fontWeight: 600,
    letterSpacing: '2px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      cursor: 'pointer',
    },
    '&:disabled': {
      color: '#dcdcdc',
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'unset',
      },
    },
  },
  arrow: {
    backgroundColor: 'white',
    border: 'none',
    color: 'black',
    '&:hover': {
      color: '#f05c35',
      cursor: 'pointer',
    },
  },
  alert: {
    margin: '1em 4em',
  },
  schedules: {
    [theme.breakpoints.between("xs", "xl")]: {
      marginLeft: '4em'
    }
  },
  schedule: {
    padding: '0.5em 1em',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    fontSize: '14px',
    cursor: "pointer",
    '&:hover': {
      background: theme.palette.primary.main,
      border: '1px solid transparent',
      color: '#fff'
    }
  },
  selectedSchedule: {
    padding: '0.5em 1em',
    borderRadius: '5px',
    background: theme.palette.primary.main,
    border: '1px solid transparent',
    color: '#fff',
    fontSize: '14px',
    cursor: "pointer",
  }
}));

export default function Calendar(props) {
  moment.locale('fr');

  const classes = useStyles();
  const { relay } = props;

  const {
    deliveryInfos,
    setDeliveryInfos
  } = useContext(CartContext);

  const { closureTimes } = useContext(SystemContext);

  const weekdays = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];
  const dates = relay && relay.schedules ? relay.schedules : props.dates;

  const [days, setDays] = useState([]);
  const [displayMonth, setDisplayMonth] = useState(moment().format('YYYY-MM'));

  const getCalendarDayStyle = (idx, date) => {
    const style = {};

    if (idx === 0) style.gridColumnStart = moment(displayMonth).isoWeekday();
    if (date === deliveryInfos.deliveryDate) {
      style.backgroundColor = '#bfcd46';
      style.color = 'white';
    }

    return style;
  };

  const goPreviousMonth = () => {
    setDisplayMonth( moment(displayMonth).subtract(1, 'month').format('YYYY-MM'));
  };

  const goNextMonth = () => {
    setDisplayMonth( moment(displayMonth).add(1, 'month').format('YYYY-MM'));
  };

  const handleDateChange = (date) => {
    deliveryInfos.deliveryDate = date;
    deliveryInfos.deliverySchedule = null;
    setDeliveryInfos(deliveryInfos);
  };

  const handleScheduleChange = (schedule) => {
    deliveryInfos.deliverySchedule = schedule.startTime + ' - ' + schedule.endTime;
    setDeliveryInfos(deliveryInfos);
  }

  useEffect(() => {
    const monthLength = moment(displayMonth, 'YYYY-MM').daysInMonth();
    const start = moment(`${displayMonth}-01`);
    const end = moment(`${displayMonth}-${monthLength}`);
    const now = start.clone();
    const today = moment();
    const lastDay = moment().add(4, 'month');

    const days = [];

    while (now.isSameOrBefore(end)) {
      let disabled = true;
      const day = now.format('dddd');

      if (dates && now.isBetween(today, lastDay)) {
        disabled = !dates.some((date) => date.day === day);
      }

      if (now.isBetween(closureTimes.start, closureTimes.end) || moment(now).format('YYYY-MM-DD') === closureTimes.start || moment(now).format('YYYY-MM-DD') === closureTimes.end) {
        disabled = true;
      }

      if (props.relay?.closure) {
        props.relay?.closure.forEach(close => {
          if(now.isBetween(close.start, close.end)){
            disabled = true;
          }
        });
      }

      days.push({
        formattedDate: now.format('YYYY-MM-DD'),
        day,
        disabled
      });

      now.add(1, 'days');
    }

    setDays(days);

  }, [displayMonth, relay, dates]);

  return (
    <React.Fragment>
      {dates.length < 1 &&
        <Alert severity="info" className={classes.alert}>Nous ne livrons pas encore votre ville. Vous habitez en ile de France ? Contactez-nous, nous pourrons peut-être vous livrer prochainement.</Alert>
      }
      <section className={classes.container}>
        <button onClick={() => goPreviousMonth()} className={classes.arrow}>
          <ArrowBackIosIcon fontSize="small" />
        </button>
        <section className={classes.month}>{moment(displayMonth).format('MMMM YYYY')}</section>
        <button onClick={() => goNextMonth()} className={classes.arrow}>
          <ArrowForwardIosIcon fontSize="small" />
        </button>
        {weekdays.map((weekday, index) => (
          <section key={index} className={classes.weekdays}>
            <p>{weekday}</p>
          </section>
        ))}
        {days.map((day, index) => (
          <button
            style={getCalendarDayStyle(index, day.formattedDate)}
            key={index}
            disabled={day.disabled}
            onClick={() => handleDateChange(day.formattedDate)}
            className={classes.day}
          >
            <p>{index + 1}</p>
          </button>
        ))}
      </section>
      {deliveryInfos.deliveryDate && dates &&
        <Box sx={{ display: 'flex', padding: '0.5em 1.5em' }} className={classes.schedules}>
          {dates
            .filter((date) => date.day === moment(deliveryInfos.deliveryDate).format('dddd'))
            .sort((a, b) => a.startTime > b.startTime ? 1 : -1)
            .map((schedule) =>
              <Box sx={{ marginRight: '1em' }} onClick={() => handleScheduleChange(schedule)}>
                <span className={deliveryInfos.deliverySchedule && deliveryInfos.deliverySchedule === schedule.startTime + ' - ' + schedule.endTime ? classes.selectedSchedule : classes.schedule}>
                  {schedule.startTime} - {schedule.endTime}
                </span>
              </Box>
            )
          }
        </Box>
      }
    </React.Fragment>
  );
}