import Cookies from 'js-cookie';

import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';
import { useCallback, useEffect, useState } from 'react';

export const useFetchSubscribersWithOrder = () => {
  const token = Cookies.get('jwt');

  const duplicateOrdersFn = useCallback(
    async (subscriberIds) => {
      return axiosPostRequest('/subscribers/duplicateLastOrder', token, { subscriberIds });
    },
    [token]
  );

  const [result, setResult] = useState({
    loading: true,
    subscribers: [],
    duplicateOrdersFn,
  });
  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosGetRequest('/subscribers/getSubscribersWithOrder', token);

      if (response.status === 200) {
        setResult((prev) => ({
          ...prev,
          loading: false,
          subscribers: response.data.data.subscribers,
        }));
      }
    };

    fetchData();
  }, [token]);

  return result;
};
