import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// # MATERIAL UI
import { Collapse, Button, Grid, Box } from '@material-ui/core';

// # COMPONENTS
import AddProducer from '../../../components/Producer/AddProducer';
import UpdateProducer from '../../../components/Producer/UpdateProducer';
import ProducerCard from '../../../components/Producer/ProducerCard';

// # UTILS
import { axiosGetRequest, axiosDeleteRequest } from '../../../utils/axiosRequests';
import { autocompleteGroupedFormater } from '../../../utils/stringFormater';

const initialState = {
  open: false,
  action: 'create',
  stagingProducer: {},
};

export default function ProducersList() {
  const token = Cookies.get('jwt');

  const [state, setState] = useState({
    producers: [],
    tags: [],
  });
  const [dialog, setDialog] = useState({ ...initialState });

  const handleOpen = (action) => setDialog((prev) => ({ ...prev, open: !prev.open, action }));

  const handleDelete = (id) => {
    axiosDeleteRequest(`/producers/${id}`, token).then((res) => {
      setState((prev) => ({
        ...prev,
        producers: prev.producers.filter((producer) => producer.id !== id),
      }));
    });
  };

  useEffect(() => {
    // # FETCH ALL PRODUCERS
    axiosGetRequest(`/producers`, token).then((res) => {
      const { producers } = res.data.data;
      setState((prev) => ({
        ...prev,
        producers,
      }));
    });

    // # FETCH TAGS
    axiosGetRequest('/tags/', token).then((res) => {
      const { tags } = res.data.data;
      const formattedTags = tags.map((tag) => ({
        ...tag,
        cleanName: tag.name.toLowerCase().split('.')[1],
      }));
      const tagsFormat = autocompleteGroupedFormater(formattedTags, 'cleanName');
      setState((prev) => ({
        ...prev,
        tags: [...tagsFormat],
      }));
    });
  }, [token]);

  const addNewProducerToList = (producer) => {
    setState((prev) => ({
      ...prev,
      producers: [producer, ...prev.producers],
    }));
  };

  const handleUpdate = (producerId) => {
    document.getElementsByClassName(
      'div.MuiContainer-root.makeStyles-container-13.MuiContainer-maxWidthLg'
    ).scrollTop = 0;
    const matchingProducer = state.producers.find((producer) => producer.id === producerId);

    setDialog((prev) => ({
      ...prev,
      open: true,
      action: 'update',
      stagingProducer: { ...matchingProducer },
    }));
  };

  const updateProducerToList = (updatedProducer) => {
    console.log(updatedProducer);
    const newProducersArr = state.producers.map((producer) => {
      if (producer.id === updatedProducer.id) {
        return updatedProducer;
      }
      return producer;
    });

    setState((prev) => ({
      ...prev,
      producers: newProducersArr,
    }));

    setDialog({ ...initialState });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1 id="title">Les Producteurs</h1>
        {dialog.open ? (
          <Button variant="contained" color="secondary" onClick={() => handleOpen('create')}>
            Annuler
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={() => handleOpen('create')}>
            Ajouter un producteur
          </Button>
        )}
      </Box>

      {dialog.open && (
        <Collapse style={{ margin: '2rem 0' }} in={dialog.open}>
          {dialog.action === 'create' && (
            <AddProducer
              producers={state.producers}
              addNewProducerToList={addNewProducerToList}
              tags={state.tags}
            />
          )}
          {dialog.action === 'update' && (
            <UpdateProducer
              updateProducerToList={updateProducerToList}
              producer={dialog.stagingProducer}
              tags={state.tags}
            />
          )}
        </Collapse>
      )}

      <Grid container spacing={3}>
        {state.producers
          .sort((a, b) => a.position - b.position)
          .map((producer, idx) => (
            <ProducerCard
              key={idx}
              producer={producer}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
            />
          ))}
      </Grid>
    </>
  );
}
