import React, { useState } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';

// MATERIAL.UI IMPORT
import TextField from '@material-ui/core/TextField';

// CSS
import '../../assets/css/contact.css';

export default function Contact() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    lastname: '',
    firstname: '',
    phone: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_API}/contact/`, state, {
        headers: {},
      })
      .then(() => {
        enqueueSnackbar('Votre message a bien été envoyé', {
          variant: 'success',
        });
        setState(() => ({
          lastname: '',
          firstname: '',
          phone: '',
          email: '',
          message: '',
        }));
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  return (
    <div id="contact">
      <div className="appel">
        <div>
          <p>
            Nous serions également ravis <br />
            si vous préférez nous appeler !
          </p>
          <p className="num">
            Florence et Adrien : <br />
            01 41 14 18 10
          </p>
        </div>
      </div>
      <form onSubmit={(e) => handleSubmit(e)} className="form">
        <h1>Contactez-nous !</h1>
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Votre Nom"
          variant="outlined"
          value={state.lastname}
          name="lastname"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="Votre Prénom"
          variant="outlined"
          value={state.firstname}
          name="firstname"
          onChange={handleChange}
        />
        <TextField
          className="input"
          fullWidth
          required
          color="primary"
          label="Téléphone"
          variant="outlined"
          value={state.phone}
          name="phone"
          onChange={handleChange}
        />
        <TextField
          className="input"
          required
          fullWidth
          color="primary"
          label="E-mail"
          variant="outlined"
          value={state.email}
          name="email"
          onChange={handleChange}
        />
        <TextField
          className="input"
          multiline
          rows={4}
          required
          fullWidth
          color="primary"
          label="Message"
          variant="outlined"
          value={state.message}
          name="message"
          onChange={handleChange}
        />
        <button className="btn-orange" type="submit">
          Envoyer
        </button>
      </form>
    </div>
  );
}
