import React, { useEffect, useState } from 'react';

// # UTILS
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # CUSTOM COMPONENTS
import SuggestionCard from '../Card/SuggestionCard';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  root: {
    height: '65%',
    overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',

    [theme.breakpoints.down('md')]: {
      height: 'unset',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
  },
}));

export default function ProductSuggestion(props) {
  const classes = useStyles();

  const { productId, setDrawer } = props;
  const [state, setState] = useState({
    loading: true,
    productSuggestions: [],
  });

  useEffect(() => {
    // # FETCH SUGGESTION WITH PRODUCT ID
    axiosGetRequest(`/products/suggestions/${productId}`)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          productSuggestions: [...res.data.data.products],
        }));
      })
      .catch((err) => console.log(err));
  }, [productId]);

  return (
    <Grid className={classes.root}>
      {state.loading ? (
        <Skeleton variant="rect" animation="wave" width="100%" height="100px" />
      ) : (
        state.productSuggestions.map((product, idx) => (
          <SuggestionCard key={idx} product={product} setDrawer={setDrawer} />
        ))
      )}
    </Grid>
  );
}
