import React, { useState } from 'react';

// # MATERIAL UI
import { Button, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    marginBottom: '1rem',
    padding: '10px',
    outline: 'none',

    '& > h3': {
      margin: '0 0 1rem 0',
    },

    '& > div': {
      marginBottom: '1rem',
    },
  },
  marginB: {
    marginBottom: '0.5rem',
  },
}));

export default function CreateTag(props) {
  const classes = useStyles();

  const { handleCreateTag } = props;

  const [state, setState] = useState({
    name: '',
  });

  // # HANDLE CHANGE
  const handleChange = (event) => {
    event.persist();

    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // # CREATE TAG
  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateTag(state).then(() => {
      setState((prev) => ({
        ...prev,
        name: '',
      }));
    });
  };

  return (
    <Paper className={classes.paper}>
      <h3>Créer un tag :</h3>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nom"
          name="name"
          variant="outlined"
          value={state.name}
          className={classes.marginB}
          onChange={handleChange}
        />
        <Button fullWidth variant="contained" color="primary" type="submit">
          Créer
        </Button>
      </form>
    </Paper>
  );
}
