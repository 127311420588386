import React, { Fragment, useContext, useState } from 'react';

import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import { useSnackbar } from 'notistack';
import { Button, CircularProgress, Modal, Paper } from '@material-ui/core';

// # UTILS
import { BeginCheckout } from '../../utils/gtmEcommerce';
import { createVadsObject } from '../../utils/lyraActions';
import { axiosPostRequest } from '../../utils/axiosRequests';

// # CONTEXT
import { UserContext } from '../../contexts/UserContext/UserContext';
import { makeStyles } from '@material-ui/core/styles';

// # IMG
import warning from './../../assets/img/picto/warning.png';
import bag from './../../assets/img/picto/zero_sac_2.png';
import currencyFormater from '../../utils/currencyFormater';
import { CartContext } from '../../contexts/CartContext';

moment.locale('fr');

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '50vw',
        '@media screen and (max-width: 600px)': {
            width: '90vw',
            padding: '1rem',
        },
        maxWidth: '600px',
        height: 'fit-content',
        margin: 'auto',
        padding: '2rem',
    },
    payingTitle: {
        fontSize: '1.5rem',
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    marginBottom: {
        marginBottom: 0,
    },
    secondaryColor: {
        color: theme.palette.secondary.main,
    },
    button: {
        borderRadius: '10px',
        fontFamily: 'Bitter',
    },
}));

export default function LyraForm(props) {
    const classes = useStyles();

    const { order, isDisabled, invitation, totalTtc, edenred } = props;

    const { user } = useContext(UserContext);
    const { setProducts, resetDiscount } = useContext(CartContext);

    const [popUp, setPopUp] = useState(false);

    const [paying, setPaying] = useState({
        status: false,
        amount: 2500,
        loading: false,
        order: null,
        partialAmount: 2500,
        diff: false,
        totalLeft: totalTtc,
        error: [],
    });

    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({});

    const handlePop = () => {
        setPopUp(true);
    };

    const handleClose = () => {
        setPopUp(false);
    };

    // TODO : TEMP
    const handlePayment = async () => {
        const requestUrl = `/orders/${invitation ? 'eco' : 'pay'}`;

        const userToken = invitation ? invitation.userToken : user.token;
        const currentUser = invitation && props.user ? props.user : user;

        // INFO : CHECK IF DELIVERY DATE IS IN THE FUTURE
        const { deliveryDate } = order.deliveryInfos;
        const today = moment().format('YYYY-MM-DD');
        if (moment(deliveryDate).isBefore(today) || moment(deliveryDate).isSame(today)) {
            return enqueueSnackbar('La date de livraison est dépassée', {
                variant: 'error',
            });
        }

        // INFO : CHECK IF IT'S AN ORDER WITH INVITATION AND IF THE DELIVERY DATE IS IN THE FUTURE
        if (invitation && !moment().isBefore(invitation?.deliveryInfos?.deliveryDate)) {
            return enqueueSnackbar('La date de cette invitation est passée, vous allez être redirigé', {
                variant: 'error',
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                onExited: () => (window.location = '/'),
            });
        }

        // INFO : CHECK IF ORDER HAS BEEN PATIALY PAID
        if (paying?.order?.status === 'partial' && paying?.order?.partialPayment) {
            return createVadsObject(paying.order, paying.totalLeft * 100, currentUser)
                .then((res) => {
                    setState({ ...res });

                    // * SEND DATA TO GTM
                    BeginCheckout(order.items);
                })
                .then(() => {
                    // * REDIRECT TO SUCCESS PAGE
                    document.getElementById('formLyra').submit();
                });
        }

        axiosPostRequest(requestUrl, userToken, order)
            .then((res) => {
                const { order, totalToPay } = res.data.data;

                createVadsObject(order, totalToPay, currentUser).then((res) => {
                    setState({ ...res });

                    // * SEND DATA TO GTM
                    BeginCheckout(order.items);
                });
            })
            .then(async () => {
                // * REDIRECT TO SUCCESS PAGE
                document.getElementById('formLyra').submit();
            })
            .catch(async (err) => {
                const { appCode } = err.response.data.error;
                if (appCode === 'INVALID_DISCOUNT') {
                    enqueueSnackbar('Ce code promotionnel est invalide', {
                        variant: 'error',
                    });
                }

                if (appCode === 'INVALID_PRODUCTS') {
                    const { datas } = err.response.data.error;
                    await setProducts(datas);
                    enqueueSnackbar(
                        `Nous avons retiré de votre commande les articles qui ne sont plus disponibles à la vente.\nL’offre évolue au fil des saisons`,
                        {
                            variant: 'error',
                        }
                    );
                }

                if (appCode === 'INVALID_TOTAL_PRICE') {
                    const { datas } = err.response.data.error;
                    await setProducts(datas);
                    enqueueSnackbar(
                        `Nous avons mis à jour les articles dont le prix a évolué à la hausse ou à la baisse.\nL’offre évolue au fil des saisons`,
                        {
                            variant: 'error',
                        }
                    );
                }

                return resetDiscount();
            });
    };

    const handleEdenred = () => {
        setPaying((prev) => ({
            ...prev,
            status: true,
            loading: true,
        }));

        const datas = {
            order,
            amount: totalTtc * 100 < 2500 ? totalTtc * 100 : paying.amount,
            totalTtc,
        };

        axiosPostRequest('/orders/edenred-payment', token, datas)
            .then((res) => {
                const { data } = res.data;
                setPaying((prev) => ({
                    ...prev,
                    loading: false,
                    order: data.newOrder,
                    partialAmount: data.partialAmount * 0.01,
                    totalLeft: totalTtc - data.partialAmount * 0.01,
                    diff: datas.amount !== data.partialAmount,
                }));
                if (data.newOrder.status === 'paid') {
                    //redirect success page
                    window.location.href = `https://www.panier-bio-paris.fr/success/${data.newOrder.id}`;
                }
            })
            .catch((err) => {
                const { errors } = err.response.data.error;
                for (const error in errors) {
                    let temp = [...paying.error];
                    temp.push(`${errors[error].message}`);
                    setPaying((prev) => ({
                        ...prev,
                        error: temp,
                    }));
                    enqueueSnackbar(`${errors[error].message}`, {
                        variant: 'error',
                    });
                }

                setPaying((prev) => ({
                    ...prev,
                    loading: false,
                    partialAmount: 0,
                    totalLeft: totalTtc - 0,
                }));
            });
    };

    return (
        <>
            <form id="formLyra" method="POST" action="https://secure.lyra.com/vads-payment/">
                {Object.keys(state).map((key, idx) => (
                    <input key={idx} type="hidden" name={key} value={state[key]} />
                ))}
            </form>

            <Modal style={{ display: 'flex' }} open={popUp} onClose={handleClose}>
                <Paper className={classes.paper}>
                    {paying.status ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <div>
                                <img width={'100px'} src={bag} alt="bag" />
                            </div>
                            <p className={classes.payingTitle}>
                                {paying.loading
                                    ? 'Paiement en cours de validation'
                                    : paying.error.length > 0
                                    ? 'Paiement Refusé'
                                    : 'Paiement Validé'}
                            </p>
                            {paying.loading ? (
                                <p>
                                    Votre paiement par carte restaurant à bien été pris en compte. Veuillez patienter le
                                    temps du traitement
                                </p>
                            ) : (
                                <Fragment>
                                    {paying.error.map((el) => (
                                        <p
                                            style={{
                                                backgroundColor: '#D32F30',
                                                padding: '1rem',
                                                borderRadius: '10px',
                                                color: 'white',
                                            }}
                                        >
                                            {el}
                                        </p>
                                    ))}
                                    {paying.diff && (
                                        <p>
                                            Le montant retenu par Edenred est{' '}
                                            <b>{currencyFormater(paying.partialAmount)}</b>
                                        </p>
                                    )}
                                    <p className={classes.marginBottom}>
                                        Il vous reste encore <b>{currencyFormater(totalTtc - paying.partialAmount)}</b>{' '}
                                        à régler
                                    </p>
                                </Fragment>
                            )}
                            <p className={classes.secondaryColor}>Nous vous redirigeons vers le site de paiement</p>

                            <Button
                                disabled={paying.loading}
                                onClick={handlePayment}
                                variant={'contained'}
                                color={'secondary'}
                                className={classes.button}
                            >
                                {paying.loading ? (
                                    <CircularProgress color={'white'} />
                                ) : (
                                    'Régler le reste de la commande'
                                )}
                            </Button>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img width={'100px'} src={warning} alt="warning" />
                            <p style={{ textAlign: 'center' }}>
                                Attention vous avez autorisé le paiement par carte restaurant souhaitez-vous tout de
                                même continuer ?
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                <Button
                                    className={classes.button}
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={handleClose}
                                >
                                    Non, je ne veux pas
                                </Button>
                                <Button
                                    className={classes.button}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={handleEdenred}
                                >
                                    Oui, continuer
                                </Button>
                            </div>
                        </div>
                    )}
                </Paper>
            </Modal>

            <Button
                disabled={isDisabled}
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={edenred ? handlePop : handlePayment}
            >
                Régler la commande
            </Button>
        </>
    );
}
