import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { makeStyles } from '@material-ui/core';
import DeliveryZoneTable from '../../../components/DeliveryZone/DeliveryZoneTable';
import CreateDeliveryZoneModal from '../../../components/DeliveryZone/CreateDeliveryZoneModal';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function DeliveryZoneList() {
  const classes = useStyles();

  const [state, setState] = useState({
    zones: [],
    loading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const zones = await axios.get(`${process.env.REACT_APP_API}/deliveryZones/`).catch((err) => {
        console.log(err);
      });

      const sortedZones = zones.data.data.deliveryZone.sort(
        (a, b) => -b.name.localeCompare(a.name)
      );
      setState((prev) => ({
        ...prev,
        zones: sortedZones,
        loading: false,
      }));
    };
    fetchData();
  }, []);

  return (
    <>
      <div className={classes.flex}>
        <h2>Tous les zones de livraison</h2>
        <CreateDeliveryZoneModal />
      </div>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <DeliveryZoneTable zones={state.zones} />
      )}
    </>
  );
}
