import React, { useContext } from 'react';

// # CONTEXTS
import { Button, Grid, makeStyles } from '@material-ui/core';
import { CartContext } from '../../../contexts/CartContext';

// # UTILS
import currencyFormater from '../../../utils/currencyFormater';

// # CUSTOM COMPONENTS
import CustomChip from '../../Basics/CustomChip';
import AddButton from '../Card/AddButton';
import RemoveButton from '../Card/RemoveButton';
import GroupedLabels from '../Card/GroupedLabels';
import ProducerList from './ProducerList';

// # MATERIAL UI

const useStyles = makeStyles(() => ({
  marginB: {
    marginBottom: '1rem',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',

    '& > figure': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      overflow: 'hidden',
      maxHeight: '200px',
      margin: 0,

      '& > img': {
        maxHeight: '200px',
        verticalAlign: 'text-bottom',
      },
    },
  },
  infos: {
    '& > h3': {
      fontFamily: 'Bitter',
      fontWeight: 500,
      textAlign: 'center',
    },

    '& > h4': {
      width: 'fit-content',
      fontFamily: 'Bitter',
      fontSize: '1rem',
      fontWeight: 500,
      borderBottom: '2px solid #F05C35',
    },

    '& > p': {
      fontStyle: 'italic',
      color: '#767676',
    },

    '& > button': {
      minHeight: '40px',
    },
  },
  flexGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '0.5rem',
  },
  labels: {
    '& > div': {
      marginBottom: '0.5rem',
    },
  },
  price: {
    margin: 0,
    color: '#F05C35',
    fontFamily: 'Bitter',
    fontWeight: 500,
    fontSize: '1.5rem',
    marginBottom: '0.5rem',
  },
  qtyController: {
    width: '50%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxHeight: '40px',
  },
}));

export default function ColumnLeft(props) {
  const classes = useStyles();

  const { addProduct } = useContext(CartContext);

  const { product, setDrawer, matchingItem, producers } = props;

  // # HANDLE ADD CLICK
  const handleAddProduct = () => {
    addProduct(product);

    if (!matchingItem) {
      setDrawer(true);
    }
  };

  return (
    <Grid
      className={classes.root}
      style={
        product.type === 'product'
          ? { justifyContent: 'flex-end' }
          : { justifyContent: 'space-between' }
      }
      item
      xs={12}
      lg={7}
    >
      <figure>
        {product.cover ? (
          <img src={`${process.env.REACT_APP_AWS}/${product.cover}`} alt={product.name} />
        ) : (
          <img src={`${process.env.REACT_APP_AWS}/${product.photo}`} alt={product.name} />
        )}
      </figure>
      <div className={classes.infos}>
        <h3>{product.name}</h3>
        {product.origin && (
          <p>
            Origine : <span>{product.origin}</span>
          </p>
        )}
        <div dangerouslySetInnerHTML={{ __html: product.description }} />
        <div className={classes.flexGroup}>
          <div>
            <div className={classes.marginB}>
              {product.type === 'basket' && (
                <>
                  <CustomChip
                    color="secondary"
                    label={`${product.weight.from} à ${product.weight.to} ${product.weight.unity.abbreviation}`}
                  />
                  <CustomChip
                    color="secondary"
                    label={`${product.persons.from} à ${product.persons.to} pers`}
                  />
                </>
              )}

              {product.type === 'product' && (
                <CustomChip
                  color="secondary"
                  label={`${product.weight.quantity} ${product.weight.unity.abbreviation}`}
                />
              )}
            </div>
            <div className={classes.labels}>
              <GroupedLabels labels={product.labels} limit={4} />
            </div>
          </div>
          <p className={classes.price}>{currencyFormater(product.price)}</p>
        </div>

        {matchingItem ? (
          <div className={classes.qtyController}>
            <RemoveButton product={product} matchingItem={matchingItem} />
            <p>{matchingItem?.quantity || 0}</p>
            <AddButton matchingItem={matchingItem} setDrawer={setDrawer} product={product} />
          </div>
        ) : (
          <Button
            fullWidth
            onClick={handleAddProduct}
            disabled={product.stock <= 0}
            variant="contained"
            color="secondary"
          >
            Ajouter au panier
          </Button>
        )}

        {product.type === 'basket' && (
          <ProducerList title="Produits cultivés par :" producers={producers} />
        )}
      </div>
    </Grid>
  );
}
