const invitationStorage = (invitInfos) => {
  localStorage.setItem('invitation', JSON.stringify(invitInfos));
};

export const setInvitInfos = (prev, infos) => {
  invitationStorage(infos);
  return infos;
};

export const InvitationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INVIT':
      return { invitation: { ...setInvitInfos(state, action.payload) } };

    default:
      return state;
  }
};
