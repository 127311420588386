import React, {Fragment, useEffect, useState} from 'react';
import {axiosDeleteRequest, axiosGetRequest, axiosPostRequest} from "../../../utils/axiosRequests";
import Cookies from 'js-cookie';

// MATERIAL UI
import { Paper, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell, Chip, Grid, Link, Modal } from '@material-ui/core'
import {makeStyles} from "@material-ui/core";
import {Delete, Create} from '@material-ui/icons';

//STYLE
const useStyle = makeStyles({
    paper: {
        padding: '1rem',
        marginBottom: '1rem',
    },
    paperModal: {
        padding: '1rem',
        maxWidth: '700px',
        margin: '20% auto 0 auto'
    },
    h2: {
        marginTop: 0,
    },
    ul: {
        listStyleType: 'none',
        paddingInlineStart: 0,
    },
    mails: {
        padding: '1rem 0'
    },
    createMails: {
        paddingTop: '1rem'
    }
});

export default function deliveryServicesList() {
    const jwt = Cookies.get('jwt');
    const classes = useStyle();
    const [state, setState] = useState({
        newDeliveryService: {
            mail: '',
            name: '',
            endTime: '',
            mails: [],
        },
        updateDeliveryService: {
            mail: '',
            name: '',
            endTime: '',
            mails: [],
        },
        deliveryServices: [],
        open: false,
    })

    useEffect(() => {
        axiosGetRequest('/deliveryServices', jwt).then((res) => setState((prev) => ({
            ...prev,
            deliveryServices: res.data.data.deliveryServices
        })))
    }, []);

    const handleOpenModal = (idx) => {
        setState((prev) => ({
            ...prev,
            open: true,
            updateDeliveryService: {...state.deliveryServices[idx]}
        }))
    }

    const handleCreate = async() => {
        axiosPostRequest('/deliveryServices', jwt, state.newDeliveryService).then(res => setState((prev) => ({
            ...prev,
            deliveryServices: [ ...prev.deliveryServices, res.data.data.deliveryService ],
            newDeliveryService: {
                mail: '',
                name: '',
                endTime: '',
                mails: [],
            }
        })));
    }

    const handleUpdate = async(id) => {
        axiosPostRequest(`/deliveryServices/${id}`, jwt, {...state.updateDeliveryService})
            .then((res) =>{
            const {deliveryService} = res.data.data;
            const filter = state.deliveryServices.filter((deliveryService) => deliveryService.id !== id);
            setState((prev) => ({
                ...prev,
                deliveryServices: [deliveryService, ...filter],
                open: false,
            }))
        })
            .catch(err => console.log(err))
    }

    const handleDelete = (id) => {
        axiosDeleteRequest(`/deliveryServices/${id}`, jwt).then(res => {
            const {deliveryServices} = res.data.data;
            setState(prev => ({
                ...prev,
                deliveryServices
            }))
        })
            .catch((err) => console.log(err))
    }

    const handleChange = (e, type) => {
        e.persist();
        if(type ===  'update'){
            setState((prev) => ({...prev, updateDeliveryService: {...prev.updateDeliveryService, [e.target.name]: e.target.value}}))
        } else {
            setState((prev) => ({...prev, newDeliveryService: {...prev.newDeliveryService, [e.target.name]: e.target.value}}));
        }
    }

    const handleClose = () => {
        setState((prev) => ({...prev, open: false}))
    }



    const Row = (props) => {
        const {data, idx} = props;

        return (
            <TableRow>
                <TableCell>
                    <Link href={`/admin/deliveryServices/${data.id}`}>{data.name}</Link>
                </TableCell>
                <TableCell>
                    {data.endTime}
                </TableCell>
                <TableCell>
                    <ul className={classes.ul}>
                        {data.mails.map((mail) => (
                            <li>
                                {mail}
                            </li>
                        ))}
                    </ul>

                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenModal(idx)}><Create/></Button>
                    <Button onClick={() => handleDelete(data.id)}><Delete/></Button>
                </TableCell>
            </TableRow>
        )
    }

    const handleKeyDown = (e, type) => {
        if(type === 'new') {
            if (e.keyCode === 13 && state.newDeliveryService.mail !== "") {
                setState((prev) => ({
                    ...prev,
                    newDeliveryService: {
                        ...prev.newDeliveryService,
                        mail: "",
                        mails: [...prev.newDeliveryService.mails, state.newDeliveryService.mail]
                    }
                }))
            }
        } else {
            if (e.keyCode === 13 && state.updateDeliveryService.mail !== "") {
                setState((prev) => ({
                    ...prev,
                    updateDeliveryService: {
                        ...prev.updateDeliveryService,
                        mail: "",
                        mails: [...prev.updateDeliveryService.mails, state.updateDeliveryService.mail]
                    }
                }))
            }
        }
    };

    const handleDeleteMail = (id, type) => {
        if(type === 'update') {
            const filter = state.updateDeliveryService.mails.filter((mail, idx) => idx !== id);
            setState((prev) => ({
                ...prev,
                updateDeliveryService: {
                    ...prev.updateDeliveryService,
                    mails: filter,
                }
            }))
        }else {
            const filter = state.newDeliveryService.mails.filter((mail, idx) => idx !== id);
            setState((prev) => ({
                ...prev,
                newDeliveryService: {
                    ...prev.newDeliveryService,
                    mails: filter,
                }
            }))
        }

    }

    return (
        <Fragment>
            <h1>Delivery Services</h1>

            <Modal
                open={state.open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                    <Paper className={classes.paperModal}>
                        <h2 style={{marginTop: 0}}>Modifier</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField variant={'outlined'} fullWidth type={'text'} label={'Nom'} name={'name'} defaultValue={state?.updateDeliveryService.name} onChange={(e)=>handleChange(e, 'update')}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField variant={'outlined'} fullWidth type={'time'} label={'Heure de fin'} name={'endTime'} defaultValue={state?.updateDeliveryService.endTime} onChange={(e)=>handleChange(e, 'update')}/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField variant={'outlined'} fullWidth type={"text"} label={'Adresse mail'} name={'mail'} value={state?.updateDeliveryService.mail} onChange={(e)=>handleChange(e, 'update')} onKeyDown={(e) => handleKeyDown(e, 'update')}/>
                            </Grid>
                        </Grid>
                        <div className={classes.mails}>
                            {state?.updateDeliveryService.mails.map((mail, idx) => (
                                <Chip label={mail} onDelete={() => handleDeleteMail(idx, 'update')}/>
                            ))}
                        </div>
                        <Button variant={'contained'} fullWidth color={'primary'} onClick={() => handleUpdate(state?.updateDeliveryService.id)}>Valider</Button>
                    </Paper>
            </Modal>

            <Paper className={classes.paper}>
                <h2 className={classes.h2}>Fields</h2>
                <Grid container spacing={3} alignItems={'center'}>
                    <Grid item xs={4}>
                        <TextField fullWidth size={'small'} variant={'outlined'} type={'text'} name={'name'} value={state.newDeliveryService.name} onChange={(e) => handleChange(e, 'new')}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth size={'small'} variant={'outlined'} value={state.newDeliveryService.endTime} type={'time'} name={'endTime'} onChange={(e) => handleChange(e, 'new')}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth size={'small'} variant={'outlined'} value={state.newDeliveryService.mail} type={'text'} name={'mail'} onChange={(e) => handleChange(e, 'new')} onKeyDown={(e) => handleKeyDown(e, 'new')}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant={'contained'} color={"primary"} fullWidth size={'small'} onClick={handleCreate}>Valider</Button>
                    </Grid>
                </Grid>
                <div className={classes.createMails}>
                    {state.newDeliveryService.mails.map((mail, idx) => (
                        <Chip label={mail} onDelete={() => handleDeleteMail(idx)}/>
                    ))}
                </div>
            </Paper>

            <Paper className={classes.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Heur de fin</TableCell>
                            <TableCell>Mails de contact</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.deliveryServices.map((delivery, idx) => (
                            <Row data={delivery} idx={idx}/>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Fragment>
    );
}