import React, { Fragment } from 'react';

// # MATERIAL UI
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
}));

export default function ProductFilters(props) {
  const classes = useStyles();

  const { filters, categories, subCategories, handleFilters } = props;

  const handleChange = (key, value) => {
    if (key === 'category' || key === 'subCategory') {
      if (value === null) return handleFilters(key, 'all');
      return handleFilters(key, value.id);
    }

    return handleFilters(key, value);
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <FormControl style={{ minWidth: '100px' }} variant="outlined">
            <InputLabel>Statut</InputLabel>
            <Select
              label="Statut"
              value={filters.status}
              onChange={(event) => handleChange('status', event.target.value)}
            >
              <MenuItem value="all">Tout</MenuItem>
              <MenuItem value={false}>Inactif</MenuItem>
              <MenuItem value>Actif</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {categories && (
          <Grid item>
            <Autocomplete
              value={
                filters.category !== 'all'
                  ? categories.find((cat) => cat.id === filters.category)
                  : null
              }
              onChange={(event, value) => handleChange('category', value)}
              options={categories.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
              groupBy={(option) => option.firstLetter}
              autoHighlight
              getOptionLabel={(option) => `${option.name}`}
              renderOption={(option) => <>{option.name}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rubrique"
                  variant="outlined"
                  style={{
                    minWidth: '200px',
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item>
          <Autocomplete
            value={
              filters.subCategory !== 'all'
                ? subCategories.find((subcat) => subcat.id === filters.subCategory)
                : null
            }
            onChange={(event, value) => handleChange('subCategory', value)}
            options={subCategories.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            autoHighlight
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(option) => <>{option.name}</>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sous-rubrique"
                variant="outlined"
                style={{
                  minWidth: '200px',
                }}
              />
            )}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Chercher un produit..."
            onChange={(event) => handleChange('search', event.target.value)}
            value={filters.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
