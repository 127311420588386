import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Meta from '../../../utils/metaDescription';

// MATERIAL.UI

// IMAGES//
import img1 from '../../../assets/img/corbeille-1.png';
import img2 from '../../../assets/img/backgrounds/fraise.png';
import img3 from '../../../assets/img/corbeille-3.png';
import img4 from '../../../assets/img/corbeille-4.png';

// STYLE//
import '../../../assets/css/stylecorbeille.css';

export default function CorbeilleFruit() {
  const discover = () => {
    window.location = 'catalogue/les-paniers-bio';
  };
  const contact = () => {
    window.location = '/contact-entreprise';
  };

  return (
    <>
      <Meta page="corbeille" />

      <div id="corbeillefruit">
        <div className="container">
          <h1>Corbeilles de fruits en entreprise</h1>
          <p>Faites profiter vos salariés de fruits frais et 100% biologique</p>
          <div className="row">
            <img src={img1} alt="corbeille" />
            <div>
              <p>Livraison : Ponctuelle ou occasionnelle</p>
              <p>Besoin : 1 à 3 corbeilles de fruits (par livraison)</p>
              <p>
                <span>étape 1/</span>Je choisis mon panier dans l'onglet Corbeille
              </p>
            </div>
          </div>

          <div className="list">
            <img src={img2} alt="fraise" />
            <div>
              <p>
                {' '}
                <span>étape 2/</span>Je choisis le jour et l'heure de livraison dans l'onglet
                Livraison en sélectionnant l'option livraison à domicile ou en entreprise
              </p>
              <p>
                {' '}
                <span>étape 3/</span>Je crée mon compte
                <br />
                (les informations enregistrées apparaitront sur votre facture, pensez donc à y
                indiquer les coordonnées de votre entreprise)
              </p>
              <p>
                {' '}
                <span>étape 4/</span>Je paie en carte bleue : vous recevrez directement votre
                facture par mail.
              </p>
            </div>
          </div>

          <Button className="btn-orange" onClick={discover}>
            Je découvre
          </Button>

          <div className="row">
            <img src={img3} alt="corbeille" />
            <div>
              <p>Livraison : Hebdomadaire de +3 corbeilles</p>
              <p>Besoin : + 3 corbeilles de fruits (par livraison)</p>
            </div>
          </div>

          <div className="list">
            <img src={img4} alt="fraise" />
            <div>
              <p>Nous vous proposons une formule d'abonnement modulable et à la carte !</p>
              <p>
                {' '}
                <span>étape 1/</span>Je choisis avec Bio Culture mon panier et la quantité adaptée à
                mon besoin
              </p>
              <p>
                {' '}
                <span>étape 2/</span>Je détermine un jour et une heure de livraison
              </p>
              <p>
                {' '}
                <span>étape 3/</span>J'écris à Bio Culture 72 heures à l'avance en cas de changement
                ou d'interruption.
              </p>
              <p>
                {' '}
                <span>étape 4/</span>Bio Culture m'envoie une facture unique en fin de mois. Je
                règle par chèque ou virement avant le 15 du mois suivant.
              </p>
            </div>
          </div>

          <Button className="btn-orange" onClick={contact}>
            Contactez-nous
          </Button>
          <p>Et recevez une offre personnalisée</p>
        </div>
      </div>
    </>
  );
}
