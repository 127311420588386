import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Cookies from 'js-cookie';
import axios from 'axios';

// # MATERIAL UI
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

// # UTILS
import { axiosGetRequest, axiosDeleteRequest } from '../../../utils/axiosRequests';

// # COMPONENTS
import DeliveryCityInfos from '../../../components/DeliveryCity/DeliveryCityInfos';

const useStyles = makeStyles((theme) => ({
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '500px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

export default function DeliveryCityDetails() {
  const { id } = useParams();
  const classes = useStyles();
  const jwt = Cookies.get('jwt');

  const [state, setState] = useState({
    isLoading: true,
    deliveryCity: {},
    zones: []
  });

  useEffect(() => {
    Promise.all([
      axiosGetRequest(`/deliveryCities/${id}`),
      axiosGetRequest('/deliveryZones'),
      axiosGetRequest('/deliveryServices', jwt)
    ])
      .then((res) => {
        setState((prev) => ({
          ...prev,
          deliveryCity: { ...res[0].data.data.deliveryCity },
          zones: [...res[1].data.data.deliveryZone],
          deliveryServices: [...res[2].data.data.deliveryServices],
        }));
      })
      .finally(() => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      })
  }, [id]);

  const handleDelete = () => {
    axiosDeleteRequest(`/deliveryCities/${id}`, jwt)
      .then(() => {
        window.location.href = '/admin/deliveryCities';
      })
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {state.isLoading ? (
          <Skeleton animation="wave" width={250} height={50} />
        ) : (
          <div className={classes.flexTop}>
            <h1>{state.deliveryCity.name}</h1>
            <div className={classes.margin}>
              <div>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                  Supprimer la ville
                </Button>
              </div>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        {state.isLoading ? (
          <Skeleton animation="wave" width={500} height={300} />
        ) : (
          <DeliveryCityInfos services={state.deliveryServices} city={state.deliveryCity} zones={state.zones} />
        )}
      </Grid>
    </Grid>
  );
}
