import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import DeliveryZoneInfos from '../../../components/DeliveryZone/DeliveryZoneInfos';

const useStyles = makeStyles((theme) => ({
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '316px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

export default function DeliveryZoneDetails() {
  const [data, setData] = useState({});
  const [cities, setCities] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const zone = await axios.get(`${process.env.REACT_APP_API}/deliveryZones/${id}`);

      const cities = await axios.get(
        `${process.env.REACT_APP_API}/deliveryCities/?deliveryZone=${id}`
      );

      setData(zone.data.data);
      setCities(cities.data.data.deliveryCity);
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton animation="wave" width={250} height={50} />
        ) : (
          <div className={classes.flexTop}>
            <h1>{data.deliveryZone.name}</h1>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton animation="wave" width={500} height={300} />
        ) : (
          <DeliveryZoneInfos zone={data} cities={cities} id={id} />
        )}
      </Grid>
    </Grid>
  );
}
