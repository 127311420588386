import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  TableCell,
  withStyles,
} from '@material-ui/core';
import LyraForm from '../../../components/Basics/LyraForm';

//IMG
import cards from './../../../assets/img/picto/cards.png';
import { CartContext } from '../../../contexts/CartContext';
import { axiosGetRequest } from '../../../utils/axiosRequests';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  summary: {
    padding: '2rem 3rem',
    '@media screen and (max-width: 600px)': {
      padding: '1rem',
    },
    marginTop: theme.spacing(3),
    textAlign: 'left',
    color: '#7c7c7c',
    borderRadius: '10px',
    boxShadow: '2px 0px 20px 0px #0000000d',

    '& > hr': {
      margin: '2rem',
    },
  },
  img: {
    '@media screen and (max-width: 600px)': {
      width: '100%',
    },
    width: '500px',
  },
  title: {
    fontFamily: 'Bitter',
    fontSize: '2.5rem',
    fontWeight: '400',
    textAlign: 'center',
    margin: 0,
    color: theme.palette.secondary.main,
  },
  flex: {
    display: 'flex',
  },
  remove: {
    backgroundColor: '#FFF2E7',
    color: '#F05C35',
    borderRadius: '10px',
  },
}));

const StyledTableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '4px',
  },
})(TableCell);

export default function Payment(props) {
  const classes = useStyles();

  const { cartItems, deliveryInfos, discount, total } = useContext(CartContext);

  const [totalTtc, setTotalTtc] = useState(total);
  const [deliveryCost, setDeliveryCost] = useState(0);

  useEffect(() => {
    if (deliveryInfos.deliveryType === 'relayPoint') {
      if (discount && discount.value && discount.reduction) {
        if (discount.reduction === 'percent') {
          setTotalTtc(
            Math.round((Number(total) * 1 - total * 1 * (discount.value / 100)) * 100) / 100
          );
        } else {
          setTotalTtc(Math.round((Number(total) * 1 - discount.value) * 100) / 100);
        }
        console.log('total1', totalTtc);
      } else {
        setTotalTtc(total);
        console.log('total2', totalTtc);
      }
      return;
    }

    axiosGetRequest(`/deliveryCities/?zipCode=${deliveryInfos.deliveryAddress.zipCode}`).then(
      (res) => {
        if (res.data.data.deliveryCity[0]) {
          const zoneDeliveryCost = res.data.data.deliveryCity[0].deliveryZone.charges
            .filter((charge) => total >= charge.threshold)
            .pop().costsTTC;

          if (!discount) {
            if (zoneDeliveryCost) {
              setDeliveryCost(zoneDeliveryCost);
              setTotalTtc(Math.round((Number(total) + zoneDeliveryCost) * 1 * 100) / 100);
            } else {
              setTotalTtc(Math.round(Number(total) * 1 * 100) / 100);
            }
          }

          if (discount && discount.value && discount.reduction) {
            if (discount.reduction === 'percent') {
              if (zoneDeliveryCost) {
                setDeliveryCost(zoneDeliveryCost);
                setTotalTtc(
                  Math.round(
                    ((Number(total) + zoneDeliveryCost) * 1 - total * 1 * (discount.value / 100)) *
                      100
                  ) / 100
                );
              } else {
                setTotalTtc(
                  Math.round((Number(total) * 1 - total * 1 * (discount.value / 100)) * 100) / 100
                );
              }
            } else {
              if (zoneDeliveryCost) {
                setDeliveryCost(zoneDeliveryCost);
                setTotalTtc(
                  Math.round(((Number(total) + zoneDeliveryCost) * 1 - discount.value) * 100) / 100
                );
              } else {
                setTotalTtc(Math.round((Number(total) * 1 - discount.value) * 100) / 100);
              }
            }
          }
        }
      }
    );
  }, [discount, cartItems]);

  const [condition, setCondition] = useState(false);
  const [information, setInformation] = useState(false);

  return (
    <React.Fragment>
      <Paper className={classes.summary}>
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={information}
                  onChange={() => setInformation((prev) => !prev)}
                  name="condition"
                />
              }
              label={
                <p className={classes.cgv}>
                  En cochant cette case, je reconnais avoir pris connaissance des{' '}
                  <a href="/mentions-legales"> Conditions Générales de Vente Bio Culture </a>
                  et je les accepte.
                </p>
              }
            />
          </Grid>
          {deliveryInfos.deliveryType === 'home' && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={condition}
                    onChange={() => setCondition((prev) => !prev)}
                    name="informations"
                  />
                }
                label={
                  <p style={{ textAlign: 'left' }}>
                    J&apos;ai renseigné des{' '}
                    <span style={{ color: '#8bc34a' }}> informations de livraison à jour </span> :
                    Codes, étages, numéro de téléphone joignable <br />
                    <span style={{ fontSize: '0.7rem' }}>
                      (Si une de ces informations est manquante, malgré notre meilleure volonté, la
                      livraison pourrait s&apos;avèrer impossible. Cela entrainerait un deuxième
                      passage au plein tarif, à votre charge).
                    </span>
                  </p>
                }
              />
            </Grid>
          )}
        </Grid>
      </Paper>

      <Alert style={{ marginTop: '2rem' }} severity="info">
        Un problème pour régler votre commande ? Pensez à vérifier que vous êtes bien connecté à
        votre compte client.
      </Alert>

      <Paper className={classes.summary}>
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item xs={12}>
            <p>Payez avec :</p>
            <div style={{ margin: '0 auto', width: 'fit-content' }}>
              <img className={classes.img} src={cards} alt="cards" />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.flex} style={{ justifyContent: 'center' }}>
            <LyraForm
              edenred={false}
              totalTtc={totalTtc}
              order={{
                items: cartItems,
                deliveryInfos,
                deliveryTaxe: deliveryCost,
                discount: discount,
              }}
              isDisabled={
                deliveryInfos.deliveryType === 'home' ? !(condition && information) : !information
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
