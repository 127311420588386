import React, { useState, useEffect } from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

// # DRAFT JS
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

// # MATERIAL UI
import { Input, makeStyles, TextField } from '@material-ui/core';
import { Paper, Grid, Button } from '@material-ui/core';

// # UTILS
import { Autocomplete } from '@material-ui/lab';
import { axiosGetRequest, axiosPostRequest } from '../../utils/axiosRequests';
import { autocompleteGroupedFormater } from '../../utils/stringFormater';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
  },
  flexImg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',

    '& > figure > img': {
      width: '200px',
    },
  },
}));

export default function AddProducer(props) {
  moment.locale('fr');
  const classes = useStyles();
  const token = Cookies.get('jwt');

  const { addNewProducerToList, tags, producers } = props;

  const initialState = {
    firstname: '',
    lastname: '',
    position: producers.length + 1,
    location: '',
    sinceWhen: moment().format('YYYY-MM-DD'),
    sinceWhenBio: moment().format('YYYY-MM-DD'),
    distinctiveSign: '',
    production: '',
    tags: [],
    description: EditorState.createEmpty(),
    photo: undefined,
  };

  const [state, setState] = useState({
    addProducer: { ...initialState },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setState((prev) => ({
      ...prev,
      addProducer: {
        ...prev.addProducer,
        photo: file,
      },
    }));
  };

  const handleChange = (e) => {
    e.persist();

    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      addProducer: {
        ...prev.addProducer,
        [name]: value,
      },
    }));
  };

  // # HANDLE AUTOCOMPLETE CHANGES
  const handleAutocompleteMultiple = (value) => {
    setState((prev) => ({
      ...prev,
      addProducer: {
        ...prev.addProducer,
        tags: value,
      },
    }));
  };

  const onEditorStateChange = (editorState) => {
    setState((prev) => ({
      ...prev,
      addProducer: {
        ...prev.addProducer,
        description: editorState,
      },
    }));
  };

  const handleCreateProducer = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('firstname', state.addProducer.firstname);
    formData.append('lastname', state.addProducer.lastname);
    formData.append('position', state.addProducer.position);
    formData.append('location', state.addProducer.location);
    formData.append('sinceWhen', state.addProducer.sinceWhen);
    formData.append('sinceWhenBio', state.addProducer.sinceWhenBio);
    formData.append('distinctiveSign', state.addProducer.distinctiveSign);
    formData.append('production', state.addProducer.production);
    formData.append('tags', JSON.stringify(state.addProducer.tags));
    formData.append(
      'description',
      draftToHtml(convertToRaw(state.addProducer.description.getCurrentContent()))
    );
    formData.append('photo', state.addProducer.photo);

    axiosPostRequest(`/producers/`, token, formData)
      .then((res) => {
        const { producer } = res.data.data;
        setState((prev) => ({
          ...prev,
          addProducer: { ...initialState, position: prev.addProducer.position + 1 },
        }));

        // # REPLACE TAGS ID WITH OBJECT
        const { tags } = producer;
        producer.tags = tags.map((tag) => tags.find((t) => t.id === tag));

        // # UPDATE PRODUCERS ARR IN PARENT COMPONENT
        addNewProducerToList(producer);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.flexImg}>
              <figure>
                {state.addProducer.photo ? (
                  <img src={URL.createObjectURL(state.addProducer.photo)} alt="upload" />
                ) : (
                  <img src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`} alt="missing" />
                )}
              </figure>
              <Input type="file" onChange={handleFileChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="firstname"
                label="Prénom"
                type="text"
                variant="outlined"
                value={state.addProducer.firstname}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="lastname"
                label="Nom"
                type="text"
                variant="outlined"
                value={state.addProducer.lastname}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="position"
                label="Position"
                type="number"
                variant="outlined"
                value={state.addProducer.position}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="location"
                label="Lieu de production"
                type="text"
                variant="outlined"
                value={state.addProducer.location}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                name="sinceWhen"
                label="Début de collaboration"
                type="date"
                variant="outlined"
                value={state.addProducer.sinceWhen}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="sinceWhenBio"
                label="Début de production Bio"
                type="date"
                variant="outlined"
                value={state.addProducer.sinceWhenBio}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="distinctiveSign"
                label="Signe distinctif"
                type="text"
                variant="outlined"
                value={state.addProducer.distinctiveSign}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="production"
                label="Production"
                type="text"
                variant="outlined"
                value={state.addProducer.production}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                value={state.addProducer.tags}
                onChange={(event, value) => handleAutocompleteMultiple(value)}
                options={tags.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.slug}`}
                renderOption={(option) => <>{option.name.split('Tags.')[1]}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    variant="outlined"
                    style={{
                      width: '100%',
                      marginBottom: '1rem',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <h3>Description :</h3>
                <Editor
                  editorState={state.addProducer.description}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  name="description"
                  toolbar={{
                    options: ['inline', 'colorPicker', 'link'],
                    inline: {
                      options: ['bold', 'italic'],
                    },
                  }}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    minHeight: '150px',
                    padding: '0 1rem',
                    marginBottom: '1rem',
                  }}
                  onEditorStateChange={onEditorStateChange}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary" onClick={handleCreateProducer}>
                Valider
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
