import React, { useEffect, useState } from 'react';

// # COMPONENTS
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ReceiptCard from './ReceiptCard';

// # UTILS
import { retrieveEntriesByTags } from '../../../utils/contentfulActions';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function ReceiptSuggestion(props) {
  const classes = useStyles();

  const { tags } = props;

  const [state, setState] = useState({
    loading: true,
    receipt: [],
  });

  useEffect(() => {
    // # RETRIEVE RECEIPTS BY TAGS
    retrieveEntriesByTags(tags).then((res) => {
      setState({ loading: false, receipt: [...res] });
    });
  }, [tags]);

  return (
    <Grid className={classes.root}>
      {state.loading ? (
        <Skeleton variant="rect" animation="wave" width="100%" height="100px" />
      ) : (
        state.receipt.length > 0 && <ReceiptCard receipt={state.receipt[0]} />
      )}
    </Grid>
  );
}
