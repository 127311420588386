import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta(props) {
  const { page } = props;

  const seo = {
    homepage: {
      title: 'Bio Culture – Livraison paniers de fruits et légumes 100% Bio en IDF',
      description:
        "Bio Culture prépare et livre des paniers de fruits et légumes 100% Bio et locaux sur Paris et l'Ile de France. Livraison à domicile et en point relais.",
    },
    lespaniers: {
      title: 'Des paniers de fruits et légumes 100% bio composés et livrés par Bio Culture',
      description:
        'Chaque semaine des paniers de fruits et légumes 100% Bio, locaux et de saison en direct des producteurs. Livraison en Ile-de-France, à domicile et en point relais.',
    },
    alacarte: {
      title: 'Fruits et légumes biologiques - Bio Culture',
      description:
        'Une sélection de fruits et légumes 100% Biologiques Composez vous-même vos paniers bio. Livraison à domicile et en point relais. Paris et Ile-de-France.',
    },
    fruitslegumes: {
      title: 'Fruits et légumes secs biologiques  - Bio Culture',
      description:
        'Achetez des fruits et légumes secs bio et locaux pour des encas sains et équilibrés ! Lentilles, haricots, pois chiches, quinoa, amandes, dattes, noix de cajou, pistaches.',
    },
    commande: {
      title: 'Découvrez les paniers de fruits et légumes biologiques - Bio Culture',
      description:
        "Régalez-vous avec des paniers de fruits et/ou légumes 100% Bio, préparés chaque matin. Paniers locavores, 100% français ou diversifiés, c'est vous qui choisissez.",
    },
    valeurs: {
      title: 'Nos valeurs - Bio Culture',
      description:
        "Bio Culture est une entreprise collaborative de l'économie sociale et solidaire. L'écologie, le respect de la terre et le maintien d'une agriculture durable sont au coeur de nos valeurs.",
    },
    producteurs: {
      title: 'Nos producteurs bio - Bio Culture',
      description:
        'Bio Culture travaille avec un réseau de plus de 30 agriculteurs bio en Ile-de-France. Tous nos producteurs sont contrôlés et certifiés agriculture biologique.',
    },
    produits: {
      title: 'Nos produits bio - Bio Culture',
      description:
        "Tous nos produits sont issus de l'agriculture biologique. Sélectionnés chaque matin pour un maximum de fraicheur et de qualité.",
    },
    recettes: {
      title: 'Le recettes de paniersBio - Bio Cuture',
      description:
        'Chaque semaine, des idées de recettes saines et équilibrés pour cuisiner les fruits et de légumes de vos paniers bio. Des recettes rapides, faciles et savoureuses.',
    },
    dechet: {
      title: 'Panier bio Zéro déchet - Bio Culture',
      description:
        "Un panier Bio, c'est ZERO déchet. 100% de nos emballages sont réutilisables ou renouvelables ! L'écologie et le respect de la terre sont au cœur de la démarche Bio Culture.",
    },
    entreprises: {
      title: 'Livraison de panier et corbeille bio en entreprise - Bio Culture',
      description:
        'Offrez un service de qualité à vos salariés avec la livraison de paniers bio et corbeilles de fruits au bureau. Paris et Ile-de-France.',
    },
    corbeille: {
      title: 'Livraison de corbeille de fruits bio en entreprise - Bio Culture',
      description:
        'Faites profiter vos salariés de corbeille de fruits frais et 100% bio. Livraison au bureau, en Ile-de-France. Livraison ponctuelle, occasionnelle, hebdomadaire.',
    },
    commandgroup: {
      title: 'Commande groupée de paniers de fruits et légumes bio - Bio Culture',
      description:
        'Créez des commandes groupées de paniers Bio entre collègues, voisins, amis... Bio Culture vous aide à tout organiser pour simplifier la vie de votre petite communauté.',
    },
    ecopoint: {
      title: 'Des eco-points pour les eco-acteurs - Bio Culture',
      description:
        'Organisez des commandes groupées de paniers Bio entre  collègues, voisins, amis... et gagnez des éco-points',
    },
    ecoacteurs: {
      title: 'Devenez éco-acteur - Bio Culture ',
      description:
        'Je deviens éco-acteur en organisant des commandes groupées de paniers de fruits et légumes bio avec mes collègues, amis, voisins... ',
    },
    moncompte: {
      title: 'Connectez-vous - Bio Culture',
      description:
        'Connectez-vous sur Bio Culture pour gérér votre compte et accéder à vos commandes, vos bons de réduction et vos livraisons',
    },
    agricole: {
      title: 'Rejoindre la coopérative agricole - Bio Culture',
      description:
        'Dévenez Coopérateur Bio Culture ? Construisez et investissez avec nous dans un espace agricole et éducatif en Ile de France ',
    },
  };

  return (
    <Helmet>
      <title>{seo[page].title}</title>
      <meta name="description" content={seo[page].description} />
    </Helmet>
  );
}
