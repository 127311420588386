import React from 'react';

// # MATERIAL UI
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// # IMAGES
import img1 from '../../assets/img/offre.png';
import img2 from '../../assets/img/fleche.png';
import img3 from '../../assets/img/imageOffre.png';
import img4 from '../../assets/img/pictoOffre.png';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>div': {
      width: 'fit-content',
    },
    '&>div:nth-child(1)': {
      marginRight: '2rem',
    },
    '@media (max-width:960px)': {
      flexWrap: 'wrap-reverse',
      '&>div:nth-child(1)': {
        marginRight: '0',
      },
    },
  },
  flex2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>div': {
      width: 'fit-content',
    },
  },
  paragraphe: {
    fontSize: '1.1rem',
  },
  stronger: {
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  strongerLittle: {
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    color: '#e1523d',
  },
  img: {
    marginLeft: '-3rem',
    marginTop: '-8rem',
  },
  imageOffre: {
    transform: 'rotate(351deg)',
    maxHeight: '300px',
    width: '90%',
  },
  imagePoireau: {
    transform: 'rotate(351deg)',
    maxHeight: '700px',
    width: '90%',
  },
  picto: {
    margin: '6rem 0',
    '&>img': {
      width: '90%',
    },
  },
  container: {
    margin: '0 auto',
    maxWidth: '1227px',
  },
  bigger: {
    fontSize: '1.5rem',
  },
}));

export default function Offre(props) {
  const classes = useStyles();
  const { code } = props;

  return (
    <div className={classes.container}>
      <div>
        <img src={img1} alt="offre" width="100%" />
      </div>

      <div className={classes.flex}>
        <div>
          <img className={classes.imageOffre} src={img3} alt="fleche" />
        </div>

        <div className={classes.flex2}>
          <div>
            <p className={classes.paragraphe}>
              Découvrez les paniers <br />
              <span className={classes.title}>Bio Culture</span> <br />
              <span className={classes.stronger}> 7 €</span> offerts <br />
              avec le code <span className={classes.stronger}>{code}</span> <br />à partir de{' '}
              <span className={classes.stronger}>
                20 € d&apos;achat <br /> seulement !
              </span>
            </p>

            <p className={classes.paragraphe}>
              Valable sur votre première commande. <br />
              Offre sans abonnement ni engagement de durée. <br /> Livraison gratuite à domicile dès
              35 € d&apos;achat.
            </p>
            <Button
              className="btn-orange"
              href="/catalogue/les-paniers-bio"
              style={{ padding: '0.5rem 2rem' }}
            >
              {' '}
              Découvrir les paniers <br /> Bio et Locaux
            </Button>
          </div>
          <img className={classes.img} src={img2} alt="fleche" height="150pxs" />
        </div>
      </div>
      <div className={classes.picto}>
        <img src={img4} alt="pictoOffre" />
      </div>
    </div>
  );
}
