import React, { useState, useEffect } from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

// # DRAFT JS
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

// # MATERIAL UI
import { useSnackbar } from 'notistack';
import { Input, makeStyles, TextField, Paper, Grid, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// # UTILS
import { axiosPatchRequest } from '../../utils/axiosRequests';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '1rem',
  },
  flexImg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',

    '& > figure > img': {
      width: '200px',
    },
  },
}));

export default function UpdateProducer(props) {
  moment.locale('fr');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // # EDITOR SETTINGS
  const editorData = (data) => {
    const contentDataState = ContentState.createFromBlockArray(convertFromHTML(data));

    return EditorState.createWithContent(contentDataState);
  };

  const { producer, tags, updateProducerToList } = props;

  const [state, setState] = useState({
    loading: false,
    updateProducer: {},
  });

  // # MOUNT COMPONENT
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      loading: true,
      updateProducer: {
        ...producer,
        description: editorData(producer.description),
      },
    }));
  }, [producer]);

  // # HANDLE IMG FILE CHANGE
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setState((prev) => ({
      ...prev,
      updateProducer: {
        ...prev.updateProducer,
        updatedPhoto: file,
      },
    }));
  };

  // # HANDLE CHANGE STATE
  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      updateProducer: {
        ...prev.updateProducer,
        [name]: value,
      },
    }));
  };

  // # HANDLE AUTOCOMPLETE CHANGES
  const handleAutocompleteMultiple = (value) => {
    setState((prev) => ({
      ...prev,
      updateProducer: {
        ...prev.updateProducer,
        tags: value,
      },
    }));
  };

  // # HANDLE EDITOR CHANGES
  const onEditorStateChange = (editorState) => {
    setState((prev) => ({
      ...prev,
      updateProducer: {
        ...prev.updateProducer,
        description: editorState,
      },
    }));
  };

  // # HANDLE UPDATE PRODUCER
  const handleUpdate = async (event) => {
    event.preventDefault();
    const token = Cookies.get('jwt');
    const {
      id,
      firstname,
      lastname,
      position,
      location,
      sinceWhen,
      sinceWhenBio,
      distinctiveSign,
      production,
      tags,
      description,
      updatedPhoto,
    } = state.updateProducer;

    const formData = new FormData();

    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('position', position);
    formData.append('location', location);
    formData.append('sinceWhen', sinceWhen);
    formData.append('sinceWhenBio', sinceWhenBio);
    formData.append('distinctiveSign', distinctiveSign);
    formData.append('production', production);
    formData.append('tags', JSON.stringify(tags.map((tag) => tag.id)));
    await formData.append(
      'description',
      draftToHtml(convertToRaw(description.getCurrentContent()))
    );

    if (updatedPhoto) formData.append('photo', updatedPhoto);

    axiosPatchRequest(`/producers/${id}`, token, formData)
      .then((res) => {
        updateProducerToList(res.data.data);
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  return (
    <>
      {state.loading && (
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.flexImg}>
                  <figure>
                    {state.updateProducer?.updatedPhoto ? (
                      <img
                        src={URL.createObjectURL(state.updateProducer.updatedPhoto)}
                        alt={`${state.updateProducer.firstname} ${state.updateProducer.lastname}`}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${state.updateProducer.photo}`}
                        alt={`${state.updateProducer.firstname} ${state.updateProducer.lastname}`}
                      />
                    )}
                  </figure>
                  <Input type="file" onChange={handleFileChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="firstname"
                    label="Prénom"
                    type="text"
                    variant="outlined"
                    value={state.updateProducer.firstname}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="lastname"
                    label="Nom"
                    type="text"
                    variant="outlined"
                    value={state.updateProducer.lastname}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    name="position"
                    label="Postition"
                    type="number"
                    variant="outlined"
                    value={state.updateProducer.position}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="location"
                    label="Lieu de production"
                    type="text"
                    variant="outlined"
                    value={state.updateProducer.location}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    name="sinceWhen"
                    label="Début de collaboration"
                    type="date"
                    variant="outlined"
                    value={state.updateProducer.sinceWhen}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="sinceWhenBio"
                    label="Début de production Bio"
                    type="date"
                    variant="outlined"
                    value={state.updateProducer.sinceWhenBio}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="distinctiveSign"
                    label="Signe distinctif"
                    type="text"
                    variant="outlined"
                    value={state.updateProducer.distinctiveSign}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="production"
                    label="Production"
                    type="text"
                    variant="outlined"
                    value={state.updateProducer.production}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    value={state.updateProducer.tags}
                    onChange={(event, value) => handleAutocompleteMultiple(value)}
                    options={tags.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(option) => <>{option.name}</>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tags"
                        variant="outlined"
                        style={{
                          width: '100%',
                          marginBottom: '1rem',
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <h3>Description :</h3>
                    <Editor
                      editorState={state.updateProducer.description}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      name="description"
                      toolbar={{
                        options: ['inline', 'colorPicker', 'link'],
                        inline: {
                          options: ['bold', 'italic'],
                        },
                      }}
                      editorStyle={{
                        border: '1px solid #F1F1F1',
                        minHeight: '150px',
                        padding: '0 1rem',
                        marginBottom: '1rem',
                      }}
                      onEditorStateChange={onEditorStateChange}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="primary" onClick={handleUpdate}>
                    Mettre à jour
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
