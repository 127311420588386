import React from 'react';

export default function CGV() {
  return (
    <div id="cgv">
      <h1>conditions générales de vente</h1>
      <div className="container">
        <h3>I - COMMANDE</h3>
        <h4>1.2 Modification de commande</h4>
        <p>
          Le client peut apporter modification de sa commande en appelant « Bio Culture » jusque la
          veille 17 h de la date de livraison indiquée au moment de la commande.
        </p>
        <h4>1.1. Prise de commande</h4>
        <p>
          Toute passation de commande sur le présent site implique l&apos;acceptation intégrale par
          le client des conditions citées ci-après. Les conditions générales de vente constituent
          l&apos;intégralité des droits et obligations des parties, aucune autre condition ne peut
          s&apos;intégrer à celles-ci. Le fournisseur « Bio Culture » fait connaître au client
          l&apos;ensemble des caractéristiques essentielles des produits offerts et livrables. Ces
          caractéristiques apparaissent dans les écrans de présentation des produits proposés à la
          vente chaque semaine. Les commandes passées par l&apos;intermédiaire du service de
          commerce électronique « www.panier-bio-paris.fr » engagent le client dès lors qu&apos;il a
          validé sa commande en réglant le montant correspondant aux articles sélectionnés au moyen
          de Mercanet (serveur sécurisé de BNP Paribas). Les commandes passées par téléphone font
          l&apos;objet de frais administratifs pour un montant de 2 € à chaque commande. Le système
          d&apos;enregistrement automatique utilisé par « www.panier-bio-paris.fr » est considéré
          comme valant preuve de la nature, du contenu et de la date de la commande. Toute commande
          passée sur le site « www.panier-bio-paris.fr » sera confirmée. Sur cette confirmation,
          transmise par E.mail dans les minutes qui suivent la passation de commande, figureront la
          commande enregistrée, son prix, la liste des produits la composant, les conditions de
          livraison convenues au moment de la commande (adresse et horaire) et un numéro de
          transaction.
          <br />
          <br />
          Pour les livraisons à domicile, le fournisseur « Bio Culture » limite géographiquement
          l&apos;offre proposée aux lieux identifiés dans la rubrique « Livraison » sur l&apos;écran
          d&apos;accueil et impose des frais de livraison selon les zones.
        </p>

        <h5>CONDITIONS SPÉCIALES CITÉ GREEN</h5>
        <p>
          Les clients bénéficiant de paniers avec réduction Cité Green ne peuvent EN AUCUN CAS faire
          de modifications de leur date de livraison ou du point de livraison sélectionnés lors de
          la commande initiale. Le points de livraison et la date de livraison choisis lors de la
          passation de commande sont fermes et définitifs pour toute la durée de l&apos;abonnement.
          Si le client le souhaite, Bio Culture assurera le remboursement des commandes pas encore
          livrées et stoppera de ce fait les livraisons.
        </p>

        <h4>1.3. Annulation de commande</h4>
        <p>
          Le client a capacité à annuler sa commande jusque la veille 12 h de la date de livraison
          prévue, par téléphone uniquement au 01 41 14 18 10. Dans ce cas, un remboursement sera
          effectué via Mercanet, déduction faite de 7 % du montant total de la commande. Passé ce
          délai, aucun remboursement ne sera effectué. Il n&apos;y a pas d&apos;annulation possible
          pour les produits de « Mon Boucher Bio ». Toute commande passée est honorée.
        </p>

        <h4>1.4 Possibilité de création d&apos;un comptes</h4>
        <p>
          Le client peut, s&apos;il le souhaite, créer un compte en remplissant un formulaire
          disponible sur le site. Il pourra procéder à la modification des informations
          communiquées. Lors de cette inscription, le client choisira un nom de compte et un mot de
          passe qui lui permettront d&apos;accéder au service ; ceux-ci devront rester confidentiels
          afin d&apos;éviter toute utilisation par un tiers dont Bio Culture ne pourrait être tenu
          pour responsable.
        </p>

        <h4>1.5 Abonnement</h4>
        <p>
          Le client peut souscrire à un abonnement de 1 ou 3 mois. Les produits commandés seront
          livrés durant la durée choisie, chaque semaine, à domicile ou en point relais. Le client
          pourra, en cas d&apos;absence, reporter ses livraisons d&apos;une semaine sur l&apos;autre
          en prévenant « Bio Culture » jusqu&apos;à 48 h avant la date de livraison convenue.
          <br />
          Le client a la possibilité de résilier son abonnement à tout moment par mail ou par
          téléphone jusqu&apos;à 48 h avant la livraison prévue. La résiliation de l&apos;abonnement
          entraîne la facturation de 5 € de frais de gestion retenus sur le montant restant dû.
          <br />
          En fin d&apos;abonnement, le client doit se réinscrire s&apos;il souhaite le renouveler.
          <br />« Bio Culture » se réserve le droit d&apos;annuler un abonnement dans le cas
          d&apos;incident de paiement ou de litige non résolu.
        </p>

        <h4>1.6 La composition est susceptible de changer au dernier moment</h4>
        <p>
          Dans le cas ou la qualité d&apos;un produit fasse défaut (Bio Culture étant le seul juge)
          ou dans le cadre de problème d&apos;arrivage. Nous rappelons que cela fait partie de
          l&apos;esprit «Panier Bio».
        </p>

        <h3>II - LIVRAISONS</h3>
        <h4>2.1 Livraison à domicile</h4>
        <p>
          Les livraisons, effectuées par nos propres équipes, sont inscrites dans l&apos;onglet «
          Livraison », à la date et dans le créneau horaire validés par le client.
          <br />À chaque ville correspond un seuil de gratuité de la livraison et le montant des
          frais, le cas échéant, sont indiqués au moment de la commande.
          <br />
          <br />
          En cas d&apos;impossibilité par « Bio Culture » de livrer à la date ou dans le créneau
          horaire souhaités par le client et indiqués au moment de la commande, le client sera
          aussitôt prévenu par téléphone par « Bio Culture » pour définir un autre horaire qui lui
          convienne. Les retards éventuels ne donnent pas le droit à l&apos;acheteur de réclamer des
          dommages et intérêts. Nous livrons à l&apos;adresse de livraison indiquée sur le bon de
          commande du client.
          <br />
          <br />
          Les informations énoncées par l&apos;acheteur, lors de la prise de commande engagent
          celui-ci. En cas d&apos;erreur dans le libellé des coordonnées du destinataire, « Bio
          Culture » ne saurait être tenu responsable de l&apos;impossibilité dans laquelle il
          pourrait être de livrer le produit.
          <br />
          <br />
          En cas d&apos;absence au moment de la livraison, un avis de passage sera déposé ; il
          conviendra à l&apos;acheteur de contacter directement « Bio Culture » afin de convenir
          d&apos;un deuxième passage à ses frais, donc facturé en sus de sa commande initiale. Il
          sera alors facturé la somme maximale indiquée pour une livraison dans la zone concernée,
          réglable au moment de la seconde livraison.
          <br />
          <br />
          Il en est de même si « Bio Culture » ne peut accéder à l&apos;immeuble en raison d&apos;un
          code qui n&apos;aurait pas été spécifié au moment de la passation de commande par internet
          et que le client n&apos;est pas joignable par téléphone au moment de la livraison. Le fait
          de ne pas réceptionner les produits ne peut, en aucun cas, être assimilé à l&apos;exercice
          implicite d&apos;un droit de rétractation.
          <br />
          Le droit de rétractation n&apos;est pas applicable suivant l&apos;article L 121-21-8 du
          code de la consommation, pour les produits très périssables tels que le fruit et légume.
          Il est applicable pour les autres produits.
          <br />
          Dans ce cas, aucun remboursement ne sera effectué de la part de « Bio Culture ».
        </p>

        <h4>2.2 Livraison en point relais</h4>
        <p>
          Les livraisons, effectuées par nos propres équipes, sont inscrites dans l&apos;onglet
          &apos; Votre Livraison&apos;, au point relais et à la date sélectionnés par le client.
          <br />A chaque ville correspond un point relais déterminé. Il est de la responsabilité de
          l&apos;acheteur de choisir le point relais qui lui correspond.
          <br />
          <br />
          En cas d&apos;impossibilité par « Bio Culture » de livrer à la date souhaitée par le
          client et indiquée au moment de la commande, le client sera aussitôt prévenu par téléphone
          par « Bio Culture » pour définir une autre date qui lui convienne.
          <br />
          Les retards éventuels ne donnent pas le droit à l&apos;acheteur de réclamer des dommages
          et intérêts.
          <br />
          <br />
          Si le client ne venait pas récupérer sa commande au jour prévu et indiqué sur le site
          internet, le produit serait perdu et facturé.
          <br />
          <br />
          Le fait de ne pas réceptionner les produits ne peut, en aucun cas, être assimilé à
          l&apos;exercice implicite d&apos;un droit de rétractation. Dans ce cas, ils seront
          facturés et aucun remboursement ne sera effectué de la part de « Bio Culture ».
        </p>

        <h3>III - TARIFS</h3>
        <p>
          Nos tarifs indiqués sont exprimés en Euro et toutes taxes comprises. Nous facturons sur la
          base du tarif affiché au moment de votre passation de commande.
        </p>

        <h3>IV - PAIEMENT</h3>
        <p>
          Le paiement se fait en ligne par carte bancaire. Il est réalisé directement et
          conformément aux conditions générales du système de télépaiement sécurisé de BNP Paribas,
          Mercanet.
          <br />
          <br />
          Une fois le paiement effectué, un mail de confirmation reprenant le détail de la commande
          et de la transaction est envoyé au client directement.
          <br />
          EN AUCUN CAS, LE FOURNISSEUR «Bio Culture» N&apos;A ACCÈS AUX INFORMATIONS CONCERNANT
          VOTRE CARTE BANCAIRE.
          <br />
          <br />
          Les commandes passées par téléphone sont réglables par chèque libellé au nom de « Bio
          Culture » ou en espèces au moment de la livraison à domicile au livreur, ou à la réception
          de la commande au point relais au responsable du point relais.
        </p>

        <h3>V - INFORMATIONS LÉGALES</h3>
        <p>
          Les mineurs n&apos;ont pas la capacité de contracter. La collecte des données auprès des
          mineurs doit faire l&apos;objet d&apos;une information de l&apos;autorité parentale.
        </p>

        <h3>VI - CONFIDENTIALITÉ DES DONNÉES</h3>
        <p>
          Les données personnelles recueillies sont uniquement destinées au traitement des
          commandes. Elles sont confidentielles, et ne sont jamais transmises à des tiers.
          Conformément à la loi, toute personne dispose d&apos;un droit d&apos;accès, de
          rectification et de suppression de données le concernant (art. 34 de la loi «Informatique
          et Libertés» du 6 janvier 1978).
          <br />
          Pour l&apos;exercer, contactez-nous par courriel : service-client@bio-culture.fr.
          <br />
          Ce traitement informatique fait l&apos;objet de la déclaration n° 1374994 auprès de la
          CNIL.
        </p>

        <h3>VII - PROPRIÉTÉ INTELLECTUELLE</h3>
        <p>
          L&apos;ensemble du contenu du site « www.panier-bio-paris.fr » est la propriété de Bio
          Culture. Tous les droits d&apos;utilisation lui sont réservés. La marque et logo sont des
          marques déposées. Leur reproduction constitue, par conséquent, une contrefaçon.
        </p>

        <h3>VIII - LITIGE</h3>
        <p>
          Dans l&apos;hypothèse où un litige naîtrait de la présente relation contractuelle, les
          parties s&apos;engagent avant toute action judiciaire à rechercher une solution amiable.
          Toute réclamation doit être adressée à bioculture.panierbio@gmail.com ou par courrier à
          Bio Culture, 28 rue de Wolfenbüttel, 92310 Sèvres
        </p>

        <h3>IX - ATTRIBUTION DE JURIDICTION</h3>
        <p>
          Les conditions générales de vente présentées ci-dessus sont régies par le droit français.
          Toute contestation concernant ces conditions générales de vente sera soumise au tribunal
          compétant.
        </p>
      </div>
    </div>
  );
}
