import React, { useState, Fragment, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, FormControl, IconButton, InputLabel, Paper, Select, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  addSchedule: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '20px',

    '& > div:first-child': {
      flexGrow: 2,
    },

    '& > div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      flexGrow: 1,

      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',

        '& > div': {
          minWidth: '125px',
        },
      },
    },
  },
  marginB: {
    margin: '0 0 1rem 0',
  },
}));

export default function AddSchedules(props) {
  const {
    editing,
    newSchedule,
    updateRelaySchedules,
    relaySchedules,
    handleRemoveSchedule,
    handleNewSchedule,
    handleAddNewSchedule
  } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <h3>Horaires de livraison :</h3>
      {editing ? (
        <div>
          {updateRelaySchedules ? (
            <ul>
              {updateRelaySchedules.map((el, index) => (
                <li key={index}>
                  <b style={{ textTransform: 'capitalize' }}>{el.day}</b> : Livraison {el.depositStartTime} - {el.depositEndTime} | Retrait {el.startTime} - {el.endTime}
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    data-index={index}
                    onClick={handleRemoveSchedule}
                  >
                    <DeleteIcon />
                  </IconButton>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucun horaire</p>
          )}
          <Divider variant="middle" className={classes.marginB} />
          <div className={classes.addSchedule}>
            <FormControl variant="outlined">
              <InputLabel>Jour de livraison</InputLabel>
              <Select
                native
                label="Jour de livraison"
                defaultValue={newSchedule.day}
                name="day"
                onChange={handleNewSchedule}
              >
                <option value="lundi">Lundi</option>
                <option value="mardi">Mardi</option>
                <option value="mercredi">Mercredi</option>
                <option value="jeudi">Jeudi</option>
                <option value="vendredi">Vendredi</option>
                <option value="samedi">Samedi</option>
              </Select>
            </FormControl>
            <div>
              <div>
                <TextField
                  className={classes.marginB}
                  label="Livraison - Début"
                  type="time"
                  variant="outlined"
                  name="depositStartTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  defaultValue={newSchedule.depositStartTime}
                  onChange={handleNewSchedule}
                />
                <TextField
                  className={classes.marginB}
                  label="Livraison - Fin"
                  type="time"
                  variant="outlined"
                  name="depositEndTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  defaultValue={newSchedule.depositEndTime}
                  onChange={handleNewSchedule}
                />
              </div>
              <div>
                <TextField
                  label="Retrait - Début"
                  type="time"
                  variant="outlined"
                  name="startTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  defaultValue={newSchedule.startTime}
                  onChange={handleNewSchedule}
                />
                <TextField
                  label="Retrait - Fin"
                  type="time"
                  variant="outlined"
                  name="endTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  defaultValue={newSchedule.endTime}
                  onChange={handleNewSchedule}
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddNewSchedule}
            >
              AJouter
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {relaySchedules.length > 0 ? (
            <ul>
              {relaySchedules.map((el, index) => (
                <li key={index}>
                  <b style={{ textTransform: 'capitalize' }}>{el.day}</b> : Livraison {el.depositStartTime} - {el.depositEndTime} | Retrait {el.startTime} - {el.endTime}
                </li>
              ))}
            </ul>
          ) : (
            <p> Aucun horaire</p>
          )}
        </div>
      )}
    </Paper>
  );
}
