import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'js-cookie';

import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// # UTILS

// # MATERIAL UI
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Paper,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { Autocomplete } from '@material-ui/lab';
import { autocompleteGroupedFormater } from '../../utils/stringFormater';
import { axiosGetRequest, axiosPatchRequest } from '../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    marginBottom: '1rem',
    padding: '15px 30px 24px',
    outline: 'none',

    '& h4': {
      margin: '5px 0',
    },
  },
  divider: {
    margin: '1rem',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infos: {
    '& > div': {
      display: 'flex',
      flexGrow: 1,

      '& > .MuiFormControl-root': {
        margin: '0 1rem 1rem 0',
        flexGrow: 1,
      },
    },
  },
  marginB: {
    marginBottom: '1rem',
  },
}));

export default function ProductUpdates(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // # Editor Settings
  const editorData = (data) => {
    const contentDataState = ContentState.createFromBlockArray(convertFromHTML(data));

    return EditorState.createWithContent(contentDataState);
  };

  const { product } = props;

  const [state, setState] = useState({
    loading: true,
    taxes: [],
    tags: [],
    categories: [],
    subCategories: [],
    weightUnities: [],
    product: {
      newImage: undefined,
      name: product.name,
      slug: product.slug,
      reference: product.reference,
      price: product.price,
      tvaRate: product.tvaRate.id,
      description: editorData(product.description),
      category: product.category.id,
      subCategory: product.subCategory.id,
      isActive: product.isActive,
      isEco: product.isEco,
      size: product.size,
      stock: product.stock,
      tags: [...product.tags],
      weight: {
        quantity: product.weight?.quantity || 0,
        unity: product.weight?.unity || '',
      },
      origin: product.origin || '',
      labels: {
        organic: product.labels.organic || false,
        wild: product.labels.wild || false,
        french: product.labels.french || false,
        native: product.labels.native || false,
      },
    },
  });

  useEffect(() => {
    const token = Cookies.get('jwt');

    // # FETCH TAXES
    axiosGetRequest(`/taxes/`, token)
      .then((res) => {
        const { taxes } = res.data.data;
        setState((prev) => ({
          ...prev,
          taxes,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    // # FETCH CATEGORIES
    axiosGetRequest(`/categories/`, token)
      .then((res) => {
        const { categories } = res.data.data;
        setState((prev) => ({
          ...prev,
          categories,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    // # FETCH SUB CATEGORIES
    axiosGetRequest(`/subcategories/`, token)
      .then((res) => {
        const { subCategories } = res.data.data;
        setState((prev) => ({
          ...prev,
          subCategories,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    // # FETCH TAGS
    axiosGetRequest('/tags/', token).then((res) => {
      const { tags } = res.data.data;
      const formattedTags = tags.map((tag) => ({
        ...tag,
        cleanName: tag.name.toLowerCase().split('.')[1],
      }));
      const tagsFormat = autocompleteGroupedFormater(formattedTags, 'cleanName');
      setState((prev) => ({
        ...prev,
        tags: [...tagsFormat],
      }));
    });

    // # FETCH WEIGHT UNITIES
    axiosGetRequest('/weight/', token)
      .then((res) => {
        const { unities } = res.data.data;
        setState((prev) => ({
          ...prev,
          weightUnities: [...unities],
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [product]);

  // # HANDLE EDITOR CHANGES
  const onEditorStateChange = (editorState) => {
    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        description: editorState,
      },
    }));
  };

  // # HANDLE IMG UPLOAD
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        newImage: file,
      },
    }));
  };

  // # HANDLE INPUTS CHANGES
  const handleInputsChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        [name]: value,
      },
    }));
  };

  // # HANDLE WEIGHT INPUTS CHANGES
  // TODO : NEED REFACTORING
  const handleInputsWeightChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        weight: {
          ...prev.product.weight,
          [name]: value,
        },
      },
    }));
  };

  // # HANDLE SWITCH CHANGES
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        [name]: checked,
      },
    }));
  };

  // # HANDLE SWITCH LABELS CHANGES
  const handleSwitchLabelsChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        labels: {
          ...prev.product.labels,
          [name]: checked,
        },
      },
    }));
  };

  // # HANDLE UPDATE PRODUCT
  const handleUpdate = async (event) => {
    event.preventDefault();
    const token = Cookies.get('jwt');

    const formData = new FormData();

    if (state.product.newImage) {
      formData.append('photo', state.product.newImage);
    }

    formData.append('name', state.product.name);
    formData.append('reference', state.product.reference);
    formData.append('price', state.product.price);
    formData.append('tvaRate', state.product.tvaRate);
    await formData.append(
      'description',
      draftToHtml(convertToRaw(state.product.description.getCurrentContent()))
    );
    formData.append('category', state.product.category);
    formData.append('subCategory', state.product.subCategory);
    formData.append('isActive', state.product.isActive);
    formData.append('isEco', state.product.isEco);
    formData.append('size', state.product.size);
    formData.append('stock', state.product.stock);
    formData.append('quantity', state.product.weight.quantity);
    formData.append('unity', state.product.weight.unity.id || state.product.weight.unity);
    formData.append('origin', state.product.origin);
    formData.append('organic', state.product.labels.organic);
    formData.append('wild', state.product.labels.wild);
    formData.append('french', state.product.labels.french);
    formData.append('native', state.product.labels.native);
    formData.append('tags', JSON.stringify(state.product.tags));

    axiosPatchRequest(`/products/${product.id}`, token, formData)
      .then(() => {
        history.goBack();
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  // # HANDLE AUTOCOMPLETE CHANGES
  const handleAutocompleteMultiple = (value) => {
    setState((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        tags: value,
      },
    }));
  };

  // # HANDLE DELETE PRODUCT
  const handleDelete = () => {
    const token = Cookies.get('jwt');

    axios
      .delete(`${process.env.REACT_APP_API}/products/${product.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Paper className={classes.paper}>
              <section className={classes.spaceBetween}>
                <div className={classes.flexCenter}>
                  {state.product.newImage ? (
                    <img
                      src={URL.createObjectURL(state.product.newImage)}
                      alt="upload"
                      width={250}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_AWS}/${product.photo}`}
                      alt={product.name}
                      width={250}
                    />
                  )}
                  <Input type="file" onChange={handleFileChange} />
                </div>
                <Divider className={classes.divider} orientation="vertical" flexItem />
                <div>
                  <h4>Visibilité :</h4>
                  <div className={classes.flex}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={state.product.isActive}
                          onChange={handleSwitchChange}
                        />
                      }
                      label="Actif"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="isEco"
                          color="primary"
                          checked={state.product.isEco}
                          onChange={handleSwitchChange}
                        />
                      }
                      label="Eco-acteurs"
                    />
                  </div>
                  <Divider className={classes.divider} />
                  <div>
                    <h4>Labels :</h4>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name="organic"
                            color="primary"
                            checked={state.product.labels.organic}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Bio"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="wild"
                            color="primary"
                            checked={state.product.labels.wild}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Sauvage"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name="french"
                            color="primary"
                            checked={state.product.labels.french}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Français"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="native"
                            color="primary"
                            checked={state.product.labels.native}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Local"
                      />
                    </div>
                  </div>
                  <Divider className={classes.divider} />
                  <TextField
                    name="origin"
                    label="Origine"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={state.product.origin}
                    onChange={handleInputsChange}
                  />
                </div>
              </section>
              <Divider className={classes.divider} />
              <section className={classes.infos}>
                <h3>Informations :</h3>
                <div>
                  <TextField
                    name="name"
                    label="Nom"
                    type="text"
                    variant="outlined"
                    value={state.product.name}
                    onChange={handleInputsChange}
                  />
                  <TextField
                    name="reference"
                    label="Référence"
                    type="text"
                    variant="outlined"
                    value={state.product.reference}
                    onChange={handleInputsChange}
                  />
                </div>
                <div>
                  <FormControl variant="outlined">
                    <InputLabel>Rubrique</InputLabel>
                    <Select
                      label="Rubrique"
                      name="category"
                      value={state.product.category}
                      onChange={handleInputsChange}
                      native
                    >
                      {state.categories.map((category, idx) => (
                        <option key={idx} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined">
                    <InputLabel>Sous-rubrique</InputLabel>
                    <Select
                      native
                      label="Sous-rubrique"
                      name="subCategory"
                      value={state.product.subCategory}
                      onChange={handleInputsChange}
                    >
                      {state.subCategories.map((subCategory, idx) => (
                        <option key={idx} value={subCategory.id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    name="price"
                    label="Prix"
                    type="number"
                    variant="outlined"
                    value={state.product.price}
                    onChange={handleInputsChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel>TVA</InputLabel>
                    <Select
                      native
                      name="tvaRate"
                      label="TVA"
                      value={state.product.tvaRate}
                      onChange={handleInputsChange}
                    >
                      {state.taxes.map((tax, idx) => (
                        <option key={idx} value={tax.id}>
                          {tax.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="stock"
                    label="Stock"
                    type="number"
                    variant="outlined"
                    value={state.product.stock}
                    onChange={handleInputsChange}
                  />
                </div>
                <div>
                  <TextField
                    name="quantity"
                    label="Poids"
                    type="number"
                    variant="outlined"
                    value={state.product.weight.quantity}
                    onChange={handleInputsWeightChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel>Unité</InputLabel>
                    <Select
                      native
                      name="unity"
                      label="Unité"
                      value={state.product.weight.unity.id}
                      onChange={handleInputsWeightChange}
                    >
                      {state.weightUnities.map((unity, idx) => (
                        <option key={idx} value={unity.id}>
                          {unity.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel>Taille</InputLabel>
                    <Select
                      native
                      name="size"
                      label="Taille"
                      value={state.product.size}
                      onChange={handleInputsChange}
                    >
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="SOLO">Solo</option>
                    </Select>
                  </FormControl>
                </div>
              </section>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper className={classes.paper}>
              <h3>Description :</h3>
              <Editor
                editorState={state.product.description}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: ['inline', 'colorPicker', 'link'],
                  inline: {
                    options: ['bold', 'italic'],
                  },
                }}
                editorStyle={{
                  border: '1px solid #F1F1F1',
                  minHeight: '150px',
                  padding: '0 1rem',
                  marginBottom: '1rem',
                }}
              />
            </Paper>

            <Paper className={classes.paper}>
              <h3>Ajouter des tags :</h3>
              <Autocomplete
                multiple
                value={state.product.tags}
                onChange={(event, value) => handleAutocompleteMultiple(value)}
                options={state.tags.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => <>{option.name}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    variant="outlined"
                    style={{
                      width: '100%',
                      marginBottom: '1rem',
                    }}
                  />
                )}
              />
            </Paper>

            <Button
              className={classes.marginB}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleUpdate}
            >
              Modifier le produit
            </Button>
            <Button variant="contained" color="secondary" fullWidth onClick={handleDelete}>
              Supprimer le produit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
