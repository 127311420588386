import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import {
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputBase,
} from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import DeliveryCityTable from '../../../components/DeliveryCity/DeliveryCityTable';
import CreateDeliveryCityModal from '../../../components/DeliveryCity/CreateDeliveryCityModal';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade('#9e9e9e'),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

export default function DeliveryCityList() {
  const classes = useStyles();
  const [state, setState] = useState({
    loading: true,
    deliveryCities: [],
    deliveryCitiesShowcased: [],
    status: 'all',
  });

  const handleSearch = (e) => {
    const search = e.target.value.toString().toLowerCase();
    if (state.status !== 'all') {
      setState((prev) => ({
        ...prev,
        status: 'all',
      }));
    }
    const filtered = state.deliveryCities.filter(
      (el) => el.name.toLowerCase().indexOf(search) > -1
    );
    if (search.length === 0) {
      return setState((prev) => ({
        ...prev,
        deliveryCitiesShowcased: prev.deliveryCities,
      }));
    }
    setState((prev) => ({
      ...prev,
      deliveryCitiesShowcased: filtered,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/deliveryCities/`)
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          const sortedCities = res.data.data.deliveryCity.sort(
            (a, b) => -b.name.localeCompare(a.name)
          );

          setState((prev) => ({
            ...prev,
            deliveryCities: res.data.data.deliveryCity,
            deliveryCitiesShowcased: sortedCities,
            loading: false,
          }));
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (state.status === 'all') {
      return setState((prev) => ({
        ...prev,
        deliveryCitiesShowcased: prev.deliveryCities,
      }));
    }
    const filter = state.deliveryCities.filter((el) => el.isActive === state.status);
    setState((prev) => ({
      ...prev,
      deliveryCitiesShowcased: filter,
    }));
  }, [state.status, state.deliveryCities]);

  return (
    <>
      <div className={classes.flex}>
        <h2>Toutes les villes de livraison</h2>
        <CreateDeliveryCityModal />
      </div>
      <Paper className={classes.paper}>
        <h3>Filtre</h3>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={3}>
            <FormControl style={{ minWidth: '150px' }} variant="outlined">
              <InputLabel id="select-payment-label">Statut</InputLabel>
              <Select
                labelId="statut"
                label="Statut"
                defaultValue="all"
                value={state.status}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value={false}>Inactif</MenuItem>
                <MenuItem value>Actif</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Chercher une ville de livraison ..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={handleSearch}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <DeliveryCityTable cities={state.deliveryCitiesShowcased} />
      )}
    </>
  );
}
