import axios from 'axios';
import { calcTotalOrder } from './totalOrders';

const formatedItems = (items) =>
  items.map((item, idx) => ({
    item_name: item.name,
    item_id: item._id,
    price: item.price,
    item_category: item.subCategory,
    index: idx + 1,
    quantity: 1,
  }));

// # CREATE ORDER
export const BeginCheckout = (items) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items: formatedItems(items),
    },
  });
};

// # ORDER SUCCESS
export const Purchase = async (order) => {
  window.dataLayer.push({ ecommerce: null });

  const orderTotals = calcTotalOrder(order);

  const objEcommerce = {
    transaction_id: order._id,
    value: `${orderTotals.formatTotalTtc}`,
    tax: `${orderTotals.formatTotalTvaAmount}`,
    shipping: `${order.deliveryCost}`,
    currency: 'EUR',
    items: formatedItems(order.items),
  };

  if (order.discount) objEcommerce.coupon = order.discount.name;

  await axios.patch(`${process.env.REACT_APP_API}/orders/analytics/${order._id}`, {}).then(() => {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: objEcommerce,
    });
  });
};
