import React, { createContext, useReducer } from 'react';
import { UserReducer } from './UserReducer';

export const UserContext = createContext();

const userStorage = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : {
      id: '',
      token: '',
      role: '',
      personalData: {
        firstname: '',
        lastname: '',
        fullname: '',
        phoneNumber: '',
        email: '',
        complement: '',
        newsLetter: false,
      },
      addresses: [],
      ecoData: {
        ecoPoints: 0,
        customMessage: '',
        pickupSchedule: {
          from: '',
          to: '',
        },
        contacts: [],
      },
    };

const initialState = {
  user: userStorage,
};

function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const login = (payload) => {
    dispatch({ type: 'LOGIN', payload });
  };

  const logout = (payload) => {
    dispatch({ type: 'LOGOUT', payload });
  };

  const setInfos = (payload) => {
    dispatch({ type: 'SET_INFOS', payload });
  };

  const addContact = (payload) => {
    dispatch({ type: 'ADD_CONTACT', payload });
  };

  const removeContact = (payload) => {
    dispatch({ type: 'REMOVE_CONTACT', payload });
  };

  /* const removeAddress = (payload) => {
        dispatch({type: 'REMOVE_ADDRESS', payload})
    } */

  const setEcoInfos = (payload) => {
    dispatch({ type: 'SET_ECOINFOS', payload });
  };

  /* const setDiscounts = (payload) => {
        dispatch({type: 'SET_DISCOUNTS', payload})
    } */

  const setAddress = (payload) => {
    dispatch({ type: 'SET_ADDRESS', payload });
  };

  const contextValues = {
    login,
    logout,
    setInfos,
    setAddress,
    addContact,
    removeContact,
    setEcoInfos,
    ...state,
  };

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
