import React from 'react';

import TagManager from 'react-gtm-module';

import { Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// # LAYOUTS
import Back from './layouts/Back';
import Front from './layouts/Front';
import EcoActeurs from './layouts/EcoActeurs';
import BlogLayout from './layouts/BlogLayout';

// # PAGES ADMIN
import Dashboard from './components/Dashboard';
import Commission from './views/Back/Commission/Commission';
import AnalyticsDashboard from './views/Back/Analytics/AnalyticsDashboard';
//! USER
import UserList from './views/Back/User/UserList';
import UserProfil from './views/Back/User/UserProfil';
import UserCreateOrder from './views/Back/User/UserCreateOrder';
import CreateUser from './views/Back/User/CreateUser';
//! PRODUCT
import ProductList from './views/Back/Product/ProductList';
import ProductPage from './views/Back/Product/ProductPage';
import CreateProduct from './views/Back/Product/CreateProduct';
//! BASKET
import BasketList from './views/Back/Basket/BasketList';
import CreateBasket from './views/Back/Basket/CreateBasket';
import BasketPage from './views/Back/Basket/BasketPage';
//! RELAY
import RelayPointList from './views/Back/Relay/RelayPointList';
import CreateRelay from './views/Back/Relay/CreateRelay';
import RelayPointDetails from './views/Back/Relay/RelayPointDetails';
//! DELIVERY_CITY
import DeliveryCityList from './views/Back/DeliveryCity/DeliveryCityList';
import DeliveryCityDetails from './views/Back/DeliveryCity/DeliveryCityDetails';
//! DELIVERY_ZONE
import DeliveryZoneList from './views/Back/DeliveryZone/DeliveryZoneList';
import DeliveryZoneDetails from './views/Back/DeliveryZone/DeliveryZoneDetails';
//! DELIVERY SERVICES
import DeliveryServicesList from './views/Back/DeliveryService/deliveryServicesList';
import DeliveryServicesDetails from './views/Back/DeliveryService/deliveryServicesDetails';
//! ORDER
import OrderList from './views/Back/Order/OrderList';
import OrderPage from './views/Back/Order/OrderPage';
import UpdateOrder from './views/Back/Order/UpdateOrder';
import InfosOfTheDay from './views/Back/Order/InfosOfTheDay';
//! BILLING
import DeliveryNotes from './views/Back/DeliveryNotes/DeliveryNotesList';
//! BILLING
import BillingList from './views/Back/Billing/BillingList';
//! DISCOUNT
import DiscountList from './views/Back/Discount/DiscountList';
import DiscountDetails from './views/Back/Discount/DiscountDetails';
//! TAXES
import TaxeList from './views/Back/Taxe/TaxeList';
//! SEGMENTS
import SegmentList from './views/Back/Segment/SegmentList';
import SegmentDetails from './views/Back/Segment/SegmentDetails';
//! CATEGORIES
import CategoryList from './views/Back/Category/CategoryList';
import CategoryDetails from './views/Back/Category/CategoryDetails';
//! SUB_CATEGORIES
import SubCategoryList from './views/Back/SubCategory/SubCategoryList';
import SubCategoryDetails from './views/Back/SubCategory/SubCategoryDetails';
//! INVITATIONS
import InvitationsDashboard from './views/Back/Invitation/InvitationsDashboard';
import InvitationDetails from './views/Back/Invitation/InvitationDetails';
//! PRODUCERS
import ProducersList from './views/Back/Producers/ProducersList';
//! TEAMS
import TeamShow from './views/Back/Team/TeamShow';
//! OTHERS
import WeightList from './views/Back/Weight/WeightList';
import TagList from './views/Back/Tag/TagList';

// # PAGES FRONT
//! PAYMENT
import Signup from './views/Front/Profil/Signup';
import Login from './views/Front/Profil/Login';
import ForgotPassword from './views/Front/Profil/ForgotPassword';
import ResetPassword from './views/Front/Profil/ResetPassword';
import Valeurs from './views/Front/NosValeurs/Valeurs';
import NosProducteurs from './views/Front/NosValeurs/NosProducteurs';
import NosProduits from './views/Front/NosValeurs/NosProduits';
import ZeroDechets from './views/Front/NosValeurs/ZeroDechets';
import HomeBis from './views/Front/HomeBis';
import PageProduits from './views/Front/PageProduits';
import PaiementSuccess from './views/Front/AfterPayment/success';
import PaiementCancel from './views/Front/AfterPayment/cancel';
import EcoPoints from './views/Front/EcoActeurs/EcoPoints';
import EcoActeur from './views/Front/EcoActeurs/DevenirEcoActeur';
import CommentCaMarche from './views/Front/EcoActeurs/CCM';
import Profil from './views/Front/Profil/Profil';
import OffreEntreprise from './views/Front/Entreprises/OffreEntreprise';
import CorbeilleFruit from './views/Front/Entreprises/CorbeilleFruit';
import MentionsLegales from './views/Front/MentionsLegales';
import cgv from './views/Front/CGV';
import ContactEntreprise from './views/Front/Entreprises/ContactEntreprise';
import Invitation from './views/Front/NavbarEcoActeur/CreateInvitation';
import InvitationList from './views/Front/NavbarEcoActeur/InvitationList';
import CreateContact from './views/Front/NavbarEcoActeur/CreateContact';
import VosEcoPoints from './views/Front/NavbarEcoActeur/VosEcoPoints';
import ContactList from './views/Front/NavbarEcoActeur/ContactList';
import Contact from './views/Front/Contact';
import DashboardEco from './views/Front/NavbarEcoActeur/Dashboard';
import UpdateInvitation from './views/Front/NavbarEcoActeur/UpdateInvitation';
import Products from './views/EcoActeurs/Products';
import EcoInvit from './views/EcoActeurs/InvitationSession';
import OrderStats from './views/Back/Analytics/orderStats';

import notFound from './views/404';
import ExpiredInvitation from './views/ExpiredInvitation';
import EcoStatistics from './views/Back/Invitation/EcoStatistics';
import Offre from './views/Front/Offre';
import Article from './views/Front/Blog/Article';
import FilterArticles from './views/Front/Blog/ArticlesFiltered';
import BlogHome from './views/Front/Blog/BlogHome';
import PoireauDefense from './views/Front/PoireauDefense';
import CarottesDefense from './views/Front/CarottesDefense';
import CloseWebsite from './views/Back/CloseWebsite/CloseWebsite';
import BannersList from './views/Back/Banner/BannersList';
import PageHome from './views/Back/PageHome';
import AppRoute from './shared/routes/AppRoute';
import SubscriberList from './views/Back/Subscriber/SubscriberList';

const history = createBrowserHistory();

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ACCOUNT,
});

function App() {
  return (
    <Router history={history}>
      <Switch>
        {/* FRONT */}
        <AppRoute exact path="/" layout={Front} component={HomeBis} />
        <AppRoute exact path="/home" layout={Front} component={HomeBis} />
        <AppRoute exact path="/valeurs" layout={Front} component={Valeurs} />
        <AppRoute exact path="/nos-producteurs" layout={Front} component={NosProducteurs} />
        <AppRoute exact path="/contact" layout={Front} component={Contact} />
        <AppRoute exact path="/zero-dechets" layout={Front} component={ZeroDechets} />
        <AppRoute exact path="/eco-points" layout={Front} component={EcoPoints} />
        <AppRoute exact path="/eco-acteur" layout={Front} component={EcoActeur} />
        <AppRoute exact path="/c-c-m" layout={Front} component={CommentCaMarche} />
        <AppRoute exact path="/profil" layout={Front} component={Profil} />
        <AppRoute exact path="/offre-entreprise" layout={Front} component={OffreEntreprise} />
        <AppRoute exact path="/corbeille-fruit" layout={Front} component={CorbeilleFruit} />
        <AppRoute exact path="/mentions-legales" layout={Front} component={MentionsLegales} />
        <AppRoute exact path="/cgv" layout={Front} component={cgv} />
        <AppRoute exact path="/nos-valeurs" layout={Front} component={Valeurs} />
        <AppRoute exact path="/nos-producteurs" layout={Front} component={NosProducteurs} />
        <AppRoute exact path="/nos-produits" layout={Front} component={NosProduits} />
        <AppRoute exact path="/zero-dechets" layout={Front} component={ZeroDechets} />
        <AppRoute exact path="/success/:id" layout={Front} component={PaiementSuccess} />
        <AppRoute exact path="/cancel" layout={Front} component={PaiementCancel} />
        <AppRoute exact path="/catalogue/:slug" layout={Front} component={PageProduits} />
        <AppRoute exact path="/offre-decouverte" layout={Front} component={Offre} code={'BIO-2022'} />
        <AppRoute exact path="/offre-rentree-bio" layout={Front} component={Offre} code={'RENTREEBIO'} />
        <AppRoute exact path="/offre-2022-bio" layout={Front} component={Offre} code={'2022-BIO'} />
        <AppRoute exact path="/poireau-ladefense" layout={Front} component={PoireauDefense} code={'POIREAU'} />
        <AppRoute exact path="/carottes-ladefense" layout={Front} component={CarottesDefense} code={'CAROTTES'} />
        <AppRoute exact path="/contact-entreprise" layout={Front} component={ContactEntreprise} />
        <AppRoute exact path="/create_invitation" layout={Front} component={Invitation} />
        <AppRoute exact path="/invitations" layout={Front} component={InvitationList} />
        <AppRoute exact path="/expired_invitations" layout={Front} component={ExpiredInvitation} />
        <AppRoute exact path="/create_contact" layout={Front} component={CreateContact} />
        <AppRoute exact path="/contact_list" layout={Front} component={ContactList} />
        <AppRoute exact path="/vos-eco-points" layout={Front} component={VosEcoPoints} />
        <AppRoute exact path="/invitation/:invitationId" layout={Front} component={DashboardEco} />
        <AppRoute exact path="/update_invitation/:invitationId" layout={Front} component={UpdateInvitation} />
        <AppRoute exact path="/signup" layout={Front} component={Signup} />
        <AppRoute exact path="/forgotpassword" layout={Front} component={ForgotPassword} />
        <AppRoute exact path="/resetPassword/:token" layout={Front} component={ResetPassword} />
        <AppRoute exact path="/login" layout={Front} component={Login} />

        {/*FRONT BLOG*/}
        <AppRoute exact path="/blog" layout={BlogLayout} component={BlogHome} />
        <AppRoute exact path="/blog/:slug" layout={BlogLayout} component={Article} />
        <AppRoute exact path="/blog/filter/:tag" layout={BlogLayout} component={FilterArticles} />

        {/*FRONT ECO ACTEURS*/}
        <AppRoute exact path="/eco-invitation/:uuid" layout={EcoActeurs} component={EcoInvit} />
        <AppRoute exact path="/eco-invite" layout={EcoActeurs} component={Products} />
        <AppRoute exact path="/eco-catalogue/:slug" layout={EcoActeurs} component={Products} />
        <AppRoute exact path="/eco-values" layout={EcoActeurs} component={Valeurs} />
        <AppRoute exact path="/eco-productors" layout={EcoActeurs} component={NosProducteurs} />
        <AppRoute exact path="/eco-products" layout={EcoActeurs} component={NosProduits} />
        <AppRoute exact path="/eco-zero-dechets" layout={EcoActeurs} component={ZeroDechets} />

        {/* BACK ADMIN */}
        <AppRoute exact path="/admin" layout={Back} component={Dashboard} />
        <AppRoute exact path="/admin/commission" layout={Back} component={Commission} />
        <AppRoute exact path="/admin/users/list/:query" layout={Back} component={UserList} />
        <AppRoute exact path="/admin/closeWebsite" layout={Back} component={CloseWebsite} />
        <AppRoute exact path="/admin/analytics" layout={Back} component={AnalyticsDashboard} />
        <AppRoute exact path="/admin/stats" layout={Back} component={OrderStats} />
        <AppRoute exact path="/admin/users/:id" layout={Back} component={UserProfil} />
        <AppRoute exact path="/admin/users/createOrder/:id" layout={Back} component={UserCreateOrder} />
        <AppRoute exact path="/admin/newUser" layout={Back} component={CreateUser} />
        <AppRoute exact path="/admin/baskets/list" layout={Back} component={BasketList} />
        <AppRoute exact path="/admin/baskets/create" layout={Back} component={CreateBasket} />
        <AppRoute exact path="/admin/baskets/:basketId" layout={Back} component={BasketPage} />
        <AppRoute exact path="/admin/products/list" layout={Back} component={ProductList} />
        <AppRoute exact path="/admin/products/create" layout={Back} component={CreateProduct} />
        <AppRoute path="/admin/products/:id" layout={Back} component={ProductPage} />
        <AppRoute exact path="/admin/categories" layout={Back} component={CategoryList} />
        <AppRoute exact path="/admin/categories/:id" layout={Back} component={CategoryDetails} />
        <AppRoute exact path="/admin/subcategories" layout={Back} component={SubCategoryList} />
        <AppRoute exact path="/admin/subcategories/:id" layout={Back} component={SubCategoryDetails} />
        <AppRoute exact path="/admin/relayPoints" layout={Back} component={RelayPointList} />
        <AppRoute exact path="/admin/relayPoints/:id" layout={Back} component={RelayPointDetails} />
        <AppRoute exact path="/admin/createRelay" layout={Back} component={CreateRelay} />
        <AppRoute exact path="/admin/deliveryCities" layout={Back} component={DeliveryCityList} />
        <AppRoute exact path="/admin/deliveryCities/:id" layout={Back} component={DeliveryCityDetails} />
        <AppRoute exact path="/admin/deliveryZones" layout={Back} component={DeliveryZoneList} />
        <AppRoute exact path="/admin/deliveryZones/:id" layout={Back} component={DeliveryZoneDetails} />
        <AppRoute exact path="/admin/deliveryServices" layout={Back} component={DeliveryServicesList} />
        <AppRoute exact path="/admin/deliveryServices/:id" layout={Back} component={DeliveryServicesDetails} />
        <AppRoute exact path="/admin/orders" layout={Back} component={OrderList} />
        <AppRoute exact path="/admin/orders/infos" layout={Back} component={InfosOfTheDay} />
        <AppRoute exact path="/admin/orders/:id" layout={Back} component={OrderPage} />
        <AppRoute exact path="/admin/orders/updateOrder/:id" layout={Back} component={UpdateOrder} />
        <AppRoute path="/admin/deliveryNotes" layout={Back} component={DeliveryNotes} />
        <AppRoute path="/admin/billings" layout={Back} component={BillingList} />
        <AppRoute exact path="/admin/discounts/list/:query" layout={Back} component={DiscountList} />
        <AppRoute exact path="/admin/discounts/:id" layout={Back} component={DiscountDetails} />
        <AppRoute exact path="/admin/taxes" layout={Back} component={TaxeList} />
        <AppRoute exact path="/admin/segments" layout={Back} component={SegmentList} />
        <AppRoute exact path="/admin/segments/:id" layout={Back} component={SegmentDetails} />
        <AppRoute exact path="/admin/invitations" layout={Back} component={InvitationsDashboard} />
        <AppRoute exact path="/admin/invitation/:id" layout={Back} component={InvitationDetails} />
        <AppRoute exact path="/admin/statistics" layout={Back} component={EcoStatistics} />
        <AppRoute exact path="/admin/weight" layout={Back} component={WeightList} />
        <AppRoute exact path="/admin/teams" layout={Back} component={TeamShow} />
        <AppRoute exact path="/admin/producers" layout={Back} component={ProducersList} />
        <AppRoute exact path="/admin/banners" layout={Back} component={BannersList} />
        <AppRoute exact path="/admin/pageHome" layout={Back} component={PageHome} />
        <AppRoute exact path="/admin/tags" layout={Back} component={TagList} />

        <AppRoute exact path="/admin/subscribers/:type/management" layout={Back} component={SubscriberList} />

        {/* NOTHING MATCHING - 404 */}
        <AppRoute layout={Front} component={notFound} />
      </Switch>
    </Router>
  );
}

export default App;
