import React from 'react';

import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Button, Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',

    '& > figure': {
      width: '70%',
      margin: '0 auto 1rem auto',

      '& > img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      },
    },

    '& > p': {
      fontWeight: 'bold',

      '& > span': {
        fontWeight: 'normal',
      },
    },
  },
  tags: {
    '& > div': {
      marginRight: '0.5rem',

      '&:last-child': {
        marginRight: '0',
      },
    },
  },
}));

export default function ProducerCard(props) {
  moment.locale('fr');
  const classes = useStyles();

  const { producer, handleDelete, handleUpdate } = props;
  const {
    id,
    photo,
    firstname,
    lastname,
    location,
    sinceWhen,
    sinceWhenBio,
    distinctiveSign,
    production,
    tags,
    description,
    position,
  } = producer;

  return (
    <Grid item xs={4}>
      <Paper className={classes.paper}>
        <figure>
          <img
            src={`${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${photo}`}
            alt={`${firstname} ${lastname}`}
          />
        </figure>
        <p>
          Prénom: <span>{firstname}</span>
        </p>
        <p>
          Nom: <span>{lastname}</span>
        </p>
        <p>
          Position: <span>{position}</span>
        </p>
        <p>
          Lieu de production: <span>{location}</span>
        </p>
        <p>
          Début de collaboration: <span>{moment(sinceWhen).format('DD-MM-YYYY')}</span>
        </p>
        <p>
          Début de production Bio: <span>{moment(sinceWhenBio).format('DD-MM-YYYY')}</span>
        </p>
        <p>
          Signe distinctif: <span>{distinctiveSign}</span>
        </p>
        <p>
          Production: <span>{production}</span>
        </p>
        <p>Tags:</p>
        <div className={classes.tags}>
          {tags.map((tag, idx) => (
            <Chip key={idx} size="small" color="primary" label={tag?.name.split('Tags.')[1]} />
          ))}
        </div>
        <p style={{ marginBottom: 0 }}>Description:</p>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <Button
          style={{ marginBottom: '1rem' }}
          variant="contained"
          color="primary"
          onClick={() => handleUpdate(id)}
        >
          Modifier
        </Button>
        <Button variant="contained" color="secondary" onClick={() => handleDelete(id)}>
          Supprimer
        </Button>
      </Paper>
    </Grid>
  );
}
