import React, { useState, useEffect, useRef } from 'react';

import Cookies from 'js-cookie';
import { useReactToPrint } from 'react-to-print';

import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Paper, Divider, Button, TextField } from '@material-ui/core';

// # COMPONENTS
import DeliveryNotesTable from '../../../components/DeliveryNotes/DeliveryNotesTable';
import DeliveryNotesTableRelay from '../../../components/DeliveryNotes/DeliveryNotesTableRelay';
import DeliveryNotesTableEco from '../../../components/DeliveryNotes/DeliveryNotesTableEco';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  firstLine: {
    '& > div': {
      minWidth: '150px',
      marginRight: '1rem',
    },
  },
  deliveryType: {
    padding: '1rem',
    marginBottom: '1rem',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

class NotesPrint extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: '90%', margin: '0 auto', padding: '0.5rem 0' }}>
        <div>
          <h1>Livraisons à domicile ({this.props.home.length} commandes)</h1>
          {this.props.home.map((elem, idx) => (
            <div
              key={idx}
              style={{
                display: 'block',
                pageBreakAfter: 'always',
                paddindTop: '15px',
              }}
            >
              <DeliveryNotesTable data={elem} />
              <Divider />
            </div>
          ))}
          <h1>Livraisons en point de livraison ({this.props.relay.length} commandes)</h1>
          {this.props.relay.map((el, idx) => (
            <div
              key={idx}
              style={{
                display: 'block',
              }}
            >
              <h4>
                Point de livraison {idx + 1}/{this.props.relay.length}
              </h4>
              <DeliveryNotesTableRelay data={el} />
              <Divider
                style={{
                  marginTop: '1rem',
                  display: 'block',
                  pageBreakAfter: 'always',
                  breakAfter: 'always',
                }}
              />
            </div>
          ))}
          <h1>Livraisons Eco-acteurs ({this.props.eco.length} commandes)</h1>
          {this.props.eco.map((el, idx) => (
            <div
              key={idx}
              style={{
                display: 'block',
              }}
            >
              <h4>
                Invitation {idx + 1}/{this.props.eco.length}
              </h4>
              <DeliveryNotesTableEco data={el} />
              <Divider
                style={{
                  marginTop: '1rem',
                  display: 'block',
                  pageBreakAfter: 'always',
                  breakAfter: 'always',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default function DeliveryNotesList() {
  moment.locale('fr');
  const token = Cookies.get('jwt');
  const classes = useStyles();
  const [state, setState] = useState({
    loading: true,
    date: moment().format('YYYY-MM-DD'),
    data: [],
    tableData: [],
    orders: [],
    sortedOrders: {
      relayOrders: [],
      homeOrders: [],
      ecoOrders: [],
    },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    axiosGetRequest(`/orders/notes/${state.date}`, token)
      .then(async (res) => {
        const { orders } = await res.data.data;

        const relayOrders = filterRelay(orders);
        const ecoOrders = filterEco(orders);
        const homeOrders = orders
          .filter((order) => order.deliveryInfos.deliveryType === 'home' && !order.invitation)
          .sort(
            (a, b) =>
              a.deliveryInfos.deliveryAddress.zipCode * 1 -
              b.deliveryInfos.deliveryAddress.zipCode * 1
          );

        setState((prev) => ({
          ...prev,
          loading: false,
          data: [...orders],
          tableData: [...orders],
          orders: [...orders],
          sortedOrders: {
            relayOrders,
            homeOrders,
            ecoOrders,
          },
        }));
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [state.date, token]);

  const handleGetCsv = () => {
    axiosGetRequest(`/orders/csv/${state.date}`, token)
      .then((res) => {
        const { data } = res.data;
        for (const key in data) {
          const element = data[key];
          const fileURL = URL.createObjectURL(new Blob([element], { type: 'text/csv' }));
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `export-cmd-${key}-${state.date}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const filterRelay = (orders) => {
    // # GET ORDER WITH DELIVERY TYPE SET AT RELAY
    const relay = orders
      .filter((order) => order.deliveryInfos.deliveryType === 'relayPoint')
      .sort(
        (a, b) =>
          a.deliveryInfos.deliveryAddress.zipCode * 1 - b.deliveryInfos.deliveryAddress.zipCode * 1
      );

    let newArray = {};
    relay.forEach((el, idx) => {
      if (newArray.hasOwnProperty(el.deliveryInfos.deliveryAddress.title)) {
        newArray = {
          ...newArray,
          [el.deliveryInfos.deliveryAddress.title]: [
            ...newArray[el.deliveryInfos.deliveryAddress.title],
            el,
          ],
        };
      } else {
        newArray = {
          ...newArray,
          [el.deliveryInfos.deliveryAddress.title]: [el],
        };
      }
    });

    const result = [];
    for (const el in newArray) {
      result.push(newArray[el]);
    }

    return result;
  };

  const filterEco = (orders) => {
    // # GET ORDER WITH DELIVERY TYPE SET AT RELAY
    const invitations = orders
      .filter((order) => order.deliveryInfos.deliveryType === 'home' && order.invitation)
      .sort(
        (a, b) =>
          a.deliveryInfos.deliveryAddress.zipCode * 1 - b.deliveryInfos.deliveryAddress.zipCode * 1
      );

    let newArray = {};
    invitations.forEach((el, idx) => {
      if (newArray.hasOwnProperty(el.invitation.id)) {
        newArray = {
          ...newArray,
          [el.invitation.id]: [...newArray[el.invitation.id], el],
        };
      } else {
        newArray = {
          ...newArray,
          [el.invitation.id]: [el],
        };
      }
    });

    const result = [];
    for (const el in newArray) {
      result.push(newArray[el]);
    }

    return result;
  };

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      date,
    }));
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <NotesPrint
          ref={componentRef}
          home={state.sortedOrders.homeOrders}
          relay={state.sortedOrders.relayOrders}
          eco={state.sortedOrders.ecoOrders}
        />
      </div>
      <div className={classes.flex}>
        <h2>Toutes les bons de livraisons</h2>
        <div>
          <Button variant="contained" color="primary" onClick={handleGetCsv}>
            Télécharger le CSV
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '1rem' }}
            onClick={handlePrint}
          >
            Imprimer les bons
          </Button>
        </div>
      </div>
      <Paper className={classes.paper}>
        <h3>Outils de recherche</h3>
        <div className={classes.firstLine}>
          <TextField
            label="Date de livraison"
            type="date"
            defaultValue={state.date}
            onChange={(e) => handleDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </Paper>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <Paper className={classes.deliveryType}>
          <h3>Livraisons à domicile :</h3>
          {state.sortedOrders.homeOrders.map((elem, idx) => (
            <div key={idx}>
              <DeliveryNotesTable data={elem} />
              <Divider />
            </div>
          ))}
          <Divider />
          <h3>
            Livraisons en point de livraison : ( Total : {state.sortedOrders.relayOrders.length} )
          </h3>
          {state.sortedOrders.relayOrders.map((el, idx) => (
            <div key={idx}>
              <h4>
                Point de livraison {idx + 1}/{state.sortedOrders.relayOrders.length}
              </h4>
              <DeliveryNotesTableRelay data={el} />
              <Divider />
            </div>
          ))}
          <Divider />
          <h3>Livraisons Eco-acteur :</h3>
          {state.sortedOrders.ecoOrders.map((el, idx) => (
            <div key={idx}>
              <h4>
                Invitation {idx + 1}/{state.sortedOrders.ecoOrders.length}
              </h4>
              <DeliveryNotesTableEco data={el} />
              <Divider />
            </div>
          ))}
        </Paper>
      )}
    </>
  );
}
