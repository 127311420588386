import React, {Fragment, useContext, useState, useEffect} from 'react';
import Cookies from "js-cookie";
import queryString from 'query-string';
import Slider from "react-slick";
import {useLocation} from "react-router-dom";

//MATERIAL UI
import {makeStyles} from "@material-ui/core/styles";
import {Hidden, Grid, Container} from "@material-ui/core";

//IMG
import liane from './../../assets/img/liane.png';
import legumes from './../../assets/img/picto/picto_inspi_2.svg';
import cuisinier from './../../assets/img/produits_sains-3.png';
import pain from './../../assets/img/cagette-pain-bio.png';
import sacetbaguette from './../../assets/img/picto/sacetbarquette.svg';
import bags from './../../assets/img/sacs.png';
import zerosac from './../../assets/img/zero_sac_kraft.png';
import card from './../../assets/img/logos/cards.svg';
import yellowBackground from './../../assets/img/backgrounds/home-background2.png';
import puzzle from "../../assets/img/puzzle.png";
import puzzle1 from "../../assets/img/picto/picto_entr_collab1.png";
import poubelle from "../../assets/img/zero_sac_recycle.png";
import livrEcoMobil from "../../assets/img/val_conv_2.png";
import circuitcourt from "../../assets/img/picto/picto_circ_court.png";
import circuitcourt1 from "../../assets/img/circuitcourt.png";
import picVenez from "../../assets/img/picto/picto_venez.png";
import background3 from "../../assets/img/backgrounds/home-background3.png";
import presidentiel from "../../assets/img/picto/presidentiel.png";
import louvre from "../../assets/img/picto/louvre.png";
import loreal from "../../assets/img/logos/loreal.svg";
import lasalleamanger from "../../assets/img/logos/lasalleamanger.png";
import smallLiane from "../../assets/img/backgrounds/smallLiane.png";
import liane2 from "../../assets/img/backgrounds/liane2.png";
import google from "../../assets/img/logos/google.png";
import facebook from "../../assets/img/logos/facebook.png";
import fourStars from "../../assets/img/picto/4stars.png";
import fiveStars from "../../assets/img/picto/5stars.png";
import profil from "../../assets/img/picto/profil.png";
import arrowRight from "../../assets/img/picto/nextArrow.svg";
import arrowLeft from "../../assets/img/picto/prevArrow.svg";

//COMPOSANT
import Hero from './../../components/Front/Hero';
import {axiosGetRequest, axiosPostRequest} from "../../utils/axiosRequests";

//CSS
import "./../../../node_modules/slick-carousel/slick/slick.css"; 
import "./../../../node_modules/slick-carousel/slick/slick-theme.css";
import Meta from "../../utils/metaDescription";

// # CONTEXT
import {CartContext} from "../../contexts/CartContext";
import {UserContext} from "../../contexts/UserContext/UserContext";

const useQuery = () => {
  const { search } = useLocation();
  return queryString.parse(search);
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1440px'
  },
  h3: {
    margin: '2rem 0',
    textAlign: 'left',
    fontSize: '3.8rem',
    '@media screen and (max-width: 960px)': {
      fontSize: '1.8rem !important',
      margin: '1rem 0'
    }
  },
  grid: {
    marginTop: '0px',
    "@media screen and (min-width: 960px)": {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 119%',
      backgroundPositionY: '-120px',
      backgroundPositionX: 'center',
      backgroundImage: `url(${liane})`,
      paddingBottom: '15rem'
    },
  },
  gridItem: {
    width: '50%',
    margin: '0 auto',
    '@media screen and (max-width: 960px)': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: '1rem 0'
    },
    '& > img': {
      '@media screen and (max-width: 960px)': {
        marginRight: '1rem',
        height: 'fit-content'
      }
    },
    '& > div': {
      '& > p:nth-child(2)': {
        fontSize: '0.9rem'
      },
      '@media screen and (max-width: 960px)': {
        textAlign: 'center'
      }
    }
  },
  midTitle: {
    fontWeight: '600',
    color: '#adc00d',
    fontSize: '1.5rem',
    '@media screen and (max-width: 960px)': {
      fontSize: '1rem'
    },
    margin: '1rem 0 0 0'
  },
  img: {
    '@media screen and (max-width: 960px)': {
      width: '100%',
      maxWidth: '150px'
    },
    width: '150px'
  },
  img2: {
    '@media screen and (max-width: 960px)': {
      width: '100%',
      maxWidth: '250px'
    },
    width: '325px',
    margin: 'auto'
    
  },
  img3: {
    '@media screen and (max-width: 960px)': {
      width: '100%',
      maxWidth: '250px'
    },
    alignItems: '',
    width: '100%',
    height: '197px',
    margin: 'auto',
    display: 'Block'
    
  },
  yellowBackground: {
    backgroundImage: `url(${yellowBackground})`,
    backgroundSize: '100% 100%',
    paddingTop: '70px',
    padding: '2rem 0',
    '@media screen and (min-width: 960px)': {
      marginTop: '-10rem',
    },
    marginBottom: '-7rem',
  },
  producers: {
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: '0 1rem 3rem 1rem',
    display: 'flex',
    '& > div': {
      backgroundColor: 'white',
      marginRight: '1rem',
      padding: '1rem',
      borderRadius: '5px',
      width: '200px',
      '& > .producersName': {
        fontWeight: '600',
        color: 'black'
      },
      '& > p': {
        fontWeight: '200'
      },
      '& > button': {
        backgroundColor: '#F05C34',
        alignSelf: 'end',
        border: 'none',
        padding: '0.5rem',
        width: '100%',
        color: 'white',
        borderRadius: '5px',
        fontFamily: 'Bitter',
        fontWeight: '500'
      }
    }
  },
  producersCard: {
    minWidth: '200px',
    '@media screen and (max-width: 960px)': {
      display: 'none'
    }
  },
  producerRound: {
    margin: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > p': {
      color: 'black',
    },
    '@media screen and (min-width: 960px)': {
      display: 'none'
    }
  },
  producersTitle: {
    fontSize: '3rem',
    '@media screen and (max-width: 960px)': {
      fontSize: '2.5rem'
    },
    fontWeight: '600',
    fontFamily: 'Amatic SC',
    color: 'white',
    textAlign: 'left',
    margin: '1rem'
  },
  divDetails: {
    textAlign: 'left',
    display: "flex",
    height: '100%',
    alignItems: 'center',
    width: 'calc(100%/3)',
    margin: '2rem 0',
    '@media screen and (max-width: 960px)': {
      width: '100%',
      '& > img': {
        width: '20%',
        marginRight: '1rem'
      },
      '& > div:nth-child(1)': {
        width:'calc(100%/3)',
        textAlign: 'center',
      },
      '& > div:nth-child(2)': {
        width: 'calc(200%/3)',
        textAlign: 'center',
        '& > p:nth-child(2)': {
          fontSize: '0.9rem'
        }
      }
    },
  },
  divDetailsReverse: {
    '@media screen and (max-width: 960px)': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      '& > div:nth-child(2)': {
        width:'calc(100%/3)'
      },
      '& > div:nth-child(1)': {
        width: 'calc(200%/3)',
        textAlign: 'center',
        '& > p:nth-child(2)': {
          fontSize: '0.9rem'
        }
      }
    },
    '@media screen and (min-width: 960px)': {
      textAlign: 'right',
      '& > div:nth-child(2) > img': {
        marginLeft: '1rem'
      }
    },
  },
  divDetailsBottom: {
    width: '45%',
    '@media screen and (max-width: 960px)': {
      width: '100%',
    }
  },
  flex: {
    '@media screen and (min-width: 960px)': {
      display: 'flex',
    },
    justifyContent: 'space-between',
    width: '100%',
  },
  redMidTitle: {
    color: '#F05C35',
    fontSize: '2rem',
    fontWeight: '600',
    margin: '0',
  },
  bottom: {
    backgroundColor: '#FF6023',
    width: '100%',
    '@media screen and (min-width: 960px)' : {
      padding: '0rem 0 0rem 0',
    }
  },
  reviews: {
    display: 'none',
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
    backgroundColor: '#EEEEEE',
    display: 'flex',
    alignItems: 'center',
    overflow: 'scroll',
    maxWidth: '100vw',
    padding: '1rem',
    '& > div': {
      marginRight: '1rem',
      '& > img': {
        minWidth: '150px',
        margin: '0 2rem'
      },
      '& > div': {
        width: '200px',
        '& > p': {
          color: 'black'
        }
      },
      minWidth: '400px',
      display: 'flex',
      alignItems: 'center'
    }
  },
},
  reviews2: {
    backgroundColor: '#EEEEEE',
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    overflow: 'hidden',
    '& > div': {
      margin: 'auto',
      '& > img': {
        minWidth: '400px',
        margin: 'auto',
      },
      '& > div': {
        width: '850px',
        margin: 'auto',
        '& > p': {
          color: 'black',
        }
      },
      display: 'flex',
      size : '24px',
    },
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
      display: 'none'
    },
  },
  sliderTest: {
    width:'45%',
    height: '150px',
    '@media screen and (max-width: 375px)': {
      width:'100%',
      margin: '5% auto'
    }
  },
  divLiane: {
    '@media screen and (min-width: 960px)': {
      backgroundImage: `url(${liane2})`,
      paddingBottom: '12rem',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'auto 100%',
      paddingTop: '1rem'
    },
  },
  venez: {
    '@media screen and (min-width: 960px)': {
      marginTop: '7rem',
      display: 'block'
    },
    display: 'none'
  },
  backgroundBottom: {
    '@media screen and (min-width: 960px)': {
      marginTop: '-11rem'
    },
    height: '100px',
    backgroundImage: `url(${background3})`,
    backgroundSize: 'cover'
  },
  smallLiane: {
    backgroundImage: `url(${smallLiane})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    height: '200px',
    position: 'relative',
    zIndex: '-3'
  },
  whiteTitle: {
    margin: 0,
    '@media screen and (max-width: 960px)': {
      fontSize: '1.8rem !important'
    },
    textAlign: 'left',
    color: 'white !important'
  },
  bottomImage: {
    height: '15vw',
    maxHeight: '200px',
    overflow: 'hidden',
    '@media screen and (min-width: 960px)': {
      marginTop: '-11rem'
    }
  },
  sliderBottom : {
    '@media screen and (min-width: 835px) and (max-width: 1800px)' : {
      width: '45%'
    },
    '@media screen and (min-width: 0px) and (max-width: 834px)' : {
      display: 'none',
      visibility: 'hidden'

    },
    
  },
  sliderBottom2 : {
    '@media screen and (min-width: 835px) and (max-width: 1800px)' : {
      width: '45%',
    },
    '@media screen and (min-width: 0px) and (max-width: 834px)' : {
      display: 'none',
      visibility: 'hidden'
    },
  },
  sliderBottom3 : {
    visibility: 'hidden',
    '@media screen and (min-width: 835px) and (max-width: 1800px)' : {
      display: 'none',
      visibility: 'hidden'
    },
    '@media screen and (min-width: 0px) and (max-width: 834px)' : {
      visibility: 'visible'
    },
  },
  slider1P : {
    fontSize: '26px',
    '@media screen and (min-width: 835px) and (max-width: 1024px)': {
      fontSize: '20px',
      width: '75%'
    },
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
      fontSize: '12px',
      width: '100%',
    }
  },
  producteur: {
    '@media screen and (min-width: 835px) and (max-width: 1024px)': {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  slider1Div: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent:'center',
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
    }

  },
   slider1Div2: {
    margin: '2% 0 0 2%',
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
    }
   },
   slider1P2: {
    color: 'black',
    fontWeight: 'bold',
    '@media screen and (min-width: 0px) and (max-width: 834px)': {
    }
   }
}))

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div style={{width: '15px', marginLeft: '4%'}}>
      <img src={arrowRight} onClick={onClick} style={{maxWidth: '15px !important'}}/>
    </div>
        
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div style={{width: '15px', marginRight: '4%'}}>
      <img src={arrowLeft} onClick={onClick} style={{maxWidth: '15px'}}/>
    </div>
  );
}

export default function totals() {
  const classes = useStyles();
  const { code } = useQuery();
  const [state, setState] = useState([]);

  const { user } = useContext(UserContext);
  const { setStep, setDrawerCart } = useContext(CartContext);

  useEffect(() => {
    const fetchWithCode = async() => {
      axiosPostRequest('/users/endered-auth', user.token, {code, grant_type: 'authorization_code'}).then((res) => {
        /*
        setState(prev => ({
          ...prev,
          user: res.data.data.updatedUser,
        }))

        */
        console.log(res.data);
      }).catch(err => console.log(err));
      setStep(2);
      setDrawerCart(true);
    };
    if (code) {
      fetchWithCode();
    }
  }, [code]);

  useEffect(() => {
      axiosGetRequest('/producers', user.token).then((res) => {
        setState(res.data.data.producers)
      }).catch(err => console.log(err))
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 200, 
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }},
      {
        breakpoint: 1439,
        settings: {
          speed: 200, 
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }}

      ],
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Fragment>
      <Meta page="homepage" />
      <Hero/>
      <Container maxWidth="lg">
        <Grid container spacing={6} className={classes.grid}>
          <Grid item xs={12}>
            <h3 className={classes.h3}>BIO CULTURE C'EST AVANT TOUT</h3>
          </Grid>
          <Grid item xs={12} md={5}>
              <Grid container spacing={3} alignItems={'center'}>
                <Grid item xs={4}>
                  <img className={classes.img} src={sacetbaguette} alt="sacetbarquette"/>
                </Grid>
                <Grid item xs={8}>
                  <p className={classes.midTitle}>Des Paniers Bio et Locaux</p>
                  <p>Paniers de fuits, de légumes, français, locaux.
                    Des paniers qui s’adaptent aux saisons et qui inspirent votre cuisine</p>
                </Grid>
              </Grid>
          </Grid>
          <Grid style={{padding: 0}} item md={2}></Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={3} alignItems={'center'}>
              <Grid item xs={4}>
                <img className={classes.img} src={legumes} alt="legumes"/>
              </Grid>
              <Grid item xs={8}>
                <p className={classes.midTitle}>À la carte</p>
                <p>Composez votre panier vous-même parmis une large selection des meilleurs produits de saison</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={3} alignItems={'center'}>
              <Grid item xs={4}>
                <img className={classes.img} src={pain} alt="pain"/>
              </Grid>
              <Grid item xs={8}>
                <p className={classes.midTitle}>Pain Bio et local</p>
                <p>Retrouvez l’incontournable pain “BRUT” issues de farines locales et à fermentation lente</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{padding: 0}} item md={2}></Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={3} alignItems={'center'}>
              <Grid item xs={4}>
                <img className={classes.img} src={cuisinier} alt="cuisinier"/>
              </Grid>
              <Grid item xs={8}>
                <p className={classes.midTitle}>Et aussi... des recettes créatives</p>
                <p>Découvrez chaque semaine 3 nouvelles recettes dans vos paniers</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <div className={classes.yellowBackground}>
        <Container>
          <p className={classes.producersTitle}>Nos Producteurs</p>
          <div className={classes.producers} >
            {state.map((producer, idx) => (
                <div key={idx} className={classes.producersCard}>
                  <figure
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${producer.photo})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      margin: '0',
                      width: '100%',
                      height: '180px'
                    }}
                  ></figure>
                  <p className={'producersName'}>{producer.firstname} {producer.lastname}</p>
                  <p dangerouslySetInnerHTML={{__html: producer.distinctiveSign}}/>
                  <button><a style={{color: 'white', alignSelf: 'end'}} href="/nos-producteurs">Découvrir</a></button>
                </div>
            ))}
            {state.map((producer, idx) => (
              <section key={idx} className={classes.producerRound}>
                <figure
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${producer.photo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    margin: '0',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%'
                  }}
                ></figure>
                <p>{producer.firstname} {producer.lastname}</p>
              </section>
            ))}
          </div>
        </Container>
      </div>


      <Container>
        <div className={classes.smallLiane}></div>
        <div style={{marginTop: '-5rem'}}>
          <Grid container spacing={6} alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3} alignItems={'center'} justifyContent={'center'} style={{boxShadow: 'white 60px -16px 15px', backgroundColor: 'white'}}>
                <Grid item xs={4} md={12}>
                  <img className={classes.img} src={bags} alt="bags"/>
                </Grid>
                <Grid item xs={8} md={12}>
                  <p className={classes.midTitle}>Faites vous plaisir !</p>
                  <p>Je choisi mes paniers Bio et les meilleurs produits de saison</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={4} md={12}>
                  <img className={classes.img} src={zerosac} alt="bags"/>
                </Grid>
                <Grid item xs={8} md={12}>
                  <p className={classes.midTitle}>Livraison par nos soins</p>
                  <p>Je suis livré en 24h chez moi ou dans un point relais, gratuitement dès 35 euro d’achat !</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={4} md={12}>
                  <img className={classes.img} src={card} alt="bags"/>
                </Grid>
                <Grid item xs={8} md={12}>
                  <p className={classes.midTitle}>Paiment facile et sécurisé</p>
                  <p>Et aussi en ticket restaurant dématérialisé !</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>


        <div className={classes.divLiane}>
          <h3 className={classes.h3}>Auprès d’une entreprise Responsable...</h3>
          <div className={classes.flex}>
            <div className={classes.divDetails}>
              <div>
                <img className={classes.img} src={livrEcoMobil} alt="Livraison écologique" />
              </div>
              <div>
                <p className={classes.midTitle}>Livraison Écologique</p>
                <p>La rive droite de Paris est livrée à vélo</p>
              </div>
            </div>


            <div className={classes.venez}>
                <img src={picVenez} alt="Venez nous voir" />
              <div>
                <p className={classes.redMidTitle}>Venez nous voir !</p>
                <p>Nos locaux sont à : <br/> <b>Sèvres dans les Hauts-de-Seine</b></p>
              </div>
            </div>


            <div className={`${classes.divDetails} ${classes.divDetailsReverse}`}>
              <div>
                <p className={classes.midTitle}>Zéro déchet</p>
                <p>100% de nos emballages sont réutilisables ou recyclables</p>
                <button className="btn-orange" href="/zero-dechets">
                  En savoir plus
                </button>
              </div>
              <div>
                <Hidden mdUp>
                  <img className={classes.img} src={poubelle} alt="Zéro déchets" />
                </Hidden>
                <Hidden smDown >
                  <img src={poubelle} alt="Zéro déchets" />
                </Hidden>
              </div>

            </div>
          </div>


          <div className={classes.flex}>
            <div className={`${classes.divDetails} ${classes.divDetailsBottom} ${classes.divDetailsReverse}`}>
              <div>
                <p className={classes.midTitle}>Entreprise collaborative</p>
                <p>Découvrez nos valeurs sociales et collaboratives</p>
                <button className="btn-orange" href="/offre-entreprise">
                  En savoir plus
                </button>
              </div>
              <div>
                <Hidden mdUp>
                  <img className={classes.img} src={puzzle1} alt="Collaboration" />
                </Hidden>
                <Hidden smDown >
                  <img className={classes.img} src={puzzle} alt="Collaboration" />
                </Hidden>

              </div>
            </div>


            <div className={`${classes.divDetails} ${classes.divDetailsBottom}`}>
              <div>
                <Hidden mdUp>
                  <img className={classes.img} src={circuitcourt1} alt="Circuit court" />
                </Hidden>
                <Hidden smDown >
                  <img width={{width: '400px'}} src={circuitcourt} alt="Circuit Court" />
                </Hidden>
              </div>
              <div>
                <p className={classes.midTitle}>Circuit court</p>
                <p>Des partenariats durables avec nos producteurs</p>
              </div>
            </div>
          </div>
        </div>
      </Container>


        <div className={classes.bottomImage}>
          <img width={'100%'} src={background3} alt="background"/>
        </div>
        <div className={classes.bottom}>
          <Container>
            <Grid container style={{marginBottom: '2rem'}} alignItems={'center'}>
              <Grid item xs={12} md={4}>
                <h3 className={classes.whiteTitle}>Un lien de confiance établit</h3>
              </Grid>
              <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} sm={6} md={4}>
                <img width={'100px'} src={google} alt="google"/>
                <img src={fiveStars} alt="étoiles"/>
              </Grid>
              <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} sm={6} md={4}>
                <img width={'100px'} src={facebook} alt="facebook"/>
                <img src={fiveStars} alt="étoiles"/>
              </Grid>
            </Grid>
          </Container>
          <div className={classes.reviews2}>
            <Slider {...settings2} className={classes.sliderBottom}>
            <div className={classes.comentaire}>
              <p className={classes.slider1P}>"Les légumes et les fruits sont supers bons. Livraison rapide et parfaite avec des livreurs supers sympas. Mille mercis"</p>
              <div className={classes.slider1Div}>
                <div>
                <img src={profil} alt='producer' className={classes.producteur}/>
                </div>
                <div className={classes.slider1Div2}>
                  <p className={classes.slider1P2}>Cicinato</p>
                  <img src={fiveStars} alt='étoiles' />
                </div>
              </div>
            </div>
            <div>
              <p className={classes.slider1P}>"Hyper pratique. On peut se faire livrer très rapidement. On a le choix dans les produits. La qualité est vraiment appréciable."</p>
              <div className={classes.slider1Div}>
                <div>
                <img src={profil} alt='producer' className={classes.producteur}/>
                </div>
                <div className={classes.slider1Div2}>
                  <p className={classes.slider1P2}>Thomas G.</p>
                  <img src={fiveStars} alt='étoiles' />
                </div>
              </div>
            </div>
            <div>
              <p className={classes.slider1P}>"De beaux fruits et légumes de saison et délicieux ! Une équipe aux petits soins. Merci !"</p>
              <div className={classes.slider1Div}>
                <div>
                <img src={profil} alt='producer' className={classes.producteur}/>
                </div>
                <div className={classes.slider1Div2}>
                  <p className={classes.slider1P2}>Inès B.</p>
                  <img src={fiveStars} alt='étoiles' />
                </div>
              </div>
            </div>
            <div>
              <p className={classes.slider1P}>"Les paniers sont variés. Les fruits et légumes sont excellents. Jamais de problème dans les livraisons. Je recommande les paniers Bio Culture."</p>
              <div className={classes.slider1Div}>
                <div>
                <img src={profil} alt='producer' className={classes.producteur}/>
                </div>
                <div className={classes.slider1Div2}>
                  <p className={classes.slider1P2}>Hélène G.</p>
                  <img src={fiveStars} alt='étoiles' />
                </div>
              </div>
            </div>
        </Slider>
          
        <Slider {...settings} className={classes.sliderBottom2}>
          <div>
          <img className={classes.img2} src={presidentiel} alt="presidentiel"/>
          </div>
          <div style={{display: 'inline-block'}}>
          <div style={{display: 'table-cell', textAlign: 'center', verticalAlign: 'middle', width: '350px', height: '325px',}}>
          <img className={classes.img3} src={louvre} alt="presidentiel" />
          </div>
          </div>
          <div style={{display: 'inline-block'}}>
          <div style={{display: 'table-cell', textAlign: 'center', verticalAlign: 'middle', width: '450px', height: '325px',}}>
          <img style={{maxWidth: '100%'}} src={loreal} alt="presidentiel"/>
          </div>
          </div>
          <div style={{display: 'inline-block'}}>
          <div style={{display: 'table-cell', textAlign: 'center', verticalAlign: 'middle', width: '450px', height: '325px',}}>
          <img className={classes.img2} src={lasalleamanger} alt="presidentiel" style={{width:'55%'}}/>
          </div>
          </div>
        </Slider>
          </div>
          <div className={classes.reviews}>
          <div>
              <img className={classes.img} src={presidentiel} alt="presidentiel"/>
              <div>
                <p>
                  <em>"Les légumes et les fruits sont supers bons. Livraison rapide et parfaite avec des livreurs supers sympas. Mille mercis"</em>
                </p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '35%'}}>
                    <img width={'100%'} src={profil} alt="producer"/>
                  </div>
                  <div style={{width: '65%'}}>
                    <b style={{color: 'black'}}>Cicinato</b>
                    <img width={'100%'} src={fiveStars} alt="étoiles"/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img className={classes.img} src={louvre} alt="presidentiel"/>
              <div>
                <p>
                  <em>"Hyper pratique. On peut se faire livrer très rapidement. On a le choix dans les produits. La qualité est vraiment appréciable."</em>
                </p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '35%'}}>
                    <img width={'100%'} src={profil} alt="producer"/>
                  </div>
                  <div style={{width: '65%'}}>
                    <b style={{color: 'black'}}>Thomas G.</b>
                    <img width={'100%'} src={fiveStars} alt="étoiles"/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img className={classes.img} src={loreal} alt="presidentiel"/>
              <div>
                <p>
                  <em>"De beaux fruits et légumes de saison et délicieux ! Une équipe aux petits soins. Merci !"</em>
                </p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '35%'}}>
                    <img width={'100%'} src={profil} alt="producer"/>
                  </div>
                  <div style={{width: '65%'}}>
                    <b style={{color: 'black'}}>Inès B.</b>
                    <img width={'100%'} src={fiveStars} alt="étoiles"/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img className={classes.img} src={lasalleamanger} alt="loreal"/>
              <div>
                <p>
                  <em>"Les paniers sont variés. Les fruits et légumes sont excellents. Jamais de problème dans les livraisons. Je recommande les paniers Bio Culture."</em>
                </p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '35%'}}>
                    <img width={'100%'} src={profil} alt="producer"/>
                  </div>
                  <div style={{width: '65%'}}>
                    <b style={{color: 'black'}}>Hélène G.</b>
                    <img width={'100%'} src={fiveStars} alt="étoiles"/>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>

    </Fragment>
  );
}