import React from 'react';

// # MATERIAL UI
import Button from '@material-ui/core/Button';

// # UTILS
import Meta from '../../../utils/metaDescription';

// # STYLE
import '../../../assets/css/styleecopoints.css';

// # IMAGES
import img1 from '../../../assets/img/sacs.png';
import img2 from '../../../assets/img/co2.png';
import img3 from '../../../assets/img/eco.png';
import img4 from '../../../assets/img/client1.png';
import img5 from '../../../assets/img/client2.png';
import img6 from '../../../assets/img/client3.png';
import img7 from '../../../assets/img/client4.png';

export default function EcoPoints() {
  return (
    <>
      <Meta page="ecopoint" />

      <div id="ecopoints">
        <div className="container">
          <h1>éco-points et témoignages</h1>
          <h3>sur chaque achat groupé, 10% de la somme vous est redistribuée en éco-points</h3>
          <p>Par exemple</p>
          <div className="schema">
            <figure>
              <h4>vous réunissez pour 320&euro; de commande</h4>
            </figure>
            <h3>{'>'}</h3>
            <figure>
              <h4>vous recevez 32 éco-points, équivalents à 32€</h4>
            </figure>
            <h3>{'>'}</h3>
            <figure>
              <h4>faites-vous plaisir ou faites un don à une banque alimentaire</h4>
            </figure>
          </div>
          <p>Amusez vous, partagez ...</p>
          <div className="schema">
            <figure>
              <img src={img1} alt="sacs" />
              <p>Une commande groupée</p>
            </figure>
            <h3 id="egal">=</h3>
            <figure>
              <img src={img2} alt="sacs" />
              <p>Moins de CO2</p>
            </figure>
            <h3 id="egal">=</h3>
            <figure>
              <img src={img3} alt="sacs" />
              <p>Des éco-points</p>
            </figure>
          </div>
          <Button className="btn-orange" href="/eco-acteur">
            Devenir éco-acteur
          </Button>
          <h3>témoignages</h3>
          <div className="row">
            <img src={img4} alt="client1" />
            <div>
              <p>
                Je prend 5 mn de mon temps tous les lundis pour lancer mon invitation aux collègues
                de la société. Bio Culture me livre le jeudi matin, et les consom’acteurs récupèrent
                leur commande entre 14h et 15h dans mon bureau (Ou celui de ma collègue quand je ne
                peux pas). Grâce aux Eco-Points, nous pouvons bénéficier d’une corbeille de fruits
                bio Chaque semaine livrée avec nos paniers. Le tout financé par nos achats à tous !
              </p>
              <p className="client">
                <b>Lorène</b> <br />
                <em>Assistante de direction</em>{' '}
              </p>
            </div>
          </div>
          <div className="row">
            <img src={img5} alt="client2" />
            <div>
              <p>
                Depuis 6 ans je commande chez Bio Culture pour leurs produits et leur service. J’ai
                testé une première distribution de paniers, surtout pour rendre service à la petite
                mamie d’à côté de chez moi qui a des problèmes de mobilité. Nous sommes désormais 6
                foyers à nous faire livrer chaque semaine ! Et mon action est récompensée, 1
                commande sur 3 est gratuite grâce aux Eco-Points.
              </p>
              <p className="client">
                <b>Pierre</b> <br />
                <em>Client historique de Bioculture</em>{' '}
              </p>
            </div>
          </div>
          <div className="row">
            <img src={img6} alt="client3" />
            <div>
              <p>
                J’organise un achat groupé à la crèche chaque vendredi. Les parents récupèrent leur
                panier en même temps que leur enfant. On leur a facilité la vie et on a fait évoluer
                leurs habitudes alimentaires. Mes collègues en profitent aussi. Nous utilisons nos
                Eco-Points pour être livré chaque semaine d’une cagette de fruits Bio pour nos
                petits loups. Ainsi, l’achat des parents profite directement aux enfants !
              </p>
              <p className="client">
                {' '}
                <b>Marianne</b> <br />
                <em>Directrice de crèche</em>{' '}
              </p>
            </div>
          </div>
          <div className="row">
            <img src={img7} alt="client4" />
            <div>
              <p>
                L’achat responsable, les bons produits, le respect de la terre ont toujours fait
                partie de mes convictions. Je partage désormais ces valeurs avec mon entourage : Mes
                amis, mes voisins… A chaque distribution, nous échangeons sur les recettes de la
                semaine précédente, sur les nouvelles saveurs. En plus de mes Eco-Points, je reçois
                des nouveaux paniers “découvertes” de Bio Culture, l’occasion d’en faire profiter
                mon entourage.
              </p>
              <p className="client">
                <b>Sylvie</b> <br />
                <em>Femme de son temps - Maman de 2 enfants</em>{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
