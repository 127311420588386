import React, {useState, useEffect, Fragment} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Paper,
  Divider,
  IconButton, InputAdornment, Checkbox, FormControlLabel,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # COMPONENTS
import GooglePlacesInput from '../../../components/Basics/GooglePlacesInput';
import AddSchedules from '../../../components/Relay/AddSchedules';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(3),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  marginB: {
    marginBottom: '1rem',
  },
  flex: {
    display: 'flex',
    '&>*': {
      marginRight: '1rem',
    },
  },
  addClosure: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>div:nth-child(2), &>div:nth-child(4), &>button': {
      margin: '1rem 1rem 1rem 0.2rem',
    },
    '&>button': {
      minWidth: '40px',
    },
    '&>button>span>.MuiButton-startIcon': {
      marginLeft: 0,
    },
  },
  button: {
    margin: '0 0 1rem 1rem',
    padding: 0,
    height: '40px',
    '&>span.MuiButton-label>span.MuiButton-startIcon': {
      marginRight: 0,
    },
  },
}));

export default function CreateRelay() {
  moment.locale('fr');
  const classes = useStyles();
  const token = Cookies.get('jwt');
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    loading: true,
    segments: [],
    isDiscount: true,
    newSchedule: {
      day: 'lundi',
      startTime: '15:30',
      endTime: '17:30',
      depositStartTime: '14:30',
      depositEndTime: '16:30',
    },
    newClosure: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().add(1, 'week').format('YYYY-MM-DD'),
    },
    newRelay: {
      name: null,
      description: '',
      phone: '',
      type: 'relay',
      siret: '',
      code: '',
      discount: {
        value: null
      },
      webSite: '',
      segment: '',
      city: {
        name: '',
        zipCode: '',
      },
      address: {
        address: '',
        country: 'France',
        complement: '',
        comment: '',
      },
      schedules: [],
      closure: [],
      isActive: true,
    },
  });

  // * FORMAT SCHEDULE
  const formatSchedule = (schedule) => schedule.replace(':', 'H');

  // * FECTCH ALL ACTIVES SEGMENTS
  useEffect(() => {
    axiosGetRequest('/segments/?isActive=true', token)
      .then((res) => {
        const { segments } = res.data.data;
        setState((prev) => ({
          ...prev,
          loading: false,
          segments,
        }));
      });
  }, [token]);

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      newRelay: {
        ...prev.newRelay,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeDiscount = (e) => {
    e.persist();

    setState((prev) => ({
      ...prev,
      newRelay: {
        ...prev.newRelay,
        discount: {
          ...prev.newRelay.address,
          value: e.target.value,
          reduction: 'percent',
        },
      },
    }));

  }

  const handleChangeAddress = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      newRelay: {
        ...prev.newRelay,
        address: {
          ...prev.newRelay.address,
          [name]: value,
        },
      },
    }));
  };

  const handleNewSchedule = (event) => {
    event.persist();

    setState((prev) => ({
      ...prev,
      newSchedule: {
        ...prev.newSchedule,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleAddNewSchedule = () => {
    const { newSchedule } = state;

    const formatedSchedule = {
      ...newSchedule,
      startTime: formatSchedule(newSchedule.startTime),
      endTime: formatSchedule(newSchedule.endTime),
      depositStartTime: formatSchedule(newSchedule.depositStartTime),
      depositEndTime: formatSchedule(newSchedule.depositEndTime),
    };

    // INFO : CHECK DUPLICATES
    if (!state.newRelay.schedules.some((schedule) => JSON.stringify(schedule) === JSON.stringify(formatedSchedule))) {
      setState((prev) => ({
        ...prev,
        newRelay: {
          ...prev.newRelay,
          schedules: [...prev.newRelay.schedules, { ...formatedSchedule }],
        },
      }));
    }
  };

  const handleRemoveSchedule = (event) => {
    event.persist();
    const idx = event.currentTarget.dataset.index * 1;
    const newSchedules = state.newRelay.schedules.filter((el, index) => index !== idx);
    setState((prev) => ({
      ...prev,
      newRelay: {
        ...prev.newRelay,
        schedules: newSchedules,
      },
    }));
  };

  const handleNewClosure = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      newClosure: {
        ...prev.newClosure,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleAddNewClosure = () => {
    if (!state.newRelay.closure.includes(state.newClosure)) {
      setState((prev) => ({
        ...prev,
        newRelay: {
          ...prev.newRelay,
          closure: [...prev.newRelay.closure, { ...state.newClosure }],
        },
      }));
    }
  };

  const handleRemoveClosure = (event) => {
    event.persist();
    const idx = event.currentTarget.dataset.index * 1;
    const newClosures = state.newRelay.closure.filter((el, index) => index !== idx);
    setState((prev) => ({
      ...prev,
      newRelay: {
        ...prev.newRelay,
        closure: newClosures,
      },
    }));
  };

  const handleChangeCheckbox = () => {
    setState((prev) => ({
      ...prev,
      isDiscount: !prev.isDiscount,
    }));
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    const token = Cookies.get('jwt');

    await axios
      .post(`${process.env.REACT_APP_API}/relayPoints`, state.newRelay, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        window.location = `/admin/relayPoints/${res.data.data.relayPoint._id}`;
      })
      .catch((err) => {
        enqueueSnackbar(`Une problème est survenu`, {
          variant: 'error',
        });
      });
  };

  // * UPDATE ADDRESS
  const getValue = (address_object) => {
    setState((prev) => ({
      ...prev,
      newRelay: {
        ...prev.newRelay,
        city: {
          ...prev.newRelay.city,
          name: address_object.city,
          zipCode: address_object.zipcode,
        },
        address: {
          ...prev.newRelay.address,
          address: `${address_object.number} ${address_object.street}`,
        },
      },
    }));
  };

  return (
    <>
      <div className={classes.container}>
        <h2>Nouveau point de livraison</h2>
      </div>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <h3>Informations :</h3>
              <form onSubmit={handleCreate}>
                <div>
                  <TextField
                    className={classes.marginB}
                    label="Nom"
                    type="text"
                    name="name"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                  <TextField
                    className={classes.marginB}
                    label="Description"
                    type="text"
                    name="description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    onChange={handleChange}
                  />
                  <div className={classes.flex}>
                    <FormControl variant="outlined" className={classes.marginB}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        native
                        label="Status"
                        fullWidth
                        name="isActive"
                        defaultValue={state.newRelay.isActive}
                        onChange={handleChange}
                      >
                        <option value>Actif</option>
                        <option value={false}>Inactif</option>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.marginB}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        native
                        label="Type"
                        fullWidth
                        name="type"
                        defaultValue={state.newRelay.type}
                        onChange={handleChange}
                      >
                        <option value="relay">Point relais</option>
                        <option value="entreprise">Entreprise</option>
                        <option value="eco">Eco-acteur</option>
                        <option value="conciergerie">Conciergerie</option>
                      </Select>
                    </FormControl>
                    {state.newRelay.type === 'entreprise' && (
                      <TextField
                        className={classes.marginB}
                        label="Siret"
                        type="text"
                        variant="outlined"
                        name="siret"
                        onChange={handleChange}
                      />
                    )}
                  </div>
                  <div className={classes.flex}>
                    <div>
                      <TextField
                          className={classes.marginB}
                          label="Numéro de téléphone"
                          type="number"
                          variant="outlined"
                          name="phone"
                          onChange={handleChange}
                      />
                      <TextField
                          className={classes.marginB}
                          label="Adresse mail"
                          type="text"
                          variant="outlined"
                          name="email"
                          onChange={handleChange}
                      />
                    </div>
                    <TextField
                        fullWidth
                      className={classes.marginB}
                      label="Site web"
                      name="webSite"
                      type="text"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </div>
                  {state.newRelay.type === 'entreprise' && (
                      <Fragment>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={state.isDiscount}
                                  onChange={handleChangeCheckbox}
                                  name="isDiscount"
                                  color="secondary"
                              />
                            }
                            label="L'entreprise possède un code discount"
                        />
                            <div className={classes.flex}>
                              <TextField
                                  className={classes.marginB}
                                  fullWidth
                                  label="Code point de livraison"
                                  type="text"
                                  name="code"
                                  variant="outlined"
                                  defaultValue={state.newRelay.code}
                                  onChange={handleChange}
                              />
                              {state.isDiscount && (
                              <TextField
                                  className={classes.transition}
                                  type="number"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  defaultValue={state.newRelay.discount.value}
                                  onChange={handleChangeDiscount}
                                  variant="outlined"
                              />)}
                            </div>
                          </Fragment>
                    )
                  }
                  <div className={classes.flex}>
                    <FormControl variant="outlined" style={{ maxWidth: '250px' }}>
                      <InputLabel>Segment</InputLabel>
                      <Select native label="Segment" onChange={handleChange} name="segment">
                        <option value="" />
                        {state.segments
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((el, index) => (
                                <option key={index} value={el._id}>
                                  {el.name}
                                </option>
                            ))}
                      </Select>
                    </FormControl>
                  </div>
                  <Divider style={{marginTop: '1rem'}} variant="middle" />
                  <h3>Livraison :</h3>
                  <GooglePlacesInput label="Adresse de livraison" getValue={getValue} />
                  <TextField
                    className={classes.marginB}
                    label="Complément d'adresse"
                    type="text"
                    variant="outlined"
                    name="complement"
                    onChange={handleChangeAddress}
                    fullWidth
                  />
                  <TextField
                    className={classes.marginB}
                    label="Commentaire pour livreur"
                    type="text"
                    variant="outlined"
                    name="comment"
                    onChange={handleChangeAddress}
                    fullWidth
                  />
                </div>
                <Button variant="contained" color="primary" type="submit">
                  Créer
                </Button>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <AddSchedules
              editing
              handleRemoveSchedule={handleRemoveSchedule}
              handleNewSchedule={handleNewSchedule}
              handleAddNewSchedule={handleAddNewSchedule}
              newSchedule={state.newSchedule}
              updateRelaySchedules={state.newRelay.schedules}
              relaySchedules={state.newRelay.schedules}
            />
            <Paper className={classes.paper}>
              <h3>Dates de fermeture :</h3>
              {state.newRelay.closure.length > 0 ? (
                <ul>
                  {state.newRelay.closure.map((el, index) => (
                    <li key={index}>
                      Du {moment(el.start).format('DD-MM-YYYY')} au{' '}
                      {moment(el.end).format('DD-MM-YYYY')}
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        data-index={index}
                        onClick={handleRemoveClosure}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              ) : (
                <div>
                  <p>Aucune date renseigné</p>
                </div>
              )}
              <Divider variant="middle" className={classes.marginB} />
              <div className={classes.addClosure}>
                <p>Du</p>
                <TextField
                  className={classes.marginB}
                  label="Date de debut"
                  type="date"
                  variant="outlined"
                  name="start"
                  defaultValue={state.newClosure.start}
                  onChange={handleNewClosure}
                />
                <p>Au</p>
                <TextField
                  className={classes.marginB}
                  label="Date de fin"
                  type="date"
                  variant="outlined"
                  name="end"
                  defaultValue={state.newClosure.end}
                  onChange={handleNewClosure}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddNewClosure}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
