import React, { createContext, useReducer } from 'react';
import { CartReducer, sumItems } from './CartReducer';

export const CartContext = createContext();

const itemStorage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

const deliveryStorage = localStorage.getItem('delivery')
    ? JSON.parse(localStorage.getItem('delivery'))
    : {
          deliveryAddress: {
              title: '',
              address: '',
              zipCode: '',
              city: '',
              country: '',
              complement: '',
          },
          deliveryBillingAddress: {
              address: '',
              zipCode: '',
              city: '',
              country: '',
          },
          deliveryType: '',
          deliverySubType: '',
          deliveryId: '',
          deliveryDate: '',
          deliverySchedule: '',
          depositSchedule: '',
          deliveryService: '',
      };

const discountStorage = localStorage.getItem('discount') ? JSON.parse(localStorage.getItem('discount')) : {};
const companyDiscountCodeStorage = localStorage.getItem('companyDiscountCode')
    ? JSON.parse(localStorage.getItem('companyDiscountCode'))
    : null;

const initialState = {
    cartItems: itemStorage,
    ...sumItems(itemStorage),
    companyDiscountCode: companyDiscountCodeStorage,
    drawer: false,
    drawerCart: false,
    step: 0,
    discount: discountStorage,
    deliveryInfos: deliveryStorage,
};

function CartContextProvider({ children }) {
    const [state, dispatch] = useReducer(CartReducer, initialState);

    const increase = (payload) => {
        dispatch({ type: 'INCREASE', payload });
    };

    const decrease = (payload) => {
        dispatch({ type: 'DECREASE', payload });
    };

    const addProduct = (payload) => {
        dispatch({ type: 'ADD_ITEM', payload });
    };

    const setProducts = (payload) => {
        dispatch({ type: 'SET_PRODUCTS', payload });
    };

    const removeProduct = (payload) => {
        dispatch({ type: 'REMOVE_ITEM', payload });
    };

    const clearCart = () => {
        dispatch({ type: 'CLEAR' });
    };

    const setDiscount = (payload) => {
        dispatch({ type: 'SET_DISCOUNT', payload });
    };

    const resetDiscount = (payload) => {
        dispatch({ type: 'RESET_DISCOUNT', payload });
    };

    const setEnterpriseDiscountCode = (payload) => {
        dispatch({ type: 'SET_ENTERPRISE_DISCOUNT_CODE', payload });
    };

    const setDrawer = (payload) => {
        dispatch({ type: 'SET_DRAWER', payload });
    };

    const setDrawerCart = (payload) => {
        dispatch({ type: 'SET_DRAWER_CART', payload });
    };

    const setStep = (payload) => {
        dispatch({ type: 'SET_STEP', payload });
    };

    const setZipCode = (payload) => {
        dispatch({ type: 'SET_ZIPCODE', payload });
    };

    const setDeliveryInfos = (payload) => {
        dispatch({ type: 'SET_DELIVERY_INFOS', payload });
    };

    const setCompanyDiscount = (payload) => {
        dispatch({ type: 'SET_COMPANY_DISCOUNT', payload });
    };

    const setDeliveryAddress = (payload) => {
        dispatch({ type: 'SET_DELIVERY_ADDRESS', payload });
    };

    const setDeliveryBillingAddress = (payload) => {
        dispatch({ type: 'SET_DELIVERY_BILLING_ADDRESS', payload });
    };

    const contextValues = {
        removeProduct,
        addProduct,
        setProducts,
        increase,
        decrease,
        clearCart,
        setDiscount,
        resetDiscount,
        setDrawer,
        setDrawerCart,
        setStep,
        setZipCode,
        setDeliveryInfos,
        setDeliveryAddress,
        setCompanyDiscount,
        setDeliveryBillingAddress,
        setEnterpriseDiscountCode,
        ...state,
    };

    return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
}

export default CartContextProvider;
