import React, { useState, useEffect, Fragment } from 'react';
import {
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

import Cookies from 'js-cookie';
import axios from 'axios';
import DiscountList from './DiscountList';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiOutlinedInput-root': {
      marginBottom: '1rem',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateDiscount(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const token = Cookies.get('jwt');

  const [state, setState] = useState({
    id: props.id,
    newDiscount: {
      code: 'BIO-',
      codeValue: 0,
      codeType: 'flat',
    },
    discounts: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/discounts?owner=${state.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setState((prev) => ({
            ...prev,
            discounts: res.data.data.discounts,
          }));
        })
        .catch((err) => {
          console.log(err.response);
        });

      await axios
        .get(`${process.env.REACT_APP_API}/discounts/nextOne`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setState((prev) => ({
            ...prev,
            newDiscount: {
              ...prev.newDiscount,
              code: res.data.data,
            },
          }));
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    fetchData();
  }, [state.id, token]);

  const handleCreate = async (e) => {
    e.preventDefault();

    if (state.newDiscount.codeValue <= 0) {
      enqueueSnackbar('Veuiller une valeur supérieur à 0 pour le montant', {
        variant: 'error',
      });
    } else {
      const discount = {
        name: state.newDiscount.code,
        value: state.newDiscount.codeValue * 1,
        reduction: state.newDiscount.codeType,
        owner: props.id,
      };

      await axios
        .post(`${process.env.REACT_APP_API}/discounts`, discount, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { discount } = res.data.data;
          setState((prev) => ({
            ...prev,
            discounts: [...prev.discounts, discount],
          }));
          enqueueSnackbar(`Le bon d'achat a bien été pris en compte`, {
            variant: 'success',
          });
        })
        .catch((err) => {
          const { code } = err.response.data.error;

          if (code === 11000) {
            enqueueSnackbar(`Ce bon d'achat existe déjà`, {
              variant: 'error',
            });
          }
        });
    }
  };

  const handleChange = (event) => {
    event.persist();

    setState((prev) => ({
      ...prev,
      newDiscount: {
        ...prev.newDiscount,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <>
      <Paper className={classes.paper}>
        <h2>Créer un bon de réduction</h2>
        <form className={classes.form} onSubmit={handleCreate}>
          <TextField
            label="Code"
            name="code"
            type="text"
            variant="outlined"
            value={state.newDiscount.code}
            onChange={handleChange}
          />
          <div className={classes.flex}>
            <TextField
              className={classes.marginR}
              label="Montant Payé"
              name="codeValue"
              type="number"
              variant="outlined"
              value={state.newDiscount.codeValue}
              onChange={handleChange}
            />
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
              <Select
                label="Type"
                name="codeType"
                value={state.newDiscount.codeType}
                onChange={handleChange}
              >
                <MenuItem value="percent">%</MenuItem>
                <MenuItem value="flat">€</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button variant="contained" color="primary" type="submit">
            Valider
          </Button>
        </form>
      </Paper>
      <DiscountList discounts={state.discounts} />
    </>
  );
}
