import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import moment from 'moment';
import 'moment/locale/fr';

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';

import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateDiscountModal(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  moment.locale('fr');

  const token = Cookies.get('jwt');

  const nextYear = moment().add(1, 'year').format('YYYY-MM-DD');
  const [state, setState] = useState({
    open: false,
    discount: {
      name: `BIO-`,
      value: 10,
      reduction: 'flat',
      count: 100,
      expireAt: nextYear,
      isActive: true,
      limited: true,
      minAmount: 0,
    },
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/discounts/nextOne`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          discount: {
            ...prev.discount,
            name: res.data.data,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleOpen = (bool) => {
    setState((prev) => ({
      ...prev,
      open: bool,
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      discount: {
        ...prev.discount,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeNumb = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      discount: {
        ...prev.discount,
        [event.target.name]: event.target.value * 1,
      },
    }));
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    await axios
      .post(`${process.env.REACT_APP_API}/discounts`, state.discount, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handleOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        const { code } = err.response.data.error;
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
        if (code === 11000) {
          enqueueSnackbar('Ce bon de réduction existe déjà', {
            variant: 'error',
          });
        }
      });
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={(e) => handleOpen(true)}
        startIcon={<AddIcon />}
      >
        Nouveau
      </Button>
      <Modal
        className={classes.modal}
        open={state.open}
        onClose={(e) => handleOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={state.open}>
          <div className={classes.paper} style={{ maxWidth: '470px' }}>
            <h2>Création d'un bon d'achat</h2>
            <form className={classes.form} onSubmit={handleCreate}>
              <div className={classes.flex}>
                <TextField
                  className={classes.marginR}
                  label="Code"
                  type="text"
                  name="name"
                  defaultValue={state.discount.name}
                  variant="outlined"
                  onChange={handleChange}
                />
                <FormControl variant="outlined" className={classes.marginR}>
                  <InputLabel>Actif</InputLabel>
                  <Select
                    native
                    label="isActif"
                    defaultValue={state.discount.isActive}
                    name="isActive"
                    onChange={handleChange}
                  >
                    <option value>Actif</option>
                    <option value={false}>Inactif</option>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.marginB}>
                  <InputLabel>Limité</InputLabel>
                  <Select
                    native
                    label="Limité"
                    defaultValue={state.discount.limited}
                    name="limited"
                    onChange={handleChange}
                  >
                    <option value>Limité</option>
                    <option value={false}>Illimité</option>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.flex}>
                <TextField
                  className={classes.marginR}
                  label="Réduction"
                  type="number"
                  variant="outlined"
                  name="value"
                  defaultValue={state.discount.value}
                  onChange={handleChangeNumb}
                />
                <FormControl variant="outlined" className={classes.marginR}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    native
                    defaultValue={state.discount.reduction}
                    label="Type"
                    name="reduction"
                    onChange={handleChange}
                  >
                    <option value="percent">%</option>
                    <option value="flat">€</option>
                  </Select>
                </FormControl>
                <TextField
                  className={classes.marginB}
                  label="Montant minimum"
                  type="number"
                  variant="outlined"
                  name="minAmount"
                  defaultValue={state.discount.minAmount}
                  onChange={handleChangeNumb}
                />
              </div>
              <TextField
                className={classes.marginR}
                id="count"
                label="Utilisations disponibles"
                type="number"
                variant="outlined"
                name="count"
                onChange={handleChangeNumb}
                defaultValue={state.discount.count}
              />
              <TextField
                className={classes.marginB}
                id="date"
                label="Date limite"
                type="date"
                variant="outlined"
                value={state.discount.expireAt}
                name="expireAt"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Créer
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
