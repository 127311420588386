import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles, IconButton, Drawer, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',

    '& h2': {
      textAlign: 'center',
      color: '#f05c34',
    },
  },
  fullList: {
    width: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexShrink: 0,
    fontFamily: 'Bitter',
    textTransform: 'uppercase',
    color: '#7c7c7c',
  },
  a: {
    fontFamily: 'Bitter',
    color: '#7c7c7c',
  },
  a_secondary: {
    fontFamily: 'Amatic SC',
    color: 'white',
  },
  backGround: {
    backgroundColor: '#ECECEC',
  },
  cadre: {
    backgroundColor: 'green',
  },
  close: {
    color: 'white',
  },
  blanc: {
    color: 'white',
  },
  padding: {
    padding: '0 1rem 0 1rem',
  },
}));

export default function Menu() {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const categories = await axios
        .get(`${process.env.REACT_APP_API}/categories/?isActive=true`)
        .catch((err) => {
          console.log(err);
        });
      setData(categories.data.data.categories);
      setLoading((prev) => !prev);
    };
    fetchData();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer(open);
  };

  const list = (anchor) => (
    <div className="ecocadre">
      <div className={classes.padding}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={5}>
            <h2 className={classes.blanc}>Menu</h2>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="buttonprofil" aria-label="menu" onClick={toggleDrawer(false)}>
              <CloseIcon className={classes.close} fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </div>

      <ul className="menu">
        <ul>
          <li>
            <a className={classes.a} href="/eco-valeurs">
              Nos valeurs
            </a>
          </li>
          <li>
            <a className={classes.a} href="/eco-productors">
              Nos producteurs
            </a>
          </li>
          <li>
            <a className={classes.a} href="/eco-products">
              Nos produits
            </a>
          </li>
          <li>
            <a className={classes.a} href="/eco-zero-dechets">
              Zéro déchet
            </a>
          </li>
        </ul>
      </ul>
      <ul className="menu-secondaire">
        {loading
          ? data.map((el, index) => (
              <li key={index}>
                <a className={classes.a_secondary} href={`/catalogue/${el.slug}`}>
                  {el.name}
                </a>
              </li>
            ))
          : null}
      </ul>
    </div>
  );

  return (
    <div id="menu">
      <IconButton className="buttonprofil" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
        {list('right')}
      </Drawer>
    </div>
  );
}
