import React, { Fragment, PureComponent, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';

// COMPONENTS

// REACT CHART
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

// MATERIAL UI
import { MonetizationOnRounded, ShoppingCartRounded, Mail } from '@material-ui/icons';
import { Button, Grid, Paper, TextField, Chip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import DateFilter from '../../../components/DateFilter';
import StatsRecap from '../../../components/Stats/StatsRecap';

// UTILS
import currencyFormater from '../../../utils/currencyFormater';
import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';
import lastMonths from '../../../utils/lastMonths';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '1rem',
        margin: '1rem 0',
        '& > h2': {
            marginTop: '0',
        },
    },
}));

function EcoGraph(props) {
    const { user } = props;
    const classes = useStyles();

    const capitalize = (name) => {
        const splitName = name.split(' ');
        const capitalizedName = splitName.map(
            (name) => name.toLowerCase().charAt(0).toUpperCase() + name.slice(1).toLowerCase()
        );
        return capitalizedName.join(' ');
    };

    const orderLen = () => user.invitations.reduce((acc, curr) => acc + curr.orders.ordersLen, 0);

    const totalOrder = () => user.invitations.reduce((acc, curr) => acc + curr.orders.totalTtc, 0);

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, payload } = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#f05c34" transform="rotate(-35)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    return (
        <Paper className={classes.paper}>
            <h2>{capitalize(user.ecoInfos.fullname)}</h2>

            <StatsRecap
                loading={false}
                datas={[
                    {
                        name: 'Total Payé',
                        picto: <ShoppingCartRounded />,
                        value: currencyFormater(totalOrder()),
                    },
                    {
                        name: 'Nombre de commandes',
                        picto: <MonetizationOnRounded />,
                        value: orderLen(),
                    },
                    {
                        name: "Nombre d'invitations",
                        picto: <Mail />,
                        value: user.invitations.length,
                    },
                ]}
            />

            <LineChart
                width={1100}
                height={400}
                data={user.invitations.sort((a, b) =>
                    moment(a.invitationInfos.deliveryInfos.deliveryDate).diff(
                        moment(b.invitationInfos.deliveryInfos.deliveryDate)
                    )
                )}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 60,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="invitationInfos.deliveryInfos.deliveryDate"
                    name="Date de livraison"
                    interval={0}
                    tick={<CustomizedAxisTick />}
                >
                    <Label value="Date de livraison de l'invitation" offset={-50} position="insideBottom" />
                </XAxis>
                <YAxis
                    dataKey="orders.totalTtc"
                    fill="f05c34"
                    label={{
                        value: "Montant total de l'invitation",
                        angle: -90,
                        position: 'insideLeft',
                        viewBox: { x: 30, y: 50, width: '100%', height: 50 },
                    }}
                />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Line type="monotone" dataKey="orders.totalTtc" stroke="#f05c34" activeDot={{ r: 7 }} />
            </LineChart>
        </Paper>
    );
}

export default function EcoStatistics() {
    const classes = useStyles();

    const [state, setState] = useState({
        loading: true,
        totalOrders: 0,
        ordersLen: 0,
        invitationsLen: 0,
        ecoUsers: [],
        datas: [],
        last12Months: [],
        today: '',
        month: '',
        week: '',
        from: '',
        to: '',
    });
    const [filter, setFilter] = useState({
        period: 'dateToDate',
        users: [],
    });
    const token = Cookies.get('jwt');

    const handleTotal = (total) => {
        setState((prev) => ({
            ...prev,
            total: prev.total + total,
        }));
    };

    useEffect(() => {
        const today = moment();
        const last12Months = lastMonths(12);
        const from = today.subtract(4, 'months').format('YYYY-MM-DD');

        setState((prev) => ({
            ...prev,
            today: today.format('YYYY-MM-DD'),
            month: today.format('YYYY-MM'),
            week: today.format('YYYY-MM-DD'),
            from,
            to: today.format('YYYY-MM-DD'),
            last12Months,
        }));
    }, [token]);

    useEffect(() => {
        axiosGetRequest('/users?role=eco', token)
            .then((res) => {
                setState((prev) => ({
                    ...prev,
                    ecoUsers: res.data.data.users,
                    loading: false,
                }));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [token]);

    const handleFilter = (event) => {
        event.persist();

        if (event.target.name === 'period') {
            setFilter((prev) => ({
                ...prev,
                [event.target.name]: event.target.value,
            }));
        }

        setFilter((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));

        setState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleFetch = async () => {
        setState((prev) => ({
            ...prev,
            loading: true,
        }));

        // INFOS : PREPARE FILTER OBJECT FOR API
        const fields = {};

        fields.ecoUsers = filter.users.map((user) => user.id);

        fields.period = filter.period;

        if (filter.period === 'dateToDate') {
            fields.from = filter.from;
            fields.to = filter.to;
        }

        if (filter.period === 'month') {
            fields.month = filter.month;
        }

        if (filter.period === 'week') {
            fields.week = filter.week;
        }

        if (filter.period === 'day') {
            fields.day = filter.day;
        }

        console.log(fields);

        const token = Cookies.get('jwt');
        axiosPostRequest('/invitations/stats/', token, { fields: { ...fields } })
            .then((res) => {
                const { stats } = res.data.data;
                console.log(stats);
                const invitationsTotal = stats.map((user) => {
                    const tempTotalOrders = user.invitations.reduce((acc, curr) => acc + curr.orders.totalTtc, 0);
                    const tempInvitationsLen = user.invitations.length;
                    const tempOrdersLen = user.invitations.reduce((acc, curr) => acc + curr.orders.ordersLen, 0);
                    return {
                        tempTotalOrders,
                        tempOrdersLen,
                        tempInvitationsLen,
                    };
                });
                const totalOrders = invitationsTotal.reduce((acc, curr) => acc + curr.tempTotalOrders, 0);
                const ordersLen = invitationsTotal.reduce((acc, curr) => acc + curr.tempOrdersLen, 0);
                const invitationsLen = invitationsTotal.reduce((acc, curr) => acc + curr.tempInvitationsLen, 0);
                setState((prev) => ({
                    ...prev,
                    totalOrders,
                    ordersLen,
                    invitationsLen,
                    loading: false,
                    datas: stats,
                }));
            })
            .catch((err) => {
                console.log(err.response.message);
            });
    };

    return (
        <>
            <h1>Eco Statistics</h1>

            <StatsRecap
                loading={false}
                datas={[
                    {
                        name: 'Total Payé',
                        picto: <ShoppingCartRounded />,
                        value: currencyFormater(state.totalOrders),
                    },
                    {
                        name: 'Nombre de commandes',
                        picto: <MonetizationOnRounded />,
                        value: state.ordersLen,
                    },
                    {
                        name: "Nombre d'invitations",
                        picto: <Mail />,
                        value: state.invitationsLen,
                    },
                ]}
            />

            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <DateFilter state={state} filter={filter} onChange={handleFilter} />
                    <Grid item>
                        <Autocomplete
                            multiple
                            id="multiple-limit-tags"
                            options={state.ecoUsers}
                            limitTags={2}
                            getOptionLabel={(option) => option.fullname}
                            onChange={(e, newValue) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    users: [...newValue],
                                }));
                            }}
                            style={{ width: 500 }}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue
                                    .reverse()
                                    .map((option, index) => (
                                        <Chip key={index} label={option.fullname} {...getTagProps(index)} />
                                    ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Eco acteurs"
                                    placeholder="Entrez un nom"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleFetch}>
                            Charger
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {state.loading === true ? (
                <Skeleton />
            ) : (
                state.datas.map((user, key) => <EcoGraph key={key} user={user} handleTotal={handleTotal} />)
            )}
        </>
    );
}
