import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

// # MATERIAL
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// # COMPONENTS
import ArticleCard from '../../../components/Front/Blog/ArticleCard';

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: '3rem !important',
    marginBottom: '4rem !important',
    fontFamily: 'Bitter !important',
    textAlign: 'center',
  },
  h3: {
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem !important',
      marginBottom: '6rem',
    },
    fontSize: '1.5rem !important',
    margin: '0 1rem 4rem 1rem !important',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Bitter !important',
  },
  container: {
    [theme.breakpoints.up('md')]: {
      margin: '5rem 5rem',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '5rem 10rem',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60%',
  },
  content: {
    textAlign: 'left',
    fontFamily: 'Bitter',
    fontSize: '1rem',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cover: {
    width: '40%',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  divider: {
    backgroundColor: '#ADC00D',
  },
  next: {
    padding: '0.5rem 0',
    color: '#ADC00D',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: '#ADC00D',
      color: 'white',
    },
  },
  sideBar: {
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    marginTop: '7rem',
    padding: '1rem',
    borderRadius: '0 14px 14px 0',
    height: 'fit-content',
    minHeight: '80%',
  },
  textField: {
    borderRadius: '14px !important',
  },
  overflow: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    marginTop: '0',
    marginBottom: '0',
    '& > p': {
      margin: '0',
      color: 'black',
    },
  },
  link: {
    padding: '0.5rem !important',
    textAlign: 'center !important',
    borderRadius: '0 0 14px 0',
  },
  a: {
    color: '#ADC00D',
    textAlign: 'center',
  },
  article: {
    display: 'flex',
    borderRadius: '14px',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    minHeight: '312px',
    width: '100%',
    '&:hover': {
      boxShadow: 'none',
      '& > div > p': {
        transition: '0.5s',
        backgroundColor: '#ADC00D',
        '&>a': {
          color: 'white',
        },
      },
    },
    '& > figure': {
      width: '40%',
      margin: '0',
      '& > img': {
        width: '100%',
        borderRadius: '14px 0 0 14px',
      },
    },
  },
  text: {
    padding: '1rem',
  },
  actions: {
    display: 'flex',
    border: '1px solid rgb(0 0 0 0 70%)',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
      '& > div > div > div:nth-child(1) > h5': {
        color: '#f05c35',
      },
      '& > div > div > div:nth-child(2) > p': {
        transition: '0.2s',
        backgroundColor: '#ADC00D',
        color: 'white',
        '& > a': {
          color: 'white',
        },
      },
    },
    borderRadius: '14px',
  },
  legend: {
    position: 'absolute',
    top: '350px',
    padding: '2rem',
    backgroundColor: 'rgba(0,0,0, 0.6)',
  },
  title: {
    margin: 0,
    color: 'white',
    fontSize: '1.5rem',
  },
  date: {
    margin: '1rem 0 0 0',
    textAlign: 'left',
    color: 'lightgrey',
  },
  chip: {
    marginRight: '0.5rem',
    '& > svg': {
      width: '20px !important',
    },
  },
}));

export default function BlogHome() {
  const classes = useStyles();
  const [state, setState] = useState({
    articles: [],
    carousel: [],
    loading: true,
    skip: 0,
  });

  const nextPage = () => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    setState((prev) => ({
      ...prev,
      skip: prev.skip + 4,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const articles = await axios
        .get(
          `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries/?content_type=recettes&access_token=${process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN_DELIVERY}&skip=${state.skip}&limit=4&order=-fields.data`
        )
        .then((res) => {
          console.error(res.data.items);
          return res.data.items;
        })
        .catch((err) => {
          console.error(err);
        });

      const cloneArticles = [...articles];
      await Promise.all(
        articles.map(async (article, idx) => {
          await axios
            .get(
              `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/assets/${article.fields.image.sys.id}?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN_DELIVERY}`
            )
            .then((res) => {
              setState((prev) => ({
                ...prev,
                loading: false,
              }));
              cloneArticles[idx].fields.imageURL = res.data.fields.file.url;
            })
            .catch((err) => {
              console.error(err);
            });
        })
      );

      setState((prev) => ({
        ...prev,
        articles: [...prev.articles, ...articles],
        carousel: articles,
      }));
    };

    fetchData();
  }, [state.skip]);

  return (
    <div className={classes.container}>
      <h1 className={classes.h1}>Nos idées recettes</h1>
      <Grid className={classes.blog} container>
        <Grid item xs={12}>
          <Carousel autoPlay>
            {state.carousel.map((el) => (
              <div key={el.sys.id}>
                <figure
                  style={{
                    backgroundImage: `url(https:${el.fields.imageURL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '14px 0 0 14px',
                    width: '100%',
                    height: '500px',
                    margin: '0',
                  }}
                />
                <Link
                  to={{
                    pathname: `/blog/${el.fields.slug}`,
                    search: `?contentType=recettes`,
                    state: {
                      article: el,
                    },
                  }}
                >
                  <div className={classes.legend}>
                    <p className={classes.title}>{el.fields.title}</p>
                    <p className={classes.date}>
                      <em>{moment(el.fields.data).format('LL')}</em>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </Carousel>
        </Grid>
        <h3 className={classes.h3}>
          Chaque semaine, retrouvez des recettes originales pour cuisiner tous les fruits et légumes
          de vos paniers Bios
        </h3>

        {!state.loading &&
          (state.articles.length === 0 ? (
            <p>Aucun article</p>
          ) : (
            state.articles.map((article) => (
              <Grid key={article.sys.id} className={classes.grid} item xs={12} md={6}>
                <ArticleCard contentType="recettes" article={article} />
              </Grid>
            ))
          ))}
      </Grid>

      <Button style={{ marginTop: '2rem' }} className="btn-orange" onClick={nextPage}>
        Charger plus de recettes
      </Button>
    </div>
  );
}
