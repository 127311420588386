import React from 'react';

// # ICONS

// # METERIAL UI
import { Chip, makeStyles } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AbLogo from '../../assets/img/logos/ab-logo.svg';

export default function CustomChip(props) {
  const { color, label, className } = props;

  const useStyles = makeStyles((theme) => ({
    chip: {
      fontFamily: 'Bitter',
      marginRight: '5px',
      height: '25px',
      borderRadius: '4px',

      '& > span': {
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: '0.7rem',
      },
    },
    organic: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      marginRight: '5px',
      padding: '3px',
      borderRadius: '4px',
      height: '18px',
      background: '#4caf50',

      '& > img': {
        height: '18px',
      },
    },
    france: {
      fontFamily: 'Bitter',
      marginRight: '5px',
      background:
        'linear-gradient(90deg, #002295 33%, transparent 33%), linear-gradient(90deg, white 66%, red 66%)',
      width: '24px',
      height: '24px',
      borderRadius: '4px',
    },
  }));
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      secondary: {
        light: '#f05c35',
        main: '#f05c35',
        dark: '#f05c35',
        contrastText: '#fff',
      },
      primary: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {label === 'Bio' ? (
        <div className={classes.organic}>
          <img src={AbLogo} alt="Logo-AB" />
        </div>
      ) : (
        <Chip
          className={classes[className] ? classes[className] : classes.chip}
          color={color}
          label={label}
        />
      )}
    </ThemeProvider>
  );
}
