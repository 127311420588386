import React, { useState, useContext, useEffect } from 'react';

// # LIBS
import axios from 'axios';
import moment from 'moment';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

// # CUSTOM COMPONENTS
import InvitationTab from '../../../components/Invitations/InvitationTab';
import {UserContext} from "../../../contexts/UserContext/UserContext";

// # MATERIAL.UI
const useStyles = makeStyles(() => ({
  container: {
    width: '80%',
    margin: '6rem auto 0 auto',
  },
  flex: {
    display: 'flex',
    margin: '30px auto',
  },
  flex2: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  invitList: {
    width: '80%',
    height: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '2rem',
  },
  h3: {
    margin: '0 !important',
  },
  button2: {
    backgroundColor: 'white !important',
    color: '#f05c34 !important',
    border: '1px solid #f05c34 !important',
    marginRight: '2vw',
    height: 'fit-content',
  },
  important: {
    border: '1px solid red',
    borderRadius: '5px',
    padding: '5px',
    margin: '10px',
  },
  red: {
    color: 'red',
  },
  button: {
    width: '100%',
    margin: '0 0 10px 0',
    backgroundColor: '#f05c34',
    color: 'white',
    borderRadius: '50px',

    '&:hover': {
      backgroundColor: '#bfcf38',
    },
  },
}));

export default function InvitationList() {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const [state, setState] = useState({
    invitations: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const invitations = await axios
        .get(`${process.env.REACT_APP_API}/invitations/user`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .catch((err) => {
          console.error(err.response);
        });

      const sortedInvitations = invitations.data.data.invitations.sort((a, b) =>
        moment(a.deliveryInfos.deliveryDate).diff(b.deliveryInfos.deliveryDate)
      );

      setState((prev) => ({
        ...prev,
        invitations: [...sortedInvitations],
      }));
    };
    fetchData();
  }, []);

  return (
    <div className={classes.container}>
      <h1>Liste d'invitations</h1>

      <div className={classes.flex}>
        <div className={classes.invitList}>
          <InvitationTab url="/invitation" invitations={state.invitations} />
        </div>
        <div className={classes.flex2}>
          {/* <Button className={classes.button}>
            Voir les invitations passées
          </Button> */}
          <Button href="/create_invitation" className={classes.button}>
            Nouvelle invitation
          </Button>
          <Button className={classes.button} href="/create_contact">
            Ajouter un contact
          </Button>
          <Button className={classes.button} href="/contact_list">
            Mes contacts
          </Button>
          <Button href="/contact" className={classes.button}>
            Contacter Bioculture
          </Button>
        </div>
      </div>
    </div>
  );
}
