import React, { useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Paper,
  Divider,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';

import Cookies from 'js-cookie';

import axios from 'axios';
import Script from 'react-load-script';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(3),
    overflow: 'auto',
    marginBottom: '1rem',
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        marginBottom: '1rem',
      },
      '& > div.flex': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  switches: {
    maxWidth: '250px',
  },
}));

export default function CreateUser() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    conciergerie: false,
    newUser: {
      role: 'user',
      isActive: true,
      newsLetter: true,
      groupLabels: true,
      firstname: '',
      lastname: '',
      phoneNumber: '',
      email: '',
      password: '',
      passwordConfirm: '',
      information: '',
      address: [],
      createdAt: new Date(),
    },
    newAddress: {
      street: '',
      zipCode: '',
      city: '',
      comment: '',
      isMain: true,
      isBill: true,
    },
  });

  const handleScriptLoad = () => {
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), options);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const components = place.address_components;
      let street;
      let zipCode;
      let city;

      components.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'locality':
            city = component.long_name;
            break;
          case 'street_number':
            street = component.long_name;
            break;
          case 'route':
            if (street) {
              street += ` ${component.long_name}`;
            } else {
              street = component.long_name;
            }
            break;
          case 'postal_code':
            zipCode = component.long_name;
            break;
          default:
            break;
        }
      });

      if (state.newUser.address.length === 0) {
        setState((prev) => ({
          ...prev,
          newUser: {
            ...prev.newUser,
          },
          newAddress: {
            ...prev.newAddress,
            street,
            zipCode,
            city,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          newUser: {
            ...prev.newUser,
          },
          newAddress: {
            ...prev.newAddress,
            street,
            zipCode,
            city,
            isMain: false,
            isBill: false,
          },
        }));
      }
    });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const token = Cookies.get('jwt');

    await axios
      .post(`${process.env.REACT_APP_API}/users`, state.newUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        window.location = `/admin/users/${res.data.data.user._id}`;
      })
      .catch((err) => {
        enqueueSnackbar(`Une problème est survenu`, {
          variant: 'error',
        });
      });
  };

  const handleSwitch = (key) => {
    setState((prev) => ({
      ...prev,
      newUser: {
        ...prev.newUser,
        [key]: !prev.newUser[key],
      },
    }));
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    if (state.newAddress.street !== '' && state.newAddress.zipCode && state.newAddress.city !== '') {
      if (state.newUser.address.length === 0) {
        setState((prev) => ({
          ...prev,
          newUser: {
            ...prev.newUser,
            address: [...prev.newUser.address, state.newAddress],
          },
          newAddress: {
            ...prev.newAddress,
            isMain: false,
            isBill: false,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          newUser: {
            ...prev.newUser,
            address: [...prev.newUser.address, state.newAddress],
          },
          newAddress: {
            ...prev.newAddress,
          },
        }));
      }
    } else {
      enqueueSnackbar('Veuillez saisir une adresse valide', {
        variant: 'error',
      });
    }
  };

  const handleChange = (event) => {
    event.persist();

    if (event.target.name === 'role') {
      if (event.target.value === 'conciergerie') {
        return setState((prev) => ({
          ...prev,
          conciergerie: true,
          newUser: {
            ...prev.newUser,
            [event.target.name]: event.target.value,
            newsLetter: false,
            groupLabels: false,
            lastname: 'conciergerie',
          },
        }));
      }
      setState((prev) => ({
        ...prev,
        conciergerie: false,
        newUser: {
          ...prev.newUser,
          newsLetter: true,
          groupLabels: true,
        },
      }));
    }

    if (event.target.name === 'password') {
      return setState((prev) => ({
        ...prev,
        newUser: {
          ...prev.newUser,
          [event.target.name]: event.target.value,
          passwordConfirm: event.target.value,
        },
      }));
    }

    setState((prev) => ({
      ...prev,
      newUser: {
        ...prev.newUser,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <div className={classes.container}>
        <h2>Créer un utilisateur</h2>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h3>Informations personnelles</h3>
            <form className={classes.form} onSubmit={handleCreate}>
              <div className="flex">
                <FormControl variant="outlined">
                  <InputLabel>Role</InputLabel>
                  <Select native label="Rôle" name="role" value={state.newUser.role} onChange={handleChange}>
                    <option value="superAdmin">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="eco">Eco-acteur</option>
                    <option value="user">Client simple</option>
                    <option value="subscriberBtoB">Client B2B</option>
                    <option value="subscriberBtoC">Client B2C</option>
                    <option value="entreprise">Entreprise</option>
                    <option value="conciergerie">Conciergerie</option>
                  </Select>
                </FormControl>
                <div className={classes.switches}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.newUser.newsLetter}
                        onChange={() => handleSwitch('newsLetter')}
                        name="newsletter"
                      />
                    }
                    label="Inscription à la newsletter"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.newUser.groupLabels}
                        onChange={() => handleSwitch('groupLabels')}
                        name="Labels"
                      />
                    }
                    label="Grouper les étiquettes"
                  />
                </div>
              </div>
              {state.conciergerie ? (
                <TextField
                  label="Dénomination Sociale"
                  type="text"
                  variant="outlined"
                  name="firstname"
                  onChange={handleChange}
                />
              ) : (
                <div className="flex">
                  <TextField label="Prénom" type="text" variant="outlined" name="firstname" onChange={handleChange} />
                  <TextField label="Nom" type="text" variant="outlined" name="lastname" onChange={handleChange} />
                </div>
              )}
              <TextField
                label="Numéro de téléphone"
                type="text"
                variant="outlined"
                name="phoneNumber"
                onChange={handleChange}
              />
              <TextField label="Email" type="text" variant="outlined" name="email" onChange={handleChange} />
              <TextField label="Mot de passe" type="text" variant="outlined" name="password" onChange={handleChange} />
              <TextField
                label="Informations"
                type="text"
                variant="outlined"
                multiline
                name="information"
                onChange={handleChange}
              />
              <Button variant="contained" color="primary" type="submit" fullwidth>
                Créer
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h3>Adresses</h3>
            <ul>
              {state.newUser.address.length > 0 ? (
                state.newUser.address.map((el, idx) => (
                  <li key={idx}>
                    {el.street} - {el.city} ({el.zipCode})
                  </li>
                ))
              ) : (
                <li>Aucune adresse renseignée</li>
              )}
            </ul>
            <Divider style={{ marginBottom: '1rem' }} />
            <form className={classes.form} onSubmit={handleAddAddress}>
              <TextField
                label="Adresse"
                type="text"
                variant="outlined"
                id="autocomplete"
                onChange={(e) => {
                  const street = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newAddress: {
                      ...prev.newAddress,
                      street,
                    },
                  }));
                }}
              />
              <TextField
                label="Commentaire pour le livreur"
                type="text"
                variant="outlined"
                multiline
                onChange={(e) => {
                  const comment = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    newAddress: {
                      ...prev.newAddress,
                      comment,
                    },
                  }));
                }}
              />
              <Button variant="contained" color="primary" type="submit" fullwidth>
                Ajouter l'adresse
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
