import React, { useState, useEffect, Fragment } from 'react';

import Cookies from 'js-cookie';

// # MOMENT
import moment from 'moment';
import 'moment/locale/fr';

// # DRAFT JS
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// # UTILS

// # MATERIAL UI
import {
  Button,
  Divider,
  FormControl,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Checkbox,
  TableCell,
  FormControlLabel,
  InputLabel,
  Paper,
  Select,
  Switch,
  TextField,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { autocompleteGroupedFormater } from '../../../utils/stringFormater';
import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    marginBottom: '1rem',
    padding: '15px 30px 24px',
    outline: 'none',

    '& h4': {
      margin: '5px 0',
    },
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: '1rem',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infos: {
    '& > div': {
      display: 'flex',
      flexGrow: 1,

      '& > .MuiFormControl-root': {
        margin: '0 1rem 1rem 0',
        flexGrow: 1,
      },
    },

    '& h4': {
      marginBottom: '1rem',
    },
  },
  cellInput: {
    width: '80px',
    marginRight: '5px',
  },
}));

export default function CreateBasket() {
  moment.locale('fr');

  const token = Cookies.get('jwt');

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // # STATE
  const [state, setState] = useState({
    loading: true,
    products: [],
    tags: [],
    taxes: [],
    categories: [],
    subCategories: [],
    weightUnities: [],
    newBasket: {
      photo: undefined,
      name: undefined,
      reference: undefined,
      price: undefined,
      tvaRate: undefined,
      description: EditorState.createEmpty(),
      category: undefined,
      subCategory: undefined,
      isActive: true,
      isTop: false,
      isEco: false,
      size: 'SOLO',
      stock: 100,
      tags: [],
      weight: {
        from: undefined,
        to: undefined,
        unity: undefined,
      },
      persons: {
        from: 2,
        to: 3,
      },
      availability: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().add(1, 'weeks').format('YYYY-MM-DD'),
      },
      labels: {
        organic: true,
        wild: false,
        french: false,
        native: false,
      },
      products: [],
    },
  });

  // # FETCH ALL DATAS
  useEffect(() => {
    // # FETCH ALL PRODUCTS
    axiosGetRequest('/products', token).then((res) => {
      const { products } = res.data.data;
      const productFormat = autocompleteGroupedFormater(products, 'name');
      setState((prev) => ({
        ...prev,
        products: [...productFormat],
      }));
    });

    // # FETCH TAXES
    axiosGetRequest('/taxes/', token).then((res) => {
      const { taxes } = res.data.data;
      setState((prev) => ({
        ...prev,
        taxes,
      }));
    });

    // # FETCH CATEGORIES
    axiosGetRequest('/categories/', token).then((res) => {
      const { categories } = res.data.data;
      setState((prev) => ({
        ...prev,
        categories,
      }));
    });

    // # FETCH SUB CATEGORIES
    axiosGetRequest('/subcategories/', token).then((res) => {
      const { subCategories } = res.data.data;
      setState((prev) => ({
        ...prev,
        subCategories,
      }));
    });

    // # FETCH TAGS
    axiosGetRequest('/tags/', token).then((res) => {
      const { tags } = res.data.data;
      const formattedTags = tags.map((tag) => ({
        ...tag,
        cleanName: tag.name.toLowerCase().split('.')[1],
      }));
      const tagsFormat = autocompleteGroupedFormater(formattedTags, 'cleanName');
      setState((prev) => ({
        ...prev,
        tags: [...tagsFormat],
      }));
    });

    // # FETCH WEIGHT UNITIES
    axiosGetRequest('/weight/', token).then((res) => {
      const { unities } = res.data.data;
      setState((prev) => ({
        ...prev,
        weightUnities: unities,
      }));
    });

    // # LOADING SET TO FALSE
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
  }, [token]);

  // # HANDLE IMG UPLOAD
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        photo: file,
      },
    }));
  };

  // # HANDLE SWITCH CHANGES
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        [name]: checked,
      },
    }));
  };

  // # HANDLE SWITCH LABELS CHANGES
  const handleSwitchLabelsChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        labels: {
          ...prev.newBasket.labels,
          [name]: checked,
        },
      },
    }));
  };

  // # HANDLE INPUTS CHANGES
  const handleInputsChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        [name]: value,
      },
    }));
  };

  // # HANDLE WEIGHT INPUTS CHANGES
  // TODO : NEED REFACTORING
  const handleInputsWeightChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        weight: {
          ...prev.newBasket.weight,
          [name]: value,
        },
      },
    }));
  };

  // # HANDLE PERSONS INPUTS CHANGES
  // TODO : NEED REFACTORING
  const handleInputsPersonsChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        persons: {
          ...prev.newBasket.persons,
          [name]: value,
        },
      },
    }));
  };

  // # HANDLE AVAILABILITY INPUTS CHANGES
  // TODO : NEED REFACTORING
  const handleInputsAvailabilityChange = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    if (type === 'number') value *= 1;

    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        availability: {
          ...prev.newBasket.availability,
          [name]: value,
        },
      },
    }));
  };

  // # HANDLE AUTOCOMPLETE MULTIPLE CHANGES
  const handleAutocompleteMultiple = (type, value) => {
    if (type === 'product') {
      const formatedValues = value.map((val) => ({
        productId: val.id,
        photo: val.photo,
        name: val.name,
        reference: val.reference,
        weight: {
          quantity: val.weight?.quantity,
          unity: val.weight?.unity?.name,
          abbreviation: val.weight?.unity?.abbreviation,
        },
        origin: val.origin,
        labels: {
          organic: val.labels?.organic,
          wild: val.labels?.wild,
          french: val.labels?.french,
          native: val.labels?.native,
        },
      }));

      setState((prev) => ({
        ...prev,
        newBasket: {
          ...prev.newBasket,
          products: [...formatedValues],
        },
      }));
    }

    if (type === 'tag') {
      const tags = value.map((val) => val.id);

      setState((prev) => ({
        ...prev,
        newBasket: {
          ...prev.newBasket,
          tags,
        },
      }));
    }
  };

  // # HANDLE EDITOR CHANGES
  const onEditorStateChange = (editorState) => {
    setState((prev) => ({
      ...prev,
      newBasket: {
        ...prev.newBasket,
        description: editorState,
      },
    }));
  };

  // # HANDLE PRODUCTS CHANGES
  const handleProductsChange = (event, productIdx) => {
    let { name, value, type, checked } = event.target;
    if (type === 'number') value *= 1;

    if (type === 'checkbox') {
      const newArr = [...state.newBasket.products];
      newArr[productIdx].labels[name] = checked;

      setState((prev) => ({
        ...prev,
        newBasket: {
          ...prev.newBasket,
          products: newArr,
        },
      }));
    }

    if (name === 'origin' || name === 'name') {
      const newArr = [...state.newBasket.products];
      newArr[productIdx][name] = value;

      setState((prev) => ({
        ...prev,
        newBasket: {
          ...prev.newBasket,
          products: newArr,
        },
      }));
    }

    if (name === 'quantity') {
      const newArr = [...state.newBasket.products];
      newArr[productIdx].weight.quantity = value;

      setState((prev) => ({
        ...prev,
        newBasket: {
          ...prev.newBasket,
          products: newArr,
        },
      }));
    }

    if (name === 'unity') {
      const matchingUnity = state.weightUnities.find((unity) => unity.id === value);
      const newArr = [...state.newBasket.products];

      newArr[productIdx].weight.unity = matchingUnity.name;
      newArr[productIdx].weight.abbreviation = matchingUnity.abbreviation;

      setState((prev) => ({
        ...prev,
        newBasket: {
          ...prev.newBasket,
          products: newArr,
        },
      }));
    }
  };

  // # HANDLE SUBMIT NEW BASKET
  const handleSubmitBasket = (e) => {
    e.preventDefault();
    const token = Cookies.get('jwt');

    const formData = new FormData();

    if (state.newBasket.photo) {
      formData.append('photo', state.newBasket.photo);
    }

    formData.append('name', state.newBasket.name);
    formData.append('reference', state.newBasket.reference);
    formData.append('price', state.newBasket.price);
    formData.append('tvaRate', state.newBasket.tvaRate);
    formData.append(
      'description',
      draftToHtml(convertToRaw(state.newBasket.description.getCurrentContent()))
    );
    formData.append('category', state.newBasket.category);
    formData.append('subCategory', state.newBasket.subCategory);
    formData.append('isActive', state.newBasket.isActive);
    formData.append('isTop', state.newBasket.isTop);
    formData.append('isEco', state.newBasket.isEco);
    formData.append('size', state.newBasket.size);
    formData.append('stock', state.newBasket.stock);
    formData.append('weightFrom', state.newBasket.weight.from);
    formData.append('weightTo', state.newBasket.weight.to);
    formData.append('weightUnity', state.newBasket.weight.unity);
    formData.append('personsFrom', state.newBasket.persons.from);
    formData.append('personsTo', state.newBasket.persons.to);
    formData.append('availabilityFrom', state.newBasket.availability.from);
    formData.append('availabilityTo', state.newBasket.availability.to);
    formData.append('origin', state.newBasket.origin);
    formData.append('organic', state.newBasket.labels.organic);
    formData.append('wild', state.newBasket.labels.wild);
    formData.append('french', state.newBasket.labels.french);
    formData.append('native', state.newBasket.labels.native);
    formData.append('products', JSON.stringify(state.newBasket.products));
    formData.append('tags', JSON.stringify(state.newBasket.tags));

    axiosPostRequest('/baskets/', token, formData)
      .then(() => {
        window.location = `/admin/baskets/list`;
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        console.log(err.response);
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  return (
    <>
      <h2>Nouveau panier</h2>

      {state.loading && <Skeleton variant="rect" height={200} />}

      {!state.loading && (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Paper className={classes.paper}>
              <section className={classes.spaceBetween}>
                <div className={classes.flexCenter}>
                  {state.newBasket.photo ? (
                    <img
                      src={URL.createObjectURL(state.newBasket.photo)}
                      alt="upload"
                      width={250}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`}
                      alt="missing"
                      width={250}
                    />
                  )}
                  <Input type="file" onChange={handleFileChange} />
                </div>
                <Divider className={classes.divider} orientation="vertical" flexItem />
                <div>
                  <h4>Visibilité :</h4>
                  <div className={classes.flex}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={state.newBasket.isActive}
                          onChange={handleSwitchChange}
                        />
                      }
                      label="Actif"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="isTop"
                          color="primary"
                          checked={state.newBasket.isTop}
                          onChange={handleSwitchChange}
                        />
                      }
                      label="Top"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="isEco"
                          color="primary"
                          checked={state.newBasket.isEco}
                          onChange={handleSwitchChange}
                        />
                      }
                      label="Eco-acteurs"
                    />
                  </div>
                  <Divider className={classes.divider} />
                  <div>
                    <h4>Labels :</h4>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name="organic"
                            color="primary"
                            checked={state.newBasket.labels.organic}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Bio"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="wild"
                            color="primary"
                            checked={state.newBasket.labels.wild}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Sauvage"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name="french"
                            color="primary"
                            checked={state.newBasket.labels.french}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Français"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="native"
                            color="primary"
                            checked={state.newBasket.labels.native}
                            onChange={handleSwitchLabelsChange}
                          />
                        }
                        label="Local"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <Divider className={classes.divider} />
              <section className={classes.infos}>
                <h3>Informations :</h3>
                <div>
                  <TextField
                    name="name"
                    label="Nom"
                    type="text"
                    variant="outlined"
                    value={state.newBasket.name}
                    onChange={handleInputsChange}
                  />
                  <TextField
                    name="reference"
                    label="Référence"
                    type="text"
                    variant="outlined"
                    value={state.newBasket.reference}
                    onChange={handleInputsChange}
                  />
                </div>
                <div>
                  <FormControl variant="outlined">
                    <InputLabel>Rubrique</InputLabel>
                    <Select
                      native
                      label="Rubrique"
                      name="category"
                      value={state.newBasket.category}
                      onChange={handleInputsChange}
                    >
                      <option value="" />
                      {state.categories.map((category, idx) => (
                        <option key={idx} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined">
                    <InputLabel>Sous-rubrique</InputLabel>
                    <Select
                      native
                      label="Sous-rubrique"
                      name="subCategory"
                      value={state.newBasket.subCategory}
                      onChange={handleInputsChange}
                    >
                      <option value="" />
                      {state.subCategories.map((subCategory, idx) => (
                        <option key={idx} value={subCategory.id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    name="price"
                    label="Prix"
                    type="number"
                    variant="outlined"
                    value={state.newBasket.price}
                    onChange={handleInputsChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel>TVA</InputLabel>
                    <Select
                      native
                      name="tvaRate"
                      label="TVA"
                      value={state.newBasket.tvaRate}
                      onChange={handleInputsChange}
                    >
                      <option value="" />
                      {state.taxes.map((tax, idx) => (
                        <option key={idx} value={tax.id}>
                          {tax.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="stock"
                    label="Stock"
                    type="number"
                    variant="outlined"
                    value={state.newBasket.stock}
                    onChange={handleInputsChange}
                  />
                </div>
                <div>
                  <TextField
                    name="from"
                    label="Poids de"
                    type="number"
                    variant="outlined"
                    value={state.newBasket.weight.from}
                    onChange={handleInputsWeightChange}
                  />
                  <TextField
                    name="to"
                    label="à"
                    type="number"
                    variant="outlined"
                    value={state.newBasket.weight.to}
                    onChange={handleInputsWeightChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel>Unité</InputLabel>
                    <Select
                      native
                      name="unity"
                      label="Unité"
                      value={state.newBasket.weight.unity}
                      onChange={handleInputsWeightChange}
                    >
                      <option value="" />
                      {state.weightUnities.map((unity, idx) => (
                        <option key={idx} value={unity.id}>
                          {unity.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel>Taille</InputLabel>
                    <Select
                      native
                      name="size"
                      label="Taille"
                      value={state.newBasket.size}
                      onChange={handleInputsChange}
                    >
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="SOLO">Solo</option>
                    </Select>
                  </FormControl>
                </div>
                <h4>Nombre de personnes :</h4>
                <div>
                  <TextField
                    name="from"
                    label="Pour"
                    type="number"
                    variant="outlined"
                    value={state.newBasket.persons.from}
                    onChange={handleInputsPersonsChange}
                  />
                  <TextField
                    name="to"
                    label="à"
                    type="number"
                    variant="outlined"
                    value={state.newBasket.persons.to}
                    onChange={handleInputsPersonsChange}
                  />
                </div>
                <h4>Disponibilité :</h4>
                <div>
                  <TextField
                    name="from"
                    label="Du"
                    type="date"
                    variant="outlined"
                    value={state.newBasket.availability.from}
                    onChange={handleInputsAvailabilityChange}
                  />
                  <TextField
                    name="to"
                    label="au"
                    type="date"
                    variant="outlined"
                    value={state.newBasket.availability.to}
                    onChange={handleInputsAvailabilityChange}
                  />
                </div>
              </section>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="primary"
              className={classes.marginBottom}
              fullWidth
              onClick={handleSubmitBasket}
            >
              Créer le panier
            </Button>
            <Paper className={classes.paper}>
              <h3>Description :</h3>
              <Editor
                editorState={state.newBasket.description}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: ['inline', 'colorPicker', 'link'],
                  inline: {
                    options: ['bold', 'italic'],
                  },
                }}
                editorStyle={{
                  border: '1px solid #F1F1F1',
                  minHeight: '150px',
                  padding: '0 1rem',
                  marginBottom: '1rem',
                }}
                onEditorStateChange={onEditorStateChange}
              />
            </Paper>

            <Paper className={classes.paper}>
              <h3>Ajouter des produits :</h3>
              <Autocomplete
                multiple
                onChange={(event, value) => handleAutocompleteMultiple('product', value)}
                options={state.products.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.name} - ${option.reference} - ${option.weight.quantity} ${option.weight.unity.abbreviation}`
                }
                renderOption={(option) => (
                  <>
                    {option.name} - {option.reference} - {option.weight.quantity}{' '}
                    {option.weight.unity.abbreviation}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Produits"
                    variant="outlined"
                    style={{
                      width: '100%',
                      marginBottom: '1rem',
                    }}
                  />
                )}
              />
            </Paper>

            <Paper className={classes.paper}>
              <h3>Ajouter des tags :</h3>
              <Autocomplete
                multiple
                onChange={(event, value) => handleAutocompleteMultiple('tag', value)}
                options={state.tags.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => <>{option.name}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    variant="outlined"
                    style={{
                      width: '100%',
                      marginBottom: '1rem',
                    }}
                  />
                )}
              />
            </Paper>
          </Grid>

          {state.newBasket.products.length > 0 && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <h3>Produits dans le panier :</h3>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <b>Image</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Nom</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Poids</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Origine</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Labels</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.newBasket.products.map((product, idx) => (
                        <TableRow key={idx}>
                          <TableCell align="left">
                            <img
                              src={`${process.env.REACT_APP_AWS}/${product.photo}`}
                              alt={product.reference}
                              width={70}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              name="name"
                              type="text"
                              variant="outlined"
                              value={state.newBasket.products[idx].name}
                              onChange={(event) => handleProductsChange(event, idx)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              className={classes.cellInput}
                              name="quantity"
                              type="number"
                              variant="outlined"
                              value={product.weight.quantity}
                              onChange={(event) => handleProductsChange(event, idx)}
                            />
                            <FormControl variant="outlined">
                              <InputLabel>Unité</InputLabel>
                              <Select
                                native
                                name="unity"
                                label="Unité"
                                value={
                                  state.weightUnities.find(
                                    (unity) =>
                                      unity.name === state.newBasket.products[idx].weight.unity
                                  ).id
                                }
                                onChange={(event) => handleProductsChange(event, idx)}
                              >
                                {state.weightUnities.map((unity, idx) => (
                                  <option key={idx} value={unity.id}>
                                    {unity.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              name="origin"
                              type="text"
                              variant="outlined"
                              value={state.newBasket.products[idx].origin}
                              onChange={(event) => handleProductsChange(event, idx)}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <div className={classes.column}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.newBasket.products[idx].labels.organic}
                                    name="organic"
                                    onChange={(event) => handleProductsChange(event, idx)}
                                  />
                                }
                                label="Bio"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.newBasket.products[idx].labels.wild}
                                    name="wild"
                                    onChange={(event) => handleProductsChange(event, idx)}
                                  />
                                }
                                label="Sauvage"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.newBasket.products[idx].labels.french}
                                    name="french"
                                    onChange={(event) => handleProductsChange(event, idx)}
                                  />
                                }
                                label="Français"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.newBasket.products[idx].labels.native}
                                    name="native"
                                    onChange={(event) => handleProductsChange(event, idx)}
                                  />
                                }
                                label="Local"
                                labelPlacement="start"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
