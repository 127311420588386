import React, { useState, useEffect, useCallback } from 'react';

import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';

// # CUSTOM COMPONENTS
import ProductTable from '../../../components/Product/ProductTable';
import ProductFilters from '../../../components/Product/Back/ProductFilters';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';
import { autocompleteGroupedFormater } from '../../../utils/stringFormater';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function ProductList() {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();

  const token = Cookies.get('jwt');

  // # FETCHED DATAS
  const [datas, setDatas] = useState({
    products: [],
    categories: [],
    subCategories: [],
  });

  // # FILTERS
  const [filters, setFilters] = useState({
    status: 'all',
    category: 'all',
    subCategory: 'all',
    search: '',
  });

  // # STATE
  const [state, setState] = useState({
    loading: true,
    products: [],
    categories: [],
    subCategories: [],
  });

  // # UPDATE HISTORY ON FILTERS CHANGE
  const updateHistory = useCallback(
    () =>
      history.push(
        `/admin/products/list?status=${filters.status}&category=${filters.category}&subCategory=${filters.subCategory}&search=${filters.search}`
      ),
    [filters]
  );

  // # FETCH ALL DATAS
  useEffect(() => {
    // # ALL BASKETS
    axiosGetRequest('/products', token).then((res) => {
      const { products } = res.data.data;

      setDatas((prev) => ({
        ...prev,
        products,
      }));

      setState((prev) => ({
        ...prev,
        products,
      }));
    });

    // # ALL CATEGORIES
    axiosGetRequest('/categories', token).then((res) => {
      const { categories } = res.data.data;
      const subCategoriesFormat = autocompleteGroupedFormater(categories, 'name');

      setDatas((prev) => ({
        ...prev,
        categories: [...subCategoriesFormat],
      }));

      setState((prev) => ({
        ...prev,
        categories: [...subCategoriesFormat],
      }));
    });

    // # ALL SUBCATEGORIES
    axiosGetRequest('/subcategories', token).then((res) => {
      const { subCategories } = res.data.data;
      const subCategoriesFormat = autocompleteGroupedFormater(subCategories, 'name');

      setDatas((prev) => ({
        ...prev,
        subCategories: [...subCategoriesFormat],
      }));

      setState((prev) => ({
        ...prev,
        subCategories: [...subCategoriesFormat],
        loading: false,
      }));
    });

    // # FILTERS
    const filters = new URLSearchParams(search);
    if (filters) {
      for (const el of filters.entries()) {
        if (el[0] === 'status' && el[1] !== 'all') {
          setFilters((prev) => ({
            ...prev,
            [el[0]]: JSON.parse(el[1]),
          }));
        } else {
          setFilters((prev) => ({
            ...prev,
            [el[0]]: el[1],
          }));
        }
      }
    }
  }, [token]);

  // # UPDATE FILTERS AND FETCH DATA FOR UPDATING VUE
  useEffect(() => {
    let initialProducts = datas.products;
    let initialSubCategories = datas.subCategories;

    if (filters.status !== 'all') {
      initialProducts = initialProducts.filter((products) => products.isActive === filters.status);
    }

    if (filters.category !== 'all') {
      initialProducts = initialProducts.filter(
        (products) => products.category.id === filters.category
      );
      initialSubCategories = initialSubCategories.filter(
        (subCategory) => subCategory.category.id === filters.category
      );
    }

    if (filters.subCategory !== 'all') {
      initialProducts = initialProducts.filter(
        (products) => products.subCategory.id === filters.subCategory
      );
    }

    if (filters.search !== '') {
      initialProducts = initialProducts.filter((products) =>
        products.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    setState((prev) => ({
      ...prev,
      products: initialProducts,
      subCategories: initialSubCategories,
    }));

    updateHistory();
  }, [datas.products, datas.subCategories, filters]);

  // # HANDLE FILTERS
  const handleFilters = (filter, data) => {
    setFilters((prev) => ({
      ...prev,
      [filter]: data,
    }));
  };

  return (
    <>
      <div className={classes.flex}>
        <h2>Tous les produits</h2>
        <Button
          type="button"
          variant="contained"
          color="primary"
          href="/admin/products/create"
          startIcon={<AddIcon />}
        >
          Nouveau
        </Button>
      </div>

      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <ProductFilters
            filters={filters}
            categories={state.categories}
            subCategories={state.subCategories}
            handleFilters={handleFilters}
          />

          <ProductTable subcategory={filters.subCategory} data={state.products} />
        </>
      )}
    </>
  );
}
