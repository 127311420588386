import React, {useContext, useState} from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';

// # Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
  IconButton,
  Collapse,
  Box,
  Button,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp, Receipt } from '@material-ui/icons';

// # UTILS
import { totalPrice } from '../../utils/totalOrders';
import { axiosGetRequest } from '../../utils/axiosRequests';
import { CartContext } from '../../contexts/CartContext';
import {toNumber} from "lodash/lang";

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  delivery: {
    color: '#f05c33',
  },
  reduction: {
    color: '#adc00c',
  },
});

function Row(props) {
  const { order } = props;
  const {clearCart, addProduct, setDrawerCart} = useContext(CartContext);

  const token = Cookies.get('jwt');
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleBilling = async () => {
    await axiosGetRequest(`/orders/createBilling/${order.id}`, token)
      .then(() => {
        window.open(
          `https://bioculture-invoices-bucket.s3.eu-west-3.amazonaws.com/facture-${order.id}.pdf`,
          '_blank'
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleReorder = async() => {
    const idArray = order.items.map(item => ({id:  item.productId, quantity: item.quantity, type: item.type === 'basket' ? 'baskets' : 'products'}));
    console.log('order', idArray);
    clearCart();
    await idArray.map(async(el) => await axiosGetRequest(`/${el.type}/${el.id}`, token).then(res => {
      if(el.type === 'baskets') {
        addProduct({...res.data.data.basket, quantity: el.quantity})
      }else {
        console.log(el.type);
        addProduct({...res.data.data.product, quantity: el.quantity})
      }
    }).catch(err => console.log(err)));

    setDrawerCart(true)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{moment(props.order.createdAt).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="center">
          {moment(order.deliveryInfos.deliveryDate).format('DD-MM-YYYY')}
        </TableCell>
        <TableCell align="center">
          <Badge
            badgeContent={
              props.order.status === 'paid'
                ? 'Payé'
                : props.order.status === 'waiting'
                ? 'Attente'
                : props.order.status === 'refund'
                ? 'Remboursé'
                : 'Impayé'
            }
            color={
              props.order.status === 'paid'
                ? 'primary'
                : props.order.status === 'waiting'
                ? 'secondary'
                : props.order.status === 'refund'
                ? 'default'
                : 'error'
            }
          />
        </TableCell>
        <TableCell align="center">{totalPrice(props.order)}</TableCell>
        <TableCell align="center">
          {props.order.billing && (
            <IconButton aria-label="bill" size="small" target="_blank" onClick={handleBilling}>
              <Receipt />
            </IconButton>
          )}
        </TableCell>
        <TableCell align={'center'}>
          {props.order.status && (
              <Button variant={'outlined'} onClick={handleReorder}>Reprendre la commande</Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell>Prix</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.order.items.map((el, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.quantity}</TableCell>
                      <TableCell>{el.price} €</TableCell>
                    </TableRow>
                  ))}
                  {props.order.discount ? (
                    <TableRow>
                      <TableCell className={classes.reduction}>Bon de réduction</TableCell>
                      <TableCell className={classes.reduction}>
                        {props.order.discount.name}
                      </TableCell>
                      <TableCell className={classes.reduction}>
                        {props.order.discount.value}{' '}
                        {props.order.discount.reduction === 'flat' ? '€' : '%'}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  <TableRow>
                    <TableCell className={classes.delivery}>Type de Livraison</TableCell>
                    <TableCell className={classes.delivery}>
                      {props.order.deliveryInfos.deliveryType === 'home' ? 'Domicile' : null}
                      {props.order.deliveryInfos.deliveryType === 'relayPoint'
                        ? 'Point Relais'
                        : null}
                    </TableCell>
                    <TableCell className={classes.delivery}>{props.order.deliveryCost} €</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function Order(props) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Détails</TableCell>
            <TableCell align="center">Date de Création</TableCell>
            <TableCell align="center">Date de Livraison</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Prix</TableCell>
            <TableCell align="center">Facture</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders
            .sort((a, b) => moment(b.createdAt).diff(a.createdAt))
            .map((order, index) => (
              <Row key={order._id} order={order} index={index} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
