import React, { useState, Fragment, useEffect } from 'react';

// # DRAFT JS
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// # MATERIAL UI
import {
  Grid,
  Paper,
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import Cookies from 'js-cookie';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editor: {
    '& .public-DraftStyleDefault-block': {
      margin: 0,
    },
  },
}));

export default function InfosOfTheDay() {
  const token = Cookies.get('jwt');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [infos, setInfos] = useState({
    content: EditorState.createEmpty(),
    dialogOpen: false,
  });

  // # FETCH LAST INFO
  useEffect(() => {
    const fetchLastInfo = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/configs/last-infos`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res.data.data.infos;
          const raw = `{ "entityMap": {}, "blocks": ${JSON.stringify(data.setting.blocks)} }`;
          const editor = EditorState.createWithContent(convertFromRaw(JSON.parse(raw)));

          setInfos((prev) => ({
            ...prev,
            content: editor,
          }));
        })
        .catch((err) => {
          setInfos((prev) => ({
            ...prev,
            content: EditorState.createEmpty(),
          }));
        });
    };

    fetchLastInfo();
  }, [token]);

  const handleEditor = (editorState) => {
    setInfos((prev) => ({
      ...prev,
      content: editorState,
    }));
  };

  const handleDialog = (bool) => {
    setInfos((prev) => ({
      ...prev,
      dialogOpen: bool,
    }));
  };

  const submitInfos = async () => {
    const blocks = convertToRaw(infos.content.getCurrentContent());

    await axios
      .post(
        `${process.env.REACT_APP_API}/orders/mail/dailyReminder`,
        { customInformations: blocks },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar(`Mails envoyés`, {
          variant: 'success',
        });

        setInfos((prev) => ({
          ...prev,
          dialogOpen: false,
        }));
      })
      .catch((err) => {
        const errors = err.response.data;
        console.error(errors);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Informations du jour :</h1>
        <Paper className={classes.paper}>
          <div className={classes.flex}>
            <h3>Contenu de l'email :</h3>
            <Button onClick={() => handleDialog(true)} variant="contained" color="primary">
              Envoyer
            </Button>
            <Dialog open={infos.dialogOpen} onClose={() => handleDialog(false)}>
              <DialogTitle>
                <b>Contenu de l'email du jour :</b>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(convertToRaw(infos.content.getCurrentContent())),
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={() => handleDialog(false)} color="primary">
                  Annuler
                </Button>
                <Button onClick={submitInfos} color="primary">
                  Confirmer
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Editor
            editorState={infos.content}
            wrapperClassName="wrapper-class"
            editorClassName={classes.editor}
            toolbarHidden
            editorStyle={{
              border: '1px solid #F1F1F1',
              minHeight: '150px',
              padding: '1rem',
              marginBottom: '1rem',
            }}
            onEditorStateChange={handleEditor}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
