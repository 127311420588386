import React, { Fragment } from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton';
import { Paper } from '@material-ui/core';

// # STYLE
const useStyles = makeStyles((theme) => ({
  skeletons: {
    display: 'flex',
    marginBottom: '1rem',
    '& > *': {
      width: '250px',
      height: '100px',
      transform: 'none',
      marginRight: '1rem',

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  stats: {
    display: 'flex',
    flexWrap: 'wrap',

    // PAPER
    '& > *': {
      width: '250px',
      height: '100px',
      margin: '0.5rem',
      padding: '0.5rem 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      '&:last-child': {
        marginRight: 0,
      },
      // ICON
      '& > div:first-child': {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          fontSize: '2.5rem',
          fill: '#6c6c6c',
        },
      },
      // STATS
      '& > div:last-child': {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        '& > *': {
          margin: 0,
        },
        '& > h4': {
          textAlign: 'right',
          fontSize: '15px',
          color: '#6c6c6c',
        },
        '& > h3': {
          fontSize: '30px',
        },
      },
    },
  },
}));

export default function StatsRecap(props) {
  const classes = useStyles();

  return (
    <>
      {props.loading && (
        <div className={classes.skeletons}>
          {props.datas.map((el, index) => (
            <Skeleton key={index} />
          ))}
        </div>
      )}

      {!props.loading && (
        <div className={classes.stats}>
          {props.datas.map((el, index) => (
            <Paper key={index}>
              <div>{el.picto}</div>
              <div>
                <h4>{el.name}</h4>
                <h3>{el.value}</h3>
              </div>
            </Paper>
          ))}
        </div>
      )}
    </>
  );
}
