import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';

// # UTILS
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Divider, Hidden } from '@material-ui/core';
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # CONTEXTS
import { UserContext } from '../../../contexts/UserContext/UserContext';

// # COMPONENTS//
import Coordonnees from '../../../components/Front/Coordonees';
import Adresses from '../../../components/Front/Adresses';
import Commandes from '../../../components/Front/Commandes';

// # MATERIAL UI

// # STYLE//
import '../../../assets/css/styleprofil.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'white',
  },
  active: {
    backgroundColor: '#e0e0e0',
  },
}));

export default function Profil(props) {
  const { location } = props;
  const { search } = location;

  const classes = useStyles();

  const [state, setState] = useState({
    orders: [],
    active: '',
  });

  const { user, logout } = useContext(UserContext);

  useEffect(() => {
    const query = new URLSearchParams(search).get('page');

    axiosGetRequest(`/users/myOrders`, user.token).then((res) => {
      setState((prev) => ({
        ...prev,
        orders: [...res.data.data],
        active: query,
      }));
    });
  }, [search, user.token]);

  const removing = () => {
    window.location = '/';
    logout();
  };

  const handleActive = (value) => {
    setState((prev) => ({
      ...prev,
      active: value,
    }));
  };

  return (
    <div id="profil">
      <div className="compte">
        <Hidden smDown>
          <div className="option">
            <div className={classes.root}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button onClick={() => handleActive('coo')}>
                  <ListItemText primary="Mon Compte" />
                </ListItem>
              </List>
              <Divider />
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button onClick={() => handleActive('ad')}>
                  <ListItemText primary="Mes Adresses" />
                </ListItem>
              </List>
              <Divider />
              <List component="nav" aria-label="main mailbox folder">
                <ListItem button onClick={() => handleActive('com')}>
                  <ListItemText primary="Mes Commandes" />
                </ListItem>
              </List>
              <Divider />
              <List component="nav" aria-label="main mailbox folder">
                <ListItem onClick={() => removing()} button>
                  <ListItemText primary="Déconnexion" />
                </ListItem>
              </List>
            </div>
          </div>
        </Hidden>

        {state.active === 'coo' && <Coordonnees />}

        {state.active === 'ad' && <Adresses data={state.data} />}

        {state.active === 'com' && <Commandes orders={state.orders} />}
      </div>
    </div>
  );
}
