import React, { useState, Fragment } from 'react';

import Cookies from 'js-cookie';

// # UTILS

// # MATERIAL UI
import { TextField, Button, Modal, Backdrop, Fade, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { axiosPostRequest } from '../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateWeightModal(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [weight, setWeight] = useState({
    name: '',
    abbreviation: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // # HANDLE INPUT CHANGE
  const handleInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setWeight((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // # HANDLE CREATE
  const handleCreate = (event) => {
    event.preventDefault();
    const token = Cookies.get('jwt');

    axiosPostRequest('/weight/', token, { ...weight })
      .then((res) => {
        setWeight({
          name: '',
          abbreviation: '',
        });
        handleClose();
        props.addNew(res.data.data.newUnity);
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        Nouveau
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Création d'une unité de poids</h2>
            <form className={classes.form} onSubmit={handleCreate}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nom"
                    type="text"
                    variant="outlined"
                    name="name"
                    value={weight.name}
                    onChange={handleInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.marginB}
                    fullWidth
                    label="Abréviation"
                    type="text"
                    variant="outlined"
                    name="abbreviation"
                    value={weight.abbreviation}
                    onChange={handleInput}
                  />
                </Grid>
              </Grid>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Créer
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
