import React, { useContext } from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

// # CONTEXT
import { CartContext } from '../../../contexts/CartContext';

// # UTILS
import ProductBox from './ProductBox';

const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'Bitter !important',
        fontSize: '2em !important',
        fontWeight: 400,
        textAlign: 'center',
    },
}));

export default function CartSummary(props) {
    const { cartItems } = useContext(CartContext);
    const classes = useStyles();

    return (
        <React.Fragment>
            <h3 className={classes.title}>{props.title}</h3>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    {cartItems.map((cartItem, index) => (
                        <Grid item xs={12} md={6} key={`${cartItem.id}_${index}`}>
                            <ProductBox item={cartItem} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </React.Fragment>
    );
}
