import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

import { CartContext } from "../../../contexts/CartContext";

import currencyFormater from '../../../utils/currencyFormater';
import GroupedInfos from "../../../components/Product/Card/GroupedInfos";
import GroupedLabels from "../../../components/Product/Card/GroupedLabels";

const useStyles = makeStyles((theme) => ({
  product: {
    textAlign: 'left'
  },
  image: {
    borderRadius: '5px',
    height: '125px',
    objectFit: 'contain',
    padding: '1em',
    width: '125px',
    marginRight: '1em'
  },
  name: {
    fontFamily: 'Amatic SC',
    fontSize: '34px',
    fontWeight: 700,
    margin: '0 0 5px 0'
  },
  price: {
    color: '#F05C35',
    flexGrow: 1,
    fontSize: '1.5rem',
    margin: 0,
    padding: '1em 1em 0 0',
    textAlign: 'right'
  },
  quantity: {
    lineHeight: '32px',
    margin: 0,
    padding: '0 0.75em',
    textAlign: 'center'
  },
  labels: {
    marginTop: '15px',
  },
  actions: {
    justifyContent: 'flex-end',
    width: '125px',
    padding: '0 1em 1em 0',
    '& > button': {
      borderRadius: '100%',
      height: '32px',
      minWidth: '32px',
      padding: 0,
      width: '32px',
      '& span': {
        margin: 0,
      },
    },
  },
  addButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
  }
}));

export default function ProductBox(props) {
  const classes = useStyles();
  const {
    addProduct,
    cartItems,
    decrease,
    increase,
    removeProduct,
    setDrawerCart,
    clearCart
  } = useContext(CartContext);

  const { item } = props;
  const { photo, name, type, price, quantity, weight, persons } = item;

  const handleDecrease = (item) => {
    if (item.quantity > 1) {
      decrease(item);
    } else {
      removeProduct(item);

      if (cartItems.length === 1) {
        setDrawerCart(false);
        clearCart();
      }
    }
  }

  return (
    <Paper className={classes.product}>
      <Box sx={{ display: 'flex' }}>
        <img
          className={classes.image}
          src={`${process.env.REACT_APP_AWS}/${photo}`}
          alt={name}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
          <p className={classes.name}>{name}</p>
          <Box>
            <GroupedInfos
              type={type}
              infos={{
                weight: { ...weight },
                persons: { ...persons },
              }}
            />
            {props.displayLabels &&
              <Box className={classes.labels}>
                <GroupedLabels labels={item.labels} limit={4} />
              </Box>
            }
          </Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <p className={classes.price}>{currencyFormater(price)}</p>
          <Grid container className={classes.actions}>
            {cartItems.includes(item) &&
              <React.Fragment>
                <Button
                  className={classes.removeButton}
                  variant="outlined"
                  startIcon={<Remove/>}
                  onClick={() => handleDecrease(item)}
                />
                <p className={classes.quantity}>{quantity}</p>
              </React.Fragment>
            }
            <Button
              className={classes.addButton}
              startIcon={<Add/>}
              disabled={item.quantity >= item.stock}
              onClick={() => (cartItems.includes(item) ? increase(item) : addProduct(item))}
            />
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
