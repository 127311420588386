import React, { useState, useEffect, Fragment } from 'react';

// # LIBS
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

import VisibilityIcon from '@material-ui/icons/Visibility';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  TablePagination,
} from '@material-ui/core';
import currencyFormater from '../../utils/currencyFormater';
import {calcTotalOrder} from "../../utils/totalOrders";

const useStyles = makeStyles((theme) => ({
  invit: {
    maxWidth: '285px',
    minWidth: '200px',
    padding: '1rem',
    margin: '10px',
    border: '2px solid #f05c35',
    borderRadius: '20px',
  },
  btn_orange: {
    backgroundColor: '#f05c33',
    color: 'white',
    padding: '0.5rem',
    '&:hover': {
      color: '#9e9e9e',
    },
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
}));

const columns = [
  { id: 'date', label: 'Date de création', minWidth: 50, align: 'center' },
  {
    id: 'deliveryDate',
    label: 'Date de livraison',
    minWidth: 50,
    align: 'center',
  },
  { id: 'participants', label: 'Participants', minWidth: 100, align: 'center' },
  { id: 'total', label: 'Total Payé', minWidth: 100, align: 'center' },
  {
    id: 'modify',
    label: `Voir l'invitation`,
    minWidth: 100,
    align: 'center',
  },
];

function Row(props) {
  const { invit } = props;
  const { url } = props;
  const invitOrders = props.invitationOrders;
  const classes = useStyles();

  const calcTotal = (orders) => {
    if (orders.length === 0) {
      return 0;
    }

    return orders
      .filter((item) => item.status === 'paid')
      .reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0);
  };

  return (
    <TableRow>
      <TableCell align="center">{moment(invit.createdAt).format('LL')}</TableCell>
      <TableCell align="center">{moment(invit.deliveryInfos.deliveryDate).format('LL')}</TableCell>
      <TableCell align="center">
        {invitOrders[invit.id] && `${invitOrders[invit.id].length} / ${invit.contacts.length}`}
      </TableCell>
      <TableCell align="center">
        {invitOrders[invit.id] && currencyFormater(calcTotal(invitOrders[invit.id]))}
      </TableCell>
      <TableCell align="center">
        <IconButton
          className={classes.btn_orange}
          aria-label="bill"
          size="small"
          href={`${url}/${invit.id}`}
        >
          <VisibilityIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default function InvitationTab(props) {
  const classes = useStyles();
  const token = Cookies.get('jwt');
  moment.locale('fr');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { url } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { invitations } = props;

  const [state, setState] = useState({
    invitationOrders: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      invitations.forEach(async (el) => {
        const orders = await axios
          .get(`${process.env.REACT_APP_API}/invitations/orders/${el.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const { orders } = res.data.data;
            return orders;
          })
          .catch((err) => {
            console.log(err);
          });
        setState((prev) => ({
          ...prev,
          invitationOrders: {
            ...prev.invitationOrders,
            [el.id]: [...orders],
          },
        }));
      });
    };
    fetchData();
  }, [invitations, token]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column, key) => (
                <TableCell key={key} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((el, key) => (
              <Row key={key} invit={el} url={url} invitationOrders={state.invitationOrders} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={invitations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
