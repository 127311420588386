import React, {useContext, useEffect, useState} from 'react';

// # CUSTOM COMPONENTS
import { Grid, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ColumnLeft from './ColumnLeft';
import ColumnRight from './ColumnRight';

// # CONTEXT
import {CartContext} from "../../../contexts/CartContext";

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    background: 'white',
    borderRadius: '0 0 4px 0',
    color: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '15px',
    left: '15px',
    cursor: 'pointer',
    fontSize: '2rem',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ProductDrawer(props) {
  const classes = useStyles();
  const {cartItems} = useContext(CartContext);

  const { infos, setDrawer } = props;
  const [producers, setProducers] = useState([]);

  const matchingItem = cartItems.find((item) => item._id === infos._id)

  useEffect(() => {
    if (infos.tags.length > 0) {
      const tagsIdsFormated = infos.tags.map((tag) => `"${tag.id}"`).join(',');
      const limit = infos.type === 'basket' ? '3' : '2';

      axiosGetRequest(`/producers/tags?tags=[${tagsIdsFormated}]&limit=${limit}`).then((res) => {
        const formatedProducers = res.data.data.producers.map((producer) => producer.producer);
        setProducers(formatedProducers);
      });
    }
  }, [infos]);

  return (
    <Grid container>
      <div className={classes.closeBtn}>
        <Close onClick={() => setDrawer(false)} />
      </div>
      <ColumnLeft
        product={infos}
        producers={producers}
        setDrawer={setDrawer}
        matchingItem={matchingItem}
      />
      <ColumnRight product={infos} producers={producers} setDrawer={setDrawer} />
    </Grid>
  );
}
