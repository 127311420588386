import React, { Fragment, useEffect, useState, useRef } from 'react';

import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import Cookies from 'js-cookie';
import axios from 'axios';
import 'moment/locale/fr';

// # UTILS
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Divider,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { calcTotalOrder } from '../../../utils/totalOrders';
import currencyFormater from '../../../utils/currencyFormater';
import formatNum from '../../../utils/numberFormater';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  container: {
    maxHeight: 'fit-content',
  },
  marginB: {
    marginBottom: '1rem',
  },
  flexEnd: {
    padding: '1em 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

class BillingPrint extends React.Component {
  render() {
    const { today, month, infos, segmentPercent } = this.props;

    // # Style
    const base = { padding: '2rem', pageBreakAfter: 'always' };
    const partRelay = { marginBottom: '2rem' };
    const partBioCulture = {
      padding: '1rem',
      border: 'solid 1px black',
      width: 'fit-content',
      margin: '0 0 10% 45%',
    };
    const txt = { fontSize: '14px', lineHeight: '18px', margin: 0 };
    const partTitle = { width: 'fit-content', margin: '0 0 10% 45%' };
    const titles = { fontSize: '18px', lineHeight: '20px', margin: 0 };
    const table = { border: 'solid 1px rgba(224, 224, 224, 1)' };

    return (
      <div>
        {infos.map((info, idx) => (
          <div style={base} key={idx}>
            <div style={partRelay}>
              <p style={txt}>
                <b>{info.relay.name}</b>
              </p>
              <p style={txt}>{info.relay.address}</p>
              <p style={txt}>
                {info.relay.zipCode} {info.relay.city}
              </p>
              {info.relay.phone && <p style={txt}>Tél: {info.relay.phone}</p>}
            </div>

            <div style={partBioCulture}>
              <p style={txt}>
                <b>Bio Culture</b>
              </p>
              <p style={txt}>28 rue de Wolfenbüttel</p>
              <p style={txt}>92310 Sèvres</p>
            </div>

            <div style={partTitle}>
              <h2 style={titles}>Facture du {today}</h2>
              <p style={titles}>Commission pour la période : {month}</p>
            </div>

            <TableContainer style={table}>
              <Table stickyHeader aria-label="sticky table">
                <TableBody>
                  <TableRow>
                    <TableCell>CA HT Livré</TableCell>
                    <TableCell>{currencyFormater(info.total.ht)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commission : Taux appliquable au CA</TableCell>
                    <TableCell>{segmentPercent}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commission : Montant HT</TableCell>
                    <TableCell>
                      {currencyFormater(info.total.ht * (segmentPercent / 100))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TVA : Taux appliquable</TableCell>
                    <TableCell>20%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TVA : Montant à payer</TableCell>
                    <TableCell>
                      {currencyFormater(info.total.ht * (segmentPercent / 100) * 0.2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Total TTC à payer</strong>
                    </TableCell>
                    <TableCell>
                      <strong>
                        {currencyFormater(
                          formatNum(
                            info.total.ht * (segmentPercent / 100) +
                              info.total.ht * (segmentPercent / 100) * 0.2
                          )
                        )}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </div>
    );
  }
}

function Row(props) {
  const { order } = props;

  return (
    <TableRow>
      <TableCell>
        <Link href={`/admin/orders/${order.id}`}>{order.id.slice(-10)}</Link>
      </TableCell>
      <TableCell>{order.owner}</TableCell>
      <TableCell>{moment(order.deliveryDate).format('DD-MM-YYYY')}</TableCell>
      <TableCell>{currencyFormater(order.formatTotalHt)}</TableCell>
      <TableCell>{currencyFormater(order.formatTotalTtc)}</TableCell>
    </TableRow>
  );
}

export default function Commission() {
  moment.locale('fr');
  const classes = useStyles();

  const today = moment();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [data, setData] = useState({
    relayPoints: [],
    last12months: [],
    segments: [],
    orders: [],
    ordersData: [],
    ordersUnitTotals: [],
    ordersTotalHt: 0,
    segmentPercent: 0,
  });

  const [filter, setFilter] = useState({
    advStatus: 'archived',
    period: 'month',
    month: '',
    segment: '',
    date: 'delivery',
    relay: 'all',
  });

  // INFOS : FETCH UNMUTABLE DATAS
  useEffect(() => {
    const fetchData = async () => {
      const segments = await axios
        .get(`${process.env.REACT_APP_API}/segments`)
        .then((res) => res.data.data.segments)
        .catch((err) => {
          console.log(err.response);
        });

      const months = [];
      const dateStart = moment();
      const dateEnd = moment().subtract(12, 'month');
      while (dateStart.diff(dateEnd, 'months') >= 0) {
        months.push(dateStart.format('YYYY-MM'));
        dateStart.subtract(1, 'month');
      }

      setData((prev) => ({
        ...prev,
        last12months: [...months],
        segments: [...segments],
        segmentPercent: segments[0].amount,
      }));

      setFilter((prev) => ({
        ...prev,
        month: moment().format('YYYY-MM'),
        segment: segments[0].id,
      }));
    };
    fetchData();
  }, []);

  // # ON SELECT SEGMENT CHANGE FETCH RELAY MATCHING
  useEffect(() => {
    const token = Cookies.get('jwt');
    const fetchData = async () => {
      const relays = await axios
        .get(`${process.env.REACT_APP_API}/relayPoints?segment=${filter.segment}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.data.relayPoints)
        .catch((err) => {
          console.log(err);
        });

      setData((prev) => ({
        ...prev,
        relayPoints: [...relays],
      }));
    };

    if (filter.segment !== '') {
      fetchData();
    }
  }, [filter.segment]);

  // # PREPARE STATS PER ORDER
  const handleSetUp = async (orders) => {
    const relays = data.relayPoints;

    const tempArr = [];

    await orders.forEach(async (order, idx) => {
      if (order.length > 0) {
        const relay = relays[idx];
        const objStats = {};

        // # ADD ORDERS TO KEY
        objStats.orders = await order.map((el) => ({
          id: el._id,
          deliveryDate: el.deliveryInfos.deliveryDate,
          owner: el.owner.fullname,
          ...calcTotalOrder(el),
        }));

        // # ADD RELAY INFOS
        objStats.relay = {
          id: relay._id,
          name: relay.name,
          address: relay.address.address,
          zipCode: relay.city.zipCode,
          city: relay.city.name,
          phone: relay.phone,
        };

        // # ADD TOTAL HT
        objStats.total = {
          ht: objStats.orders.reduce((acc, curr) => acc + curr.formatTotalHt, 0),
          ttc: objStats.orders.reduce((acc, curr) => acc + curr.formatTotalTtc, 0),
        };

        // # ADD TO TEMP OBJ
        tempArr.push(objStats);
      }
    });

    setData((prev) => ({
      ...prev,
      ordersData: [...tempArr],
      loading: false,
    }));
  };

  const handleFetch = async () => {
    setData((prev) => ({
      ...prev,
      loading: true,
    }));

    // INFOS : PREPARE FILTER OBJECT FOR API
    const fields = {};
    fields.advStatus = filter.advStatus;
    fields.period = filter.period;

    if (filter.relay !== 'all') {
      fields.segmentRelay = filter.relay;
    } else {
      fields.arrRelayIds = data.relayPoints.map((relay) => relay._id);
    }

    if (filter.period === 'month') {
      fields.month = filter.month;
    }

    fields.date = filter.date;

    fields.needOrders = true;

    const token = Cookies.get('jwt');
    await axios
      .post(
        `${process.env.REACT_APP_API}/orders/dashboard/`,
        { fields },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const { orders } = res.data;
        const ordersMatchingRelay = data.relayPoints.map((relay) =>
          orders.filter((order) => order.deliveryInfos.deliveryId === relay._id)
        );

        handleSetUp(ordersMatchingRelay);

        setData((prev) => ({
          ...prev,
          orders: [...ordersMatchingRelay],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilter = (event) => {
    event.persist();

    const { name, value } = event.target;

    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (data.orders.length > 0) {
      setData((prev) => ({
        ...prev,
        orders: [],
      }));
    }

    if (name === 'segment') {
      const matchingSegment = data.segments.find((segment) => segment.id === value);

      setData((prev) => ({
        ...prev,
        segmentPercent: matchingSegment.amount,
      }));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <h2>Commissions</h2>
      </div>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Statut</InputLabel>
              <Select
                label="advStatus"
                value={filter.advStatus}
                name="advStatus"
                onChange={handleFilter}
              >
                <MenuItem value="input">En cours de saisie</MenuItem>
                <MenuItem value="validate">Validées</MenuItem>
                <MenuItem value="processed">Traitées</MenuItem>
                <MenuItem value="archived">Archivées</MenuItem>
                <MenuItem value="canceled">Annulées</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Mois</InputLabel>
              <Select label="Mois" name="month" value={filter.month} onChange={handleFilter}>
                {data.last12months.map((month, idx) => (
                  <MenuItem key={idx} value={month}>
                    {moment(month).format('MMMM YYYY')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Segment</InputLabel>
              <Select label="Segment" value={filter.segment} name="segment" onChange={handleFilter}>
                {data.segments.map((segment, idx) => (
                  <MenuItem key={idx} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Point de livraison</InputLabel>
              <Select
                label="Point de livraison"
                value={filter.relay}
                name="relay"
                onChange={handleFilter}
              >
                <MenuItem value="all">Tous</MenuItem>
                {data.relayPoints.map((relay, idx) => (
                  <MenuItem key={idx} value={relay.id}>
                    {relay.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Date</InputLabel>
              <Select label="date" value={filter.date} name="date" onChange={handleFilter}>
                <MenuItem value="payment">Date de commande</MenuItem>
                <MenuItem value="delivery">Date de livraison</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleFetch}>
              Charger
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {data.loading !== undefined &&
        (data.loading ? (
          <Skeleton variant="rect" width="100%" height={150} />
        ) : (
          data.ordersData.length > 0 && (
            <>
              <div className={classes.flexEnd}>
                <div style={{ overflow: 'hidden', height: 0, width: 0 }}>
                  <BillingPrint
                    ref={componentRef}
                    today={today.format('DD/MM/YYYY')}
                    month={moment(filter.month).format('MMMM YYYY')}
                    segmentPercent={data.segmentPercent}
                    infos={data.ordersData}
                  />
                </div>
                <Button variant="contained" color="primary" onClick={() => handlePrint()}>
                  {filter.relay === 'all' ? 'Imprimer les factures' : 'Imprimer la facture'}
                </Button>
              </div>

              {data.ordersData.sort((a,b) => a.relay.name - b.relay.name).map((elem, idx) => (
                <Fragment key={idx}>
                  <h2>
                    {elem.relay.name} - {moment(filter.month).format('MMMM YYYY')}
                  </h2>
                  <Paper className={classes.paper}>
                    <div className={classes.root}>
                      <h3>Détails des livraisons</h3>
                      <h3>Total HT : {currencyFormater(elem.total.ht)}</h3>
                      <h3>Total TTC : {currencyFormater(elem.total.ttc)}</h3>
                    </div>
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Commande ID</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Date de livraison</TableCell>
                            <TableCell>Total HT</TableCell>
                            <TableCell>Total TTC</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {elem.orders
                            .sort((a, b) => moment(a.deliveryDate).diff(moment(b.deliveryDate)))
                            .map((order, idx) => (
                              <Row key={idx} order={order} />
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={7}>
                      <Paper className={classes.paper}>
                        <TableContainer className={classes.container}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                              <TableRow>
                                <TableCell>CA HT Livré</TableCell>
                                <TableCell>{currencyFormater(elem.total.ht)}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Commission : Taux appliquable au CA</TableCell>
                                <TableCell>{data.segmentPercent}%</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Commission : Montant HT</TableCell>
                                <TableCell>
                                  {currencyFormater(elem.total.ht * (data.segmentPercent / 100))}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>TVA : Taux appliquable</TableCell>
                                <TableCell>20%</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>TVA : Montant à payer</TableCell>
                                <TableCell>
                                  {currencyFormater(
                                    elem.total.ht * (data.segmentPercent / 100) * 0.2
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <strong>Total TTC à payer</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {currencyFormater(
                                      formatNum(
                                        elem.total.ht * (data.segmentPercent / 100) +
                                          elem.total.ht * (data.segmentPercent / 100) * 0.2
                                      )
                                    )}
                                  </strong>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: '1rem 0' }} />
                </Fragment>
              ))}
            </>
          )
        ))}
    </>
  );
}
