import React, { useState, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import { useSnackbar } from 'notistack';

// # MATERIAL UI
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Grid,
  Modal,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  IconButton,
  Drawer, TextField,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

// * ICONS
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import BuildIcon from '@material-ui/icons/Build';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

// # CONTEXT
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext/UserContext';

// # UTILS
import { getSchedules } from '../utils/getSchedules';
import {axiosGetRequest} from "../utils/axiosRequests";
import ProductCard from "./Product/ProductCard";

const useStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',

    '& h2': {
      textAlign: 'center',
      color: '#f05c34',
    },
  },
  fullList: {
    width: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexShrink: 0,
    fontFamily: 'Bitter',
    textTransform: 'uppercase',
    color: '#7c7c7c',
  },
  headingBlog: {
    fontSize: theme.typography.pxToRem(20),
    flexShrink: 0,
    fontFamily: 'Bitter',
    textTransform: 'uppercase',
    color: 'white',
  },
  a: {
    fontFamily: 'Bitter',
    color: '#7c7c7c',
  },
  aBlog: {
    fontFamily: 'Bitter',
    color: 'white',
  },
  a_secondary: {
    fontFamily: 'Amatic SC',
    color: 'white',
  },
  backGround: {
    backgroundColor: '#ECECEC',
  },
  backGroundBlog: {
    backgroundColor: '#f57c47',
  },
  textField: {
    '& > div': {
      borderRadius: '30px !important',
    },
    '& > div:hover': {
      '& > fieldset': {
        borderColor: '#adc00d !important'
      }
    },
    '& > div > input': {
      color: 'grey'
    },
    '& > div > fieldset': {
      border: 'solid 2px #f57c47',
    },
  },
  search: {
    width: '90vw',
    display: 'flex',
    overflowX: 'scroll'
  },
  productCard: {
    marginRight: '1rem',
  },
}));

export default function Menu() {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [code, setCode] = useState();
  const [open, setOpen] = useState(false);
  const [delivery, setDelivery] = useState({});
  const [info, setInfo] = useState(false);
  const [schedule, setSchedule] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState({
    search: '',
    results: []
  });

  const { setDeliveryInfos, setDeliveryAddress, deliveryInfos, setDiscount, setCompanyDiscount } =
    useContext(CartContext);
  const { user } = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className="modal">
      <h2>Entrez votre code entreprise</h2>
      <div className="code">
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12}>
            <p>Le code entreprise est désormais à renseigner sur la page livraison</p>
          </Grid>
          <Grid item xs={12}>
            <button className="btn-orange" type="button" onClick={() => handleClose()}>
              OK
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer(open);
  };

  const list = (anchor) => (
    <div className="cadre">
      <IconButton className="buttonprofil" aria-label="menu" onClick={toggleDrawer(false)}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <ul className="menu">
        <Accordion>
          <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.heading}>
              <a className={classes.a} href="/catalogue/les-paniers-bio">
                Je commande
              </a>
            </Typography>
          </AccordionSummary>
        </Accordion>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>Nos valeurs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a className={classes.a} href="/valeurs">
                  Nos valeurs
                </a>
              </li>
              <li>
                <a className={classes.a} href="/nos-producteurs">
                  Nos producteurs
                </a>
              </li>
              <li>
                <a className={classes.a} href="/nos-produits">
                  Nos produits
                </a>
              </li>
              <li>
                <a className={classes.a} href="/#">
                  Nos recettes
                </a>
              </li>
              <li>
                <a className={classes.a} href="/zero-dechets">
                  Zéro déchet
                </a>
              </li>
              <li>
                <a className={classes.a} href="/#">
                  Devenez Eco-acteur
                </a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>Entreprises</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a className={classes.a} href="/offre-entreprise">
                  Une offre adaptée
                </a>
              </li>
              <li>
                <a className={classes.a} href="/corbeille-fruit">
                  Corbeille de fruits
                </a>
              </li>
              <li>
                <a onClick={handleOpen}>Choisissez un code Entreprise</a>
              </li>
            </ul>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>éco-acteurs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a className={classes.a} href="/eco-points">
                  Ecopoints
                </a>
              </li>
              <li>
                <a className={classes.a} href="/eco-acteur">
                  Devenir eco-acteur
                </a>
              </li>
              <li>
                <a className={classes.a} href="/c-c-m">
                  Comment ça marche
                </a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>rejoindre la coopérative</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a className={classes.a} href="https://la-cooperative-bio-culture.fr/">
                  La Coopérative d'intérêt collectif
                </a>
              </li>
              <li>
                <a
                  className={classes.a}
                  href="https://la-cooperative-bio-culture.fr/nous-connaitre"
                >
                  Qui sommes-nous ?
                </a>
              </li>
              <li>
                <a className={classes.a} href="https://la-cooperative-bio-culture.fr/adherer">
                  Je me lance
                </a>
              </li>
              <li>
                <a className={classes.a} href="https://la-cooperative-bio-culture.fr/tout-savoir">
                  Tout savoir
                </a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.heading}>
              <a className={classes.a} href="/blog">
                Recettes
              </a>
            </Typography>
          </AccordionSummary>
        </Accordion>
      </ul>

      {user.role === 'eco' ? (
        <ul className="menu">
          <Accordion
            className={classes.backGround}
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <MailIcon
                style={{
                  color: '7c7c7c',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              />
              <Typography className={classes.heading}>Invitations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <a className={classes.a} href="/create_invitation">
                    Créer une inviation
                  </a>
                </li>
                <li>
                  <a className={classes.a} href="/invitations">
                    Mes invitations
                  </a>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classes.backGround}
            expanded={expanded === 'panel6'}
            onChange={handleChange('panel6')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <PermContactCalendarIcon
                style={{
                  color: '7c7c7c',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              />
              <Typography className={classes.heading}>Contacts</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <a className={classes.a} href="/create_contact">
                    Ajouter des contacts
                  </a>
                </li>
                <li>
                  <a className={classes.a} href="/contact_list">
                    Mes contacts
                  </a>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.backGround} onChange={handleChange('panel7')}>
            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
              <LocalOfferIcon
                style={{
                  color: '7c7c7c',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              />
              <Typography className={classes.heading}>
                <a className={classes.a} href="/vos-eco-points">
                  Mes Eco-points
                </a>
              </Typography>
            </AccordionSummary>
          </Accordion>

          <Accordion
            className={classes.backGround}
            expanded={expanded === 'panel8'}
            onChange={handleChange('panel8')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <BuildIcon
                style={{
                  color: '7c7c7c',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              />
              <Typography className={classes.heading}>Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <a className={classes.a} href="/contact">
                    Poser une question
                  </a>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </ul>
      ) : null}
    </div>
  );

  return (
    <div id="menu">
      <IconButton className="buttonprofil" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon color="primary" fontSize="large" />
      </IconButton>
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
        {list('right')}
      </Drawer>
    </div>
  );
}
