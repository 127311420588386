import React, { useState } from 'react';

import Cookies from 'js-cookie';

// # UTILS

// # MATERIAL UI
import { Paper, Button, Input, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { axiosPostRequest } from '../../utils/axiosRequests';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
    padding: '1rem',

    '& > div:first-child': {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '& > div:last-child': {
      width: '50%',

      '& > div': {
        marginBottom: '1rem',
      },
    },

    '& figure': {
      margin: '0',
      width: '100%',

      '& img': {
        width: '100%',
      },
    },
  },
});

const initialState = {
  photo: null,
  name: '',
  description: '',
};

export default function AddTeam(props) {
  const token = Cookies.get('jwt');
  const classes = useStyles();

  const { addNewTeam } = props;

  const [state, setState] = useState({
    newTeam: { ...initialState },
  });

  // # HANDLE IMAGE UPLOAD
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setState((prev) => ({
      ...prev,
      newTeam: {
        ...prev.newTeam,
        photo: file,
      },
    }));
  };

  // # HANDLE CHANGES
  const handleNewTeam = (e) => {
    e.persist();
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      newTeam: {
        ...prev.newTeam,
        [name]: value,
      },
    }));
  };

  // # HANDLE CREATE NEW TEAM REQUEST
  const handleCreateTeam = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', state.newTeam.name);
    formData.append('description', state.newTeam.description);
    formData.append('photo', state.newTeam.photo);

    axiosPostRequest(`/teams/`, token, formData).then((res) => {
      const { newTeam } = res.data.data;
      setState((prev) => ({
        ...prev,
        newTeam: { ...initialState },
      }));
      addNewTeam(newTeam);
    });
  };

  return (
    <Paper className={classes.root}>
      <div>
        <figure>
          {state.newTeam.photo ? (
            <img src={URL.createObjectURL(state.newTeam.photo)} alt="upload" />
          ) : (
            <img src={`${process.env.REACT_APP_AWS}/product-no-img.jpeg`} alt="missing" />
          )}
        </figure>
        <Input type="file" onChange={handleFileChange} />
      </div>
      <div>
        <TextField
          fullWidth
          name="name"
          label="Nom"
          type="text"
          variant="outlined"
          value={state.newTeam.name}
          onChange={(e) => handleNewTeam(e)}
        />
        <TextField
          fullWidth
          name="description"
          label="Description"
          type="text"
          variant="outlined"
          value={state.newTeam.description}
          onChange={(e) => handleNewTeam(e)}
        />
        <Button fullWidth variant="contained" color="primary" onClick={handleCreateTeam}>
          Valider
        </Button>
      </div>
    </Paper>
  );
}
