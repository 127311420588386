import React, { useState, useEffect, Fragment } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router';

import {
  Grid,
  Paper,
  Chip,
  Button,
  Switch,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Link,
} from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBot: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editing: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      maxWidth: '70%',
      marginBottom: '1rem',
    },
  },
  marginB: {
    margin: '0 0 1rem 0',
  },
  marginR: {
    margin: '0 1rem 0 0',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '316px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

function EditingT(props) {
  return (
    <section>
      <div className={props.classes.editing}>
        <FormControlLabel
          control={
            <Switch
              checked={props.subCategoryUpdate.isActive}
              name="isActive"
              onClick={props.handleSwitch}
            />
          }
          label={props.subCategoryUpdate.isActive ? 'Actif' : 'Inactif'}
        />
        <TextField
          className={props.classes.marginB}
          label="Nom"
          variant="outlined"
          type="text"
          size="small"
          name="name"
          defaultValue={props.subCategoryUpdate.name}
          onChange={props.handleChange}
        />
        <FormControl variant="outlined" className={props.classes.marginB}>
          <InputLabel>Rubrique</InputLabel>
          <Select
            native
            label="Rubrique"
            defaultValue={props.subCategoryUpdate.category}
            name="category"
            onChange={props.handleChange}
          >
            <option value="" />
            {props.categories.map((el, index) => (
              <option key={index} value={el._id}>
                {el.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <TextField
          className={props.classes.marginB}
          label="Description"
          variant="outlined"
          type="text"
          size="small"
          multiline
          name="description"
          defaultValue={props.subCategoryUpdate.description}
          onChange={props.handleChange}
        />
      </div>
      <div className={props.classes.flexBot}>
        <Button variant="contained" color="primary" onClick={props.handleUpdate}>
          Valider les modifications
        </Button>
      </div>
    </section>
  );
}

function EditingF(props) {
  return (
    <section>
      <Chip
        size="small"
        label={props.subCategory.isActive ? 'Actif' : 'Inactif'}
        color={props.subCategory.isActive ? 'primary' : 'secondary'}
      />
      <p>
        <b>ID :</b> {props.subCategory._id}
      </p>
      <p>
        <b>Nom :</b> {props.subCategory.name}
      </p>
      <p>
        <b>Rubrique : </b>
        {props.subCategory.category ? (
          <Link href={`/admin/categories/${props.subCategory.category._id}`}>
            {props.subCategory.category.name}
          </Link>
        ) : (
          'Aucune rubrique associé'
        )}
      </p>
      <p>
        <b>Description :</b>
        <br />
        {props.subCategory.description}
      </p>
    </section>
  );
}

export default function SubCategoryDetails() {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    loading: true,
    editing: false,
    subCategory: {},
    subCategoryUpdate: {
      name: null,
      category: null,
      description: null,
      isActive: null,
    },
    categories: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const subCategory = await axios
        .get(`${process.env.REACT_APP_API}/subCategories/${id}`)
        .catch((err) => {
          console.log(err);
        });

      const categories = await axios
        .get(`${process.env.REACT_APP_API}/categories/`)
        .catch((err) => {
          console.log(err);
        });

      if (subCategory.data.data.subCategory.category) {
        setState((prev) => ({
          ...prev,
          loading: false,
          subCategory: subCategory.data.data.subCategory,
          categories: [...categories.data.data.categories],
          subCategoryUpdate: {
            name: subCategory.data.data.subCategory.name,
            category: subCategory.data.data.subCategory.category._id,
            description: subCategory.data.data.subCategory.description,
            isActive: subCategory.data.data.subCategory.isActive,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          loading: false,
          subCategory: subCategory.data.data.subCategory,
          categories: [...categories.data.data.categories],
          subCategoryUpdate: {
            ...prev.subCategoryUpdate,
            name: subCategory.data.data.subCategory.name,
            description: subCategory.data.data.subCategory.description,
            isActive: subCategory.data.data.subCategory.isActive,
          },
        }));
      }
    };
    fetchData();
  }, [id]);

  const handleEditing = (bool) => {
    setState((prev) => ({
      ...prev,
      editing: bool,
    }));
  };

  const handleSwitch = (event) => {
    setState((prev) => ({
      ...prev,
      subCategoryUpdate: {
        ...prev.subCategoryUpdate,
        isActive: event.target.checked,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      subCategoryUpdate: {
        ...prev.subCategoryUpdate,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleUpdate = async () => {
    const token = Cookies.get('jwt');

    await axios
      .patch(`${process.env.REACT_APP_API}/subCategories/${id}`, state.subCategoryUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        enqueueSnackbar(`Une problème est survenu`, {
          variant: 'error',
        });
      });
  };

  const handleDelete = async () => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/subCategories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = '/admin/subcategories';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <div className={classes.flexTop}>
            <h1>Sous-rubrique : {state.subCategory.name}</h1>
            {state.editing ? (
              <div>
                <Button
                  className={classes.marginR}
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleDelete()}
                >
                  Supprimer
                </Button>
                <Button variant="contained" color="primary" onClick={(e) => handleEditing(false)}>
                  Annuler
                </Button>
              </div>
            ) : (
              <div className={classes.margin}>
                <div>
                  <Button variant="contained" color="primary" onClick={(e) => handleEditing(true)}>
                    Modifier
                  </Button>
                </div>
              </div>
            )}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <h3>Informations :</h3>
                {state.editing ? (
                  <EditingT
                    classes={classes}
                    subCategoryUpdate={state.subCategoryUpdate}
                    categories={state.categories}
                    handleSwitch={handleSwitch}
                    handleChange={handleChange}
                    handleUpdate={handleUpdate}
                  />
                ) : (
                  <EditingF subCategory={state.subCategory} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
