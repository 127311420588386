import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';

// STYLE//
import '../assets/css/navbar.css';

// MATERIAL UI
import {
  TextField,
  Button,
  Collapse,
  CardActionArea,
  Typography,
  Chip,
  Divider,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Alarm, People, Person, MenuBook, Whatshot, Kitchen, HomeRounded, LibraryBooks, EmojiObjects, Search, WbSunny} from "@material-ui/icons";
import MenuBlog from "./MenuBlog";

const useStyles = makeStyles((theme) => ({
  a: {
    textTransform: 'capitalize',
  },
  link: {
    color: 'white !important',
  },
  navbar: {
    backgroundColor: '#F05C35 !important',
  },
  textField: {
    width: '940px',
    '& > div': {
      borderRadius: '30px !important',
    },
    '& > div:hover': {
      '& > fieldset': {
        borderColor: '#adc00d !important',
      },
    },
    '& > div > input': {
      color: 'white',
    },
    '& > div > fieldset': {
      border: 'solid 2px white',
    },
  },
  search: {
    padding: '0 1rem 1rem 1rem',
  },
  logo: {
    color: 'white',
  },
  article: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderRadius: '14px',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    minHeight: '271px',
    width: '200px',
    '&:hover': {
      boxShadow: 'none',
      '& > div > p': {
        transition: '0.5s',
        backgroundColor: '#ADC00D',
        '&>a': {
          color: 'white',
        },
      },
    },
  },
  container: {
    display: 'flex',
    overflow: 'scroll',
    padding: '1rem 0rem 0rem 2rem',
  },
  text: {
    padding: '1rem',
  },
  cardLlink: {
    padding: '0.5rem !important',
    textAlign: 'center !important',
    borderRadius: '0 0 14px 0',
  },
  cardA: {
    color: '#ADC00D',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    border: '1px solid rgb(0 0 0 0 70%)',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
      '& > div > div > div:nth-child(1) > h5': {
        color: '#f05c35',
      },
      '& > div > div > div:nth-child(2) > p': {
        transition: '0.2s',
        backgroundColor: '#ADC00D',
        color: 'white',
        '& > a': {
          color: 'white',
        },
      },
    },
    borderRadius: '14px',
  },
  dropdown: {
    backgroundColor: '#F05C35 !important',
  },
  margin: {
    margin: '0 1rem 1rem 0',
  },
}));

export default function NavbarBlog() {
  const classes = useStyles();
  const [state, setState] = useState({
    tags: [],
    selectedTags: [],
    articles: null,
  });
  const [open, setOpen] = useState(false);

  const handleTag = (temp) => {
    console.log(temp);
    const tags = temp.map((tag) => tag.sys.id);
    setState((prev) => ({
      ...prev,
      selectedTags: [...tags],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          'https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/tags?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&limit=300'
        )
        .then((res) =>
          setState((prev) => ({
            ...prev,
            tags: res.data.items,
          }))
        )
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const articles = await axios
        .get(
          `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&content_type=recettes&metadata.tags.sys.id[all]=${state.selectedTags}`
        )
        .then((res) => res.data.items)
        .catch((err) => {
          console.log(err);
        });

      articles.forEach(async (article) => {
        await axios
          .get(
            `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/assets/${article.fields.image.sys.id}?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8`
          )
          .then((res) => {
            setState((prev) => ({
              ...prev,
              loading: false,
            }));
            return (article.fields.imageURL = res.data.fields.file.url);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      setState((prev) => ({
        ...prev,
        articles,
      }));
    };
    fetchData();
  }, [state.selectedTags]);

  return (
    <div id="navbareco" className={classes.navbar}>
      <div className={`nav-navbareco ${classes.navbar}`}>
        <ul>
          <li>
            <Link to="/blog" className={classes.link}>
              <HomeRounded style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Accueil
            </Link>
          </li>
          <li>
            <a className={classes.link} href="/#" onClick={(e) => e.preventDefault()}>
              <WbSunny style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Recettes de saison
            </a>
            <ul className={`dropdown ${classes.dropdown}`}>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-ete',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Eté
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-automne',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Automne
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-hiver',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Hiver
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-printemps',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Printemps
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a className={classes.link} href="/#" onClick={(e) => e.preventDefault()}>
              <Kitchen style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Les bons petits plats
            </a>
            <ul className={`dropdown ${classes.dropdown}`}>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-aperitif',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Apéritif
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-entree',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Entrée
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-plat-principal',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Plat principal
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-dessert',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Déssert
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-gouter',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Goûter
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-sans-gluten',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Sans gluten
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-vegetarien',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Végétarien
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="/#" onClick={(e) => e.preventDefault()} className={classes.link}>
              <EmojiObjects style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Les recettes créatives
            </a>
            <ul className={`dropdown ${classes.dropdown}`}>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-apero-bio',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Les Apéros Bio
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-les-salades-gourmandes',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Les Salades Gourmandes
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-les-popottes-du-dimanche',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Les popottes du dimanche
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-les-soupes-creatives',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Les soupes créatives
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-les-intemporelles',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Les intemporelles
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/blog/filter/cat-les-anti-gaspi',
                    state: {
                      contentType: 'recettes',
                    },
                  }}
                  className={classes.link}
                >
                  Les anti-gaspi
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to={{
                pathname: '/blog/filter/cat-les-actus-de-bio-culture',
                state: {
                  contentType: 'lesActusDuBio',
                },
              }}
              className={classes.link}
            >
              <LibraryBooks style={{ verticalAlign: 'middle', marginRight: '10px' }} />
              Les Actus Bio
            </Link>
          </li>
        </ul>
        <div>
          <Button onClick={() => setOpen(true)}>
            <Search className={classes.logo} />
          </Button>
        </div>
      </div>
      <Collapse in={open}>
        <Autocomplete
          multiple
          className={classes.search}
          id="tags-outlined"
          size="small"
          options={state.tags}
          limitTags={10}
          getOptionLabel={(option) => option.name.split('.')[1]}
          filterSelectedOptions
          onChange={(_event, tags) => {
            handleTag(tags);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Recherche"
              className={classes.textField}
            />
          )}
        />
        <div className={classes.container}>
          {state.articles &&
            state.articles.map((el, idx) => (
              <Link
                className={classes.margin}
                key={idx}
                to={{
                  pathname: `/blog/${el.fields.slug}`,
                  search: `?contentType=recettes`,
                  state: {
                    article: el,
                  },
                }}
              >
                <CardActionArea onClick={() => setOpen(false)} className={classes.actions}>
                  <Card className={classes.article}>
                    <CardMedia
                      component="img"
                      alt="image"
                      height="100"
                      image={el.fields.imageURL}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography className={classes.h5} component="h5" variant="h5">
                        {el.fields.title}
                      </Typography>
                      <Typography variant="subtitle" color="textSecondary">
                        Publié le {moment(el.fields.data).format('LL')}
                      </Typography>
                      {el.fields.infos && (
                        <div
                          style={{
                            marginTop: '1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Chip
                            className={classes.chip}
                            avatar={<People />}
                            color="secondary"
                            label={el.fields.infos[0].split(' ')[1]}
                          />
                          <Chip
                            className={classes.chip}
                            avatar={<Alarm />}
                            color="secondary"
                            label={`${el.fields.infos[1].split(' ')[1]} min`}
                          />
                          <Chip
                            className={classes.chip}
                            avatar={<Whatshot />}
                            color="secondary"
                            label={`${el.fields.infos[2].split(' ')[1]} min`}
                          />
                        </div>
                      )}
                    </CardContent>
                    <div>
                      <Divider className={classes.divider} />
                      <Typography className={classes.link}>
                        <Link
                          to={{
                            pathname: `/blog/${el.fields.slug}`,
                            search: `?contentType=recettes`,
                            state: {
                              article: el,
                            },
                          }}
                          className={classes.cardA}
                        >
                          Lire la suite
                        </Link>
                      </Typography>
                    </div>
                  </Card>
                </CardActionArea>
              </Link>
            ))}
        </div>
      </Collapse>
    </div>
  );
}
