import React, { useState, useEffect, useContext, Fragment } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import {
  TextField,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import Meta from '../../utils/metaDescription';

// CONTEXT
import { UserContext } from '../../contexts/UserContext/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: '0px',
    fontWeight: '200px',
  },
  div: {
    width: '100%',
  },
  paperDiscount: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    color: 'white',
    backgroundColor: '#f05c35',
  },
  paperDiscountDisable: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    color: '#B2B2B2',
    backgroundColor: '#d8d8d8',
  },
  input: {
    width: '100%',
  },
  button: {
    marginTop: '20px',
  },
}));

export default function Coordonnees(props) {
  const [editing, setEditing] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, setInfos } = useContext(UserContext);
  const [state, setState] = useState({
    personalData: {
      firstname: '',
      lastname: '',
      fullname: '',
      phoneNumber: '',
      email: '',
      complement: '',
      newsLetter: false,
    },
    discounts: [],
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      personalData: {
        firstname: user.personalData.firstname,
        lastname: user.personalData.lastname,
        fullname: user.personalData.fullname,
        email: user.personalData.email,
        phoneNumber: user.personalData.phoneNumber,
        complement: user.personalData.complement,
        newsLetter: user.personalData.newsLetter,
      },
    }));

    const fetchData = async () => {
      const jwt = Cookies.get('jwt');
      await axios
        .get(`${process.env.REACT_APP_API}/discounts?owner=${user.id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => {
          setState((prev) => ({
            ...prev,
            discounts: res.data.data.discounts,
          }));
        });
    };
    fetchData();
  }, [user]);

  const handleUpdate = (e, key) => {
    e.persist();
    let { fullname } = state.personalData;
    if (key === 'firstname') {
      fullname = `${e.target.value} ${state.personalData.lastname}`;
    } else if (key === 'lastname') {
      fullname = `${state.personalData.firstname} ${e.target.value}`;
    }
    setState((prev) => ({
      ...prev,
      personalData: {
        ...prev.personalData,
        fullname,
        [key]: e.target.value,
      },
    }));
  };

  const handleSwitch = (type) => {
    setState((prev) => ({
      ...prev,
      personalData: {
        ...prev.personalData,
        newsLetter: !prev.personalData.newsLetter,
      },
    }));
  };

  const handleEditing = () => {
    setEditing(!editing);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = Cookies.get('jwt');
    axios
      .patch(`${process.env.REACT_APP_API}/users/updateMe`, state.personalData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setInfos(state.personalData);
        // const response = res.data.data.user;
        enqueueSnackbar('Les modifications ont été prises en compte', {
          variant: 'success',
        });
        handleEditing();
      })
      .catch((err) => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <Meta page="moncompte" />

      <Grid container spacing={3}>
        <Grid className={classes.grid} item xs={12} sm={7}>
          {!editing ? (
            <Paper className={classes.paper} elevation={0}>
              <h3>Coordonnées</h3>
              <br />
              <p>Contact : {user.personalData.fullname}</p>
              <p>Email : {user.personalData.email}</p>
              <p>Téléphone : {user.personalData.phoneNumber}</p>
              <p>Informations : {user.personalData.complement}</p>

              <Chip
                icon={
                  user.personalData.newsLetter ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />
                }
                color={user.personalData.newsLetter ? 'primary' : 'secondary'}
                label="Newsletter"
              />

              <br />
              <br />

              <Button className="btn-orange" onClick={handleEditing}>
                Modifier ces informations
              </Button>
            </Paper>
          ) : (
            <Paper className={classes.paper} elevation={0}>
              <h3>Coordonnées</h3>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid className={classes.form} container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      defaultValue={user.personalData.firstname}
                      className={classes.input}
                      label="Prénom"
                      variant="outlined"
                      onChange={(e) => handleUpdate(e, 'firstname')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      defaultValue={user.personalData.lastname}
                      className={classes.input}
                      label="Nom"
                      variant="outlined"
                      onChange={(e) => handleUpdate(e, 'lastname')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={user.personalData.email}
                      className={classes.input}
                      label="Email"
                      variant="outlined"
                      onChange={(e) => handleUpdate(e, 'email')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={user.personalData.phoneNumber}
                      className={classes.input}
                      label="Téléphone"
                      variant="outlined"
                      onChange={(e) => handleUpdate(e, 'phoneNumber')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={user.personalData.complement}
                      className={classes.input}
                      label="Information livreur"
                      variant="outlined"
                      onChange={(e) => handleUpdate(e, 'complement')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.personalData.newsLetter}
                          name="newsLetter"
                          color={state.personalData.newsLetter ? 'primary' : 'secondary'}
                          onChange={() => handleSwitch()}
                        />
                      }
                      label="Newsletter"
                    />
                  </Grid>
                </Grid>

                <button style={{ marginTop: '1rem' }} className="btn-orange" type="submit">
                  Modifier ses informations
                </button>
              </form>
            </Paper>
          )}
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={5}>
          <Paper className={classes.paper} elevation={0}>
            <h3>Bon d'achat</h3>
            {state.discounts.length === 0 ? (
              <p>Vous n'avez pas de bon d'achat</p>
            ) : (
              <div>
                {state.discounts
                  .filter((el) => el.isActive === true)
                  .map((el, idx) => (
                    <Paper key={idx} className={classes.paperDiscount} elevation={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={9}>
                          <p>{el.name}</p>
                        </Grid>
                        <Grid item xs={3}>
                          <p>
                            {el.value} {el.reduction === 'percent' ? '%' : '€'}
                          </p>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      Bons d'achat utilisés et expirés
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.div}>
                      {state.discounts
                        .filter((el) => el.isActive === false)
                        .map((el, idx) => (
                          <Paper key={idx} className={classes.paperDiscountDisable} elevation={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={9}>
                                <p>{el.name}</p>
                              </Grid>
                              <Grid item xs={3}>
                                <p>
                                  {el.value} {el.reduction === 'percent' ? '%' : '€'}
                                </p>
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
