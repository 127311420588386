const userStorage = (userInfos) => {
  localStorage.setItem('user', JSON.stringify(userInfos));
};

export const setUpUserInfos = (prev, infos, action) => {
  const userTemp = {
    ...prev,
  };
  if (action === 'login') {
    userTemp.user = {
      ...infos,
    };
  }
  if (action === 'infos') {
    userTemp.user.personalData = {
      ...prev.user.personalData,
      ...infos,
    };
  }
  if (action === 'setAddress') {
    userTemp.user.addresses = infos;
  }
  if (action === 'addContact') {
    userTemp.user.ecoData.contacts = infos;
  }
  if (action === 'removeContact') {
    userTemp.user.ecoData.contacts = prev.user.ecoData.contacts.filter((el) => el._id !== infos);
  }
  if (action === 'setEcoInfos') {
    userTemp.user.ecoData = {
      ...prev.user.ecoData,
      ...infos,
    };
  }

  userStorage(userTemp.user);
  return userTemp.user;
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const UserReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        user: {
          ...setUpUserInfos(state, action.payload, 'login'),
        },
      };

    case 'LOGOUT':
      return logout();

    case 'SET_INFOS':
      return {
        user: {
          ...setUpUserInfos(state, action.payload, 'infos'),
        },
      };

    case 'SET_ADDRESS':
      return {
        user: {
          ...setUpUserInfos(state, action.payload, 'setAddress'),
        },
      };

    case 'ADD_CONTACT':
      return {
        user: {
          ...setUpUserInfos(state, action.payload, 'addContact'),
        },
      };

    case 'REMOVE_CONTACT':
      return {
        user: {
          ...setUpUserInfos(state, action.payload, 'removeContact'),
        },
      };

    case 'SET_ECOINFOS':
      return {
        user: {
          ...setUpUserInfos(state, action.payload, 'setEcoInfos'),
        },
      };

    case 'DISCONNECT_USER':
      return logout();

    default:
      return state;
  }
};
