import React from 'react';
import Button from '@material-ui/core/Button';
import Order from './Order';

// MATERIAL.UI

export default function Commandes(props) {
  const { orders } = props;

  return (
    <section>
      <h3>Mes Commandes</h3>
      <p>L'historique de vos commandes apparait ici !</p>
      <Order orders={orders} />
      <br />
      <Button className="btn-orange" href="/catalogue/les-paniers-bio">
        Parcourir le catalogue
      </Button>
    </section>
  );
}
