import React from 'react';

// # ASSETS
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from '@material-ui/core/IconButton';
import logoFooter from '../assets/img/logo_footer.png';

// # CSS
import '../assets/css/footer.css';

export default function EcoFooter() {
  return (
    <section id="footer">
      <div>
        <div>
          <h4>L'Offre</h4>
          <ul>
            <li>
              <a href="/catalogue/les-paniers-bio">Les paniers Bio</a>
            </li>
            <li>
              <a href="/catalogue/a-la-carte">À la carte</a>
            </li>
            <li>
              <a href="/catalogue/les-fruits-et-legumes-sec">Les fruits et légumes sec</a>
            </li>
          </ul>
        </div>
        <div>
          <h4>Bioculture</h4>
          <ul>
            <li>
              <a href="/nos-valeurs">Nos valeurs</a>
            </li>
            <li>
              <a href="/nos-produits">Nos produits</a>
            </li>
            <li>
              <a href="/nos-producteurs">Nos producteurs</a>
            </li>
            <li>
              <a href="/zero-dechets">Zéro déchet</a>
            </li>
            <li>
              <a href="/contact">Nous écrire</a>
            </li>
          </ul>
        </div>
        <div>
          <h4>Mentions légales</h4>
          <ul>
            <li>
              <a href="/cgv">CGV</a>
            </li>
            <li>
              <a href="/mentions-legales">Mentions légales</a>
            </li>
            <li>
              <a href="/signup">S'inscrire</a>
            </li>
            <li>
              <a href="/#">Se connecter</a>
            </li>
          </ul>
        </div>
        <div>
          <figure>
            <img src={logoFooter} alt="Logo bioculture" />
          </figure>
          <p>
            Bioculture.fr - Mon Panier Bio
            <br />
            28 rue de Wolfenbüttel
            <br />
            92310 Sèvres
            <br />
            Tél: 01 41 14 18 10
            <br />
            service-client@bio-culture.fr
          </p>
        </div>
      </div>
      <div className="bottom">
        <div>Certifié par Ecocert FR-BIO-01</div>
        <div>
          <IconButton href="https://www.facebook.com/panierbioparis">
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://www.instagram.com/bioculture.lespaniersbio/">
            <InstagramIcon />
          </IconButton>
        </div>
      </div>
    </section>
  );
}
