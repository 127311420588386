import React, { Fragment, useEffect, useContext, useState } from 'react';

import axios from 'axios';

import { useSnackbar } from 'notistack';

// # MATERIA.UI
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  FormControl,
  Select,
  Container,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Meta from '../../../utils/metaDescription';

// # CONTEXT
import { UserContext } from '../../../contexts/UserContext/UserContext';

const useStyles = makeStyles(() => ({
  header: {},
  content: {
    maxWidth: '90%',
    margin: '0 auto',
    padding: '2rem 0',

    '& > h2': {
      textAlign: 'start',
    },

    '& > div': {
      margin: '0 0 1.5rem 0',
    },

    '& > form > div': {
      margin: '0 0 1.5rem 0',
    },
  },
}));

export default function BecomeEco() {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    accountExist: false,
    userRole: '',
    form: {
      lastname: '',
      firstname: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirm: '',
      address: '',
      zipcode: '',
      city: '',
      complement: '',
    },
    infos: {
      why: 'Mon voisinage',
      how: '',
    },
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      userRole: user.role,
    }));

    if (user.role === 'user') {
      setState((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          email: user.email,
        },
      }));
    }
  }, [user.role, user.email]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (user.role !== 'user' && !state.accountExist) {
      if (state.form.password.length < 8) {
        return enqueueSnackbar('Votre mot de passe doit contenir au moins 8 caractères', {
          variant: 'error',
        });
      }

      if (state.form.password !== state.form.passwordConfirm) {
        return enqueueSnackbar('Votre mot de passe et sa confirmation doivent être identique', {
          variant: 'error',
        });
      }
    }

    await axios
      .post(`${process.env.REACT_APP_API}/contact/becomeEco`, state)
      .then(() => {
        enqueueSnackbar(
          `Merci d'avoir créé votre compte Eco-Acteur. Il sera validé par nos équipes sous 72 heures maximum.`,
          { variant: 'success' }
        );
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        Object.keys(errors).forEach((key) => {
          enqueueSnackbar(`${errors[key].message}`, { variant: 'error' });
        });
      });
  };

  const handleForm = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleInfos = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      infos: {
        ...prev.infos,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCheck = () => {
    setState((prev) => ({
      ...prev,
      accountExist: !prev.accountExist,
    }));
  };

  return (
    <>
      <Meta page="ecoacteurs" />

      <Container maxWidth="md">
        <div className={classes.header}>
          <h1>JE ME LANCE</h1>
          <p>
            Je souhaite pouvoir réaliser des commandes groupées de paniers Bio pour mes collègues,
            mon entourage, mes clients... Pour rendre service, promouvoir le Bio, bénéficier
            d’Eco-Points... On a tous une bonne raison de devenir Eco-acteur !
          </p>
        </div>
        {state.userRole === 'eco' && (
          <div>
            <h2>Vous êtes déjà éco-acteur</h2>
          </div>
        )}

        {state.userRole === '' && (
          <div className={classes.content}>
            <h3>Je fais une demande pour devenir eco-acteur</h3>
            <FormControlLabel
              control={
                <Checkbox checked={state.accountExist} onChange={handleCheck} name="account" />
              }
              label="Vous avez déjà un compte"
            />

            {state.accountExist ? (
              <>
                <h4 style={{ textAlign: 'start' }}>Vos informations :</h4>
                <form
                  onSubmit={handleSubmit}
                  className={classes.form}
                  style={{ marginTop: '1rem' }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    value={state.form.email}
                    name="email"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Mot de passe"
                    type="password"
                    value={state.form.password}
                    name="password"
                    onChange={handleForm}
                  />
                  <h4 style={{ textAlign: 'start' }}>Complément d'information :</h4>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Vous envisagez de réaliser des commandes groupés pour :</InputLabel>
                    <Select
                      required
                      native
                      label="Vous envisagez de réaliser des commandes groupés pour :"
                      name="why"
                      value={state.infos.why}
                      onChange={handleInfos}
                    >
                      <option value="Mon voisinage">Votre voisinage</option>
                      <option value="Mon entreprise">Votre entreprise</option>
                      <option value="Mon école ou ma crèche">Votre école ou votre crèche</option>
                      <option value="Mon association">Votre association</option>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Comment avez vous connu la partie eco-acteur ?"
                    name="how"
                    value={state.infos.how}
                    onChange={handleInfos}
                  />
                  <button className="btn-orange" type="submit">
                    Valider ma demande
                  </button>
                </form>
              </>
            ) : (
              <>
                <h4 style={{ textAlign: 'start' }}>Vos informations</h4>
                <p style={{ textAlign: 'start', marginBottom: '1rem' }}>
                  Vous pourrez ensuite vous connecter avec ces informations
                </p>
                <form onSubmit={handleSubmit} className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nom"
                    value={state.form.lastname}
                    name="lastname"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Prénom"
                    value={state.form.firstname}
                    name="firstname"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Téléphone"
                    value={state.form.phone}
                    name="phone"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    value={state.form.email}
                    name="email"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Mot de passe"
                    type="password"
                    value={state.form.password}
                    name="password"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Confirmez votre mot de passe"
                    type="password"
                    value={state.form.passwordConfirm}
                    name="passwordConfirm"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Adresse"
                    value={state.form.address}
                    name="address"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Code Postal"
                    value={state.form.zipcode}
                    name="zipcode"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Ville"
                    value={state.form.city}
                    name="city"
                    onChange={handleForm}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Complément d'adresse"
                    value={state.form.complement}
                    name="complement"
                    onChange={handleForm}
                  />
                  <h4 style={{ textAlign: 'start' }}>Complément d'information :</h4>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Vous envisagez de réaliser des commandes groupés pour :</InputLabel>
                    <Select
                      required
                      native
                      label="Vous envisagez de réaliser des commandes groupés pour :"
                      name="why"
                      value={state.infos.why}
                      onChange={handleInfos}
                    >
                      <option value="Mon voisinage">Votre voisinage</option>
                      <option value="Mon entreprise">Votre entreprise</option>
                      <option value="Mon école ou ma crèche">Votre école ou votre crèche</option>
                      <option value="Mon association">Votre association</option>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Comment avez vous connu la partie eco-acteur ?"
                    name="how"
                    value={state.infos.how}
                    onChange={handleInfos}
                  />
                  <button className="btn-orange" type="submit">
                    Valider ma demande
                  </button>
                </form>
              </>
            )}
          </div>
        )}

        {state.userRole === 'user' && (
          <div className={classes.content}>
            <h3>Je fais une demande pour devenir eco-acteur</h3>
            <div>
              <h4>
                Vous êtes connecté avec l'email :
                <span style={{ display: 'block' }}>{user.email}</span>
              </h4>
            </div>
            <form onSubmit={handleSubmit} className={classes.form}>
              <h4 style={{ textAlign: 'start' }}>Complément d'information :</h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Vous envisagez de réaliser des commandes groupés pour :</InputLabel>
                <Select
                  required
                  native
                  label="Vous envisagez de réaliser des commandes groupés pour :"
                  name="why"
                  value={state.infos.why}
                  onChange={handleInfos}
                >
                  <option value="Mon voisinage">Votre voisinage</option>
                  <option value="Mon entreprise">Votre entreprise</option>
                  <option value="Mon école ou ma crèche">Votre école ou votre crèche</option>
                  <option value="Mon association">Votre association</option>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                fullWidth
                label="Comment avez vous connu la partie eco-acteur ?"
                name="how"
                value={state.infos.how}
                onChange={handleInfos}
              />
              <button className="btn-orange" type="submit">
                Valider ma demande
              </button>
            </form>
          </div>
        )}
      </Container>
    </>
  );
}
