import React, { useState, useEffect, Fragment } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router';

import {
  Grid,
  Paper,
  Chip,
  Button,
  Switch,
  FormControlLabel,
  TextField,
  Link,
} from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { useSnackbar } from 'notistack';

import ListIcon from '@material-ui/icons/List';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBot: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editing: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      maxWidth: '70%',
      marginBottom: '1rem',
    },
  },
  marginB: {
    margin: '0 0 1rem 0',
  },
  marginR: {
    margin: '0 1rem 0 0',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

function TopDetails(props) {
  return (
    <div className={props.flexTop}>
      <h1>Rubrique : {props.name}</h1>
      {props.editing ? (
        <div>
          <Button
            className={props.marginR}
            variant="contained"
            color="secondary"
            onClick={(e) => props.handleDelete()}
          >
            Supprimer
          </Button>
          <Button variant="contained" color="primary" onClick={(e) => props.handleEditing(false)}>
            Annuler
          </Button>
        </div>
      ) : (
        <div className={props.classes.margin}>
          <div>
            <Button
              className={props.classes.button}
              type="button"
              variant="contained"
              color="primary"
              startIcon={<ListIcon />}
              href="/admin/categories"
            >
              Liste rubriques
            </Button>
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={(e) => props.handleEditing(true)}>
              Modifier
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function EditingT(props) {
  return (
    <section>
      <div className={props.classes.editing}>
        <FormControlLabel
          control={
            <Switch
              checked={props.categoryUpdate.isActive}
              name="isActive"
              onClick={props.handleSwitch}
            />
          }
          label={props.categoryUpdate.isActive ? 'Actif' : 'Inactif'}
        />
        <TextField
          className={props.classes.marginB}
          label="Nom"
          variant="outlined"
          type="text"
          size="small"
          name="name"
          defaultValue={props.categoryUpdate.name}
          onChange={props.handleChange}
        />
        <TextField
          className={props.classes.marginB}
          label="Description"
          variant="outlined"
          type="text"
          size="small"
          multiline
          name="description"
          defaultValue={props.categoryUpdate.description}
          onChange={props.handleChange}
        />
      </div>
      <div className={props.classes.flexBot}>
        <Button variant="contained" color="primary" onClick={props.handleUpdate}>
          Valider les modifications
        </Button>
      </div>
    </section>
  );
}

function EditingF(props) {
  return (
    <section>
      <Chip
        size="small"
        label={props.category.isActive ? 'Actif' : 'Inactif'}
        color={props.category.isActive ? 'primary' : 'secondary'}
      />
      <p>
        <b>ID :</b> {props.category._id}
      </p>
      <p>
        <b>Nom :</b> {props.category.name}
      </p>
      <p>
        <b>Description :</b>
        <br />
        {props.category.description}
      </p>
    </section>
  );
}

export default function CategoryDetails() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const [state, setState] = useState({
    loading: true,
    editing: false,
    category: {},
    categoryUpdate: {
      name: null,
      description: null,
      isActive: null,
    },
    subCategories: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const categories = await axios
        .get(`${process.env.REACT_APP_API}/categories/${id}`)
        .catch((err) => {
          console.log(err);
        });

      const subCategories = await axios
        .get(`${process.env.REACT_APP_API}/subcategories/?category=${id}`)
        .catch((err) => {
          console.log(err);
        });

      setState((prev) => ({
        ...prev,
        loading: false,
        category: categories.data.data.category,
        categoryUpdate: {
          name: categories.data.data.category.name,
          description: categories.data.data.category.description,
          isActive: categories.data.data.category.isActive,
        },
        subCategories: [...subCategories.data.data.subCategories],
      }));
    };
    fetchData();
  }, [id]);

  const handleEditing = (bool) => {
    setState((prev) => ({
      ...prev,
      editing: bool,
    }));
  };

  const handleSwitch = (event) => {
    setState((prev) => ({
      ...prev,
      categoryUpdate: {
        ...prev.categoryUpdate,
        isActive: event.target.checked,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      categoryUpdate: {
        ...prev.categoryUpdate,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleUpdate = async () => {
    const token = Cookies.get('jwt');

    await axios
      .patch(`${process.env.REACT_APP_API}/categories/${id}`, state.categoryUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        enqueueSnackbar(`Une problème est survenu`, {
          variant: 'error',
        });
      });
  };

  const handleDelete = async () => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = '/admin/categories';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <TopDetails
            classes={classes}
            marginR={classes.marginR}
            flexTop={classes.flexTop}
            name={state.category.name}
            editing={state.editing}
            handleEditing={handleEditing}
            handleDelete={handleDelete}
          />
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Paper className={classes.paper}>
                <h3>Informations :</h3>
                {state.editing ? (
                  <EditingT
                    classes={classes}
                    categoryUpdate={state.categoryUpdate}
                    handleSwitch={handleSwitch}
                    handleChange={handleChange}
                    handleUpdate={handleUpdate}
                  />
                ) : (
                  <EditingF category={state.category} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <h3>Sous-rubriques liées :</h3>
                {state.subCategories.length > 0 ? (
                  <ul>
                    {state.subCategories.map((el, index) => (
                      <Link key={index} href={`/admin/subcategories/${el._id}`}>
                        <li>{el.name}</li>
                      </Link>
                    ))}
                  </ul>
                ) : (
                  'Aucune'
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
