import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';

import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputBase,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import RelayPointTable from '../../../components/Relay/RelayPointTable';
import { axiosGetRequest } from '../../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade('#9e9e9e'),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

export default function RelayPointList() {
  const classes = useStyles();
  const token = Cookies.get('jwt');
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    relay: [],
    relayShowcased: [],
    allSegments: [],
    status: 'all',
    type: 'all',
    segment: 'all',
  });

  const handleSearch = (e) => {
    const search = e.target.value.toString().toLowerCase();

    if (state.status !== 'all') {
      setState((prev) => ({
        ...prev,
        status: 'all',
      }));
    }
    if (state.type !== 'all') {
      setState((prev) => ({
        ...prev,
        type: 'all',
      }));
    }
    if (state.segment !== 'all') {
      setState((prev) => ({
        ...prev,
        segment: 'all',
      }));
    }
    const filtered = state.relay.filter((el) => el.name.toLowerCase().indexOf(search) > -1);

    if (search.length === 0) {
      return setState((prev) => ({
        ...prev,
        relayShowcased: prev.relay,
      }));
    }
    setState((prev) => ({
      ...prev,
      relayShowcased: filtered,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const relayPoints = await axios
        .get(`${process.env.REACT_APP_API}/relayPoints/`)
        .catch((err) => {
          console.log(err);
        })
        .then(async (res) => {
          console.log(res.data.data.relayPoints);
          return res.data.data.relayPoints;
        });
      const sortedArray = await relayPoints.sort((a, b) =>
        moment(b.createdAt).diff(moment(a.createdAt))
      );
      const segments = await axiosGetRequest(`/segments`, token).then(
        (res) => res.data.data.segments
      );
      setState((prev) => ({
        ...prev,
        relay: sortedArray,
        relayShowcased: sortedArray,
        allSegments: segments,
      }));
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (state.relay.length > 0) {
      let filter = [...state.relay];

      if (state.status !== 'all') {
        filter = filter.filter((el) => el.isActive === state.status);
      }
      if (state.type !== 'all') {
        filter = filter.filter((el) => el.type === state.type);
      }
      if (state.segment !== 'all') {
        filter = filter.filter((el) => el.segment?.id === state.segment);
      }

      setState((prev) => ({
        ...prev,
        relayShowcased: filter,
      }));
    }
  }, [state.status, state.type, state.segment, state.relay]);

  return (
    <>
      <div className={classes.container}>
        <h2>Tous les points de livraison</h2>
        <Button
          type="button"
          variant="contained"
          color="primary"
          href="/admin/createRelay/"
          startIcon={<AddIcon />}
        >
          Nouveau
        </Button>
      </div>
      <Paper className={classes.paper}>
        <h3>Filtre</h3>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={8}>
            <FormControl style={{ minWidth: '150px', marginRight: '15px' }} variant="outlined">
              <InputLabel id="select-type-label">Type</InputLabel>
              <Select
                labelId="select-type-label"
                label="Type"
                defaultValue="all"
                value={state.type}
                autoWidth
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value="relay">Point relais</MenuItem>
                <MenuItem value="entreprise">Entreprise</MenuItem>
                <MenuItem value="conciergerie">Conciergerie</MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{ minWidth: '150px', marginRight: '15px' }} variant="outlined">
              <InputLabel id="select-status-label">Statut</InputLabel>
              <Select
                labelId="select-status-label"
                label="Statut"
                defaultValue="all"
                value={state.status}
                autoWidth
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value={false}>Inactif</MenuItem>
                <MenuItem value>Actif</MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{ minWidth: '150px' }} variant="outlined">
              <InputLabel id="select-status-label">Segment</InputLabel>
              <Select
                labelId="select-status-label"
                label="Segment"
                defaultValue="all"
                value={state.segment}
                autoWidth
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    segment: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                {state.allSegments.map((segment) => (
                  <MenuItem value={segment.id}>{segment.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Chercher un point de livraison"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={handleSearch}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      {loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <RelayPointTable data={state.relayShowcased} />
      )}
    </>
  );
}
