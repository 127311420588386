import React, { useEffect, useState } from 'react';

// # DEPENDANCES
import Cookies from 'js-cookie';

// # COMPONENTS
import { Button, Collapse, Grid, Box } from '@material-ui/core';
import TeamCard from '../../../components/Team/TeamCard';
import AddTeam from '../../../components/Team/AddTeam';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # MATERIAL UI

export default function TeamShow() {
  const token = Cookies.get('jwt');

  const [state, setState] = useState({
    open: false,
    teams: [],
  });

  useEffect(() => {
    axiosGetRequest(`/teams`, token).then((res) => {
      const { teams } = res.data.data;
      setState((prev) => ({
        ...prev,
        teams,
      }));
    });
  }, [token]);

  const handleOpen = () => {
    setState((prev) => ({
      ...prev,
      open: !prev.open,
    }));
  };

  const removeNewTeam = (teamId) => {
    setState((prev) => ({
      ...prev,
      teams: prev.teams.filter((team) => team.id !== teamId),
    }));
  };

  const addNewTeam = (newTeam) => {
    setState((prev) => ({
      ...prev,
      teams: [newTeam, ...prev.teams],
    }));
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Les équipes</h1>
        {state.open ? (
          <Button variant="contained" color="secondary" onClick={handleOpen}>
            Annuler
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Ajouter une équipe
          </Button>
        )}
      </Box>

      <Collapse style={{ margin: '2rem 0' }} in={state.open}>
        <AddTeam addNewTeam={addNewTeam} />
      </Collapse>

      <Grid container spacing={3}>
        {state.teams.map((team, idx) => (
          <TeamCard key={idx} team={team} removeNewTeam={removeNewTeam} />
        ))}
      </Grid>
    </>
  );
}
