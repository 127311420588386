import React from 'react';

// # CSS
import '../assets/css/404.css';

export default function Notfound() {
    return (
        <div id="not-found">
            <h1>Oups !</h1>
            <h2>La page demandée est introuvable...</h2>
        </div>
    );
}
