import React, { Fragment } from 'react';

import { Divider, Hidden } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: 'white',
  },
}));

export default function NavCategories(props) {
  const classes = useStyles();
  const data = props.categories;
  return (
    <div className="nav-navbar2">
      <ul style={{ padding: '0' }}>
        <Hidden smDown>
          <Divider className={classes.divider} orientation="vertical" flexItem color="white" />
        </Hidden>
        {data
          .sort((a, b) => a.position - b.position)
          .map((el, index) => (
            <Fragment key={index}>
              <li>
                <a href={`/eco-catalogue/${el.slug}`}>{el.name}</a>
              </li>
              <Divider className={classes.divider} orientation="vertical" flexItem />
            </Fragment>
          ))}
        <Hidden smDown>
          <Divider className={classes.divider} orientation="vertical" flexItem />
        </Hidden>
      </ul>
    </div>
  );
}
