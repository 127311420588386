import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core';
import { Paper, Grid, Button } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';

// # COMPONENTS
import AddButton from './Card/AddButton';
import CustomDrawer from '../Navigation/CustomDrawer';
import GroupedLabels from './Card/GroupedLabels';
import GroupedInfos from './Card/GroupedInfos';
import ProductDrawer from './SwipeableDrawer/ProductDrawer';
import RemoveButton from './Card/RemoveButton';

// # CONTEXT
import { CartContext } from '../../contexts/CartContext';

// # UTILS
import currencyFormater from '../../utils/currencyFormater';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '230px',
        minWidth: '230px',
        height: '432px',
        margin: '1rem',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '2px 30px 60px rgba(0, 0, 0, 0.05);',
        overflow: 'hidden',

        '& > figure': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '230px',
            margin: '0',
            borderRadius: '4px 4px 0 0',

            '& > img': {
                width: '100%',
            },
        },

        [theme.breakpoints.down(555)]: {
            width: '165px',
            height: 'unset',
            margin: '0 0 1rem 0',
        },
    },
    text: {
        height: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        [theme.breakpoints.down(555)]: {
            justifyContent: 'space-evenly',

            '& > div:last-child > div:nth-child(4n) > div > button': {
                width: '100%',
            },
        },
    },
    name: {
        fontFamily: 'Amatic SC, sans-serif',
        margin: '0 auto auto auto',

        [theme.breakpoints.down(555)]: {
            fontSize: '1.4rem !important',
            marginBottom: '0.5rem',
        },
    },
    price: {
        textAlign: 'right',
        fontWeight: 500,
        color: theme.palette.secondary.main,
        margin: '0 0 0.5rem 0',

        [theme.breakpoints.down(555)]: {
            margin: 0,
            textAlign: 'center',
        },
    },
    detailsButton: {
        textTransform: 'capitalize',
        borderRadius: '4px',
        color: theme.palette.primary.main,
    },
    flexStart: {
        display: 'flex',
    },
    addBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& > button': {
            width: '36.5px',
            height: '36.5px',
            minWidth: '36.5px',
        },
    },
    qtyButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxHeight: '44.5px',

        [theme.breakpoints.down(555)]: {
            justifyContent: 'space-between',
        },

        '& > p': {
            width: '30px',
        },

        '& > button': {
            width: '36.5px',
            height: '36.5px',
            minWidth: '36.5px',
        },
    },
}));

export default function ProductCard(props) {
    moment.locale('fr');

    const classes = useStyles();
    const { cartItems, addProduct, setStep, setDrawerCart, clearCart } = useContext(CartContext);

    const { infos, productCard } = props;

    const [drawer, setDrawer] = useState(false);
    const [matchingItem, setMatchingItem] = useState({});

    useEffect(() => setMatchingItem(cartItems.find((item) => item._id === infos._id)), [cartItems, infos._id]);

    const handleAddProduct = (product) => {
        addProduct(product);
    };

    const updateParent = (value) => setDrawer(value);

    return (
        <Paper elevation={0} className={`${classes.paper} ${productCard || null}`}>
            <figure>
                <img src={`${process.env.REACT_APP_AWS}/${infos.photo}`} alt={infos.photo} />
            </figure>
            <div className={classes.text}>
                <Grid container spacing={0} justifyContent="space-between" alignContent="space-between">
                    <Grid item xs={12}>
                        <p
                            className={classes.name}
                            style={infos.name.length >= 22 ? { fontSize: '1.5rem' } : { fontSize: '2rem' }}
                        >
                            {infos.name}
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                    <Grid item sm={12}>
                        <div className={classes.flexStart}>
                            <GroupedInfos
                                type={infos.type}
                                infos={{
                                    weight: { ...infos.weight },
                                    persons: { ...infos.persons },
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <GroupedLabels labels={infos.labels} limit={3} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <p className={classes.price}>{currencyFormater(infos.price)}</p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className={classes.flexStart}>
                            <Button
                                className={classes.detailsButton}
                                onClick={() => setDrawer(true)}
                                color="primary"
                                variant="outlined"
                            >
                                Détail
                            </Button>
                        </div>
                    </Grid>
                    {matchingItem ? (
                        <Grid className={classes.qtyButtons} item xs={12} sm={7}>
                            <RemoveButton product={infos} matchingItem={matchingItem} />
                            <p>{matchingItem.quantity}</p>
                            <AddButton matchingItem={matchingItem} setDrawer={setDrawer} product={infos} />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={7}>
                            <Button
                                disabled={infos.stock <= 0}
                                variant="contained"
                                color="secondary"
                                style={{ width: '100%', textTransform: 'capitalize' }}
                                startIcon={<ShoppingCart />}
                                onClick={() => handleAddProduct(infos)}
                            >
                                Ajouter
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <CustomDrawer updateParent={updateParent} open={drawer}>
                    <ProductDrawer infos={infos} matchingItem={matchingItem} setDrawer={setDrawer} />
                </CustomDrawer>
            </div>
        </Paper>
    );
}
