import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router';

// # COMPONENTS
import ProductCard from '../../components/Product/ProductCard';
import ProductPageInfos from '../../components/Product/ProductPageInfos';

// # UTILS
import Meta from '../../utils/metaDescription';
import { axiosGetRequest } from '../../utils/axiosRequests';

// # CSS
import '../../assets/css/pages/productPages.css';

export default function PageProduits() {
  const { slug } = useParams();

  const [state, setState] = useState({
    loading: true,
    category: {},
    subcategories: [],
    products: [],
  });

  useEffect(() => {
    const type = slug === 'les-paniers-bio' ? 'baskets' : 'products';

    // # FETCH CATEGORY FROM PATH SLUG
    axiosGetRequest(`/categories/?slug=${slug}`).then((res) => {
      const category = res.data.data.categories[0];
      const { id } = category;

      setState((prev) => ({
        ...prev,
        category,
      }));

      // # FETCH SUBCATEGORIES
      axiosGetRequest(`/subcategories/?category=${id}`).then((resSub) => {
        const { subCategories } = resSub.data.data;
        setState((prev) => ({
          ...prev,
          subcategories: [...subCategories],
        }));
      });

      // # FETCH PRODUCTS
      axiosGetRequest(`/${type}/?category=${id}&isActive=true`).then((response) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          products: [...response.data.data[type]],
        }));
      });
    });
  }, [slug]);

  return (
    <>
      <Meta page="commande" />

      <section className="products">
        <div className="col-left">
          <ProductPageInfos />
        </div>

        <div className="col-right">
          {!state.loading &&
            state.subcategories
              .sort((a, b) => a.position - b.position)
              .map((el) => {
                const result = state.products.filter((product) => product.subCategory.id === el.id);

                return (
                  <div key={el._id}>
                    {result.length > 0 && (
                      <div>
                        <h2>{el.name}</h2>
                        <p>{el.description}</p>
                        <div className="product-category">
                          {result
                            .sort((a, b) => a.position - b.position)
                            .map((prod) => (
                              <ProductCard key={prod._id} infos={prod} />
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
      </section>
    </>
  );
}
