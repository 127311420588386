import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
} from '@material-ui/core/';

const columns = [
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  { id: 'category', label: 'Catégorie', minWidth: 100, align: 'left' },
  { id: 'amount', label: 'Montant Payé', minWidth: 100, align: 'left' },
  { id: 'isActive', label: 'Statut', minWidth: 100, align: 'left' },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  a: {
    color: '#adc00d',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default function SegmentTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((seg, idx) => (
                <TableRow key={idx}>
                  <TableCell align="left">
                    {' '}
                    <a className={classes.a} href={`/admin/segments/${seg._id}`}>
                      {seg.name}
                    </a>
                  </TableCell>
                  <TableCell align="left">
                    {seg.category === 'relay'
                      ? 'Point relais'
                      : seg.category === 'entreprise'
                      ? 'Entreprise'
                      : 'Eco-acteur'}
                  </TableCell>
                  <TableCell align="left">{seg.amount} %</TableCell>
                  <TableCell align="left">
                    <Chip
                      size="small"
                      label={seg.isActive ? 'Actif' : 'Inactif'}
                      color={seg.isActive ? 'primary' : 'secondary'}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
