import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

// # UTILS
import { autocompleteGroupedFormater } from '../../../utils/stringFormater';
import { axiosGetRequest, axiosPatchRequest } from '../../../utils/axiosRequests';

// # CUSTOM COMPONENTS
import BasketTable from '../../../components/Basket/BasketTable';
import ProductFilters from '../../../components/Product/Back/ProductFilters';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function BasketList() {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();

  const token = Cookies.get('jwt');

  // # FETCHED DATAS
  const [datas, setDatas] = useState({
    baskets: [],
    subCategories: [],
  });

  // # FILTERS
  const [filters, setFilters] = useState({
    status: 'all',
    subCategory: 'all',
    search: '',
  });

  // # STATE
  const [state, setState] = useState({
    loading: true,
    baskets: [],
    subCategories: [],
  });

  // # UPDATE HISTORY ON FILTERS CHANGE
  const updateHistory = useCallback(
    () =>
      history.push(
        `/admin/baskets/list?status=${filters.status}&subCategory=${filters.subCategory}&search=${filters.search}`
      ),
    [filters]
  );

  // # FETCH ALL DATAS
  useEffect(() => {
    // # ALL BASKETS
    axiosGetRequest('/baskets', token).then((res) => {
      const { baskets } = res.data.data;

      setDatas((prev) => ({
        ...prev,
        baskets,
      }));

      setState((prev) => ({
        ...prev,
        baskets,
      }));
    });

    // # ALL SUBCATEGORIES
    axiosGetRequest('/subcategories', token).then((res) => {
      const { subCategories } = res.data.data;
      const filteredSubCategories = subCategories.filter(
        (subCategory) => subCategory.category.slug === 'les-paniers-bio'
      );
      const subCategoriesFormat = autocompleteGroupedFormater(filteredSubCategories, 'name');

      setDatas((prev) => ({
        ...prev,
        subCategories: [...subCategoriesFormat],
      }));

      setState((prev) => ({
        ...prev,
        subCategories: [...subCategoriesFormat],
        loading: false,
      }));
    });

    // # FILTERS
    const filters = new URLSearchParams(search);
    if (filters) {
      for (const el of filters.entries()) {
        if (el[0] === 'status' && el[1] !== 'all') {
          setFilters((prev) => ({
            ...prev,
            [el[0]]: JSON.parse(el[1]),
          }));
        } else {
          setFilters((prev) => ({
            ...prev,
            [el[0]]: el[1],
          }));
        }
      }
    }
  }, [token]);

  // # UPDATE FILTERS AND FETCH DATA FOR UPDATING VUE
  useEffect(() => {
    let initialBaskets = datas.baskets;
    const initialSubCategories = datas.subCategories;

    if (filters.status !== 'all') {
      initialBaskets = initialBaskets.filter((basket) => basket.isActive === filters.status);
    }

    if (filters.subCategory !== 'all') {
      initialBaskets = initialBaskets.filter(
        (basket) => basket.subCategory.id === filters.subCategory
      );
    }

    if (filters.search !== '') {
      initialBaskets = initialBaskets.filter((basket) =>
        basket.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    setState((prev) => ({
      ...prev,
      baskets: initialBaskets,
      subCategories: initialSubCategories,
    }));

    updateHistory();
  }, [datas.baskets, datas.subCategories, filters]);

  // # HANDLE FILTERS
  const handleFilters = (filter, data) => {
    setFilters((prev) => ({
      ...prev,
      [filter]: data,
    }));
  };

  // # HANDLE SWITCH UPDATE ISACTIVE
  const handleSwitchActive = (id, idx) => {
    const temp = state.baskets[idx];
    temp.isActive = !temp.isActive;

    axiosPatchRequest(`/baskets/${id}`, token, { isActive: temp.isActive })
      .then(() => {
        setState((prev) => ({
          ...prev,
          baskets: prev.baskets.map((el, index) => (index === idx ? temp : el)),
        }));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <div className={classes.flex}>
        <h2>Tous les paniers</h2>
        <Button
          type="button"
          variant="contained"
          color="primary"
          href="/admin/baskets/create"
          startIcon={<Add />}
        >
          Nouveau
        </Button>
      </div>

      {state.loading ? (
        <Skeleton variant="rect" height={300} />
      ) : (
        <>
          <ProductFilters
            filters={filters}
            subCategories={state.subCategories}
            handleFilters={handleFilters}
          />
          <BasketTable baskets={state.baskets} handleSwitchActive={handleSwitchActive} />
        </>
      )}
    </>
  );
}
