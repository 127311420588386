import React, { useState, useEffect } from 'react';

// # MATERIAL UI
import { makeStyles, Grid } from '@material-ui/core';

// # UTILS
import { retrieveImgByArticleId } from '../../../utils/contentfulActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    maxWidth: '275px',
    minHeight: '110px',
    marginBottom: '1rem',
    padding: '5px',
    alignItems: 'center',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 5px 5px 0px #49494929',

    '& figure': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70px',
      height: '70px',
      borderRadius: '70px',
      overflow: 'hidden',
      margin: 0,

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        verticalAlign: 'text-bottom',
      },
    },

    [theme.breakpoints.down('lg')]: {
      minWidth: '45%',

      '& > div:first-child': {
        width: '40%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      '& > div:last-child': {
        width: '60%',
      },
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '& h4': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Bitter',
      fontWeight: '500',
      margin: '0 0 2px 0',
    },
  },
}));

export default function ReceiptCard(props) {
  const classes = useStyles();

  const { receipt } = props;

  const [image, setImage] = useState(null);

  useEffect(() => {
    retrieveImgByArticleId(receipt.fields.image.sys.id).then((img) => {
      setImage(img);
    });
  }, [receipt]);

  return (
    <Grid container className={classes.root}>
      <Grid item lg={4}>
        <a
          target="_blank"
          href={`/blog/${receipt.fields.slug}?contentType=recettes`}
          rel="noreferrer"
        >
          <figure>
            <img src={`${image}`} alt={receipt.fields.slug} />
          </figure>
        </a>
      </Grid>
      <Grid item lg={8} className={classes.infos}>
        <a
          target="_blank"
          href={`/blog/${receipt.fields.slug}?contentType=recettes`}
          rel="noreferrer"
        >
          <h4>{receipt.fields.title}</h4>
        </a>
      </Grid>
    </Grid>
  );
}
