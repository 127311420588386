import React from 'react';

// # MATERIAL UI
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& > div:nth-child(3)': {
      borderRadius: '16px 16px 0px 0px',
      '@media (min-width:768px)': {
        width: '80%',
        margin: '0 auto',
      },
      '@media (min-width:960px) and (max-width:1024px)': {
        width: '80%',
        maxWidth: '768px',
        margin: '0 auto',
      },
      '@media (min-width:1024px)': {
        width: '60%',
        minWidth: '653px',
        maxWidth: '768px',
        margin: '0 auto',
      },
    },
  },
}));

export default function CustomDrawer(props) {
  const classes = useStyles();
  const { open, updateParent } = props;

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    updateParent(open);
  };

  return (
    <Drawer className={classes.drawer} anchor="bottom" open={open} onClose={toggleDrawer(false)}>
      {props.children}
    </Drawer>
  );
}
