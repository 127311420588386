import React, { useEffect, useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';

// # CUSTOM COMPONENTS
import { makeStyles } from '@material-ui/core/styles';
import EcoCart from './EcoCart';
import EcoMenu from './EcoMenu';
import NavEcoCategories from './NavEcoCategories';

// # ASSETS
import logo from '../assets/img/logoBlanc.png';

// # CSS
import '../assets/css/econavbar.css';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#A0C868',
    color: 'white',
  },
  '.MuiIconButton-root': {
    color: 'white',
  },
  navbar: {
    maxWidth: '1200px',
    margin: '0 auto 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#A0C868',

    '&>figure': {
      margin: '0',
      maxWidth: '90px',
    },

    '& > ul > li > a': {
      textTransform: 'uppercase',
    },

    '&>ul': {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
      display: 'block',

      '&>li': {
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        display: 'inline-block',
        position: 'relative',
        fontSize: '14px',
        color: 'white',

        '&:hover a': {
          color: '#f05c34',
        },

        '&>a': {
          padding: '15px 15px',
          fontSize: 14,
          color: 'white',
          display: 'inline-block',
          outline: 0,
          fontWeight: 400,
        },
      },
    },

    '&>.nav-pictos': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '80px',

      '&>.MuiSvgIcon-root': {
        cursor: 'pointer',
        fontSize: '1.5em',
        fill: '#adc00d',

        '&:hover': {
          fill: '#7c7c7c',
        },
      },
    },
  },
  logo: {
    width: '100%',
  },
  divider: {
    backgroundColor: 'white',
  },
}));

export default function Navbar(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const categories = await axios
        .get(`${process.env.REACT_APP_API}/categories/?isActive=true`)
        .catch((err) => {
          console.log(err);
        });
      setData(categories.data.data.categories);
      setLoading((prev) => !prev);
    };
    fetchData();
  }, []);

  return (
    <div className={props.navbarPosition}>
      <section className={classes.appBar}>
        <nav>
          <div className={classes.navbar}>
            <figure>
              <a href="/">
                <img className={classes.logo} src={logo} alt="Logo Bioculture" />
              </a>
            </figure>
            <Hidden smDown>
              <ul>
                <li>
                  <a href="/eco-values">Nos valeurs</a>
                </li>
                <li>
                  <a href="/eco-productors">Nos producteurs</a>
                </li>
                <li>
                  <a href="/eco-products">Nos produits</a>
                </li>
                <li>
                  <a href="/eco-zero-dechets">Zéro déchet</a>
                </li>
              </ul>
            </Hidden>
            <div className="nav-pictos">
              <EcoCart />
              <Hidden mdUp>
                <EcoMenu />
              </Hidden>
            </div>
          </div>
          {loading ? <NavEcoCategories categories={data} /> : null}
        </nav>
      </section>
    </div>
  );
}
