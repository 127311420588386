import React, {Fragment, useEffect, useState} from 'react';
import {axiosPostRequest, axiosGetRequest} from "../../../utils/axiosRequests";
import Cookies from 'js-cookie';
import {Paper, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer} from "@material-ui/core";
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import currencyFormater from "../../../utils/currencyFormater";
import {
    EqualizerRounded,
    EuroRounded,
    PersonRounded,
    ShoppingBasketRounded,
    ShoppingCartRounded
} from "@material-ui/icons";
import formatNum from "../../../utils/numberFormater";
import StatsRecap from "../../../components/Stats/StatsRecap";
import {PieChart, Pie, ResponsiveContainer, Cell} from "recharts";

const useStyles = (makeStyles({
    paper: {
        padding: '1rem',
    },
    container: {
        marginTop: '1rem',
        height: '150px'
    },
    chart: {
        margin: '0 auto',
    },
    picto: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            fontSize: '2.5rem',
            fill: '#6c6c6c',
        },
    },
    stats: {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        '& > *': {
            margin: 0,
        },
        '& > h4': {
            textAlign: 'right',
            fontSize: '15px',
            color: '#6c6c6c',
        },
        '& > h3': {
            fontSize: '30px',
        },
    },
    paperStats: {
        padding: '1rem'
    },
    gridStats: {
        margin: '1rem 0'
    }
}))

export default function orderStats() {
    const token = Cookies.get();
    const classes = useStyles();
    const [load, setLoad] = useState(true);
    const [progress, setProgress] = useState(false);
    const [retroLoad, setRetroLoad] = useState(false);
    const [state, setState] = useState({
        stats: null,
        last12Months: [],
    })
    const [filter, setFilter] = useState({
        start: '2022-01-01',
        end: moment().format('YYYY-MM-DD'),
        timeSpan: 'dateToDate',
        users: 'all',
        from: moment().subtract(1, "weeks").format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        month: moment().format('YYYY-MM'),
        year: moment().format('YYYY'),
    });
    const time = 100;
    const seconds = time % 60;
    const minutes = (time / 60) % 60;
    const hours = (time / (60 * 24)) % 24;
    const PI = Math.PI;

    const data01 = [
        {
            "name": "Group A",
            "value": 400
        },
        {
            "name": "Group B",
            "value": 300
        },
        {
            "name": "Group C",
            "value": 300
        },
        {
            "name": "Group D",
            "value": 200
        },
        {
            "name": "Group E",
            "value": 278
        },
        {
            "name": "Group F",
            "value": 189
        }
    ];
    const data02 = [
        {
            "name": "Group A",
            "value": 2400
        },
        {
            "name": "Group B",
            "value": 4567
        },
        {
            "name": "Group C",
            "value": 1398
        },
        {
            "name": "Group D",
            "value": 9800
        },
        {
            "name": "Group E",
            "value": 3908
        },
        {
            "name": "Group F",
            "value": 4800
        }
    ];

    const data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0000', '#ff8ef9', '#bfcd45'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ name, value, cx, cy, midAngle, innerRadius, outerRadius }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 2.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        console.log(value, cx, cy, midAngle, innerRadius, outerRadius);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${Math.floor(value)} %: ${name} fois`}
            </text>
        );
    };

    useEffect(() => {

        const last12Months = [];
        const dateStart = moment();
        const dateEnd = moment().subtract(12, 'month');
        while (dateStart.diff(dateEnd, 'months') >= 0) {
            last12Months.push(dateStart.format('YYYY-MM'));
            dateStart.subtract(1, 'month');
        }
        setState(prev => ({
                ...prev,
                last12Months,
            })
        );

        const fetchData = async () => {
            setProgress(true);
            const stats = await axiosPostRequest('/stats', token, filter).then(res => {
                setLoad(false)
                setProgress(false);
                return res.data.data.results;
            }).catch(err => console.log(err));
            setState((prev) => ({
                ...prev,
                stats
            }))
        }
        fetchData();

    }, []);

    const handleChangeFilters = (event, filter) => {
        event.persist();
        console.log(event.target.value, filter)
        setFilter((prev) => ({
                ...prev,
                [filter]: event.target.value,
            })
        )
    }

    const handleDatas = async() => {
        setProgress(true);
        const stats = await axiosPostRequest('/stats', token, filter).then(res => {
            setLoad(false)
            setProgress(false);
            return res.data.data.results;
        }).catch(err => console.log(err));
        setState((prev) => ({
            ...prev,
            stats
        }))
        console.log(stats);
    }

    const handleRetro = async() => {
        setRetroLoad(true)
        await axiosPostRequest('/stats/retroactive', token, filter).then(res => setRetroLoad(false)).catch(err => console.log(err));
    }

    const datasClient = [
        {
            picto: <PersonRounded />,
            name: 'Clients',
            value: state.stats?.clientsNumber,
        },
        {
            picto: <PersonRounded />,
            name: "Inscrits",
            value: state.stats?.newUsersCount,
        },
        {
            picto: <PersonRounded />,
            name: "Inscrits sans commander",
            value: state.stats?.newUsersWithNoOrders,
        },
        {
            picto: <EuroRounded />,
            name: 'Montant total',
            value: currencyFormater(state.stats?.totalAmount),
        },
    ]

    const datas = [
        {
            picto: <ShoppingBasketRounded />,
            name: "Nombre de commandes",
            value: state.stats?.ordersCount,
        },
        {
            picto: <EqualizerRounded />,
            name: 'Panier moyen',
            value: currencyFormater(state.stats?.averageCart),
        },
        {
            picto: <ShoppingCartRounded />,
            name: 'Taux de réachat',
            value: `${formatNum(state.stats?.repurchaseRate)}x`,
        },

    ];

    return (
        <Fragment>
            <h1>Order Statistics</h1>

                <Grid container spacing={3} justifyContent={'space-between'}>
                    <Grid item xs={9}>
                        <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Clients à sélectionner</InputLabel>
                                    <Select
                                        label="Clients à sélectionner"
                                        name="selectedUsers"
                                        value={filter.users}
                                        onChange={(event) => handleChangeFilters(event, 'users')}
                                    >
                                        <MenuItem value="all">Tous les clients</MenuItem>
                                        <MenuItem value="user">Clients Simples</MenuItem>
                                        <MenuItem value="eco">Eco Acteurs</MenuItem>
                                        <MenuItem value="conciergerie">Conciergerie</MenuItem>
                                        <MenuItem value="entreprise">Entreprise</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Type de dates</InputLabel>
                                    <Select
                                        label="Type de dates"
                                        name="selectedTimeSpan"
                                        value={filter.timeSpan}
                                        onChange={(event) => handleChangeFilters(event, 'timeSpan')}
                                    >
                                        <MenuItem value="dateToDate">Date à date</MenuItem>
                                        <MenuItem value="month">Mois</MenuItem>
                                        <MenuItem value="year">Années</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                {filter.timeSpan === 'dateToDate' ? (
                                    <FormControl variant="outlined" fullWidth>
                                        <TextField
                                            style={{ marginBottom: '1rem' }}
                                            label="Du"
                                            type="date"
                                            name="from"
                                            value={filter.from}
                                            onChange={(event) => handleChangeFilters(event, 'from')}
                                        />
                                        <TextField
                                            label="Au"
                                            type="date"
                                            name="to"
                                            value={filter.to}
                                            onChange={(event) => handleChangeFilters(event, 'to')}
                                        />
                                    </FormControl>
                                ) : filter.timeSpan === 'month' ? (
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Dates</InputLabel>
                                        <Select
                                            label="Mois"
                                            name="selectMonth"
                                            value={filter.month}
                                            onChange={(event) => handleChangeFilters(event, 'month')}
                                        >
                                            {state.last12Months.map((month, idx) => (
                                                <MenuItem key={idx} value={month}>
                                                    {moment(month).format('MMMM YYYY')}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Dates</InputLabel>
                                        <Select
                                            label="Mois"
                                            name="selectMonth"
                                            value={filter.year}
                                            onChange={(event) => handleChangeFilters(event, 'year')}
                                        >
                                            <MenuItem value={'2022'}>2022</MenuItem>
                                            <MenuItem value={'2021'}>2021</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={progress} variant={'contained'} color={'primary'} fullWidth onClick={handleDatas}>
                                    {progress ? <CircularProgress size={25} thickness={8}/> : 'Charger'}
                                </Button>
                            </Grid>
                        </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        style={{ marginBottom: '1rem' }}
                                        label="Du"
                                        type="date"
                                        name="start"
                                        value={filter.start}
                                        onChange={(event) => handleChangeFilters(event, 'start')}
                                    />
                                    <TextField
                                        label="Au"
                                        type="date"
                                        name="end"
                                        value={filter.end}
                                        onChange={(event) => handleChangeFilters(event, 'end')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={retroLoad} variant={'outlined'} color={'primary'} onClick={handleRetro} fullWidth>
                                    {retroLoad ? <CircularProgress size={25} thickness={8}/> : 'Statistiques rétroactives'}
                                </Button>
                            </Grid>
                        </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            <div className={classes.gridStats}>
                <Grid container spacing={3}>
                    {datasClient.map((data) => (
                        <Grid item xs={3}>
                            <Paper className={classes.paperStats}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <div className={classes.picto}>{data.picto}</div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className={classes.stats}>
                                            <h4>{data.name}</h4>
                                            <h3>{data.value}</h3>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                    <Grid item xs={8}>
                        <Paper style={{height: '100%', padding: '1rem'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={7}>
                                    <PieChart width={500} height={300}>
                                        <Pie
                                            data={state.stats?.axis}
                                            cx="50%"
                                            cy="50%"
                                            label={renderCustomizedLabel}
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {state.stats?.axis.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </Grid>
                                <Grid item xs={5}>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableCell>

                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    Nombre de commandes
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    Pourcentage
                                                </TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {state.stats?.axis.map((axe, index) => (
                                                    <TableRow>
                                                        <TableCell style={{backgroundColor: COLORS[index % COLORS.length]}}>
                                                        </TableCell>
                                                        <TableCell align={'center'}>
                                                            <p>{axe.name}</p>
                                                        </TableCell>
                                                        <TableCell align={'center'}>
                                                            <p>{Math.floor(axe.value)} %</p>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            {datas.map((data) => (
                                <Grid item xs={12}>
                                    <Paper className={classes.paperStats}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <div className={classes.picto}>{data.picto}</div>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className={classes.stats}>
                                                    <h4>{data.name}</h4>
                                                    <h3>{data.value}</h3>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
}