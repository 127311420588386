import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  makeStyles,
  TextField,
  Button,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { useSnackbar } from 'notistack';
import Script from 'react-load-script';
import '../../assets/css/place.css';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0 1rem 1rem 1rem',
    padding: 0,
    height: '40px',
    '&>span.MuiButton-label>span.MuiButton-startIcon': {
      marginRight: 0,
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    margin: 'auto',
  },
  addSchedule: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>div:nth-child(1), &>div:nth-child(2)': {
      marginRight: '1rem',
    },
    '&>div:nth-child(2), &>div:nth-child(3)': {
      maxWidth: '120px',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateDeliveryCityModal() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [state, setState] = useState({
    deliveryCity: {
      name: '',
      zipCode: '',
      infos: [],
      deliveryZone: '',
      isActive: true,
    },
    selectDay: 'lundi',
    startSchedule: '10:30',
    endSchedule: '12:30',
    deliveryZones: [],
  });

  const handleScriptLoad = () => {
    const options = {
      types: ['(regions)'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const components = place.address_components;
      let zipCode;
      let name;

      components.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'locality':
            name = component.long_name;
            break;
          case 'postal_code':
            zipCode = component.long_name;
            break;
          default:
            break;
        }
      });

      setState((prev) => ({
        ...prev,
        deliveryCity: {
          ...prev.deliveryCity,
          name,
          zipCode: zipCode || '',
        },
      }));
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('jwt');
      const deliveryZones = await axios
        .get(`${process.env.REACT_APP_API}/deliveryZones/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          const { errors } = err.response.data.error;
          for (const error in errors) {
            enqueueSnackbar(`${errors[error].message}`, {
              variant: 'error',
            });
          }
        });

      setState((prev) => ({
        ...prev,
        deliveryZones: [...deliveryZones.data.data.deliveryZone],
      }));
    };
    fetchData();
  }, [enqueueSnackbar]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActive = (event) => {
    setState((prev) => ({
      ...prev,
      deliveryCity: {
        ...prev.deliveryCity,
        isActive: event.target.checked,
      },
    }));
  };

  const handleAddSchedule = () => {
    const schedule = {
      day: state.selectDay.toLowerCase(),
      startTime: state.startSchedule.replace(':', 'H'),
      endTime: state.endSchedule.replace(':', 'H'),
    };

    setState((prev) => ({
      ...prev,
      deliveryCity: {
        ...prev.deliveryCity,
        infos: [...prev.deliveryCity.infos, schedule],
      },
    }));
  };

  const handleDeleteSchedule = (idx) => {
    const woIdx = state.deliveryCity.infos.filter((el, index) => index !== idx);
    setState((prev) => ({
      ...prev,
      deliveryCity: {
        ...prev.deliveryCity,
        infos: woIdx,
      },
    }));
  };

  const handleAddCity = async () => {
    const token = Cookies.get('jwt');
    await axios
      .post(`${process.env.REACT_APP_API}/deliveryCities`, state.deliveryCity, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          if (errors[error].message) {
            enqueueSnackbar(`${errors[error].message}`, {
              variant: 'error',
            });
          }
        }
      });
  };

  const handleZipCode = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      deliveryCity: {
        ...prev.deliveryCity,
        zipCode: event.target.value,
      },
    }));
  };

  return (
    <>
      {open && (
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
          onLoad={handleScriptLoad}
        />
      )}
      <div>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          Nouvelle ville
        </Button>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.flexTop}>
                <h2>Ajouter une nouvelle ville</h2>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.deliveryCity.isActive}
                      name="isActive"
                      color="primary"
                      onChange={handleActive}
                    />
                  }
                  label={state.deliveryCity.isActive ? 'Actif' : 'Inactif'}
                />
              </div>
              <TextField
                className={classes.marginB}
                label="Ville"
                type="text"
                variant="outlined"
                id="autocomplete"
                fullWidth
              />
              <TextField
                className={classes.marginB}
                label="Code Postal"
                type="text"
                variant="outlined"
                value={state.deliveryCity.zipCode}
                onChange={handleZipCode}
                fullWidth
              />
              <FormControl variant="outlined" className={classes.marginB}>
                <InputLabel>Zone de livraison</InputLabel>
                <Select
                  native
                  label="Zone de livraison"
                  onChange={(e) => {
                    const deliveryZone = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      deliveryCity: {
                        ...prev.deliveryCity,
                        deliveryZone,
                      },
                    }));
                  }}
                >
                  <option value="" />
                  {state.deliveryZones.map((el, index) => (
                    <option key={index} value={el._id}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.schedules}>
                {state.deliveryCity.infos.length > 0 ? (
                  <ul>
                    {state.deliveryCity.infos.map((el, index) => (
                      <li key={index}>
                        {el.day} ({el.startTime} - {el.endTime})
                        <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          onClick={(e) => handleDeleteSchedule(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Aucun créneaux pour cette ville</p>
                )}
              </div>
              <div className={classes.addSchedule}>
                <FormControl variant="outlined" className={classes.marginB}>
                  <InputLabel>Jour de livraison</InputLabel>
                  <Select
                    native
                    label="Jour de livraison"
                    defaultValue={state.selectDay}
                    onChange={(e) => {
                      const selectDay = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        selectDay,
                      }));
                    }}
                  >
                    <option value="lundi">Lundi</option>
                    <option value="mardi">Mardi</option>
                    <option value="mercredi">Mercredi</option>
                    <option value="jeudi">Jeudi</option>
                    <option value="vendredi">Vendredi</option>
                    <option value="samedi">Samedi</option>
                  </Select>
                </FormControl>
                <TextField
                  className={classes.marginB}
                  label="Heure de debut"
                  type="time"
                  variant="outlined"
                  defaultValue={state.startSchedule}
                  onChange={(e) => {
                    const startSchedule = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      startSchedule,
                    }));
                  }}
                />
                <TextField
                  className={classes.marginB}
                  label="Heure de fin"
                  type="time"
                  variant="outlined"
                  defaultValue={state.endSchedule}
                  onChange={(e) => {
                    const endSchedule = e.target.value.toUpperCase();
                    setState((prev) => ({
                      ...prev,
                      endSchedule,
                    }));
                  }}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddSchedule}
                />
              </div>
              <Button variant="contained" color="primary" fullWidth onClick={handleAddCity}>
                Valider
              </Button>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}
