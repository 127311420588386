import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import moment from 'moment';

import { Link, useLocation, useParams } from 'react-router-dom';

// MATERIAL
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  Chip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { People, Whatshot, Alarm } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '14px',
    border: '1px solid rgb(0 0 0 0 70%)',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60%',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'left',
  },
  cover: {
    width: '40%',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  divider: {
    backgroundColor: '#ADC00D',
  },
  next: {
    margin: '0.5rem 0',
    color: '#ADC00D',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: '#ADC00D',
      color: 'white',
    },
  },
  text: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'black',
    fontFamily: 'Bitter',
    '& > p': {
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  },
  richText: {
    textAlign: 'left',
    margin: '0 auto',
    color: 'black',
    fontFamily: 'Bitter',
    '& > ul > li': {
      listStyleType: 'unset',
    },
    '& > ol > li': {
      listStyleType: 'unset',
    },
  },
  date: {
    textAlign: 'left',
    margin: '0 auto',
    marginBottom: '3rem',
  },
  sideBar: {
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    marginTop: '7rem',
    padding: '1rem',
    borderRadius: '0 14px 14px 0',
    height: 'fit-content',
    minHeight: '80%',
  },
  textField: {
    borderRadius: '14px !important',
  },
  img: {
    borderRadius: '14px',
    width: '100%',
    objectFit: 'cover',
    height: '350px',
    marginBottom: '1rem',
  },
  h1: {
    margin: '4rem auto !important',
    fontFamily: 'Bitter !important',
    fontSize: '1.5rem !important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem !important',
    },
  },
  h3: {
    marginBottom: '2rem !important',
    margin: '1rem auto',
  },
  h5: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  article: {
    height: '100%',
    display: 'flex',
    border: '1px solid rgb(0 0 0 0 70%)',
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 20%)',
    borderRadius: '14px',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      boxShadow: 'none',
      '& > div:nth-child(3) > p': {
        transition: '0.4s',
        backgroundColor: '#ADC00D',
        '& > span': {
          color: 'white',
        },
      },
    },
  },
  action: {
    fontSize: '1rem',
    borderRadius: '14px',
    height: '100%',
  },
  link: {
    padding: '0.5rem !important',
    textAlign: 'center !important',
    borderRadius: '0 0 14px 0',
  },
  a: {
    color: '#ADC00D',
    textAlign: 'center',
  },
  gridItem: {
    paddingBottom: '1rem',
    paddingRight: '1rem',
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 1rem',
  },
  containerIdeas: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 1rem',
  },
  chip: {
    margin: '0 2px',
    '& > svg': {
      width: '20px !important',
    },
  },
  skeleton: {
    borderRadius: '14px',
  },
}));

export default function Article() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const { slug } = useParams();
  const query = useQuery();
  const contentType = query.get('contentType');
  const classes = useStyles();
  const [state, setState] = useState({
    articles: [],
    article: null,
    loading: true,
  });

  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getImages = async (id) => {
    const url = await axios
      .get(
        `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/assets/${id}?access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8`
      )
      .then((res) => {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
        console.log(res.data.fields.file.url);
        return res.data.fields.file.url;
      })
      .catch((err) => {
        console.log(err);
      });

    return url;
  };

  useEffect(() => {
    handleTop();

    let total = null;

    const fetchArticle = async () => {
      const article = await axios
        .get(
          `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries/?content_type=${contentType}&access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&fields.slug=${slug}`
        )
        .then((res) => res.data.items[0])
        .catch((err) => {
          console.log(err);
        });

      article.fields.imageURL = await getImages(article.fields.image.sys.id);

      console.log(article);

      setState((prevState) => ({
        ...prevState,
        article,
      }));
    };

    const fetchArticles = async () => {
      let articles = await axios
        .get(
          `https://cdn.contentful.com/spaces/ix9hnqmnkeei/environments/master/entries/?content_type=${contentType}&access_token=n202IncU3phE8sqMc3NdvgN-HmXYQNLWRWGAhhUGMz8&limit=1000`
        )
        .then((res) => {
          total = res.data.total;
          console.log('articles', res.data.items);
          return res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });

      const rand = Math.floor(Math.random() * total);

      articles = articles.slice(rand, rand + 4);

      await articles.map(async (article) => {
        article.fields.imageURL = await getImages(article.fields.image.sys.id);
      });

      setState((prev) => ({
        ...prev,
        articles,
      }));
    };

    fetchArticle();
    fetchArticles();
  }, [slug]);

  return (
    <div>
      <Grid className={classes.container} container>
        {state.article && (
          <Grid item xs={12}>
            <h1 className={classes.h1}>{state.article.fields.title}</h1>
            {!state.loading ? (
              <div>
                <img
                  className={classes.img}
                  src={state.article.fields.imageURL}
                  alt="articleImage"
                />
              </div>
            ) : (
              <Skeleton
                className={classes.skeleton}
                variant="rect"
                animation="wave"
                width="100%"
                height={350}
              />
            )}
            <div className={classes.date}>
              Publié le {moment(state.article.fields.data).format('LL')}
            </div>
            <div className={classes.richText}>
              {documentToReactComponents(state.article.fields.description)}
            </div>
            {contentType === 'recettes' && (
              <div style={{ textAlign: 'left' }}>
                <div style={{ marginTop: '1rem', display: 'flex' }}>
                  <Chip
                    className={classes.chip}
                    style={{ marginRight: '1rem' }}
                    avatar={<People />}
                    color="secondary"
                    label={state.article.fields.persons}
                  />
                  <Chip
                    className={classes.chip}
                    style={{ marginRight: '1rem' }}
                    avatar={<Alarm />}
                    color="secondary"
                    label={`${state.article.fields.preparation} min`}
                  />
                  <Chip
                    className={classes.chip}
                    avatar={<Whatshot />}
                    color="secondary"
                    label={`${state.article.fields.cuisson} min`}
                  />
                </div>
              </div>
            )}

            {contentType === 'recettes' ? (
              <>
                <h3 style={{ textAlign: 'left' }}>Ingrédients</h3>
                <div className={classes.richText}>
                  {documentToReactComponents(state.article.fields.ingredients)}
                </div>
              </>
            ) : null}

            {contentType === 'recettes' && <h3 style={{ textAlign: 'left' }}>Préparation</h3>}
            <div className={classes.richText}>
              {documentToReactComponents(state.article.fields.text)}
            </div>
          </Grid>
        )}
        <h3>Autres recettes</h3>
      </Grid>
      <Grid className={classes.containerIdeas} container>
        {!state.loading &&
          state.articles.slice(0, 4).map((el, idx) => (
            <Grid key={idx} className={classes.gridItem} item xs={12} md={6}>
              <Link
                to={{
                  pathname: `/blog/${el.fields.slug}`,
                  search: `?contentType=${contentType}`,
                  state: {
                    article: el,
                    contentType,
                  },
                }}
              >
                <CardActionArea onClick={handleTop} className={classes.action}>
                  <Card className={classes.article}>
                    <CardMedia
                      component="img"
                      alt="Image article"
                      height="200"
                      image={el.fields.imageURL}
                      title="Image article"
                    />
                    <CardContent>
                      <Typography className={classes.h5} component="h5" variant="h5">
                        {el.fields.title}
                      </Typography>
                      <Typography
                        style={{ fontFamily: 'Bitter' }}
                        variant="subtitle1"
                        color="textSecondary"
                      >
                        Publié le {moment(el.fields.data).format('LL')}
                      </Typography>
                      <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
                        <Chip
                          className={classes.chip}
                          avatar={<People />}
                          color="secondary"
                          label={el.fields.persons}
                        />
                        <Chip
                          className={classes.chip}
                          avatar={<Alarm />}
                          color="secondary"
                          label={`${el.fields.preparation} min`}
                        />
                        <Chip
                          className={classes.chip}
                          avatar={<Whatshot />}
                          color="secondary"
                          label={`${el.fields.cuisson} min`}
                        />
                      </div>
                      <div className={classes.text}>
                        {documentToReactComponents(el.fields.description)}
                      </div>
                    </CardContent>
                    <div>
                      <Divider className={classes.divider} />
                      <Typography className={classes.link}>
                        <span className={classes.a}>Lire la suite</span>
                      </Typography>
                    </div>
                  </Card>
                </CardActionArea>
              </Link>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
