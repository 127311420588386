import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Link,
  IconButton,
  Collapse,
  Box,
  Typography,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons/';

// # CUSTOM COMMPONENT

import ReceiptIcon from '@material-ui/icons/Receipt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import InvitationTab from '../Invitations/InvitationTab';
import { totalPrice } from '../../utils/totalOrders';

moment.locale();

const columns = [
  { id: 'details', label: '', minWidth: 50, align: 'left' },
  { id: 'identity', label: 'ID', minWidth: 100, align: 'left' },
  {
    id: 'status',
    label: 'Statut',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'adv',
    label: 'ADV',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'amount',
    label: 'Montant Payé',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'delivery',
    label: 'Date de livraison',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'created',
    label: 'Date de création',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'invoice',
    label: 'Facture',
    minWidth: 100,
    align: 'left',
  },
];

const contactTab = [
  {
    id: 'fullname',
    label: 'Nom',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'email',
    label: 'Mail',
    minWidth: 100,
    align: 'left',
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& > div': {
      padding: '0',
    },
  },
  filters: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
}));

function Row(props) {
  const [open, setOpen] = useState(false);

  const handleDuplicate = async (id) => {
    const token = Cookies.get('jwt');

    await axios
      .post(
        `${process.env.REACT_APP_API}/orders/${id}/duplicate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const { id } = res.data.data.order;
        window.location = `/admin/orders/${id}`;
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleInvoice = async (id) => {
    const token = Cookies.get('jwt');

    await axios
      .get(`${process.env.REACT_APP_API}/orders/createBilling/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.open(
          `https://bioculture-invoices-bucket.s3.eu-west-3.amazonaws.com/facture-${id}.pdf`,
          '_blank'
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleRemove = async (id) => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(window.location.reload());
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Link href={`/admin/orders/${props.order._id}`}>
            {props.order._id.substr(props.order._id.length - 5)}
          </Link>
        </TableCell>
        <TableCell align="center">
          <Badge
            badgeContent={
              props.order.status === 'paid'
                ? 'Payé'
                : props.order.status === 'waiting'
                ? 'Attente'
                : props.order.status === 'refund'
                ? 'Remboursé'
                : 'Impayé'
            }
            color={
              props.order.status === 'paid'
                ? 'primary'
                : props.order.status === 'waiting'
                ? 'secondary'
                : props.order.status === 'refund'
                ? 'default'
                : 'error'
            }
          />
        </TableCell>
        <TableCell align="center">
          <Badge
            badgeContent={
              props.order.advStatus === 'input'
                ? 'Saisie'
                : props.order.advStatus === 'validate'
                ? 'Validée'
                : props.order.advStatus === 'processed'
                ? 'Traitée'
                : props.order.advStatus === 'archived'
                ? 'Archivée'
                : 'Annulée'
            }
            color={
              props.order.advStatus === 'archived'
                ? 'primary'
                : props.order.advStatus === 'canceled'
                ? 'secondary'
                : 'error'
            }
          />
        </TableCell>
        <TableCell align="center">{totalPrice(props.order)}</TableCell>
        <TableCell align="center">
          {moment(props.order.deliveryInfos.deliveryDate).format('DD-MM-YYYY')}
        </TableCell>
        <TableCell align="center">{moment(props.order.createdAt).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="center">
          {(props.order.stats !== 'paid') & (props.order.advStatus !== 'archived') ? (
            <Button
              size="small"
              type="button"
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => handleRemove(props.order._id)}
            >
              Supprimer
            </Button>
          ) : null}
          {props.order.advStatus === 'archived' && (
            <Button
              size="small"
              type="button"
              variant="contained"
              color="primary"
              startIcon={<FileCopyIcon />}
              onClick={() => handleDuplicate(props.order._id)}
            >
              Dupliquer
            </Button>
          )}
        </TableCell>
        <TableCell>
          <Button
            size="small"
            type="button"
            variant="contained"
            color="default"
            startIcon={<ReceiptIcon />}
            onClick={() => handleInvoice(props.order._id)}
          >
            Facture
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" component="div">
                Détails de la commande :
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="p">
                Livraison le{' '}
                {moment(props.order.deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')} à{' '}
                {props.order.deliveryInfos.deliverySchedule}{' '}
                {props.order.deliveryInfos.deliveryType === 'relayPoint'
                  ? 'en point de livraison'
                  : props.order.deliveryInfos.deliveryType === 'home'
                  ? 'à domicile'
                  : null}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Rubrique</TableCell>
                    <TableCell>Sous-rubrique</TableCell>
                    <TableCell align="right">Quantité</TableCell>
                    <TableCell align="right">Prix</TableCell>
                    <TableCell align="right">Prix total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.order.items.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.subCategory}</TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{item.price} €</TableCell>
                      <TableCell align="right">{item.price * item.quantity} €</TableCell>
                    </TableRow>
                  ))}
                  {props.order.discount && (
                    <TableRow>
                      <TableCell colSpan={2}>Bon de réduction</TableCell>
                      <TableCell colSpan={2}>{props.order.discount.name}</TableCell>
                      <TableCell colSpan={2}>
                        {props.order.discount.value}{' '}
                        {props.order.discount.reduction === 'flat' ? '€' : '%'}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={2}>Frais de livraison</TableCell>
                    <TableCell colSpan={2}>
                      {props.order.deliveryInfos.deliveryType === 'home'
                        ? 'Domicile'
                        : props.order.deliveryInfos.deliveryType === 'relayPoint'
                        ? 'Point Relay'
                        : 'autre'}
                    </TableCell>
                    <TableCell colSpan={2}>{props.order.deliveryCost} €</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function RowContact(props) {
  return (
    <TableRow>
      <TableCell align="left">
        <Link href={`/admin/users/${props.contact._id}`}>{props.contact.fullname}</Link>
      </TableCell>
      <TableCell align="left">{props.contact.email}</TableCell>
    </TableRow>
  );
}

export default function LastOrders(props) {
  const classes = useStyles();

  const [data, setData] = useState({
    order: [],
    orderDisplay: [],
    user: {},
    invitations: [],
  });
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const token = Cookies.get('jwt');
    const fetchData = async (id) => {
      await axios
        .get(`${process.env.REACT_APP_API}/invitations?sender=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData((prev) => ({
            ...prev,
            invitations: [...res.data.data.invitations],
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (props.user.id) {
      fetchData(props.user.id);
    }
  }, [props.user.id]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      order: props.order,
      orderDisplay: props.orderDisplay,
      user: props.user,
    }));
  }, [props]);

  const handleFilter = (value) => {
    if (value === 'all') return setData((prev) => ({ ...prev, orderDisplay: [...prev.order] }));

    const filteredOrder = data.order.filter((el) => el.status === value);
    setData((prev) => ({ ...prev, orderDisplay: [...filteredOrder] }));
  };

  return (
    <>
      {data.user.role === 'eco' ? (
        <>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Dernières Commandes" {...a11yProps(0)} />
              <Tab label="Invitations" {...a11yProps(1)} />
              <Tab label="Contacts" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className={classes.root}>
            <Paper className={classes.paper}>
              <h2>Dernières commandes</h2>
              <div className={classes.filters}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Statut de paiement</InputLabel>
                  <Select
                    label="Statut de paiement"
                    defaultValue="all"
                    onChange={(e) => handleFilter(e.target.value)}
                  >
                    <MenuItem value="all">Toutes</MenuItem>
                    <MenuItem value="canceled">Annulée</MenuItem>
                    <MenuItem value="paid">Payée</MenuItem>
                    <MenuItem value="refuse">Refusée</MenuItem>
                    <MenuItem value="waiting">En attente</MenuItem>
                    <MenuItem value="refuse">Remboursé</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.order.length > 0 ? (
                      data.orderDisplay
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((order, idx) => <Row key={idx} order={order} />)
                    ) : (
                      <TableRow style={{ paddingLeft: '1rem' }}>
                        <TableCell align="center" colSpan={8}>
                          Aucune commande
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.orderDisplay.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.root}>
            <Paper className={classes.paper}>
              <h2>Dernières Invitation Eco-acteur</h2>
              <div className={classes.filters}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Statut de l'invitation</InputLabel>
                  <Select
                    label="Statut de l'invitation"
                    defaultValue="all"
                    onChange={(e) => handleFilter(e.target.value)}
                  >
                    <MenuItem value="all">Toutes</MenuItem>
                    <MenuItem value="canceled">Annulée</MenuItem>
                    <MenuItem value="paid">Payée</MenuItem>
                    <MenuItem value="refuse">Refusée</MenuItem>
                    <MenuItem value="waiting">En attente</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <InvitationTab invitations={data.invitations} url="/admin/invitation" />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.root}>
            <Paper className={classes.paper}>
              <h2>Contact Eco-acteur</h2>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {contactTab.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.user.contacts.length ? (
                      data.user.contacts.map((contact, idx) => (
                        <RowContact key={idx} contact={contact} />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>Aucun contact</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.orderDisplay.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </TabPanel>
        </>
      ) : (
        <Paper className={classes.paper}>
          <h2>Dernières commandes</h2>
          <div className={classes.filters}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Statut de paiement</InputLabel>
              <Select
                label="Statut de paiement"
                defaultValue="all"
                onChange={(e) => handleFilter(e.target.value)}
              >
                <MenuItem value="all">Toutes</MenuItem>
                <MenuItem value="canceled">Annulée</MenuItem>
                <MenuItem value="paid">Payée</MenuItem>
                <MenuItem value="refuse">Refusée</MenuItem>
                <MenuItem value="waiting">En attente</MenuItem>
                <MenuItem value="refund">Remboursé</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.order.length > 0 ? (
                  data.orderDisplay
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order, idx) => <Row key={idx} order={order} />)
                  ) : (
                  <TableRow style={{ paddingLeft: '1rem' }}>
                    <TableCell align="center" colSpan={8}>
                      Aucune commande
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.orderDisplay.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
