import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
} from '@material-ui/core/';

const columns = [
  { id: 'name', label: 'Ville', minWidth: 100, align: 'left' },
  { id: 'zipcode', label: 'Code Postal', minWidth: 100, align: 'center' },
  {
    id: 'status',
    label: 'Statut',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'schedules',
    label: 'Nb créneaux',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  a: {
    color: '#adc00d',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default function DeliveryCityTable(props) {
  const classes = useStyles();
  const { cities } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {cities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((city) => (
              <TableRow key={city._id}>
                <TableCell align="left">
                  <a className={classes.a} href={`/admin/deliveryCities/${city._id}`}>
                    {city.name}
                  </a>
                </TableCell>
                <TableCell align="center">{city.zipCode}</TableCell>
                <TableCell align="center">
                  <Badge
                    badgeContent={city.isActive ? 'Actif' : 'Inactif'}
                    color={city.isActive ? 'primary' : 'error'}
                  />
                </TableCell>
                <TableCell align="center">{city.infos.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={cities.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
