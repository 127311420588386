import React from 'react';
import { Link, ListItem, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Dashboard, Assessment, ShowChart, BeachAccessRounded, Equalizer } from '@material-ui/icons';

// # CUSTOM COMPONENTS
import ContactMenu from './ContactMenu';
import ProductsMenu from './ProductsMenu';
import SalesMenu from './SalesMenu';
import DeliveryMenu from './DeliveryMenu';
import OthersMenu from './OthersMenu';
import WebsiteMenu from './WebsiteMenu';

const useStyles = makeStyles((theme) => ({
  menuBack: {
    maxHeight: '90vh',
    overflow: 'scroll',
    '& > nav > .MuiListItem-root > .MuiListItemIcon-root, & > div > .MuiListItemIcon-root': {
      minWidth: '45px',
    },
  },
}));

export default function MenuBack() {
  const classes = useStyles();
  return (
    <div className={classes.menuBack}>
      <ListItem button>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <Link href="/admin/">Statistiques des ventes</Link>
      </ListItem>

      <ListItem button>
        <ListItemIcon>
          <Assessment />
        </ListItemIcon>
        <Link href="/admin/commission/">Commissions</Link>
      </ListItem>

      <ListItem button>
        <ListItemIcon>
          <ShowChart />
        </ListItemIcon>
        <Link href="/admin/analytics/">Statistiques des utilisateurs</Link>
      </ListItem>

      <ListItem button>
        <ListItemIcon>
          <BeachAccessRounded />
        </ListItemIcon>
        <Link href="/admin/closeWebsite">Fermeture du site</Link>
      </ListItem>

      <ListItem button>
        <ListItemIcon>
          <Equalizer />
        </ListItemIcon>
        <Link href="/admin/stats">Statistiques commandes</Link>
      </ListItem>

      <ContactMenu />
      <ProductsMenu />
      <SalesMenu />
      <DeliveryMenu />
      <WebsiteMenu />
      <OthersMenu />
    </div>
  );
}
