import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

// # MATERIAL UI
import {
  Grid,
  Paper,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  IconButton,
  Link,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

// # UTILS
import { axiosPatchRequest } from '../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',

    '& > div': {
      flexGrow: 1,
    }
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  flexMid: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '190px',
  },
  flexBtn: {
    display: 'flex',
    flexDirection: 'column',
    '&>button:nth-child(1)': {
      marginBottom: '1rem',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > *': {
      marginBottom: '1rem',
    },
  },
  marginB: {
    marginBottom: '1rem',
  },
}));

function Schedules(props) {
  const { datas, handleUpdateCity, handleEditing, handleChange, handleDelete } = props;

  return (
    <Paper className={props.paper}>
      <div className={props.flexTop}>
        <h3>Informations :</h3>
        {datas.editing ? (
          <div className={props.flexBtn}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateCity} >
              Valider
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleEditing(false)}
            >
              Annuler
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditing(true)} >
            Modifier les informations
          </Button>
        )}
      </div>
      {datas.editing ? (
        <div className={props.flexMid}>
          <FormControlLabel
            className={props.marginB}
            control={
              <Switch
                checked={datas.deliveryCity.isActive}
                onChange={handleChange}
                name="isActive"
                color="primary"
              />
            }
            label={datas.deliveryCity.isActive ? 'Actif' : 'Inactif'}
          />
          <TextField
            className={props.marginB}
            label="Nom"
            variant="outlined"
            type="text"
            size="small"
            name="name"
            defaultValue={datas.deliveryCity.name}
            onChange={handleChange}
          />
          <TextField
            className={props.marginB}
            label="Code postal"
            variant="outlined"
            type="number"
            size="small"
            name="zipCode"
            defaultValue={datas.deliveryCity.zipCode}
            onChange={handleChange}
          />
          <FormControl variant="outlined" className={props.marginB}>
            <InputLabel>Zone de livraison</InputLabel>
            <Select
              native
              name="deliveryZone"
              label="Zone de livraison"
              value={datas.deliveryCity.deliveryZone._id}
              onChange={handleChange}
            >
              <option value="" />
              {datas.deliveryZones.map((el, index) => (
                <option
                  key={index}
                  value={el._id} >
                  {el.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={props.marginB}>
            <InputLabel>Servcie de Livraison</InputLabel>
            <Select
                native
                name="deliveryService"
                label="Service de livraison"
                value={datas.deliveryCity?.deliveryService?._id}
                onChange={handleChange}
            >
              <option value="" />
              {datas.deliveryServices.map((el, index) => (
                  <option
                      key={index}
                      value={el._id} >
                    {el.name}
                  </option>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : (
        <div>
          <Chip
            size="small"
            label={datas.deliveryCity.isActive ? 'Actif' : 'Inactif'}
            color={datas.deliveryCity.isActive ? 'primary' : 'secondary'}
          />
          <p>
            <b>Ville : </b>
            {datas.deliveryCity.name}
          </p>
          <p>
            <b>Code postal : </b>
            {datas.deliveryCity.zipCode}
          </p>
          <p>
            <b>Zone de livraison : </b>
            {datas?.deliveryCity?.deliveryZone?.id ? (
              <Link href={`/admin/deliveryZones/${datas.deliveryCity.deliveryZone.id}`}>
                {datas.deliveryCity.deliveryZone.name}
              </Link>
            ) : (
              'Aucune zone assignée'
            )}
          </p>
          {datas.deliveryCity.deliveryService && (
              <p>
                <b>Service de livraison : </b>
                <Link href={`/admin/deliveryServices/${datas.deliveryCity.deliveryService?.id}`}>
                  {datas.deliveryCity.deliveryService?.name}
                </Link>
              </p>
          )}
        </div>
      )}
      <hr />
      <h3>Créneaux :</h3>
      <ul>
        {datas?.deliveryCity?.infos?.length > 0 ? (
          datas.deliveryCity.infos.map((info, index) => (
            <li key={index}>
              {info.day} - {info.startTime} - {info.endTime}
              <IconButton
                data-index={index}
                aria-label="delete"
                value={index}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </li>
          ))
        ) : (
          <li>Aucun</li>
        )}
      </ul>
    </Paper>
  );
}

export default function DeliveryCityInfos(props) {
  const { services, zones, city } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    editing: false,
    validation: false,
    deliveryServices: [],
    deliveryZones: [],
    deliveryCity: {},
    newSchedule: {
      day: 'mardi',
      startTime: '10:30',
      endTime: '12:30',
    },
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      deliveryZones: zones,
      deliveryCity: city,
      deliveryServices: services,
    }));
  }, [props]);

  const handleAdd = (e) => {
    e.preventDefault();

    const formatedSchedule = {
      ...state.newSchedule,
      day: state.newSchedule.day.toLowerCase(),
      startTime: state.newSchedule.startTime.replace(':', 'H'),
      endTime: state.newSchedule.endTime.replace(':', 'H'),
    };

    setState((prev) => ({
      ...prev,
      validation: true,
      deliveryCity: {
        ...prev.deliveryCity,
        infos: [...prev.deliveryCity.infos, formatedSchedule],
      },
    }));
  };

  const handleEditing = (bool) => {
    setState((prev) => ({
      ...prev,
      editing: bool,
    }));
  };

  const handleDelete = (event) => {
    const { index } = event.currentTarget.dataset;
    const updatedArr = state.deliveryCity.infos.filter((el, idx) => idx !== index * 1);

    setState((prev) => ({
      ...prev,
      validation: true,
      deliveryCity: {
        ...prev.deliveryCity,
        infos: updatedArr,
      },
    }))
  };

  const handleChange = (event) => {
    event.persist();
    const { name, value, checked } = event.target;

    if (name === 'isActive') {
      return setState((prev) => ({
        ...prev,
        deliveryCity: {
          ...prev.deliveryCity,
          [name]: checked,
        },
      }))
    }

    if (name === 'deliveryZone') {
      const matchingZone = zones.find((zone) => zone._id === value);

      return setState((prev) => ({
        ...prev,
        deliveryCity: {
          ...prev.deliveryCity,
          [name]: matchingZone,
        },
      }));
    }

    setState((prev) => ({
      ...prev,
      deliveryCity: {
        ...prev.deliveryCity,
        [name]: value,
      },
    }));
  }

  const handleUpdateCity = async () => {
    const token = Cookies.get('jwt');

    axiosPatchRequest(`/deliveryCities/${state.deliveryCity._id}`, token, state.deliveryCity)
      .then((res) => {
        const { deliveryCity } = res.data.data;

        enqueueSnackbar('La ville de livraison a bien été modifiée', {
          variant: 'success',
        });

        setState((prev) => ({
          ...prev,
          editing: false,
          validation: false,
          deliveryCity: { ...deliveryCity },
        }));
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  const handleNewSchedule = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      newSchedule: {
        ...prev.newSchedule,
        [name]: value,
      },
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Schedules
          datas={state}
          paper={classes.paper}
          flexTop={classes.flexTop}
          flexMid={classes.flexMid}
          marginB={classes.marginB}
          flexBtn={classes.flexBtn}
          handleEditing={handleEditing}
          handleChange={handleChange}
          handleUpdateCity={handleUpdateCity}
          handleDelete={handleDelete}
        />
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <h3>Ajouter des créneaux :</h3>
          <form className={classes.form} onSubmit={handleAdd}>
            <FormControl variant="outlined" className={classes.marginB}>
              <InputLabel>Jour</InputLabel>
              <Select
                native
                name={"day"}
                value={state.newSchedule.day}
                onChange={handleNewSchedule}
                label="Type"
              >
                <option value="mardi">Mardi</option>
                <option value="mercredi">Mercredi</option>
                <option value="jeudi">Jeudi</option>
                <option value="vendredi">Vendredi</option>
                <option value="samedi">Samedi</option>
              </Select>
            </FormControl>
            <div className={classes.flex}>
              <TextField
                label="Début"
                type="time"
                variant="outlined"
                name={"startTime"}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 600, // 10 min
                }}
                value={state.newSchedule.startTime}
                onChange={handleNewSchedule}
              />
              <TextField
                label="Fin"
                type="time"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 600, // 10 min
                }}
                name={"endTime"}
                value={state.newSchedule.endTime}
                onChange={handleNewSchedule}
              />
            </div>
            <Button variant="contained" color="primary" type="submit">
              Ajouter
            </Button>
          </form>
          {state.validation && (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleUpdateCity}>
              Sauvegarder les créneaux
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
