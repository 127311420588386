import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

// # UTILS

// # CUSTOM COMPONENTS

// # MATERIAL UI
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ProductUpdates from '../../../components/Product/ProductUpdate';
import { axiosGetRequest } from '../../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '316px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

export default function ProductPage() {
  const { id } = useParams();
  const classes = useStyles();

  const [state, setState] = useState({
    product: {},
    loading: true,
  });

  useEffect(() => {
    axiosGetRequest(`/products/${id}`)
      .then((res) => {
        const { product } = res.data.data;
        setState({
          product,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.flexTop}>
          <h2>Détails du produit ID : {id}</h2>
        </div>
      </Grid>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <ProductUpdates product={state.product} />
      )}
    </Grid>
  );
}
