import React, { Fragment, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import List from '@material-ui/core/List';
import { useSnackbar } from 'notistack';

// # Context
import { CartContext } from './../contexts/CartContext';
import { UserContext } from '../contexts/UserContext/UserContext';

// # AXIOS
import axios from 'axios';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Popover,
  Button,
  TextField,
  Link,
  Grid,
  ListItem,
  ListItemText,
  Divider,
  Badge
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      maxWidth: '250px',
      padding: '1rem',
    },
  },
  marginB: {
    marginBottom: '0.5rem',
  },
}));

export default function ProfileNav() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const jwt = !!Cookies.get('jwt');
  const { enqueueSnackbar } = useSnackbar();

  // # CONTEXTS
  const { login, logout, user } = useContext(UserContext);
  const { setDeliveryAddress, setDeliveryInfos } = useContext(CartContext);

  // # STATE HOOKS
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const removing = () => {
    Cookies.remove('jwt');
    if (
      window.location.pathname !== '/panier' ||
      '/profil/?page=com' ||
      '/profil/?page=ad' ||
      '/profil/?page=coo'
    ) {
      window.location = '/';
    }
    logout();
    setDeliveryInfos({
      deliveryAddress: {
        title: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        complement: '',
      },
      deliveryBillingAddress: {
        address: '',
        zipCode: '',
        city: '',
        country: '',
      },
      deliveryType: '',
      deliverySubType: '',
      deliveryId: '',
      deliveryDate: '',
      deliverySchedule: '',
    });
    setDeliveryAddress({
      deliveryAddress: {
        title: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        complement: '',
        comment: '',
      },
    });
  };

  /* const handleDiscount = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API}/discounts?owner=${id}`)
      .then((res) => {
        setDiscounts(res.data.data.discounts);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }; */

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = {
      email,
      password,
    };

    await axios
      .post(`${process.env.REACT_APP_API}/users/login`, user, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        const response = res.data.data.user;
        console.log(response);
        Cookies.set('jwt', res.data.token, { expires: 90 });
        const user = {
          id: response.id,
          token: res.data.token,
          role: response.role,
          personalData: {
            firstname: response.firstname,
            lastname: response.lastname,
            fullname: response.fullname,
            phoneNumber: response.phoneNumber,
            email: response.email,
            complement: response.complement || '',
            newsLetter: response.newsLetter,
          },
          addresses: response.address,
        };
        login({
          ...user,
          ecoData: {
            ecoPoints: response.ecoPoints || 0,
            customMessage: response.ecoInvitationSettings?.customMessage || '',
            pickupSchedule: {
              from: response.ecoInvitationSettings?.pickupSchedule.from || '',
              to: response.ecoInvitationSettings?.pickupSchedule.to || '',
            },
            contacts: response.contacts || [],
          },
        });

        localStorage.removeItem('delivery');
        /* handleDiscount(response.id); */
      })
      .catch((err) => {
        console.log(err.message);
        enqueueSnackbar('Email ou mot de passe incorrect', {
          variant: 'error',
        });
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton className="buttonprofil" aria-label="compte utilisateur" onClick={handleClick}>
        <Badge variant={jwt ? "dot" : ""} color="secondary">
          <AccountCircle color="primary" />
        </Badge>
      </IconButton>
      {jwt ? (
        <Popover
          id={id}
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button>
                <Link href="/profil/?page=coo">
                  <ListItemText primary="Mon Compte" />
                </Link>
              </ListItem>
            </List>
            <Divider />
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button>
                <Link href="/profil/?page=ad">
                  <ListItemText primary="Mes Adresses" />
                </Link>
              </ListItem>
            </List>
            <Divider />
            <List component="nav" aria-label="main mailbox folder">
              <ListItem button>
                <Link href="/profil/?page=com">
                  <ListItemText primary="Mes Commandes" />
                </Link>
              </ListItem>
            </List>
            <Divider />
            <List component="nav" aria-label="main mailbox folder">
              <ListItem button>
                <Link onClick={() => removing()}>
                  <ListItemText primary="Déconnexion" />
                </Link>
              </ListItem>
            </List>
          </div>
        </Popover>
      ) : (
        <Popover
          id={id}
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <h2>Connexion :</h2>
          <form className={classes.marginB} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Connexion
            </Button>
          </form>
          <Grid container>
            <Grid item xs className={classes.marginB}>
              <Link href="/forgotpassword" variant="body2">
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                Vous n'avez pas encore de compte ?
              </Link>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
}
