import React, { useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import { TextField, Button, Modal, Backdrop, Fade, Grid } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
}));

export default function CreateTaxModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const token = Cookies.get('jwt');
    const tax = {
      name,
      amount,
    };

    await axios
      .post(`${process.env.REACT_APP_API}/taxes`, tax, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        Nouveau
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Création d'une taxe</h2>
            <form className={classes.form} onSubmit={handleCreate}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    className={classes.marginB}
                    id="name"
                    label="Nom"
                    type="text"
                    value={name}
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.marginB}
                    id="amount"
                    label="Taux"
                    type="string"
                    value={amount}
                    variant="outlined"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Créer
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
