const itemStorage = (cartItems) => {
    localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems : []));
};

const deliveryStorage = (deliveryInfos) => {
    localStorage.setItem('delivery', JSON.stringify(deliveryInfos));
};

const discountStorage = (discount) => {
    localStorage.setItem('discount', JSON.stringify(discount));
};

const companyDiscountCodeStorage = (companyDiscountCode) => {
    localStorage.setItem('companyDiscountCode', JSON.stringify(companyDiscountCode));
};

export const sumItems = (cartItems) => {
    itemStorage(cartItems);
    const itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    const total = cartItems.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    return { itemCount, total };
};

export const setUpDeliveryInfos = (prev, infos, action) => {
    let deliveryTemp = {
        ...prev.deliveryInfos,
    };

    const emptyObj = {
        deliveryAddress: {
            title: '',
            address: '',
            zipCode: '',
            city: '',
            country: '',
            complement: '',
        },
        deliveryBillingAddress: {
            address: '',
            zipCode: '',
            city: '',
            country: '',
            complement: '',
        },
        deliveryType: '',
        deliverySubType: '',
        deliveryId: '',
        deliveryDate: '',
        deliverySchedule: '',
        depositSchedule: '',
        deliveryService: '',
    };

    if (action === 'reset') {
        deliveryTemp = { ...emptyObj };
    }

    if (action === 'zipCode') {
        deliveryTemp = {
            ...emptyObj,
            deliveryAddress: {
                ...emptyObj.deliveryAddress,
                zipCode: infos,
            },
        };
    }

    if (action === 'deliveryInfos') {
        deliveryTemp.deliveryType = infos.deliveryType;
        deliveryTemp.deliverySubType = infos.deliverySubType;
        deliveryTemp.deliveryId = infos.deliveryId;
        deliveryTemp.deliveryDate = infos.deliveryDate;
        deliveryTemp.deliverySchedule = infos.deliverySchedule;
        deliveryTemp.depositSchedule = infos.depositSchedule;
        deliveryTemp.deliveryService = infos.deliveryService;
    }
    if (action === 'deliveryAddress') {
        deliveryTemp.deliveryAddress = infos.deliveryAddress;
    }

    if (action === 'deliveryBillingAddress') {
        deliveryTemp.deliveryBillingAddress = infos.deliveryBillingAddress;
    }

    deliveryStorage(deliveryTemp);

    return deliveryTemp;
};

export const CartReducer = (state, action) => {
    const { type } = action;
    switch (type) {
        case 'ADD_ITEM':
            if (!state.cartItems.find((item) => item._id === action.payload._id)) {
                state.cartItems.push({
                    ...action.payload,
                    quantity: 1,
                });
            }
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems],
            };

        case 'SET_PRODUCTS':
            return {
                ...state,
                ...sumItems(action.payload),
                cartItems: [...action.payload],
            };

        case 'REMOVE_ITEM':
            return {
                ...state,
                ...sumItems(state.cartItems.filter((item) => item._id !== action.payload._id)),
                cartItems: [...state.cartItems.filter((item) => item._id !== action.payload._id)],
            };

        case 'INCREASE':
            state.cartItems[state.cartItems.findIndex((item) => item._id === action.payload._id)].quantity++;
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems],
            };

        case 'DECREASE':
            state.cartItems[state.cartItems.findIndex((item) => item._id === action.payload._id)].quantity--;
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems],
            };

        case 'CLEAR':
            localStorage.setItem('discount', null);
            localStorage.setItem('companyDiscountCode', null);

            return {
                ...state,
                cartItems: [],
                deliveryInfos: {
                    ...setUpDeliveryInfos(state, action.payload, 'reset'),
                },
                ...sumItems([]),
            };

        case 'SET_DISCOUNT':
            discountStorage(action.payload);

            return {
                ...state,
                discount: action.payload,
            };

        case 'RESET_DISCOUNT':
            discountStorage(null);

            return {
                ...state,
                discount: null,
            };

        case 'SET_ENTERPRISE_DISCOUNT_CODE':
            companyDiscountCodeStorage(action.payload);

            return {
                ...state,
                companyDiscountCode: action.payload,
            };

        case 'SET_DRAWER':
            return {
                ...state,
                drawer: action.payload,
            };

        case 'SET_DRAWER_CART':
            return {
                ...state,
                drawerCart: action.payload,
            };

        case 'SET_STEP':
            return {
                ...state,
                step: action.payload,
            };

        case 'SET_ZIPCODE':
            return {
                ...state,
                deliveryInfos: {
                    ...setUpDeliveryInfos(state, action.payload, 'zipCode'),
                },
            };

        case 'SET_DELIVERY_INFOS':
            return {
                ...state,
                deliveryInfos: {
                    ...setUpDeliveryInfos(state, action.payload, 'deliveryInfos'),
                },
            };

        case 'SET_COMPANY_DISCOUNT':
            return {
                ...state,
                deliveryInfos: {
                    ...setUpDeliveryInfos(state, action.payload, 'companyDiscount'),
                },
            };

        case 'SET_DELIVERY_ADDRESS':
            return {
                ...state,
                deliveryInfos: {
                    ...setUpDeliveryInfos(state, action.payload, 'deliveryAddress'),
                },
            };

        case 'SET_DELIVERY_BILLING_ADDRESS':
            return {
                ...state,
                deliveryInfos: {
                    ...setUpDeliveryInfos(state, action.payload, 'deliveryBillingAddress'),
                },
            };

        default:
            return state;
    }
};
