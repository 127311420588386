import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';

import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';

// # MATERIAL UI
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Grid,
  Paper,
  Chip,
  Button,
  Divider,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Link,
  InputAdornment,
  Checkbox, Switch, FormControlLabel
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// # UTILS
import {
  axiosGetRequest,
  axiosPatchRequest,
  axiosDeleteRequest,
} from '../../../utils/axiosRequests';

// # COMPONENTS
import OrderTable from '../../../components/Order/OrderTable';
import GooglePlacesInput from '../../../components/Basics/GooglePlacesInput';
import AddSchedules from '../../../components/Relay/AddSchedules';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  flex: {
    display: 'flex',
    '&>div': {
      marginRight: '1rem',
    },
    '&>div:last-child': {
      marginRight: 0,
    },
  },
  badges: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '140px',
    marginBottom: '1rem',
  },
  addClosure: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>div:nth-child(2), &>div:nth-child(4), &>button': {
      margin: '1rem 1rem 1rem 0.2rem',
    },
    '&>button': {
      minWidth: '40px',
    },
    '&>button>span>.MuiButton-startIcon': {
      marginLeft: 0,
    },
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBot: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editing: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      maxWidth: '70%',
      marginBottom: '1rem',
    },
  },
  marginB: {
    margin: '0 0 1rem 0',
  },
  marginR: {
    margin: '0 1rem 0 0',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function RelayPointDetails() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const token = Cookies.get('jwt');

  moment.locale('fr');

  // * STATE
  const [state, setState] = useState({
    loading: true,
    editing: false,
    isDiscount: true,
    segments: [],
    relay: {},
    orders: [],
    updateRelay: {
      type: '',
      isActive: true,
      name: '',
      description: '',
      phone: '',
      siret: '',
      webSite: '',
      segment: '',
      code: '',
      discount: {
        value: 0,
        reduction: 'percent',
      },
      city: {
        name: '',
        zipCode: '',
      },
      schedules: [],
      closure: [],
      address: {
        country: '',
        address: '',
        complement: '',
        comment: '',
      },
    },
    newSchedule: {
      day: 'lundi',
      startTime: '15:30',
      endTime: '17:30',
      depositStartTime: '14:30',
      depositEndTime: '16:30',
    },
    newClosure: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().add(1, 'week').format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    Promise.all([
      // * GET RELAY POINT BY ID
      axiosGetRequest(`/relayPoints/${id}`)
        .then((res) => {
          const { relayPoint } = res.data.data;
          setState((prev) => ({
            ...prev,
            relay: relayPoint,
            isDiscount : !!relayPoint.discount.value,
            updateRelay: {
              ...relayPoint,
              segment: relayPoint.segment.id,
            },
          }));
        })
        .catch((err) => {
          console.log(err);
        }),
      // * GET ALL ACTIVE SEGMENTS
      axiosGetRequest(`/segments/?isActive=true`, token)
        .then((res) => {
          const { segments } = res.data.data;
          setState((prev) => ({
            ...prev,
            segments,
          }));
        })
        .catch((err) => {
          console.log(err);
        }),
      // * GET ALL ORDERS WITH THIS RELAY ID
      axiosGetRequest(`/orders/relay/${id}`, token)
        .then((res) => {
          const { order } = res.data.data;
          setState((prev) => ({
            ...prev,
            orders: order,
          }));
        })
        .catch((err) => {
          console.log(err);
        }),
    ]).then(() => setState((prev) => ({ ...prev, loading: false })));
  }, [id]);

  // * FORMAT SCHEDULE
  const formatSchedule = (schedule) => schedule.replace(':', 'H');

  // * CHANGE TO EDITING MODE
  const handleEditing = (bool) => {
    setState((prev) => ({
      ...prev,
      editing: bool,
    }));
  };

  // * UPDATE RELAY POINT VALUES
  const handleChange = (event) => {
    event.persist();
    const { type, value, name } = event.target;

    const formatedValue = type === 'number' ? value * 1 : value;

    setState((prev) => ({
      ...prev,
      updateRelay: {
        ...prev.updateRelay,
        [name]: formatedValue,
      },
    }));
  };

  // * UPDATE RELAY POINT DISCOUNT VALUES
  const handleChangeDiscount = (event) => {
    event.persist();
    const { value } = event.target;

    setState((prev) => ({
      ...prev,
      updateRelay: {
        ...prev.updateRelay,
        discount: {
          ...prev.updateRelay.discount,
          value: value * 1,
          reduction: 'percent',
        },
      },
    }));
  };

  // * UPDATE ADDRESS FROM G PLACES
  const updateAddress = (address_object) => {
    const { city, number, street, zipcode } = address_object;

    setState((prev) => ({
      ...prev,
      updateRelay: {
        ...prev.updateRelay,
        city: {
          ...prev.updateRelay.city,
          name: city,
          zipCode: zipcode,
        },
        address: {
          ...prev.updateRelay.address,
          address: `${number} ${street}`,
        },
      },
    }));
  };

  // * UPDATE ADDRESS COMPLEMENT AND COMMENT
  const handleChangeAddress = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      updateRelay: {
        ...prev.updateRelay,
        address: {
          ...prev.updateRelay.address,
          [name]: value,
        },
      },
    }));
  };

  // * UPDATE RELAY FROM DB
  const handleUpdate = () => {
    axiosPatchRequest(`/relayPoints/${id}`, token, state.updateRelay)
      .then((res) => {
        const {relayPoint} = res.data.data;
        setState(prev => ({
          ...prev,
          relay: relayPoint,
          editing: false,
        }))
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  // * DELETE RELAY FROM DB
  const handleDelete = () => {
    axiosDeleteRequest(`/relayPoints/${id}`, token)
      .then(() => (window.location = '/admin/relayPoints'))
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  // * HANDLE NEW SCHEDULE INFOS
  const handleNewSchedule = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      newSchedule: {
        ...prev.newSchedule,
        [name]: value,
      },
    }));
  };

  // * REMOVE SCHEDULE FROM RELAY ARRAY
  const handleRemoveSchedule = (event) => {
    event.persist();
    const idx = event.currentTarget.dataset.index * 1;
    const newSchedules = state.updateRelay.schedules.filter((el, index) => index !== idx);
    setState((prev) => ({
      ...prev,
      updateRelay: {
        ...prev.updateRelay,
        schedules: newSchedules,
      },
    }));
  };

  // * ADD SCHEDULE TO RELAY ARRAY
  const handleAddNewSchedule = () => {
    const { newSchedule } = state;

    const formatedSchedule = {
      ...newSchedule,
      startTime: formatSchedule(newSchedule.startTime),
      endTime: formatSchedule(newSchedule.endTime),
      depositStartTime: formatSchedule(newSchedule.depositStartTime),
      depositEndTime: formatSchedule(newSchedule.depositEndTime),
    };

    // INFO : CHECK DUPLICATES
    if (!state.updateRelay.schedules.some((schedule) => JSON.stringify(schedule) === JSON.stringify(formatedSchedule))) {
      setState((prev) => ({
        ...prev,
        updateRelay: {
          ...prev.updateRelay,
          schedules: [...prev.updateRelay.schedules, { ...formatedSchedule }],
        },
      }));
    }
  };

  // * HANDLE NEW CLOSURE INFOS
  const handleNewClosure = (event) => {
    event.persist();
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      newClosure: {
        ...prev.newClosure,
        [name]: value,
      },
    }));
  };

  // * ADD CLOSURE TO RELAY ARRAY
  const handleAddNewClosure = () => {
    console.log('handleNewClosure')
    if (!state.updateRelay.closure.includes(state.newClosure)) {
      setState((prev) => ({
        ...prev,
        updateRelay: {
          ...prev.updateRelay,
          closure: [...prev.updateRelay.closure, { ...state.newClosure }],
        },
      }));
    }
  };

  const handleChangeCheckbox = () => {
    setState((prev) => ({
      ...prev,
      isDiscount: !prev.isDiscount,
      updateRelay: {
        ...prev.updateRelay,
        discount: {
          ...prev.updateRelay.discount,
          value: prev.isDiscount ? null : 0
        }
      }
    }));
  }

  // * REMOVE CLOSURE TO RELAY ARRAY
  const handleRemoveClosure = (event) => {
    event.persist();
    const idx = event.currentTarget.dataset.index * 1;
    const newClosures = state.updateRelay.closure.filter((el, index) => index !== idx);
    setState((prev) => ({
      ...prev,
      updateRelay: {
        ...prev.updateRelay,
        closure: newClosures,
      },
    }));
  };

  return (
    <>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <div className={classes.flexTop}>
            <h1>Point de livraison : {state.relay.name}</h1>
            {state.editing ? (
              <div>
                <Button
                  className={classes.marginR}
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  Supprimer
                </Button>
                <Button variant="contained" color="primary" onClick={(e) => handleEditing(false)}>
                  Annuler
                </Button>
              </div>
            ) : (
              <div className={classes.margin}>
                <div>
                  <Button variant="contained" color="primary" onClick={(e) => handleEditing(true)}>
                    Modifier
                  </Button>
                </div>
              </div>
            )}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <h3>Informations :</h3>
                {state.editing ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.marginB}
                        label="Nom"
                        type="text"
                        name="name"
                        variant="outlined"
                        fullWidth
                        defaultValue={state.updateRelay.name}
                        onChange={handleChange}
                      />
                      <TextField
                        label="Description"
                        type="text"
                        name="description"
                        variant="outlined"
                        fullWidth
                        defaultValue={state.updateRelay.description}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          native
                          label="Status"
                          fullWidth
                          name="isActive"
                          defaultValue={state.updateRelay.isActive}
                          onChange={handleChange}
                        >
                          <option value>Actif</option>
                          <option value={false}>Inactif</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fillWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                          native
                          label="Type"
                          fullWidth
                          name="type"
                          defaultValue={state.updateRelay.type}
                          onChange={handleChange}
                        >
                          <option value="relay">Point relais</option>
                          <option value="entreprise">Entreprise</option>
                          <option value="eco">Eco-acteur</option>
                          <option value="conciergerie">Conciergerie</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    {state.updateRelay.type === 'entreprise' && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Siret"
                          type="text"
                          variant="outlined"
                          name="siret"
                          defaultValue={state.updateRelay.siret}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Numéro de téléphone"
                        type="number"
                        variant="outlined"
                        name="phone"
                        defaultValue={state.updateRelay.phone}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Site web"
                        name="webSite"
                        type="text"
                        variant="outlined"
                        defaultValue={state.updateRelay.webSite}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Adresse email"
                        name="email"
                        type="email"
                        variant="outlined"
                        defaultValue={state.updateRelay.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    {state.updateRelay.type === 'entreprise' && (
                        <Fragment>
                          <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      checked={state.isDiscount}
                                      onChange={handleChangeCheckbox}
                                      name="isDiscount"
                                      color="secondary"
                                  />
                                }
                                label="L'entreprise possède un code discount"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                                fullWidth
                                className={classes.marginB}
                                label="Code point de livraison"
                                type="text"
                                name="code"
                                variant="outlined"
                                defaultValue={state.updateRelay.code}
                                onChange={handleChange}
                            />
                            {state.isDiscount && (
                                <TextField
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    defaultValue={state.updateRelay.discount.value}
                                    onChange={handleChangeDiscount}
                                    variant="outlined"
                                />
                            )}
                          </Grid>
                        </Fragment>
                    )}
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Segment</InputLabel>
                        <Select
                          native
                          label="Segment"
                          defaultValue={state.updateRelay.segment}
                          onChange={handleChange}
                          name="segment"
                        >
                          <option value="" />
                          {state.segments.map((el, index) => (
                            <option key={index} value={el._id}>
                              {el.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider variant="middle" />
                    <h3>Livraison :</h3>
                    <Grid item xs={12}>
                      <GooglePlacesInput
                        label="Adresse de livraison"
                        getValue={updateAddress}
                        defaultValue={`${state.updateRelay.address.address}, ${state.updateRelay.city.zipCode} ${state.updateRelay.city.name}, ${state.updateRelay.address.country}`}
                      />
                      <TextField
                        className={classes.marginB}
                        label="Complément d'adresse"
                        type="text"
                        variant="outlined"
                        name="complement"
                        defaultValue={state.updateRelay.address.complement}
                        onChange={handleChangeAddress}
                        fullWidth
                      />
                      <TextField
                        className={classes.marginB}
                        label="Commentaire pour livreur"
                        type="text"
                        variant="outlined"
                        name="comment"
                        defaultValue={state.updateRelay.address.comment}
                        onChange={handleChangeAddress}
                        fullWidth
                      />
                      <Button variant="contained" color="primary" fullWidth onClick={handleUpdate}>
                        Valider
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <div className={classes.editingF}>
                    <div className={classes.badges}>
                      <Chip
                        size="small"
                        label={state.relay.isActive ? 'Actif' : 'Inactif'}
                        color={state.relay.isActive ? 'primary' : 'secondary'}
                      />
                      <Chip
                        size="small"
                        label={
                          state.relay.type === 'entreprise'
                            ? 'Entreprise'
                            : state.relay.type === 'eco'
                              ? 'Eco-acteur'
                              : state.relay.type === 'conciergerie'
                                ? 'Conciergerie'
                                : 'Point relais'
                        }
                        color="primary"
                      />
                    </div>
                    <p>
                      <b>ID :</b> {state.relay._id}
                    </p>
                    <p>
                      <b>Nom :</b> {state.relay.name}
                    </p>
                    <p>
                      <b>Description :</b> {state.relay.description}
                    </p>
                    {state.relay.siret !== '' && (
                      <p>
                        <b>Siret :</b> {state.relay.siret}
                      </p>
                    )}
                    {state.relay.code !== '' && (
                      <p>
                        <b>Code point de livraison :</b> {state.relay.code}
                      </p>
                    )}
                    {state.relay.discount.value && (
                      <p>
                        <b>Réduction entreprise :</b> {state.relay.discount.value} %
                      </p>
                    )}
                    <p>
                      <b>Téléphone :</b> {state.relay.phone}
                    </p>
                    <p>
                      <b>Adresse mail :</b>{' '}
                      <Link href={`mailto:${state.relay.email}`}>{state.relay.email}</Link>
                    </p>
                    {state.relay.webSite !== '' && (
                      <p>
                        <b>Site web :</b>{' '}
                        <Link href={`https://${state.relay.webSite}`} target="blank">
                          {state.relay.webSite}
                        </Link>
                      </p>
                    )}
                    <Divider variant="middle" />
                    <h3>Livraison :</h3>
                    <p>
                      <b>Ville :</b> {state.relay.city.name} ({state.relay.city.zipCode})
                    </p>
                    <p>
                      <b>Segment :</b>{' '}
                      <Link href={`/admin/segments/${state.relay.segment._id}`}>
                        {state.relay.segment.name}
                      </Link>
                    </p>
                    <p>
                      <b>Adresse :</b> {state.relay.address.address}
                    </p>
                    <p>
                      <b>Complément :</b> {state.relay.address.complement}
                    </p>
                    <p>
                      <b>Commentaire pour livreur :</b> {state.relay.address.comment}
                    </p>
                  </div>
                )}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <AddSchedules
                editing={state.editing}
                handleRemoveSchedule={handleRemoveSchedule}
                handleNewSchedule={handleNewSchedule}
                handleAddNewSchedule={handleAddNewSchedule}
                newSchedule={state.newSchedule}
                updateRelaySchedules={state.updateRelay.schedules}
                relaySchedules={state.relay.schedules}
              />
              <Paper className={classes.paper}>
                <h3>Dates de fermetures :</h3>
                {state.editing ? (
                  <div>
                    {state.updateRelay.closure.length > 0 ? (
                      <ul>
                        {state.updateRelay.closure.map((el, index) => (
                          <li key={index}>
                            Du : {moment(el.start).format('DD-MM-YYYY')} au :{' '}
                            {moment(el.end).format('DD-MM-YYYY')}
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              data-index={index}
                              onClick={handleRemoveClosure}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Aucune date</p>
                    )}
                    <Divider variant="middle" className={classes.marginB} />
                    <div className={classes.addClosure}>
                      <p>Du</p>
                      <TextField
                        className={classes.marginB}
                        label="Date de debut"
                        type="date"
                        variant="outlined"
                        name="start"
                        defaultValue={state.newClosure.start}
                        onChange={handleNewClosure}
                      />
                      <p>Au</p>
                      <TextField
                        className={classes.marginB}
                        label="Date de fin"
                        type="date"
                        variant="outlined"
                        name="end"
                        defaultValue={state.newClosure.end}
                        onChange={handleNewClosure}
                      />
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddNewClosure}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {state.relay.closure.length > 0 ? (
                      <ul>
                        {state.relay.closure.map((el, index) => (
                          <li key={index}>
                            Du : {moment(el.start).format('DD-MM-YYYY')} au :{' '}
                            {moment(el.end).format('DD-MM-YYYY')}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Aucune date</p>
                    )}
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      <OrderTable data={state} />
    </>
  );
}
