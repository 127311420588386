import React, { Fragment, useContext, useEffect, useState } from 'react';

// # MATERIAL UIs
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer, Button, ButtonGroup, Divider, Badge } from '@material-ui/core';
import { ShoppingCart, Payment } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

// CONTEXT
import { CartContext } from '../contexts/CartContext';

// # CSS
import '../assets/css/navbar.css';
import Panier from "../views/Front/Panier";

const useStyles = makeStyles((theme) => ({
  drawerPaperAnchorBottom: {
    top: "15%",
    maxWidth: "1200px",
    margin: "0 auto",
    borderRadius: '6px'
  },
}));

export default function Cart(props) {
  const { itemCount, setStep, setDrawerCart } = useContext(CartContext);
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStep(0);
    setDrawerCart(open);
  };

  return (
    <React.Fragment>
      <IconButton
        className="buttonprofil"
        aria-label="voir le painier"
        onClick={toggleDrawer(true)}
      >
        <Badge badgeContent={itemCount} color="secondary">
          <ShoppingCart color="primary" />
        </Badge>
      </IconButton>
      <Drawer classes={{ paperAnchorBottom: classes.drawerPaperAnchorBottom }} anchor="bottom" open={props.drawer} onClose={toggleDrawer(false)}>
        <div>
          <IconButton className="buttonprofil" aria-label="menu" onClick={toggleDrawer(false)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <Panier />
      </Drawer>
    </React.Fragment>
  );
}
