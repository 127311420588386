import React from 'react';

import { Route } from 'react-router-dom';

export default function AppRoute({ component: Component, layout: Layout, code, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component code={code} {...props} />
        </Layout>
      )}
    />
  );
}
