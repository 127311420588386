import React, { useState, useEffect } from 'react';

// # MATERIAL UI
import { Delete, Create } from '@material-ui/icons';
import {
  Paper,
  TableCell,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const columns = [
  { id: 'id', label: 'ID', minWidth: 100, align: 'left' },
  { id: 'name', label: 'Nom', minWidth: 100, align: 'left' },
  { id: 'slug', label: 'Slug', minWidth: 100, align: 'left' },
  { id: 'update', label: 'Modifier', minWidth: 100, align: 'right' },
  { id: 'delete', label: 'Supprimer', minWidth: 50, align: 'right' },
];

function EditableCell({
  value: initialValue,
  tagId: id,
  tagSlug: slug,
  idx,
  handleUpdateTag,
  handleUpdateModeOff,
}) {
  const [value, setValue] = useState('');

  const onChange = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleUpdateTag(id, slug, idx, `Tags.${value}`);
      handleUpdateModeOff();
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}

export default function TagTable(props) {
  const classes = useStyles();

  const { tags, handleDeleteTag, handleUpdateTag } = props;

  const [state, setState] = useState({
    open: false,
    tag: {},
    update: false,
  });

  // # OPEN DIALOG
  const handleOpenDialog = (tag) => {
    setState((prev) => ({
      ...prev,
      open: true,
      tag,
    }));
  };

  // # CLOSE DIALOG
  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      open: false,
      tag: {},
    }));
  };

  // # HANDLE UPDATE MODE ON
  const handleUpdateMode = (id) => {
    setState((prev) => ({
      ...prev,
      update: id,
    }));
  };

  // # HANDLE UPDATE MODE OFF
  const handleUpdateModeOff = () => {
    setState((prev) => ({
      ...prev,
      update: null,
    }));
  };

  // # DELETE TAG
  const handleConfirmDelete = () => {
    handleDeleteTag(state.tag).then(() => {
      setState((prev) => ({
        ...prev,
        open: false,
        tag: {},
      }));
    });
  };

  return (
    <>
      <Dialog open={state.open}>
        <DialogTitle style={{ cursor: 'move' }}>Supprimer le tag {state.tag.name}</DialogTitle>
        <DialogActions>
          <Button autoFocus color="primary" onClick={() => handleCloseDialog()}>
            Annuler
          </Button>
          <Button color="primary" onClick={() => handleConfirmDelete()}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag, idx) => (
                <TableRow key={tag.id}>
                  <TableCell align="left">{tag.id.slice(-5)}</TableCell>
                  <TableCell>
                    {state.update === tag.id ? (
                      <EditableCell
                        value={tag.name.split('Tags.')[1]}
                        idx={idx}
                        tagId={tag.id}
                        tagSlug={tag.slug}
                        handleUpdateTag={handleUpdateTag}
                        handleUpdateModeOff={handleUpdateModeOff}
                      />
                    ) : (
                      tag.name.split('Tags.')[1]
                    )}
                  </TableCell>
                  <TableCell align="left">{tag.slug}</TableCell>

                  <TableCell align="right">
                    <IconButton onClick={() => handleUpdateMode(tag.id)}>
                      <Create />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpenDialog(tag)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
