import React, { useState } from 'react';

// NOTISTACK
import { useSnackbar } from 'notistack';

// AIRTABLE
import Airtable from 'airtable';

// MATERIAL UI
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// IMAGES
import img1 from '../../assets/img/backgrounds/poireau-defense.png';
import img2 from '../../assets/img/fleche.png';
import img4 from '../../assets/img/pictoOffre.png';
import img5 from '../../assets/img/photo-homme-poireau.png';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '2rem 0 5rem 0',
    padding: '0 1rem',
    '&>div': {
      width: 'fit-content',
    },
    '&>div:nth-child(1)': {
      marginRight: '2rem',
    },
    '@media (max-width:960px)': {
      flexWrap: 'wrap-reverse',
      '&>div:nth-child(1)': {
        marginRight: '0',
      },
    },
  },
  flex2: {
    display: 'flex',
    maxWidth: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    '&>div': {
      width: 'fit-content',
    },
  },
  paragraphe: {
    fontSize: '1.1rem',
  },
  stronger: {
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  strongerLittle: {
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    color: '#e1523d',
  },
  img: {
    marginLeft: '-3rem',
    marginTop: '-8rem',
  },
  imageOffre: {
    transform: 'rotate(351deg)',
    maxHeight: '300px',
    width: '90%',
  },
  imagePoireau: {
    transform: 'rotate(351deg)',
    maxHeight: '600px',
    width: '90%',
    '@media (min-width: 0px) and (max-width: 960px)': {
      marginTop: '3rem',
    },
  },
  picto: {
    margin: '6rem 0',
    '&>img': {
      width: '90%',
    },
  },
  container: {
    margin: '0 auto',
    maxWidth: '1500px',
  },
  bigger: {
    fontSize: '1.5rem',
  },
}));

export default function PoireauDefense(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    participant: {
      firstname: undefined,
      lastname: undefined,
      email: undefined,
      phone: undefined,
      newsletter: false,
    },
    participated: false,
    loading: false,
  });
  const base = new Airtable({
    endpointUrl: 'https://api.airtable.com',
    apiKey: process.env.REACT_APP_AIRTABLE_KEY,
  }).base('app4rzuojPW3IOwMf');
  const { code } = props;

  const validateEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const handleFields = (e) => {
    e.persist();
    if (e.target.name === 'newsletter') {
      setState((prev) => ({
        ...prev,
        participant: {
          ...prev.participant,
          [e.target.name]: !prev.participant.newsletter,
        },
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      participant: {
        ...prev.participant,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const AddParticipant = async () => {
    if (
      !state.participant.firstname ||
      !state.participant.lastname ||
      !state.participant.email ||
      !state.participant.phone
    )
      return enqueueSnackbar('Veuillez remplir les champs obligatoires', {
        variant: 'error',
      });
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    if (validateEmail(state.participant.email)) {
      base('Participants')
        .select({
          filterByFormula: `email = "${state.participant.email}"`,
          view: 'Grid view',
        })
        .firstPage((error, records) => {
          const len = records.length;
          if (len > 0) {
            setState((prev) => ({
              ...prev,
              loading: false,
            }));
            return enqueueSnackbar('Cette adresse mail est déjà utilisée', {
              variant: 'error',
            });
          }
          base('Participants').create(
            [
              {
                fields: state.participant,
              },
            ],
            (err) => {
              if (err) {
                console.error(err);
                setState((prev) => ({
                  ...prev,
                  loading: false,
                }));
                return;
              }
              setState((prev) => ({
                ...prev,
                participated: true,
                loading: false,
              }));
              enqueueSnackbar(`Votre participation a bien été prise en compte`, {
                variant: 'success',
              });
            }
          );
        });
    } else {
      enqueueSnackbar(`Votre adresse mail n'a pas le bon format`, {
        variant: 'error',
      });
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <img src={img1} alt="offre" width="100%" />
      </div>

      <div className={classes.flex}>
        <div>
          <img className={classes.imagePoireau} src={img5} alt="fleche" />
        </div>

        <div className={classes.flex2}>
          <div>
            <p className={classes.paragraphe}>
              <span className={classes.bigger}>Jouez et tentez de gagner un</span> <br />
              <span className={classes.title}>Panier Locavore</span> <br />
              <span className={classes.stronger}> d'une valeur de 25€</span>
              <br />
              Tirage au sort le jeudi 10 mars à 14h30. <br />
              Retrait des gains directement auprès de notre <br /> relais la{' '}
              <span className={classes.strongerLittle}>"Salle à manger" à la Défense.</span>
            </p>
            {state.participated ? (
              <span className={classes.title}>
                Merci pour votre participation, vous recevrez bientôt la réponse !
              </span>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="lastname"
                    required
                    id="outlined-basic"
                    label="Nom"
                    variant="outlined"
                    onChange={handleFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="firstname"
                    required
                    id="outlined-basic"
                    label="Prénom"
                    variant="outlined"
                    onChange={handleFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="email"
                    required
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onChange={handleFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="phone"
                    type="number"
                    id="outlined-basic"
                    label="Téléphone"
                    variant="outlined"
                    onChange={handleFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="newsletter"
                        checked={state.participant.newsletter}
                        onChange={(e) => handleFields(e)}
                        color="primary"
                        value={state.participant.newsletter}
                      />
                    }
                    label="En cochant cette case vous souhaitez recevoir la composition des paniers chaque semaine."
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button className="btn-orange" onClick={AddParticipant}>
                    {state.loading ? (
                      <CircularProgress color="white" />
                    ) : (
                      <p style={{ fontSize: '1.5rem', margin: 0 }}>Je participe</p>
                    )}
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>

      <div className={classes.flex}>
        <div className={classes.flex2}>
          <div>
            <p className={classes.paragraphe}>
              Découvrez les paniers <br />
              <span className={classes.title}>Bio Culture</span> <br />
              <span className={classes.stronger}> 10 €</span> offerts <br />
              avec le code <span className={classes.stronger}>{code}</span> <br />à partir de{' '}
              <span className={classes.stronger}>
                25 € d'achat <br /> seulement !
              </span>
            </p>

            <p className={classes.paragraphe}>
              Valable sur votre première commande. <br />
              Offre sans abonnement ni engagement de durée.
            </p>
            <Button
              className="btn-orange"
              href="/catalogue/les-paniers-bio"
              style={{ padding: '0.5rem 2rem' }}
            >
              {' '}
              Découvrir les paniers <br /> Bio et Locaux
            </Button>
          </div>
          <img className={classes.img} src={img2} alt="fleche" height="150pxs" />
        </div>
      </div>
      <div className={classes.picto}>
        <img src={img4} alt="pictoOffre" />
      </div>
    </div>
  );
}
