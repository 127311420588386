import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
} from '@material-ui/core/';
import moment from 'moment';

const columns = [
  { id: 'name', label: 'Point relais', minWidth: 100, align: 'left' },
  { id: 'segment', label: 'Segment', minWidth: 100, align: 'left' },
  { id: 'city', label: 'Ville', minWidth: 100, align: 'center' },
  {
    id: 'zipcode',
    label: 'Code postal',
    minWidth: 100,
    align: 'center',
  },
  { id: 'phone', label: 'Tel', minWidth: 100, align: 'center' },
  { id: 'email', label: 'Mail', minWidth: 100, align: 'left' },
  { id: 'date', label: 'Date de création', minWidth: 100, align: 'left' },
  {
    id: 'status',
    label: 'Statut',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-container',
  },
  a: {
    color: '#adc00d',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default function RelayPointTable(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    relayPoints: [],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const relays = props.data;

    setState((prev) => ({
      ...prev,
      relayPoints: relays,
    }));
  }, [props]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.relayPoints
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((relay, idx) => (
                <TableRow key={idx}>
                  <TableCell align="left">
                    <a className={classes.a} href={`/admin/relayPoints/${relay.id}`}>
                      {relay.name}
                    </a>
                  </TableCell>
                  <TableCell align="left">{relay.segment?.name}</TableCell>
                  <TableCell align="center">{relay.city.name}</TableCell>
                  <TableCell align="center">{relay.city.zipCode}</TableCell>
                  <TableCell align="center">{relay.phone !== '0' ? relay.phone : ''}</TableCell>
                  <TableCell align="left">{relay.email && relay.email}</TableCell>
                  <TableCell align="left">
                    {relay.createdAt && moment(relay.createdAt).format('LLL')}
                  </TableCell>
                  <TableCell align="center">
                    <Badge
                      badgeContent={relay.isActive ? 'Actif' : 'Inactif'}
                      color={relay.isActive ? 'primary' : 'error'}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={state.relayPoints.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
