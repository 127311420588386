import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import TaxTable from '../../../components/Tax/TaxTable';
import CreateTaxModal from '../../../components/Tax/TaxCreateModal';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function TaxeList() {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const taxes = await axios.get(`${process.env.REACT_APP_API}/taxes/`).catch((err) => {
        console.log(err);
      });
      setData(taxes.data.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className={classes.flex}>
        <h2>Toutes les taxes</h2>
        <CreateTaxModal />
      </div>
      {loading ? <Skeleton variant="rect" width={500} height={150} /> : <TaxTable data={data} />}
    </>
  );
}
