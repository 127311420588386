import React, { useState, Fragment, useContext, useEffect } from 'react';

import { useSnackbar } from 'notistack';

// # MATERIAL UIs
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  Button,
  ButtonGroup,
  Divider,
  Badge,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

// # CONTEXT
import { InvitationContext } from '../contexts/InvitationContext/InvitationContext';
import { CartContext } from '../contexts/CartContext';

// # COMPONENTS
import LyraForm from './Basics/LyraForm';

// # UTILS
import { axiosGetRequest, axiosPostRequest } from '../utils/axiosRequests';

// # CSS
import '../assets/css/navbar.css';

const useStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& h2': {
      textAlign: 'center',
      color: '#f05c34',
    },
  },
  fullList: {
    width: 'auto',
  },
  button: {
    marginTop: theme.spacing(1),
    backgroundColor: '#a0c868',
    borderRadius: '30px',
    color: 'white',
  },
  but: {
    backgroundColor: '#f05c34',
    color: 'white',
    borderRadius: '30px 30px',
  },
  but1: {
    height: '28px',
  },
  but2: {
    backgroundColor: '#F05C34',
    color: 'white',
    height: '28px',
    borderRadius: '30px 30px',
    '&:hover': {
      color: '#F05C34',
    },
  },
  orange: {
    color: '#f05c34',
  },
  vert: {
    color: '#a0c868',
  },
  drawer: {
    padding: '1rem !important',
    textAlign: 'left',
  },
  info: {
    border: '1px solid #f05c33',
    borderRadius: '2px',
    padding: '10px',
    marginBottom: '1rem',
    textAlign: 'center',
    color: '#f05c34',
    '&>p': {
      margin: 0,
    },
  },
  payment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnPrice: {
    color: '#f05c33',
    borderRadius: '30px',
    backgroundColor: 'white',
    border: '1px solid #f05c33',
    padding: '1px',
    width: '80px',
    marginRight: '10px',
  },
  img: {
    width: 'auto',
  },
  title: {
    margin: '0',
  },
  titleProd: {
    margin: '0 2rem 0 2rem',
    textAlign: 'center',
  },
  cart: {
    width: '350px',
    padding: '10px 20px 20px 20px',
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  articles: {
    padding: '10px',
  },
  panier: {
    maxHeight: '60vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
}));

export default function EcoCart() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    discount: '',
    discounts: [],
    discountUsed: {},
    user: {},
  });

  const [drawer, setDrawer] = useState(false);

  // # CONTEXT
  const { invitation } = useContext(InvitationContext);
  const { increase, decrease, removeProduct, cartItems, total, itemCount } =
    useContext(CartContext);

  const handleCheckDiscount = async (e) => {
    e.preventDefault();
    const discount = state.discount === '' ? e.target.value : state.discount;

    if (!discount) {
      return enqueueSnackbar('Veuillez renseigner le champ', {
        variant: 'error',
      });
    }

    const data = { name: discount, amount: total, requete: 'discount' };

    axiosPostRequest('/discounts/check', invitation.userToken, data)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          discountUsed: { ...res.data.data.discount },
        }));
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    const { userToken } = invitation;

    if (userToken) {
      axiosGetRequest('/users/me', userToken).then((res) => {
        const { data } = res.data;
        setState((prev) => ({
          ...prev,
          user: data,
        }));
      });

      axiosGetRequest('/discounts/user', userToken).then((res) => {
        const { discounts } = res.data.data;
        setState((prev) => ({
          ...prev,
          discounts,
        }));
      });
    }
  }, [invitation]);

  const lessQty = (elem) => {
    decrease(elem);
    elem.quantity === 0 && removeProduct(elem);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer(open);
  };

  const handleRemoveDiscount = () => {
    setState((prev) => ({
      ...prev,
      discountUsed: {},
    }));
  };

  const calcTotal = (discount) => {
    const { value, reduction } = discount;

    if (value && reduction) {
      if (reduction === 'percent') {
        return Math.round((total * 1 - total * 1 * (value / 100)) * 100) / 100;
      }
      return Math.round((total * 1 - value) * 100) / 100;
    }
    return Math.round(total * 1 * 100) / 100;
  };

  return (
    <>
      <IconButton
        style={{ color: 'white' }}
        className="buttonprofil"
        aria-label="voir le painier"
        onClick={toggleDrawer(true)}
      >
        <Badge badgeContent={itemCount} color="secondary">
          <ShoppingCart fontSize="large" />
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
        <div className={classes.cart}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={5}>
              <h2 className={classes.orange}>Panier</h2>
            </Grid>
            <Grid item xs={2}>
              <IconButton className="buttonprofil" aria-label="menu" onClick={toggleDrawer(false)}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classes.titleProd}>
            <Divider />
          </div>
          <div className={classes.panier}>
            {cartItems.map((el, idx) => (
              <div className={classes.articles} key={idx}>
                <Grid container>
                  <Grid item xs={3}>
                    <img
                      height="60px"
                      src={`${process.env.REACT_APP_AWS}/${el.photo}`}
                      alt={el.name}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <p className={classes.title}>{el.name}</p>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={4}>
                        <p style={{ color: '#f05c33' }}>{el.price} €</p>
                      </Grid>
                      <Grid item xs={6}>
                        <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                          <Button className={classes.but2} onClick={() => lessQty(el)}>
                            -
                          </Button>
                          <Button className={classes.but1}>{el.quantity}</Button>
                          <Button className={classes.but2} onClick={() => increase(el)}>
                            +
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          aria-label="effecer-le-prpoduit"
                          onClick={() => removeProduct(el)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: '0.5rem' }} />
              </div>
            ))}
          </div>

          <p style={{ fontWeight: 'bold', color: '#bfcd46' }}>Bon de réduction</p>
          {!state.discountUsed.id ? (
            <>
              <div style={{ display: 'flex' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Code promo"
                  placeholder="Entrez votre code promo"
                  onChange={(e) => {
                    e.persist();
                    setState((prev) => ({ ...prev, discount: e.target.value }));
                  }}
                />
                <Button variant="outlined" color="primary" onClick={handleCheckDiscount}>
                  Valider
                </Button>
              </div>
              <p style={{ textAlign: 'center' }}>ou</p>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-type">Un bon de votre liste</InputLabel>
                <Select
                  autoWidth
                  label="Vos bons de réduction"
                  style={{ minWidth: '200px' }}
                  value={state.discount}
                  onChange={handleCheckDiscount}
                >
                  {state.discounts.length > 0 ? (
                    state.discounts.map((discount) => (
                      <MenuItem key={discount._id} value={discount.name}>
                        {discount.name} (-{discount.value}
                        {discount.reduction === 'flat' ? '€' : '%'})
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="none">Aucun bon d'achat</MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p>
                {state.discountUsed.name} (-{state.discountUsed.value}
                {state.discountUsed.reduction === 'flat' ? '€' : '%'})
              </p>
              <IconButton aria-label="cancel" onClick={handleRemoveDiscount}>
                <CancelIcon />
              </IconButton>
            </div>
          )}

          <div className="total">
            <h2 className={classes.orange}>
              Total : {state.discountUsed._id ? calcTotal(state.discountUsed) : total} €
            </h2>
          </div>
          <div className={classes.info}>
            <p>
              Aucune création de compte n'est nécéssaire, vous pouvez finaliser votre commande en 2
              clics
            </p>
          </div>
          <div className={classes.payment}>
            <LyraForm
              invitation={invitation}
              user={state.user}
              order={{
                items: cartItems,
                deliveryInfos: invitation.deliveryInfos,
                deliveryTaxe: 0,
                invitationId: invitation.id,
                discount: state.discountUsed,
              }}
              totalTtc={state.discountUsed._id ? calcTotal(state.discountUsed) : total}
              isDisabled={false}
              token={invitation.userToken}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
}
