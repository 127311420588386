import React, { useState, useEffect, Fragment } from 'react';

import Cookies from 'js-cookie';

// # MATERIAL UI
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # CUSTOM COMPONENTS
import WeightUnitiesTable from '../../../components/Weight/WeightTable';
import CreateWeightModal from '../../../components/Weight/CreateWeightModal';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function WeightList() {
  const classes = useStyles();
  const [unities, setUnities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get('jwt');

    axiosGetRequest('/weight/', token).then((res) => {
      const { unities } = res.data.data;
      setUnities(unities);
      setLoading(false);
    });
  }, []);

  // # ADD A NEW UNITY
  const addNew = (newUnity) => {
    setUnities([...unities, newUnity]);
  };

  return (
    <>
      <div className={classes.flex}>
        <h2>Toutes les unités de poids</h2>
        <CreateWeightModal addNew={addNew} />
      </div>
      {loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <WeightUnitiesTable datas={unities} />
      )}
    </>
  );
}
