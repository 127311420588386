import React, { useEffect, useContext, useState } from 'react';

import { useSnackbar } from 'notistack';

import axios from 'axios';
import { useParams } from 'react-router';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { calcTotalOrder } from '../../../utils/totalOrders';

import {UserContext} from "../../../contexts/UserContext/UserContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    margin: '1rem auto 0 auto',
  },
  container: {
    marginTop: '6rem',
  },
  btn: {
    backgroundColor: 'white',
    color: '#f05c34',
    border: '1px solid #f05c34',
    borderRadius: '30px',
    padding: '14px',
    '&:hover': {
      backgroundColor: '#f05c34',
      color: 'white',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    maxWidth: '50%',
    margin: '2rem auto',
  },
});

export default function Dashboard() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);
  const { invitationId } = useParams();

  const [state, setState] = useState({
    invitation: {
      deliveryInfos: {
        deliveryAddress: {
          address: '',
          zipCode: '',
          city: '',
          country: '',
          complement: '',
        },
        deliveryDate: '',
        deliverySchedule: '',
      },
      contacts: [],
    },
    orders: [],
    notInContacts: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const invit = await axios
        .get(`${process.env.REACT_APP_API}/invitations/${invitationId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .catch(() => {
          enqueueSnackbar('Une erreur est survenue', {
            variant: 'error',
          });
        });

      const matchingOrders = await axios
        .get(`${process.env.REACT_APP_API}/invitations/orders/${invitationId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .catch(() => {
          enqueueSnackbar('Une erreur est survenue', {
            variant: 'error',
          });
        });

      const ecoContacts = await axios
        .get(`${process.env.REACT_APP_API}/users/contacts`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .catch((err) => {
          console.error(err);
        });

      const { invitation } = invit.data.data;
      const { orders } = matchingOrders.data.data;

      const onlyId = invitation.contacts.map((el) => el.id);
      const contactsWoInvit = ecoContacts.data.data.contacts.filter((el) => {
        if (onlyId.indexOf(el._id) === -1) return el;
        return false;
      });

      setState((prev) => ({
        ...prev,
        invitation: {
          ...prev.invitation,
          deliveryInfos: {
            ...prev.invitation.deliveryInfos,
            deliveryAddress: {
              ...prev.invitation.deliveryInfos.deliveryAddress,
              address: invitation.deliveryInfos.deliveryAddress.address,
              zipCode: invitation.deliveryInfos.deliveryAddress.zipCode,
              city: invitation.deliveryInfos.deliveryAddress.city,
              country: invitation.deliveryInfos.deliveryAddress.country,
              complement: invitation.deliveryInfos.deliveryAddress.complement,
            },
            deliveryDate: invitation.deliveryInfos.deliveryDate,
            deliverySchedule: invitation.deliveryInfos.deliverySchedule,
          },
          contacts: [...invitation.contacts],
        },
        orders: [...orders],
        notInContacts: [...contactsWoInvit],
      }));
    };
    fetchData();
  }, [invitationId, enqueueSnackbar, user.token]);

  const findContactOrder = (contactId) => {
    const order = state.orders.find((currOrder) => currOrder.owner.id === contactId);

    if (!order) {
      return 'Aucune commande';
    }

    return order;
  };

  const calcTotalInvit = () => {
    const { orders } = state;

    return orders.filter((order) => order.status === 'paid')
      .reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0);
  };

  const addContact = async (contactId) => {
    const newContact = state.notInContacts.find((el) => el._id === contactId);
    const newNotIn = state.notInContacts.filter((el) => el._id !== contactId);

    await axios
      .patch(
        `${process.env.REACT_APP_API}/invitations/contacts`,
        { invitationId, contactId },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(() => {
        setState((prev) => ({
          ...prev,
          invitation: {
            ...prev.invitation,
            contacts: [...prev.invitation.contacts, newContact],
          },
          notInContacts: [...newNotIn],
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <h1>Tableau de bord</h1>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <h4 style={{ textAlign: 'start' }}>Contacts invités</h4>
          <TableContainer>
            <Table className={classes.table}>
              <TableBody>
                {state.invitation.contacts.map((contact) => (
                  <StyledTableRow key={contact.id}>
                    <StyledTableCell component="th" scope="row">
                      {contact.firstname} {contact.lastname}
                    </StyledTableCell>
                    <StyledTableCell align="left">{contact.email}</StyledTableCell>
                    {findContactOrder(contact._id).status === 'paid' && findContactOrder(contact._id).items ?
                    (
                      <StyledTableCell align="right">
                        {calcTotalOrder(findContactOrder(contact._id)).formatTotalTtc}{' '}
                        €
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="right" />
                    )}
                    <StyledTableCell align="right">
                      {findContactOrder(contact._id).status === 'canceled' && 'Annulée'}
                      {findContactOrder(contact._id).status === 'paid' && 'Payée'}
                      {findContactOrder(contact._id).status === 'refuse' && 'Refusée'}
                      {findContactOrder(contact._id).status === 'waiting' &&
                        'En attente de paiement'}
                      {findContactOrder(contact._id).status === 'abandoned' && 'Abondonnée'}
                      {findContactOrder(contact._id).status === 'refund' && 'Remboursée'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={5}>
          <h4 style={{ textAlign: 'start' }}>Contacts non invités</h4>
          <TableContainer>
            <Table className={classes.table}>
              <TableBody>
                {state.notInContacts.length > 0 ? (
                  state.notInContacts.map((contact) => (
                    <StyledTableRow key={contact._id}>
                      <StyledTableCell scope="row">
                        {contact.firstname} {contact.lastname}
                      </StyledTableCell>
                      <StyledTableCell align="left">{contact.email}</StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton aria-label="add" onClick={() => addContact(contact._id)}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow scope="row">
                    <StyledTableCell>Vous n'avez pas d'autre contact à inviter</StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <div>
            <h3>
              Total :{' '}
              <b style={{ color: 'black' }}>{state.orders.length > 0 ? calcTotalInvit() : '0'} €</b>
            </h3>
          </div>
        </Grid>

        <div className={classes.flex}>
          <Button href={`/update_invitation/${invitationId}`} className={classes.btn}>
            Relancer les invitations
          </Button>
        </div>
      </Grid>
    </Container>
  );
}
