import React from 'react';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListSubheader,
  List,
  ListItemText,
  Collapse,
} from '@material-ui/core';

import {
  ShoppingBasket,
  Spa,
  ExpandLess,
  ExpandMore,
  TurnedIn,
  TrendingUp,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ProductsMenu() {
  const classes = useStyles();

  const [openCategories, setOpenCategories] = React.useState(false);
  const [openTaxes, setOpenTaxes] = React.useState(false);

  const handleOpenCategories = () => {
    setOpenCategories((prev) => !prev);
  };

  const handleOpenTaxes = () => {
    setOpenTaxes((prev) => !prev);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Catalogue
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem>
        <ListItemIcon>
          <Spa />
        </ListItemIcon>
        <Link href="/admin/products/list">Produits</Link>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <ShoppingBasket />
        </ListItemIcon>
        <Link href="/admin/baskets/list">Paniers</Link>
      </ListItem>

      <ListItem button onClick={handleOpenCategories}>
        <ListItemIcon>
          <TurnedIn />
        </ListItemIcon>
        <ListItemText primary="Rubriques" />
        {openCategories ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCategories} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/categories">Toutes les rubriques</Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <Link href="/admin/subcategories">Toutes les sous-rubriques</Link>
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleOpenTaxes}>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText primary="Taxes" />
        {openTaxes ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openTaxes} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link href="/admin/taxes">Toutes les taxes</Link>
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
