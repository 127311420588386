import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Chip } from '@material-ui/core/';
import moment from 'moment';
import 'moment/locale/fr';

// # UTILS
import { calcTotalOrder } from '../../utils/totalOrders';
import currencyFormater from '../../utils/currencyFormater';

const useStyles = makeStyles({
  bbold: {
    fontSize: '18px',
  },
  root: {
    width: '100%',
    display: 'block',
  },
  container: {
    maxHeight: 550,
  },
  header: {
    display: 'flex',
    margin: '1rem 0',
    '& p': {
      margin: 0,
    },
    '& > div': {
      flexGrow: 1,
      padding: '1rem',
      border: 'solid 1px black',
      borderRadius: '5px',
    },
    '& > div:nth-child(1)': {
      borderRadius: '5px 0 0 5px',
    },
    '& > div:nth-child(2)': {
      borderRadius: 0,
      borderRight: 'none',
      borderLeft: 'none',
    },
    '& > div:nth-child(3)': {
      display: 'flex',
      padding: '0',
      flexDirection: 'column',
      borderRadius: '0 5px 5px 0',
      '& > div': {
        flexGrow: 1,
        '&:nth-child(1), &:nth-child(2)': {
          borderBottom: 'black 1px solid',
        },
        '&:nth-child(3)': {
          flexGrow: 2,
        },
        '& > p': {
          margin: '0.5rem',
        },
      },
    },
  },

  body: {
    display: 'flex',
    pageBreakInside: 'avoid',
    marginBottom: '1rem',
    '& > div': {
      flexGrow: 1,
      padding: '1rem',
      border: 'solid 1px black',
    },
    '& > div:nth-child(1), & > div:nth-child(3)': {
      flexGrow: 2,
    },
    '& > div:nth-child(1), & > div:nth-child(2), & > div:nth-child(3), & > div:nth-child(4)': {
      borderRight: 0,
    },
    '& > div:nth-child(1)': {
      borderRadius: '5px 0 0 5px',
    },
    '& > div:nth-child(2), & > div:nth-child(3),& > div:nth-child(4), & > div:nth-child(5)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& > div:nth-child(3) > ul': {
      padding: 0,
    },
    '& > div:nth-child(5)': {
      borderRadius: '0 5px 5px 0',
    },
  },
});

export default function DeliveryNotesTableEco(props) {
  const classes = useStyles();
  moment.locale('fr');
  const deliveryNotes = props.data;

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div>
          <p>
            <b>{deliveryNotes.length} commandes</b>
          </p>
          <p>
            {deliveryNotes[0].deliveryInfos.deliveryAddress.city} -{' '}
            {deliveryNotes[0].deliveryInfos.deliveryAddress.zipCode}
          </p>
          <p>{moment(deliveryNotes[0].deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')}</p>
          <p>{deliveryNotes[0].deliveryInfos.deliverySchedule}</p>
        </div>
        <div style={{ maxWidth: '50%' }}>
          <p>
            <b>Eco-acteur :</b> {deliveryNotes[0].invitation.sender.fullname}
          </p>
          <p>
            <b>Tél :</b> {deliveryNotes[0].invitation.sender.phoneNumber}
          </p>
          <p>
            <b>Adresse :</b>
            <br />
            {deliveryNotes[0].deliveryInfos.deliveryAddress.address} -{' '}
            {deliveryNotes[0].deliveryInfos.deliveryAddress.city}{' '}
            {deliveryNotes[0].deliveryInfos.deliveryAddress.zipCode}
          </p>
          {deliveryNotes[0].deliveryInfos.deliveryAddress.complement !== '' && (
            <p>
              <b>Complément : </b>
              {deliveryNotes[0].deliveryInfos.deliveryAddress.complement}
            </p>
          )}
          {deliveryNotes[0].deliveryInfos.deliveryAddress.comment !== '' && (
            <p>
              <b>Commentaire : </b>
              {deliveryNotes[0].deliveryInfos.deliveryAddress.comment}
            </p>
          )}
        </div>
        <div>
          <div>
            <p>SACS : {deliveryNotes.reduce((acc, curr) => curr.labelsLen + acc, 0)}</p>
          </div>
          <div>
            <p>CAISSES :</p>
          </div>
          <div>
            <p>NOTE :</p>
          </div>
        </div>
      </div>
      {deliveryNotes.map((el, idx) => (
        <div className={classes.body} key={idx}>
          <div>
            <p>
              <b>
                Commande {idx + 1}/{deliveryNotes.length}
              </b>
            </p>
            <p>
              {el.owner.lastname} {el.owner.firstname}
            </p>
          </div>
          <div>
            <Chip
              label={Math.round(el.items.reduce((acc, curr) => acc + curr.quantity, 0))}
              color="secondary"
            />
          </div>
          <div>
            <ul>
              {el.items.map((e, index) => (
                <li key={index}>
                  {e.name}{' '}
                  <b>
                    x<span className={classes.bbold}>{e.quantity}</span>
                  </b>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p style={{ fontSize: '15px' }}>
              {currencyFormater(calcTotalOrder(el).formatTotalTtc)}
            </p>
          </div>
          <div>
            <p>
              {el.status === 'paid'
                ? 'Payé'
                : el.status === 'waiting'
                ? 'En attente de paiement'
                : el.status === 'refuse'
                ? 'Refusé'
                : 'Annulé'}
            </p>
          </div>
        </div>
      ))}
    </Paper>
  );
}
