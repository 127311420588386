import React, { Fragment } from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  preview: {
    padding: theme.spacing(2),
    textAlign: 'initial',

    '& > p': {
      marginBottom: '1rem',
      '& > span': {
        display: 'block',
      },
    },

    '& > .txt-red': {
      fontWeight: 'Bold',
      color: '#f05c34',
    },

    '& > .txt-green': {
      fontWeight: 'Bold',
      color: '#bfcf38',
    },
    '& > ul > li': {
      listStyle: 'initial',
    },
  },
}));

export default function InvitationMailPreview(props) {
  const classes = useStyles();

  return (
    <>
      <p>Voici le mail d'invitation qui va être envoyé aux contacts sélectionnés :</p>
      <Paper className={classes.preview} elevation={3}>
        <p>Bonjour [Prénom du contact],</p>

        <p>
          Je vous propose de participer à notre commande groupée de paniers Bio de cette semaine.
        </p>

        <p className="txt-red">Pour commander vos paniers en 3 clics :</p>
        <ul>
          <li>Cliquez sur "Je découvre les paniers de la semaine !"</li>
          <li>Choississez les paniers et suppléments qui vous font envie</li>
          <li>Réglez directement par carte bancaire sur le site</li>
        </ul>

        <p>Tous les détails concernant la livraison se trouvent en haut de ce mail.</p>

        <p className="txt-green">
          {props.message !== ''
            ? props.message
            : '[AJOUT DE VOTRE MESSAGE PERSONNALISÉ ICI (SI EXISTANT)]'}
        </p>

        <p>
          Belle semaine à vous,
          <span>{props.userFirstname}</span>
        </p>
      </Paper>
    </>
  );
}
