import React, {Fragment, useState, useEffect} from 'react';
// UTILS
import Cookies from "js-cookie";
import {axiosDeleteRequest, axiosGetRequest, axiosPatchRequest, axiosPostRequest} from "../../../utils/axiosRequests";
//COMPONENTS
import AddBanner from './../../../components/Banner/AddBanner';
// MATERIAL UI
import {TextField, Button, Paper, Grid, Table, TableHead, TableCell, TableRow, TableBody, Switch, Modal} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    paper: {
      padding: '1rem',
        marginBottom: '1rem'
    },
    image: {
        borderRadius: '8px',
        margin: 'auto',
        '& > img': {
            borderRadius: '8px',
            width: '100%'
        },
    },
    inputFile: {
        display: 'none',
    },
    modal: {
        width: 'fit-content',
        height: 'fit-content',
        margin: 'auto',
    },
    imageModal: {
        maxHeight: '90vh',
    },
    imageTable:{
        '&:hover':{
            cursor: 'pointer',
        }
    }
})

export default function BannersList() {
    const classes = useStyles();
    const token = Cookies.get('jwt');
    const initialState = {
        title: undefined,
        photo: undefined,
        isActive: undefined,
    };

    const [state, setState] = useState({
        addBanner: {...initialState},
        updateBanner: {...initialState},
        updatedRow: undefined,
        bannerList: [],
        open: false,
        file: undefined,
        isActiveBanner: undefined
    });

    useEffect(() => {
        axiosGetRequest('/banners/').then((res) => {
            const {banners} = res.data.data;
            setState(prev => ({
                ...prev,
                bannerList: banners,
                })
            );
        }).catch(err => console.log(err))
    }, [])

    useEffect(()=> {
        console.log('vanner changed');
        const updateIsActive = async() => {
            console.log(state.updateBanner);
            let removeActive;
            const updatedBannerList = state.bannerList.map(bann => {
                if(bann.isActive === true) {
                    removeActive = bann._id;
                    return {
                        ...bann,
                        isActive: false
                    }
                }
                return bann;
            });
            const updatedBanner = await axiosPatchRequest(`/banners/${state.isActiveBanner}`, token, {infos:{isActive: state.updateBanner.isActive}, removeActive}).then(res => res.data.data.banner).catch(err => console.log(err));
            const bannerList = updatedBannerList.map(bann => {
                if (bann._id === state.isActiveBanner) {
                    return {
                        ...updatedBanner
                    }
                }
                return bann;
            });
            setState(prev => ({
                ...prev,
                updateBanner: {...initialState},
                bannerList: [...bannerList],
            }));
        }
        if(state.updateBanner.isActive !== undefined) {
            updateIsActive();
        }
    }, [state.updateBanner.isActive]);

    const addNewBannerToList = (newBanner) => {
        setState(prev => ({
            ...prev,
            bannerList: [newBanner, ...prev.bannerList],
        }))
    };

    const removeBannerFromList = (deletedBanner) => {
        setState(prev => ({
            ...prev,
            bannerList : prev.bannerList.filter((banner) => banner._id !== deletedBanner._id),
        }))
    }

    const updateBannerInList = (updatedBanner) => {
        const bannerList = state.bannerList.map((banner) => {
            if(banner._id === updatedBanner._id){
                return {
                    ...banner,
                    title: updatedBanner.title,
                }
            }
            return banner;
        });
        setState(prev => ({
            ...prev,
            bannerList,
        }))
    }

    const handleChangeUpdate = async(e, banner) => {
        e.persist();
        const {name, value} = e.target;
        setState(prev => ({
            ...prev,
            updateBanner: {
                ...prev.addBanner,
                [name]: name === 'isActive' ? !banner.isActive : value
            },
            isActiveBanner: banner._id
        }))
    }

    const update = (banner) => {
        console.log(banner);
        setState(prev => ({
            ...prev,
            updatedRow: banner._id,
            updateBanner: {
                title: banner.title
            }
        }))
    }

    const handleUpdate = async(e, id) => {
        if(e.keyCode === 13) {
            const updatedBanner = await axiosPatchRequest(`/banners/${id}`, token, {infos:state.updateBanner}).then(res => res.data.data.banner).catch(err => console.log(err));

            // # UPDATE BANNER IN BANNERLIST
            updateBannerInList(updatedBanner);
            setState(prev => ({
                ...prev,
                updatedRow: undefined,
                })
            )
        }
    }

    const handleDelete = async(id) => {
        const deletedBanner = await axiosDeleteRequest(`/banners/${id}`, token).then(res => res.data.data.banner ).catch(err => console.log(err));

        // # REMOVE BANNER
        removeBannerFromList(deletedBanner);
    }

    const widden = (file) => {
        setState(prev => ({
            ...prev,
            open :true,
            file,
        }));
    };

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            open: false
        }))
    }

    return (
        <Fragment>
            <h1>Les bannières</h1>
            <AddBanner state={state} widden={widden} addNewBannerToList={addNewBannerToList}/>

            <Modal className={classes.modal} open={state.open} onClose={handleClose}>
                <Paper>
                    <img className={classes.imageModal} src={state.file} alt="image"/>
                </Paper>
            </Modal>

            <Paper className={classes.paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"} width={50}>
                                Image
                            </TableCell>
                            <TableCell width={'70%'} rowSpan={4}>
                                Titre
                            </TableCell>
                            <TableCell align={"center"}>
                                Actif
                            </TableCell>
                            <TableCell align={'center'}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.bannerList.map( (banner, key) => (
                            <TableRow key={key}>
                                <TableCell>
                                    <img className={classes.imageTable} onClick={()=>widden(`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${banner.photo}`)} width={'50px'} src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${banner.photo}`} alt=""/>
                                </TableCell>
                                <TableCell>
                                    {banner._id === state.updatedRow ? (
                                        <TextField fullWidth id="outlined-basic" size={"small"} value={state.updateBanner.title} name={"title"} label="Titre" variant="outlined" onKeyDown={(e)=>handleUpdate(e, banner._id)} onChange={(e)=>handleChangeUpdate(e, banner)}/>
                                    ) : banner.title}
                                </TableCell>
                                <TableCell>
                                    <Switch checked={banner.isActive} name={"isActive"} onChange={(e)=>handleChangeUpdate(e, banner)}/>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button fullWidth onClick={()=>update(banner)} variant={'contained'} color={'primary'}>Modifier</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth onClick={()=>handleDelete(banner._id)} variant={'contained'} color={'secondary'}>Supprimer</Button>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Fragment>
    );
}