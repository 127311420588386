import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import { Paper, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CategoryTable from '../../../components/Category/CategoryTable';
import CreateCategoryModal from '../../../components/Category/CategoryCreateModal';

// MATERIAL.UI
// #STYLE

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
}));

export default function CategoryList() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    loading: true,
    status: 'all',
    categories: [],
    categoriesShowcased: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/categories/`)
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          setState((prev) => ({
            ...prev,
            categories: res.data.data.categories,
            categoriesShowcased: res.data.data.categories,
          }));
        });
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (state.status === 'all') {
      return setState((prev) => ({
        ...prev,
        categoriesShowcased: prev.categories,
      }));
    }
    const filter = state.categories.filter((el) => el.isActive === state.status);
    setState((prev) => ({
      ...prev,
      categoriesShowcased: filter,
    }));
  }, [state.status, state.categories]);

  return (
    <>
      <div className={classes.flex}>
        <h2>Toutes les rubriques</h2>
        <CreateCategoryModal />
      </div>

      <Paper className={classes.paper}>
        <h3>Filtre</h3>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={3}>
            <FormControl style={{ minWidth: '150px' }} variant="outlined">
              <InputLabel id="select-payment-label">Statut</InputLabel>
              <Select
                labelId="select-payment-label"
                label="Statut"
                defaultValue="all"
                value={state.status}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="all">Tout</MenuItem>
                <MenuItem value={false}>Inactif</MenuItem>
                <MenuItem value>Actif</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <CategoryTable data={state.categoriesShowcased} />
      )}
    </>
  );
}
