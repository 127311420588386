import React, { Fragment } from 'react';

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import moment from 'moment/moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    width: '60vw',
    maxWidth: '600px',
    minWidth: '320px',
    maxHeight: '90vh',
    overflowY: 'scroll',
    textAlign: 'left',
  },
  padding: {
    margin: '2rem',
  },
  iframe: {
    marginBottom: '20vh',
    width: 'auto',
    height: '40vh',
  },
  figure: {
    margin: '0 auto',
    width: '100%',
    overflow: 'hidden',
    height: '250px',
  },
  producteur: {
    height: 'fit-content',
    textAlign: 'left',
    boxShadow: '0px 0px 10px 5px #d8d8d8',
  },
  name: {
    fontWeight: '700',
    color: 'black',
    fontSize: '1.5rem',
    textAlign: 'center',
    margin: '0',
  },
  subtitle: {
    marginTop: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '1rem',
    fontWeight: '300',
  },
  production: {
    textAlign: 'center',
    color: 'black',
  },
  sign: {
    fontStyle: 'italic',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#000000',
    opacity: '0.6',
  },
  prod: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  date: {
    fontSize: '1.4rem',
    textAlign: 'center',
    color: 'black',
    fontWeight: '700',
  },
  text: {
    color: 'black',
  },
  red: {
    color: '#F05C35',
  },
  section: {
    '& > p': {
      color: 'black',
      margin: '0',
    },
  },
}));

export default function ModalProducteurs(props) {
  const { infos } = props;
  const classes = useStyles(makeStyles);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.producteur}>
        <figure
          className={classes.figure}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${infos.photo})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            margin: '0',
            width: '100%',
            height: '200px',
          }}
        />
        <div className={classes.prod}>
          <p className={classes.name}>
            {infos.firstname} {infos.lastname}
          </p>
          <p className={classes.subtitle}>{infos.location}</p>
          <p className={classes.production}>{infos.production}</p>
          <p className={classes.sign}>{infos.distinctiveSign}</p>
          <Button className="btn-orange" type="button" onClick={handleOpen}>
            Découvrir
          </Button>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {infos.photo ? (
              <figure
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${infos.photo})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  margin: '0',
                  width: '100%',
                  height: '50vh',
                }}
              />
            ) : null}
            <div className={classes.padding}>
              <p className={classes.name}>
                {infos.firstname} {infos.lastname}
              </p>
              <p className={classes.subtitle}>{infos.location}</p>
              <p className={classes.date}>
                Partenaire Bio Culture depuis {moment(infos.sinceWhen).format('YYYY')}
              </p>
              <p className={classes.text}>
                <span className={classes.red}>Production :</span> {infos.production}
              </p>
              <p className={classes.text}>
                <span className={classes.red}>Bio depuis :</span>{' '}
                {moment(infos.sinceWhenBio).format('YYYY')}
              </p>
              {infos.autre ? (
                <p className={classes.text}>
                  <span className={classes.red}>Autre :</span> {infos.autre}
                </p>
              ) : null}
              <p className={classes.text}>
                <span className={classes.red}>Signe distinctif :</span> {infos.distinctiveSign}
              </p>
              <br />
              <section
                className={classes.section}
                dangerouslySetInnerHTML={{ __html: infos.description }}
              />
              {/* {infos.video ? (
                <iframe
                  title="youtube-video-producteur"
                  className={classes.iframe}
                  src={infos.video}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              ) : null} */}
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
