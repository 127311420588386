import React, { useState, useEffect } from 'react';
import {
  Paper,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import LensIcon from '@material-ui/icons/Lens';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
}));

const columns = [
  { id: 'name', label: 'Nom', minWidth: 50, align: 'left' },
  { id: 'amount', label: 'Montant Payé', minWidth: 50, align: 'center' },
  { id: 'used', label: 'Utilisation', minWidth: 50, align: 'center' },
  { id: 'isActive', label: 'Actif', minWidth: 50, align: 'right' },
];

function Row(props) {
  return (
    <TableRow>
      <TableCell>
        <Link href={`/admin/discounts/${props.discount.id}`}>{props.discount.name}</Link>
      </TableCell>
      <TableCell align="center">
        {props.discount.value} {props.discount.reduction === 'percent' ? '%' : '€'}
      </TableCell>
      <TableCell align="center">{props.discount.count}</TableCell>
      <TableCell align="right">
        <LensIcon color={props.discount.isActive ? 'primary' : 'secondary'} />
      </TableCell>
    </TableRow>
  );
}

export default function DiscountList(props) {
  const classes = useStyles();

  const [state, setState] = useState({
    discounts: [],
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      discounts: props.discounts,
    }));
  }, [props]);

  return (
    <Paper className={classes.paper}>
      <h2>Bons d'achats</h2>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.discounts && state.discounts.map((el, idx) => <Row key={idx} discount={el} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
