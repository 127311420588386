import React, { useState, useEffect } from 'react';

// # MATERIAL UI
import { TextField } from '@material-ui/core';

export default function GooglePlacesInput(props) {
  const { label, getValue, defaultValue } = props;

  const [state, setState] = useState({
    input: defaultValue || '',
    address_components: [],
  });

  let autoComplete;

  const loadScript = (url, callback) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery) => {
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components', 'formatted_address'],
    };

    autoComplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    );
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
  };

  const handlePlaceSelect = async (updateQuery) => {
    const place = autoComplete.getPlace();
    const { address_components, formatted_address } = place;

    updateQuery((prev) => ({
      ...prev,
      input: formatted_address,
      address_components,
    }));

    const address = {
      number: '',
      street: '',
      city: '',
      zipcode: '',
    };

    address_components.forEach((component) => {
      const componentType = component.types[0];
      const { long_name } = component;
      switch (componentType) {
        case 'street_number':
          address.number = long_name;
          break;
        case 'locality':
          address.city = long_name;
          break;
        case 'route':
          address.street = long_name;
          break;
        case 'postal_code':
          address.zipcode = long_name;
          break;
        default:
          break;
      }
    });

    getValue(address);
  };

  const handleChange = (event) => {
    event.persist();
    const { value } = event.target;

    setState((prev) => ({
      ...prev,
      input: value,
      address_components: [],
    }));
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`,
      () => handleScriptLoad(setState)
    );
  }, []);

  return (
    <TextField
      fullWidth
      style={{ marginBottom: '1rem' }}
      id="autocomplete"
      type="text"
      variant="outlined"
      label={label}
      value={state.input}
      onChange={handleChange}
    />
  );
}
