import React, { useContext } from 'react';

// # CONTEXT

// # MATERIAL UI
import { Button, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CartContext } from '../../../contexts/CartContext';

const useStyles = makeStyles((theme) => ({
  qtyBtn: {
    width: '40px',
    minWidth: '40px',
    height: '40px',
    borderRadius: '40px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,

    '& span': {
      margin: 0,
    },
  },
}));

export default function AddButton(props) {
  const classes = useStyles();
  const { addProduct, increase } = useContext(CartContext);

  const { product, setDrawer, matchingItem } = props;

  // # CHECK IF BUTTON SHOULD BE DISABLED
  const isDisabled = matchingItem?.quantity >= matchingItem?.stock;

  // # HANDLE ADD CLICK
  const handleAddProduct = () => {
    addProduct(product);

    // # OPEN DRAWER IF NO MATCHING PRODUCT IN CONTEXT
    if (!matchingItem) {
      setDrawer(true);
    }
  };

  return (
    <Button
      className={classes.qtyBtn}
      startIcon={<Add />}
      disabled={isDisabled}
      onClick={() => (matchingItem ? increase(matchingItem) : handleAddProduct())}
    />
  );
}
