import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import baniereD from '../../assets/img/backgrounds/BaniereDesktop.svg'
import baniereM from '../../assets/img/backgrounds/BaniereMobile.svg'
import {axiosGetRequest} from "../../utils/axiosRequests";
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  head: {
    width: '100%',
    height: 'auto',
    maxWidth: '1440px',
    '@media only screen and (min-width:0px) and (max-width: 960px)': {
      display: 'none'
    }
  },
  tablette: {
    display: 'none',
    '@media only screen and (min-width:577px) and (max-width: 960px)': {
      display: 'block',
      width: '100%',
      height: 'auto',

    }
  },
  mobile: {
    display: 'none',
    '@media only screen and (min-width:0px) and (max-width: 577px)': {
      display: 'block',
      width: '100%',
      height: 'auto',
      
    }
  }
    /*backgroundImage: `url(${baniereD})`,
    backgroundSize: 'cover',
    padding: '4rem',
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1280px',
    margin: '0 auto',
    justifyContent: 'space-between',
    '& > div:nth-child(1)': {
      textAlign: 'left',
      width: '60%',
    },
    '@media only screen and (min-width:0px) and (max-width: 375px)': {
      backgroundImage: `url(${baniereM})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: 'calc(100vh / 2.5)',
      padding: '4rem',
      justifyContent: 'space-between',
      height: 'auto',
      display: 'flex',
      margin: '0 auto',
      alignItems: 'center',
      '& > div:nth-child(1)': {
        textAlign: 'left',
        width: '60%',
      },
  },
    '@media only screen and (min-width:376px) and (max-width: 703px)': {
      backgroundImage: `url(${baniereT})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100px',
      padding: '4rem',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      justifyContent: 'space-between',
      
  },
  /*h1: {
    margin: 0,
    color: 'white !important',
    fontFamily: 'Bitter, sans-serif !important',
    fontSize: '3rem !important',
    textAlign: 'left',
    '@media (min-width:600px) and (max-width: 1020px)': {
      fontSize: '2rem !important',
    },
    '@media (min-width:0px) and (max-width: 600px)': {
      fontSize: '1rem !important',
    },
    // maxWidth: '48vw',
  },
  button: {
    marginTop: '1rem',
    padding: '1rem 2rem !important',
    borderRadius: '100px !important',
    '& > span': {
      fontSize: '1rem !important',
    },
    '@media (min-width:600px) and (max-width: 1024px)': {
      padding: '0.5rem 1rem !important',
      '& > span': {
        fontSize: '1rem !important',
      },
    },
    '@media (min-width:400px) and (max-width: 600px)': {
      padding: '0.5rem 1rem !important',
      '& > span': {
        fontSize: '1rem !important',
      },
    },
    '@media (min-width:0px) and (max-width: 400px)': {
      padding: '0.5rem 1rem !important',
      '& > span': {
        fontSize: '0.8rem !important',
      },
    },
  },
  image: {
    width: '100%',
    '@media (min-width:0px) and (max-width: 450px)': {
      width: '60%',
    },*/
  
}));

export default function Hero() {
  const classes = useStyles();
  const token = Cookies.get('jwt');
  const [state, setState] = useState({
    photoD: undefined,
    photoM: undefined,
    photoT: undefined
  });

  useEffect(() => {
      axiosGetRequest(`/home/`, token).then(res => setState((prev) => ({
        ...res.data.data.home,
      }))).catch(err => console.log(err))
  }, []);


    const imageClick = () => {
      window.location.href = "/catalogue/les-paniers-bio"
      
    }

  return (
    <div>
      <img src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.photoD}`} className={classes.head} onClick={() => imageClick()} />
      <img src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.photoT}`} className={classes.tablette} onClick={() => imageClick()} />
      <img src={`${process.env.REACT_APP_AWS_BANNIER_PICTURE_BUCKET}/${state.photoM}`} className={classes.mobile} onClick={() => imageClick()} />
    </div>
    
  );
  
}
/*
      <div>
        <h1 className={classes.h1}>
          Livraison de Paniers 100% Bio et Locaux à domicile et en point relais
        </h1>
        <Button href="/catalogue/les-paniers-bio" className={`btn-orange ${classes.button}`}>
          Je commande
        </Button>
      </div>
      <div>
        <img id="logoAB" src={img} alt="logoAB" className={classes.image} />
      </div>
      */