import React, { useEffect, useState } from 'react';

// # UTILS
import { Grid, makeStyles } from '@material-ui/core';
import { axiosGetRequest } from '../../../utils/axiosRequests';

// # MATERIAL UI
const useStyles = makeStyles(() => ({
  root: {
    '& > div': {
      margin: 0,
    },

    '& figure': {
      margin: 0,
      height: '95px',
      width: '95px',
      borderRadius: '95px',
      overflow: 'hidden',

      '& img': {
        height: '95px',
        width: '95px',
        objectFit: 'cover',
      },
    },
  },
}));

export default function ProducerList(props) {
  const classes = useStyles();

  const { producers, title } = props;

  return (
    <>
      <h4>{title}</h4>
      <Grid container spacing={2} className={classes.root}>
        {producers.map((producer) => (
          <Grid item lg={4} key={producer.id}>
            <figure>
              <a href="/nos-producteurs">
                <img
                  src={`${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${producer.photo}`}
                  alt={producer.name}
                />
              </a>
            </figure>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
