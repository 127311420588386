import React, { useState, useContext, useEffect } from 'react';

// # MATERIAL
import { Button, Hidden } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

// # UTILS
import { axiosGetRequest } from '../../../utils/axiosRequests';
import Meta from '../../../utils/metaDescription';

// # CSS
import '../../../assets/css/mainstyle.css';

// # IMAGES
import img1 from '../../../assets/img/val_conv_1.png';
import img2 from '../../../assets/img/val_conv_2.png';
import img3 from '../../../assets/img/val_conv_3.png';
import img4 from '../../../assets/img/val_conv_4.png';
import img5 from '../../../assets/img/team.png';
import img6 from '../../../assets/img/val_ouv_4.png';
import {UserContext} from "../../../contexts/UserContext/UserContext";

export default function Valeurs() {
  const [state, setState] = useState({
    teams: [],
  });
  const [back, setBack] = useState(0);
  const useStyles = makeStyles({
    skeletons: {
      margin: '0 auto',
    },
    showTeams: {
      margin: '1rem 0',
      position: 'relative',
      flexGrow: 1,
      display: 'flex',
      width: `calc(200px*${state.teams.length})`,
      alignItems: 'stretch',
      justifyContent: 'space-between',
      transition: '0.3s',
      left: `calc(-200px * ${back})`,
    },
    showTeamsItem: {
      margin: '1rem 0',
      width: '200px',
      padding: '1rem',
    },
    img: {
      borderRadius: '100px',
    },
    carousel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      overflow: 'unset',
    },
    slides: {
      maxWidth: `600px`,
      overflow: 'hidden',
      '@media screen and (max-width: 600px)': {
        maxWidth: '200px',
      },
    },
    disabled: {
      backgroundColor: '#d9d9d9 !important',
    },
    name: {
      fontWeight: '500',
      color: 'black',
    },
    image: {
      borderRadius: '14px',
    },
    divImage: {
      display: 'flex',
      justifyContent: 'right',
      width: '40%',
      '@media screen and (max-width: 960px)': {
        width: '100%',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    h4: {
      fontSize: '2.8rem !important',
    },
  });
  const classes = useStyles();

  const { user } = useContext(UserContext);

  useEffect(() => {
    axiosGetRequest(`/teams`, user.token).then((res) => {
      const { teams } = res.data.data;
      setState((prev) => ({
        ...prev,
        teams,
      }));
    });
  }, [user.token]);

  return (
    <>
      <Meta page="valeurs" />

      <div id="valeurs">
        <h1>Des Valeurs</h1>
        <h2>Une entreprise collaborative</h2>

        <h3>
          Ensemble nous partageons des valeurs <br /> Et nous portons un projet commun
        </h3>

        <div className="section">
          <section>
            <img src={img1} alt="" />
            <p>
              <b>Ce qui nous guide :</b>
            </p>
            <ul>
              <li className="valeur">Le respect de la terre, de l&apos;eau et du vivant.</li>
              <li className="valeur">
                Le maintien d&apos;un lien agricole et d&apos;une agriculture durable.
              </li>
              <li className="valeur">L&apos;héritage que nous laissons aux générations futures.</li>
            </ul>
          </section>

          <section>
            <img src={img2} alt="" />
            <p>
              <b>Le CO2, on le réduit !</b>
            </p>
            <ul>
              <li className="valeur">Livraison de Paris à vélo</li>
              <li className="valeur">Mutualisations des livraisons avec les commandes groupées</li>
            </ul>
          </section>

          <section>
            <img src={img3} alt="" />
            <p>
              <b>Aucun compromis sur le choix de nos produits :</b>
            </p>
            <ul>
              <li className="valeur">Du bio, rien que du Bio, Et un maximum de local !</li>
            </ul>
          </section>

          <section>
            <img src={img4} alt="" />
            <p>
              <b>Et oui, un panier Bio, c&apos;est ZERO déchet.</b>
            </p>
            <ul>
              <li className="valeur">
                100% de nos emballages sont réutilisables ou renouvelables !
              </li>
            </ul>
            <Button className="btn-orange" href="/zero-dechets">
              Voir
            </Button>
          </section>
        </div>

        <h3>Bio Culture, une entreprise collaborative et humaine :</h3>
        <div className="flex">
          <ul className="ulend">
            <li className="liend">
              Des salariés engagés et fiers de participer à un objectif commun
            </li>
            <li className="liend">
              Une confiance réciproque entre tous les acteurs de la société : clients, salariés,
              producteurs, fournisseurs...
            </li>
            <li className="liend">Une parité hommes / femmes</li>
            <li className="liend">
              Un ratio de moins de 1,3 entre le salaire le plus haut et le plus bas, parce que
              chaque membre de notre équipe joue un rôle clé et participe à la continuité de
              l&apos;entreprise
            </li>
            <li className="liend">
              2 membres d&apos;un ESAT qui viennent quotidiennement renforcer nos équipes,
              participer à un équilibre social
            </li>
          </ul>
          <div className={classes.divImage}>
            <figure
              style={{
                backgroundImage: `url(${img5})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                margin: '0',
                height: '100%',
                maxHeight: '350px',
                maxWidth: '400px',
                width: '100%',
                borderRadius: '30px',
              }}
            />
          </div>
        </div>

        <div className="flex2">
          <h4 className={classes.h4}>Une entreprise ouverte</h4>
          <div>
            <section>
              <p className="pourcent">70%</p>
              <p>
                d&apos;actionnaires clients qui donnent de leur temps pour co-construire un projet
                commun
              </p>
            </section>
            <section>
              <p className="pourcent">20%</p>
              <p>
                d&apos;actionnaires salariés ou anciens salariés qui ont décidé d&apos;aller plus
                loin dans leur engagement
              </p>
            </section>
            <section>
              <p className="pourcent">10%</p>
              <p>d&apos;actionnaires entrepreneurs, impliqués dans une cause écologique</p>
            </section>
            <section>
              <img src={img6} alt="" />
              <p>
                Un reversement de 20% des dividendes aux salariés et de 40% en réinvestissements
                dans des projets portant nos valeurs
              </p>
            </section>
          </div>
        </div>

        <div>
          <h3>Notre équipe</h3>
          {state.teams && (
            <div className={classes.carousel}>
              <div>
                <Button
                  className={back > 0 ? null : classes.disabled}
                  disabled={!(back > 0)}
                  onClick={() => setBack((prev) => prev - 1)}
                >
                  <ArrowBack />
                </Button>
              </div>
              <div className={classes.slides}>
                <section className={classes.showTeams}>
                  {state.teams &&
                    state.teams.map((team) => (
                      <div className={classes.showTeamsItem}>
                        {team.photo ? (
                          <img
                            src={`${process.env.REACT_APP_AWS_PROFIL_PICTURE_BUCKET}/${team.photo}`}
                            alt={team.name}
                            width="100"
                            height="100"
                            className={classes.img}
                          />
                        ) : (
                          <Skeleton
                            className={classes.skeletons}
                            variant="circle"
                            width={60}
                            height={60}
                          />
                        )}
                        <p className={classes.name}>{team.name}</p>
                        <p>{team.description}</p>
                      </div>
                    ))}
                </section>
              </div>
              <Hidden xsDown>
                <div>
                  <Button
                    className={back === state.teams.length - 3 && classes.disabled}
                    disabled={back === state.teams.length - 3}
                    onClick={() => setBack((prev) => prev + 1)}
                  >
                    <ArrowForward />
                  </Button>
                </div>
              </Hidden>
              <Hidden smUp>
                <div>
                  <Button
                    className={back === state.teams.length - 1 && classes.disabled}
                    disabled={back === state.teams.length - 1}
                    onClick={() => setBack((prev) => prev + 1)}
                  >
                    <ArrowForward />
                  </Button>
                </div>
              </Hidden>
            </div>
          )}
        </div>

        <div>
          <h3>Découvrez Bio Culture en images :</h3>
          <iframe
            title="présentation-entreprise"
            src="https://www.youtube.com/embed/9MI4OdEBhnU"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
}
